import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import PersonIcon from "@material-ui/icons/Person";
import ExamNotifications from "../../../blocks/notifications/src/ExamNotifications.web";
// Customizable Area End

import DashboardHomeController from "./DashboardHomeController.web";

class DashboardHome extends DashboardHomeController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    const { userData } = this.state;
    return (
      // Customizable Area Start
      <Box className={this.props.classes.dashboardHome}>
        <Box className="course-profile">
          <Box className="course-finder">
            <p>Course Finder</p>
            <Box className="course-main-container">
              {Array.from({ length: 4 }).map((_, index) => {
                return CourseFinder();
              })}
            </Box>
          </Box>
          <Box className="user-profile">
            <Box className="profile-details">
              <h3>
                <span>
                  <PersonIcon />
                </span>
                Profile Details
              </h3>
              <Box>
                <p>My Rank Id - {userData?.attributes?.myrank_id}</p>
                <p>Student Name - {userData?.attributes?.name}</p>
                <p>Email - {userData?.attributes?.email}</p>
              </Box>
            </Box>
            <Box className="package-details">
              <p>Package - {userData?.attributes.package[0]?.agent1_name}</p>
              <p>
                Ask Your Query - {userData?.attributes.package[0]?.ayq_level}
              </p>
              <p>Live Classes - {userData?.attributes.package[0]?.ayq_level}</p>
              <p>Expiry - {userData?.attributes.package[0]?.expiry_date}</p>
              <p>Amount Paid - Rs {userData?.attributes.package[0]?.amount}</p>
            </Box>
          </Box>
        </Box>
        <ExamNotifications
          id="exam-notification"
          navigation={this.props.navigation}
        />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CourseFinder = () => {
  return (
    <Box className="course-found-container">
      <h3>MPC</h3>
      {Array.from({ length: 3 }).map((_, index) => {
        return (
          <Box key={`course-${index}`} className="course-found">
            <h4>Aerospace Engineer</h4>
            <Box>
              <p>Degree - B.Tech</p>
              <p>Duration - 5 years</p>
              <p>Eligible - MPC/MBi.Pc</p>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const dashboardStyle = () =>
  createStyles({
    dashboardHome: {
      padding: "2em 2em",
      width: "100%",

      "& .course-profile": {
        display: "flex",
        minHeight: "max-content",
        width: "100%",
        gap: "1em",

        "& .course-finder": {
          display: "flex",
          width: "73%",
          flexDirection: "column",
          border: "none",
          borderRadius: "0.7em",
          boxShadow: "0px 0px 15px rgb(190, 189, 189)",
          padding: "2em 1em",

          "& > p": {
            fontSize: "1.2em",
            fontWeight: "500",
          },

          "& .course-main-container": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: "1em",

            "& .course-found-container": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "2px solid #f0b447",
              width: "270px",
              borderRadius: "1em",
              gap: "1em",
              background: "#ebebeb",
              padding: "1em 0",

              "& .course-found": {
                display: "flex",
                flexDirection: "column",
                background: "white",
                width: "200px",
                borderRadius: "1em",
                padding: "1em",
                boxShadow: "0px 0px 15px rgb(190, 189, 189)",

                "& div": {
                  "& p": {
                    fontSize: "0.9em",
                    color: "gray",
                  },
                },
              },
            },
          },
        },

        "& .user-profile": {
          display: "flex",
          flexDirection: "column",
          gap: "2em",
          width: "26%",

          "& *": {
            overflowWrap: "break-word",
          },

          "& p": {
            fontWeight: "500",
          },

          "& .profile-details": {
            display: "flex",
            flexDirection: "column",
            border: "none",
            borderRadius: "1em",
            padding: "1.5em 1em",
            background: "#f5fafe",
            minHeight: "200px",
            height: "max-content",

            "& h3": {
              color: "#5f687e",
            },

            "& div": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginTop: "2em",
              gap: "1em",
            },
          },

          "& .package-details": {
            display: "flex",
            flexDirection: "column",
            border: "none",
            borderRadius: "1em",
            padding: "3em 1em",
            background: "#fbf5ff",
            height: "330px",
            justifyContent: "space-between",
          },
        },

        "@media only screen and (max-width: 992px)": {
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",

          "& .course-finder": {
            width: "100%",
          },

          "& .user-profile": {
            width: "100%",

            "& .profile-details, & .package-details": {
              width: "100%",
            },
          },
        },
      },

      "& .exam-notif": {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        gap: "1em",
        border: "none",
        borderRadius: "1em",
        background: "#f9f8fd",
        height: "max-content",
        padding: "2em 1em",
        marginTop: "3em",
        color: "#37363c",
        width: "100%",

        "& .notifs-exam-container": {
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          gap: "3em",

          "& .notifs-exam": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            borderRadius: "1em",
            padding: "0.8em",
            background: "white",
            width: "48%",
            height: "100px",

            "& .gobackstep": {
              display: "inline-block",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              textAlign: "center",
              background:
                "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
              color: "white",
              fontSize: "1.3em",
              cursor: "pointer",
              marginLeft: "1em",
            },
          },
        },
      },
    },
  });

export default withStyles(dashboardStyle)(DashboardHome);
export { DashboardHome };
// Customizable Area End
