import React from "react";
import { Button as MUIButton } from "@material-ui/core";

function Button({
  width,
  height,
  color,
  title,
  background,
  border,
  onClick,
}: {
  width: string;
  height: string;
  color: string;
  title: string;
  background?: string;
  border?: string;
  onClick?: () => void;
}) {
  const buttonStyle = {
    width: width,
    height: height,
    color: color,
    background: background
      ? background
      : "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    textTransform: "none" as const,
    border: border && border,
  };
  return (
    <MUIButton onClick={onClick} style={buttonStyle}>
      {title}
    </MUIButton>
  );
}

export default Button;
