import React, { CSSProperties } from "react";
import { withStyles, createStyles } from "@material-ui/core";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { StyleRules } from "@material-ui/styles";

interface IPageDefaultExport {
  pageStyles: {
    [key: string]: CSSProperties | Record<string, unknown>;
  };
  component: unknown;
}

const PageDefaultExport = ({ pageStyles, component }: IPageDefaultExport) => {
  const styles = () => {
    return createStyles(pageStyles as StyleRules<{}, string>);
  };

  return withStyles(styles)(component as typeof BlockComponent);
};

export default PageDefaultExport;
