import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { extractParams } from "../../../framework/src/UtilsWeb";
interface Root {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Attributes {
  id: number;
  name: string;
  image: string;
  practice_test: string;
  avail_pt: string;
  date_pt: any;
  class_zone: string;
  avail_cz: string;
  date_cz: any;
  question_bank: string;
  avail_qb: string;
  date_qb: any;
  created_by: string;
  remarks: string;
  chapter_read: boolean;
  viewed: boolean;
  continue_reading_topic_id: any;
  test_attempted: boolean;
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  chaptersAttempted: Root[];
  chaptersUnattempted: Root[];
  token: string;
  subjectId: string;
  loading: boolean;
  subjectName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PracticeTestAttemptsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getChaptersMessageId = "";
  token = "";
  pathnameSearchResolve =
    this.props.navigation.history.location.pathname +
    this.props.navigation.history.location.search;
  searchResolve = this.props.navigation.history.location.search;
  breadcrumb = [
    {
      label: "Practice Test",
      link: this.pathnameSearchResolve,
    },
    {
      label: "Chapters",
      link: this.pathnameSearchResolve,
    },
  ];
  getSubjectAttemptsMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      chaptersAttempted: [],
      chaptersUnattempted: [],
      token: "",
      subjectId: "",
      loading: true,
      subjectName: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getChaptersMessageId) {
      if (response.data) {
        this.handleSuccessfulChapterFetch(response.data);
      }
    }

    if (apiRequestCallId === this.getSubjectAttemptsMessageId && response) {
      if (response.data) {
        this.handleSuccessSubject(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  handleSuccessSubject = (
    response: {
      id: string;
      attributes: { name: string };
    }[]
  ) => {
    const subjectId = this.props.navigation.getParam("subjectId");
    for (const data of response) {
      if (data.id === subjectId) {
        const breadcrumb = this.breadcrumb;
        breadcrumb[1].label = data.attributes.name;

        const propPassingAttempts = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingAttempts.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingAttempts);

        this.setState({
          subjectName: data.attributes.name,
          loading: false,
        });
      }
    }
  };

  handleSubjectIdChange = async () => {
    const token = await storage.get("authToken");
    if (token) {
      this.token = token;
    }

    const subjectId = this.props.navigation.getParam("subjectId");
    if (subjectId) {
      this.setState(
        {
          subjectId,
        },
        () => {
          this.getChapters(this.state.subjectId);
        }
      );
    }
  };

  handleSuccessfulChapterFetch = (response: Root[]) => {
    const attempted = response.filter(
      (chapter) => chapter.attributes.test_attempted
    );
    const unattempted = response.filter(
      (chapter) => !chapter.attributes.test_attempted
    );

    this.setState({
      chaptersAttempted: attempted,
      chaptersUnattempted: unattempted,
      loading: false,
    });
  };

  // Customizable Area Start
  componentDidMount = async () => {
    const subjectId = this.props.navigation.getParam("subjectId");
    this.subjectFetched(subjectId);
    const propPassingAttempts = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingAttempts.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingAttempts);

    this.getSubjectAttempts();
  };

  subjectFetched = (subjectId: string) => {
    if (subjectId) {
      this.setState(
        {
          subjectId,
        },
        () => {
          this.getChapters(this.state.subjectId);
        }
      );
    }
  };

  getChapters = async (subjectId: string) => {
    const getChaptersMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    this.getChaptersMessageId = getChaptersMessage.messageId;

    getChaptersMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getChaptersMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${subjectId}/get_chapters`
    );

    getChaptersMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getChaptersMessage.id, getChaptersMessage);
  };

  componentDidUpdate = (_: Props, prevState: S) => {
    const subjectId = this.props.navigation.getParam("subjectId");

    if (subjectId !== prevState.subjectId) {
      this.handleSubjectIdChange();
      this.getSubjectAttempts();
    }
  };

  getSubjectAttempts = async () => {
    const urlSearch = this.props.navigation.history.location.search;
    const packageId = extractParams(urlSearch, "packageId");
    const getSubjectAttemptsMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    this.getSubjectAttemptsMessageId = getSubjectAttemptsMessage.messageId;

    getSubjectAttemptsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${packageId}/get_subjects`
    );

    getSubjectAttemptsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectAttemptsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getSubjectAttemptsMessage.id,
      getSubjectAttemptsMessage
    );
  };
  // Customizable Area End
}
