import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Banner from "../../../components/src/Banner.web";
// Customizable Area End

import CourseFinderController, {
  Props,
  configJSON,
} from "./CourseFinderController.web";
import { rightarrow } from "./assets";

class CourseFinder extends CourseFinderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { collegesLength, counsellingLength, coursesLength, examLength } =
      this.state;
    return (
      // Customizable Area Start
      <Box>
        <Banner title="Course Finder" />
        <Box className={classes.dataContainer}>
          <Box className={classes.examinationStyle}>
            <Typography className="box-label">
              {examLength} {configJSON.examtionLabel}
            </Typography>
            <button
              data-test-id="navigate-to-btn"
              onClick={() => this.navigateTo("CourseFinderExamination")}
            >
              <Typography>{configJSON.completeListLabel}</Typography>
              <img src={rightarrow} alt="arrow" />
            </button>
          </Box>
          <Box className={classes.collegesStyle}>
            <Typography className="box-label">
              {collegesLength} {configJSON.colleageLabel}
            </Typography>
            <button
              data-test-id="navigate-to-btn"
              onClick={() => this.navigateTo("CourseFinderColleges")}
            >
              <Typography>{configJSON.completeListLabel}</Typography>
              <img src={rightarrow} alt="arrow" />
            </button>
          </Box>
          <Box className={classes.counsellingStyle}>
            <Typography className="box-label">
              {counsellingLength} {configJSON.Counselling}
            </Typography>
            <button
              data-test-id="navigate-to-btn"
              onClick={() => this.navigateTo("CourseFinderCounselling")}
            >
              <Typography>{configJSON.completeListLabel}</Typography>
              <img src={rightarrow} alt="arrow" />
            </button>
          </Box>
          <Box className={classes.coursesStyle}>
            <Typography className="box-label">
              {coursesLength} {configJSON.Courses}
            </Typography>
            <button
              data-test-id="navigate-to-btn"
              onClick={() => this.navigateTo("CourseFinderCourses")}
            >
              <Typography>{configJSON.completeListLabel}</Typography>
              <img src={rightarrow} alt="arrow" />
            </button>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleCourseFinder = () =>
  createStyles({
    dataContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "2em",
      gap: "2em",
      "& button": {
        display: "flex",
        width: "100%",
        height: "47px",
        fontSize: "18px",
        color: "white",
        border: "none",
        cursor: "pointer",
        background: "rgba(0, 0, 0, 0.46)",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        bottom: "0",
        left: "0",
        gap: "1em",
        "& img": {
          width: "25px",
          height: "25px",
        },
      },
    },
    examinationStyle: {
      width: "80%",
      height: "200px",
      background: "rgb(2, 191, 239)",
      position: "relative",
      paddingLeft: "4em",
      paddingTop: "4em",
      "& .box-label": {
        fontSize: "32px",
        color: "white",
      },
    },
    collegesStyle: {
      width: "80%",
      height: "200px",
      paddingLeft: "4em",
      paddingTop: "4em",
      "& .box-label": {
        fontSize: "32px",
        color: "white",
      },
      background: "rgb(245, 156, 14)",
      position: "relative",
    },
    counsellingStyle: {
      width: "80%",
      paddingLeft: "4em",
      paddingTop: "4em",
      "& .box-label": {
        fontSize: "32px",
        color: "white",
      },
      height: "200px",
      background: "rgb(5, 165, 90)",
      position: "relative",
    },
    coursesStyle: {
      width: "80%",
      height: "200px",
      background: "rgb(222, 76, 58)",
      paddingLeft: "4em",
      paddingTop: "4em",
      "& .box-label": {
        fontSize: "32px",
        color: "white",
      },
      position: "relative",
    },
  });
export default withStyles(webStyleCourseFinder)(CourseFinder);
export { CourseFinder };
// Customizable Area End
