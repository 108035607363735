import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Banner from "../../../components/src/Banner.web";
import { arrowCircle, searchImage } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { searchInputStyle } from "./CourseFinderExamination.web";
// Customizable Area End

import MPCGuidanceAnalysisController, {
  Props,
  configJSON,
} from "./MPCGuidanceAnalysisController.web";

class MPCGuidanceAnalysis extends MPCGuidanceAnalysisController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const mpcType = this.props.navigation.getParam("mpc_data");

    const { loading, currentPage, analysisData } = this.state;
    const startIndex = currentPage * this.dataPerPage;
    const endIndex = Math.min(
      startIndex + this.dataPerPage,
      analysisData.length
    );

    const currentPageData = analysisData.slice(startIndex, endIndex);
    const pagesArray = Array.from(
      { length: this.state.numberOfPages },
      (_, index) => index + 1
    );

    if (loading) {
      return <Loader loading={loading} />;
    }
    return (
      // Customizable Area Start
      <Box>
        <Banner title="Exam Details" />
        <Box>
          <Box className={classes.searchContainer}>
            <Box position={"relative"}>
              <img
                src={searchImage}
                alt="search-image"
                className={classes.searchImageStyle}
              />
              <input className={classes.searchStyle} />
            </Box>
          </Box>
          <Box>
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    {mpcType !== "jeeadv" &&
                      mpcType !== "tseamcetengg" &&
                      mpcType !== "apeamcetengg" && (
                        <TableCell>College Type</TableCell>
                      )}
                    <TableCell>
                      {configJSON.collegeN}{" "}
                      <div className="total-class">
                        {this.state.totalCollegeName}
                      </div>
                    </TableCell>
                    <TableCell>
                      Course Name{" "}
                      <div className="total-class">
                        {this.state.totalCourseName}
                      </div>
                    </TableCell>
                    {mpcType !== "jeeadv" && <TableCell>Quota</TableCell>}
                    <TableCell>
                      Total Seats{" "}
                      <div className="total-class">{this.state.totalSeats}</div>
                    </TableCell>
                    <TableCell colSpan={2}>Gender Neutral</TableCell>
                    <TableCell colSpan={2}>Female only</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="table-head-row-2">
                    {mpcType !== "jeeadv" &&
                      mpcType !== "tseamcetengg" &&
                      mpcType !== "apeamcetengg" && <TableCell />}
                    <TableCell />
                    <TableCell />
                    {mpcType !== "jeeadv" && <TableCell />}
                    <TableCell />
                    <TableCell className="colored-td">Open</TableCell>
                    <TableCell className="colored-td">Close</TableCell>
                    <TableCell className="colored-td">Open</TableCell>
                    <TableCell className="colored-td">Close</TableCell>
                  </TableRow>
                  {currentPageData.map((data) => {
                    return (
                      <TableRow>
                        {mpcType !== "jeeadv" &&
                          mpcType !== "tseamcetengg" &&
                          mpcType !== "apeamcetengg" && (
                            <TableCell>
                              {data.college_data.college_type}
                            </TableCell>
                          )}
                        <TableCell>{data.college_data.college_name}</TableCell>
                        <TableCell>{data.course_data.course_name}</TableCell>
                        {mpcType !== "jeeadv" && (
                          <TableCell>{data.cutoff_data.quota}</TableCell>
                        )}
                        <TableCell>
                          {data.cutoff_data.gen_seats +
                            data.cutoff_data.female_seats}
                        </TableCell>
                        <TableCell>{data.cutoff_data.gen_min}</TableCell>
                        <TableCell>{data.cutoff_data.gen_max}</TableCell>
                        <TableCell>{data.cutoff_data.female_min}</TableCell>
                        <TableCell>{data.cutoff_data.female_max}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.paginationContainerStyle}>
              <Box className={classes.buttonPaginationStyle}>
                {pagesArray.map((pageNumber) => (
                  <button
                    onClick={() => this.changePage(pageNumber - 1)}
                    key={pageNumber}
                    className={
                      this.state.currentPage === pageNumber - 1
                        ? "active-page"
                        : ""
                    }
                  >
                    {pageNumber}
                  </button>
                ))}
                <img
                  onClick={() => this.changePage(currentPage + 1)}
                  alt="arrow"
                  data-test-id="change-page-btn"
                  src={arrowCircle}
                  className={classes.arrowCircle}
                />
                <Typography className={classes.paginationLabel}>
                  1-{this.state.numberOfPages}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleMPCGuidanceAnalysis = () =>
  createStyles({
    ...searchInputStyle,
    tableContainer: {
      width: "100%",
      minWidth: "100%",
      minHeight: "700px",
      "& .badge-tables": {
        display: "inline-block",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        background: "rgb(20, 134, 0)",
        color: "white",
      },
      "& .table-head-row-2": {
        background: "none",
        "& td": {
          height: "48px",
        },
        "& .colored-td": {
          background: "rgba(89, 10, 135, 0.24)",
          color: "#222",
        },
      },
      "& *": {
        textAlign: "center",
      },
      "& .MuiTableCell-root": {
        border: "1px solid rgb(151, 151, 151)",
      },
      "& td, & td *": {
        fontSize: "20px",
        fontWeight: 500,
        textAlign: "center",
      },
      "& th": {
        background: "rgb(82, 0, 130)",
        height: "77px",
        color: "white",
        fontWeight: 500,
        fontSize: "24px",
        textAlign: "center",
        "& .total-class": {
          display: "inline-block",
          width: "max-content",
          minWidth: "30px",
          minHeight: "30px",
          height: "max-content",
          borderRadius: "50%",
          background: "#158A00",
          fontSize: "0.7em",
          padding: "0.2em",
        },
      },
      "& td": {
        height: "152px",
      },
    },
    paginationContainerStyle: {
      display: "flex",
      alignItems: "center",
      padding: "3em 3em 3em 0",
      width: "100%",
      justifyContent: "flex-end",
    },
    buttonPaginationStyle: {
      borderRadius: "4px",
      border: "1px solid rgba(255, 58, 87, 0.7)",
      position: "relative" as const,
      "& button": {
        height: "41px",
        width: "35px",
        color: "#222",
        border: "none",
        background: "white",
        borderRadius: "2px",
        fontWeight: 500,
        cursor: "pointer",
      },
      "& .active-page": {
        color: "#eee",
        background:
          "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      },
    },
    paginationLabel: {
      fontSize: "12px",
      position: "absolute" as const,
      left: "10px",
    },
    arrowCircle: {
      width: "15px",
      position: "absolute" as const,
      background: "white",
      right: "-9px",
      borderRadius: "50%",
      top: "11px",
      height: "15px",
      cursor: "pointer",
    },
  });
export default withStyles(webStyleMPCGuidanceAnalysis)(MPCGuidanceAnalysis);
export { MPCGuidanceAnalysis };
// Customizable Area End
