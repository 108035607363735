import React from "react";

import {
  Box,
  // Customizable Area Start
  Button,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./styles/forgotpassword.scss";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// Customizable Area End

import ForgotPasswordController, {
  Props,
  configJSON,
} from "./ForgotPasswordController.web";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className="forgot-password-wrapper">
        <Modal
          open={this.state.isModalOpen}
          onClose={this.handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box sx={webStyle.modalStyle}>
            <HighlightOffIcon
              data-test-id="close-modal"
              onClick={this.handleCloseModal}
              style={webStyle.highlightIcon}
            />
            <CheckCircleIcon style={webStyle.checkIcon} />
            <p>{configJSON.modalResetPasswordSuccess}</p>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpenWrong}
          onClose={this.handleCloseModalWrong}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box sx={webStyle.modalStyle}>
            <HighlightOffIcon
              data-test-id="close-modal"
              onClick={this.handleCloseModalWrong}
              style={webStyle.highlightIcon}
            />
            <CancelIcon style={webStyle.cancelIcon} />
            <p>{configJSON.modalResetPasswordFail}</p>
          </Box>
        </Modal>
        <Box
          className="forgot-password-back"
          onClick={this.handleGoBack}
          data-test-id="go-back"
        >
          <div className="forgot-password-back-icon">{"<"}</div>
          <p>{configJSON.goback}</p>
        </Box>
        <Box className="forgot-password-box">
          <h3>{configJSON.enterCredential}</h3>
          <Box className="forgot-password-credential">
            <p>{configJSON.enterEmail}</p>
            <form
              data-test-id="submit-form"
              onSubmit={(event) => this.sendForgotPasswordLink(event)}
            >
              <input
                value={this.state.userInput.emailAddress}
                name="emailAddress"
                onChange={(event) => this.handleInputChange(event)}
                placeholder="Enter mail id"
                data-test-id="email-input"
              />
              <Button data-test-id="submit-btn" type="submit">
                {configJSON.continue}
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "389px",
    height: "210px",
    border: "none",
    backgroundColor: "white",
    borderRadius: "0.5em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
  },
  highlightIcon: {
    position: "absolute" as const,
    top: "10px",
    right: "10px",
    fontSize: "2rem",
    cursor: "pointer",
    color: "gray",
  },
  checkIcon: { color: "#FF6A54", fontSize: "4rem" },
  cancelIcon: { color: "#FE6056", fontSize: "4rem" },
};
// Customizable Area End
