import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import WebRoutesGenerator from "../../../components/src/NativeWebRouteWrapper";
import { routeMapDashboard } from "../../../web/src/App";
import Header from "../../../components/src/Header.web";
import { book, home, myranklogo, document, notes } from "./assets";
import Footer from "../../../components/src/Footer.web";
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Button from "../../../components/src/Button.web";
import MenuIcon from "@material-ui/icons/Menu";
// Customizable Area End

import DashboardGuidanceController, {
  Props,
  configJSON,
} from "./DashboardGuidanceController.web";

class DashboardGuidance extends DashboardGuidanceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box data-test-id="guidance-dashboard">
        <Header image={myranklogo} navigation={this.props.navigation} />
        <Box className={this.props.classes.sideBarContainer}>
          <Box position={"relative"}>
            <Sidebar
              className={this.props.classes.dashboardSidebar}
              rootStyles={{
                [`.${sidebarClasses.container}`]: {
                  backgroundColor: "inherit",
                  position: "relative",
                  minHeight: "97vh",
                },
              }}
              collapsed={!this.state.showSidebar}
              collapsedWidth="0"
              data-test-id="sidebar-container"
            >
              <Menu
                rootStyles={{
                  paddingBottom: "15em",
                  ".ps-menu-button:hover": {
                    background: "inherit",
                  },
                }}
              >
                <MenuItem
                  icon={<img src={home} className="nav-icon" />}
                  onClick={() => this.navigateToHome()}
                  className={this.determineIfActiveLink(
                    "/dashboard-guidance/home"
                  )}
                  data-test-id="nav-btns"
                >
                  {configJSON.home}
                </MenuItem>
                <MenuItem
                  icon={<img src={book} className="nav-icon" />}
                  onClick={() => this.navigateToCourseFinder()}
                  className={this.determineIfActiveLink(
                    "/dashboard-guidance/course-finder"
                  )}
                  data-test-id="nav-btns"
                >
                  {configJSON.courseFinder}
                </MenuItem>
                <MenuItem
                  icon={<img src={notes} className="nav-icon" />}
                  onClick={() => this.navigateToCutOff()}
                  className={this.determineIfActiveLink(
                    "/dashboard-guidance/cut-off-analysis"
                  )}
                  data-test-id="nav-btns"
                >
                  {configJSON.cutoff}
                </MenuItem>
                <MenuItem
                  icon={<img src={document} className="nav-icon" alt="Icon" />}
                  onClick={() => this.navigateToApplications()}
                  className={this.determineIfActiveLink(
                    "/dashboard-guidance/applications"
                  )}
                  data-test-id="nav-btns"
                >
                  {configJSON.applications}
                </MenuItem>
                <MenuItem
                  icon={<img src={document} className="nav-icon" alt="Icon" />}
                  onClick={() => this.navigateToDocuments()}
                  className={this.determineIfActiveLink(
                    "/dashboard-guidance/documents-upload"
                  )}
                  data-test-id="nav-btns"
                >
                  Documents
                </MenuItem>
                <MenuItem
                  icon={<img src={document} className="nav-icon" alt="Icon" />}
                  onClick={() => this.navigateUsageStats()}
                  className={this.determineIfActiveLink(
                    "/dashboard-guidance/usage-stats"
                  )}
                  data-test-id="nav-btns"
                >
                  Usage Stats
                </MenuItem>
              </Menu>
              <Menu
                rootStyles={{
                  ".ps-menu-button:hover": {
                    background: "inherit",
                  },
                }}
                className={this.props.classes.logoutStyle}
              >
                <MenuItem
                  icon={<ExitToAppIcon className="nav-icon" />}
                  onClick={() => this.logout()}
                  data-test-id="logout-btn"
                >
                  Logout
                </MenuItem>
              </Menu>
            </Sidebar>
            <Box className={this.props.classes.burgerButton}>
              <MenuIcon
                data-test-id="sidebar-btn"
                className="menu-icon"
                onClick={() => this.setShowSidebar()}
              />
            </Box>
          </Box>
          <Box className={this.props.classes.content}>
            {WebRoutesGenerator({ routeMap: routeMapDashboard })}
          </Box>
        </Box>
        <Footer />
        <Modal open={this.state.isModalOpenLogout} onClose={this.handleModal}>
          <Box className={this.props.classes.modalStyle}>
            <p>You Successfully Logged Out</p>
            <Box
              onClick={() => this.navigateToLandingPage()}
              data-test-id="logout-btn-modal"
            >
              <Button
                key={"buttonKey"}
                width="200px"
                title="Go Back To Landing Page"
                color="white"
                height="50px"
              />
            </Box>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleDashboardGuidance = () =>
  createStyles({
    sideBarContentContainer: {
      display: "flex",
      width: "100%",
      marginTop: "1em",
      "& .sidebar-main-container": {
        "& #sideBarClose": {
          width: "0",
          left: "-600px",
        },
      },
    },
    content: {
      width: "100%",
      minHeight: "110vh",
      height: "max-content",
    },
    burgerButton: {
      position: "absolute",
      top: "10px",
      right: "-25px",
      cursor: "pointer",
      zIndex: 9999,
    },
    sideBarContainer: {
      display: "flex",
      marginBottom: "5em",
    },
    dashboardSidebar: {
      minHeight: "97vh",
      height: "100%",
      backgroundColor: "#520082",
      color: "white",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderBottomRightRadius: "2em",
      padding: "2em 0",
      transition: "all 0.3s ease",
      position: "relative",
      "& *": {
        whiteSpace: "wrap",
      },
      "& .nav-icon": {
        width: "20px",
        height: "20px",
        color: "#a77ec0",
      },
      "& .active-link": {
        color: "white",
        fontWeight: "bold",
      },
    },
    logoutStyle: {
      position: "absolute",
      bottom: "25px",
    },
    modalStyle: {
      left: "50%",
      alignItems: "center",
      justifyContent: "center",
      transform: "translate(-50%, -50%)",
      gap: "0.5em",
      top: "50%",
      position: "absolute" as "absolute",
      flexDirection: "column",
      height: "210px",
      border: "none",
      width: "calc(max-content + 100px)",
      padding: "2em",
      display: "flex",
      borderRadius: "0.5em",
      backgroundColor: "white",
      "& h6": {
        marginBottom: "1em",
      },
    },
  });
export default withStyles(webStyleDashboardGuidance)(DashboardGuidance);
export { DashboardGuidance };
// Customizable Area End
