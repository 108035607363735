import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import GuestPracticeTestSubjectsController, {
  Props,
  configJSON,
} from "./GuestPracticeTestSubjectsController.web";

class GuestPracticeTestSubjects extends GuestPracticeTestSubjectsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box className={this.props.classes.practiceTestGuestStyle}>
          <p style={webStylesGuestPracticeTest.selectSubject}>
            {configJSON.selectSubjectContinue}
          </p>
          {this.state.subjects.length > 0 && (
            <Box className="test-box-container">
              {this.state.subjects.map((subject) => {
                return (
                  <Box
                    key={subject.attributes.name}
                    className="test-box"
                    onClick={() =>
                      this.navigateTo(
                        `/guest/practice-test/${subject.id}/chapters`
                      )
                    }
                    data-test-id="subject-box"
                  >
                    <Box className="icon-container">
                      <img
                        src={subject.attributes.image}
                        className="sample-image"
                        alt={subject.attributes.name}
                      />
                    </Box>
                    <p className="link-test">{subject.attributes.name}</p>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStylesGuestPracticeTest = {
  bannerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    color: "rgb(82, 0, 130)",
  },
  selectSubject: {
    fontSize: "1.5em",
    fontWeight: 500,
    marginTop: "3em",
  },
};

export const webStyleGuestPracticeTestSubjects = () =>
  createStyles({
    practiceTestGuestStyle: {
      padding: "0 2em",
      width: "100%",
      "& .test-box-container": {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "1.5em",
        height: "max-content",
        marginTop: "1.5em",
        "& .test-box": {
          borderRadius: "0.5em",
          border: "1px solid rgb(178, 176, 176)",
          minHeight: "200px",
          width: "300px",
          height: "max-content",
          cursor: "pointer",
          "& .icon-container": {
            justifyContent: "center",
            display: "flex",
            height: "140px",
            alignItems: "center",
            width: "100%",
            "& .sample-image": {
              border: "none",
              background: "rgb(178, 176, 176)",
              width: "70px",
              borderRadius: "50%",
              height: "70px",
            },
          },
          "& .link-test": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTop: "1px solid rgb(178, 176, 176)",
            height: "max-content",
            background: "rgb(178, 176, 176)",
            borderRadius: "0 0 0.43em 0.43em",
            textAlign: "center",
            color: "white",
            padding: "1.5em",
            fontWeight: "bold",
          },
          "&:hover": {
            border: "1px solid #500084",
            "& .link-test": {
              backgroundColor: "#500084",
            },
          },
        },
      },
    },
  });
export default withStyles(webStyleGuestPracticeTestSubjects)(
  GuestPracticeTestSubjects
);
export { GuestPracticeTestSubjects };
// Customizable Area End
