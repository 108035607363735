import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { styles } from "./styles";
import { WithStyles } from "@material-ui/core";
interface Test {
  id: number;
  duration: number;
  test_name: string;
  test_subject: string;
}

interface TopicQuestion {
  attributes: {
    remark: string;
    created_by: string;
    options: Option[] | [];
    opt_count: null | number;
    is_correct: boolean;
    question_1: string;
    id: number;
    question_no: number;
    topic_id: number;
    question_type: string;
    p_mark: number;
    n_mark: string;
    question_2: string;
    is_unattended: null;
    explanation: string;
    passage: string;
    correct_answer: string;
  };
  id: string;
  type: string;
}

interface Option {
  id: string;
  option: string;
}
// Customizable Area End

export interface Props extends WithStyles<typeof styles> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  answersWeeklyTest: {
    question_id: number;
    option: string[][];
    legend?: string;
    is_marked?: boolean;
  }[];
  endTimeWeeklyTest: number;
  timeRemaining: number;
  testData: TopicQuestion[];
  meta: Test | null;
  submitModalOpen: boolean;
  questionIds: number[];
  reviewModalOpen: boolean;
  currentTestData: number;
  reviewId: string;
  errorModalOpen: boolean;
  loading: boolean;
  isBlocking: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WeeklyTestTakingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTestMessageId = "";
  intervalId: NodeJS.Timeout = setTimeout(() => {}, 0);
  submitTestMessageId = "";
  submitOverviewMessageId = "";
  testId = "";
  breadcrumb = [
    {
      label: "Weekly Test",
      link: "/dashboard/weekly-test/instructions",
    },
    {
      label: "Instructions",
      link: "/dashboard/weekly-test/instructions",
    },
    {
      link: "/dashboard/weekly-test/tests",
      label: "Tests",
    },
    {
      link: "/dashboard/weekly-test/tests",
      label: "Tests",
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Taking Test",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.tick = this.tick.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      endTimeWeeklyTest: 0,
      loading: false,
      timeRemaining: 0,
      testData: [],
      currentTestData: 0,
      answersWeeklyTest: localStorage.getItem("answersWeeklyTest")
        ? JSON.parse(localStorage.getItem("answersWeeklyTest") as string)
        : [],
      meta: null,
      submitModalOpen: false,
      reviewModalOpen: false,
      questionIds: [],
      reviewId: "",
      errorModalOpen: false,
      isBlocking: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.submitTestMessageId && response) {
      if (response.message) {
        localStorage.removeItem("endTimeWeeklyTest");
        localStorage.removeItem("totalTestTimeWeeklyTest");
        localStorage.removeItem("currentWeeklyTest");
        localStorage.removeItem("currentWeeklyMeta");
        localStorage.removeItem("answersWeeklyTest");
        this.setState({
          reviewId: String(response.review_history.id),
          isBlocking: false,
        });
        this.handleSubmitModalClose();
        this.handleReviewModalOpen();
      }

      this.setState({
        loading: false,
      });
    }

    if (apiRequestCallId === this.getTestMessageId && response) {
      if (response.data) {
        this.handleTestData(response.data, response.meta.weekly_test);
        const savedEndTime = localStorage.getItem("endTimeWeeklyTest");
        let endTimeWeeklyTest: number;

        if (savedEndTime && parseInt(savedEndTime, 10) !== 0) {
          endTimeWeeklyTest = parseInt(savedEndTime, 10);
        } else {
          endTimeWeeklyTest =
            Date.now() + response.meta.weekly_test.duration * 60 * 1000;
          localStorage.setItem(
            "endTimeWeeklyTest",
            endTimeWeeklyTest.toString()
          );
        }

        const timeRemaining = Math.max(endTimeWeeklyTest - Date.now(), 0);

        this.handleTimerStart(endTimeWeeklyTest, timeRemaining);
      } else {
        this.setState({
          errorModalOpen: true,
        });
      }

      this.setState({
        loading: false,
      });
    }

    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingWTTC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingWTTC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingWTTC);

    const testId = this.props.navigation.getParam("testId");

    this.testId = testId;

    const currentTestData = localStorage.getItem("currentWeeklyTest");
    const currentTestMeta = localStorage.getItem("currentWeeklyMeta");

    if (testId === String(JSON.parse(currentTestMeta as string)?.id)) {
      if (currentTestData && currentTestMeta) {
        this.handleCurrentTest(
          JSON.parse(currentTestData),
          JSON.parse(currentTestMeta)
        );
        const savedEndTime = localStorage.getItem("endTimeWeeklyTest");
        const totalTestTimeWeeklyTest = localStorage.getItem(
          "totalTestTimeWeeklyTest"
        );
        let endTimeWeeklyTest: number;

        if (
          savedEndTime &&
          parseInt(savedEndTime, 10) !== 0 &&
          totalTestTimeWeeklyTest
        ) {
          endTimeWeeklyTest = parseInt(savedEndTime, 10);
        } else {
          endTimeWeeklyTest =
            Date.now() + Number(totalTestTimeWeeklyTest) * 1000;
          localStorage.setItem(
            "endTimeWeeklyTest",
            endTimeWeeklyTest.toString()
          );
        }

        const timeRemaining = Math.max(endTimeWeeklyTest - Date.now(), 0);

        this.handleTimerStart(endTimeWeeklyTest, timeRemaining);
      }
    } else {
      localStorage.removeItem("totalTestTimeWeeklyTest");
      localStorage.removeItem("endTimeWeeklyTest");
      localStorage.removeItem("currentWeeklyTest");
      localStorage.removeItem("currentWeeklyMeta");
      localStorage.removeItem("answersWeeklyTest");
      if (testId) {
        this.getTest(testId);
      }
    }
  };

  componentWillUnmount = async () => {
    clearInterval(this.intervalId);
  };

  handleSubmitModalClose = () => {
    this.setState({
      submitModalOpen: false,
    });
  };

  handleSubmitModalOpen = () => {
    this.setState({
      submitModalOpen: true,
    });
  };

  handleReviewModalClose = () => {
    this.setState({
      reviewModalOpen: false,
    });
  };

  handleReviewModalOpen = () => {
    this.setState({
      reviewModalOpen: true,
    });
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  handleCurrentTest = (testData: TopicQuestion[], meta: Test) => {
    const responseDataIds = testData.map((question) => question.attributes.id);

    const breadcrumb = this.breadcrumb;
    breadcrumb[3].label = meta.test_subject;
    breadcrumb[4].label = meta.test_name + " Test";

    const propPassingWTTC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingWTTC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingWTTC);

    this.setState({
      testData,
      meta,
      questionIds: responseDataIds,
    });
  };

  tick = () => {
    const newTimeRemaining = Math.max(
      this.state.endTimeWeeklyTest - Date.now(),
      0
    );
    this.setState({ timeRemaining: newTimeRemaining });

    if (newTimeRemaining <= 0) {
      clearInterval(this.intervalId);
      this.timeReachedZero();
    }
  };

  timeReachedZero = () => {
    localStorage.removeItem("endTimeWeeklyTest");
    localStorage.removeItem("totalTestTimeWeeklyTest");
    localStorage.removeItem("currentWeeklyTest");
    localStorage.removeItem("currentWeeklyMeta");
    localStorage.removeItem("answersWeeklyTest");
    this.submitTest();
  };

  handleTimerStart = (endTimeWeeklyTest: number, timeRemaining: number) => {
    this.setState(
      {
        endTimeWeeklyTest,
        timeRemaining,
      },
      () => {
        this.intervalId = setInterval(this.tick, 1000);
      }
    );
  };

  handleSpecificQuestion = (index: number) => {
    this.setState({
      currentTestData: index,
    });
  };

  handlePreviousQuestion = () => {
    if (this.state.currentTestData !== 0) {
      this.setState({
        currentTestData: this.state.currentTestData - 1,
      });
    }
  };

  handleNextQuestion = () => {
    if (this.state.currentTestData !== this.state.testData.length - 1) {
      this.setState({
        currentTestData: this.state.currentTestData + 1,
      });
    }
  };

  handleTestData = (response: TopicQuestion[], meta: Test) => {
    localStorage.setItem("totalTestTimeWeeklyTest", String(meta.duration * 60));
    localStorage.setItem("currentWeeklyTest", JSON.stringify(response));
    localStorage.setItem("currentWeeklyMeta", JSON.stringify(meta));
    localStorage.setItem(
      "answersWeeklyTest",
      JSON.stringify([{ option: [[]] }])
    );
    const responseDataId = response.map((question) => question.attributes.id);

    const breadcrumb = this.breadcrumb;
    breadcrumb[3].label = meta.test_subject;
    breadcrumb[4].label = meta.test_name + " Test";

    const propPassingWTTC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingWTTC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingWTTC);

    this.setState({
      testData: response,
      currentTestData: 0,
      meta,
      answersWeeklyTest: [
        {
          is_marked: false,
          question_id: response[0].attributes.id,
          option: [[]],
        },
      ],
      questionIds: responseDataId,
    });
  };

  handleAnswersChanged = (prevState: S) => {
    let currentAnswers =
      this.state.answersWeeklyTest[this.state.currentTestData];

    const isAnswered = currentAnswers?.option?.[0]?.[0]?.length > 0;
    const isMarked = currentAnswers?.is_marked;

    if (isMarked) {
      currentAnswers = { ...currentAnswers, is_marked: true };
    } else {
      currentAnswers = { ...currentAnswers, is_marked: false };
    }

    if (isAnswered) {
      currentAnswers = { ...currentAnswers, legend: "answered" };
    }

    if (isAnswered && isMarked) {
      currentAnswers = {
        ...currentAnswers,
        legend: "answered_and_marked_for_review",
      };
    }

    if (!isAnswered && isMarked) {
      currentAnswers = {
        ...currentAnswers,
        legend: "not_answered_and_marked_for_review",
      };
    }

    if (
      prevState.answersWeeklyTest[this.state.currentTestData]?.option?.[0]?.[0]
        ?.length > 0 &&
      !currentAnswers?.option?.[0]?.[0]?.length
    ) {
      currentAnswers = {
        ...currentAnswers,
        legend: "answered_and_cleared_the_answer",
      };
    }

    if (
      prevState.answersWeeklyTest[this.state.currentTestData]?.is_marked &&
      !currentAnswers?.is_marked
    ) {
      currentAnswers = {
        ...currentAnswers,
        legend: "marked_and_un_marked_for_review",
      };
    }

    const answersWeeklyTest = this.state.answersWeeklyTest;
    answersWeeklyTest[this.state.currentTestData] = currentAnswers;

    localStorage.setItem(
      "answersWeeklyTest",
      JSON.stringify(answersWeeklyTest)
    );

    this.setState({
      answersWeeklyTest,
    });
  };

  setAnswer = (index: number, option: string[][]) => {
    const answersWeeklyTest = [...this.state.answersWeeklyTest];

    answersWeeklyTest[index] = {
      ...this.state.answersWeeklyTest[index],
      question_id: this.state.testData[index].attributes.id,
      option,
    };

    this.setState({ answersWeeklyTest });
  };

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (
      JSON.stringify(prevState.answersWeeklyTest) !==
        JSON.stringify(this.state.answersWeeklyTest) ||
      JSON.stringify(prevState.currentTestData) !==
        JSON.stringify(this.state.currentTestData)
    ) {
      this.handleAnswersChanged(prevState);
    }

    if (prevState.currentTestData !== this.state.currentTestData) {
      this.submitOverviewPerQuestion(
        this.state.answersWeeklyTest[prevState.currentTestData],
        prevState.currentTestData
      );
    }
  }

  getBtnClassName = (index: number) => {
    const current = this.state.answersWeeklyTest[index];

    if (current?.legend === "answered") {
      return "btn-answered";
    }

    if (current?.legend === "un_answered") {
      return "btn-unanswered";
    }

    if (current?.legend === "answered_and_marked_for_review") {
      return "btn-answered-marked";
    }

    if (current?.legend === "not_answered_and_marked_for_review") {
      return "btn-unanswered-marked";
    }

    if (current?.legend === "marked_and_un_marked_for_review") {
      return "btn-marked-unmarked";
    }

    if (current?.legend === "answered_and_cleared_the_answer") {
      return "btn-answered-cleared";
    }

    return "";
  };

  clearResponse = () => {
    const answersWeeklyTest = [...this.state.answersWeeklyTest];

    answersWeeklyTest[this.state.currentTestData] = {
      ...answersWeeklyTest[this.state.currentTestData],
      option: [[]],
    };

    this.setState({
      answersWeeklyTest,
    });
  };

  markForReview = () => {
    const newAnswer = [...this.state.answersWeeklyTest];

    newAnswer[this.state.currentTestData] = {
      ...newAnswer[this.state.currentTestData],
      is_marked: !newAnswer[this.state.currentTestData].is_marked,
    };

    this.setState({
      answersWeeklyTest: newAnswer,
    });
  };

  // Customizable Area Start
  submitOverviewPerQuestion = (
    prevState: {
      question_id: number;
      option: string[][];
      legend?: string;
      is_marked?: boolean;
    },
    prevIndex: number
  ) => {
    let currentAnswers = this.state.answersWeeklyTest[prevIndex];
    const isAnswered = currentAnswers?.option?.[0]?.[0]?.length > 0;
    const legend = currentAnswers?.legend;

    if (!isAnswered && legend === undefined) {
      currentAnswers = { ...currentAnswers, legend: "un_answered" };
    }

    const answersWeeklyTest = this.state.answersWeeklyTest;
    answersWeeklyTest[prevIndex] = currentAnswers;

    localStorage.setItem(
      "answersWeeklyTest",
      JSON.stringify(answersWeeklyTest)
    );

    this.setState({
      answersWeeklyTest,
    });

    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    };

    const submitOverviewMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitOverviewMessageId = submitOverviewMessage.messageId;

    submitOverviewMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_assessmenttest/practice_tests/${this.testId}/overview`
    );

    submitOverviewMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    submitOverviewMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        ...prevState,
        option: prevState.option?.[0].flat(),
      })
    );

    submitOverviewMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(submitOverviewMessage.id, submitOverviewMessage);
  };

  getTest = (testId: string) => {
    const headers = {
      token: localStorage.getItem("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getTestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getTestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/${testId}/take_test`
    );
    this.getTestMessageId = getTestMessage.messageId;

    getTestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    getTestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getTestMessage.id, getTestMessage);
  };

  submitTest = () => {
    const submitTestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: localStorage.getItem("authToken"),
    };
    const testTime = Number(localStorage.getItem("totalTestTimeWeeklyTest"));
    const totalSeconds = testTime - this.state.timeRemaining / 1000;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60);
    const questionIds = this.state?.questionIds.join(",");
    const answersWeeklyTest: { [questionId: string]: string[] } = {};

    this.submitTestMessageId = submitTestMessage.messageId;

    if (this.state.meta) {
      for (
        let indexQuestion = 0;
        indexQuestion < this.state?.questionIds.length;
        indexQuestion++
      ) {
        if (this.state.answersWeeklyTest[indexQuestion] === undefined) {
          continue;
        } else {
          if (
            this.state.answersWeeklyTest[indexQuestion].option?.flat().length >
            0
          ) {
            answersWeeklyTest[
              this.state?.questionIds[indexQuestion].toString()
            ] = this.state.answersWeeklyTest[indexQuestion].option.flat();
          }
        }
      }
    }

    submitTestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    submitTestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/${
        this.testId
      }/submit_test?a_str=${JSON.stringify(answersWeeklyTest)}&time_taken=${
        String(hours).length > 1 ? hours : "0" + hours
      }:${String(minutes).length > 1 ? minutes : "0" + minutes}:${
        String(seconds).length > 1 ? seconds : "0" + seconds
      }&question_ids=${questionIds}`
    );

    submitTestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(submitTestMessage.id, submitTestMessage);
  };
  // Customizable Area End
}
