import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Banner from "../../../components/src/Banner.web";
import { arrowCircle, searchImage } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import CourseFinderExaminationController, {
  Props,
  configJSON,
} from "./CourseFinderExaminationController.web";

class CourseFinderExamination extends CourseFinderExaminationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { loading, currentPage, examinationData } = this.state;
    const startIndex = currentPage * this.dataPerPage;
    const endIndex = Math.min(
      startIndex + this.dataPerPage,
      examinationData.length
    );

    const currentPageData = examinationData.slice(startIndex, endIndex);
    const pagesArray = Array.from(
      { length: this.state.numberOfPages },
      (_, index) => index + 1
    );

    if (loading) {
      return <Loader loading={loading} />;
    }

    return (
      // Customizable Area Start
      <Box>
        <Banner title="Exam Details" />
        <Box>
          <Box className={classes.searchContainer}>
            <Box position={"relative"}>
              <img
                src={searchImage}
                alt="search-image"
                className={classes.searchImageStyle}
              />
              <input className={classes.searchStyle} />
            </Box>
          </Box>
          <Box>
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{configJSON.examN}</TableCell>
                    <TableCell>Notification</TableCell>
                    <TableCell>Eligible Groups</TableCell>
                    <TableCell>Counsellings Linked</TableCell>
                    <TableCell>Website</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageData.map((examData) => {
                    return (
                      <TableRow>
                        <TableCell data-test-id="exam-name">
                          {examData.attributes.exam_name}
                        </TableCell>
                        <TableCell>https://notification-sample.com</TableCell>
                        <TableCell>MPC, MBiPC</TableCell>
                        <TableCell>
                          <Typography className={classes.linkedCounsellings}>
                            Counsellings Linked
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <a
                            href={examData.attributes.website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {examData.attributes.website}
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.paginationContainerStyle}>
              <Box className={classes.buttonPaginationStyle}>
                {pagesArray.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => this.changePage(pageNumber - 1)}
                    className={
                      this.state.currentPage === pageNumber - 1
                        ? "active-page"
                        : ""
                    }
                  >
                    {pageNumber}
                  </button>
                ))}
                <img
                  src={arrowCircle}
                  alt="arrow"
                  className={classes.arrowCircle}
                  onClick={() => this.changePage(currentPage + 1)}
                  data-test-id="change-page-btn"
                />
                <Typography className={classes.paginationLabel}>
                  1-{this.state.numberOfPages}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const searchInputStyle = {
  searchStyle: {
    width: "366px",
    height: "50px",
    borderRadius: "24.96px",
    border: "1px solid rgb(82, 0, 130)",
    outline: "none",
    paddingLeft: "4em",
    paddingRight: "2em",
  },
  searchContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    padding: "4em 2em",
  },
  searchImageStyle: {
    position: "absolute" as const,
    width: "24px",
    height: "24px",
    top: "12px",
    left: "24px",
  },
};

export const courseFinderGlobalStyle = {
  tableContainer: {
    width: "100%",
    minWidth: "100%",
    minHeight: "700px",
    "& .MuiTableCell-root": {
      border: "1px solid rgb(151, 151, 151)",
    },
    "& *": {
      textAlign: "center",
    },
    "& th": {
      background: "rgb(82, 0, 130)",
      color: "white",
      fontSize: "24px",
      fontWeight: 500,
      textAlign: "center",
      height: "77px",
    },
    "& td, & td *": {
      fontSize: "20px",
      fontWeight: 500,
      textAlign: "center",
    },
    "& td": {
      height: "152px",
    },
  },
  paginationContainerStyle: {
    display: "flex",
    padding: "3em 3em 3em 0",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  buttonPaginationStyle: {
    border: "1px solid rgba(255, 58, 87, 0.7)",
    borderRadius: "4px",
    position: "relative" as const,
    "& button": {
      width: "35px",
      height: "41px",
      color: "#222",
      border: "none",
      background: "white",
      fontWeight: 500,
      borderRadius: "2px",
      cursor: "pointer",
    },
    "& .active-page": {
      color: "#eee",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
    },
  },
  paginationLabel: {
    position: "absolute" as const,
    fontSize: "12px",
    left: "10px",
  },
  arrowCircle: {
    position: "absolute" as const,
    right: "-9px",
    top: "11px",
    background: "white",
    width: "15px",
    height: "15px",
    cursor: "pointer",
    borderRadius: "50%",
  },
};

export const webStyleCourseFinderExamination = () =>
  createStyles({
    ...searchInputStyle,
    ...courseFinderGlobalStyle,
    linkedCounsellings: {
      cursor: "pointer",
    },
  });

export default withStyles(webStyleCourseFinderExamination)(
  CourseFinderExamination
);
export { CourseFinderExamination };
// Customizable Area End
