import React from "react";

import {
  Box,
  // Customizable Area Start
  Button,
  Modal,
  createStyles,
  withStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { myRankLogo, mainLoginImage, party, bgLoginMain } from "./assets";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Formik } from "formik";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import AYQLoginController, { Props } from "./AYQLoginController";

export class AYQLogin extends AYQLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    if (!this.state.userInputLoaded) {
      return <Loader loading={true} />;
    }

    return (
      // Customizable Area Start
      <Box className={this.props.classes.loginbyId}>
        <Loader loading={this.state.loading} />
        <Box
          className={this.props.classes.goBackBtn}
          onClick={this.handleGoBack}
          data-testid="go-back"
        >
          <div className="back-icon">{"<"}</div>
        </Box>
        <Box className="leftscreen">
          <img src={mainLoginImage} alt="mainloginimage" />
          <Typography className="title">Ask Your Query</Typography>
          <Typography className="secondary">
            AYQ will help users to answer queries and solve doubts for questions
            user have
          </Typography>
        </Box>
        <Box className="rightscreen">
          <img className="myranklogo" src={myRankLogo} alt="myranklogo" />
          <Box className="main-rightscreen">
            <h1>Ask Your Query Login</h1>
            <Formik
              initialValues={this.state.userInput}
              onSubmit={(values) => {
                this.handleUserIdLoginSubmit(values);
              }}
              validationSchema={this.loginSchema}
              data-testid="formik"
            >
              {(props) => (
                <>
                  <form data-testid="formsubmit" onSubmit={props.handleSubmit}>
                    <input
                      data-testid="useridfield"
                      className={`input-form-myrank ${
                        (this.state.userNotFound ||
                          (props.errors.userIdInput &&
                            props.touched.userIdInput)) &&
                        "input-error"
                      }`}
                      name="userIdInput"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.userIdInput}
                      placeholder="Enter Email Id"
                      type="text"
                      style={{ paddingLeft: "1em" }}
                      spellCheck={false}
                    />
                    <Box className="passwordInput">
                      <input
                        data-testid="passwordfield"
                        className={`input-form-myrank ${
                          (this.state.passwordIncorrect ||
                            (props.errors.passwordInput &&
                              props.touched.passwordInput)) &&
                          "input-error"
                        }`}
                        type={this.state.showPassword ? "text" : "password"}
                        name="passwordInput"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.passwordInput}
                        placeholder="Enter Password"
                        style={{ paddingLeft: "1em" }}
                        spellCheck={false}
                      />
                      <Box
                        data-testid="peekbtn"
                        onClick={this.handleClickShowPassword}
                      >
                        {this.state.showPassword ? (
                          <Visibility style={{ color: "#520082" }} />
                        ) : (
                          <VisibilityOff style={{ color: "#520082" }} />
                        )}
                      </Box>
                    </Box>
                    <Button
                      style={{
                        textTransform: "none",
                        width: "100%",
                        color: "white",
                      }}
                      data-testid="submituserlogin"
                      type="submit"
                    >
                      Login
                    </Button>
                  </form>
                  <Box style={{ marginTop: "1em" }} className="rememberme">
                    <label>
                      <input
                        type="checkbox"
                        checked={this.state.rememberMe}
                        onChange={(event) =>
                          this.handleRememberMe(event, props.values)
                        }
                        className="checkbox-remember-me"
                        style={{ marginRight: "0.5em", accentColor: "#520082" }}
                        data-testid="remember-me-btn"
                      />
                      Remember me
                    </label>
                    <Button
                      style={{ textTransform: "none", color: "#FE5F56" }}
                      onClick={this.handleToForgotPassword}
                      data-testid="to-forgot"
                    >
                      Forgot password ?
                    </Button>
                  </Box>
                </>
              )}
            </Formik>
            <Box style={{ marginTop: "2em" }} className="dont-have-account">
              <p>Don't have an account?</p>
              <Button
                style={{ textTransform: "none", textDecoration: "underline" }}
                onClick={this.handleGoToSignUp}
                data-testid="to-sign-up"
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </Box>
        <Modal open={this.state.isModalOpen} onClose={this.handleCloseModal}>
          <Box sx={webStyle.modalStyle}>
            <Box
              display={"flex"}
              alignItems={"center"}
              position={"relative"}
              margin={"2em 0"}
              justifyContent={"center"}
            >
              <img
                src={party}
                alt={"party-pop"}
                style={{ position: "absolute", zIndex: 200, width: "150px" }}
              />
              <CheckCircleIcon
                style={{
                  color: "#009644",
                  fontSize: "70px",
                  zIndex: 300,
                }}
              />
            </Box>
            <p style={{ fontWeight: 500, fontSize: "18px" }}>
              Logged in Successfully !
            </p>
            <Button
              data-testid="gotodashboard"
              style={webStyle.buttonDashboard}
              onClick={this.handleGoToDashboard}
            >
              Go to Ask Your Query Dashboard
            </Button>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpenWrongCredential}
          onClose={() => this.handleCloseModalWrongCredential()}
        >
          <Box sx={webStyle.modalStyle}>
            <p style={{ fontWeight: 500, fontSize: "16px" }}>
              You have entered wrong credentials
            </p>
            <Button
              data-testid="get-credentials-btn"
              style={webStyle.buttonDashboard}
              onClick={() => this.handleGoToCredentials()}
            >
              Get Credentials
            </Button>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpenWrongPassword}
          onClose={this.handleCloseModalWrongPassword}
        >
          <Box sx={webStyle.modalStyle}>
            <p style={{ fontWeight: 500, fontSize: "16px" }}>
              You have entered wrong password
            </p>
            <Button
              style={webStyle.buttonDashboard}
              onClick={this.handleCloseModalWrongPassword}
            >
              Retry
            </Button>
            <Button
              style={{ textTransform: "none", color: "#FE5F56" }}
              onClick={this.handleToForgotPassword}
              data-testid="go-to-forgot-password"
            >
              Forgot password ?
            </Button>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonDashboard: {
    height: "50px",
    width: "250px",
    color: "white",
    fontSize: "16px",
    background:
      "linear-gradient(90deg, rgba(255,63,89,1) 0%, rgba(254,98,86,1) 100%)",
  },
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    left: "50%",
    minWidth: "450px",
    minHeight: "300px",
    width: "max-content",
    border: "2px solid #832890",
    boxShadow: 24,
    backgroundColor: "white",
    p: 4,
    borderRadius: "0.5em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
  },
};

const webStyleLoginMain = () =>
  createStyles({
    goBackBtn: {
      position: "absolute",
      color: "white",
      top: "20px",
      left: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
      cursor: "pointer",
      "& .back-icon": {
        borderRadius: "50%",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        marginRight: "0.3em",
        width: "24px",
        height: "24px",
        textAlign: "center",
      },
    },
    loginbyId: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
      backgroundSize: "90% 120%",
      backgroundImage: `url(${bgLoginMain})`,

      "@media only screen and (max-width: 1200px)": {
        "&": {
          background: "none",
          backgroundColor: "white",
        },
      },

      "& .circle-icon": {
        fontSize: "70px",
      },

      "& .leftscreen": {
        minHeight: "100vh",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "60%",

        "& .title": {
          fontSize: "2.5em",
          color: "white",
        },
        "& .secondary": {
          fontSize: "1.7em",
          color: "#aaa",
          width: "50%",
          textAlign: "center",
        },

        "@media only screen and (max-width: 1200px)": {
          "&": {
            display: "none",
          },
        },

        "& img": {
          width: "450px",
          height: "450px",
        },
      },

      "& .rightscreen": {
        width: "40%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        backgroundColor: "white",

        "& .myranklogo": {
          width: "250px",
          marginBottom: "5em",
        },

        "& .main-rightscreen": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          width: "462px",

          "& h1": {
            fontSize: "1.6em",
            fontWeight: 500,
          },

          "& .login-btns": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginTop: "4em",
            gap: "1em",

            "& button": {
              borderRadius: "3px",
              backgroundColor: "rgba(235, 226, 241, 1)",
              width: "226px",
              height: "67px",
              color: "#520082",
            },

            "& .button-chosen": {
              color: "white",
              backgroundColor: "#520082",
            },
          },

          "& form": {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: "2em",
            gap: "2em",

            "& .input-form-myrank": {
              width: "100%",
              height: "80px",
              fontSize: "19px",
              border: "1px solid #520082",
            },

            "& .input-form-myrank:focus": {
              outline: "1px solid #520082",
            },

            "& .input-error": {
              borderColor: "#ff3956",
              color: "#ff3956",
            },

            "& .input-error:focus": {
              outline: "1px solid #ff3956",
            },

            "& button": {
              margin: "0 !important",
              height: "70px",
              fontSize: "22px",
              background:
                "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
            },

            "& .passwordInput": {
              width: "100%",
              position: "relative",
              cursor: "pointer",

              "& div": {
                position: "absolute",
                right: "20px",
                top: "30%",
              },
            },
          },

          "& .dont-have-account": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "13px",

            "& button": {
              color: "#ff3956",
            },
          },

          "& .rememberme": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          },
        },

        "@media only screen and (max-width: 500px)": {
          "&": {
            width: "100%",
            fontSize: "14px",
          },

          "& .main-rightscreen": {
            width: "100% !important",
            padding: "0 1em",
          },

          "& .myranklogo": {
            width: "200px",
          },

          "& form": {
            justifyContent: "center !important",
            alignItems: "center !important",
          },
        },
      },
    },
  });

export default withStyles(webStyleLoginMain)(AYQLogin);
// Customizable Area End
