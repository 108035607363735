import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { AppointmentT } from "./BookMySlotController.web";
import storage from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pageIndex: number;
  upcomingData: AppointmentT[];
  loading: boolean;
  previousData: AppointmentT[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotBookingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUpcomingMessageId = "";
  getPreviousMessageId = "";
  status = [
    { value: "first_presentation", label: "First Presentation" },
    { value: "follow_up", label: "Follow up" },
    { value: "rejected", label: "Rejected" },
    { value: "registration_done", label: "Registration Done" },
  ];
  breadcrumb = [
    {
      link: `/dashboard/book-my-slot`,
      label: "Book my Slot",
    },
    {
      label: "Slots",
      link: `/dashboard/book-my-slot`,
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Slot Bookings",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pageIndex: 0,
      upcomingData: [],
      loading: false,
      previousData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getUpcomingMessageId && response) {
      if (response.data) {
        this.handleUpcomingData(response.data);
      }
    }

    if (apiRequestCallId === this.getPreviousMessageId && response) {
      if (response.data) {
        this.handlePreviousData(response.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getUpcoming();
    this.getPrevious();

    const propPassingBookings = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingBookings.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingBookings);
  };

  // Customizable Area Start
  pageChange = (pageIndex: number) => {
    this.setState({
      pageIndex,
    });
  };

  handleUpcomingData = (responseData: AppointmentT[]) => {
    this.setState({
      upcomingData: responseData,
    });
  };

  handlePreviousData = (responseData: AppointmentT[]) => {
    this.setState({
      previousData: responseData,
    });
  };

  getUpcoming = async () => {
    this.setState({
      loading: true,
    });
    const getUpcomingMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getUpcomingMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/cms_bookeds/upcoming_stots`
    );
    this.getUpcomingMessageId = getUpcomingMessage.messageId;
    getUpcomingMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getUpcomingMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getUpcomingMessage.id, getUpcomingMessage);
  };

  getPrevious = async () => {
    this.setState({
      loading: true,
    });
    const getPreviousMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getPreviousMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/cms_bookeds/previous_appointments`
    );
    this.getPreviousMessageId = getPreviousMessage.messageId;
    getPreviousMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPreviousMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getPreviousMessage.id, getPreviousMessage);
  };

  changeSlot = (slotId: string) => {
    this.props.navigation.history.push(
      `/dashboard/book-my-slot/change-slot?slotId=${slotId}`
    );
  };

  cancelSlot = (slotId: string) => {
    this.props.navigation.history.push(
      `/dashboard/book-my-slot/change-slot/update?slotId=${slotId}&cancelSlot=true`
    );
  };

  navigateToFeedbacks = (slotId: string) => {
    this.props.navigation.history.push(
      `/dashboard/book-my-slot/slot-bookings/feedback?slotId=${slotId}&editable=true`
    );
  };

  timeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };
  // Customizable Area End
}
