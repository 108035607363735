import React from "react";
import {
  // Customizable Area Start
  Breadcrumbs,
  Box,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// Customizable Area End

import BreadcrumbNavigationDisplayController, {
  Props,
} from "./BreadcrumbNavigationDisplayController.web";

export default class BreadcrumbNavigationDisplay extends BreadcrumbNavigationDisplayController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.props.navigation.history.location.pathname !==
          "/dashboard/home" &&
          this.state.breadcrumb.length > 0 && (
            <Box padding={"3em 2em 0.5em 2em"}>
              <Breadcrumbs
                separator={<ChevronRightIcon />}
                aria-label="breadcrumb"
              >
                {this.state.breadcrumb.map((data, index) => {
                  return (
                    <Box
                      key={`${data.label}-${index}`}
                      style={webStyle.navigation}
                      onClick={() => this.navigateTo(data.link)}
                      data-test-id="navigation-btn"
                    >
                      {data.label}
                    </Box>
                  );
                })}
              </Breadcrumbs>
            </Box>
          )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  navigation: {
    fontSize: "0.85em",
    textDecoration: "none",
    color: "#222",
    cursor: "pointer",
  },
};
// Customizable Area End
