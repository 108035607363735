import React from "react";

import {
  Box as MUIBox,
  // Customizable Area Start
  withStyles,
  Typography,
  Button,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { filterBtn, sortBtn } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import { colourStyles } from "../../../framework/src/UtilsWeb";
import Select from "react-select";
import { webStyleSwayamPrabha } from "../../videos/src/SwayamPrabha.web";
import { webStylePTGuest } from "../../CfTestManagement2/src/GuestPracticeTest.web";
import ReactPlayer from "react-player";
// Customizable Area End

import GuestSwayamPrabhaController, {
  Props,
} from "./GuestSwayamPrabhaController.web";

class GuestSwayamPrabha extends GuestSwayamPrabhaController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { subjects, chapters } = this.state;
    return (
      // Customizable Area Start
      <MUIBox>
        <Modal
          open={this.state.showModalSubmit}
          data-test-id="modal-here"
          BackdropProps={{ onClick: undefined }}
        >
          <MUIBox
            sx={webStylePTGuest.modalStyle}
            className="submit-test-question"
          >
            <Typography style={webStylePTGuest.subStyleModal}>
              To get access for real swayam prabha and more content you need to
              register.
            </Typography>
            <Button
              style={webStylePTGuest.buttonStyleYes}
              data-test-id="get-here"
              onClick={this.navigateToRegister}
            >
              Get Started
            </Button>
            <Button
              style={webStylePTGuest.buttonStyleCancel}
              data-test-id="cancel-here"
              onClick={this.showModalSubmit}
            >
              Cancel
            </Button>
          </MUIBox>
        </Modal>
        <Modal
          open={this.state.showVideoModal}
          data-test-id="modal-here"
          BackdropProps={{ onClick: undefined }}
        >
          <MUIBox
            sx={{ ...webStylePTGuest.modalStyle, width: "max-content" }}
            className="submit-test-question"
          >
            <Typography style={webStylePTGuest.headerStyleModal}>
              Demo Video Swayam Prabha
            </Typography>
            <ReactPlayer
              key={`video-lecture`}
              url={this.state.currentVideo}
              controls
            />
            <Typography style={webStylePTGuest.subStyleModal}>
              To get access for real swayam prabha and more content you need to
              register.
            </Typography>
            <Button
              style={webStylePTGuest.buttonStyleYes}
              data-test-id="get-here"
              onClick={this.navigateToRegister}
            >
              Get Started
            </Button>
            <Button
              style={webStylePTGuest.buttonStyleCancel}
              data-test-id="cancel-here"
              onClick={() => this.showModalVideo(this.state.currentVideo)}
            >
              Cancel
            </Button>
          </MUIBox>
        </Modal>
        <Modal
          open={this.state.isModalOpenFilter}
          onClose={this.handleCloseModalFilter}
        >
          <MUIBox className={this.props.classes.modalStyle}>
            <Typography variant={"h6"} style={{ fontWeight: 700 }}>
              Filter By
            </Typography>
            <MUIBox
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              gridGap={"1.5em"}
            >
              <Select
                isClearable={true}
                isSearchable={true}
                placeholder="Subject"
                data-test-id="filter-subject"
                name="subject"
                options={subjects}
                onChange={(event) =>
                  this.handleUserInputChange(event, "subject")
                }
                styles={colourStyles}
              />
              <Select
                options={chapters}
                placeholder="Chapter"
                data-test-id="filter-chapter"
                isSearchable={true}
                name="chapter"
                isClearable={true}
                onChange={(event) =>
                  this.handleUserInputChange(event, "chapter")
                }
                styles={colourStyles}
              />
            </MUIBox>
            <MUIBox alignSelf={"center"}>
              <Button
                onClick={this.handleCloseModalFilter}
                data-test-id="close-filter"
              >
                Cancel
              </Button>
              <Button data-test-id="filter-init" onClick={this.doneFilter}>
                Done
              </Button>
            </MUIBox>
          </MUIBox>
        </Modal>
        <Modal
          open={this.state.isModalOpenSort}
          onClose={this.handleCloseModalSort}
        >
          <MUIBox className={this.props.classes.modalStyle}>
            <Typography variant={"h6"} style={{ fontWeight: 700 }}>
              Sort By
            </Typography>
            <MUIBox
              flexDirection={"column"}
              width={"100%"}
              display={"flex"}
              gridGap={"1.5em"}
            >
              <Select
                isSearchable={true}
                options={chapters}
                placeholder="Chapter"
                data-test-id="sort-chapter"
                isClearable={true}
                name="chapterSort"
                onChange={(event) =>
                  this.handleUserInputChange(event, "chapterSort")
                }
                styles={colourStyles}
              />
            </MUIBox>
            <MUIBox alignSelf={"center"}>
              <Button
                onClick={this.handleCloseModalSort}
                data-test-id="close-sort"
              >
                Cancel
              </Button>
              <Button data-test-id="sort-init" onClick={this.doneSort}>
                Done
              </Button>
            </MUIBox>
          </MUIBox>
        </Modal>
        <MUIBox className={this.props.classes.bannerStyle}>
          <Typography variant="h5">Swayam Prabha</Typography>
        </MUIBox>
        <MUIBox className={this.props.classes.swayamContainer}>
          <MUIBox className={this.props.classes.aboutStyle}>
            <Typography variant="h6">About</Typography>
            <MUIBox
              dangerouslySetInnerHTML={{ __html: this.state.about }}
              component={"div"}
            />
          </MUIBox>
          <MUIBox
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            marginTop={"3em"}
          >
            <MUIBox
              display={"flex"}
              gridGap={"1em"}
              flexDirection={"row"}
              margin={"0 auto"}
            >
              {this.state.swayamButtons.map((subject, index) => {
                return (
                  <Button
                    className={this.props.classes.buttonStyle}
                    key={`${subject}-${index}`}
                    style={{
                      background:
                        this.state.activeIndex === index
                          ? "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)"
                          : "white",
                      color:
                        this.state.activeIndex === index ? "white" : "black",
                    }}
                    onClick={() =>
                      index > 0
                        ? this.showModalSubmit()
                        : this.handleActiveSubject(index)
                    }
                  >
                    {subject}
                  </Button>
                );
              })}
            </MUIBox>
            <MUIBox
              alignItems={"center"}
              gridGap={"1em"}
              display={"flex"}
              justifyContent={"center"}
            >
              <MUIBox position={"relative"}>
                <SearchIcon
                  style={{
                    top: "5px",
                    color: "#510081",
                    position: "absolute",
                    left: "4px",
                  }}
                />
                <input
                  className={this.props.classes.inputSearchStyle}
                  value={this.state.search}
                  onChange={(event) => this.handleSearch(event.target.value)}
                  onKeyDown={(event) => this.onKeyEnterPressSearch(event.key)}
                  data-test-id="search"
                  placeholder="Search"
                />
              </MUIBox>
              <img
                src={filterBtn}
                data-test-id="filter"
                onClick={this.handleOpenModalFilter}
                style={{ width: "20px", cursor: "pointer" }}
              />
              <img
                src={sortBtn}
                data-test-id="sort"
                style={{ width: "20px", cursor: "pointer" }}
                onClick={this.handleOpenModalSort}
              />
            </MUIBox>
          </MUIBox>
          <MUIBox>
            <table
              cellPadding="0"
              cellSpacing="0"
              className={this.props.classes.tableStyle}
            >
              <thead>
                <tr>
                  <th>Subject</th>
                  <th>Chapter</th>
                  <th>Class Name</th>
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                {this.state.currentSwayam.map((swayams, index) => {
                  return (
                    <tr key={`${index}-tr-swayam`}>
                      <td>{swayams.attributes.subject_name}</td>
                      <td>{swayams.attributes.chapter_name}</td>
                      <td>{swayams.attributes.class_name}</td>
                      <td>
                        <Button
                          className={this.props.classes.buttonStyleView}
                          onClick={() =>
                            index > 0
                              ? this.showModalSubmit()
                              : this.showModalVideo(swayams.attributes.url)
                          }
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </MUIBox>
        </MUIBox>
      </MUIBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(webStyleSwayamPrabha)(GuestSwayamPrabha);
export { GuestSwayamPrabha };
// Customizable Area End
