import React from "react";

import {
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
import Button from "../../../components/src/Button.web";
// Customizable Area End

import WeeklyTestAttemptsController from "./WeeklyTestAttemptsController.web";

export default class WeeklyTestAttempts extends WeeklyTestAttemptsController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.testAttemptsStyles}>
        {this.state.testsAttempted.length === 0 &&
          this.state.testsUnattempted.length === 0 && (
            <Box>
              <h4>No Test Available for this Week</h4>
            </Box>
          )}
        {this.state.testsAttempted.length > 0 && (
          <Box>
            <h2>Tests attempted</h2>
            <Box sx={webStyle.chapterContainerStyle}>
              {this.state.testsAttempted.map((attempted) => {
                return (
                  <Box style={webStyle.cardStyle}>
                    <Box
                      alignItems={"center"}
                      display={"flex"}
                      justifyContent={"flex-start"}
                    >
                      <img
                        style={webStyle.imageStyle}
                        alt={`test-${attempted.id}`}
                        src={attempted.attributes.image}
                      />
                      <p style={webStyle.cardTextStyle}>
                        {attempted.attributes.test_name}
                      </p>
                    </Box>
                    <Box sx={webStyle.buttonStyles}>
                      <Link
                        to={`/dashboard/weekly-test/test?testId=${attempted.id}`}
                      >
                        <Button
                          color="white"
                          width="121px"
                          title="Take Test"
                          height="35px"
                        />
                      </Link>
                      <Link
                        to={`/dashboard/weekly-test/reviews-weekly?reviewId=${attempted.id}&reviewChapter=${this.state.subjectId}`}
                      >
                        <Button
                          color="white"
                          width="121px"
                          title="Review Test"
                          height="35px"
                        />
                      </Link>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
        {this.state.testsUnattempted.length > 0 && (
          <Box>
            <h2>Tests Unattempted</h2>
            <Box sx={webStyle.chapterContainerStyle}>
              {this.state.testsUnattempted.map((unattempted) => {
                return (
                  <Box style={webStyle.cardStyle}>
                    <Box
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      display={"flex"}
                    >
                      <img
                        alt={`test-${unattempted.id}`}
                        style={webStyle.imageStyle}
                        src={unattempted.attributes.image}
                      />
                      <p style={webStyle.cardTextStyle}>
                        {unattempted.attributes.test_name}
                      </p>
                    </Box>
                    <Box sx={webStyle.buttonStyles}>
                      <Link
                        to={`/dashboard/weekly-test/test?testId=${unattempted.id}`}
                      >
                        <Button
                          width="121px"
                          title="Take Test"
                          color="white"
                          height="35px"
                        />
                      </Link>
                      <Link
                        to={`/dashboard/weekly-test/reviews-weekly?reviewId=${unattempted.id}&reviewChapter=${this.state.subjectId}`}
                      >
                        <Button
                          width="121px"
                          height="35px"
                          title="Review Test"
                          color="white"
                        />
                      </Link>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  cardStyle: {
    border: "1px solid rgba(255, 63, 89, 1)",
    marginTop: "2em",
    padding: "4em 0.7em",
    borderRadius: "0.5em",
    justifyContent: "center",
    gap: "1em",
    display: "flex",
    width: "420px",
    flexDirection: "column" as const,
    height: "101px",
  },
  cardTextStyle: {
    fontSize: "15px",
    fontWeight: "bold" as const,
    marginLeft: "1em",
  },
  buttonStyles: {
    display: "flex",
    gap: "2em",
    marginLeft: "3.5em",
  },
  chapterContainerStyle: {
    display: "flex",
    width: "100%",
    gap: "1em",
    flexWrap: "wrap" as const,
  },
  testAttemptsStyles: {
    padding: "2em 3em",
    display: "flex",
    flexDirection: "column",
    gap: "2em" as const,
  },
  imageStyle: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
};
// Customizable Area End
