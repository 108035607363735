import React from "react";
import { Box, Button, FormControl } from "@material-ui/core";

import ContactusController, { Props } from "./ContactusController.web";
import { myrankicon, office } from "./assets";
import {
  LocationOnOutlined,
  EmailOutlined,
  CallOutlined,
  QueryBuilderOutlined,
  SmartphoneOutlined,
  CheckCircle,
} from "@material-ui/icons";
import "./styles/page.scss";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";

const branchData = [
  {
    header: "MYRANK EDUCATION PVT LTD - BRANCH (A.P)",
    location1: "GF- 4, Anjana Apartments,",
    location2: "Water Tank Road,Labbipet,",
    mobile: "91-9392666883",
    location3: "Near Indira Gandhi Municipal Stadium,",
    email: "info@myrank.co.in",
    location4: "Vijayawada, Andhra Pradesh - 520010",
    phone: "040-49521580",
  },
  {
    location1: "D.No:47-9-24,6th Floor,",
    location4: "Visakhapatnam, Andhra Pradesh - 530016",
    header: "MYRANK EDUCATION PVT LTD - BRANCH (A.P)",
    location3: "3rd Lane, Dwarakanagar,",
    email: "info@myrank.co.in",
    phone: "040-49521580",
    mobile: "91-9959988163",
    location2: "Satya Sridevi Enclave,",
  },
  {
    email: "info@myrank.co.in",
    location1: "#3/260,2 Floor, AK Towers,",
    location4: "Bangalore, Karnataka - 560068",
    header: "MYRANK EDUCATION PVT LTD - BRANCH (KARNATAKA)",
    location2: "Near Bommanahalli Bus Stop,",
    phone: "040-49521580",
    location3: "Hosur Main Road,",
    mobile: "91-6364545903",
  },
  {
    location1: "Platinum Class,",
    mobile: "91-9546961213, 9431329590",
    email: "info@myrank.co.in",
    location3: "Akashdeep Plaza, Golmuri,",
    location4: "Jamshedpur, Jharkhand - 831003",
    phone: "040-49521580",
    location2: "UB177, AC Market,",
    header: "MYRANK EDUCATION PVT LTD - BRANCH (JHARKHAND)",
  },
];

const BranchOffices = ({
  header,
  location1,
  location2,
  location3,
  location4,
  email,
  phone,
  mobile,
}: {
  header: string;
  location1: string;
  location2: string;
  location3: string;
  location4: string;
  email: string;
  phone: string;
  mobile: string;
}) => {
  return (
    <Box
      className="branch-container"
      width={"500px"}
      height={"500px"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      border={"1px solid black"}
      borderRadius={"0.7em"}
    >
      <p className="branch-header">{header}</p>
      <Box className="branch-contacts">
        <Box>
          <LocationOnOutlined />
          <p>
            {location1}
            <br /> {location2} <br /> {location3} <br /> {location4}
          </p>
        </Box>
        <Box>
          <EmailOutlined />
          <a href="mailto:info@myrank.co.in">{email}</a>
        </Box>
        <Box>
          <CallOutlined />
          <p>{phone}</p>
        </Box>
        <Box>
          <SmartphoneOutlined />
          <p>+{mobile}</p>
        </Box>
      </Box>
    </Box>
  );
};
export default class ContactUsNew extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Box>
        {/* Customizable Area Start */}
        <Header image={myrankicon} navigation={this.props.navigation} />
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gridGap={"5em"}
          className="header-contact"
        >
          <h1>Contact US</h1>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7615.737809141466!2d78.53471693660475!3d17.370040097320373!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb98586635aea7%3A0xad34402cfd33c62d!2sMyRank%20Education%20Private%20Ltd!5e0!3m2!1sen!2sph!4v1690186766183!5m2!1sen!2sph"
            width="1300"
            height="600"
            style={{ border: 0 }}
            allowFullScreen={true}
            referrerPolicy="no-referrer-when-downgrade"
          />
        </Box>
        <Box className="head-office contact-box" margin={"2em 0"}>
          <h2>Head Office</h2>
          <Box margin={"2em 0"}>
            <img src={office} alt="office" />
            <Box>
              <Box>
                <LocationOnOutlined />
                <p>
                  MyRank Education PVT. LTD. <br /> 3rd Floor, Chaitanya
                  Chambers, Beside HP Petrol <br /> Pump, Chaitanya Puro,
                  Dilsukhnagar, Hyderabad, <br /> Telangana - 500060. Land Mark:
                  Metro Pilar No: <br /> A1568(Towards LB Nagar)
                </p>
              </Box>
              <Box>
                <EmailOutlined />
                <a href="mailto:info@myrank.co.in">info@myrank.co.in</a>
              </Box>
              <Box>
                <CallOutlined />
                <p>
                  040-49521580,
                  <br />
                  040-49521581,
                  <br />
                  040-49521582
                </p>
              </Box>
              <Box>
                <QueryBuilderOutlined />
                <p>
                  Office Timings: 9.30AM to 5.30PM
                  <br />
                  (Week Off - Tuesday)
                </p>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="branch-office contact-box" margin={"2em 0"}>
          <h2>Branch Offices</h2>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"80%"}
            flexWrap={"wrap"}
            gridGap={"2em"}
            margin={"2em 0"}
          >
            {branchData.map((branch) => {
              return <BranchOffices {...branch} />;
            })}
          </Box>
        </Box>
        <Box className="message-us contact-box" margin={"2em 0"}>
          <h2>Message Us</h2>
          <Box width={"80%"}>
            {this.state.formComplete ? (
              <Box
                height={"600px"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CheckCircle style={{ color: "#FF5D5C", fontSize: "5.5em" }} />
                <h3 style={{ color: "#FF5D5C", fontSize: "1.5em" }}>
                  Your Message has been submitted!
                </h3>
                <h4>We will get back to you shortly</h4>
              </Box>
            ) : (
              <Box
                component={"form"}
                onSubmit={(event) => this.handleContactUsSubmit(event)}
                display={"flex"}
                flexDirection={"column"}
                gridGap={"1em"}
                margin={"2em 0"}
                data-test-id="form-submit"
              >
                <FormControl>
                  <label htmlFor="name-input">Name</label>
                  <input
                    data-test-id="input"
                    id="name-input"
                    name="name"
                    value={this.state.userInput.name}
                    onChange={(event) => this.handleChangeInput(event)}
                  />
                </FormControl>
                <FormControl>
                  <label htmlFor="email-input">E-mail</label>
                  <input
                    data-test-id="input"
                    id="email-input"
                    type="email"
                    name="email"
                    value={this.state.userInput.email}
                    onChange={(event) => this.handleChangeInput(event)}
                  />
                </FormControl>
                <FormControl>
                  <label htmlFor="phone-input">Phone Number</label>
                  <input
                    data-test-id="input"
                    id="phone-input"
                    name="phone"
                    value={this.state.userInput.phone}
                    onChange={(event) => this.handleChangeInput(event)}
                  />
                </FormControl>
                <FormControl>
                  <label htmlFor="subject-input">Subject</label>
                  <input
                    data-test-id="input"
                    id="subject-input"
                    name="subject"
                    value={this.state.userInput.subject}
                    onChange={(event) => this.handleChangeInput(event)}
                  />
                </FormControl>
                <FormControl>
                  <label htmlFor="message-input">Message</label>
                  <textarea
                    data-test-id="input"
                    id="message-input"
                    name="message"
                    value={this.state.userInput.message}
                    onChange={(event) => this.handleChangeInput(event)}
                  />
                </FormControl>
                <Box
                  className="form-buttons"
                  display={"flex"}
                  alignSelf={"flex-end"}
                  marginTop={"2em"}
                  gridGap={"2em"}
                >
                  <Button onClick={this.clearForms}>Clear</Button>
                  <Button type="submit">Submit</Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Footer />
        {/* Customizable End Start */}
      </Box>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
// Customizable Area End
