import { Theme, createStyles } from "@material-ui/core";
export const styles = (theme: Theme) =>
  createStyles({
    practiceTest: {
      width: "100%",
    },
    practiceTestReview: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      minHeight: "100vh",
      padding: "2em 3em",
    },
    practiceTestTimer: {
      width: "144px",
      height: "58px",
      color: "white",
      fontWeight: "bold",
      backgroundColor: "#ff4f4f",
      fontSize: "24px",
      borderRadius: "8px",
      border: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "auto",
      "& span": {
        fontSize: "30px",
      },
    },
    questionOverview: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "74px",
      backgroundColor: "rgba(82, 0, 130, 0.08)",
      fontWeight: 500,
      fontSize: "18px",
      position: "relative",
    },
    mainContainerPracticeTest: {
      display: "flex",
      padding: "2em",
      width: "100%",
      "@media only screen and (max-width: 1200px)": {
        flexWrap: "wrap",
      },
      "& .MuiBox-root.MuiBox-root-31.submit-test-question button": {
        width: "250px !important",
        height: "50px !important",
        backgroundColor:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%) !important",
        color: "white !important",
        alignSelf: "flex-end !important",
      },
      "& .overview-practice-test": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        "& .overview-board": {
          width: "407px",
          minHeight: "331px",
          height: "max-content",
          backgroundColor: "rgb(247, 242, 248)",
          padding: "1.5em",
          borderRadius: "0.6em",
          "& h3": {
            color: "#4d4d4d",
          },
          "& .overview-btns": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
            gap: "1em",
            marginTop: "1em",
            padding: "0 0.5em",
            "& button": {
              border: "1px solid #6f6f6f",
              borderRadius: "50%",
              backgroundColor: "white",
              width: "45px",
              height: "45px",
              color: "#6f6f6f",
              fontWeight: "bold",
              fontSize: "18px",
              cursor: "pointer",
            },
            "& .btn-answered": {
              color: "white",
              backgroundColor: "#9cba58",
              border: "none",
            },
            "& .btn-unanswered": {
              color: "white",
              backgroundColor: "#4b4b4b",
              border: "none",
            },
            "& .btn-answered-marked": {
              color: "white",
              backgroundColor: "#ff8147",
              border: "none",
            },
            "& .btn-unanswered-marked": {
              color: "white",
              backgroundColor: "#dd1000",
              border: "none",
            },
            "& .btn-answered-cleared": {
              color: "white",
              backgroundColor: "#69b5ff",
              border: "none",
            },
            "& .btn-marked-unmarked": {
              color: "white",
              backgroundColor: "#d426c9",
              border: "none",
            },
          },
        },
        "& .overview-legends": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "0.8em",
          marginRight: "5em",
          marginTop: "2em",
          "& p": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#6f6f6f",
            fontSize: "18px",
          },
        },
      },
    },
    btnsPracticeTest: {
      display: "flex",
      padding: "0 2em",
      "& .submit-mark-practice-btns": {
        width: "100%",
        "& .submit-test-btn": {
          width: "156px",
          height: "59px",
          backgroundColor: "#ff5c5c",
          color: "white",
          border: "none",
          borderRadius: "0.7em",
          cursor: "pointer",
          fontSize: "18px",
          margin: "2em 1em 4em 0",
        },
      },
      "& .navigation-practice-btns": {
        width: "36%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "2em 1em 4em 0",
        "& .bookmark-clear-box": {
          width: "407px",
          display: "flex",
          justifyContent: "flex-start",
          gap: "6em",
          "& .bookmark-icon-review": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            width: "42px",
            height: "42px",
            color: "#FF803E",
            backgroundColor: "white",
            border: "1px solid #FF803E",
            cursor: "pointer",
          },
          "& .clear-icon": {
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "42px",
            height: "42px",
            color: "#C6140A",
            backgroundColor: "white",
            border: "1px solid #C6140A",
            cursor: "pointer",
            "& img": {
              width: "25px",
              height: "25px",
            },
          },
          "& .bookmark-icon-review::after": {
            content: "'Mark for review'",
            position: "absolute",
            display: "block",
            color: "#FF803E",
            fontSize: "13px",
            marginTop: "6em",
            marginLeft: "0.5em",
            fontWeight: "500",
            width: "100px",
          },
          "& .clear-icon::after": {
            content: "'Clear Responses'",
            position: "absolute",
            display: "block",
            color: "#C6140A",
            fontSize: "13px",
            marginTop: "6em",
            marginLeft: "7em",
            fontWeight: "500",
            width: "200px",
          },
        },
        "& div": {
          width: "407px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
          "& button": {
            position: "relative",
            width: "42px",
            height: "42px",
            color: "520082",
            backgroundColor: "white",
            border: "1px solid #ff6752",
            cursor: "pointer",
          },
          "& button:nth-child(1)::after": {
            content: "'Prev'",
            position: "absolute",
            display: "block",
            color: "#520082",
            fontSize: "13px",
            marginTop: "1em",
            marginLeft: "0.5em",
            fontWeight: "500",
          },
          "& button:nth-child(2)::after": {
            content: "'Next'",
            position: "absolute",
            display: "block",
            color: "#520082",
            fontSize: "13px",
            marginTop: "1em",
            marginLeft: "0.5em",
            fontWeight: "500",
          },
        },
      },
    },
    savedNotesIcon: {
      position: "absolute",
      right: "10%",
      top: "20%",
      cursor: "pointer",
      color: "black",

      "&::before": {
        content: '"Notes"',
        position: "absolute",
        top: "100%",
        left: "-40%",
        width: "100px",
        height: "30px",
      },
    },
    bookmarkIcon: {
      position: "absolute",
      top: "20%",
      right: "5%",
      cursor: "pointer",
      color: "black",

      "&::before": {
        content: '"Bookmark"',
        position: "absolute",
        top: "100%",
        left: "-100%",
        width: "100px",
        height: "30px",
      },
    },
    buttonStyleModals: {
      width: "250px",
      height: "55px",
      color: "#4F1f6d",
      background: "white",
      border: "1px solid #ffe9ed",
      borderRadius: "0.6em",
      textTransform: "none" as const,
      fontSize: "1.2em",
      "&:hover": {
        background: "#FF5D5B",
        color: "white",
      },
    },
  });
