import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Button,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import { Edit } from "./assets";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import BookMySlotAppointmentsController, {
  Props,
} from "./BookMySlotAppointmentsController.web";

class BookMySlotAppointments extends BookMySlotAppointmentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const webStyle = this.props.classes;
    const { tableDataAppointments, currentPage, itemsPerPage } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = tableDataAppointments.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    const totalPages = Math.ceil(tableDataAppointments.length / itemsPerPage);

    return (
      // Customizable Area Start
      <Box>
        <Loader
          loading={this.state.numberOfLoadingFromAPI > 0 ? true : false}
        />
        <BannerWeb title="Book my slot - Appointments" />
        <Box padding="2em">
          <Box className={webStyle.searchContainer}>
            <Typography>Search:</Typography>
            <input
              value={this.state.input}
              data-test-id="search-input"
              onChange={(event) => this.handleInput(event.target.value)}
            />
          </Box>
          <Box className={webStyle.buttonNavigationNewContainer}>
            <Box className={webStyle.buttonContainers}>
              <Button
                onClick={() => this.getAppointments("registration_done")}
                data-test-id="btn-register"
                style={this.getButtonStyle(0)}
              >
                Registered
              </Button>
              <Button
                onClick={() => this.getAppointments("follow_up")}
                data-test-id="btn-followup"
                style={this.getButtonStyle(1)}
              >
                Follow-up
              </Button>
              <Button
                onClick={() => this.getAppointments("rejected")}
                data-test-id="btn-rejected"
                style={this.getButtonStyle(2)}
              >
                Rejected
              </Button>
            </Box>
            <Button
              className={webStyle.buttonAddNew}
              onClick={() => this.navigateToAddNewSlot()}
              data-test-id="add-new-btn"
            >
              <AddCircleOutlineIcon /> Add New
            </Button>
          </Box>
          <Box minHeight={"700px"}>
            <table className={webStyle.table}>
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>Parents Name</th>
                  <th>Group</th>
                  <th>Mobile no.</th>
                  <th>Email ID</th>
                  <th>Remarks</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((appointment, index) => (
                  <tr key={`${index}-table-row-appointment`}>
                    <td>
                      <Box
                        display={"flex"}
                        alignContent={"center"}
                        justifyContent={"center"}
                        gridGap={"0.5em"}
                        textAlign={"center"}
                        height={"100%"}
                      >
                        <img
                          src={appointment.attributes.image_url}
                          alt="student"
                        />
                        <Typography>
                          {appointment.attributes.student_name}
                        </Typography>
                      </Box>
                    </td>
                    <td>{appointment.attributes.parent_name}</td>
                    <td>{appointment.attributes.group}</td>
                    <td>{appointment.attributes.mobile_number}</td>
                    <td>{appointment.attributes.email}</td>
                    <td
                      className={this.props.classes.showText}
                      onClick={() =>
                        this.navigateToUpdateFeedback(appointment.id)
                      }
                      data-test-id="show-text"
                    >
                      Show Text
                    </td>
                    <td>
                      <img
                        src={Edit}
                        alt="edit-btn"
                        onClick={() => this.editAppointment(appointment.id)}
                        data-test-id="edit-btn"
                        className={this.props.classes.editBtn}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          <Box
            display="flex"
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Pagination
              data-test-id="pagination"
              count={totalPages}
              page={this.state.currentPage}
              onChange={(_: unknown, value: number) =>
                this.handlePageChange(value)
              }
              className={this.props.classes.muiPaginationStyle}
            />
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const muiPaginationstyle = {
  borderRadius: "4px",
  position: "relative" as const,
  width: "max-content",
  border: "1px solid rgba(255, 58, 87, 0.7)",
  "& li": {
    color: "white",
    background: "white",
    borderRadius: "2px",
    width: "35px",
    height: "41px",
  },
  "& li:nth-child(1)": {
    zIndex: 999,
    left: -20,
    top: 10,
    position: "absolute" as const,
    background: "transparent",
    "& button": {
      height: "max-content",
      width: "max-content",
    },
    "& button svg": {
      "& path": {
        fill: "white",
      },
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      borderRadius: "50%",
    },
    "& button.Mui-disabled": {
      display: "none",
    },
  },
  "& li:nth-last-child(1)": {
    background: "transparent",
    position: "absolute",
    zIndex: 999,
    right: -20,
    top: 10,
    "& button": {
      height: "max-content",
      width: "max-content",
    },
    "& button svg": {
      "& path": {
        fill: "white",
      },
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      borderRadius: "50%",
    },
    "& button.Mui-disabled": {
      display: "none",
    },
  },
  "& li button:hover": {
    background: "none",
  },
  "& li button": {
    height: "100%",
    width: "100%",
    alignItems: "center",
    margin: 0,
    padding: 0,
    background: "none",
    display: "flex",
    justifyContent: "center",
  },
  "& li div": {
    background: "none",
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    width: "100%",
  },
  "& li button.Mui-selected": {
    borderRadius: "2px",
    fontWeight: 500,
    color: "white",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
  },
};

export const webStyleBookMySlotAppointments = () =>
  createStyles({
    searchContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "2em 0",
      "& p": { marginRight: "1em" },
      "& input": {
        width: "300px",
        height: "30px",
        border: "1px solid #bbb",
        borderRadius: "0.3em",
      },
    },
    buttonNavigationNewContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "2em",
      width: "100%",
      marginBottom: "3em",
    },
    buttonContainers: {
      width: "100%",
      height: "67px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      background: "#eee",
      border: "none",
      borderRadius: "0.5em",
      "& button": {
        fontSize: "18px",
        height: "100%",
        transition: "all 0.3s ease",
        "&:hover": {
          color: "rgb(82, 0, 130)",
          borderBottom: "4px solid rgb(82, 0, 130)",
          borderBottomRightRadius: "0",
          borderBottomLeftRadius: "0",
        },
      },
    },
    buttonAddNew: {
      width: "178px",
      height: "67px",
      background:
        "linear-gradient(90deg, rgba(254, 98, 86, 1) 0%, rgba(255, 63, 89, 1) 100%)",
      borderRadius: "0.7em",
      fontWeight: 500,
      color: "white",
    },
    table: {
      tableLayout: "fixed",
      width: "100%",
      textAlign: "center",
      borderCollapse: "collapse",
      "& thead": {
        background:
          "linear-gradient(90deg, rgba(254, 98, 86, 1) 0%, rgba(255, 63, 89, 1) 100%)",
        "& tr th": {
          color: "white",
          padding: "1em 0.5em",
          width: "100%",
          fontWeight: 500,
        },
      },
      "& tbody": {
        "& tr td": {
          overflowWrap: "break-word",
          wordWrap: "break-word",
          padding: "1em 0.5em",
          width: "100%",
          fontWeight: 500,
          height: "max-content",
          minHeight: "50px",
          "& div img": {
            height: "40px",
            width: "40px",
            border: "none",
            borderRadius: "50%",
          },
          "& div p": {
            fontWeight: 500,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      },
    },
    editBtn: {
      cursor: "pointer",
      height: "25px",
      width: "25px",
    },
    muiPaginationStyle: { ...muiPaginationstyle },
    showText: {
      fontWeight: 700,
      color: "#460C72",
      cursor: "pointer",
    },
  });
export default withStyles(webStyleBookMySlotAppointments)(
  BookMySlotAppointments
);
export { BookMySlotAppointments };
// Customizable Area End
