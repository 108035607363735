import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface Root {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  test_name: string;
  test_subject: string;
  test_category: string;
  no_of_questions: number;
  duration: number;
  start_time: string;
  end_time: string;
  status: string;
  test_submitted_already: boolean;
  image: any;
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  testsAttempted: Root[];
  testsUnattempted: Root[];
  token: string;
  subjectId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WeeklyTestAttemptsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTestsMessageId = "";
  token = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      testsAttempted: [],
      testsUnattempted: [],
      token: "",
      subjectId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getTestsMessageId) {
      if (response.data) {
        this.handleSuccessfulChapterFetchData(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidUpdate = (prevProps: Props, prevState: S) => {
    const windowUrl = window.location.href;
    const urlParams = new URLSearchParams(windowUrl.split("?")[1]);
    const subjectId = urlParams.get("subjectId");

    if (subjectId !== prevState.subjectId) {
      this.handleSubjectIdChange();
    }
  };

  handleSuccessfulChapterFetchData = (response: Root[]) => {
    const currentDateTime = new Date();

    const attempted = response.filter((test) => {
      const startTime = new Date(test.attributes.start_time);
      const endTime = new Date(test.attributes.end_time);

      return (
        test.attributes.test_submitted_already &&
        currentDateTime >= startTime &&
        currentDateTime <= endTime
      );
    });

    const unattempted = response.filter((test) => {
      const startTime = new Date(test.attributes.start_time);
      const endTime = new Date(test.attributes.end_time);

      return (
        !test.attributes.test_submitted_already &&
        currentDateTime >= startTime &&
        currentDateTime <= endTime
      );
    });

    this.setState({
      testsAttempted: attempted,
      testsUnattempted: unattempted,
    });
  };

  componentDidMount = async () => {
    const windowUrl = window.location.href;
    const urlParams = new URLSearchParams(windowUrl.split("?")[1]);
    const subjectId = urlParams.get("subjectId");
    if (subjectId) {
      this.setState(
        {
          subjectId,
        },
        () => {
          this.getTests(this.state.subjectId);
        }
      );
    }
  };
  // Customizable Area Start
  getTests = (subjectId: string) => {
    const headers = {
      token: this.token,
    };

    const getTestsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTestsMessageId = getTestsMessage.messageId;

    getTestsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/get_tests?subject_name=${subjectId}`
    );

    getTestsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getTestsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getTestsMessage.id, getTestsMessage);
  };

  handleSubjectIdChange = () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      this.token = token;
    }

    const windowUrl = window.location.href;
    const urlParams = new URLSearchParams(windowUrl.split("?")[1]);
    const subjectId = urlParams.get("subjectId");
    if (subjectId) {
      this.setState(
        {
          subjectId,
        },
        () => {
          this.getTests(this.state.subjectId);
        }
      );
    }
  };
  // Customizable Area End
}
