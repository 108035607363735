import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { WithStyles } from "@material-ui/core";

type MaterialNotesType = {
  id: string;
  type: string;
  attributes: {
    id: number;
    subject_name: string;
    topic_name?: string;
    chapter_name?: string;
    academic_year: string;
    conducted_by: string;
    content: string;
    file: string;
    created_at: string;
    updated_at: string;
  };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentId: string;
  currentType: string;
  currentTable: MaterialNotesType[];
  currentlyDisplayed: MaterialNotesType | null;
  currentPage: number;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LiveClassMaterialController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNotesMessageId = "";
  getMaterialsMessageId = "";
  breadcrumb = [
    {
      label: "Live Classes",
      link: "/dashboard/classes/live-class",
    },
    {
      label: "Subject",
      link: this.props.navigation.history.location.pathname,
    },
    {
      label: "Material / Notes",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  getMaterialsDisplayMessageId = "";
  getNoteDisplayMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentId: "",
      currentType: "",
      currentTable: [],
      currentlyDisplayed: null,
      loading: false,
      currentPage: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      apiRequestCallId === this.getMaterialsMessageId ||
      apiRequestCallId === this.getNotesMessageId
    ) {
      if (response.data) {
        this.handleFetchSuccess(response.data);
      }
    }

    if (
      (apiRequestCallId === this.getMaterialsDisplayMessageId ||
        apiRequestCallId === this.getNoteDisplayMessageId) &&
      response
    ) {
      if (response.data) {
        this.handleFetchSpecificSuccess(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingLCM = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingLCM.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingLCM);
    const currentId = this.props.navigation.getParam("id");
    const type = this.props.navigation.getParam("type");

    this.handleParams(currentId, type);
  };

  componentDidUpdate = (prevProps: Props, _: S) => {
    const currentId = this.props.navigation.getParam("id");

    if (currentId !== prevProps.navigation.getParam("id")) {
      const type = this.props.navigation.getParam("type");
      this.handleChangeId(currentId, type);
    }
  };

  handleFetchSuccess = (currentTable: MaterialNotesType[]) => {
    let currentPage = 1;

    let currentlyDisplayed: null | MaterialNotesType = null;

    for (let current = 0; current < currentTable.length; current++) {
      if (currentTable[current].id === this.state.currentId) {
        currentPage = current + 1;
        currentlyDisplayed = currentTable[current];
        break;
      }
    }

    const type = this.props.navigation.getParam("type");

    const breadcrumb = this.breadcrumb;
    breadcrumb[1].label = currentlyDisplayed?.attributes.subject_name as string;

    if (type === "note") {
      breadcrumb[2].label = "Notes";
    } else {
      breadcrumb[2].label = "Materials";
    }

    const propPassingLCM = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingLCM.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingLCM);

    this.setState({
      currentTable,
      currentlyDisplayed,
      currentPage,
      loading: false,
    });
  };

  handleFetchSpecificSuccess = (response: MaterialNotesType) => {
    let currentPage = 1;
    let table = this.state.currentTable;
    for (let current = 0; current < table.length; current++) {
      if (table[current].id === this.state.currentId) {
        currentPage = current + 1;
        break;
      }
    }

    const type = this.props.navigation.getParam("type");

    const breadcrumb = this.breadcrumb;
    breadcrumb[1].label = response?.attributes.subject_name;

    if (type === "material") {
      breadcrumb[2].label = "Materials";
    } else {
      breadcrumb[2].label = "Notes";
    }

    const propPassingLCM = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingLCM.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingLCM);

    this.setState({
      currentlyDisplayed: response,
      currentPage,
      loading: false,
    });
  };

  handlePageNumber = (value: number) => {
    const type = this.state.currentType;

    this.props.navigation.navigate("LiveClassMaterial", {
      type,
      id: this.state.currentTable[value - 1].id,
    });
  };

  handleParams = (id: string, type: string) => {
    this.setState(
      {
        currentId: id,
        currentType: type,
        loading: true,
      },
      () => {
        if (type === "material") {
          this.getMaterials();
        }

        if (type === "note") {
          this.getNotes();
        }
      }
    );
  };

  handleChangeId = (paramId: string, type: string) => {
    this.setState(
      {
        currentId: paramId,
        currentType: type,
        loading: true,
      },
      () => {
        if (type === "material") {
          this.handleDisplayedMaterials();
        }

        if (type === "note") {
          this.handleDisplayedNote();
        }
      }
    );
  };

  handleDisplayedMaterials = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getMaterialsDisplayMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMaterialsDisplayMessageId = getMaterialsDisplayMessage.messageId;

    getMaterialsDisplayMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/materials/${this.state.currentId}`
    );

    getMaterialsDisplayMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getMaterialsDisplayMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getMaterialsDisplayMessage.id,
      getMaterialsDisplayMessage
    );
  };

  handleDisplayedNote = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getNoteDisplayMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNoteDisplayMessageId = getNoteDisplayMessage.messageId;

    getNoteDisplayMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/live_class_notes/${this.state.currentId}`
    );

    getNoteDisplayMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getNoteDisplayMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getNoteDisplayMessage.id, getNoteDisplayMessage);
  };

  // Customizable Area Start
  getNotes = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNotesMessageId = getNotesMessage.messageId;

    getNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    getNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/live_class_notes`
    );

    runEngine.sendMessage(getNotesMessage.id, getNotesMessage);
  };

  getMaterials = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getMaterialsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getMaterialsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/materials`
    );

    this.getMaterialsMessageId = getMaterialsMessage.messageId;

    getMaterialsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    getMaterialsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getMaterialsMessage.id, getMaterialsMessage);
  };
  // Customizable Area End
}
