import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
import { Chart } from "react-google-charts";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

import UsageStatsGrandTestController, {
  configJSON,
  Props,
} from "./UsageStatsGrandTestController.web";

class UsageStatsGrandTest extends UsageStatsGrandTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderOverallChart = () => {
    if (this.state.stats) {
      const dataOverall = [
        ["Category", "Percentage"],
        ["Correct Answers", this.state.stats.exams_overall.correct_answer],
        ["Wrong Answers", this.state.stats.exams_overall.incorrect_answer],
        ["Unattempted Questions", this.state.stats.exams_overall.unattended],
      ];

      const options = {
        slices: {
          0: { color: "#5407E0" },
          1: { color: "#681D74" },
          2: { color: "#8E2DE2" },
        },
        background: "none",
      };

      return (
        <Box>
          <Typography className={this.props.classes.typoCenterBold}>
            {this.state.selectedPackage} overall test taken -{" "}
            {this.state.stats.exams_overall.exam_wise_overall_attempts}
          </Typography>
          <Chart
            data={dataOverall}
            chartType="PieChart"
            height={"700px"}
            width={"100%"}
            options={options}
            className={this.props.classes.chart}
          />
        </Box>
      );
    }

    return <></>;
  };

  RenderExamsOverAllChart = () => {
    const examsStat = this.state.stats;

    if (examsStat) {
      const dataOverall = [
        ["Category", "Percentage"],
        [
          "Correct Answers",
          examsStat.exam_wise.reduce(
            (accumulator, current) => accumulator + current.correct_answer,
            0
          ),
        ],
        [
          "Wrong Answers",
          examsStat.exam_wise.reduce(
            (accumulator, current) => accumulator + current.incorrect_answer,
            0
          ),
        ],
        [
          "Unattempted Questions",
          examsStat.exam_wise.reduce(
            (accumulator, current) => accumulator + current.unattended,
            0
          ),
        ],
      ];

      return (
        <Box>
          <Chart
            chartType="PieChart"
            data={dataOverall}
            width={"100%"}
            height={"700px"}
            className={this.props.classes.chart}
          />
        </Box>
      );
    }

    return <></>;
  };

  RenderSubjectOverallChart = () => {
    if (this.state.stats) {
      const dataSubjectTestOverall = [
        ["", "Correct", "Wrong", "Unattempted"],
        ...this.state.stats.subject_wise.map((subject) => {
          return [
            subject.subject_name,
            subject.correct_answer,
            subject.incorrect_answer,
            subject.unattended,
          ];
        }),
      ];

      return (
        <Box>
          <Chart
            chartType="Bar"
            data={dataSubjectTestOverall}
            width={"100%"}
            height={"400px"}
            className={this.props.classes.chart}
          />
        </Box>
      );
    }

    return <></>;
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box marginTop="2em" padding="2em">
          <Box className={this.props.classes.packageSelections}>
            {this.state.packages.map((packageData) => {
              return (
                <button
                  data-test-id="select-btn"
                  onClick={() =>
                    this.onChangeSelectPackage(packageData.gt_code)
                  }
                  key={packageData.id}
                >
                  {packageData.package_name}
                </button>
              );
            })}
          </Box>

          {this.RenderOverallChart()}

          <Box position={"relative"}>
            <select
              onChange={(event) =>
                this.onChangeSelectSubject(event.target.value)
              }
              value={this.state.selectedChoice}
              data-test-id="select-btn-choice"
              className={this.props.classes.selectStyle}
            >
              <option value="" disabled hidden>
                {configJSON.subjectNotSelected}
              </option>
              <option value=""> </option>
              {this.state.stats?.exam_wise.map((subject) => {
                return (
                  <option key={subject.grand_test} value={subject.grand_test}>
                    {subject.grand_test}
                  </option>
                );
              })}
            </select>
            <ExpandMoreIcon className={this.props.classes.expandMoreIcon} />
          </Box>
          {this.state.selectedChoice && this.RenderExamsOverAllChart()}
          {this.state.selectedChoice && this.RenderSubjectOverallChart()}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleUsageStatsGrandTest = () =>
  createStyles({
    chart: {
      "& rect": {
        fill: "#FAFAFA!important",
      },
    },
    packageSelections: {
      width: "100%",
      background: "#eee",
      display: "flex",
      alignItems: "center",
      height: "max-content",
      padding: "1.3em",
      minHeight: "150px",
      justifyContent: "center",
      gap: "3em",
      marginBottom: "3em",
      "& button": {
        fontWeight: 500,
        fontSize: "1.4em",
        cursor: "pointer",
        border: "none",
        color: "#808080",
      },
    },
    selectStyle: {
      fontSize: "1.4em",
      WebkitAppearance: "none",
      outline: 0,
      background: "rgb(250,231,231)",
      height: "70px",
      width: "100%",
      paddingLeft: "1.5em",
      border: "none",
      marginTop: "1.3em",
      fontWeight: 500,
      color: "#5C0F89",
    },
    typoCenterBold: {
      fontSize: "1.5em",
      fontWeight: 700,
      textAlign: "center",
    },
    expandMoreIcon: {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      color: "white",
      position: "absolute",
      borderRadius: "50%",
      right: "30px",
      cursor: "pointer",
      top: "47px",
    },
  });
export default withStyles(webStyleUsageStatsGrandTest)(UsageStatsGrandTest);
export { UsageStatsGrandTest };
// Customizable Area End
