import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { AppointmentT } from "./BookMySlotStudentAppointmentsController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  slotId: string;
  slotDetails: AppointmentT | null;
  starsIndex: number;
  comments: string;
  isSlotUpdatedModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotStudentFeedbacksController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStudentSlotOfIdMessageId = "";
  postStudentFeedbackMessageId = "";
  status = [
    { value: "first_presentation", label: "First Presentation" },
    { value: "follow_up", label: "Follow up" },
    { value: "rejected", label: "Rejected" },
    { value: "registered", label: "Registration Done" },
  ];
  breadcrumb = [
    {
      link: `/dashboard/book-my-slot-student`,
      label: "Book my Slot",
    },
    {
      label: "Bookings",
      link: `/dashboard/book-my-slot-student`,
    },
    {
      link: `/dashboard/book-my-slot-student/appointments`,
      label: "Appointments",
    },
    {
      label: "Feedback",
      link:
        this.props.navigation.history.location.pathname +
        this.props.navigation.history.location.search,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      slotId: "",
      slotDetails: null,
      starsIndex: -1,
      comments: "",
      isSlotUpdatedModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getStudentSlotOfIdMessageId) {
      if (response?.data) {
        this.successFetch(response.data);
      }
    }

    if (apiRequestCallId === this.postStudentFeedbackMessageId) {
      if (response?.id) {
        this.successPostMessage();
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const search = this.props.navigation.history.location.search;
    const params = new URLSearchParams(search);

    const propPassingFeedback = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingFeedback.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingFeedback);

    if (search) {
      const slotId = params.get("slotId") as string;

      return this.getStudentSlotOfId(slotId);
    }

    this.props.navigation.history.goBack();
  };

  setComments = (comments: string) => {
    this.setState({
      comments,
    });
  };

  handleRatedStars = (index: number) => {
    this.setState((prev) => ({
      ...prev,
      starsIndex: index,
    }));
  };

  successFetch = (slotDetails: AppointmentT[]) => {
    let slotDetailsFiltered = slotDetails.filter(
      (slot) => slot.id === this.state.slotId
    )[0];

    this.setState({
      slotDetails: slotDetailsFiltered,
      comments: slotDetailsFiltered.attributes.student_feedback || "",
      loading: false,
      starsIndex: slotDetailsFiltered.attributes.student_status
        ? slotDetailsFiltered.attributes.student_status - 1
        : 1,
    });
  };

  // Customizable Area Start
  successPostMessage = () => {
    this.handleIsSlotUpdatedModal();
  };

  handleIsSlotUpdatedModal = () => {
    this.setState({
      loading: false,
      isSlotUpdatedModal: !this.state.isSlotUpdatedModal,
    });
  };

  getStudentSlotOfId = async (slotId: string) => {
    const getStudentSlotOfIdMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
      slotId,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getStudentSlotOfIdMessageId = getStudentSlotOfIdMessage.messageId;

    getStudentSlotOfIdMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.studentAppointment
    );

    getStudentSlotOfIdMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getStudentSlotOfIdMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getStudentSlotOfIdMessage.id,
      getStudentSlotOfIdMessage
    );
  };

  postStudentFeedback = async () => {
    const postStudentFeedbackMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const body = {
      data: {
        rating: this.state.starsIndex + 1,
        remarks: this.state.comments,
        call_status: "call_status",
      },
    };

    this.postStudentFeedbackMessageId = postStudentFeedbackMessage.messageId;

    postStudentFeedbackMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/bms_bookeds/${this.state.slotId}/feedback`
    );

    postStudentFeedbackMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    postStudentFeedbackMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    postStudentFeedbackMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(
      postStudentFeedbackMessage.id,
      postStudentFeedbackMessage
    );
  };

  navigateToBookings = () => {
    this.props.navigation.history.push("/dashboard/book-my-slot-student/appointments");
  };
  // Customizable Area End
}
