Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentManagement";
exports.labelBodyText = "ContentManagement Body";
exports.btnExampleTitle = "CLICK ME";
exports.selectSubject = "Select subject";
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.SortAPiEndPoint = "sorting/sorting";
exports.SortAscAPiEndPoint = "sorting/sorting?sort[order_by]=price&sort[direction]=asc";
exports.SortDescAPiEndPoint = "sorting/sorting?sort[order_by]=price&sort[direction]=desc";
exports.SortNewestAPiEndPoint = "sorting/sorting?sort[order_by]=created_at&sort[direction]=desc";
exports.SortPopularityAPiEndPoint = "sorting/sorting?sort[order_by]=average_rating&sort[direction]=desc";
exports.bookmarkTopicEndPoint = "/bx_block_content_management/bookmarks";


exports.deactive =  "Deactivated"
exports.deactivePackageEndPoint = "/bx_block_plan/packages/{id}/deactive_package"
exports.ContactUS = "Contact Us"
exports.InfoLabel = "Contact us to add more Package"
exports.active = "Active"
exports.putmethod = "PUT"
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.headerLabel = 'My Package'
exports.myPackageEndPoint = "/account_block/profile_settings/my_packages"
exports.activePageEndPoint = "/bx_block_plan/packages/{id}/active_package"
exports.titleLabel = "Package"
exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.packagesLabel = "Package Level";
exports.askYourQuerry = "Ask Your Querry";
exports.LiveClass = "Live Classes";
exports.ExpiryDate = "Expiry date";
exports.AmountPaid = "Amount Paid";
exports.RegisterID = "Register ID";
exports.rankID = "My Rank ID";
exports.studentName = "Student Name";
exports.mobileNumber= "Mobile Number";
exports.errorTitle = "Error";
exports.rDetailsLabel = "Registration Details";
exports.errorAllFieldsAreMandatory = "Please enter a category first";
exports.errorCategory = "Please select a category first";
exports.Token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6Mzc0LCJleHAiOjE2OTQ1ODM0MDksInRva2VuX3R5cGUiOiJsb2dpbiJ9.lXRqndp38qwSMxUVYfxIy_74e3GucylJXqpPt11LLGXrB7fumCFz8gMVeg4tgC0JTehLbGuRg4sj6dkYUPh-DQ";
exports.getbookmarksEndPoint = "/bx_block_content_management/bookmarks?topic_id=";
exports.guestUserSynopsisEndPoint = "bx_block_assessmenttest/guestsynopsis/get_subject?pack_final_id={packageID}";
exports.guestUserSynopsisChapterEndPoint = "bx_block_assessmenttest/guestsynopsis/";
exports.guestSynopsisEndChapter = "/subject_chapter"
exports.registrstionText = "To get access for all subjects and more \n content you need to register";
exports.guestUserSynopsisTopicEndPoint = "/bx_block_assessmenttest/guestsynopsis/"
exports.guestUserSynopsisTopicEndPart = "/chapter_topic";
exports.GuestPagesEndPoint = "/bx_block_assessmenttest/guestsynopsis/"
exports.GuestPageEndPart = "/chapter_topic"
exports.guest = "Guest"
exports.Register = "Register"
// Customizable Area End