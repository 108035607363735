import React from "react";

import {
  Box,
  // Customizable Area Start
  createStyles,
  withStyles,
  Modal,
  Typography,
  Button as MUIButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SearchIcon from "@material-ui/icons/Search";
import Select, { OptionTypeBase, StylesConfig } from "react-select";
import Pagination from "@material-ui/lab/Pagination";
interface OptionType extends OptionTypeBase {
  value: string;
  label: string;
}
import { filterBtn, sortBtn } from "./assets";
import Button from "../../../components/src/Button.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import WeeklyTestTakeTestReviewController, {
  Props,
} from "./WeeklyTestTakeTestReviewController.web";

class WeeklyTestTakeTestReview extends WeeklyTestTakeTestReviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const colourStyles: StylesConfig<OptionType, false> = {
      input: (styles) => ({
        ...styles,
        color: "#510081",
      }),
      singleValue: (styles) => ({
        ...styles,
        color: "#510081",
      }),
      control: (styles) => ({
        ...styles,
        borderRadius: "0.5em",
        border: "1px solid #FF6B55",
        height: "51px",
        "&:hover": {
          outline: "none",
        },
        boxShadow: "none",
      }),
      placeholder: (styles) => ({
        ...styles,
        color: "#510081",
      }),
    };

    const { tests, currentTestsPage } = this.state;

    const itemsPerPage = 10;
    const indexOfLastItem = currentTestsPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = tests.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(tests.length / itemsPerPage);

    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box sx={webStyle.bannerStyle}>
          <h2>Weekly Test</h2>
        </Box>
        <Box padding={"2em"}>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gridGap={"1.2em"}
          >
            <Box position={"relative"}>
              <SearchIcon
                style={{
                  position: "absolute",
                  left: "4px",
                  top: "5px",
                  color: "#510081",
                }}
              />
              <input
                className={this.props.classes.inputSearchStyle}
                data-test-id="search"
                placeholder="Search"
                onKeyDown={(event) => this.onKeyEnterPressSearch(event.key)}
                value={this.state.search}
                onChange={(event) => this.handleSearch(event.target.value)}
              />
            </Box>
            <img
              src={filterBtn}
              onClick={this.handleModalFilter}
              style={{ width: "20px", cursor: "pointer" }}
              data-test-id="filter-img-btn"
            />
            <img
              src={sortBtn}
              onClick={this.handleModalSort}
              style={{ width: "20px", cursor: "pointer" }}
              data-test-id="sort-img-btn"
            />
          </Box>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <table
              cellPadding="0"
              className={this.props.classes.tableStyle}
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>Subject</th>
                  <th>Test name</th>
                  <th>Date</th>
                  <th>Timings</th>
                  <th>Take Test</th>
                  <th>Review Test</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((test, index) => {
                  const created = this.handleDate(test.attributes.created_at);
                  const start = this.handleDays(test.attributes.start_time);
                  return (
                    <tr key={`${index}-tr-swayam`}>
                      <td>{test.attributes.test_subject}</td>
                      <td>{test.attributes.test_name}</td>
                      <td>{created}</td>
                      <td>{start}</td>
                      <td>
                        <Box
                          data-test-id="navigate-test"
                          onClick={() => this.navigateToTest(test.id)}
                        >
                          <Button
                            color="white"
                            height="40px"
                            width="120px"
                            title="Take Test"
                          />
                        </Box>
                      </td>
                      <td>
                        <MUIButton
                          className={this.props.classes.buttonStyleReview}
                          data-test-id="navigate-review"
                          onClick={() => this.navigateToReview(test.id)}
                        >
                          Review
                        </MUIButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
          <Box
            display="flex"
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            marginBottom={"5em"}
          >
            <Pagination
              data-test-id="pagination"
              count={totalPages}
              page={currentTestsPage}
              onChange={(_: unknown, value: number) =>
                this.handleTestPage(value)
              }
              className={this.props.classes.muiPaginationStyle}
            />
          </Box>
        </Box>
        <Modal
          open={this.state.isModalOpenFilter}
          onClose={this.handleModalFilter}
        >
          <Box className={this.props.classes.modalStyle}>
            <Typography variant={"h6"} style={{ fontWeight: 700 }}>
              Filter By
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              gridGap={"1.5em"}
            >
              <Select
                data-test-id="filter-subject"
                isSearchable={true}
                options={this.state.option.testName}
                isClearable={true}
                name="testName"
                onChange={(event) => this.handleUserInputChangeCurrent(event)}
                placeholder="Test Name"
                styles={colourStyles}
              />
              <input
                name="currentDate"
                type="date"
                data-test-id="filter-date"
                value={this.state.userInput.currentDate}
                onChange={(event) => this.handleUserInputChange(event)}
                placeholder="Date"
              />
            </Box>
            <Box alignSelf={"center"}>
              <MUIButton
                onClick={this.handleModalFilter}
                data-test-id="close-filter"
              >
                Cancel
              </MUIButton>
              <MUIButton data-test-id="filter-init" onClick={this.doneFilter}>
                Done
              </MUIButton>
            </Box>
          </Box>
        </Modal>
        <Modal open={this.state.isModalSort} onClose={this.handleModalSort}>
          <Box className={this.props.classes.modalStyle}>
            <Typography variant={"h6"} style={{ fontWeight: 700 }}>
              Sort By
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              gridGap={"1.5em"}
            >
              <label>
                <input
                  data-test-id="recently-added"
                  type="checkbox"
                  className={this.props.classes.checkbox}
                  checked={this.state.selectedOption === "recently_added"}
                  onChange={() => this.handleCheckboxChange("recently_added")}
                />
                <p>Recently added</p>
              </label>
              <label>
                <input
                  data-test-id="most-viewed"
                  type="checkbox"
                  className={this.props.classes.checkbox}
                  checked={this.state.selectedOption === "test_name"}
                  onChange={() => this.handleCheckboxChange("test_name")}
                />
                <p>Test Name</p>
              </label>
            </Box>
            <Box alignSelf={"center"}>
              <MUIButton
                data-test-id="close-sort"
                onClick={this.handleModalSort}
              >
                Cancel
              </MUIButton>
              <MUIButton data-test-id="sort-init" onClick={this.doneSort}>
                Done
              </MUIButton>
            </Box>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  bannerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    color: "#222",
  },
};

export const styles = () =>
  createStyles({
    weeklyTestClass: {
      display: "flex",
      padding: "1em",
    },
    buttonStyleReview: {
      border: "1.5px solid #FD3959",
      padding: "0.5em 2em",
      borderRadius: "0.7em",
    },
    tableStyle: {
      width: "100%",
      textAlign: "left",
      margin: "4em 0",
      tableLayout: "fixed",
      "& thead": {
        border: "1px solid #E8E8E8",
        background: "#E8E8E8",
        "& tr th:last-child": {
          borderTopRightRadius: "0.7em",
        },
        "& tr th:nth-child(1)": {
          borderTopLeftRadius: "0.7em",
          paddingLeft: "1.2em",
        },
        "& tr th": {
          padding: "1.2em 0",
        },
      },
      "& tbody": {
        "& tr:last-child td:nth-child(1)": {
          borderBottomLeftRadius: "0.7em",
        },
        "& tr td": {
          padding: "1.2em 0",
          wordWrap: "break-word",
          overflowWrap: "break-word",
        },
        "& tr:last-child td:last-child": {
          borderBottomRightRadius: "0.7em",
        },
        "& tr:nth-child(even)": {
          background: "#E8E8E8",
        },
        "& tr td:nth-child(1)": {
          paddingLeft: "1.2em",
        },
      },
    },
    buttonStyle: {
      border: "1px solid #BE888E",
      borderRadius: "0.6em",
      "&:hover": {
        color: "white",
        background: "#FF5D5B",
      },
      textTransform: "none" as const,
      height: "50px",
      padding: "0 2em",
      width: "max-content",
      color: "#4F1f6d",
    },
    modalStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      width: 500,
      height: "300px",
      flexDirection: "column",
      left: "50%",
      border: "2px solid #832890",
      padding: "2em",
      backgroundColor: "white",
      justifyContent: "space-between",
      gap: "1em",
      borderRadius: "0.5em",
      textAlign: "left",
      outline: "none",
      "& input, & label": {
        width: "100%",
        border: "1px solid #FF6B55",
        outline: "none" as const,
        padding: "1em",
        color: "#510081",
        borderRadius: "0.5em",
        "&::placeholder": {
          color: "#510081",
        },
      },
      "& label": {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-start",
      },
      "& label p": {
        fontSize: "1.2em",
        fontWeight: 500,
      },
      "& label input": {
        height: "20px",
        width: "20px",
        borderRadius: "50%",
      },
      "& button": {
        borderRadius: "0.6em",
        border: "1px solid #BE888E",
        textTransform: "none",
        "&:hover": {
          color: "white",
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        },
        height: "45px",
        color: "#4F1f6d",
        width: "max-content",
        fontWeight: 700,
        minWidth: "130px",
        margin: "0 1em",
      },
    },
    buttonStyleView: {
      borderRadius: "0.6em",
      justifyContent: "center",
      height: "45px",
      border: "none",
      textTransform: "none" as const,
      color: "white",
      display: "flex",
      fontSize: "0.9em",
      width: "180px",
      alignItems: "center",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    },
    checkbox: {
      border: "none",
      position: "relative",
      width: "10px",
      appearance: "none",
      height: "10px",
      marginRight: "10px",
      borderRadius: "5em",
      transition: "0.3s all ease",
      "&:checked": {
        backgroundColor: "#540083",
        "&:after": {
          content: '""',
          borderTop: "none",
          width: "13px",
          borderRight: "none",
          display: "block",
          position: "absolute",
          height: "7px",
          left: "50%",
          border: "2px solid white",
          transform: "translate(-50%, -50%) rotate(-47deg)",
          top: "45%",
        },
      },
    },
    inputSearchStyle: {
      border: "1px solid #510081",
      padding: "0.5em 0.5em 0.5em 2em",
      outline: "none",
      borderRadius: "1.2em",
      color: "#510081",
      "&::placeholder": {
        color: "#510081",
      },
    },
    muiPaginationStyle: {
      position: "relative",
      borderRadius: "4px",
      border: "1px solid rgba(255, 58, 87, 0.7)",
      width: "max-content",
      "& li": {
        color: "white",
        width: "35px",
        height: "41px",
        background: "white",
        borderRadius: "2px",
      },
      "& li:nth-child(1)": {
        background: "transparent",
        zIndex: 999,
        top: 10,
        left: -20,
        position: "absolute",
        "& button": {
          width: "max-content",
          height: "max-content",
        },
        "& button.Mui-disabled": {
          display: "none",
        },
        "& button svg": {
          "& path": {
            fill: "white",
          },
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          borderRadius: "50%",
        },
      },
      "& li:nth-last-child(1)": {
        background: "transparent",
        position: "absolute",
        top: 10,
        right: -20,
        zIndex: 999,
        "& button svg": {
          borderRadius: "50%",
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          "& path": {
            fill: "white",
          },
        },
        "& button.Mui-disabled": {
          display: "none",
        },
        "& button": {
          height: "max-content",
          width: "max-content",
        },
      },
      "& li button:hover": {
        background: "none",
      },
      "& li div": {
        height: "100%",
        alignItems: "center",
        background: "none",
        display: "flex",
        width: "100%",
        justifyContent: "center",
      },
      "& li button": {
        height: "100%",
        background: "none",
        justifyContent: "center",
        margin: 0,
        alignItems: "center",
        display: "flex",
        width: "100%",
        padding: 0,
      },
      "& li button.Mui-selected": {
        color: "white",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        fontWeight: 500,
        borderRadius: "2px",
      },
    },
  });

export { WeeklyTestTakeTestReview };
export default withStyles(styles)(WeeklyTestTakeTestReview);
// Customizable Area End
