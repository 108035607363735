import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { extractParams } from "../../../framework/src/UtilsWeb";
interface Root {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Attributes {
  id: number;
  account_id: number;
  bookmarkable_id: number;
  bookmarkable_type: string;
  created_at: string;
  updated_at: string;
  bookmarkable: Bookmarkable;
  question_id: number;
  highlighted_text: any;
  question_no: number;
  question: any;
}

interface Bookmarkable {
  id: number;
  time_taken: string;
  chapter_id: number;
  account_id: number;
  correct_answer: number;
  unattended: number;
  incorrect_answer: number;
  marks: number;
  total_questions: number;
  score_db: string;
  question_ids: string[];
  score_id: number;
  created_at: string;
  updated_at: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bookmarks: Root[];
  submitModalOpen: boolean;
  submitModalOpen2: boolean;
  loading: boolean;
  chapterName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookmarkController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getBookmarksNotesMessageId = "";
  getResultsMessageBookmarkId = "";
  getSubjectsMessageBCId = "";
  token = "";
  reviewId = "";
  indexToDelete = Infinity;
  bookmarkIdToDelete = "";
  pathnameSearchResolve =
    this.props.navigation.history.location.pathname +
    this.props.navigation.history.location.search;
  searchResolve = this.props.navigation.history.location.search;
  breadcrumb = [
    {
      label: "Practice Test",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}${this.searchResolve}`,
    },
    {
      label: "Chapters",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}${this.searchResolve}`,
    },
    {
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/review-test/${this.props.navigation.getParam("chapterId")}${
        this.searchResolve
      }`,
      label: "Reviews",
    },
    {
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/review-test/${this.props.navigation.getParam("chapterId")}${
        this.searchResolve
      }`,
      label: "Reviews",
    },
    {
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/review-test/${this.props.navigation.getParam(
        "chapterId"
      )}/review/${this.props.navigation.getParam("reviewId")}${
        this.searchResolve
      }`,
      label: "Review Test",
    },
    {
      label: "Bookmarks",
      link: this.pathnameSearchResolve,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      bookmarks: [],
      submitModalOpen: false,
      submitModalOpen2: false,
      loading: false,
      chapterName: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getBookmarksNotesMessageId) {
      if (response?.data) {
        this.successfulFetchBookmark(response.data);
      }
    }

    if (apiRequestCallId === this.getSubjectsMessageBCId && response) {
      if (response.data) {
        this.handleSuccessSubject(response.data);
      }
    }

    if (apiRequestCallId === this.getResultsMessageBookmarkId) {
      if (response.meta) {
        const breadcrumb = this.breadcrumb;
        breadcrumb[2].label = response.meta.chapter.name;

        const propPassingBC = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingBC.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );

        this.send(propPassingBC);
      }

      this.setState({
        loading: false,
        chapterName: response.meta.chapter.name,
      });
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const token = await storage.get("authToken");
    if (token) {
      this.token = token;
    }
    const reviewId = this.props.navigation.getParam("reviewId");
    if (reviewId) {
      this.reviewId = reviewId;
      this.getBookmarksNotes(reviewId);
    }

    const propPassingBC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingBC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingBC);

    this.getSubjects();
    this.getReview();
  };

  successfulFetchBookmark = (response: Root[]) => {
    this.setState({
      bookmarks: response,
      loading: false,
    });
  };

  handleSubmitModalClose = () => {
    this.setState({
      submitModalOpen: false,
    });
  };

  handleSubmitModalClose2 = () => {
    this.setState({
      submitModalOpen2: false,
    });
  };

  initializeBookmarkDeletion = (bookmarkId: string, index: number) => {
    this.bookmarkIdToDelete = bookmarkId;
    this.indexToDelete = index;
    this.setState({
      submitModalOpen: true,
    });
  };

  // Customizable Area Start
  getBookmarksNotes = (reviewId: string) => {
    const headers = {
      token: this.token,
    };

    const getBookmarksNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });
    this.getBookmarksNotesMessageId = getBookmarksNotesMessage.messageId;

    getBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/bookmarks?practice_test_review_history_id=${reviewId}`
    );

    getBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getBookmarksNotesMessage.id,
      getBookmarksNotesMessage
    );
  };

  deleteBookmarkNotes = (bookmarkId: string, index: number) => {
    let bookmarks = this.state.bookmarks;
    bookmarks = bookmarks.filter((_, indexFilter) => indexFilter !== index);
    this.setState({
      bookmarks,
      submitModalOpen: false,
      submitModalOpen2: true,
    });

    const headers = {
      token: this.token,
    };

    const deleteBookmarkNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    deleteBookmarkNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    deleteBookmarkNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/bookmarks/${bookmarkId}`
    );

    deleteBookmarkNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      deleteBookmarkNotesMessage.id,
      deleteBookmarkNotesMessage
    );
  };

  getSubjects = async () => {
    const urlSearch = this.props.navigation.history.location.search;
    const packageId = extractParams(urlSearch, "packageId");
    const getSubjectsMessageBC: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    this.getSubjectsMessageBCId = getSubjectsMessageBC.messageId;

    getSubjectsMessageBC.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${packageId}/get_subjects`
    );

    getSubjectsMessageBC.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectsMessageBC.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSubjectsMessageBC.id, getSubjectsMessageBC);
  };

  handleSuccessSubject = (
    response: {
      id: string;
      attributes: { name: string };
    }[]
  ) => {
    const subjectId = this.props.navigation.getParam("subjectId");
    for (const data of response) {
      if (data.id === subjectId) {
        const breadcrumb = this.breadcrumb;
        breadcrumb[1].label = data.attributes.name;

        const propPassingReviewBC = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingReviewBC.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingReviewBC);

        this.setState({
          loading: false,
        });
      }
    }
  };

  getReview = () => {
    const headers = {
      token: this.token,
    };

    const getResultsMessageBookmark = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    this.getResultsMessageBookmarkId = getResultsMessageBookmark.messageId;

    getResultsMessageBookmark.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${this.props.navigation.getParam(
        "chapterId"
      )}/review_test`
    );

    getResultsMessageBookmark.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getResultsMessageBookmark.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getResultsMessageBookmark.id, getResultsMessageBookmark);
  };
  // Customizable Area End
}
