import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  Grid,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import SearchIcon from "@material-ui/icons/Search";
import { Physics, Edit } from "./assets";
import Loader from "../../../components/src/Loader.web";
import DateRangeIcon from "@material-ui/icons/DateRange";
import TodayIcon from "@material-ui/icons/Today";
import Pagination from "@material-ui/lab/Pagination";
import { muiPaginationstyle } from "./BookMySlotAppointments.web";
import { webStyle } from "./BookMySlotAddEditSlot.web";
// Customizable Area End

import BookMySlotController, {
  AppointmentT,
  Props,
  configJSON,
} from "./BookMySlotController.web";

class BookMySlot extends BookMySlotController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderAppointmentBox = (data: AppointmentT) => {
    return (
      <Grid item key={`notifs-${data.id}`} sm={12} lg={6}>
        <Box className={this.props.classes.appointmentContainerBox}>
          <img
            src={Edit}
            alt="edit-btn"
            className="edit-btn"
            data-test-id="edit-btn"
            onClick={() => this.navigateToEdit(data.id)}
          />
          <Box className="details-appointment">
            <img src={Physics} alt={"physics-image"} />
            <Box>
              <Box className="text-appointment-headers">
                <Typography>{configJSON.StudentName}</Typography>
                <Typography>Parent Name</Typography>
                <Typography>Group</Typography>
                <Typography>Zoom Link</Typography>
              </Box>
              <Box className="text-appointment-description-dots">
                <Typography>:</Typography>
                <Typography>:</Typography>
                <Typography>:</Typography>
                <Typography>:</Typography>
                <Typography />
              </Box>
              <Box className="text-appointment-description">
                <Typography>{data.attributes.student_name}</Typography>
                <Typography>{data.attributes.parent_name}</Typography>
                <Typography>{data.attributes.group}</Typography>
                <a href={data.attributes.zoom_url}>
                  {data.attributes.zoom_url}
                </a>
                <Typography className={this.props.classes.dateslotStyle}>
                  Time Slot: {data.attributes.slot_date}{" "}
                  {data.attributes.time_slot}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="buttons-appointment">
            <Button className="status">
              Status:{" "}
              {
                this.status.filter(
                  (status) => status.value === data.attributes.status
                )[0].label
              }
            </Button>
            <Button
              className="cancel"
              data-test-id="cancel-btn"
              onClick={() => this.cancelSlot(data.id)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Grid>
    );
  };

  RenderTodayAppointments = () => {
    const { currentPageToday, itemsPerPage, todayData } = this.state;
    const filteredtodayData = todayData.filter(
      (data) => data.attributes.slot_type !== "cancel_slot"
    );
    const indexOfLastItem = currentPageToday * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredtodayData
      .filter((data) => data.attributes.slot_type !== "cancel_slot")
      .slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredtodayData.length / itemsPerPage);

    if (filteredtodayData.length === 0) {
      return <Typography>There are no appointments today</Typography>;
    }

    return (
      <Grid container direction="row" alignItems="center" spacing={4}>
        {currentItems.map((appointment) => {
          return this.RenderAppointmentBox(appointment);
        })}
        <Box
          display="flex"
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
        >
          <Pagination
            data-test-id="pagination"
            count={totalPages}
            page={this.state.currentPageToday}
            onChange={(_: unknown, value: number) =>
              this.handlePageChangeToday(value)
            }
            className={this.props.classes.muiPaginationStyle}
          />
        </Box>
      </Grid>
    );
  };

  RenderUpcomingAppointments = () => {
    const { currentPageUpcoming, itemsPerPage, upcomingData } = this.state;
    const filteredupcomingData = upcomingData.filter(
      (data) => data.attributes.slot_type !== "cancel_slot"
    );
    const indexOfLastItem = currentPageUpcoming * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredupcomingData
      .filter((data) => data.attributes.slot_type !== "cancel_slot")
      .slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredupcomingData.length / itemsPerPage);

    if (filteredupcomingData.length === 0) {
      return <Typography>There are no upcoming appointment today</Typography>;
    }

    return (
      <Grid container direction="row" alignItems="center" spacing={4}>
        {currentItems.map((appointment) => {
          return this.RenderAppointmentBox(appointment);
        })}
        <Box
          display="flex"
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
        >
          <Pagination
            data-test-id="pagination"
            count={totalPages}
            page={this.state.currentPageUpcoming}
            onChange={(_: unknown, value: number) =>
              this.handlePageChangeUpcoming(value)
            }
            className={this.props.classes.muiPaginationStyle}
          />
        </Box>
      </Grid>
    );
  };

  RenderUpdatePendingStatus = () => {
    const { currentPageUpdating, itemsPerPage, pendingData } = this.state;
    const filteredPendingData = pendingData.filter(
      (data) => data.attributes.slot_type !== "cancel_slot"
    );
    const indexOfLastItem = currentPageUpdating * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredPendingData.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    const totalPages = Math.ceil(filteredPendingData.length / itemsPerPage);

    if (filteredPendingData.length === 0) {
      return <Typography>There are no pending today</Typography>;
    }

    return (
      <Grid container direction="row" alignItems="center" spacing={4}>
        {currentItems.map((appointment) => {
          return this.RenderAppointmentBox(appointment);
        })}
        <Box
          display="flex"
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
        >
          <Pagination
            data-test-id="pagination"
            count={totalPages}
            page={this.state.currentPageUpdating}
            onChange={(_: unknown, value: number) =>
              this.handlePageChangeUpdating(value)
            }
            className={this.props.classes.muiPaginationStyle}
          />
        </Box>
      </Grid>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Modal
          open={this.state.isSlotUpdatedModal}
          onClose={this.handleIsSlotUpdatedModal}
          data-test-id="modal-slot-booked"
        >
          <Box sx={webStyle.modalStyle}>
            <Typography>{configJSON.successCancelSlotBook}</Typography>
            <Button
              style={webStyle.buttonStyle}
              onClick={this.handleIsSlotUpdatedModal}
            >
              Close
            </Button>
          </Box>
        </Modal>
        <Box position="relative">
          <BannerWeb title="Book my slot" />
          <Box className={this.props.classes.iconsContainer}>
            <Box
              className={this.props.classes.iconBtn}
              onClick={() => this.navigateToAppointments()}
              data-test-id="to-appointments"
            >
              <Box>
                <TodayIcon />
              </Box>
              <Typography>Appointments</Typography>
            </Box>
            <Box
              className={this.props.classes.iconBtn}
              onClick={this.navigateToSlotBookings}
            >
              <Box>
                <DateRangeIcon />
              </Box>
              <Typography>Slots</Typography>
            </Box>
          </Box>
        </Box>
        <Box padding={"2em"}>
          <Box className={this.props.classes.searchStyle}>
            <Box position="relative" width={"100%"}>
              <SearchIcon style={searchStyle} />
              <input
                placeholder="Search"
                value={this.state.searchInput}
                data-test-id="input-search"
                onChange={(event) => this.handleSearch(event.target.value)}
              />
            </Box>
          </Box>
          <Box marginTop={"2em"}>
            <Box className={this.props.classes.appointmentsStyle}>
              <Box>
                <Typography className="header-text">
                  Today's Appointments{" "}
                  <span>
                    (
                    {
                      this.state.todayData.filter(
                        (data) => data.attributes.slot_type !== "cancel_slot"
                      ).length
                    }
                    )
                  </span>
                </Typography>
              </Box>
              <Box marginTop="2em">{this.RenderTodayAppointments()}</Box>
            </Box>
            <Box className={this.props.classes.appointmentsStyle}>
              <Box className="header-container">
                <Typography className="header-text">
                  Upcoming Appointments{" "}
                  <span>
                    (
                    {
                      this.state.upcomingData.filter(
                        (data) => data.attributes.slot_type !== "cancel_slot"
                      ).length
                    }
                    )
                  </span>
                </Typography>
              </Box>
              <Box marginTop="2em">{this.RenderUpcomingAppointments()}</Box>
            </Box>
            <Box className={this.props.classes.appointmentsStyle}>
              <Box className="header-container">
                <Typography className="header-text">
                  Update Pending Status{" "}
                  <span>
                    (
                    {
                      this.state.pendingData.filter(
                        (data) => data.attributes.slot_type !== "cancel_slot"
                      ).length
                    }
                    )
                  </span>
                </Typography>
              </Box>
              <Box marginTop="2em">{this.RenderUpdatePendingStatus()}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const searchStyle = {
  position: "absolute" as const,
  left: "25px",
  top: "23px",
  color: "#510081",
};

export const boxStyles = {
  border: "1px solid #BE544D",
  borderRadius: "5px",
  minHeight: "245px",
  height: "max-content",
  position: "relative" as const,
  padding: "2em",
  "& .edit-btn": {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: "25px",
    height: "25px",
    cursor: "pointer",
  },
  "& .details-appointment": {
    display: "flex",
    gap: "1em",
    "& img": {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
    },
    "& > div": {
      display: "flex",
      "& .text-appointment-headers": {
        display: "flex",
        flexDirection: "column",
        gap: "1.2em",
        width: "200px",
        "& p": {
          fontWeight: 500,
        },
      },
      "& .text-appointment-description-dots": {
        display: "flex",
        flexDirection: "column",
        gap: "1.2em",
        marginRight: "1em",
      },
      "& .text-appointment-description": {
        display: "flex",
        flexDirection: "column",
        gap: "1.2em",
      },
    },
  },
  "& .buttons-appointment": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "1.3em",
    "& .status": {
      background: "rgba(173, 135, 197, 0.25)",
      borderRadius: "4px",
      color: "rgb(82, 0, 130)",
      minWidth: "226px",
      width: "max-content",
      height: "48px",
      fontWeight: 500,
    },
    "& .cancel": {
      background: "rgba(255, 13, 0, 0.83)",
      borderRadius: "4px",
      color: "white",
      width: "104px",
      height: "48px",
      fontWeight: 500,
    },
  },
};
export const webStyleBookMySlot = () =>
  createStyles({
    appointmentsStyle: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "4em",
      "& .header-text": {
        fontSize: "1.8em",
        fontWeight: 500,
        "& span": {
          color: "#5D2E7C",
        },
      },
    },
    iconsContainer: {
      display: "flex",
      gap: "2em",
      position: "absolute",
      right: "10%",
      top: "10px",
    },
    iconBtn: {
      cursor: "pointer",
      color: "rgb(82, 0, 130)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "& p": { fontWeight: 500 },
      "& div": {
        border: "none",
        padding: "0.6em",
        background: "rgb(82, 0, 130, 0.2)",
        borderRadius: "50%",
        position: "relative",
      },
    },
    appointmentContainerBox: { ...boxStyles },
    searchStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginTop: "2em",
      "& input": {
        border: "1px solid #510081",
        borderRadius: "5em",
        color: "#510081",
        outline: "none",
        width: "100%",
        fontSize: "1.3em",
        height: "70px",
        padding: "0.5em 0.5em 0.5em 3em",
        "&::placeholder": {
          color: "#510081",
        },
      },
    },
    muiPaginationStyle: { ...muiPaginationstyle },
    dateslotStyle: {
      fontWeight: 500,
      color: "#808080",
    },
  });
export default withStyles(webStyleBookMySlot)(BookMySlot);
export { BookMySlot };
// Customizable Area End
