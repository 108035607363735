import React from "react";

import {
  Box as MUIBoxDiv,
  // Customizable Area Start
  Modal,
  Button,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// Customizable Area End

import GrandTestBookmarkController, {
  Props,
} from "./GrandTestBookmarkController.web";
import { Delete } from "./assets";
import Loader from "../../../components/src/Loader.web";

export default class GrandTestBookmark extends GrandTestBookmarkController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MUIBoxDiv>
        <Loader loading={this.state.loading} />
        <MUIBoxDiv sx={webStyle.bannerStyle}>
          <h2>Grand Test</h2>
        </MUIBoxDiv>
        <MUIBoxDiv sx={webStyle.centerHorizontally}>
          <h1 style={{ margin: "1em" }}>Bookmarks</h1>
          <MUIBoxDiv sx={webStyle.centerVertically}>
            <Typography variant="h6">
              Exam : {this.state.breadcrumb[1].label}
            </Typography>
            <Typography variant="h6">
              Bookmarks - {this.state.bookmarks.length}
            </Typography>
          </MUIBoxDiv>
        </MUIBoxDiv>
        <MUIBoxDiv sx={webStyle.centerHorizontally}>
          {this.state.bookmarks?.map((bookmark, index) => {
            return (
              <MUIBoxDiv key={`box-${index}`} sx={webStyle.cardBookmark}>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  Question No : {bookmark.attributes.question_no}
                </Typography>
                <MUIBoxDiv
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="body1" style={{ color: "#520082" }}>
                    You have Bookmarked this Question
                  </Typography>
                  <img
                    onClick={() =>
                      this.initializeBookmarkDeletion(bookmark.id, index)
                    }
                    data-test-id="delete-init"
                    src={Delete}
                    alt="delete-png"
                    width={"20px"}
                    style={{ cursor: "pointer", marginRight: "2em" }}
                  />
                </MUIBoxDiv>
              </MUIBoxDiv>
            );
          })}
        </MUIBoxDiv>
        <Modal
          open={this.state.submitModalOpen}
          BackdropProps={{ onClick: undefined }}
          onClose={this.handleSubmitModalClose}
        >
          <MUIBoxDiv sx={webStyle.modalStyle} className="submit-test-question">
            <h3>Are you sure you want to delete this Bookmark?</h3>
            <MUIBoxDiv>
              <Button
                onClick={this.handleSubmitModalClose}
                style={webStyle.buttonStyleNo}
                data-test-id="closeSubmit"
              >
                Cancel
              </Button>
              <Button
                style={webStyle.buttonStyleYes}
                data-test-id="deleteModal"
                onClick={() =>
                  this.deleteBookmarkNotes(
                    this.bookmarkIdToDelete,
                    this.indexToDelete
                  )
                }
              >
                Delete
              </Button>
            </MUIBoxDiv>
          </MUIBoxDiv>
        </Modal>
        <Modal
          onClose={this.handleSubmitModalClose2}
          open={this.state.submitModalOpen2}
          BackdropProps={{ onClick: undefined }}
        >
          <MUIBoxDiv sx={webStyle.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>Deleted Bookmark Successfully</h3>
            <MUIBoxDiv>
              <Button
                style={webStyle.buttonStyleYes}
                data-test-id="submitBtnModal"
                onClick={this.handleSubmitModalClose2}
              >
                Go Back
              </Button>
            </MUIBoxDiv>
          </MUIBoxDiv>
        </Modal>
      </MUIBoxDiv>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyleNo: {
    width: "130px",
    border: "1px solid #ffe9ed",
    borderRadius: "0.6em",
    height: "50px",
    color: "#4F1f6d",
    background: "white",
    textTransform: "none" as const,
  },
  bannerStyle: {
    display: "flex",
    background: "rgba(247, 214, 255, 0.19)",
    justifyContent: "center",
    alignItems: "center",
    color: "rgb(82, 0, 130)",
    width: "100%",
    height: "71px",
  },
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    transform: "translate(-50%, -50%)",
    width: "35%",
    minHeight: "max-content",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute" as "absolute",
    left: "50%",
    bgcolor: "background.paper",
    border: "2px solid #FFD0D8",
    borderRadius: "1.5em",
    padding: "2em",
    top: "50%",
    gap: "1.5em",
  },
  buttonStyleYes: {
    height: "50px",
    color: "white",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    textTransform: "none" as const,
    width: "130px",
    marginLeft: "1em",
  },
  centerHorizontally: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    gap: "1em",
    width: "100%",
  },
  centerVertically: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    width: "60%",
    margin: "2em 0",
  },
  cardBookmark: {
    width: "60%",
    background: "rgba(209, 170, 255, 0.14)",
    border: "1px solid #FCC2C0",
    height: "116.8px",
    borderRadius: "10px",
    padding: "1em",
  },
};
// Customizable Area End
