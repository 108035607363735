import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  totalMark: number;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CalculateController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCalculationId = "";
  calculatorSchema = Yup.object({
    eamcetInput: Yup.number()
      .required("Please input a value here.")
      .positive("Number should be above 0")
      .max(160, "Number should be less than 160")
      .typeError("A number is required"),
    ipeInput: Yup.number()
      .required("Please input a value here.")
      .positive("Number should be above 0")
      .max(600, "Number should be less than 600")
      .typeError("A number is required"),
  });
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      totalMark: 0,
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getCalculationId && responseJson) {
      if (responseJson.norm_mark) {
        this.setState({
          totalMark: responseJson.norm_mark,
          loading: false,
        });
      }
    }
    // Customizable Area End
  }

  // web events

  // Customizable Area Start
  getCalculationSubmit = async (userInput: {
    eamcetInput?: number;
    ipeInput?: number;
  }) => {
    this.setState({
      loading: true,
    });
    const getCalculation = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getCalculation.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `weighted_marks?eamcet_exam=${userInput.eamcetInput}&ipe_mark=${userInput.ipeInput}`
    );
    this.getCalculationId = getCalculation.messageId;
    getCalculation.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getCalculation.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getCalculation.id, getCalculation);
  };
  // Customizable Area End
}
