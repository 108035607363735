import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
interface Root {
  question_no: number;
  created_at: string;
  notes_content: string;
  updated_at: string;
  serial_number: number;
  noteable_id: number;
  noteable_type: string;
  id: number;
  question_id: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  notes: Root[];
  isConfirmDeletePromptOpen: boolean;
  testId: string;
  isDeletePromptOpen: boolean;
  loading: boolean;
  breadcrumb: { link: string; label: string }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GrandTestSavedNotesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSavedNotesMessageId = "";
  indexToDelete = Infinity;
  noteIdToDelete = "";
  breadcrumb = [
    {
      label: "Grand Test",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}`,
    },
    {
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}`,
      label: "Chapters",
    },
    {
      label: "Reviews",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}/review-test/${this.props.navigation.getParam("subjectId")}`,
    },
    {
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}/review-test/${this.props.navigation.getParam("subjectId")}`,
      label: "Reviews",
    },
    {
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}/review-test/${this.props.navigation.getParam(
        "subjectId"
      )}/review/${this.props.navigation.getParam("testId")}`,
      label: "Review Test",
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Saved Notes",
    },
  ];
  getPackageReviewTestMessageId = "";
  getTestNameMessageSNId = "";
  token = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isDeletePromptOpen: false,
      notes: [],
      testId: "",
      isConfirmDeletePromptOpen: false,
      loading: false,
      breadcrumb: this.breadcrumb,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSavedNotesMessageId) {
      if (responseJson.notes.length > 0) {
        this.successfulFetchNotes(responseJson.notes);
      }
      this.setState({
        loading: false,
      });
      this.getPackageReviewTestMessage();
    }

    if (apiRequestCallId === this.getTestNameMessageSNId) {
      if (responseJson.data) {
        this.handleSuccessfulFetchTest(responseJson.data);
      }
    }

    if (apiRequestCallId === this.getPackageReviewTestMessageId) {
      if (responseJson.data) {
        this.handleSuccessfulPackage(responseJson.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const token = await storage.get("authToken");
    if (token) {
      this.token = token;
    }
    const testId = this.props.navigation.getParam("testId");
    if (testId) {
      this.getSavedNotes(testId);
      this.setState({
        testId,
      });
    }

    const propPassingGTSNC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingGTSNC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingGTSNC);
  };

  deletePromptOpen = (noteId: string, index: number) => {
    this.noteIdToDelete = noteId;
    this.indexToDelete = index;
    this.setState({
      isDeletePromptOpen: true,
    });
  };
  successfulFetchNotes = (response: Root[]) => {
    this.setState({
      notes: response,
    });
  };

  handleDeletePromptClose2 = () => {
    this.setState({
      isConfirmDeletePromptOpen: false,
    });
  };
  getSavedNotes = (testId: string) => {
    const headers = {
      token: this.token,
    };
    this.setState({
      loading: true,
    });
    const getSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSavedNotesMessageId = getSavedNotesMessage.messageId;
    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/${testId}/gt_notes`
    );
    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getSavedNotesMessage.id, getSavedNotesMessage);
  };
  deleteSavedNotes = (noteId: string, index: number) => {
    let notes = this.state.notes;
    notes = notes.filter((_, noteIndex) => noteIndex !== index);
    this.deleteSavedNotesApi(noteId);
    this.setState({
      notes,
      isDeletePromptOpen: false,
      isConfirmDeletePromptOpen: true,
    });
  };
  handleDeletePromptClose = () => {
    this.setState({
      isDeletePromptOpen: false,
    });
  };

  // Customizable Area Start
  deleteSavedNotesApi = (noteId: string) => {
    const headers = {
      token: this.token,
    };
    const deleteSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    deleteSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deleteSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    deleteSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/notes/${noteId}`
    );
    runEngine.sendMessage(deleteSavedNotesMessage.id, deleteSavedNotesMessage);
  };

  handleSuccessfulFetchTest = (
    response: { id: string; attributes: { test_name: string } }[]
  ) => {
    const packageId = this.props.navigation.getParam("testId");

    response.forEach((data) => {
      if (data.id === packageId) {
        const breadcrumb = this.state.breadcrumb;
        breadcrumb[2] = { ...breadcrumb[2], label: data.attributes.test_name };

        const propPassingSN = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingSN.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingSN);

        this.setState(
          {
            breadcrumb,
            loading: false,
          }
        );
      }
    });
  };

  handleSuccessfulPackage = (
    response: { gt_code: number; package_name: string }[]
  ) => {
    const packageId = this.props.navigation.getParam("packageId");
    response.forEach((data) => {
      if (data.gt_code === packageId) {
        const breadcrumb = this.state.breadcrumb;
        breadcrumb[1] = { ...breadcrumb[1], label: data.package_name };

        const propPassingSN = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingSN.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingSN);

        this.setState(
          {
            breadcrumb,
            loading: false,
          },
          () => {
            this.getTestNameMessageSN();
          }
        );
      }
    });
  };

  getPackageReviewTestMessage = async () => {
    const getPackageMessageSN = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getPackageReviewTestMessageId =
      getPackageMessageSN.messageId;

    getPackageMessageSN.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getPackageMessageSN.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/get_packages`
    );

    getPackageMessageSN.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getPackageMessageSN.id,
      getPackageMessageSN
    );
  };

  getTestNameMessageSN = async () => {
    const getTestNameMessageSN = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getTestNameMessageSNId =
      getTestNameMessageSN.messageId;

    getTestNameMessageSN.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getTestNameMessageSN.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/${this.props.navigation.getParam(
        "subjectId"
      )}/review_histories`
    );

    getTestNameMessageSN.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getTestNameMessageSN.id,
      getTestNameMessageSN
    );
  };
  // Customizable Area End
}
