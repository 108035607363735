import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { webStyleReviewTestMain } from "./ReviewTestMain.web";
import storage from "../../../framework/src/StorageProvider.web";
import { extractParams } from "../../../framework/src/UtilsWeb";
interface Root {
  id: number;
  time_taken: string;
  chapter_id: number;
  account_id: number;
  correct_answer: number;
  unattended: number;
  incorrect_answer: number;
  marks: number;
  total_questions: number;
  score_db: string;
  question_ids: string[];
  score_id: number;
  created_at: string;
  updated_at: string;
}

export interface Root2 {
  chapter: Chapter;
  subject: string;
}

export interface Chapter {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  practice_test: string;
  avail_pt: string;
  date_pt: string;
  class_zone: string;
  avail_cz: string;
  date_cz: string;
  question_bank: string;
  avail_qb: string;
  date_qb: string;
  created_by: string;
  remarks: string;
  topic_db: string;
  qb_id: any;
  qb_db: string;
  id_pt: string;
  id_cz: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleReviewTestMain> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  review_histories: Root[];
  chapterData: Root2 | null;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReviewTestMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pathnameSearchResolve =
    this.props.navigation.history.location.pathname +
    this.props.navigation.history.location.search;
  searchResolve = this.props.navigation.history.location.search;
  getReviewHistoriesMessageId = "";
  chapterId = "";
  breadcrumb = [
    {
      label: "Practice Test",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}${this.searchResolve}`,
    },
    {
      label: "Chapters",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}${this.searchResolve}`,
    },
    {
      label: "Reviews",
      link: this.pathnameSearchResolve,
    },
    {
      label: "Reviews",
      link: this.pathnameSearchResolve,
    },
  ];
  getSubjectMainMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      review_histories: [],
      chapterData: null,
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getReviewHistoriesMessageId) {
      if (response?.review_histories && response?.subject) {
        this.handleSuccessFetchHistories(response.review_histories);
        this.handleSuccessFetchResponse(response);
      }
    }

    if (apiRequestCallId === this.getSubjectMainMessageId && response) {
      if (response.data) {
        this.handleSuccessSubject(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const chapterId = this.props.navigation.getParam("chapterId");

    this.chapterId = chapterId;

    this.getReviewHistories(chapterId);
    this.getSubjectMain();
  };

  handleSuccessSubject = (
    response: {
      id: string;
      attributes: { name: string };
    }[]
  ) => {
    const subjectId = this.props.navigation.getParam("subjectId");
    for (const data of response) {
      if (data.id === subjectId) {
        const breadcrumb = this.breadcrumb;
        breadcrumb[1].label = data.attributes.name;

        const propPassingRTMCTest = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingRTMCTest.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingRTMCTest);

        this.setState({
          loading: false,
        });
      }
    }
  };

  handleSuccessFetchHistories = (review_histories: Root[]) => {
    this.setState({
      review_histories,
      loading: false,
    });
  };

  handleSuccessFetchResponse = (response: Root2) => {
    const breadcrumb = this.breadcrumb;
    breadcrumb[2].label = response.chapter.name;

    const propPassingRTM = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingRTM.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingRTM);

    this.setState({
      chapterData: response,
      loading: false,
    });
  };

  convertToDate = (timestamp: string) => {
    const date = new Date(timestamp);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  // Customizable Area Start
  getReviewHistories = async (chapterId: string) => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getReviewHistoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReviewHistoriesMessageId = getReviewHistoryMessage.messageId;

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${chapterId}/review_histories`
    );

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getReviewHistoryMessage.id, getReviewHistoryMessage);
  };

  getSubjectMain = async () => {
    const urlSearch = this.props.navigation.history.location.search;
    const packageId = extractParams(urlSearch, "packageId");
    const getSubjectMainMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    this.getSubjectMainMessageId = getSubjectMainMessage.messageId;

    getSubjectMainMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${packageId}/get_subjects`
    );

    getSubjectMainMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectMainMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSubjectMainMessage.id, getSubjectMainMessage);
  };
  // Customizable Area End
}
