import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import UsageStatsRecordedClassController, {
  Props,
} from "./UsageStatsRecordedClassController.web";

class UsageStatsRecordedClass extends UsageStatsRecordedClassController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ProgressBar = ({ time_spent }: { time_spent: string }) => {
    return <Box className={this.props.classes.progressBar}>{time_spent}</Box>;
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box marginTop="2em" padding="2em">
          {this.state.stats && (
            <Box marginBottom={"3em"}>
              <Box marginTop={"2em"}>
                {this.state.stats.time_spent_stats.map((stat) => {
                  return (
                    <Box marginTop={"2em"}>
                      <Typography className={this.props.classes.typoSub}>
                        Time Spent on subject {stat.subject}
                      </Typography>
                      <Box marginTop="1em">
                        {this.ProgressBar({
                          time_spent: stat.time_spent,
                        })}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleUsageStatsRecordedClass = () =>
  createStyles({
    selectStyle: {
      outline: 0,
      width: "100%",
      fontSize: "1.4em",
      border: "1px solid #808080",
      height: "70px",
      color: "#5C0F89",
      marginTop: "1.3em",
      fontWeight: 500,
      paddingLeft: "1.5em",
      WebkitAppearance: "none",
    },
    expandMoreIcon: {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      borderRadius: "50%",
      right: "30px",
      position: "absolute",
      cursor: "pointer",
      top: "47px",
      color: "white",
    },
    typographyStyle: {
      fontWeight: 500,
      fontSize: "1.3em",
    },
    subjectTitle: {
      color: "#ff3366",
      fontSize: "1.2em",
    },
    progressBar: {
      overflow: "hidden",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "5px",
      height: "60px",
      fontWeight: 500,
      fontSize: "1.3em",
      textAlign: "center",
      background: "#7500BE",
      color: "white",
    },
    covered: {
      backgroundColor: "#7500BE",
      justifyContent: "center",
      color: "white",
      alignItems: "center",
      display: "flex",
    },
    notCovered: {
      justifyContent: "center",
      alignItems: "center",
      color: "black",
      textAlign: "right",
      backgroundColor: "lightgray",
      display: "flex",
    },
    typoMain: {
      fontWeight: 700,
      color: "#FF5C5C",
      fontSize: "1.5em",
    },
    typoSub: {
      fontWeight: 500,
      fontSize: "1.2em",
    },
  });
export default withStyles(webStyleUsageStatsRecordedClass)(
  UsageStatsRecordedClass
);
export { UsageStatsRecordedClass };
// Customizable Area End
