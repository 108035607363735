import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
export interface Root {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  subject_id: number;
  chapter_id: number;
  question_type: string;
  question_num: number;
  question: string;
  solution: string;
  db_type: string;
  qb_type: string;
  created_at: string;
  updated_at: string;
}

import { WithStyles } from "@material-ui/core";
import { styles } from "./QuestionBankMain.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof styles> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  list: Root[];
  reportModalOpen: boolean;
  reportInput: string;
  openBookmarkSuccessModal: boolean;
  bookmarkedQuestionNumber: string;
  activeButton: number;
  reportSuccessModal: boolean;
  chapterName: string;
  chapterId: string;
  questionTypes: Array<{ question_type: string }>;
  loading: boolean;
  currentQuestionType: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuestionBankMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postReportMessageId = "";
  bookmarkHistoryMessageId = "";
  breadcrumb = [
    {
      label: "Question Bank",
      link: "/dashboard/question-bank",
    },
    {
      label: "Subject",
      link: `/dashboard/question-bank/subject/${this.props.navigation.getParam(
        "subjectId"
      )}`,
    },
    {
      label: "Chapter",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  getQbListMessageId = "";
  getQbQTypeMessageId = "";
  getSubjectDetailsMainMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      list: [],
      reportModalOpen: false,
      reportInput: "",
      openBookmarkSuccessModal: false,
      bookmarkedQuestionNumber: "",
      activeButton: 0,
      reportSuccessModal: false,
      chapterName: "",
      loading: false,
      chapterId: "",
      questionTypes: [],
      currentQuestionType: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getQbListMessageId) {
      const breadcrumb = this.breadcrumb;
      breadcrumb[2].label = response.meta.chapter_name;

      const propPassingQBMC = new Message(
        getName(MessageEnum.NavigationBreadcrumbMessage)
      );
      propPassingQBMC.addData(
        getName(MessageEnum.BreadcrumbDataMessage),
        this.breadcrumb
      );
      this.send(propPassingQBMC);

      if (response.data) {
        this.setState({
          list: response.data,
          chapterName: response.meta.chapter_name,
          loading: false,
        });
      }
    }

    if (apiRequestCallId === this.postReportMessageId) {
      if (response.message) {
        this.setState({
          reportInput: "",
        });
        this.handleReportModal();
        this.handleReportSuccessModal();
      }
    }

    if (apiRequestCallId === this.bookmarkHistoryMessageId) {
      this.handleQbBookmarkMessage(response);
    }

    if (apiRequestCallId === this.getQbQTypeMessageId && response) {
      this.handleQbTypeMessage(response);
    }

    if (apiRequestCallId === this.getSubjectDetailsMainMessageId && response) {
      this.handleQbSubjectMessage(response);
    }
    // Customizable Area End
  }

  // web events
  handleQbTypeMessage = (response: {
    question_types: Array<{ question_type: string }>;
  }) => {
    if (response.question_types) {
      this.successfulQuestionTypeFetch(response.question_types);
    }
  };

  handleQbSubjectMessage = (response: {
    data: unknown;
    meta: { subject_name: string };
  }) => {
    if (response.data) {
      this.handleSubjectDetailSuccess(response.meta.subject_name);
    }
  };

  handleQbBookmarkMessage = (response: { data: unknown }) => {
    if (response.data) {
      this.handleBookmarkSuccessModal();
    }
  };

  successfulQuestionTypeFetch = (
    questionTypes: Array<{ question_type: string }>
  ) => {
    this.setState(
      {
        questionTypes: questionTypes,
        loading: false,
      },
      () => {
        const questionType = this.state.currentQuestionType;

        if (questionType) {
          for (const [index, data] of this.state.questionTypes.entries()) {
            if (data.question_type === questionType) {
              this.setState({
                activeButton: index,
              });
            }
          }
          return this.getList(questionType, this.state.chapterId);
        }

        return this.getList(
          questionTypes[0]?.question_type,
          this.state.chapterId
        );
      }
    );
  };

  componentDidMount = async () => {
    const questionType = this.props.navigation.history.location.search;
    const propPassingQBMC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingQBMC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingQBMC);
    const chapterId = this.props.navigation.getParam("chapterId");
    const subjectId = this.props.navigation.getParam("subjectId");
    this.setState({
      chapterId,
    });

    this.updateQuestionType(questionType);
    this.getQuestionTypesByChapterId(chapterId);
    this.getSubjectDetailsMain(subjectId);
  };

  componentDidUpdate = (prevProps: Props, prevState: S) => {
    if (this.props.navigation.getParam("chapterId") !== this.state.chapterId) {
      const chapterId = this.props.navigation.getParam("chapterId");
      const subjectId = this.props.navigation.getParam("subjectId");
      this.setState({
        chapterId,
      });
      this.getQuestionTypesByChapterId(chapterId);
      this.getSubjectDetailsMain(subjectId);
    }
  };

  updateQuestionType = (questionType: string) => {
    if (questionType) {
      const parsedQuestionType = questionType.split("question_type=");
      this.setState({
        currentQuestionType: parsedQuestionType[1].replace(/%20/g, " "),
      });
    }
  };

  setActiveButton = (buttonNo: number) => {
    this.setState({
      activeButton: buttonNo,
    });
  };

  handleReportSuccessModal = () => {
    this.setState({
      reportSuccessModal: !this.state.reportSuccessModal,
    });
  };

  handleListClick = (listType: string, btnNo: number) => {
    this.setActiveButton(btnNo);
    this.getList(listType, this.state.chapterId);
  };

  handleReportModal = () => {
    this.setState({
      reportModalOpen: !this.state.reportModalOpen,
    });
  };

  handleInputChange = (value: string) => {
    this.setState({
      reportInput: value,
    });
  };

  handleBookmarkSuccessModal = () => {
    this.setState({
      openBookmarkSuccessModal: !this.state.openBookmarkSuccessModal,
    });
  };

  // Customizable Area Start
  getList = async (qbType: string, chapterId: string) => {
    const getQbListMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };
    const endpoint = `bx_block_questionbank/${chapterId}/qb_lists`;
    const queryParams = `?question_type=${qbType}`;

    getQbListMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endpoint}${queryParams}`
    );

    this.getQbListMessageId = getQbListMessage.messageId;

    getQbListMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getQbListMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getQbListMessage.id, getQbListMessage);
  };

  sendReport = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const postReportMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const body = {
      reportable_id: this.props.navigation.getParam("chapterId"),
      reportable_type: "chapter",
      contant: this.state.reportInput,
    };

    postReportMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/qb_reports`
    );

    postReportMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    this.postReportMessageId = postReportMessage.messageId;

    postReportMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    postReportMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(postReportMessage.id, postReportMessage);
  };

  bookmarkSave = async (bookmarkId: string, questionNumber: string) => {
    this.setState({
      bookmarkedQuestionNumber: questionNumber,
    });
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const bookmarkHistoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const body = {
      bookmarkable_id: bookmarkId,
      bookmarkable_type: "qb_ipe",
      question_no: questionNumber,
      question_id: bookmarkId,
    };

    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/bookmarks`
    );
    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    this.bookmarkHistoryMessageId = bookmarkHistoryMessage.messageId;

    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(bookmarkHistoryMessage.id, bookmarkHistoryMessage);
  };

  getQuestionTypesByChapterId = async (chapterId: string) => {
    const getQbQTypeMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    getQbQTypeMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/${chapterId}/question_types`
    );

    this.getQbQTypeMessageId = getQbQTypeMessage.messageId;

    getQbQTypeMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getQbQTypeMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getQbQTypeMessage.id, getQbQTypeMessage);
  };

  getSubjectDetailsMain = async (subjectId: string) => {
    const getSubjectDetailsMainMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getSubjectDetailsMainMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/${subjectId}/qb_chapters`
    );
    this.getSubjectDetailsMainMessageId =
      getSubjectDetailsMainMessage.messageId;
    getSubjectDetailsMainMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSubjectDetailsMainMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getSubjectDetailsMainMessage.id,
      getSubjectDetailsMainMessage
    );
  };

  handleSubjectDetailSuccess = (subjectName: string) => {
    const breadcrumb = this.breadcrumb;
    breadcrumb[1].label = subjectName;

    const propPassingQBMC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingQBMC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingQBMC);
  };
  // Customizable Area End
}
