import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  Modal,
  Button,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Stepper from "../../../components/src/Stepper.web";
import TimerIcon from "@material-ui/icons/Timer";
import Loader from "../../../components/src/Loader.web";
import TestComponent from "../../../components/src/TestComponent.web";
import { styles } from "./styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import { clean } from "./assets";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// Customizable Area End

import GuestPracticeTestController, {
  Props,
} from "./GuestPracticeTestController.web";

class GuestPracticeTest extends GuestPracticeTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const totalSeconds = Math.floor(this.state.timeRemaining / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = totalSeconds - hours * 3600 - minutes * 60;

    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Modal
          open={this.state.showModalSubmit}
          BackdropProps={{ onClick: undefined }}
          data-test-id="modal-here"
        >
          <Box sx={webStylePTGuest.modalStyle} className="submit-test-question">
            <CheckCircleIcon style={webStylePTGuest.circleModal} />
            <Typography style={webStylePTGuest.headerStyleModal}>
              Demo Test Submitted
            </Typography>
            <Typography style={webStylePTGuest.subStyleModal}>
              To get access for real test and more content you need to register.
            </Typography>
            <Button
              style={webStylePTGuest.buttonStyleYes}
              data-test-id="get-here"
              onClick={this.navigateToRegister}
            >
              Get Started
            </Button>
            <Button
              style={webStylePTGuest.buttonStyleCancel}
              data-test-id="cancel-here"
              onClick={this.showModalSubmit}
            >
              Cancel
            </Button>
          </Box>
        </Modal>
        <Box style={webStylePTGuest.stepperContainer}>
          <Stepper
            steps={this.state.testData.length}
            activeStep={this.state.testDataIndex + 1}
          />
          <Typography>
            {this.state.testDataIndex + 1}/{this.state.testData.length}
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            gap: "1em",
            padding: "1em 2em",
            alignItems: "center",
          }}
        >
          <p className={this.props.classes.practiceTestTimer}>
            <span>
              <TimerIcon />
            </span>
            {String(hours).length > 1 ? hours : `${hours}`}:
            {String(minutes).length > 1 ? minutes : `0${minutes}`}:
            {String(seconds).length > 1 ? seconds : `0${seconds}`}
          </p>
        </Box>
        <Box className={this.props.classes.questionOverview} data-test-id="rendered-question">
          This question carries +
          {this.state.testData[this.state.testDataIndex]?.attributes.p_mark} for
          Correct Answer and -
          {Number(
            this.state.testData[this.state.testDataIndex]?.attributes.n_mark
          )}{" "}
          for wrong Answer
        </Box>
        <Box className={this.props.classes.mainContainerPracticeTest}>
          {this.state.testData.length > 0 && (
            <TestComponent
              question={this.state.testData[this.state.testDataIndex]}
              index={this.state.testDataIndex}
              setAnswer={this.setAnswer}
              answers={this.state.answers}
            />
          )}
          <Box className="overview-practice-test">
            <Box className="overview-board">
              <h3>Overview</h3>
              <Box className="overview-btns">
                {this.state.testData.map((question, index) => {
                  const className = this.getBtnClassName(index);
                  return (
                    <button
                      data-test-id={`specific-btn-test-id`}
                      key={`button-${index}`}
                      className={className}
                      onClick={() => this.handleSpecificQuestion(index)}
                    >
                      {question.attributes.question_no}
                    </button>
                  );
                })}
              </Box>
            </Box>
            <Box className="overview-legends">
              <p>
                <span>
                  <FiberManualRecordIcon style={{ color: "#9CBA58" }} />
                </span>
                Answered{" "}
                <span style={{ marginLeft: "0.8em" }}>
                  <FiberManualRecordIcon style={{ color: "#4B4B4B" }} />
                </span>
                Un-Answered
              </p>
              <p>
                <span>
                  <FiberManualRecordIcon style={{ color: "#FF8147" }} />
                </span>
                Answered & Marked for Review
              </p>
              <p>
                <span>
                  <FiberManualRecordIcon style={{ color: "#DD1000" }} />
                </span>
                Not Answered & Marked for Review
              </p>
              <p>
                <span>
                  <FiberManualRecordIcon style={{ color: "#69B5FF" }} />
                </span>
                Answered & Cleared the answer
              </p>
              <p>
                <span>
                  <FiberManualRecordIcon style={{ color: "#D426C9" }} />
                </span>
                Marked & Unmarked for review
              </p>
            </Box>
          </Box>
        </Box>
        <Box className={this.props.classes.btnsPracticeTest}>
          <Box className="submit-mark-practice-btns">
            <button
              className="submit-test-btn"
              data-test-id={`submitTest`}
              onClick={this.showModalSubmit}
            >
              Submit Test
            </button>
          </Box>
          <Box className="navigation-practice-btns">
            <Box className="bookmark-clear-box">
              <Box
                onClick={this.markForReview}
                className="bookmark-icon-review"
                data-test-id="markforreview"
              >
                <BookmarkIcon />
              </Box>
              <Box
                data-test-id="clrbtn"
                className="clear-icon"
                onClick={this.clearResponse}
              >
                <img src={clean} alt="clear-icon" />
              </Box>
            </Box>
            <Box>
              <button
                onClick={this.handlePreviousQuestion}
                data-test-id={`prevbtn`}
              >
                <ChevronLeftIcon />
              </button>
              <button data-test-id={`nxtbtn`} onClick={this.handleNextQuestion}>
                <ChevronRightIcon />
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStylePTGuest = {
  stepperContainer: { display: "flex", gap: "1em", padding: "1em 2em 0 2em" },
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    top: "50%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute" as "absolute",
    bgcolor: "background.paper",
    border: "2px solid #6E1186",
    borderRadius: "1.5em",
    gap: "1.5em",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "4em 2em",
    width: "400px",
    minHeight: "max-content",
    outline: "none",
  },
  buttonStyleYes: {
    width: "300px",
    color: "white",
    height: "60px",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    textTransform: "none" as const,
    borderRadius: "0.5em",
    fontWeight: 700,
  },
  buttonStyleCancel: {
    width: "300px",
    color: "#222",
    height: "60px",
    textTransform: "none" as const,
    border: "1px solid #FE6555",
    borderRadius: "0.5em",
    fontWeight: 700,
  },
  headerStyleModal: {
    color: "#222",
    textAlign: "center" as const,
    fontWeight: 700,
    fontSize: "1.5em",
  },
  subStyleModal: {
    color: "#aaa",
    marginBottom: "2em",
    textAlign: "center" as const,
  },
  circleModal: {
    fontSize: "7em",
    color: "#FF6A54",
  },
};

export default withStyles(styles)(GuestPracticeTest);
export { GuestPracticeTest };
// Customizable Area End
