Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.videoName = "Video"
exports.swayamPrabhaFilter = "/bx_block_videos8/swayamprabhas"
exports.noData = "No About Found"
exports.veryLikely = "Very likely"
exports.notLikly = "Not Likely"
exports.ratingT2 = "myrank to a friend or colleague?"
exports.ratingT1 = " How likely would you be to recommend"
exports.ratingTitle = "How can we do better"
exports.viewRecordingEndPoint = "/bx_block_videos8/swayamprabhas/{id}"
exports.videoRegex =  /embed\/([A-Za-z0-9_-]+)/
exports.GoBack = "Go Back"
exports.FeedBackLabel = "Feedback submitted succesfully"
exports.ThankY = "ThankYou!"
exports.ratingEndPoint = "/bx_block_videos8/swayamprabhas/{id}/rating"
exports.send = "Send"
exports.Cancel = 'Cancel'
exports.EnterYMsg = "Enter your Feedback"
exports.reportLabel = "What do you want to report?"
exports.reportEndPoint = "/bx_block_videos8/swayamprabhas/{id}/report"
exports.RecordScreenLabel = "Recorded Classes"
exports.SortTitle = "Sort By"
exports.Done = "Done"
exports.cancel = "Cancel"
exports.ChapterName = "Chapter name"
exports.FilterTitle = "Filter By"
exports.Nodata = "No Data Found"
exports.Search = "Search"
exports.ClassName = 'Video Name'
exports.swayamPrabhaSubjectList = "/bx_block_videos8/swayamprabhas"
exports.aboutEndPoint = "/bx_block_videos8/swayamprabhas/about"
exports.about = "About"
exports.view = "View"
exports.Chapter = 'Chapter'
exports.subject = "Subject"
exports.aboutLabel = "About"
exports.Headlabel = 'Swayam Prabha'
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "VideoManagement";
exports.labelBodyText = "VideoManagement Body";
exports.swayamprabhaEndPoint = "/bx_block_videos8/swayamprabhas?subject_name={subjectName}&chapter_name={chapterName}&sort_by={sortBy}&search={searchFilter}"
exports.btnExampleTitle = "CLICK ME";
exports.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NzU0LCJleHAiOjE3MDk5NzAwOTEsInRva2VuX3R5cGUiOiJsb2dpbiJ9.j-7wecH1KiRvhdHSRoMv9gbzclRqKXF3MjHj7wk3IBMuP6GywPqhFMyknSqIBoNJ0MHoRhcZyAyOKW9NZB_M3g"
// Customizable Area End