export function extractParams(url: string, paramName: string): string | null {
  const regex = new RegExp(`\\?${paramName}=([^&]+)`);
  const match = url.match(regex);
  if (match) {
    return match[1];
  }

  return null;
}

import { OptionTypeBase, StylesConfig } from 'react-select';

interface OptionType<T> extends OptionTypeBase {
  value: T;
  label: string;
}

export const colourStyles: StylesConfig<OptionType<any>, false> = {
  input: (styles) => ({
    ...styles,
    color: '#510081',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#510081',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#510081',
  }),
  control: (styles) => ({
    ...styles,
    border: '1px solid #FF6B55',
    height: '51px',
    borderRadius: '0.5em',
    '&:hover': {
      outline: 'none',
    },
    boxShadow: 'none',
  }),
};

export const colourStyles2: StylesConfig<OptionType<any>, false> = {
  input: (styles) => ({
    ...styles,
    color: '#222',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#222',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#8E8E8E',
  }),
  control: (styles) => ({
    ...styles,
    border: '1px solid #FF6B55',
    height: '51px',
    borderRadius: '0.5em',
    '&:hover': {
      outline: 'none',
    },
    boxShadow: 'none',
  }),
};
