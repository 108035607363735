// export const noNetwork = require('../assets/nonetwork.jpg')
//export const bird = require('../assets/bird.png')
//export const Background = require('../assets/logInBackground.png')
//export const MY_RANK_LOGO =  require('../../components/assets/MyRanklogo.png')
// export const backIcon =  require('../../components/assets/backIcon.png')
export const backIcon = require('./backIcon.png')
export const myranklogo = require("./myrank_svg-logo.png")
export const ThreeDotsSelection =  require('./more.png')
export const ButtonBG = require('./CustomErrorModal/assets/button_filled.png')
export const rightIcon = require('./CustomErrorModal/assets/submitIcon.png')

