import React from "react";
import { Box } from "@material-ui/core";
import WebRoutesGenerator from "./WebRoutesGenerator.web";
import { Redirect } from "react-router-dom";

// Dashboard Home
import DashboardHome from "../../blocks/dashboard/src/DashboardHome.web";

// Practice Test Components
import PracticeTestAttempts from "../../blocks/CfTestManagement2/src/PracticeTestAttempts.web";
import PracticeTestReview from "../../blocks/CfTestManagement2/src/PracticeTestReview.web";
import ReviewTest from "../../blocks/CfTestManagement2/src/ReviewTest.web";
import ReviewTestMain from "../../blocks/CfTestManagement2/src/ReviewTestMain.web";
import SavedNotes from "../../blocks/CfTestManagement2/src/SavedNotes.web";
import Bookmark from "../../blocks/CfTestManagement2/src/Bookmark.web";
import SavedNotesAddEdit from "../../blocks/CfTestManagement2/src/SavedNotesAddEdit.web";
import PracticeTestTaking from "../../blocks/CfTestManagement2/src/PracticeTestTaking.web";

// Weekly Test Components
import WeeklyTestReview from "../../blocks/CfTestManagement2/src/WeeklyTestReview.web";
import WeeklyTestMain from "../../blocks/CfTestManagement2/src/WeeklyTestMain.web";
import ReviewTestWeekly from "../../blocks/CfTestManagement2/src/ReviewTestWeekly.web";
import ReviewTestWeeklyMain from "../../blocks/CfTestManagement2/src/ReviewTestWeeklyMain.web";
import WeeklyTestSavedNotes from "../../blocks/CfTestManagement2/src/WeeklyTestSavedNotes.web";
import WeeklyTestBookmark from "../../blocks/CfTestManagement2/src/WeeklyTestBookmark.web";
import WeeklyTestSavedNotesAddEdit from "../../blocks/CfTestManagement2/src/WeeklyTestSavedNotesAddEdit.web";
import WeeklyTestAttempts from "../../blocks/CfTestManagement2/src/WeeklyTestAttempts.web";
import WeeklyTestTaking from "../../blocks/CfTestManagement2/src/WeeklyTestTaking.web";
import WeeklyTestTakeTestReview from "../../blocks/CfTestManagement2/src/WeeklyTestTakeTestReview.web";

// Grand Test Component
import GrandTestAttempts from "../../blocks/CfTestManagement2/src/GrandTestAttempts.web";
import GrandTestTaking from "../../blocks/CfTestManagement2/src/GrandTestTaking.web";
import GrandTestReview from "../../blocks/CfTestManagement2/src/GrandTestTakingReview.web";
import GrandTestReviewTest from "../../blocks/CfTestManagement2/src/GrandTestReviewTest.web";
import GrandTestReviewTestMain from "../../blocks/CfTestManagement2/src/GrandTestReviewTestMain.web";
import GrandTestBookmark from "../../blocks/CfTestManagement2/src/GrandTestBookmark.web";
import GrandTestSavedNotes from "../../blocks/CfTestManagement2/src/GrandTestSavedNotes.web";
import GrandTestSavedNotesMain from "../../blocks/CfTestManagement2/src/GrandTestSavedNotesMain.web";

// Synopsis Components
import SynopsisChapters from "../../blocks/ContentManagement/src/SynopsisChapters.web";
import SynopsisSubjects from "../../blocks/ContentManagement/src/SynopsisSubjects.web";
import SynopsisTopics from "../../blocks/ContentManagement/src/SynopsisTopics.web";
import SynopsisTopicRead from "../../blocks/ContentManagement/src/SynopsisTopicRead.web";
import SynopsisBookmarks from "../../blocks/ContentManagement/src/SynopsisBookmarks.web";
import SynopsisSavedNotes from "../../blocks/ContentManagement/src/SynopsisSavedNotes.web";
import SynopsisAddEditNotes from "../../blocks/ContentManagement/src/SynopsisSavedNotesAddEdit.web";

// Question Bank Components
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank.web";
import QuestionBankChapters from "../../blocks/QuestionBank/src/QuestionBankChapters.web";
import QuestionBankMain from "../../blocks/QuestionBank/src/QuestionBankMain.web";
import QuestionBankBookmarks from "../../blocks/QuestionBank/src/QuestionBankBookmarks.web";
import QuestionBankSavedNotes from "../../blocks/QuestionBank/src/QuestionBankSavedNotes.web";
import QuestionBankSavedNotesAddEdit from "../../blocks/QuestionBank/src/QuestionBankSavedNotesAddEdit.web";

// Swayam Prabha
import SwayamPrabha from "../../blocks/videos/src/SwayamPrabha.web";

// Recorded Classes
import RecordedClassVideo from "../../blocks/VideoLibrary/src/RecordedClassVideo.web";
import RecordedClass from "../../blocks/VideoLibrary/src/RecordedClass.web";

// Live Classes
import LiveClass from "../../blocks/CfZoomIntegration16/src/LiveClass.web";
import LiveClassMaterial from "../../blocks/CfZoomIntegration16/src/LiveClassMaterial.web";

// My Packages
import MyPackage from "../../blocks/categoriessubcategories/src/MyPackages.web";
import BreadcrumbNavigationDisplay from "../../blocks/BreadcrumbNavigation/src/BreadcrumbNavigationDisplay.web";

// Calculator
import Calculate from "../../blocks/Scoring/src/Calculate.web";

// Ask your Query
import AskYourQueryBadges from "../../blocks/CustomisableUserProfiles/src/AYQBadges.web";
import AskYourQueryLeaderboard from "../../blocks/CustomisableUserProfiles/src/AYQLeaderboard.web";
import AskYourQueryQuestion from "../../blocks/CommunityForum/src/AYQQuestion.web";
import AskYourQueryQuestionAnswer from "../../blocks/CommunityForum/src/AYQQuestionAnswer.web";
import AskYourQueryProfilePeek from "../../blocks/CommunityForum/src/AYQProfilePeek.web";
import AskYourQueryProfile from "../../blocks/CustomisableUserProfiles/src/AYQProfile.web";
import AskYourQueryEditProfile from "../../blocks/CustomisableUserProfiles/src/AYQEditProfile.web";
import AskYourQueryQuestionsAsked from "../../blocks/CustomisableUserProfiles/src/AYQQuestionsAsked.web";
import AskYourQueryReceivedAnswers from "../../blocks/CustomisableUserProfiles/src/AYQReceivedAnswers.web";
import AskYourQueryQuestionToAsk from "../../blocks/CustomisableUserProfiles/src/AYQAskQuestion.web";
import AskYourQuerySimilarQuestion from "../../blocks/CustomisableUserProfiles/src/AYQSimilarQuestion.web";

// Book My Slot Counselling Manager
import BookMySlot from "../../blocks/scheduling/src/BookMySlot.web";
import BookMySlotAppointments from "../../blocks/scheduling/src/BookMySlotAppointments.web";
import BookMySlotAddEditSlot from "../../blocks/scheduling/src/BookMySlotAddEditSlot.web";
import BookMySlotDateAndTimeSlot from "../../blocks/scheduling/src/BookMySlotDateTimePick.web";
import BookMySlotBookings from "../../blocks/scheduling/src/BookMySlotBookings.web";
import BookMySlotChangeSlot from "../../blocks/scheduling/src/BookMySlotChangeSlot.web";
import BookMySlotUpdateSlot from "../../blocks/scheduling/src/BookMySlotUpdateSlot.web";
import BookMySlotFeedbacks from "../../blocks/scheduling/src/BookMySlotFeedbacks.web";

// Book My Slot Faculty
import BookMySlotFaculty from "../../blocks/scheduling/src/BookMySlotFaculty.web";
import BookMySlotFacultyFeedback from "../../blocks/scheduling/src/BookMySlotFacultyFeedback.web";
import BookMySlotFacultySlotAvailability from "../../blocks/scheduling/src/BookMySlotFacultySlotAvailability.web";

// Book My Slot Student
import BookMySlotStudent from "../../blocks/scheduling/src/BookMySlotStudent.web";
import BookMySlotStudentFeedbacks from "../../blocks/scheduling/src/BookMySlotStudentFeedbacks.web";
import BookMySlotStudentBookSlot from "../../blocks/scheduling/src/BookMySlotStudentBookSlot.web";
import BookMySlotStudentAppointments from "../../blocks/scheduling/src/BookMySlotStudentAppointments.web";

function ReRoute() {
  return <Redirect to="/dashboard/home" />;
}

export const routeMap = {
  BreadcrumbNavigation: {
    component: BreadcrumbNavigationDisplay,
  },
  DashboardHome: {
    component: DashboardHome,
    path: "/dashboard/home",
    exact: true,
  },
  DashboardReroute: {
    component: ReRoute,
    path: "/dashboard",
    exact: true,
  },
  // PRACTICE TEST ROUTES
  PracticeTestTaking: {
    component: PracticeTestTaking,
    path: "/dashboard/practice-test/chapters/:subjectId/test/:chapterId",
    exact: true,
  },
  PracticeTestAttempts: {
    component: PracticeTestAttempts,
    path: "/dashboard/practice-test/chapters/:subjectId",
    exact: true,
  },
  PracticeTestReview: {
    component: PracticeTestReview,
    path: "/dashboard/practice-test/chapters/:subjectId/result-overview/:chapterId",
    exact: true,
  },
  ReviewTest: {
    component: ReviewTest,
    path: "/dashboard/practice-test/chapters/:subjectId/review-test/:chapterId/review/:reviewId",
    exact: true,
  },
  ReviewTestMain: {
    component: ReviewTestMain,
    path: "/dashboard/practice-test/chapters/:subjectId/review-test/:chapterId",
    exact: true,
  },
  SavedNotes: {
    component: SavedNotes,
    path: "/dashboard/practice-test/chapters/:subjectId/review-test/:chapterId/saved-notes/:reviewId",
    exact: true,
  },
  Bookmark: {
    component: Bookmark,
    path: "/dashboard/practice-test/chapters/:subjectId/review-test/:chapterId/bookmarks/:reviewId",
    exact: true,
  },
  SavedNotesAddEdit: {
    component: SavedNotesAddEdit,
    path: "/dashboard/practice-test/chapters/:subjectId/review-test/:chapterId/saved-notes/:reviewId/note/:noteType/:qid?/:qno?/:noteId?",
    exact: true,
  },
  // WEEKLY TEST ROUTES
  WeekylyTest: {
    component: WeeklyTestMain,
    path: "/dashboard/weekly-test/instructions",
    exact: true,
  },
  WeekylyTestAttempts: {
    component: WeeklyTestAttempts,
    path: "/dashboard/weekly-test/attempts",
    exact: true,
  },
  WeekylyTestTaking: {
    component: WeeklyTestTaking,
    path: "/dashboard/weekly-test/tests/take-test/:testId",
    exact: true,
  },
  WeekylyTestReview: {
    component: WeeklyTestReview,
    path: "/dashboard/weekly-test/tests/take-test/:testId/overview/:reviewId",
    exact: true,
  },
  ReviewTestWeekly: {
    component: ReviewTestWeekly,
    path: "/dashboard/weekly-test/tests/review-test/:testId/review/:reviewId",
    exact: true,
  },
  ReviewTestWeeklyMain: {
    component: ReviewTestWeeklyMain,
    path: "/dashboard/weekly-test/tests/review-test/:testId",
    exact: true,
  },
  WeeklyTestSavedNotes: {
    component: WeeklyTestSavedNotes,
    path: "/dashboard/weekly-test/tests/review-test/:testId/review/:reviewId/saved-notes",
    exact: true,
  },
  WeeklyTestBookmark: {
    component: WeeklyTestBookmark,
    path: "/dashboard/weekly-test/tests/review-test/:testId/review/:reviewId/bookmarks",
    exact: true,
  },
  WeeklyTestSavedNotesAddEdit: {
    component: WeeklyTestSavedNotesAddEdit,
    path: "/dashboard/weekly-test/tests/review-test/:testId/review/:reviewId/saved-notes/notes",
    exact: true,
  },
  WeeklyTestTakeTestReview: {
    component: WeeklyTestTakeTestReview,
    path: "/dashboard/weekly-test/tests",
    exact: true,
  },
  // GRAND TEST ROUTES
  GrandTestAttempts: {
    component: GrandTestAttempts,
    path: "/dashboard/grand-test/subjects/:packageId",
    exact: true,
  },
  GrandTestTaking: {
    component: GrandTestTaking,
    path: "/dashboard/grand-test/subjects/:packageId/test/:subjectId",
    exact: true,
  },
  GrandTestReview: {
    component: GrandTestReview,
    path: "/dashboard/grand-test/subjects/:packageId/review/:testId/:subjectId",
    exact: true,
  },
  GrandTestReviewTest: {
    component: GrandTestReviewTest,
    path: "/dashboard/grand-test/subjects/:packageId/review-test/:subjectId",
    exact: true,
  },
  GrandTestReviewTestMain: {
    component: GrandTestReviewTestMain,
    path: "/dashboard/grand-test/subjects/:packageId/review-test/:subjectId/review/:testId",
    exact: true,
  },
  GrandTestBookmarks: {
    component: GrandTestBookmark,
    path: "/dashboard/grand-test/subjects/:packageId/review-test/:subjectId/review/:testId/bookmarks",
    exact: true,
  },
  GrandTestSavedNotes: {
    component: GrandTestSavedNotes,
    path: "/dashboard/grand-test/subjects/:packageId/review-test/:subjectId/review/:testId/saved-notes",
    exact: true,
  },
  GrandTestSavedNotesMain: {
    component: GrandTestSavedNotesMain,
    path: "/dashboard/grand-test/subjects/:packageId/review-test/:subjectId/review/:testId/saved-notes/notes/:noteType/:qno?/:qid?/:noteId?",
    exact: true,
  },
  // SYNOPSIS ROUTES
  SynopsisSubjects: {
    component: SynopsisSubjects,
    path: "/dashboard/synopsis",
    exact: true,
  },
  SynopsisChapters: {
    component: SynopsisChapters,
    path: "/dashboard/synopsis/chapters/:subjectId",
    exact: true,
  },
  SynopsisTopics: {
    component: SynopsisTopics,
    path: "/dashboard/synopsis/chapters/:subjectId/topics/:chapterId",
    exact: true,
  },
  SynopsisTopicRead: {
    component: SynopsisTopicRead,
    path: "/dashboard/synopsis/chapters/:subjectId/topics/:chapterId/topic-read/:topicId/:highlighted",
    exact: true,
  },
  SynopsisBookmarks: {
    component: SynopsisBookmarks,
    path: "/dashboard/synopsis/chapters/:subjectId/topics/:chapterId/bookmarks/:topicId",
    exact: true,
  },
  SynopsisSavedNotes: {
    component: SynopsisSavedNotes,
    path: "/dashboard/synopsis/chapters/:subjectId/topics/:chapterId/saved-notes/:topicId",
    exact: true,
  },
  SynopsisAddEditNotes: {
    component: SynopsisAddEditNotes,
    path: "/dashboard/synopsis/chapters/:subjectId/topics/:chapterId/topic-read/:topicId/notes/:noteId/:noteType",
    exact: true,
  },
  // QUESTION BANK ROUTES
  QuestionBank: {
    component: QuestionBank,
    path: "/dashboard/question-bank",
    exact: true,
  },
  QuestionBankChapters: {
    component: QuestionBankChapters,
    path: "/dashboard/question-bank/subject/:subjectId",
    exact: true,
  },
  QuestionBankMain: {
    component: QuestionBankMain,
    path: "/dashboard/question-bank/subject/:subjectId/chapter/:chapterId",
    exact: true,
  },
  QuestionBankBookmarks: {
    component: QuestionBankBookmarks,
    path: "/dashboard/question-bank/subject/:subjectId/chapter/:chapterId/bookmark",
    exact: true,
  },
  QuestionBankSavedNotes: {
    component: QuestionBankSavedNotes,
    path: "/dashboard/question-bank/subject/:subjectId/chapter/:chapterId/saved-notes",
    exact: true,
  },
  QuestionBankSavedNotesAddEdit: {
    component: QuestionBankSavedNotesAddEdit,
    path: "/dashboard/question-bank/subject/:subjectId/chapter/:chapterId/saved-notes/notes",
    exact: true,
  },
  // SWAYAM PRABHA ROUTES
  SwayamPrabha: {
    component: SwayamPrabha,
    path: "/dashboard/swayam-prabha",
    exact: true,
  },
  SwayamClassVideo: {
    component: RecordedClassVideo,
    path: "/dashboard/swayam-prabha/watch",
    exact: true,
  },
  // RECORDED CLASS ROUTES
  RecordedClassVideo: {
    component: RecordedClassVideo,
    path: "/dashboard/classes/recorded-class/watch",
    exact: true,
  },
  RecordedClass: {
    component: RecordedClass,
    path: "/dashboard/classes/recorded-class",
    exact: true,
  },
  // LIVE CLASS ROUTES
  LiveClass: {
    component: LiveClass,
    path: "/dashboard/classes/live-class",
    exact: true,
  },
  LiveClassMaterial: {
    component: LiveClassMaterial,
    path: "/dashboard/classes/live-class/material-note/:type/:id",
    exact: true,
  },
  // MY PACKAGE ROUTE
  MyPackage: {
    component: MyPackage,
    path: "/dashboard/my-package",
    exact: true,
  },
  // CALCULATE ROUTE
  Calculate: {
    component: Calculate,
    path: "/dashboard/calculate",
    exact: true,
  },
  AskYourQueryBadges: {
    component: AskYourQueryBadges,
    path: "/dashboard/ask-your-query/badges",
    exact: true,
  },
  AskYourQueryLeaderboard: {
    component: AskYourQueryLeaderboard,
    path: "/dashboard/ask-your-query/leaderboard",
    exact: true,
  },
  AskYourQueryQuestion: {
    component: AskYourQueryQuestion,
    path: "/dashboard/ask-your-query/questions-all/:navigationCurr?",
    exact: true,
  },
  AskYourQueryQuestionAnswer: {
    component: AskYourQueryQuestionAnswer,
    path: "/dashboard/ask-your-query/answer/:questionId",
    exact: true,
  },
  AskYourQueryProfilePeek: {
    component: AskYourQueryProfilePeek,
    path: "/dashboard/ask-your-query/profile-view/:profileId",
    exact: true,
  },
  AskYourQueryProfile: {
    component: AskYourQueryProfile,
    path: "/dashboard/ask-your-query/profile",
    exact: true,
  },
  AskYourQueryEditProfile: {
    component: AskYourQueryEditProfile,
    path: "/dashboard/ask-your-query/profile/edit-profile",
    exact: true,
  },
  AskYourQueryQuestionsAsked: {
    component: AskYourQueryQuestionsAsked,
    path: "/dashboard/ask-your-query/questions-asked",
    exact: true,
  },
  AskYourQueryReceivedAnswers: {
    component: AskYourQueryReceivedAnswers,
    path: "/dashboard/ask-your-query/received-answers/:questionId",
    exact: true,
  },
  AskYourQueryQuestionToAsk: {
    component: AskYourQueryQuestionToAsk,
    path: "/dashboard/ask-your-query/ask-a-question",
    exact: true,
  },
  AskYourQuerySimilarQuestion: {
    component: AskYourQuerySimilarQuestion,
    path: "/dashboard/ask-your-query/similar-question/:search",
    exact: true,
  },
  BookMySlot: {
    component: BookMySlot,
    path: "/dashboard/book-my-slot",
    exact: true,
  },
  BookMySlotAppointments: {
    component: BookMySlotAppointments,
    path: "/dashboard/book-my-slot/appointments",
    exact: true,
  },
  BookMySlotAddEditSlot: {
    component: BookMySlotAddEditSlot,
    path: "/dashboard/book-my-slot/add-edit-slot",
    exact: true,
  },
  BookMySlotDateAndTimeSlot: {
    component: BookMySlotDateAndTimeSlot,
    path: "/dashboard/book-my-slot/date-time-slot",
    exact: true,
  },
  BookMySlotBookings: {
    component: BookMySlotBookings,
    path: "/dashboard/book-my-slot/slot-bookings",
    exact: true,
  },
  BookMySlotChangeSlot: {
    component: BookMySlotChangeSlot,
    path: "/dashboard/book-my-slot/change-slot",
    exact: true,
  },
  BookMySlotUpdateSlot: {
    component: BookMySlotUpdateSlot,
    path: "/dashboard/book-my-slot/change-slot/update",
    exact: true,
  },
  BookMySlotFeedbacks: {
    component: BookMySlotFeedbacks,
    path: "/dashboard/book-my-slot/slot-bookings/feedback",
    exact: true,
  },
  BookMySlotFeedbacksAppointment: {
    component: BookMySlotFeedbacks,
    path: "/dashboard/book-my-slot/appointments/feedback",
    exact: true,
  },
  BookMySlotFacultyBookings: {
    component: BookMySlotFaculty,
    path: "/dashboard/book-my-slot-faculty",
    exact: true,
  },
  BookMySlotFacultyFeedback: {
    component: BookMySlotFacultyFeedback,
    path: "/dashboard/book-my-slot-faculty/feedback",
    exact: true,
  },
  BookMySlotFacultySlotAvailability: {
    component: BookMySlotFacultySlotAvailability,
    path: "/dashboard/book-my-slot-faculty/availability",
    exact: true,
  },
  BookMySlotStudent: {
    component: BookMySlotStudent,
    path: "/dashboard/book-my-slot-student",
    exact: true,
  },
  BookMySlotStudentBookSlot: {
    component: BookMySlotStudentBookSlot,
    path: "/dashboard/book-my-slot-student/book-slot",
    exact: true,
  },
  BookMySlotStudentAppointments: {
    component: BookMySlotStudentAppointments,
    path: "/dashboard/book-my-slot-student/appointments",
    exact: true,
  },
  BookMySlotStudentFeedbacks: {
    component: BookMySlotStudentFeedbacks,
    path: "/dashboard/book-my-slot-student/appointments/feedback",
    exact: true,
  },
  // BREADCRUMB ROUTE
};

export default function RoutesDashboard() {
  return <Box>{WebRoutesGenerator({ routeMap })}</Box>;
}
