import React from "react";

import {
  Box,
  // Customizable Area Start
  Button as MUIButton,
  Modal,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Button from "../../../components/src/Button.web";
import { noreview, Delete, edit } from "./assets";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// Customizable Area End

import WeeklyTestSavedNotesController, {
  Props,
} from "./WeeklyTestSavedNotesController.web";

export default class WeeklyTestSavedNotes extends WeeklyTestSavedNotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        <Modal
          onClose={this.handleDeletePromptClose2}
          open={this.state.isConfirmDeletePromptOpen}
          BackdropProps={{ onClick: undefined }}
        >
          <Box style={webStyle.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>Deleted Note Successfully</h3>
            <div>
              <MUIButton
                style={webStyle.buttonStyleYes}
                data-test-id="goback-btn"
                onClick={this.handleDeletePromptClose2}
              >
                Go Back
              </MUIButton>
            </div>
          </Box>
        </Modal>
        <Modal
          open={this.state.isDeletePromptOpen}
          onClose={this.handleDeletePromptClose}
          BackdropProps={{ onClick: undefined }}
        >
          <Box style={webStyle.modalStyle} className="submit-test-question">
            <h3>Are you sure to delete this Note?</h3>
            <div>
              <MUIButton
                style={webStyle.buttonStyleNo}
                data-test-id="cancel-delete"
                onClick={this.handleDeletePromptClose}
              >
                Cancel
              </MUIButton>
              <MUIButton
                style={webStyle.buttonStyleYes}
                data-test-id="delete-confirm"
                onClick={() =>
                  this.deleteSavedNotes(this.noteIdToDelete, this.indexToDelete)
                }
              >
                Delete
              </MUIButton>
            </div>
          </Box>
        </Modal>
        <div style={webStyle.bannerStyle}>
          <h2>Saved Notes</h2>
        </div>
        {this.state.notes.length === 0 ? (
          <div style={webStyle.setMiddleStyleColumn2}>
            <div style={webStyle.setMiddleStyleColumn2}>
              <img src={noreview} alt="noreview" width={"600px"} />
              <h2>You didn't take test to Review</h2>
              <p>Click on Take Test to get Review</p>
            </div>
            <Link
              to={`${this.props.navigation.history.location.pathname}/notes?noteType=New&reviewId=${this.state.reviewId}`}
            >
              <Button
                color="white"
                height="60px"
                title="Create Note"
                width="500px"
              />
            </Link>
          </div>
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <div style={webStyle.centerVertically}>
              <Typography variant="h6">Exam : {this.state.examName}</Typography>
              <Typography variant="h6">
                Total Notes - {this.state.notes.length}
              </Typography>
            </div>

            <div style={webStyle.centerHorizontally}>
              {this.state.notes.map((note, index) => {
                return (
                  <div style={webStyle.cardBookmark}>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      Note: {note.notes_content}
                    </Typography>
                    <div style={webStyle.cardBookmark2}>
                      <p>Question No: {note.question_no}</p>
                      <div>
                        <MUIButton>
                          <Link
                            to={`${this.props.navigation.history.location.pathname}/notes?noteType=Edit&reviewId=${this.state.reviewId}&qno=${note.question_no}&qid=${note.question_id}&noteId=${note.id}`}
                          >
                            <img
                              src={edit}
                              alt="edit-png"
                              style={webStyle.imgStyle}
                            />
                          </Link>
                        </MUIButton>
                        <MUIButton
                          onClick={() =>
                            this.deletePromptOpen(String(note.id), index)
                          }
                          data-test-id="delete-btn"
                        >
                          <img
                            src={Delete}
                            alt="delete-png"
                            style={webStyle.imgStyle}
                          />
                        </MUIButton>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Box>
        )}
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyleNo: {
    width: "130px",
    height: "50px",
    color: "#4F1f6d",
    background: "white",
    border: "1px solid #ffe9ed",
    borderRadius: "0.6em",
    textTransform: "none" as const,
    cursor: "pointer",
  },
  setMiddleStyleColumn2: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "2em",
  },
  bannerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    color: "rgb(82, 0, 130)",
  },
  centerVertically: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "60%",
    margin: "2em",
  },
  buttonStyleYes: {
    width: "130px",
    height: "50px",
    color: "white",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    textTransform: "none" as const,
    marginLeft: "1em",
    cursor: "pointer",
  },
  centerHorizontally: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "1em",
    width: "100%",
  },
  imgStyle: {
    width: "20px",
  },
  modalStyle: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    minHeight: "max-content",
    backgroundColor: "white",
    border: "2px solid #FFD0D8",
    borderRadius: "1.5em",
    padding: "2em",
    gap: "1.5em",
  },
  cardBookmark2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardBookmark: {
    width: "60%",
    height: "116.8px",
    background: "rgba(209, 170, 255, 0.14)",
    border: "1px solid #FCC2C0",
    borderRadius: "10px",
    padding: "1em",
  },
};
// Customizable Area End
