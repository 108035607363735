import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
export type AnalysisType = {
  college_data: {
    id: number;
    college_name: string;
    state_name: string;
    councelling_id: string | number | null;
    contact1: string;
    district: string;
    hostel: string | number | null;
    established: number;
    website: string;
    email: string;
    address: string;
    remarks: string;
    status: string | number | null;
    link_myrank: string;
    wikipedia: string;
    contact2: string;
    region: string;
    college_type: string | number | null;
    college_type2: string | number | null;
    remarks_2: string;
    created_at: string;
    updated_at: string;
  };
  course_data: {
    id: number;
    course_name: string;
    mbipc: string | number | null;
    duration: number;
    status: string;
    degree: string;
    jee_id: string | number | null;
    english: string | number | null;
    mpc: string;
    mec: string | number | null;
    cec: string | number | null;
    bipc: string | number | null;
    hec: string | number | null;
    hslc: string | number | null;
    specialization: string;
    scope: string;
    short_code: string;
    remarks: string;
    remarks_deletion: string;
    created_at: string;
    updated_at: string;
  };
  cutoff_data: {
    id: number;
    college_id: number;
    course_id: number;
    gen_min: string;
    gen_max: string;
    female_min: string;
    female_max: string;
    gen_seats: number;
    female_seats: number;
    quota: string;
    college_type: string;
    r1gen_min: string | number | null;
    r1gen_max: string | number | null;
    r1female_min: string | number | null;
    r1female_max: string | number | null;
    r2gen_min: string | number | null;
    r2gen_max: string | number | null;
    r2female_min: string | number | null;
    r2female_max: string | number | null;
    r3gen_min: string | number | null;
    r3gen_max: string | number | null;
    r3female_min: string | number | null;
    r3female_max: string | number | null;
    r4gen_min: string | number | null;
    r4gen_max: string | number | null;
    r4female_min: string | number | null;
    r4female_max: string | number | null;
    r5gen_min: string | number | null;
    r5gen_max: string | number | null;
    r5female_min: string | number | null;
    r5female_max: string | number | null;
    r6gen_min: string | number | null;
    r6gen_max: string | number | null;
    r6female_min: string | number | null;
    r6female_max: string | number | null;
    remarks: string | number | null;
    created_at: string;
    updated_at: string;
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentPage: number;
  loading: boolean;
  numberOfPages: number;
  analysisData: Array<AnalysisType>;
  totalCollegeName: number;
  totalCourseName: number;
  totalSeats: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MPCGuidanceAnalysisController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  dataPerPage = 10;
  getAnalysisDataMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentPage: 0,
      numberOfPages: 1,
      loading: false,
      totalCollegeName: 0,
      totalCourseName: 0,
      totalSeats: 0,
      analysisData: [
        {
          college_data: {
            id: 1,
            college_name: "madhav science",
            state_name: "MP",
            councelling_id: null,
            contact1: "9876543210",
            district: "Ujjain",
            hostel: null,
            established: 2,
            website: "",
            email: "",
            address: "",
            remarks: "",
            status: null,
            link_myrank: "",
            wikipedia: "",
            contact2: "",
            region: "",
            college_type: null,
            college_type2: null,
            remarks_2: "",
            created_at: "2023-10-17T18:26:34.367+05:30",
            updated_at: "2023-10-17T18:26:34.367+05:30",
          },
          course_data: {
            id: 1,
            course_name: "MCA",
            duration: 3.0,
            status: "block",
            degree: "mca",
            jee_id: null,
            mpc: "active",
            bipc: null,
            mbipc: null,
            english: null,
            mec: null,
            cec: null,
            hec: null,
            hslc: null,
            specialization: "",
            scope: "",
            short_code: "",
            remarks: "",
            remarks_deletion: "",
            created_at: "2023-10-17T18:27:57.796+05:30",
            updated_at: "2023-10-17T18:27:57.796+05:30",
          },
          cutoff_data: {
            id: 1,
            college_id: 1,
            course_id: 1,
            gen_min: "2022",
            gen_max: "200",
            female_min: "200",
            female_max: "300",
            gen_seats: 500,
            female_seats: 400,
            quota: "RN",
            college_type: "PVT",
            r1gen_min: null,
            r1gen_max: null,
            r1female_min: null,
            r1female_max: null,
            r2gen_min: null,
            r2gen_max: null,
            r2female_min: null,
            r2female_max: null,
            r3gen_min: null,
            r3gen_max: null,
            r3female_min: null,
            r3female_max: null,
            r4gen_min: null,
            r4gen_max: null,
            r4female_min: null,
            r4female_max: null,
            r5gen_min: null,
            r5gen_max: null,
            r5female_min: null,
            r5female_max: null,
            r6gen_min: null,
            r6gen_max: null,
            r6female_min: null,
            r6female_max: null,
            remarks: null,
            created_at: "2024-02-21T14:27:51.058+05:30",
            updated_at: "2024-02-21T14:27:51.058+05:30",
          },
        },
      ],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getAnalysisDataMessageId && response) {
      if (response.cutoff_analysis) {
        this.successfulFetch(response);
      }

      this.setState({
        loading: false,
      });
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getAnalysisData();
  };

  // Customizable Area Start
  changePage = (pageNumber: number) => {
    if (pageNumber < this.state.numberOfPages) {
      this.setState({
        currentPage: pageNumber,
      });
    }
  };

  countTotalSeats = (analysisData: Array<AnalysisType>) => {
    return analysisData.reduce((accumulator, current) => {
      const { gen_seats, female_seats } = current.cutoff_data;
      return accumulator + gen_seats + female_seats;
    }, 0);
  };

  countTotalCourseNames = (analysisData: Array<AnalysisType>) => {
    const object: { [courseName: string]: number } = {};
    analysisData.forEach((data) => {
      const courseName = data.course_data.course_name;
      if (object[courseName]) {
        object[courseName] = object[courseName] + 1;
      } else {
        object[courseName] = 1;
      }
    });

    const totalCount = Object.entries(object).reduce((accumulator, current) => {
      return accumulator + current[1];
    }, 0);

    return totalCount;
  };

  countTotalCollegeNames = (analysisData: Array<AnalysisType>) => {
    const object: { [collegeName: string]: number } = {};
    analysisData.forEach((data) => {
      const collegeName = data.college_data.college_name;
      if (object[collegeName]) {
        object[collegeName] = object[collegeName] + 1;
      } else {
        object[collegeName] = 1;
      }
    });

    const totalCount = Object.entries(object).reduce((accumulator, current) => {
      return accumulator + current[1];
    }, 0);

    return totalCount;
  };

  successfulFetch = (response: { cutoff_analysis: Array<AnalysisType> }) => {
    const totalSeats = this.countTotalSeats(response.cutoff_analysis);
    const totalCourseName = this.countTotalCourseNames(
      response.cutoff_analysis
    );
    const totalCollegeName = this.countTotalCollegeNames(
      response.cutoff_analysis
    );

    this.setState({
      analysisData: [...this.state.analysisData, ...response.cutoff_analysis],
      totalSeats,
      totalCourseName,
      totalCollegeName,
    });
  };

  getAnalysisData = async () => {
    this.setState({
      loading: true,
    });

    const getAnalysisData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getAnalysisDataMessageId = getAnalysisData.messageId;

    getAnalysisData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getAnalysisData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_tutorials2/cutoff_analysis?exam_name=jeeadv_2020&category=GN&round=0"
    );

    getAnalysisData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getAnalysisData.id, getAnalysisData);
  };
  // Customizable Area End
}
