import React from "react";

import {
  Box,
  // Customizable Area Start
  Button,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Loader from "../../../components/src/Loader.web";
import { Link } from "react-router-dom";
// Customizable Area End

import SavedNotesAddEditController, {
  Props,
} from "./SavedNotesAddEditController.web";

export default class SavedNotesAddEdit extends SavedNotesAddEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { noteType, questionNumber } = this.state;

    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box sx={webStyle.centerHorizontally}>
          <Modal
            open={this.state.isNoteCreateSavedSuccessfully}
            onClose={this.handleNoteCreateModalClose}
            BackdropProps={{ onClick: undefined }}
          >
            <Box sx={webStyle.modalStyle}>
              <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
              <h3>
                {this.state.noteType === "Edit"
                  ? "Changes Updated Successfully!"
                  : "Note Saved Successfully"}
              </h3>
              <Box>
                <Link
                  to={`/dashboard/practice-test/chapters/${this.props.navigation.getParam(
                    "subjectId"
                  )}/review-test/${this.props.navigation.getParam(
                    "chapterId"
                  )}/saved-notes/${this.props.navigation.getParam("reviewId")}${
                    this.searchResolve
                  }`}
                >
                  <Button
                    style={webStyle.buttonStyleYes}
                    data-test-id="go-saved-success"
                    onClick={this.handleNoteCreateModalClose}
                  >
                    Go to saved notes
                  </Button>
                </Link>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={this.state.isNoteCancellationModalOpen}
            onClose={this.handleNoteCancelModalClose}
            BackdropProps={{ onClick: undefined }}
          >
            <Box sx={webStyle.modalStyle}>
              <h3>Are you sure you want to cancel note without saving?</h3>
              <Box>
                <Button
                  style={webStyle.buttonStyleNo}
                  data-test-id="go-saved-no"
                  onClick={this.handleNoteCancelModalClose}
                >
                  No
                </Button>
                <Button
                  style={webStyle.buttonStyleYes}
                  data-test-id="go-saved-yes"
                  onClick={this.confirmCancel}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={this.state.cancelModalConfirm}
            onClose={this.handleNoteCancelConfirmModalClose}
            BackdropProps={{ onClick: undefined }}
          >
            <Box sx={webStyle.modalStyle}>
              <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
              <h3>Cancelled Note Successfully!</h3>
              <Box>
                <Link
                  to={`/dashboard/practice-test/saved-notes?reviewId=${this.state.reviewId}${this.searchResolve}`}
                >
                  <Button
                    onClick={this.handleNoteCancelConfirmModalClose}
                    data-test-id="go-back"
                    style={webStyle.buttonStyleYes}
                  >
                    Go Back
                  </Button>
                </Link>
              </Box>
            </Box>
          </Modal>

          <Box sx={webStyle.bannerStyle}>
            <h2>{noteType} Note</h2>
          </Box>
          <Box sx={webStyle.inputStyle}>
            <Box sx={webStyle.inputBannerStyle}>
              <p>Exam : {this.state.chapterName}</p>
              <p>Question No - {questionNumber}</p>
            </Box>
            <textarea
              data-test-id="text-area"
              name="textarea"
              value={this.state.content}
              onChange={(event) => this.handleInputChange(event.target.value)}
              style={webStyle.textInputStyle}
              placeholder="Write your note here"
            />
          </Box>
          <Box>
            <Button
              data-test-id="cancel-btn"
              style={webStyle.buttonStyleNo}
              onClick={this.handleNoteCancelModalOpen}
            >
              Cancel
            </Button>
            <Button
              data-test-id="save-btn"
              style={webStyle.buttonStyleYes}
              onClick={this.NoteApi}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  bannerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    color: "rgb(82, 0, 130)",
  },
  buttonStyleYes: {
    width: "200px",
    height: "50px",
    color: "white",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    textTransform: "none" as const,
    marginLeft: "1em",
  },
  buttonStyleNo: {
    width: "200px",
    height: "50px",
    color: "#4F1f6d",
    background: "white",
    border: "1px solid #ffe9ed",
    borderRadius: "0.6em",
    textTransform: "none" as const,
  },
  inputBannerStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid gray",
    height: "70px",
    width: "100%",
    padding: "0em 2em",
    fontSize: "24px",
  },
  inputStyle: {
    height: "max-content",
    minHeight: "300px",
    width: "90%",
    border: "1px solid rgb(255,167,167)",
  },
  centerHorizontally: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1em",
  },
  textInputStyle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    border: "none",
    width: "100%",
    minHeight: "300px",
    height: "100%",
    fontSize: "20px",
    padding: "1.2em 1.2em",
    outline: "none",
    ":focus": {
      outline: "none",
    },
    resize: "none" as const,
  },
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    minHeight: "max-content",
    bgcolor: "background.paper",
    border: "2px solid #FFD0D8",
    borderRadius: "1.5em",
    padding: "2em",
    gap: "1.5em",
  },
};
// Customizable Area End
