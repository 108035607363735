import React from "react";

import {
  Box,
  // Customizable Area Start
  createStyles,
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

import WeeklyTestMainController, {
  Props,
} from "./WeeklyTestMainController.web";
import Button from "../../../components/src/Button.web";
import Loader from "../../../components/src/Loader.web";

class WeeklyTestMain extends WeeklyTestMainController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box sx={webStyle.bannerStyle}>
          <h2>Weekly Test</h2>
        </Box>
        <Box margin={"1em 2em 1em 2em"}>
          <Box className={this.props.classes.instructionsWeekly}>
            <Box>
              <h3>Instructions</h3>
              <ul>
                {this.sortInstructions(this.state.instructions).map(
                  (instruction) => {
                    return (
                      <li key={`${instruction.content}`}>
                        {instruction.content}
                      </li>
                    );
                  }
                )}
              </ul>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          marginTop={"5em"}
          onClick={this.navigateToTakeTestReview}
        >
          <Button color="white" height="60px" title="Continue" width="300px" />
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  bannerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    color: "black",
  },
};

export const styles: any = () =>
  createStyles({
    weeklyTestClass: {
      display: "flex",
      padding: "1em",
    },
    instructionsWeekly: {
      display: "flex",
      padding: "1em",
      textWrap: "wrap",
      overflowWrap: "break-word",
      "& > div:nth-child(1)": {
        display: "flex",
        flexDirection: "column",
        background: "#f5fafe",
        height: "500px",
        overflowY: "hidden",
        width: "100%",
        minHeight: "350px",
        fontSize: "1.3em",
        padding: "1.5em",
        borderRadius: "0.6em",
        border: "2px solid #D8E6EF",
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#888",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
        "&:hover": {
          overflowY: "scroll",
        },
        "& ul": {
          marginLeft: "3em",
          "& li": {
            listStyle: "disc",
            margin: "1em 0",
          },
        },
      },
    },
  });

export { WeeklyTestMain };
export default withStyles(styles)(WeeklyTestMain);
// Customizable Area End
