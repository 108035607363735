import React from "react";

import {
  Box,
  // Customizable Area Start
  Typography,
  withStyles,
  createStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End

import LiveClassMaterialController, {
  Props,
} from "./LiveClassMaterialController.web";

class LiveClassMaterial extends LiveClassMaterialController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { currentType, currentlyDisplayed } = this.state;
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box className={this.props.classes.bannerStyle}>
          <Typography variant="h6" style={{ margin: "0 auto" }}>
            {currentType === "material" ? "Materials" : "Notes"}-
            {currentlyDisplayed?.attributes.subject_name}
          </Typography>
          <Typography variant="h6">
            {currentlyDisplayed?.attributes.chapter_name
              ? `Chapter - ${currentlyDisplayed?.attributes.chapter_name}`
              : `Topic - ${currentlyDisplayed?.attributes.topic_name}`}
          </Typography>
        </Box>
        <Box padding={"2em"}>
          <Box className={this.props.classes.liveClassMaterialContainer}>
            <Typography variant="h6" className="header-class">
              Chapter - {currentlyDisplayed?.attributes.id}
            </Typography>
            <div
              dangerouslySetInnerHTML={{
                __html: currentlyDisplayed?.attributes.content as string,
              }}
            ></div>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Pagination
            data-test-id="pagination"
            count={this.state.currentTable.length}
            page={this.state.currentPage}
            onChange={(_, value) => this.handlePageNumber(value)}
            className={this.props.classes.muiPaginationStyle}
          />
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleLiveClassMaterial = () =>
  createStyles({
    bannerStyle: {
      display: "flex",
      height: "71px",
      background: "rgb(244,239,246)",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: "0 2em",
      width: "100%",
      "& h6": {
        color: "#702F8C",
        fontWeight: 500,
      },
    },
    liveClassMaterialContainer: {
      padding: "1em 2em",
      background: "rgb(255,246,246)",
      overflowY: "hidden",
      height: "80vh",
      "& .header-class": {
        color: "#702F8C",
        marginBottom: "2em",
      },
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
      "&:hover": {
        overflowY: "scroll",
      },
    },
    muiPaginationStyle: {
      borderRadius: "4px",
      border: "1px solid rgba(255, 58, 87, 0.7)",
      position: "relative",
      width: "max-content",
      "& li": {
        color: "white",
        width: "35px",
        height: "41px",
        background: "white",
        borderRadius: "2px",
      },
      "& li:nth-child(1)": {
        position: "absolute",
        background: "transparent",
        zIndex: 999,
        left: -20,
        top: 10,
        "& button": {
          width: "max-content",
          height: "max-content",
        },
        "& button svg": {
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          borderRadius: "50%",
          "& path": {
            fill: "white",
          },
        },
        "& button.Mui-disabled": {
          display: "none",
        },
      },
      "& li:nth-last-child(1)": {
        position: "absolute",
        background: "transparent",
        right: -20,
        top: 10,
        zIndex: 999,
        "& button": {
          width: "max-content",
          height: "max-content",
        },
        "& button svg": {
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          borderRadius: "50%",
          "& path": {
            fill: "white",
          },
        },
        "& button.Mui-disabled": {
          display: "none",
        },
      },
      "& li button:hover": {
        background: "none",
      },
      "& li button": {
        height: "100%",
        width: "100%",
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
        padding: 0,
      },
      "& li div": {
        height: "100%",
        width: "100%",
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& li button.Mui-selected": {
        color: "white",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        borderRadius: "2px",
        fontWeight: 500,
      },
    },
  });
export default withStyles(webStyleLiveClassMaterial)(LiveClassMaterial);
export { LiveClassMaterial };
// Customizable Area End
