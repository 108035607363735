import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
export type StatsRecordedClasses = {
  time_spent_stats: [
    {
      subject: string;
      time_spent: string;
    }
  ];
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  stats: StatsRecordedClasses | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UsageStatsRecordedClassController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSubjectsRecordedClassMessageId: string = "";
  getRecordedClassStatsMessageId: string = "";
  buttons = [
    "RecordedClasses",
    "Weekly Test",
    "Grand Test",
    "Practice Test",
    "Live Classes",
    "Recorded Classes",
    "Question Bank",
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      stats: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getRecordedClassStatsMessageId && response) {
      if (response?.time_spent_stats) {
        this.handleRecordedClassesData(response);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getRecordedClassStats();
  };

  handleRecordedClassesData = (stats: StatsRecordedClasses) => {
    this.setState({
      stats,
      loading: false,
    });
  };

  // Customizable Area Start
  getRecordedClassStats = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };
    const endpoint = `bx_block_usageinsights/usage_stats/recorded_class_usage_stats`;
    const requestType = "GET";

    this.setState({
      loading: true,
    });
    const getRecordedClassStatsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRecordedClassStatsMessageId =
      getRecordedClassStatsMessage.messageId;

    getRecordedClassStatsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    getRecordedClassStatsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    getRecordedClassStatsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      requestType
    );
    runEngine.sendMessage(
      getRecordedClassStatsMessage.id,
      getRecordedClassStatsMessage
    );
  };
  // Customizable Area End
}
