import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isModalOpen: boolean;
  userInput: {
    newPassword: string;
    newPasswordConfirmation: string;
  };
  token: string;
  visibilityPassword1: boolean;
  visibilityPassword2: boolean;
  isModalOpenWrongPassword: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewPassword extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postNewPasswordDocumentId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isModalOpen: false,
      userInput: {
        newPassword: "",
        newPasswordConfirmation: "",
      },
      token: "",
      visibilityPassword1: false,
      visibilityPassword2: false,
      isModalOpenWrongPassword: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.postNewPasswordDocumentId) {
      if (responseJson.errors) {
        this.setState({
          isModalOpenWrongPassword: true,
        });
      }

      if (responseJson.data) {
        this.handleOpenModal();
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const windowUrl = window.location.href;
    const urlParams = new URLSearchParams(windowUrl.split("?")[1]);
    const token = urlParams.get("token");
    if (token) {
      this.handleTokenChange(token);
    }
  };

  handleTokenChange = (token: string) => {
    this.setState({
      token,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleOpenModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  handleGoToLogin = () => {
    this.props.navigation.navigate("Login");
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({
      userInput: {
        ...this.state.userInput,
        [name]: value,
      },
    });
  };

  handlePasswordVisibility1 = () => {
    this.setState({
      visibilityPassword1: !this.state.visibilityPassword1,
    });
  };

  handlePasswordVisibility2 = () => {
    this.setState({
      visibilityPassword2: !this.state.visibilityPassword2,
    });
  };

  handleCloseModalPasswordWrong = () => {
    this.setState({
      isModalOpenWrongPassword: false,
    });
  };

  // Customizable Area Start
  submitConfirmationPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const header = {
      "Content-Type": "application/json",
    };

    const requestBody = {
      data: {
        token: this.state.token,
        new_password: this.state.userInput.newPassword,
        new_password_confirm: this.state.userInput.newPasswordConfirmation,
      },
    };

    const apiPostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    apiPostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.postNewPasswordDocumentId = apiPostMessage.messageId;

    apiPostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.newPasswordEndpoint
    );

    apiPostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    apiPostMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(apiPostMessage.id, apiPostMessage);
  };
  // Customizable Area End
}
