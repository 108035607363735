Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";
exports.getBranchOfficeApiEndPOint = "/bx_block_contact_us/branchoffices";
exports.showCaptchaGetApiEndPoint = "/bx_block_contact_us/contacts/captcha";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid";
exports.postContactApiEndPOint="/bx_block_contact_us/contacts";
exports.getHeadOfficeApiEndPOint = "/bx_block_contact_us/headoffices";

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
exports.nameInputError = "Please enter the valid Name";
exports.contactErrorInput = "Please enter the valid phone number";
exports.emailErrorInput = "Enter a valid email address";
exports.subjectErrorInput = "Please enter the valid Subject Name";
exports.discriptionErrorInput = "Message can't be blank";
exports.validCaptchaMessage = "Please enter a valid captcha";
exports.captchaConfirmationMessage = "Form submmited will connect you shortly";
exports.bookSlotHeader = "Book Slot";
exports.bookSlotHeading = "Book slot to schedule Meeting";
exports.enterName = "Enter Name";
exports.mobileNumber = "Mobile number";
exports.emailID = "Email ID";
exports.messageData = "Message";
exports.slotBooked = "Slot is booked";
exports.bookSlotDiscrtiption = "Our Counsling Officer will connect \n you shortly !"
exports.submitbutton = "Submit";
exports.placeholderName = "Enter Your name";
exports.placeholderNumber = "Enter Mobile Number";
exports.placeholderEmail = "Enter Email ID";
exports.placeholderMessage = "Type what you want to ask for";
exports.bookSlotApiendPoint = "/bx_block_contact_us/guest_book_slots";
exports.nameError = "Name can't be blank";
exports.numerError = "Mobile number can't be blank or enter a valid Mobile number";
exports.emailError = "Email can't be blank";
exports.feedbackError = "Message can't be blank";
exports.goBackbutton = "Go Back";
exports.mobileNumError = "Please enter the valid phone number";
exports.emailError = "Enter a valid email address";
exports.emailRagex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
// Customizable Area End