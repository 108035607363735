import React from "react";

import {
  Box,
  // Customizable Area Start
  Button,
  createStyles,
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { mainLoginImage, google, myrankLogo, bgLogin } from "./assets";
import { Link } from "react-router-dom";

// Customizable Area End

import EmailAccountLoginBlockController, {
  Props,
} from "./EmailAccountLoginBlockController.web";

export class EmailAccountLoginMain extends EmailAccountLoginBlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const windowUrl = window.location.href;
    const urlParams = new URLSearchParams(windowUrl.split("?")[1]);
    const callbackUrl = urlParams.get("callback");

    return (
      // Customizable Area Start
      <Box
        className={this.props.classes.mainEmailContainer}
        data-test-id="main-login-screen"
      >
        <img
          src={bgLogin}
          alt="login-background"
          className={this.props.classes.mainLoginBg}
        />
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            left: "10px",
          }}
          onClick={this.handleGoToLandingPage}
        >
          <img
            style={{ width: "120px", cursor: "pointer" }}
            src={myrankLogo}
            alt="home-btn"
          />
        </Box>
        <Box className="main-email-image">
          <img src={mainLoginImage} alt="mainloginimage" />
        </Box>
        <Box className="main-email-box-choosing">
          <Link
            to={`/login/loginbyid${
              callbackUrl ? `?callback=${encodeURIComponent(callbackUrl)}` : ""
            }`}
          >
            <Button className="link-box">
              Login with Myrank ID / Mobile number
            </Button>
          </Link>
          <Button
            data-test-id="guest-login-btn"
            className="link-box"
            onClick={() => this.loginAsGuest()}
          >
            Continue as Guest
          </Button>
          <Box className="or-divider">OR</Box>
          <Button
            data-test-id="google-login-btn"
            className="link-box"
            onClick={this.handleGoogleLogIn}
          >
            <img
              src={google}
              alt="google"
              width={"20px"}
              style={{ marginRight: "1em" }}
            />{" "}
            Connect with Google
          </Button>
        </Box>
        <p style={webStyle.troubleStyle}>
          Have trouble logging in ?{" "}
          <Box className="spanStyle" component={"span"} sx={webStyle.spanStyle}>
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/contact-us"
            >
              Contact US
            </Link>
          </Box>
        </p>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  troubleStyle: {
    color: "white",
  },
  spanStyle: {
    position: "relative",
    cursor: "pointer",
    fontWeight: 700,
  },
};

const webStyleLogin = () =>
  createStyles({
    mainLoginBg: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: -1,
      width: "100%",
      height: "100%",
    },
    mainEmailContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "100% 100%",
      backgroundAttachment: "fixed",
      position: "relative",
      gap: "2em",
      padding: "3em",
      height: "max-content",

      "& .spanstyle::before": {
        content: '""',
        position: "absolute",
        bottom: 0,
        width: "80px",
        background: "#ff5b56",
        height: "1px",
      },

      "& .main-email-image": {
        "& img": {
          width: "433px",
          height: "409px",
        },
      },

      "& .main-email-box-choosing": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background:
          "linear-gradient(0deg, rgba(80, 33, 135, 1) 0%, rgba(129, 62, 153, 1) 100%)",
        borderRadius: "1em",
        border: "none",
        padding: "2em",
        width: "max-content",
        height: "max-content",
        gap: "1.5em",

        "& .link-box": {
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          backgroundColor: "white",
          minHeight: "82px",
          width: "404px",
          border: "none !important",
          color: "#01315d",

          "&:hover": {
            background:
              "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
            color: "white",
          },
        },

        "& .link-box::before": {
          display: "block",
          position: "absolute",
          content: '""',
          width: "14px",
          height: "100%",
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          left: 0,
          top: 0,
          zIndex: 999,
        },

        "& .or-divider": {
          position: "relative",
          fontWeight: 700,
          color: "white",
        },

        "& .or-divider::before": {
          fontWeight: 400,
          position: "absolute",
          left: "-165px",
          content: '"---------------------"',
          width: "max-content",
        },
        "& .or-divider::after": {
          fontWeight: 400,
          position: "absolute",
          left: "22px",
          content: '"---------------------"',
          width: "max-content",
        },
      },
    },

    "@media only screen and (max-width: 485px)": {
      "& .main-email-container": {
        "& .main-email-image": {
          "& img": {
            width: "233px",
            height: "209px",
          },
        },

        "& .main-email-box-choosing": {
          width: "100%",

          "& .link-box": {
            fontSize: "10px",
            minHeight: "52px",
            width: "204px",
          },
        },
      },
    },
  });

export default withStyles(webStyleLogin)(EmailAccountLoginMain);
// Customizable Area End
