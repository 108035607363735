import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "react-quill/dist/quill.snow.css";
import { SubmitIcon } from "./assets";
import CancelIcon from "@material-ui/icons/Cancel";
import { CKEditor } from "ckeditor4-react";
// Customizable Area End

import AYQAskQuestionController, {
  Props,
} from "./AYQAskQuestionController.web";

class AYQAskQuestion extends AYQAskQuestionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  CKEditorWrapper = () => {
    return (
      <CKEditor
        config={{ extraPlugins: "ckeditor_wiris" }}
        onChange={(event: unknown) => {
          const data = (
            event as { editor: { getData: () => string } }
          ).editor.getData();

          this.handleChange(data);
        }}
        onBeforeLoad={(CKEDITOR) => {
          CKEDITOR.plugins.addExternal(
            "ckeditor_wiris",
            "/mathtype-ckeditor4/",
            "plugin.js"
          );
        }}
      />
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Box className={this.props.classes.bannerStyle}>
          <Typography variant="h5">Ask Your Query</Typography>
        </Box>
        <Box className={this.props.classes.askingContainerStyle}>
          <Typography variant="h6">New Query</Typography>
          <form
            onSubmit={(event) => this.handleSubmit(event)}
            data-test-id="submit"
          >
            <label htmlFor="tags">Tags</label>
            <Box className={this.props.classes.tagsContainerStyle}>
              {this.state.tags.map((tags, index) => {
                return (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    className="tags"
                  >
                    <Typography>{tags}</Typography>
                    <CancelIcon
                      className={this.props.classes.cancelTag}
                      onClick={() => this.removeTag(index)}
                      data-test-id="remove-tag"
                    />
                  </Box>
                );
              })}
              <input
                id="tags"
                className="input-tag"
                name="tags"
                value={this.state.inputs.tags}
                onChange={(event) => this.handleInputChange(event)}
                onKeyDown={(event) => this.handleKeyDown(event.key)}
                placeholder="Enter Tags"
                required={this.state.tags.length === 0}
                data-test-id="tag-input"
              />
            </Box>
            <label htmlFor="subject">Subject Name</label>
            <input
              id="subject"
              name="subject"
              value={this.state.inputs.subject}
              onChange={(event) => this.handleInputChange(event)}
              placeholder="Enter Subject Name"
              data-test-id="subject-input"
              required
            />
            <label htmlFor="topic">Topic Name</label>
            <input
              id="topic"
              name="topic"
              value={this.state.inputs.topic}
              onChange={(event) => this.handleInputChange(event)}
              placeholder="Enter Topic Name"
              data-test-id="topic-input"
              required
            />
            <label htmlFor="query">Describe Your Query</label>
            {this.CKEditorWrapper()}
            <label htmlFor="query">Review Question</label>
            <Typography variant="body1">
              If there's any previously answers available in Myrank it will be
              shown here
            </Typography>
            <Box className="searchbox">
              {this.state.totalSearches > 0 ? (
                <Typography
                  style={{
                    cursor: "pointer",
                    borderRadius: "1em",
                    border: "1px solid #ddd",
                    padding: "0.5em",
                  }}
                  onClick={() => this.navigateToSimilarQuestion()}
                >
                  Found {this.state.totalSearches} similar questions
                </Typography>
              ) : (
                <Typography>
                  Describe Query and see if there are any available/related
                  answer to your question
                </Typography>
              )}
            </Box>
            <Button type="submit">Submit</Button>
          </form>
        </Box>
        <Modal
          open={this.state.isModalOpen}
          onClose={this.handleModalOpen}
          BackdropProps={{ onClick: undefined }}
        >
          <Box className={this.props.classes.modalStyle}>
            <img src={SubmitIcon} alt="getstarted" />
            <h3>Your Query Posted Successfully</h3>
            <Box display={"flex"} gridGap={"1em"}>
              <Button
                className={this.props.classes.buttonStyleYes}
                data-test-id="navigate-btn"
                onClick={() => this.handleGoBack()}
              >
                Go Back
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleAYQAskQuestion = () =>
  createStyles({
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "#510081",
      display: "flex",
      background: "rgb(247,242,249)",
      height: "71px",
      "& h5": {
        fontWeight: 700,
      },
    },
    askingContainerStyle: {
      padding: "2em",
      "& h6": {
        color: "#561A72",
        fontWeight: "600",
      },
      "& form": {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        "& input": {
          border: "1.5px solid #ccc",
          borderRadius: "12px",
          height: "80px",
          padding: "0 1em",
          outline: "none",
        },
        "& div input": {
          border: "none",
        },
        "& label": {
          fontSize: "17px",
          fontWeight: "600",
          margin: "1em 0",
        },
        "& .quill": {
          "& .ql-toolbar.ql-snow": {
            borderTopRightRadius: "1em",
            borderTopLeftRadius: "1em",
          },
          "& .ql-container.ql-snow": {
            borderBottomRightRadius: "1em",
            borderBottomLeftRadius: "1em",
            height: "max-content",
            minHeight: "150px",
          },
        },
        "& button": {
          marginTop: "1em",
          width: "150px",
          height: "50px",
          background:
            "linear-gradient(90deg, rgba(254,55,86,1) 0%, rgba(255,109,83,1) 100%)",
          color: "white",
        },
        "& .searchbox": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "150px",
          border: "1.5px solid #ccc",
          borderRadius: "12px",
        },
      },
    },
    tagsContainerStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1.5px solid #dddddd",
      borderRadius: "12px",
      height: "80px",
      background: "white",
      "& .tags": {
        background: "#dddddd",
        padding: "0.6em 1em",
        borderRadius: "20px",
        margin: "0 0.5em",
        fontSize: "12px",
        width: "max-content",
      },
      "& .input-tag": {
        width: "100%",
        height: "30px",
        outline: "none",
      },
    },
    buttonStyleYes: {
      width: "130px",
      color: "white",
      textTransform: "none" as const,
      height: "50px",
      marginLeft: "1em",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    },
    modalStyle: {
      position: "absolute" as "absolute",
      justifyContent: "center",
      padding: "2em",
      display: "flex",
      top: "50%",
      flexDirection: "column",
      alignItems: "center",
      left: "50%",
      background: "white",
      border: "2px solid #FFD0D8",
      gap: "1.5em",
      transform: "translate(-50%, -50%)",
      borderRadius: "1.5em",
      width: "35%",
      minHeight: "max-content",
    },
    cancelTag: {
      cursor: "pointer",
    },
  });
export default withStyles(webStyleAYQAskQuestion)(AYQAskQuestion);
export { AYQAskQuestion };
// Customizable Area End
