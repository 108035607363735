import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Banner from "../../../components/src/Banner.web";
// Customizable Area End

import MPCGuidanceMainController, {
  Props,
  configJSON,
} from "./MPCGuidanceMainController.web";

class MPCGuidanceMain extends MPCGuidanceMainController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderHeaderMPC = () => {
    const years = [2023, 2022, 2021, 2020, 2019, 2018];
    return (
      <>
        {years.map((year, index) => {
          return (
            <Box
              key={`${index}-year-box`}
              className={this.state.activeYearIndex === index ? "active" : ""}
              data-test-id="select-year-btn"
              onClick={() => this.setYearIndex(index)}
            >
              <Typography variant="h6">{year}</Typography>
            </Box>
          );
        })}
      </>
    );
  };

  RenderMPC2020 = ({ classes }: Props) => {
    return (
      <Box className={classes.year_boxes_style}>
        <Box className="upper-boxes">
          <Box
            data-test-id="mpc-btn"
            onClick={() =>
              this.navigationTo("MPCGuidanceCutOffs", "jeeadv", 2020)
            }
          >
            {configJSON.Mpc2020[0]}
          </Box>
        </Box>
        <Box className="box-breaker" />
        <Box className="secondary-boxes">
          <Box
            data-test-id="mpc-btn"
            onClick={() =>
              this.navigationTo("MPCGuidanceCutOffs", "jeemainsjosaa", 2020)
            }
          >
            {configJSON.Mpc2020[1]}
          </Box>
          <Box
            data-test-id="mpc-btn"
            onClick={() =>
              this.navigationTo("MPCGuidanceCutOffs", "jeemainscsab", 2020)
            }
          >
            {configJSON.Mpc2020[2]}
          </Box>
        </Box>
        <Box className="box-breaker" />
        <Box className="lower-boxes">
          <Box
            data-test-id="mpc-btn"
            onClick={() =>
              this.navigationTo("MPCGuidanceCutOffs", "tseamcetengg", 2020)
            }
          >
            {configJSON.Mpc2020[3]}
          </Box>
          <Box>{configJSON.Mpc2020[4]}</Box>
        </Box>
      </Box>
    );
  };

  RenderMPCBasedFromYear = () => {
    switch (this.state.activeYearIndex) {
      case 1:
        return <Box>Nothing to display here yet</Box>;
      case 2:
        return <Box>Nothing to display here yet</Box>;
      case 3:
        return this.RenderMPC2020(this.props);
      default:
        return <Box>Nothing to display here yet</Box>;
    }
  };
  // Customizable Area End

  render() {
    const { classes } = this.props;

    return (
      // Customizable Area Start
      <Box>
        <Banner title="M.P.C Guidance" />
        <Box className={classes.year_header_style}>
          {this.RenderHeaderMPC()}
        </Box>
        <Box>{this.RenderMPCBasedFromYear()}</Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const boxesStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "90%",
  gap: "5em",
};

export const boxDefaultStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "106px",
  color: "white",
  width: "100%",
  fontSize: "2em",
  cursor: "pointer",
};

export const boxWithBackgroundStyles = {
  "& .upper-boxes": {
    "& div": {
      background: "rgb(1, 192, 238)",
    },
  },
  "& .lower-boxes": {
    "& div": {
      background: "rgb(221, 75, 57)",
    },
  },
  "& .secondary-boxes": {
    "& div": {
      background: "rgb(243, 156, 18)",
    },
  },
};

export const webStyleMPCGuidanceMain = () =>
  createStyles({
    year_header_style: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& div": {
        height: "106px",
        width: "100%",
        background: "rgb(244, 244, 244)",
        color: "rgb(82, 0, 130)",
        marginTop: "2em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.4s ease",
        cursor: "pointer",
      },
      "& .active": {
        background: "rgb(82, 0, 130)",
        color: "rgb(255, 255, 255)",
      },
    },
    year_boxes_style: {
      width: "100%",
      height: "max-content",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "3em",
      gap: "3em",
      "& .upper-boxes, & .lower-boxes, & .secondary-boxes": {
        ...boxesStyle,
        "& div": {
          ...boxDefaultStyle,
        },
      },
      ...boxWithBackgroundStyles,
      "& .box-breaker": {
        width: "90%",
        height: "2px",
        background: "#ddd",
      },
    },
  });
export default withStyles(webStyleMPCGuidanceMain)(MPCGuidanceMain);
export { MPCGuidanceMain };
// Customizable Area End
