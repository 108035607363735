import React from "react";

import {
  Box,
  // Customizable Area Start
  Typography,
  Modal,
  withStyles,
  createStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import MenuIcon from "@material-ui/icons/Menu";
import Footer from "../../../components/src/Footer.web";
import { myranklogo, home, book, message } from "./assets";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Button from "../../../components/src/Button.web";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import WebRoutesGenerator from "../../../components/src/NativeWebRouteWrapper";
import { routeMapDashboardAYQ } from "../../../web/src/App";
import Loader from "../../../components/src/Loader.web";
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
// Customizable Area End

import DashboardAYQController, { Props } from "./DashboardAYQController.web";

export class DashboardAYQ extends DashboardAYQController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    if (this.state.isLoading) {
      return <Loader loading={true} />;
    }

    return (
      // Customizable Area Start
      <Box>
        <Box className={this.props.classes.dashboard}>
          <Box
            boxShadow={"1px 1px 1px gray"}
            className="dashboard-header"
            component={"header"}
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="0 3em"
          >
            <Box className="header-nav">
              <img
                src={myranklogo}
                alt="logomyrank"
                height={"39px"}
                data-test-id="navigate-to-landing-page"
                onClick={() => this.props.navigation.navigate("LandingPage")}
              />
              <MenuIcon
                data-test-id="sidebar-btn"
                className="menu-icon"
                onClick={() => this.setShowSidebar()}
              />
            </Box>
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              gridGap={"5em"}
              display={"flex"}
              position={"relative"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                style={{ cursor: "pointer" }}
                data-test-id="toggle-profile-popup"
                justifyContent={"center"}
                onClick={this.toggleProfilePopUp}
              >
                {this.state.userData?.attributes.image ? (
                  <img
                    style={{ borderRadius: "50%", width: "30px" }}
                    src={this.state.userData.attributes.image}
                  />
                ) : (
                  <AccountCircleIcon
                    className={this.props.classes.accountImg}
                  />
                )}
                <Typography variant="h6">
                  {this.state.userData
                    ? this.state.userData?.attributes.first_name +
                      " " +
                      this.state.userData?.attributes.last_name
                    : "Guest"}
                </Typography>
                <span style={{ left: "-5px", position: "relative" }}>
                  <ArrowDropDownIcon style={{ color: "#949497" }} />
                </span>
              </Box>
              <Box
                className={
                  this.state.profilePopUp
                    ? this.props.classes.profilePopUp
                    : this.props.classes.profilePopUpHidden
                }
              >
                <Box
                  className={this.props.classes.profilePopUpTypo}
                  height={"50%"}
                  data-test-id="navigate-to-dashboard"
                  onClick={() => {
                    this.navigateToDashboard();
                  }}
                >
                  <Typography>My Dashboard</Typography>
                </Box>
                <Box
                  className={this.props.classes.profilePopUpTypo}
                  height={"50%"}
                  data-test-id="profile-logout-btn"
                  onClick={this.logout}
                >
                  <Typography>Sign Out</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box component="main" className="dashboard-main">
            <Sidebar
              className={`dashboard-sidebar`}
              rootStyles={{
                [`.${sidebarClasses.container}`]: {
                  backgroundColor: "inherit",
                  position: "relative",
                  minHeight: "97vh",
                },
              }}
              collapsed={!this.state.showSidebar}
              collapsedWidth="0"
              data-test-id="sidebar-container"
            >
              <Menu
                rootStyles={{
                  paddingBottom: "15em",
                  ".ps-menu-button:hover": {
                    background: "inherit",
                  },
                }}
              >
                <MenuItem
                  icon={<img src={home} className="nav-icon" />}
                  onClick={() => this.navigateToHome()}
                  className={this.determineIfActiveLink(
                    "/dashboard-ask-your-query/home"
                  )}
                  data-test-id="nav-btns"
                >
                  Home
                </MenuItem>
                {this.state.userData && (
                  <>
                    <MenuItem
                      icon={<img src={book} className="nav-icon" />}
                      onClick={() => this.navigateToQuestionsAsked()}
                      className={this.determineIfActiveLink(
                        "/dashboard-ask-your-query/questions-asked"
                      )}
                      data-test-id="nav-btns"
                    >
                      Questions Asked
                    </MenuItem>
                    <MenuItem
                      icon={<img src={message} className="nav-icon" />}
                      onClick={() => this.navigateToProfile()}
                      className={this.determineIfActiveLink(
                        "/dashboard-ask-your-query/profile"
                      )}
                      data-test-id="nav-btns"
                    >
                      My Profile
                    </MenuItem>
                  </>
                )}
              </Menu>
              <Menu
                rootStyles={{
                  ".ps-menu-button:hover": {
                    background: "inherit",
                  },
                }}
                className={this.props.classes.logoutStyle}
              >
                <MenuItem
                  icon={<ExitToAppIcon className="nav-icon" />}
                  onClick={
                    this.state.userData ? this.logout : this.handleAyqLoginModal
                  }
                  data-test-id="logout-btn-clear"
                >
                  {this.state.userData ? "Logout" : "Login"}
                </MenuItem>
              </Menu>
            </Sidebar>
            <Modal
              open={this.state.isNotLoggedIn}
              onClose={this.handleAyqLoginModal}
            >
              <Box className={this.props.classes.modalStyle}>
                <HighlightOffIcon
                  data-test-id="close-modal"
                  onClick={this.handleAyqLoginModal}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    fontSize: "2rem",
                    cursor: "pointer",
                    color: "gray",
                  }}
                />
                <Typography variant="h6">
                  Login / Signup with AYQ to ask a question
                </Typography>
                <Box data-test-id="to-login-btn" onClick={this.navigateToLogin}>
                  <Button
                    key={"buttonKey"}
                    color="white"
                    height="50px"
                    width="200px"
                    title="Login"
                  />
                </Box>
              </Box>
            </Modal>
            <Modal
              open={this.state.isModalOpenLogout}
              onClose={this.handleCloseModal}
            >
              <Box className={this.props.classes.modalStyle}>
                <p>You Successfully Logged Out</p>
                <Box
                  onClick={this.navigateToLandingPage}
                  data-test-id="logout-btn"
                >
                  <Button
                    key={"buttonKey"}
                    width="200px"
                    title="Go Back To Landing Page"
                    color="white"
                    height="50px"
                  />
                </Box>
              </Box>
            </Modal>
            <Box component="section" className="dashboard-content">
              {WebRoutesGenerator({ routeMap: routeMapDashboardAYQ })}
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = () =>
  createStyles({
    modalStyle: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.5em",
      position: "absolute" as "absolute",
      height: "210px",
      border: "none",
      backgroundColor: "white",
      borderRadius: "0.5em",
      display: "flex",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "calc(max-content + 100px)",
      padding: "2em",
      "& h6": {
        marginBottom: "1em",
      },
    },
    menuStyle: {
      display: "flex",
      flexDirection: "column" as const,
    },
    profilePopUp: {
      display: "flex",
      flexDirection: "column" as const,
      position: "absolute" as const,
      width: "200px",
      height: "100px",
      textAlign: "center" as const,
      top: "60px",
      right: "0px",
      color: "black",
      borderRadius: "0.7em",
      border: "1px solid gray",
      background: "white",
    },
    accountImg: { fontSize: "2.5em" },
    profilePopUpHidden: {
      display: "none",
    },
    profilePopUpTypo: {
      "&:hover": {
        background: "rgb(81,0,129)",
        color: "white",
        borderRadius: "0.35em",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    dashboard: {
      display: "flex",
      flexDirection: "column",

      "& .dashboard-header": {
        display: "flex",
        alignItems: "center",
        height: "71px",
        width: "100%",
        justifyContent: "space-between",

        "& .header-nav": {
          display: "flex",
          gap: "1em",
          alignItems: "center",

          "& h1": {
            fontWeight: "bold",
            fontSize: "2rem",
          },

          "& img": {
            cursor: "pointer",
          },

          "& .menu-icon": {
            display: "inline-block",
            fontSize: "2em",
            width: "100px",
            cursor: "pointer",
          },
        },
      },

      "& .dashboard-main": {
        display: "flex",
        minHeight: "90vh",
        marginBottom: "1em",
        width: "100%",

        "& .active-link": {
          color: "white",
          fontWeight: "bold",
        },

        "& .submenu": {
          fontSize: "14px",
          marginLeft: "2em",

          "& > div": {
            margin: "0.5em 0",
          },

          "& .submenu-inner": {
            "& > a": {
              margin: "0.5em 0",
            },
          },
        },

        "& .dashboard-sidebar": {
          minHeight: "97vh",
          maxHeight: "max-content",
          height: "100%",
          backgroundColor: "#520082",
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderBottomRightRadius: "2em",
          padding: "2em 0",
          transition: "all 0.3s ease",
          position: "relative",

          "& .nav-icon": {
            width: "20px",
            height: "20px",
            color: "#a77ec0",
          },
        },

        "& .sidebar-close": {
          padding: "0px",
          width: "0px",
          "& .nav-link-list, & .logout-dashboard, & *": {
            display: "none",
          },
        },

        "& .dashboard-content": {
          minHeight: "100vh",
          width: "100%",
          height: "max-content",
        },
      },
    },
    logoutStyle: {
      position: "absolute",
      bottom: "25px",
    },
  });

export default withStyles(webStyle)(DashboardAYQ);
// Customizable Area End
