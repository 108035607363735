import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
export type Slot = {
  id: string;
  type: string;
  attributes: {
    id: number;
    slot_date: string;
    slot_subject: string;
    slot_start: string;
    slot_end: string;
    slot_status: string;
    availability_status: string;
  };
};

export type Availability = {
  bms_slot: {
    id: number;
    slot_date: string;
    slot_subject: string;
    slot_status: string;
    availability_status: string;
    created_at: string;
    updated_at: string;
    year: string | null;
    slot_start: string;
    slot_end: string;
  };
  availability: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  valueDate: string;
  slots: Slot[];
  loading: boolean;
  slotsArrayCurrentData: Slot[];
  facultySlot: { attributes: Availability }[];
  subjectDealing: string[];
  subjectName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotFacultySlotAvailabilityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSlotsAvailableMessageId = "";
  postAvailabilityUpdateMessageId = "";
  getFacultySlotDateMessageId = "";
  breadcrumb = [
    {
      link: `/dashboard/book-my-slot-faculty`,
      label: "Book my Slot Faculty",
    },
    {
      link: `/dashboard/book-my-slot-faculty`,
      label: "Bookings",
    },
    {
      link: `/dashboard/book-my-slot-faculty/availability`,
      label: "Availability",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      valueDate: "",
      slots: [],
      loading: false,
      slotsArrayCurrentData: [],
      facultySlot: [],
      subjectDealing: [],
      subjectName: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSlotsAvailableMessageId && response) {
      if (response?.data) {
        this.handleData(response.data);
      }
    }

    if (apiRequestCallId === this.postAvailabilityUpdateMessageId && response) {
      if (response?.data) {
        this.handleCurrentSlotsAvailability(response.data.attributes);
      }
    }

    if (apiRequestCallId === this.getFacultySlotDateMessageId && response) {
      if (response?.data) {
        this.handleFacultySlotDates(response.data);
      }

      if (response?.errors) {
        this.setState({
          loading: false,
          facultySlot: [],
        });
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidUpdate(_: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.valueDate !== this.state.valueDate) {
      this.handleCurrentDataForCurrentSlots();
    }
  }

  componentDidMount = async () => {
    this.getSlotsAvailable();
    this.getSubjectDealings();

    const propPassingFacultyAvailability = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingFacultyAvailability.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingFacultyAvailability);
  };

  getSubjectDealings = async () => {
    const userData = JSON.parse(await storage.get("userData"));

    const subjectDealing = userData?.attributes?.subject_dealing;

    this.setState({
      subjectDealing,
    });
  };

  handleFacultySlotDates = (facultySlot: { attributes: Availability }[]) => {
    this.setState({
      facultySlot,
      loading: false,
    });
  };

  handleCurrentSlotsAvailability = (slotDetail: Availability) => {
    const slots = this.state.slots;

    const indexToChange = slots.findIndex(
      (slot) => slot.attributes.id === slotDetail.bms_slot.id
    );

    slots[indexToChange] = {
      id: String(slotDetail.bms_slot.id),
      type: "bms_slot",
      attributes: {
        id: slotDetail.bms_slot.id,
        slot_date: slotDetail.bms_slot.slot_date,
        slot_subject: slotDetail.bms_slot.slot_subject,
        slot_start: slotDetail.bms_slot.slot_start,
        slot_end: slotDetail.bms_slot.slot_end,
        slot_status: slotDetail.bms_slot.slot_status,
        availability_status: slotDetail.availability,
      },
    };

    this.setState({ slots, loading: false }, () => {
      this.handleCurrentDataForCurrentSlots();
      this.getFacultySlotDate(this.state.valueDate);
    });
  };

  handleData = (slots: Slot[]) => {
    this.setState({
      slots,
      loading: false,
    });
  };

  handleCurrentDataForCurrentSlots = () => {
    const slots = this.state.slots;

    const slotsArrayCurrentData = slots.filter(
      (slot) =>
        slot.attributes.slot_date === this.state.valueDate &&
        slot.attributes.slot_subject.toLowerCase() ===
          this.state.subjectName.toLowerCase()
    );

    this.setState({
      slotsArrayCurrentData,
    });
  };

  // Customizable Area Start
  changeDateChange = (value: string) => {
    const valueDate = this.formatDate(value);
    this.setState(
      {
        valueDate,
      },
      () => {
        this.getFacultySlotDate(value);
      }
    );
  };

  formatDate = (data: string) => {
    const date = new Date(data);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  getSlotsAvailable = async () => {
    this.setState({
      loading: true,
    });
    const getSlotsAvailableMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getSlotsAvailableMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.slotsGetAll
    );
    this.getSlotsAvailableMessageId = getSlotsAvailableMessage.messageId;
    getSlotsAvailableMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSlotsAvailableMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getSlotsAvailableMessage.id,
      getSlotsAvailableMessage
    );
  };

  postAvailabilityUpdate = async (slotId: string, availability: string) => {
    const postAvailabilityUpdateMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    this.postAvailabilityUpdateMessageId =
      postAvailabilityUpdateMessage.messageId;

    postAvailabilityUpdateMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/faculty_bms?availability=${availability}&bms_slot_id=${slotId}`
    );

    postAvailabilityUpdateMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    postAvailabilityUpdateMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(
      postAvailabilityUpdateMessage.id,
      postAvailabilityUpdateMessage
    );
  };

  getStyleColorSlotTime = (slotId: string) => {
    const facultySlot = this.state.facultySlot;
    const slotDetails = facultySlot.find(
      (slot) => String(slot.attributes.bms_slot.id) === slotId
    );

    if (slotDetails === undefined) {
      return "nonexisting";
    }

    if (slotDetails.attributes.availability === "notavailable") {
      return "notavailable";
    }

    return "available";
  };

  getFacultySlotDate = async (date: string) => {
    this.setState({
      loading: true,
    });
    const getFacultySlotDateMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getFacultySlotDateMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/faculty_bms?slot_date=${date}`
    );
    this.getFacultySlotDateMessageId = getFacultySlotDateMessage.messageId;
    getFacultySlotDateMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getFacultySlotDateMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getFacultySlotDateMessage.id,
      getFacultySlotDateMessage
    );
  };

  onChangeSelectSubject = (selectedChoice: string) => {
    this.setState(
      {
        subjectName: selectedChoice,
      },
      () => {
        this.handleCurrentDataForCurrentSlots();
      }
    );
  };
  // Customizable Area End
}
