import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  Modal,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import Loader from "../../../components/src/Loader.web";
import CalendarComponent from "../../../components/src/Calendar.web";
import { Physics } from "./assets";
import { colourStyles } from "../../../framework/src/UtilsWeb";
import { boxStyles } from "./BookMySlot.web";
import { webStyle } from "./BookMySlotAddEditSlot.web";
import Select from "react-select";
// Customizable Area End

import BookMySlotStudentBookSlotController, {
  Props,
  configJSON,
} from "./BookMySlotStudentBookSlotController.web";

class BookMySlotStudentBookSlot extends BookMySlotStudentBookSlotController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderAppointmentBox = (index: number) => {
    return (
      <Grid key={`bookSlot-${index}`} sm={12} item>
        <Box className={this.props.classes.appointmentBox}>
          <Box className="details-appointment" position={"relative"}>
            <img src={Physics} alt={"physics-image"} />
            <Box
              display="flex"
              flexDirection={"column"}
              gridGap={"1em"}
              marginLeft={"3em"}
            >
              <Typography>
                {configJSON.subjectTitle} -{" "}
                {this.state.subject?.attributes.name}
              </Typography>
              <Typography>
                Chapter - {this.state.chapter?.attributes.name}
              </Typography>
              <Typography>Select Topic:</Typography>
              <Select
                isSearchable={true}
                isClearable={true}
                name="topic"
                options={this.state.topics}
                placeholder="Choose topic below"
                data-test-id="filter-topic"
                onChange={(event) => this.handleUserInputChange(event)}
                styles={colourStyles}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  };
  // Customizable Area End

  render() {
    console.log(this.state.loadingQueues, "QUEUES");
    return (
      // Customizable Area Start
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Modal
          open={this.state.isSlotUpdatedModal}
          data-test-id="modal-slot-booked"
          onClose={this.handleIsSlotUpdatedModal}
        >
          <Box sx={webStyle.modalStyle}>
            <Typography>Your Slot has been booked</Typography>
            <Button
              data-test-id="homebtn"
              style={webStyle.buttonStyle}
              onClick={() => this.handleToBookSlot()}
            >
              Go to Bookings
            </Button>
          </Box>
        </Modal>
        <Modal
          open={this.state.isErrorModal}
          data-test-id="modal-slot-booked"
          onClose={this.showErrorModal}
        >
          <Box sx={webStyle.modalStyle}>
            <Typography>
              Faculty slot not found or slot is not available.
            </Typography>
            <Button
              style={webStyle.buttonStyle}
              data-test-id="homebtn"
              onClick={this.showErrorModal}
            >
              Go Back
            </Button>
          </Box>
        </Modal>
        <Loader loading={this.state.loadingQueues > 0} />
        <BannerWeb title="Book My Slot" />
        <Box className={this.props.classes.mainHeading}>
          <Typography>Available Slots - {this.state.meta.all_slots}</Typography>
          <Typography>
            Booked Slots - {this.state.meta.all_booked_slots}
          </Typography>
        </Box>
        <Box width={"70%"}>
          <Box width={"100%"}>
            <Typography className={this.props.classes.chooseChapter}>
              Choose Chapter and Topic
            </Typography>
            {this.RenderAppointmentBox(1)}
          </Box>
          <Box width={"100%"}>
            <Typography className={this.props.classes.chooseChapter}>
              {configJSON.briefYourDoubt}
            </Typography>
            <textarea
              placeholder="Type message here"
              onChange={(event) => this.setDoubtComments(event.target.value)}
              className={this.props.classes.textAreaStyle}
              data-test-id="textarea"
              value={this.state.doubtComment}
            />
          </Box>
          <Box marginTop={"3em"}>
            <Typography className={this.props.classes.chooseChapter}>
              Select Date
            </Typography>
          </Box>
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Box marginTop="2em">
              <CalendarComponent
                onChangeDate={(value) => this.changeDateChange(value)}
              />
            </Box>
            <Box>
              <Typography className={this.props.classes.chooseChapter}>
                Available Slots
              </Typography>
              <Box display={"flex"} gridGap={"1.6em"} flexWrap={"wrap"}>
                {this.state.slotsArrayCurrentData.map((slot) => {
                  return (
                    <Box
                      className={`${this.props.classes.slotTime} ${
                        slot.id === this.state.slotId ? "active" : ""
                      }`}
                      data-test-id="slecttime"
                      onClick={() => this.selectTimeDate(slot.id)}
                    >
                      <Button
                        className={`${
                          slot.id === this.state.slotId ? "active" : ""
                        } ${
                          slot.attributes.availability_status === "notavailable"
                            ? "inactive"
                            : ""
                        }`}
                        disabled={
                          slot.attributes.availability_status === "notavailable"
                        }
                      >
                        {slot.attributes.slot_start} -{" "}
                        {slot.attributes.slot_end}
                      </Button>
                    </Box>
                  );
                })}
              </Box>
              {this.state.slotsArrayCurrentData.length === 0 && (
                <Typography className={this.props.classes.subjectTypoStudent}>
                  No available slots for this subject
                </Typography>
              )}
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Button
                onClick={() => this.bookSlot()}
                data-test-id="gotoNewSlots"
                className={this.props.classes.update}
              >
                Book my Slot
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleBookMySlotStudentBookSlot = () =>
  createStyles({
    mainHeading: {
      justifyContent: "center",
      display: "flex",
      marginTop: "2em",
      marginBottom: "5em",
      alignItems: "center",
      gap: "1.5em",
      "& p": {
        fontSize: "1.2em",
      },
      "& button": {
        color: "#722E9A",
        border: "1px solid #FE5057",
      },
    },
    appointmentBox: {
      ...boxStyles,
      "& .details-appointment img": {
        width: "300px!important",
        height: "180px!important",
        borderRadius: "0.7em!important",
      },
      "& .details-appointment p": {
        fontSize: "1.4em",
        fontWeight: 500,
      },
      border: "none!important",
      padding: "2em 0!important",
    },
    textAreaStyle: {
      border: "1px solid #FF4D4F",
      width: "100%",
      height: "200px",
      resize: "none",
      borderRadius: "0.7em",
      padding: "1.5em",
      fontSize: "1.2em",
      outline: 0,
      marginTop: "1.3em",
    },
    slotTime: {
      "& button": {
        border: "1px solid #FE6954",
        background: "white",
        color: "#808080",
        width: "130px",
      },
      "& .active": {
        color: "black",
      },
      "&.inactive": {
        background: "red",
        border: "none",
        color: "white",
      },
    },
    chooseChapter: {
      fontWeight: 500,
      fontSize: "1.6em",
      alignSelf: "flex-start",
      margin: "3em 0",
      width: "100%",
    },
    update: {
      width: "400px",
      borderRadius: "0.5em",
      color: "white",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      margin: "2em 0 3em 0",
      fontSize: "20px",
      height: "70px",
    },
    subjectTypoStudent: {
      fontWeight: 500,
      fontSize: "1.4em",
      textAlign: "center",
    },
  });
export default withStyles(webStyleBookMySlotStudentBookSlot)(
  BookMySlotStudentBookSlot
);
export { BookMySlotStudentBookSlot };
// Customizable Area End
