import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { webStyleAYQProfilePeek } from "./AYQProfilePeek.web";
import storage from "../../../framework/src/StorageProvider.web";

export interface Root {
  profile: Profile;
  member_since: string;
  top_questions: TopQuestions;
  top_answers: TopAnswers;
  badges: Badges;
}

export interface Profile {
  data: Data;
}

export interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  name: string;
  first_name: string | number;
  last_name: string | number;
  phone_number: number;
  email: string;
  city: string | number;
  status: string;
  myrank_id: string;
  image: string | number;
  uuid: string | number;
  course_notification_enable: boolean;
  exam_notification_enable: boolean;
  package: Package[];
}

export interface Package {
  id: number;
  account_id: number;
  amount: number;
  validity: string;
  expiry_date: string;
  sales_manager_name: string | number;
  agent1_name: string | number;
  agent2_name: string | number;
  transaction_type: string;
  status: string;
  pack_final_id: number;
  pack_pricing_id: number;
  packfinal_feature_id: string | number;
  ayq_level: number;
  created_by: string;
  created_at: string;
  lc_level: number;
  wb_level: number;
  bms_level: number;
  tution_level: number;
  acat_apply: number;
  bcat_apply: number;
  actual_price: number;
  updated_at: string;
  sms_level: string | number;
  active: boolean;
}

export interface TopQuestions {
  data: Daum[];
}

export interface Daum {
  id: string;
  type: string;
  attributes: Attributes2;
}

export interface Attributes2 {
  account_id: number;
  subject_name: string;
  updated_at: string;
  tag_list: string[];
  topic_name: string;
  question: string;
  answer: string;
  score: number;
  created_at: string;
  view_badge: ViewBadge;
  like_count: number;
  dislike_count: number;
  view: number;
  question_badge: QuestionBadge;
  ayq_answers: AyqAnswers;
  user_profile: UserProfile;
  answer_count: number;
  current_user_liked: number;
}

export interface ViewBadge {
  data: string | number;
}

export interface QuestionBadge {
  data: Data2;
}

export interface Data2 {
  id: string;
  type: string;
  attributes: Attributes3;
}

export interface Attributes3 {
  id: number;
  badge_type: BadgeType;
  name: string;
  description: string;
  image: string | number;
  created_at: string;
  updated_at: string;
}

export interface BadgeType {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface UserProfile {
  data: Data3;
}

export interface Data3 {
  id: string;
  type: string;
  attributes: Attributes4;
}

export interface Attributes4 {
  name: string;
  first_name: string | number;
  last_name: string | number;
  phone_number: number;
  email: string;
  city: string | number;
  status: string;
  myrank_id: string;
  image: string | number;
  uuid: string | number;
  course_notification_enable: boolean;
  exam_notification_enable: boolean;
  package: Package2[];
}

export interface Package2 {
  id: number;
  account_id: number;
  pack_final_id: number;
  tution_level: number;
  acat_apply: number;
  sales_manager_name: string | number;
  agent1_name: string | number;
  pack_pricing_id: number;
  packfinal_feature_id: string | number;
  ayq_level: number;
  lc_level: number;
  wb_level: number;
  bms_level: number;
  agent2_name: string | number;
  transaction_type: string;
  status: string;
  created_by: string;
  bcat_apply: number;
  actual_price: number;
  amount: number;
  validity: string;
  expiry_date: string;
  created_at: string;
  updated_at: string;
  sms_level: string | number;
  active: boolean;
}

export interface AyqAnswers {
  data: Daum2[];
}

export interface Daum2 {
  id: string;
  type: string;
  attributes: Attributes5;
}

export interface Attributes5 {
  account_id: number;
  ayq_question_id: number;
  answer: string;
  like_count: number;
  dislike_count: number;
  updated_at: string;
  answer_badge: AnswerBadge;
  user_profile: UserProfile2;
  current_user_liked: number;
  comments: Comments;
  score: number;
  is_correct: boolean;
  created_at: string;
  ayq_question: AyqQuestion;
}

export interface AnswerBadge {
  data: Data4;
}

export interface Data4 {
  id: string;
  type: string;
  attributes: Attributes6;
}

export interface Attributes6 {
  id: number;
  badge_type: BadgeType2;
  name: string;
  description: string;
  image: string | number;
  created_at: string;
  updated_at: string;
}

export interface BadgeType2 {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface UserProfile2 {
  data: Data5;
}

export interface Data5 {
  id: string;
  type: string;
  attributes: Attributes7;
}

export interface Attributes7 {
  name: string;
  first_name: string | number;
  last_name: string | number;
  phone_number: number;
  email: string;
  city: string | number;
  status: string;
  myrank_id: string;
  image: string | number;
  uuid: string | number;
  course_notification_enable: boolean;
  exam_notification_enable: boolean;
  package: Package3[];
}

export interface Package3 {
  id: number;
  account_id: number;
  pack_final_id: number;
  pack_pricing_id: number;
  bcat_apply: number;
  expiry_date: string;
  sales_manager_name: string | number;
  packfinal_feature_id: string | number;
  ayq_level: number;
  lc_level: number;
  wb_level: number;
  bms_level: number;
  tution_level: number;
  acat_apply: number;
  agent1_name: string | number;
  agent2_name: string | number;
  transaction_type: string;
  status: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  sms_level: string | number;
  actual_price: number;
  amount: number;
  validity: string;
  active: boolean;
}

export interface Comments {
  data: string | number[];
}

export interface AyqQuestion {
  id: number;
  account_id: number;
  question: string;
  like_count: number;
  dislike_count: number;
  created_at: string;
  updated_at: string;
  subject_name: string;
  topic_name: string;
  score: number;
  view: number;
  tag_list: string[];
}

export interface TopAnswers {
  data: Daum3[];
}

export interface Daum3 {
  id: string;
  type: string;
  attributes: Attributes8;
}

export interface Attributes8 {
  account_id: number;
  ayq_question_id: number;
  answer: string;
  dislike_count: number;
  score: number;
  is_correct: boolean;
  created_at: string;
  updated_at: string;
  answer_badge: AnswerBadge2;
  question: string;
  like_count: number;
  user_profile: UserProfile3;
  current_user_liked: number;
  comments: Comments2;
  ayq_question: AyqQuestion2;
}

export interface AnswerBadge2 {
  data: Data6;
}

export interface Data6 {
  id: string;
  type: string;
  attributes: Attributes9;
}

export interface Attributes9 {
  id: number;
  badge_type: BadgeType3;
  name: string;
  description: string;
  image: string | number;
  created_at: string;
  updated_at: string;
}

export interface BadgeType3 {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface UserProfile3 {
  data: Data7;
}

export interface Data7 {
  id: string;
  type: string;
  attributes: Attributes10;
}

export interface Attributes10 {
  name: string;
  first_name: string | number;
  last_name: string | number;
  phone_number: number;
  email: string;
  city: string | number;
  status: string;
  myrank_id: string;
  image: string | number;
  uuid: string | number;
  course_notification_enable: boolean;
  exam_notification_enable: boolean;
  package: Package4[];
}

export interface Package4 {
  id: number;
  account_id: number;
  pack_final_id: number;
  pack_pricing_id: number;
  packfinal_feature_id: string | number;
  ayq_level: number;
  sales_manager_name: string | number;
  agent1_name: string | number;
  agent2_name: string | number;
  lc_level: number;
  wb_level: number;
  bms_level: number;
  actual_price: number;
  amount: number;
  validity: string;
  expiry_date: string;
  transaction_type: string;
  status: string;
  tution_level: number;
  acat_apply: number;
  bcat_apply: number;
  created_by: string;
  created_at: string;
  updated_at: string;
  sms_level: string | number;
  active: boolean;
}

export interface Comments2 {
  data: string | number[];
}

export interface AyqQuestion2 {
  id: number;
  account_id: number;
  question: string;
  like_count: number;
  dislike_count: number;
  created_at: string;
  updated_at: string;
  subject_name: string;
  topic_name: string;
  score: number;
  view: number;
  tag_list: string[];
}

export interface Badges {
  data: Daum4[];
}

export interface Daum4 {
  id: string;
  type: string;
  attributes: Attributes11;
}

export interface Attributes11 {
  id: number;
  badge_type: BadgeType4;
  name: string;
  description: string;
  image: string | number;
  created_at: string;
  updated_at: string;
}

export interface BadgeType4 {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleAYQProfilePeek> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userProfile: Root | null;
  currentDataAll: (Daum3 | Daum)[] | null;
  currentDataQue: Daum[] | null;
  currentDataAns: Daum3[] | null;
  activeNav: number;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AYQProfilePeekController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileDataMessageId = "";
  breadcrumb = [
    {
      label: "Questions",
      link: "/dashboard/ask-your-query/questions",
    },
    {
      label: "User Profile",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userProfile: null,
      currentDataAll: null,
      currentDataQue: null,
      currentDataAns: null,
      activeNav: 0,
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getProfileDataMessageId) {
      this.handleFetchProfileSuccess(response);
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const dataId = this.props.navigation.getParam("profileId");
    this.getProfileData(dataId);
    const propPassingAPPC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingAPPC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingAPPC);
  };

  // Customizable Area Start
  handleFetchProfileSuccess = (data: Root) => {
    this.setState({
      userProfile: data,
      currentDataAll: [...data.top_answers.data, ...data.top_questions.data],
      currentDataQue: data.top_questions.data,
      currentDataAns: data.top_answers.data,
    });
  };

  getProfileData = async (dataId: string) => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getProfileDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDataMessageId = getProfileDataMessage.messageId;

    getProfileDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getProfileDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getProfileDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/visit_user_profile?account_id=${dataId}`
    );

    runEngine.sendMessage(getProfileDataMessage.id, getProfileDataMessage);
  };

  setActiveIndex = (index: number) => {
    this.setState({
      activeNav: index,
    });
  };

  // Customizable Area End
}
