import React from "react";

import { Modal, Button, withStyles, Box as MUIBox } from "@material-ui/core";

// Customizable Area Start
import TimerIcon from "@material-ui/icons/Timer";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { clean } from "./assets";
import Stepper from "../../../components/src/Stepper.web";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import { Link, Prompt } from "react-router-dom";
import { styles } from "./styles";
import TestComponent from "../../../components/src/TestComponent.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import WeeklyTestTakingController, {
  Props,
} from "./WeeklyTestTakingController.web";

class WeeklyTestTaking extends WeeklyTestTakingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const totalSeconds = Math.floor(this.state.timeRemaining / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = totalSeconds - hours * 3600 - minutes * 60;

    return (
      // Customizable Area Start
      <div>
        <Prompt
          when={this.state.isBlocking}
          message="Are you sure you want to leave this page? Your changes may be lost."
        />
        <Loader loading={this.state.loading} />
        <Modal
          open={this.state.errorModalOpen}
          BackdropProps={{ onClick: undefined }}
        >
          <MUIBox sx={webStyle.modalStyle} className="submit-test-question">
            <h3>No exam found</h3>
            <Button
              style={webStyle.buttonStyleYes}
              data-test-id="goBack"
              onClick={this.goBack}
            >
              Go back
            </Button>
          </MUIBox>
        </Modal>
        <div style={webStyle.stepperContainer}>
          <Stepper
            steps={this.state.testData.length}
            activeStep={this.state.currentTestData + 1}
          />
          <p>
            {this.state.currentTestData + 1}/{this.state.testData.length}
          </p>
        </div>
        <div style={webStyle.timerContainer}>
          <p className={this.props.classes.practiceTestTimer}>
            <span>
              <TimerIcon />
            </span>
            {String(hours).length > 1 ? hours : `${hours}`}:
            {String(minutes).length > 1 ? minutes : `0${minutes}`}:
            {String(seconds).length > 1 ? seconds : `0${seconds}`}
          </p>
        </div>
        <div className={this.props.classes.questionOverview}>
          This question carries +
          {this.state.testData[this.state.currentTestData]?.attributes.p_mark}{" "}
          for Correct Answer and -
          {Number(
            this.state.testData[this.state.currentTestData]?.attributes.n_mark
          )}{" "}
          for wrong Answer
        </div>
        <div className={this.props.classes.mainContainerPracticeTest}>
          {this.state.testData.length > 0 && (
            <TestComponent
              question={this.state.testData[this.state.currentTestData]}
              index={this.state.currentTestData}
              setAnswer={this.setAnswer}
              answers={this.state.answersWeeklyTest}
            />
          )}
          <div className="overview-practice-test">
            <div className="overview-board">
              <h3>Overview</h3>
              <div className="overview-btns">
                {this.state.testData.map((question, index) => {
                  const className = this.getBtnClassName(index);
                  return (
                    <button
                      data-test-id={`specific-btn-test-id`}
                      key={`button-${index}`}
                      className={className}
                      onClick={() => this.handleSpecificQuestion(index)}
                    >
                      {question.attributes.question_no}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="overview-legends">
              <p>
                <span>
                  <FiberManualRecordIcon style={webStyle.colorGreen} />
                </span>
                Answered{" "}
                <span style={webStyle.marginLeft08}>
                  <FiberManualRecordIcon style={webStyle.colorGray} />
                </span>
                Un-Answered
              </p>
              <p>
                <span>
                  <FiberManualRecordIcon style={webStyle.colorOrange} />
                </span>
                Answered & Marked for Review
              </p>
              <p>
                <span>
                  <FiberManualRecordIcon style={webStyle.colorRed} />
                </span>
                Not Answered & Marked for Review
              </p>
              <p>
                <span>
                  <FiberManualRecordIcon style={webStyle.colorBlue} />
                </span>
                Answered & Cleared the answer
              </p>
              <p>
                <span>
                  <FiberManualRecordIcon style={webStyle.colorPurple} />
                </span>
                Marked & Unmarked for review
              </p>
            </div>
          </div>
        </div>
        <div className={this.props.classes.btnsPracticeTest}>
          <div className="submit-mark-practice-btns">
            <button
              data-test-id={`submitTest`}
              className="submit-test-btn"
              onClick={this.handleSubmitModalOpen}
            >
              Submit Test
            </button>
          </div>
          <div className="navigation-practice-btns">
            <div className="bookmark-clear-box">
              <div
                className="bookmark-icon-review"
                onClick={this.markForReview}
                data-test-id="markforreview"
              >
                <BookmarkIcon />
              </div>
              <div
                className="clear-icon"
                onClick={this.clearResponse}
                data-test-id="clrbtn"
              >
                <img src={clean} alt="clear-icon" />
              </div>
            </div>
            <div>
              <button
                data-test-id={`prevbtn`}
                onClick={this.handlePreviousQuestion}
              >
                <ChevronLeftIcon />
              </button>
              <button data-test-id={`nxtbtn`} onClick={this.handleNextQuestion}>
                <ChevronRightIcon />
              </button>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.reviewModalOpen}
          BackdropProps={{ onClick: undefined }}
          onClose={this.handleReviewModalClose}
        >
          <div
            style={{ ...webStyle.modalStyle, height: "300px" }}
            className="submit-test-done"
          >
            <CheckCircleIcon style={webStyle.checkCircleIcon} />
            <div style={webStyle.centerStyle}>
              <h3>Your Test has been submitted Successfully</h3>
              <h3>Click On Review button to know about the answers</h3>
            </div>
            <Link
              to={`/dashboard/weekly-test/tests/take-test/${this.props.navigation.getParam(
                "testId"
              )}/overview/${this.state.reviewId}`}
            >
              <Button style={webStyle.buttonStyleYes}>Review</Button>
            </Link>
          </div>
        </Modal>
        <Modal
          open={this.state.submitModalOpen}
          BackdropProps={{ onClick: undefined }}
          onClose={this.handleSubmitModalClose}
        >
          <div style={webStyle.modalStyle} className="submit-test-question">
            <h3>Are you sure you want to submit your Test?</h3>
            <div>
              <Button
                style={webStyle.buttonStyleNo}
                data-test-id="closeSubmit"
                onClick={this.handleSubmitModalClose}
              >
                No
              </Button>
              <Button
                style={webStyle.buttonStyleYes}
                data-test-id="submitBtnModal"
                onClick={this.submitTest}
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyleYes: {
    color: "white",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    textTransform: "none" as const,
    marginLeft: "1em",
    width: "130px",
    height: "50px",
  },
  buttonStyleNo: {
    color: "#4F1f6d",
    background: "white",
    border: "1px solid #ffe9ed",
    borderRadius: "0.6em",
    textTransform: "none" as const,
    width: "130px",
    height: "50px",
  },
  modalStyle: {
    flexDirection: "column" as const,
    display: "flex",
    width: "35%",
    minHeight: "max-content",
    background: "white",
    border: "2px solid #FFD0D8",
    borderRadius: "1.5em",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "2em",
    gap: "1.5em",
  },
  checkCircleIcon: {
    color: "#ff5d5b",
    fontSize: "3.5em",
  },
  stepperContainer: {
    display: "flex",
    gap: "1em",
    padding: "1em 2em 0 2em",
  },
  timerContainer: {
    display: "flex",
    gap: "1em",
    padding: "1em 2em",
    alignItems: "center",
  },
  colorGreen: {
    color: "#9CBA58",
  },
  colorGray: {
    color: "#4B4B4B",
  },
  colorOrange: {
    color: "#FF8147",
  },
  colorRed: {
    color: "#DD1000",
  },
  colorBlue: {
    color: "#69B5FF",
  },
  colorPurple: {
    color: "#D426C9",
  },
  marginLeft08: {
    marginLeft: "0.8em",
  },
  centerStyle: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
  },
};

export default withStyles(styles)(WeeklyTestTaking);
export { WeeklyTestTaking };
// Customizable Area End
