Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.postMethodType = "POST";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Feedback";
exports.labelBodyText = "Feedback Body";
exports.bookSlotFacultyHeader = "Slot Bookings"
exports.getSlotsEndPoint ="bx_block_appointment_management/bms_slots/slot_booking";
exports.subject = "Physics";
exports.topic = "Kaynatics";
exports.Time = "12:00 - 12:30";
exports.bookSlotStudentEndPoint = "/bx_block_appointment_management/bms_bookeds/subjects";
exports.headingText = "Cleared sessions till date - 3"
exports.timeStamp = "Time";
exports.subject = "Subject";
exports.topic = "Topic";
exports.zoomlink = "Zoom link";
exports.timeslot = "Time slot: 12:00 - 12:30";
exports.summary = "Summary"
exports.Status = "Status :";
exports.name = "Sumit Belsare";
exports.btnExampleTitle = "CLICK ME";
exports.placeHolderText = "Type your message";
exports.reviewTextWrite = "Write your Review";
exports.rattingText = "Please Rate and review your doubt \n session with";
exports.feedbackMessageEndPoint = "/bx_block_scheduling/faculty_bms";
exports.selectDate = "Select Date";
exports.successSlotBook = "Slot has been registered successfully";
exports.successChangeSlotBook = "Slot is updated successfully";
exports.successCancelSlotBook = "Slot has been cancelled successfully";
exports.StudentName = "Student Name";
exports.subjectTitle = "Subject";
exports.prevFacultAppoint = "bx_block_scheduling/faculty_bms/previous_appoints";
exports.markAvailable = "Mark as available";
exports.slotsGetAll = "bx_block_appointment_management/bms_slots"
exports.bookingHistory = "Booking History"
exports.bookingSubjectsStudent = "bx_block_appointment_management/bms_bookeds/subjects"
exports.remarks = "Remarks"
exports.studentAppointment = "bx_block_appointment_management/bms_bookeds/previous_appoints"
exports.briefYourDoubt = "Brief your doubt"

exports.UpcomingAppointments = "Upcoming Appointments"
exports.changes = "Change "
exports.zoomLink = "Zoom Link"
exports.Group = "Group"
exports.studentName = "Student Name"
exports.cancel = "Cancel"
exports.slotSucces = "Slot booked succesfullly"
exports.slotBooking = "Go to bookings"
exports.put = "PUT"
exports.editSlotsEndPoint = "/bx_block_scheduling/cms_appoinments/{id}"
exports.editSlot = "Edit Slot"
exports.upComingSlotsEndPoint = "/bx_block_appointment_management/cms_bookeds/upcoming_stots"
exports.noData = "No Data Available"
exports.getSlotsEndPoint ="/bx_block_appointment_management/bms_slots/slot_booking"
exports.Available = "Available"
exports.BookedSlots = "Booked Slots"
exports.SelectDate = "Select Date"
exports.entermailID = "Enter Email ID"
exports.enterMob =  "Enter Mob Num"
exports.enterParent = "Enter Parent name"
exports.statusData = [{statuskey:'registration_done'},{statuskey:'rejected'},{statuskey:'follow_up'}]
exports.CounsellingManagerSelect = "Select Counselling Manager"
exports.counsellingManagerOfficer = "/bx_block_appointment_management/cms_bookeds/councelling_officers"
exports.counsellingmangerOffer = "Counselling Officer (Faculty)"
exports.statusEndPoint = "/bx_block_appointment_management/bms_slots"
exports.counselingManagerEndPoint = "/account_block/accounts/counselling_managers"
exports.groupEndPoint = "/bx_block_tutorials2/groups"
exports.selectTime =  "Select Timeslot"
exports.selectslotDate = "Select slot Date"
exports.CounsellingManager = "Counselling Manager"
exports.Newslot = "New slot";
exports.Headlabel = "Book My Slot";
exports.bookSlotTopicEndPoint = "/bx_block_appointment_management/bms_bookeds/{id}/topics"
exports.availableSlot = "Available Slot - ";
exports.AddButton = "+ Add";
exports.selectSlotsCenter = "Select Time"
exports.breifWrite = "Breif Your doubt"
exports.bookSlotChapterEndPoint = "/bx_block_appointment_management/bms_bookeds/{id}/chapters"
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.getSlotsStudentEndPOint = "/bx_block_appointment_management/bms_slots?subject={subjectName}"
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.booksSlots = "Booked Slot - ";
exports.selectDate = "Select Date"
exports.labelTitleText = "Scheduling";
exports.labelBodyText = "scheduling Body";
exports.subjectHeader = "Practice Test"
exports.dateandTime = "Date and Time";
exports.Time = "Time"
exports.slotsCenter = "Slots"
exports.btnExampleTitle = "CLICK ME";
exports.bookSlotFacultyHeader = "Slot Bookings"
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.StudentName = "Student Name"
exports.ParentName = "Parent Name"
exports.mobileNumber = "Mobile Number"
exports.emailID = "Email ID"
exports.dateandTime = "Date and Time:";
exports.dateandTimeData = "12th nov, 12:00-12:30"
exports.selectGroup = "Select Group"
exports.headingText = "Cleared sessions till date - "
exports.actualTIme = "12:00-12:30"
exports.Status = "Status:"
exports.status = "Status"
exports.RegisterSlot = "Register Slot"
exports.enterName = "Enter Student name";
exports.selectstudentGroup = "Enter student Group"
exports.enterStatus = "Enter status of call"
exports.newSlotEndPoint = "/bx_block_scheduling/cms_appoinments"
exports.CMToken = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzMsImV4cCI6MTcxMzg2MDk5NSwidG9rZW5fdHlwZSI6ImxvZ2luIn0.PFkGFoqaUzd8pVOsEN69KPIAMEITvjKcJ-GUlXaJrxosDhFYyGzD2pwmUZf0eYpSEcMxO5c8smPkpB2-i7DWeQ"
exports.subject = "Subject";
exports.topic = "Topic";
exports.zoomlink = "Zoom link";
exports.timeslot = "Time slot: 12:00 - 12:30";
exports.summary = "Summary";
exports.feedback = "Feedback";
exports.upcommingEndPoint = "/bx_block_scheduling/faculty_bms/upcoming_appoints";
exports.studentUpcomming ="/bx_block_appointment_management/bms_bookeds/upcoming_appoints";
exports.previousEndPoint = "/bx_block_scheduling/faculty_bms/previous_appoints";
exports.studentPrevious = "/bx_block_appointment_management/bms_bookeds/previous_appoints";
exports.CMToken = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTAzNywiZXhwIjoxNzEyMzE3MTQxLCJ0b2tlbl90eXBlIjoibG9naW4ifQ.2c0et4aB5fDq_JrcRSvrmD38vWIxYzb7qu8S_TBz-6hMTtTIEocE234kzlKN_MC1O2mMU1tKBqq5fBvoAaBeUw";
exports.availableSlotKey = "Mark as available";
exports.unAvailableSlotKey = "Mark as not available";
exports.bookedSlots = "/bx_block_scheduling/faculty_bms?availability=";
exports.goBack = "Go back";
exports.upcommingText = "Registered slots for today";
exports.getSlotEndPoint = "/bx_block_scheduling/faculty_bms?slot_date="
exports.studentBookSlotEndPOint = "/bx_block_appointment_management/bms_bookeds"
exports.submitTextData = "Book my slot"
exports.goToBack = "Go to Bookings"
exports.feedbackSuccess = "Your slot has booked"
exports.sessionMessage = "Hope you have a good session"
exports.studentFeedbackMessageEndPoint = "/bx_block_appointment_management/bms_bookeds";
exports.getBookStudentEndPoint = "/bx_block_appointment_management/bms_bookeds";
exports.submitText = "Submit";
// Customizable Area End
