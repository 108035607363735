import React, { useCallback, useEffect, useState } from "react";
import Calendar from "react-calendar";
import { makeStyles, createStyles } from "@material-ui/core/styles";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const useStyles = makeStyles(() =>
  createStyles({
    dateTimePicker: {
      width: "100%!important",
      "& .react-calendar__navigation__prev2-button, & .react-calendar__navigation__next2-button":
        {
          display: "none!important",
        },
      "& .react-calendar__navigation": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      "& .react-calendar__tile": {
        background: "none",
        border: "none",
        fontSize: "1.5em",
        fontWeight: 500,
        margin: "1.5em 0",
        cursor: "pointer",
        height: "100%",
        overflow: "initial!important",
        color: "#2DA127",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& abbr": {
          border: "none",
          borderRadius: "50%",
          padding: "0.3em",
          width: "2.2em",
          height: "2.2em",
        },
      },
      "& .react-calendar__month-view__weekdays": {
        margin: "2em 0",
        fontWeight: 500,
        color: "#aaa",
        fontSize: "1.3em",
        textAlign: "center",
        "& abbr": {
          textDecoration: "none",
        },
      },
      "& .react-calendar__navigation__label": {
        border: "none",
        background: "none",
        fontSize: "2em",
        fontWeight: 500,
        color: "#333",
        cursor: "pointer",
      },
      "& .react-calendar__navigation__arrow": {
        border: "none",
        padding: "0.1em 0.7em",
        color: "white",
        background: "#520082",
        borderRadius: "50%",
        fontSize: "2em",
        cursor: "pointer",
      },
      "& .react-calendar__tile--active": {
        "& abbr": {
          border: "none",
          borderRadius: "50%",
          padding: "0.3em 0.7em",
          background: "#FF5C5C",
          color: "white",
        },
      },
    },
  })
);

export default function CalendarComponent({
  onChangeDate,
}: {
  onChangeDate: (currentValue: string) => void;
}) {
  const classes = useStyles();
  const [value, onChange] = useState<Value>(new Date());

  const applyDateChanges = useCallback(() => {
    onChangeDate(String(value));
  }, [value]);

  useEffect(() => {
    applyDateChanges();
  }, [applyDateChanges]);

  return (
    <Calendar
      onChange={onChange}
      value={value}
      className={classes.dateTimePicker}
    />
  );
}
