import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
export interface ChapterTopic {
  attributes: Attributes;
  type: string;
  id: string;
}

export interface Attributes {
  id: number;
  remark_2: string;
  date_synopsis: any;
  question_status: string;
  search_key: any;
  created_at: string;
  updated_at: string;
  synopsis: string;
  topic_read: boolean;
  name: string;
  remarks: string;
  saved_notes_count: number;
  is_bookmarked: boolean;
}

export interface Meta {
  chapter: MetaChapter;
}

export interface MetaChapter {
  id: number;
  name: string;
  avail_cz: string;
  date_cz: any;
  question_bank: string;
  created_at: string;
  updated_at: string;
  practice_test: string;
  avail_pt: string;
  date_pt: any;
  class_zone: string;
  avail_qb: string;
  qb_id: any;
  qb_db: string;
  id_pt: string;
  id_cz: string;
  date_qb: any;
  created_by: string;
  remarks: string;
  topic_db: string;
}

import { WithStyles } from "@material-ui/core";
import { webStyleSynopsisTopics } from "./SynopsisGuestTopics.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleSynopsisTopics> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  allTopics: ChapterTopic[];
  meta: Meta | null;
  isModalOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SynopsisGuestTopicsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSynopsisTopicsMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      allTopics: [],
      isModalOpen: false,
      meta: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSynopsisTopicsMessageId) {
      if (response.data) {
        this.handleSuccessfulTopics(response);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const param = this.props.navigation.getParam("chapterId");
    if (!(await storage.get("guestToken"))) {
      this.props.navigation.navigate("LandingPage");
    } else {
      this.getSynopsisTopics(param);
    }
  };

  handleModalOpen = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  handleSuccessfulTopics = (response: { data: ChapterTopic[]; meta: Meta }) => {
    this.setState({
      allTopics: response.data,
      meta: response.meta,
    });
  };

  navigateTo = (chapterId: string) => {
    this.props.navigation.navigate("GuestSynopsisCheckPage", { chapterId });
  };

  // Customizable Area Start
  getSynopsisTopics = async (chapterId: string) => {
    const getSynopsisTopicsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      uuid: await storage.get("guestToken"),
    };
    getSynopsisTopicsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/guestsynopsis/${chapterId}/chapter_topic`
    );
    this.getSynopsisTopicsMessageId = getSynopsisTopicsMessage.messageId;
    getSynopsisTopicsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSynopsisTopicsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getSynopsisTopicsMessage.id,
      getSynopsisTopicsMessage
    );
  };
  // Customizable Area End
}
