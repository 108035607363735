import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { webStyleAYQAskQuestion } from "./AYQAskQuestion.web";
import storage from "../../../framework/src/StorageProvider.web";
import { Daum } from "../../CommunityForum/src/AYQQuestionController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleAYQAskQuestion> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  query: string;
  inputs: {
    tags: string;
    subject: string;
    topic: string;
  };
  tags: string[];
  isModalOpen: boolean;
  totalSearches: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AYQAskQuestionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postUserQuestionMessageId = "";
  getSearchMessageId = "";
  searchTimeout: NodeJS.Timeout | null = null;
  breadcrumb = [
    {
      label: "Ask a Question",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      query: "",
      inputs: {
        subject: "",
        tags: "",
        topic: "",
      },
      tags: [],
      isModalOpen: false,
      totalSearches: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.postUserQuestionMessageId) {
      this.handleSuccessPost();
    }

    if (apiRequestCallId === this.getSearchMessageId) {
      this.handleSearchSuccess(response);
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingAAQCWithCK = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingAAQCWithCK.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingAAQCWithCK);
  };

  handleSearchSuccess = (response: { data: Daum[]; errors: string }) => {
    if (!response.errors) {
      this.setState({
        totalSearches: response.data.length,
      });
    }
  };
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;

    if (name === "tags") {
      if (value[value.length - 1] !== " ") {
        this.setState({
          inputs: {
            ...this.state.inputs,
            [name]: value,
          },
        });
      }
    } else {
      this.setState({
        inputs: {
          ...this.state.inputs,
          [name]: value,
        },
      });
    }
  };
  navigateToSimilarQuestion = () => {
    const currentValue = this.state.query.replace(
      /<(?:[^>"']|"[^"]*"|'[^']*')*>/g,
      ""
    );
    this.props.navigation.navigate("AskYourQuerySimilarQuestion", {
      search: currentValue,
    });
  };

  handleChange = (value: string) => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.setState({ query: value }, () => {
      const currentValue = value.replace(/<(?:[^>"']|"[^"]*"|'[^']*')*>/g, "");
      if (currentValue.length > 0) {
        this.searchTimeout = setTimeout(() => {
          this.searchData(currentValue);
        }, 600);
      } else {
        this.setState({
          totalSearches: 0,
        });
      }
    });
  };

  handleSuccessPost = () => {
    this.handleModalOpen();
    this.setState({
      inputs: {
        subject: "",
        tags: "",
        topic: "",
      },
      query: "",
      tags: [],
    });
  };

  handleModalOpen = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.postUserQuestion();
  };

  removeTag = (index: number) => {
    this.setState({
      tags: this.state.tags.filter((_, idx) => idx !== index),
    });
  };

  searchData = async (search: string) => {
    const headers = {
      token: await storage.get("authToken"),
    };

    const getSearchMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchMessageId = getSearchMessage.messageId;

    getSearchMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSearchMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getSearchMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/search?search_text=${search}`
    );

    runEngine.sendMessage(getSearchMessage.id, getSearchMessage);
  };

  handleKeyDown = (keyPressed: string) => {
    if (keyPressed === " " && this.state.inputs.tags.length > 0) {
      this.setState({
        inputs: {
          ...this.state.inputs,
          tags: "",
        },
        tags: [...this.state.tags, this.state.inputs.tags],
      });
    }
  };

  // Customizable Area Start
  postUserQuestion = async () => {
    const postUserQuestionMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };
    const body = {
      data: {
        subject_name: this.state.inputs.subject,
        topic_name: this.state.inputs.topic,
        question: this.state.query,
        tag_list: this.state.tags,
      },
    };
    postUserQuestionMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions`
    );
    this.postUserQuestionMessageId = postUserQuestionMessage.messageId;
    postUserQuestionMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    postUserQuestionMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    postUserQuestionMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(postUserQuestionMessage.id, postUserQuestionMessage);
  };

  handleGoBack = () => {
    this.props.navigation.navigate("AskYourQueryQuestion");
  };
  // Customizable Area End
}
