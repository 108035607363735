import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Select,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
type AnalysisDefaultType = {
  title: string;
  year: string;
  withStateId: boolean;
  withUniversity: boolean;
  withRound: boolean;
  categorySet2: boolean;
  categoryWise?: boolean;
  collegeWise?: boolean;
  courseWise?: boolean;
};

import Banner from "../../../components/src/Banner.web";
// Customizable Area End

import MPCGuidanceCutOffsController, {
  Props,
  configJSON,
} from "./MPCGuidanceCutOffsController.web";

class MPCGuidanceCutOffs extends MPCGuidanceCutOffsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderWithStateId = ({
    isRender,
    stateAnalysis,
  }: {
    isRender: boolean;
    stateAnalysis:
      | "stateAnalysis"
      | "stateAnalysisCollege"
      | "stateAnalysisCourse";
  }) => {
    if (!isRender) {
      return <></>;
    }

    return (
      <Box className={this.props.classes.selectionTypeStyle}>
        <Box className="selection-label">Home State/UT</Box>
        <Select
          data-test-id="state-select"
          value={this.state.userState[stateAnalysis]}
          className="selection-select"
          onChange={(event) => this.handleChangeState(event, stateAnalysis)}
          required
        >
          {this.state.states.map((states) => {
            return (
              <MenuItem
                data-test-id="menu-states-item"
                key={`${states}-states-category`}
                value={states}
              >
                {states}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    );
  };

  RenderWithUniversityId = ({
    isRender,
    analysis,
  }: {
    isRender: boolean;
    analysis:
      | "universityAnalysis"
      | "universityAnalysisCollege"
      | "universityAnalysisCourse";
  }) => {
    if (!isRender) {
      return <></>;
    }

    return (
      <Box className={this.props.classes.selectionTypeStyle}>
        <Box className="selection-label">University Region</Box>
        <Select
          data-test-id="university-select"
          value={this.state.userUniversity[analysis]}
          className="selection-select"
          onChange={(event) => this.handleChangeUnivesity(event, analysis)}
          required
        >
          {this.state.university.map((university) => {
            return (
              <MenuItem
                data-test-id="menu-university-item"
                key={`${university.label}-university-category`}
                value={university.value}
              >
                {university.label}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    );
  };

  RenderWithRound = ({
    isRender,
    analysis,
  }: {
    isRender: boolean;
    analysis: "roundAnalysis" | "roundAnalysisCollege" | "roundAnalysisCourse";
  }) => {
    if (!isRender) {
      return <></>;
    }

    return (
      <Box className={this.props.classes.selectionTypeStyle}>
        <Box className="selection-label">Rounds</Box>
        <Select
          data-test-id="rounds-select"
          value={this.state.userRounds[analysis]}
          className="selection-select"
          onChange={(event) => this.handleChangeRound(event, analysis)}
          required
        >
          {this.state.rounds.map((round) => {
            return (
              <MenuItem key={round.label} value={round.value}>
                {round.label}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    );
  };

  RenderCategorySet = (
    isCategorySet2: boolean,
    analysis:
      | "categoryAnalysis"
      | "categoryAnalysisCollege"
      | "categoryAnalysisCourse"
  ) => {
    const category = isCategorySet2
      ? this.state.categorySet2
      : this.state.category;

    return (
      <Box className={this.props.classes.selectionTypeStyle}>
        <Box className="selection-label">Category</Box>
        <Select
          data-test-id="category-select"
          value={this.state.userInput[analysis]}
          className="selection-select"
          onChange={(event) => this.handleChangeCategory(event, analysis)}
          required
        >
          {category.map((categorySet) => {
            return (
              <MenuItem
                data-test-id="menu-category-item"
                key={categorySet.key}
                value={categorySet.key}
              >
                {categorySet.title}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    );
  };

  DefaultUI = ({
    title,
    year,
    withStateId,
    withUniversity,
    withRound,
    categoryWise,
    collegeWise,
    courseWise,
    categorySet2,
  }: AnalysisDefaultType) => {
    return (
      <>
        <Banner title={`${title} ${year}`} />
        <Box className={this.props.classes.mainContainer}>
          <Box className={this.props.classes.mainTitle}>{title}</Box>
          {categoryWise && (
            <Box className={this.props.classes.analysisContainer}>
              <Typography className={this.props.classes.analysisTitle}>
                {title}-{year} {configJSON.chooseCatgories[0]}
              </Typography>
              <form
                className={this.props.classes.analysisContainer}
                onSubmit={(event) =>
                  this.navigateToAnalysisMPC(event, {
                    category: this.state.userInput.categoryAnalysis,
                    round: this.state.userRounds.roundAnalysis,
                    stateId: withStateId
                      ? this.state.userState.stateAnalysis
                      : undefined,
                    univId: withUniversity
                      ? this.state.userUniversity.universityAnalysis
                      : undefined,
                  })
                }
                data-test-id="get-analysis-btn"
              >
                <Box className={this.props.classes.selectionContainer}>
                  {this.RenderWithStateId({
                    isRender: withStateId,
                    stateAnalysis: "stateAnalysis",
                  })}
                  {this.RenderWithUniversityId({
                    isRender: withUniversity,
                    analysis: "universityAnalysis",
                  })}
                  {this.RenderCategorySet(categorySet2, "categoryAnalysis")}
                  {this.RenderWithRound({
                    isRender: withRound,
                    analysis: "roundAnalysis",
                  })}
                </Box>
                <button
                  className={this.props.classes.analysisButtonCategory}
                  type="submit"
                >
                  GET ANALYSIS
                </button>
              </form>
            </Box>
          )}
          {collegeWise && (
            <Box className={this.props.classes.analysisContainer}>
              <Typography className={this.props.classes.analysisTitle}>
                {title}-{year} {configJSON.chooseCatgories[1]}
              </Typography>
              <form
                className={this.props.classes.analysisContainer}
                onSubmit={(event) =>
                  this.navigateToAnalysisMPC(event, {
                    category: this.state.userInput.categoryAnalysisCollege,
                    round: this.state.userRounds.roundAnalysisCollege,
                    stateId: withStateId
                      ? this.state.userState.stateAnalysisCollege
                      : undefined,
                    univId: withUniversity
                      ? this.state.userUniversity.universityAnalysisCollege
                      : undefined,
                  })
                }
                data-test-id="get-analysis-btn"
              >
                <Box className={this.props.classes.selectionContainer}>
                  {this.RenderWithStateId({
                    isRender: withStateId,
                    stateAnalysis: "stateAnalysisCollege",
                  })}
                  {this.RenderWithUniversityId({
                    isRender: withUniversity,
                    analysis: "universityAnalysisCollege",
                  })}
                  {this.RenderCategorySet(
                    categorySet2,
                    "categoryAnalysisCollege"
                  )}
                  <Box className={this.props.classes.selectionTypeStyle}>
                    <Box className="selection-label">College Name</Box>
                    <Select
                      data-test-id="college-select"
                      value={this.state.collegeAnalysis}
                      onChange={(event) => this.handleCollegeSelect(event)}
                      className="selection-select"
                      required
                    >
                      {this.state.collegeList.map((college) => {
                        return (
                          <MenuItem
                            data-test-id="menu-category-item"
                            key={college.attributes.college_name}
                            value={college.attributes.college_name}
                          >
                            {college.attributes.college_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                  {this.RenderWithRound({
                    isRender: withRound,
                    analysis: "roundAnalysisCollege",
                  })}
                </Box>
                <button
                  className={this.props.classes.analysisButtonCollege}
                  type="submit"
                >
                  GET ANALYSIS
                </button>
              </form>
            </Box>
          )}
          {courseWise && (
            <Box className={this.props.classes.analysisContainer}>
              <Typography className={this.props.classes.analysisTitle}>
                {title}-{year} {configJSON.chooseCatgories[2]}
              </Typography>
              <form
                className={this.props.classes.analysisContainer}
                onSubmit={(event) =>
                  this.navigateToAnalysisMPC(event, {
                    category: this.state.userInput.categoryAnalysisCourse,
                    round: this.state.userRounds.roundAnalysisCourse,
                    stateId: withStateId
                      ? this.state.userState.stateAnalysisCourse
                      : undefined,
                    univId: withUniversity
                      ? this.state.userUniversity.universityAnalysisCourse
                      : undefined,
                  })
                }
                data-test-id="get-analysis-btn"
              >
                <Box className={this.props.classes.selectionContainer}>
                  {this.RenderWithStateId({
                    isRender: withStateId,
                    stateAnalysis: "stateAnalysisCourse",
                  })}
                  {this.RenderWithUniversityId({
                    isRender: withUniversity,
                    analysis: "universityAnalysisCourse",
                  })}
                  {this.RenderCategorySet(
                    categorySet2,
                    "categoryAnalysisCourse"
                  )}
                  <Box className={this.props.classes.selectionTypeStyle}>
                    <Box className="selection-label">Course Name</Box>
                    <Select
                      data-test-id="course-select"
                      value={this.state.courseAnalysis}
                      className="selection-select"
                      onChange={(event) => this.handleCourseSelect(event)}
                      required
                    >
                      {this.state.courseList.map((course) => {
                        return (
                          <MenuItem
                            data-test-id="menu-category-item"
                            key={course.attributes.course_name}
                            value={course.attributes.course_name}
                          >
                            {course.attributes.course_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                  {this.RenderWithRound({
                    isRender: withRound,
                    analysis: "roundAnalysisCourse",
                  })}
                </Box>
                <button
                  type="submit"
                  className={this.props.classes.analysisButtonCourse}
                >
                  GET ANALYSIS
                </button>
              </form>
            </Box>
          )}
        </Box>
      </>
    );
  };

  RenderJEEAdvanced2020 = () => {
    return (
      <>
        {this.DefaultUI({
          title: "JEE ADVANCED",
          year: "2020",
          withStateId: false,
          withUniversity: false,
          withRound: true,
          categoryWise: true,
          collegeWise: true,
          courseWise: true,
          categorySet2: false,
        })}
      </>
    );
  };

  RenderJEEMains2020 = () => {
    return (
      <>
        {this.DefaultUI({
          title: "JEE MAINS(JOSAA only)",
          year: "2020",
          withStateId: true,
          withUniversity: false,
          withRound: true,
          categoryWise: true,
          collegeWise: true,
          courseWise: true,
          categorySet2: false,
        })}
      </>
    );
  };

  RenderJEEMainsCsab2020 = () => {
    return (
      <>
        {this.DefaultUI({
          title: "JEE MAINS(CSAB only)",
          year: "2020",
          withStateId: true,
          withUniversity: false,
          withRound: true,
          categoryWise: true,
          collegeWise: true,
          courseWise: true,
          categorySet2: false,
        })}
      </>
    );
  };

  RenderTSEamcetEngg2020 = () => {
    return (
      <>
        {this.DefaultUI({
          title: "TS EAMCET ENGINEERING",
          year: "2020",
          withStateId: false,
          withUniversity: true,
          withRound: false,
          categoryWise: true,
          collegeWise: true,
          courseWise: true,
          categorySet2: true,
        })}
      </>
    );
  };

  RenderMPCBasedOnParams = () => {
    switch (this.state.activeMPCName) {
      case "jeeadv":
        return this.RenderJEEAdvanced2020();
      case "jeemainsjosaa":
        return this.RenderJEEMains2020();
      case "jeemainscsab":
        return this.RenderJEEMainsCsab2020();
      case "tseamcetengg":
        return this.RenderTSEamcetEngg2020();
      default:
        return <Box>Nothing to display here yet</Box>;
    }
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>{this.RenderMPCBasedOnParams()}</Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const buttonDefault = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "71px",
  color: "white",
  width: "90%",
  fontSize: "32px",
  cursor: "pointer",
  border: "none",
  transition: "all 0.3s ease",
  "&:hover": {
    scale: "0.98",
  },
};

export const webStyleMPCGuidanceCutOffs = () =>
  createStyles({
    mainTitle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "106px",
      color: "white",
      width: "90%",
      fontSize: "32px",
      background: "rgb(1, 192, 238)",
    },
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "3em 0",
      gap: "3em",
    },
    analysisContainer: {
      height: "max-content",
      padding: "2em 0",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      background: "rgb(242, 242, 242)",
      gap: "3em",
    },
    analysisTitle: {
      fontSize: "32px",
      fontWeight: 500,
    },
    selectionContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "2em",
      "& .MuiSelect-root": {
        height: "70px",
        paddingLeft: "3em",
        display: "flex",
        alignItems: "center",
      },
    },
    analysisButtonCategory: {
      ...buttonDefault,
      background: "rgb(222, 76, 57)",
    },
    analysisButtonCourse: {
      ...buttonDefault,
      background: "rgb(241, 154, 17)",
    },
    analysisButtonCollege: {
      ...buttonDefault,
      background: "rgb(5, 164, 89)",
    },
    selectionTypeStyle: {
      display: "flex",
      "& .selection-label": {
        width: "295px",
        height: "86px",
        border: "1px solid rgb(151, 151, 151)",
        fontSize: "22px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .selection-select": {
        width: "765px",
        height: "86px",
        border: "1px solid rgb(151, 151, 151)",
        fontSize: "22px",
      },
    },
  });
export default withStyles(webStyleMPCGuidanceCutOffs)(MPCGuidanceCutOffs);
export { MPCGuidanceCutOffs };
// Customizable Area End
