import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isModalOpen: boolean;
  isModalOpen2: boolean;
  userInput: {
    emailAddress: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPassword extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postSendCredentialsDocumentId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isModalOpen: false,
      isModalOpen2: false,
      userInput: {
        emailAddress: "",
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.postSendCredentialsDocumentId) {
      if (responseJson.message) {
        this.handleOpenModal2();
      } else {
        this.handleOpenModal3();
      }
    }
    // Customizable Area End
  }

  // web events
  handleGoBack2 = () => {
    this.props.navigation.goBack();
  };

  handleOpenModal2 = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleCloseModal2 = () => {
    this.setState({
      isModalOpen: false,
    });
    this.props.navigation.navigate("Login")
  };

  handleOpenModal3 = () => {
    this.setState({
      isModalOpen2: true,
    });
  };

  handleCloseModal3 = () => {
    this.setState({
      isModalOpen2: false,
    });
  };

  handleInputChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    this.setState({
      userInput: {
        ...this.state.userInput,
        [name]: value,
      },
    });
  };

  // Customizable Area Start
  sendCredentials = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const header = {
      "Content-Type": "application/json",
    };

    const requestBody = {
      data: {
        attributes: {
          email: this.state.userInput.emailAddress,
        },
      },
    };

    const apiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.postSendCredentialsDocumentId = apiMessage.messageId;

    apiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCredentialsEndpoint
    );

    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(apiMessage.id, apiMessage);
  };
  // Customizable Area End
}
