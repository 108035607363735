import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

type Test = {
  id: string;
  type: string;
  attributes: {
    id: number;
    test_no: number;
    test_name: string;
    gt_code: string;
    avail_test: boolean;
    limit_test: string;
    test_page: string;
    analysis_page: string;
    avail_analysis: string;
    calc_page: string;
    attempted: boolean;
    image_url: string;
    created_by: string;
    created_at: string;
    search_key: any;
  };
};
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tests: Test[];
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GuestTestsGrandTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGrandTestListMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      tests: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getGrandTestListMessageId && response) {
      if (response?.grand_tests) {
        this.fetchedTestsSuccessful(response.grand_tests.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const packageId = this.props.navigation.getParam("packageId");
    this.getTests(packageId);
  };

  // Customizable Area Start
  fetchedTestsSuccessful = (tests: Test[]) => {
    this.setState({
      tests,
    });
  };

  navigateTo = (link: string) => {
    this.props.navigation.history.push(link);
  };

  getTests = async (packageId: string) => {
    const getGrandTestListMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      uuid: await storage.get("guestToken"),
    };

    this.setState({
      loading: true,
    });

    this.getGrandTestListMessageId = getGrandTestListMessage.messageId;

    getGrandTestListMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/guest_grand_tests?gt_code=${packageId}`
    );

    getGrandTestListMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getGrandTestListMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getGrandTestListMessage.id, getGrandTestListMessage);
  };
  // Customizable Area End
}
