import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  WithStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CircularProgress from "@material-ui/core/CircularProgress";
import { gradhat, searchImage, clock, oval } from "./assets";
// Customizable Area End

import DashboardGuidanceHomeController, {
  Props,
  configJSON,
} from "./DashboardGuidanceHomeController.web";

class DashboardGuidanceHome extends DashboardGuidanceHomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderContinueApplication = ({ classes }: WithStyles) => {
    return (
      <Box className={classes.applicationBox}>
        <Box className={classes.applicationBoxImages}>
          <img
            src={oval}
            alt="application-image"
            className={classes.imageApplicationStyle}
          />
          <img
            src={clock}
            alt="application-clock"
            className={classes.clockPosition}
          />
        </Box>
        <Box>
          <Box className={classes.applicationInfoStyle}>
            <Typography>University</Typography>
            <Typography>-</Typography>
            <Typography>Christ University</Typography>
          </Box>
        </Box>
        <Box>
          <Box className={classes.applicationInfoStyle}>
            <Typography>Course</Typography>
            <Typography>-</Typography>
            <Typography>Btech</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  RenderOpenings = ({ classes }: WithStyles) => {
    return (
      <Box className={classes.openingBox}>
        <Box className={classes.openingImageContainer}>
          <img src={oval} alt="image-opening" />
        </Box>
        <Box className={classes.textOpeningContainer}>
          <Typography>University of Mumbai</Typography>
          <Typography>Course : Bachelors of Finance</Typography>
          <Typography>22 Feb 2023, 1:45PM</Typography>
        </Box>
        <Button className={classes.buttonOpening}>View Opening</Button>
      </Box>
    );
  };

  RenderUnapproved = ({ classes }: WithStyles) => {
    return (
      <Box className={classes.unapprovedBox}>
        <Box className={classes.unapprovedHeader}>
          <img src={oval} alt="image-opening" />
          <Box>
            <Typography style={{ color: "#510081", fontWeight: 700 }}>
              Parul University
            </Typography>
            <Typography style={{ fontWeight: 500 }}>
              Course - Btech I.T
            </Typography>
          </Box>
        </Box>
        <Button className={classes.buttonUnapproved}>Reapply</Button>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const Loader = () => {
      return (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"80vh"}
        >
          <CircularProgress />
        </Box>
      );
    };

    return (
      // Customizable Area Start
      <>
        {this.state.userData ? (
          <Box data-test-id="guidance-home-container">
            <Box className={classes.initialSectionStyle} padding={"2em"}>
              <Box className={classes.initialIntroStyle}>
                <Typography variant="h4">
                  Hello {this.state.userName}!
                </Typography>
                <Typography variant="h5" className={classes.semiBoldStyle}>
                  {configJSON.welcome}
                </Typography>
              </Box>
              <Box className={classes.searchInputContainer}>
                <img
                  src={searchImage}
                  alt="searchImage"
                  className={classes.searchImageStyle}
                />
                <input
                  className={classes.searchInputStyle}
                  data-test-id="search-input"
                />
              </Box>
            </Box>
            <Box padding={"2em"}>
              <Box className={classes.headerBoxStyle}>
                <Typography>M/Bi.P.C</Typography>
                <Typography>Expiry - 31/08/2023</Typography>
                <Box className={classes.amountPaidContainer}>
                  <Typography>Amount Paid - 12999/-</Typography>
                </Box>
                <Box className={classes.gradHatStyle}>
                  <img src={gradhat} alt="gradhat" />
                </Box>
              </Box>
            </Box>
            <Box className={classes.containerForAll}>
              <Box padding={"2em"}>
                <Box>
                  <Typography variant="h5">
                    Continue with your Application
                  </Typography>
                  <Box
                    className={classes.boxContainer}
                    style={{
                      background: "rgb(242,239,243)",
                      borderRadius: "1em",
                    }}
                  >
                    {this.RenderContinueApplication(this.props)}
                  </Box>
                </Box>
              </Box>
              <Box className={classes.containerWithBackground}>
                <Box>
                  <Typography variant="h5">
                    Upcoming University Openings
                  </Typography>
                  <Box className={classes.boxContainer}>
                    {this.RenderOpenings(this.props)}
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h5">
                    Reapply Unapproved Applications
                  </Typography>
                  <Box className={classes.boxContainer}>
                    {this.RenderUnapproved(this.props)}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Loader />
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleDashboardGuidanceHome = () =>
  createStyles({
    searchInputStyle: {
      borderRadius: "3em",
      border: "2px solid #510081",
      height: "50px",
      padding: "0 0.7em 0 10%",
      width: "500px",
      outline: "none",
      fontSize: "20px",
    },
    searchInputContainer: {
      position: "relative",
    },
    searchImageStyle: {
      position: "absolute",
      left: "2.5%",
      top: "20%",
      width: "27px",
    },
    initialSectionStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    initialIntroStyle: {
      display: "flex",
      flexDirection: "column",
      gap: "2em",
    },
    boldStyle: {
      fontWeight: 700,
    },
    semiBoldStyle: {
      fontWeight: 500,
    },
    headerBoxStyle: {
      width: "100%",
      height: "350px",
      border: "none",
      borderRadius: "0.7em",
      boxShadow: "-5px 8px 10px #ccc",
      overflow: "hidden",
      marginTop: "4em",
      position: "relative",
      padding: "3em",
      "& p": {
        fontSize: "1.7em",
        color: "#888",
      },
    },
    gradHatStyle: {
      border: "15px solid #684DB2",
      borderRadius: "50%",
      width: "max-content",
      padding: "10em",
      position: "absolute",
      right: "-100px",
      background: "#F4EEFC",
      top: "-75px",
    },
    applicationBox: {
      width: "350px",
      height: "250px",
      borderRadius: "1.5em",
      border: "1px solid #CB5464",
    },
    unapprovedBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "2em",
      width: "350px",
      height: "220px",
      borderRadius: "1em",
      border: "1px solid #CB5464",
      padding: "1.5em",
    },
    openingBox: {
      width: "350px",
      height: "300px",
      borderRadius: "1.5em",
      border: "1px solid rgb(207, 186, 218)",
      padding: "1em",
    },
    boxContainer: {
      display: "flex",
      alignItems: "center",
      width: "max-content",
      height: "max-content",
      flexShrink: 0,
      gap: "3em",
      marginTop: "2em",
    },
    imageApplicationStyle: {
      borderRadius: "50%",
      border: "none",
      width: "90px",
      height: "90px",
    },
    containerForAll: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: "3em",
      marginTop: "5em",
    },
    containerWithBackground: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: "3em",
      width: "100%",
      background:
        "linear-gradient(180deg, rgba(244,229,225,1) 0%, rgba(9,9,121,0) 97%, rgba(0,212,255,0) 100%)",
      padding: "2em",
    },
    applicationBoxImages: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1em",
      marginTop: "1.5em",
    },
    clockPosition: {
      marginRight: "2em",
      width: "30px",
    },
    applicationInfoStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1em 2em",
    },
    openingImageContainer: {
      width: "100%",
      borderRadius: "0.75em",
      height: "120px",
      overflow: "hidden",
      background: "#999",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectPosition: "center center",
      },
    },
    buttonOpening: {
      width: "150px",
      height: "40px",
      color: "white",
      fontWeight: 700,
      borderRadius: "0.8em",
      padding: "0.5em 2em",
      fontSize: "0.9em",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    },
    textOpeningContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "0.4em",
      margin: "0.6em 0",
    },
    buttonUnapproved: {
      width: "200px",
      height: "47px",
      color: "white",
      fontWeight: 700,
      borderRadius: "0.8em",
      padding: "0.5em 2em",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    },
    unapprovedHeader: {
      display: "flex",
      alignItems: "center",
      "& img": {
        marginRight: "1.5em",
        borderRadius: "50%",
        border: "none",
        width: "90px",
        height: "90px",
      },
    },
    amountPaidContainer: {
      width: "700px",
      border: "1px solid #EEE4D3",
      background: "#FFF7EC",
      height: "150px",
      padding: "2em",
      marginTop: "2em",
      "& p": {
        color: "#7A84A6",
        fontWeight: 700,
      },
    },
  });
export default withStyles(webStyleDashboardGuidanceHome)(DashboardGuidanceHome);
export { DashboardGuidanceHome };
// Customizable Area End
