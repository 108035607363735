import React from "react";

import {
  Box,
  // Customizable Area Start
  Paper,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// Customizable Area End

import MegamenuLandingController, {
  Props,
} from "./MegamenuLandingController.web";

export default class MegamenuLanding extends MegamenuLandingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Paper
        style={{
          position: "absolute",
          backgroundColor: "white",
          padding: "20px",
          display: this.props.isOpen ? "block" : "none",
          zIndex: 9999,
          width: "80%",
          maxWidth: "80%",
          top: "100px",
          minHeight: "700px",
          margin: "auto auto",
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
      >
        <Box display={"flex"} flexWrap={"wrap"} gridGap={"2em"}>
          {this.state.headersWithExams.map((menu, index) => (
            <Box key={index}>
              <Typography variant="h6" gutterBottom>
                <FiberManualRecordIcon fontSize={"small"} />
                {menu.attributes.name}
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gridGap={"1.5em"}
                marginTop={"2em"}
              >
                {menu.attributes.exams.data.map((item, i) => (
                  <p
                    key={i}
                    style={{
                      fontWeight: 500,
                      cursor: "pointer",
                      fontSize: "1.1em",
                    }}
                    data-test-id="navigate-to-content"
                    onClick={() => {
                      this.props.toggleShowGuidance();
                      this.navigateToMenuContents(menu.id, item.id);
                    }}
                  >
                    {item.attributes.name}
                  </p>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Paper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
