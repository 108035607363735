Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.deactiveInnerHeader = "You are account will be temporarily closed and you \n will not recieve notifications unless you login"
exports.deactiveHeaderLabel = "Are you sure to Deactivate account?"
exports.deactiveLabel = "Deactivate"
exports.logOutLabel = "Logout"
exports.cancel = "cancel"
exports.MainLabel ="Are you sure to Logout"
exports.logoutLabel = "You will be loggedout from the application you can \n login back again"
exports.putMethod = "PUT"
exports.editProfil = "Edit Profile Details"
exports.aboutUS = "About Us"
exports.contactUs = "Contact Us"
exports.Registration = "Registration"
exports.TandC = "Terms and conditions"
exports.leaderBoard = "Leaderboard"
exports.courseNotif = "Course Notifications"
exports.examNotif = "Exam Notifications"
exports.mypack = "My Packages"
exports.myRank = "MyRank Blogs"
exports.answerEndPoint = "/bx_block_communityforum/ayq_answers/user_ayq_answer"
exports.DeactiveAcc = "Deactivate Account"
exports.logOut = "Log out"
exports.medalLabel = "Curious"
exports.label = "My Profile"
exports.nameLabel = "Profile Name"
exports.contentType = "multipart/form-data"
exports.patch="PATCH"
exports.updateProfileEndPoint = "/account_block/profile_settings/edit_profile"
exports.myRankID = "My Rank ID"
exports.emailID = "Email Id"
exports.phoneNumber = "Phone Number"
exports.firstName = "First Name"
exports.lastName = 'Last Name'
exports.Save = "Save"
exports.editProfileLabel = "Edit Profile"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.answerLabel = "Answered";
exports.answerLa = "Answer";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";
exports.profileDetailsEndPoint = "/account_block/profile_settings/profile"
exports.btnExampleTitle = "CLICK ME";
exports.courseNotificationEndPoint = "/account_block/profile_settings/course_notification_setting";
exports.examNotificationEndPoint = "/account_block/profile_settings/exam_notification_setting";
exports.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NTQxLCJleHAiOjE2OTU4ODQ3NzcsInRva2VuX3R5cGUiOiJsb2dpbiJ9.u03L9NUfFyB2Z4ZevELteNHiyRdOzn8P107E3L49I8WrHaRjV3Of4YZw7F5BwiJPIK9iuXMu44AwH4cJXV0H4g"
exports.labelCurious = "Curious"
exports.btnExampleTitle = "CLICK ME";
exports.profileDetailEndPoint = "/account_block/profile_settings/profile"
exports.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6Mzc0LCJleHAiOjE2OTQ1ODM0MDksInRva2VuX3R5cGUiOiJsb2dpbiJ9.lXRqndp38qwSMxUVYfxIy_74e3GucylJXqpPt11LLGXrB7fumCFz8gMVeg4tgC0JTehLbGuRg4sj6dkYUPh-DQ"

exports.badgeText = "you don't have silver badge \n yet you have to answer few more question to \n earn this badge"
exports.leaderBoardEndPoint = "/account_block/profile_settings/leaderboard"
exports.btnText = "Go to Leaderboard"
exports.leaderBoardLabel = "Check leaderboard for more details about badge"
exports.noDatafound = "No Data Found"
exports.answerLa = "Answer"
exports.asklabel = "Ask"
exports.colors = ['#e6b450', '#d3d3d3', '#d38843']
exports.answerEndPoint = "/bx_block_communityforum/ayq_answers/user_ayq_answer"
exports.answer = "Answers"
exports.mybadegEndPoint = "/account_block/profile_settings/my_badges"
exports.questionAskedLabel = "Questions  asked";
exports.answerLabel = "Answered";
exports.badgeslabel = "Badges";
exports.goBacklabel = "Go Back to E - Learn"
exports.deactiveInnerHeader = "You are account will be temporarily closed and you \n will not recieve notifications unless you login"
exports.deactiveHeaderLabel = "Are you sure to Deactivate account?"
exports.deactiveLabel = "Deactivate"
exports.logOutLabel = "Logout"
exports.cancel = "cancel"
exports.MainLabel = "Are you sure to Logout"
exports.logoutLabel = "You will be loggedout from the application you can \n login back again"
exports.putMethod = "PUT"
exports.editProfil = "Edit Profile Details"
exports.aboutUS = "About Us"
exports.contactUs = "Contact Us"
exports.Registration = "Registration"
exports.TandC = "Terms and conditions"
exports.leaderBoard = "Leaderboard"
exports.courseNotif = "Course Notifications"
exports.examNotif = "Exam Notifications"
exports.mypack = "My Packages"
exports.myRank = "MyRank Blogs"
exports.DeactiveAcc = "Deactivate Account"
exports.logOut = "Log out"
exports.medalLabel = "Curious"
exports.label = "My Profile"
exports.nameLabel = "Profile Name"
exports.contentType = "multipart/form-data"
exports.patch = "PATCH"
exports.updateProfileEndPoint = "/account_block/profile_settings/edit_profile"
exports.myRankID = "My Rank ID"
exports.emailID = "Email Id"
exports.phoneNumber = "Phone Number"
exports.firstName = "First Name"
exports.lastName = 'Last Name'
exports.Save = "Save"
exports.editProfileLabel = "Edit Profile"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";
exports.profileDetailsEndPoint = "/account_block/profile_settings/profile"
exports.btnExampleTitle = "CLICK ME";
exports.courseNotificationEndPoint = "/account_block/profile_settings/course_notification_setting";
exports.examNotificationEndPoint = "/account_block/profile_settings/exam_notification_setting";
exports.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NzU0LCJleHAiOjE3MDAyMjA0ODQsInRva2VuX3R5cGUiOiJsb2dpbiJ9.2mUwSId0-6w9nv6BlDj-_FS6iIKglYbgfKjsvWV1y4Tg9uJXm6UYqXDYztkusjdnckPbfXB4hR8N9XHW7KQC5w"
exports.labelCurious = "Curious"
exports.btnExampleTitle = "CLICK ME";
exports.profileDetailEndPoint = "/account_block/profile_settings/profile"
// Customizable Area End