import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  examLength: number;
  coursesLength: number;
  collegesLength: number;
  counsellingLength: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseFinderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCoursesLengthMessageId = "";
  getCollegesLengthMessageId = "";
  getCounsellingLengthMessageId = "";
  getExamsLengthMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      collegesLength: 0,
      counsellingLength: 0,
      coursesLength: 0,
      examLength: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (response && apiRequestCallId === this.getCollegesLengthMessageId) {
      this.stateChange(response, "collegesLength");
    }

    if (response && apiRequestCallId === this.getCounsellingLengthMessageId) {
      this.stateChange(response, "counsellingLength");
    }

    if (response && apiRequestCallId === this.getCoursesLengthMessageId) {
      this.stateChange(response, "coursesLength");
    }

    if (response && apiRequestCallId === this.getExamsLengthMessageId) {
      this.stateChange(response, "examLength");
    }
    // Customizable Area End
  }

  // web events
  stateChange = (response: { data: Array<unknown> }, stateKey: string) => {
    if (response.data) {
      this.setState({
        ...this.state,
        [stateKey]: response.data.length,
      });
    }
  };

  componentDidMount = async () => {
    this.getCoursesLength();
    this.getCollegesLength();
    this.getCounsellingLength();
    this.getExamsLength();
  };

  navigateTo = (navigationName: string) => {
    const navigateToScreen = new Message(
      getName(MessageEnum.NavigationCourseFinderMessage)
    );
    navigateToScreen.addData(
      getName(MessageEnum.CourseFinderDataMessage),
      navigationName
    );
    navigateToScreen.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateToScreen);
  };

  // Customizable Area Start
  getCoursesLength = async () => {
    const getCoursesLengthMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getCoursesLengthMessageId = getCoursesLengthMessage.messageId;

    getCoursesLengthMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getCoursesLengthMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allCourses
    );

    getCoursesLengthMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getCoursesLengthMessage.id, getCoursesLengthMessage);
  };

  getCollegesLength = async () => {
    const getCollegesLengthMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getCollegesLengthMessageId = getCollegesLengthMessage.messageId;

    getCollegesLengthMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getCollegesLengthMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allColleges
    );

    getCollegesLengthMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getCollegesLengthMessage.id,
      getCollegesLengthMessage
    );
  };

  getCounsellingLength = async () => {
    const getCounsellingLengthMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getCounsellingLengthMessageId = getCounsellingLengthMessage.messageId;

    getCounsellingLengthMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getCounsellingLengthMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allCounselings
    );

    getCounsellingLengthMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getCounsellingLengthMessage.id,
      getCounsellingLengthMessage
    );
  };

  getExamsLength = async () => {
    const getExamsLengthMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getExamsLengthMessageId = getExamsLengthMessage.messageId;

    getExamsLengthMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getExamsLengthMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allExams
    );

    getExamsLengthMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getExamsLengthMessage.id, getExamsLengthMessage);
  };
  // Customizable Area End
}
