import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { WithStyles } from "@material-ui/core";
import { webStyleSynopsisChapterTopic } from "./SynopsisGuestCheck.web";

export interface ChapterTopic {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  remarks: string;
  remark_2: string;
  date_synopsis: any;
  question_status: string;
  search_key: any;
  synopsis: string;
  topic_read: boolean;
  saved_notes_count: number;
  is_bookmarked: boolean;
}

export interface Meta {
  chapter: MetaChapter;
}

export interface MetaChapter {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  practice_test: string;
  avail_pt: string;
  date_pt: any;
  class_zone: string;
  avail_cz: string;
  date_cz: any;
  question_bank: string;
  avail_qb: string;
  date_qb: any;
  created_by: string;
  remarks: string;
  topic_db: string;
  qb_id: any;
  qb_db: string;
  id_pt: string;
  id_cz: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleSynopsisChapterTopic> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  allChapterTopic: ChapterTopic[];
  meta: Meta | null;
  currentActiveIndex: number;
  isModalOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SynopsisGuestCheckController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSynopsisChapterTopicMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      allChapterTopic: [],
      meta: null,
      currentActiveIndex: 0,
      isModalOpen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSynopsisChapterTopicMessageId) {
      if (response.data) {
        this.handleSuccessFetch(response);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const chapterId = this.props.navigation.getParam("chapterId");
    this.getSynopsisChapterTopic(chapterId);
  };

  handleSuccessFetch = (allChapterTopic: {
    data: ChapterTopic[];
    meta: Meta;
  }) => {
    this.setState({
      allChapterTopic: allChapterTopic.data,
      meta: allChapterTopic.meta,
    });
  };

  setActiveIndex = (index: number) => {
    this.setState({
      currentActiveIndex: index,
    });
  };

  handleModalOpen = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  // Customizable Area Start
  getSynopsisChapterTopic = async (chapterId: string) => {
    const getSynopsisChapterTopicMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      uuid: await storage.get("guestToken"),
    };
    const packageId = await storage.get("guestPackageId");
    getSynopsisChapterTopicMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/guestsynopsis/${chapterId}/chapter_topic?pack_final_id=${packageId}`
    );
    this.getSynopsisChapterTopicMessageId =
      getSynopsisChapterTopicMessage.messageId;
    getSynopsisChapterTopicMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSynopsisChapterTopicMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getSynopsisChapterTopicMessage.id,
      getSynopsisChapterTopicMessage
    );
  };
  // Customizable Area End
}
