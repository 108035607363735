import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
export type IApplicationOpened = {
  id: string;
  type: string;
  attributes: {
    id: number;
    univ_name: string;
    established: number;
    state: string;
    address: string;
    univ_type: string;
    status: string;
    website: string;
    link_myrank: string;
    wikipedia: string;
    contact1: string;
    contact2: string;
    email: string;
    description: string;
    rating: string | number | null;
    remarks: string;
    notification: Array<{
      id: number;
      notice_head: string;
      notice_desc: string;
      notice_cat: Array<string>;
      notice_final: string;
      meta_disc: string;
      meta_key: string;
      application_mode: string;
      application_cost: string;
      mode_of_payment: string;
      created_at: string;
      updated_at: string;
      notification_date: string;
      cdf_univ_id: number;
      number_of_phases: number;
      phase_number: number;
    }>;
  };
};

import { WithStyles } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  latestApplications: IApplicationOpened[];
  allApplications: IApplicationOpened[];
  currentPage: number;
  loading: boolean;
  currentPageApplication: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApplicationNewOpeningController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLatestApplicationsMessageId = "";
  getAllApplicationsMessageId = "";
  postSavedForLaterMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      latestApplications: [],
      allApplications: [],
      currentPage: 0,
      loading: false,
      currentPageApplication: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getLatestApplicationsMessageId && response) {
      if (response?.data) {
        this.handleSuccessfulLatestApplicationFetch(response.data);
      }
    }

    if (apiRequestCallId === this.getAllApplicationsMessageId && response) {
      if (response?.data) {
        this.handleSuccessfulAllApplicationFetch(response.data);
      }
    }

    if (apiRequestCallId === this.postSavedForLaterMessageId && response) {
      if (response?.message) {
        this.navigateToSaved();
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getLatestApplications();
    this.getAllApplications();
  };

  handleSuccessfulAllApplicationFetch = (data: IApplicationOpened[]) => {
    this.setState({
      loading: false,
      allApplications: data,
    });
  };

  handleSuccessfulLatestApplicationFetch = (data: IApplicationOpened[]) => {
    this.setState({
      loading: false,
      latestApplications: data,
    });
  };

  selectCurrentPage = (pageIndex: number) => {
    this.setState({
      currentPage: pageIndex,
      currentPageApplication: 1,
    });
  };

  handlePageChangeApplication = (value: number) => {
    this.setState({
      currentPageApplication: value,
    });
  };

  // Customizable Area Start
  getLatestApplications = async () => {
    this.setState({
      loading: true,
    });

    const getLatestApplicationsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };

    getLatestApplicationsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tutorials2/guidance_applications/latest_applications_opened`
    );
    this.getLatestApplicationsMessageId =
      getLatestApplicationsMessage.messageId;
    getLatestApplicationsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getLatestApplicationsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getLatestApplicationsMessage.id,
      getLatestApplicationsMessage
    );
  };

  getAllApplications = async () => {
    this.setState({
      loading: true,
    });

    const getAllApplicationsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };

    getAllApplicationsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tutorials2/guidance_applications/applications_opened`
    );

    this.getAllApplicationsMessageId = getAllApplicationsMessage.messageId;

    getAllApplicationsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllApplicationsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getAllApplicationsMessage.id,
      getAllApplicationsMessage
    );
  };

  postSavedForLater = async (univId: string) => {
    this.setState({
      loading: true,
    });

    const postSavedForLaterMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };

    postSavedForLaterMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tutorials2/guidance_applications/marked?university_id=${univId}&marked=save_for_later`
    );

    this.postSavedForLaterMessageId = postSavedForLaterMessage.messageId;

    postSavedForLaterMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    postSavedForLaterMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(
      postSavedForLaterMessage.id,
      postSavedForLaterMessage
    );
  };

  navigateToSaved = () => {
    const globalNavigation = new Message(
      getName(MessageEnum.NavigationGlobalMessage)
    );
    globalNavigation.addData(
      getName(MessageEnum.GlobalDataMessage),
      "ApplicationSaved"
    );
    globalNavigation.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    this.send(globalNavigation);
  };
  // Customizable Area End
}
