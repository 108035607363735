import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { webStyleAYQSimilarQuestion } from "./AYQSimilarQuestion.web";
import storage from "../../../framework/src/StorageProvider.web";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";

export type QuestionsAskedType = {
  id: string;
  type: string;
  attributes: {
    account_id: number;
    subject_name: string;
    topic_name: string;
    question: string;
    like_count: number;
    dislike_count: number;
    view: number;
    score: number;
    created_at: string;
    updated_at: string;
    tag_list: Array<string>;
    user_profile: {
      data: {
        id: string;
        type: string;
        attributes: {
          name: string;
          first_name: string;
          last_name: string;
          phone_number: number;
          email: string;
          city: string;
          status: string;
          myrank_id: string;
          image: string;
          uuid: string;
          course_notification_enable: boolean;
          exam_notification_enable: boolean;
        };
      };
    };
    answer_count: number;
    current_user_liked: number;
  };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleAYQSimilarQuestion> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  questionsAsked: QuestionsAskedType[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AYQSimilarQuestionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  viewAyqQuestionMessageId = "";
  getAyqSearchMessageId = "";
  breadcrumb = [
    {
      label: "Ask a Question",
      link: "/dashboard/ask-your-query/ask-a-question",
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Related Questions",
    },
  ];
  questionIdView = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      questionsAsked: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.viewAyqQuestionMessageId) {
      this.handleSuccessfulView(this.questionIdView);
    }

    if (apiRequestCallId === this.getAyqSearchMessageId) {
      if (response.data) {
        this.successfulFetch(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const searchParam = this.props.navigation.getParam("search");
    this.searchData(searchParam);
    const propPassingASQC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingASQC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingASQC);
  };

  goToProfile = (dataId: string) => {
    this.props.navigation.navigate("AskYourQueryProfilePeek", {
      profileId: dataId,
    });
  };
  navigateToAskQuestion = () => {
    this.props.navigation.navigate("AskYourQueryQuestionToAsk");
  };

  handleSuccessfulView = (questionId: string) => {
    this.props.navigation.navigate("AskYourQueryQuestionAnswer", {
      questionId,
    });
  };

  successfulFetch = (data: QuestionsAskedType[]) => {
    this.setState({
      questionsAsked: data,
    });
  };
  getTimeDifference2 = (timestampStr: string): string => {
    const timestamp2 = new Date(timestampStr);
    const relativeTime2 = formatDistanceToNow(timestamp2, { addSuffix: true });
    return relativeTime2;
  };
  // Customizable Area Start
  viewAyqQuestion = async (questionId: string) => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const viewQuestionsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewAyqQuestionMessageId = viewQuestionsMessage.messageId;
    this.questionIdView = questionId;

    viewQuestionsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    viewQuestionsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    viewQuestionsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/${questionId}/view`
    );

    runEngine.sendMessage(viewQuestionsMessage.id, viewQuestionsMessage);
  };
  searchData = async (search: string) => {
    const headers = {
      token: await storage.get("authToken"),
    };

    const getAyqSearchMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAyqSearchMessageId = getAyqSearchMessage.messageId;

    getAyqSearchMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    getAyqSearchMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAyqSearchMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/search?search_text=${search}`
    );

    runEngine.sendMessage(getAyqSearchMessage.id, getAyqSearchMessage);
  };
  // Customizable Area End
}
