import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CalendarComponent from "../../../components/src/Calendar.web";
import BannerWeb from "../../../components/src/Banner.web";
import Loader from "../../../components/src/Loader.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

import BookMySlotFacultySlotAvailabilityController, {
  Props,
  configJSON,
} from "./BookMySlotFacultySlotAvailabilityController.web";

class BookMySlotFacultySlotAvailability extends BookMySlotFacultySlotAvailabilityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box
        width={"100%"}
        alignItems={"center"}
        minHeight={"100vh"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Loader loading={this.state.loading} />
        <BannerWeb title="Faculty Bookings" />
        <Box width={"70%"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            margin={"2em"}
          >
            <Box marginTop="2em">
              <CalendarComponent
                onChangeDate={(value) => this.changeDateChange(value)}
              />
            </Box>
          </Box>
          <Box>
            <Typography className={this.props.classes.slotsTypo}>
              Slots
            </Typography>
            <Typography className={this.props.classes.subjectTypoFaculty}>
              Choose Subject:
            </Typography>
            <Box position={"relative"}>
              <select
                onChange={(event) =>
                  this.onChangeSelectSubject(event.target.value)
                }
                data-test-id="select-btn"
                value={this.state.subjectName}
                className={this.props.classes.selectStyle}
              >
                <option value="" disabled hidden>
                  No subject selected
                </option>
                <option value=""> </option>
                {this.state.subjectDealing.map((subject) => {
                  return (
                    <option value={subject} key={`subject-${subject}`}>
                      {subject}
                    </option>
                  );
                })}
              </select>
              <ExpandMoreIcon className={this.props.classes.expandMoreIcon} />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gridGap={"1.6em"}
              minHeight={"500px"}
              margin={"3em 0"}
            >
              {this.state.slotsArrayCurrentData.map((slot) => {
                return (
                  <Box className={this.props.classes.buttonsAvailability}>
                    <Typography className={this.getStyleColorSlotTime(slot.id)}>
                      {slot.attributes.slot_start} - {slot.attributes.slot_end}
                    </Typography>
                    <Box display={"flex"} gridGap={"1em"}>
                      <Button
                        data-test-id="available-btn"
                        onClick={() =>
                          this.postAvailabilityUpdate(slot.id, "available")
                        }
                      >
                        {configJSON.markAvailable}
                      </Button>
                      <Button
                        data-test-id="not-available-btn"
                        onClick={() =>
                          this.postAvailabilityUpdate(slot.id, "notavailable")
                        }
                      >
                        Mark as unavailable
                      </Button>
                    </Box>
                  </Box>
                );
              })}

              {this.state.slotsArrayCurrentData.length === 0 &&
                this.state.subjectName && (
                  <Typography className={this.props.classes.subjectTypoFaculty}>
                    No Slots Available
                  </Typography>
                )}
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleBookMySlotFacultySlotAvailability = () =>
  createStyles({
    buttonsAvailability: {
      display: "flex",
      gap: "2em",
      "& p": {
        padding: "0.3em 1em",
        borderRadius: "0.3em",
        width: "130px",
        textAlign: "center",
      },
      "& .available": {
        color: "#808080",
        background: "#99FF94",
      },
      "& .notavailable": { color: "white", background: "#E91B1B" },
      "& .nonexisting": { color: "white", background: "#ccc" },
      "& button": {
        width: "400px",
        padding: "0.5em 1em",
        background: "rgb(245,205,205)",
      },
      "& button:hover": {
        background: "rgb(255,215,215)",
      },
    },
    slotsTypo: {
      fontWeight: 700,
      fontSize: "1.5em",
      marginBottom: "2em",
    },
    selectStyle: {
      fontSize: "1.4em",
      width: "100%",
      outline: 0,
      fontWeight: 500,
      height: "70px",
      color: "#5C0F89",
      background: "rgb(250,231,231)",
      marginTop: "1.3em",
      paddingLeft: "1.5em",
      WebkitAppearance: "none",
      border: "none",
    },
    expandMoreIcon: {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      borderRadius: "50%",
      top: "47px",
      color: "white",
      right: "30px",
      cursor: "pointer",
      position: "absolute",
    },
    subjectTypoFaculty: {
      fontWeight: 500,
      fontSize: "1.4em",
    },
  });
export default withStyles(webStyleBookMySlotFacultySlotAvailability)(
  BookMySlotFacultySlotAvailability
);
export { BookMySlotFacultySlotAvailability };
// Customizable Area End
