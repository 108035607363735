import React from "react";

import {
  Box as MUIBox,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button as ButtonMUI,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SearchIcon from "@material-ui/icons/Search";
import { setting } from "./assets";
import Loader from "../../../components/src/Loader.web";
import Button from "../../../components/src/Button.web";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MathJaxBox from "../../../components/src/MathJaxBox.web";
// Customizable Area End

import AYQQuestionController, { Props } from "./AYQQuestionController.web";

class AYQQuestion extends AYQQuestionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  navigationTitles = ["MyQueries", "Top", "Hot", "Week", "Month"];

  renderNavigation = (title: string, index: number) => {
    return (
      <MUIBox
        component={"li"}
        onClick={() => this.setActiveLink(index, title.toLowerCase())}
        data-test-id="active-btn"
        key={`${index}-navigation-rendered`}
      >
        <ButtonMUI
          className={this.state.currentIndex === index ? "activeLink" : ""}
        >
          {title}
        </ButtonMUI>
      </MUIBox>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MUIBox>
        <Loader loading={this.state.loading} />
        <Modal
          open={this.state.modalCurrentFilter}
          onClose={this.handleModalFilterCurrent}
        >
          <MUIBox className={this.props.classes.modalStyleFilter}>
            <Typography variant={"h6"} style={{ fontWeight: 700 }}>
              Filter By Tag
            </Typography>
            <MUIBox
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              gridGap={"1.5em"}
            >
              <input
                name="tag"
                data-test-id="filter-tag"
                value={this.state.filter}
                onChange={(event) => this.handleFilter(event.target.value)}
                placeholder="Tag"
              />
            </MUIBox>
            <MUIBox alignSelf={"center"}>
              <ButtonMUI
                data-test-id="close-filter"
                onClick={this.handleModalFilterCurrent}
              >
                Cancel
              </ButtonMUI>
              <ButtonMUI
                data-test-id="filter-init"
                onClick={() => this.filterQuestion()}
              >
                Done
              </ButtonMUI>
            </MUIBox>
          </MUIBox>
        </Modal>
        <MUIBox className={this.props.classes.bannerStyle}>
          <Typography variant="h5">Ask Your Query</Typography>
        </MUIBox>
        <MUIBox className={this.props.classes.ayqContainer}>
          <MUIBox className={this.props.classes.navigationStyle}>
            <MUIBox component={"ul"}>
              {this.navigationTitles
                .filter((title) => {
                  if (title === "MyQueries" && !this.state.token) {
                    return false;
                  }
                  return true;
                })
                .map((title, index) => {
                  return this.renderNavigation(title, index);
                })}
            </MUIBox>
            <ButtonMUI
              data-test-id="ask-question-btn"
              onClick={() =>
                this.state.token
                  ? this.navigateToAskQuestion()
                  : this.handleAyqLoginModal()
              }
              className="ask-question-btn"
            >
              Ask Question
            </ButtonMUI>
          </MUIBox>
          <MUIBox className={this.props.classes.searchStyle}>
            <MUIBox position="relative" width={"100%"}>
              <SearchIcon
                style={{
                  position: "absolute",
                  left: "15px",
                  top: "13px",
                  color: "#510081",
                }}
              />
              <input
                placeholder="Search"
                value={this.state.search}
                data-test-id="input-search"
                onChange={(event) => this.handleSearch(event.target.value)}
              />
            </MUIBox>
            <img
              src={setting}
              style={{ width: "30px", cursor: "pointer", margin: "0 1em" }}
              data-test-id="filter"
              onClick={this.handleModalFilterCurrent}
            />
          </MUIBox>
          <MUIBox
            margin="3em 0"
            display="flex"
            flexDirection={"column"}
            gridGap="1.5em"
          >
            {this.state.ayqQuestions.length > 0 ? (
              this.state.ayqQuestions.map((question, index) => {
                return (
                  <MUIBox
                    className={this.props.classes.questionBoxStyle}
                    position={"relative"}
                    key={`${question.attributes.account_id}-${index}`}
                  >
                    <MUIBox
                      position="absolute"
                      left="20px"
                      top="20px"
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <ArrowDropUpIcon
                        data-test-id="like-btn-q"
                        onClick={() => this.likeDislike(1, index)}
                        style={{
                          color:
                            question.attributes.current_user_liked === 1
                              ? "green"
                              : "#1f1f1f",
                          cursor: "pointer",
                        }}
                      />
                      <Typography variant="body1">
                        {question.attributes.like_count -
                          question.attributes.dislike_count}
                      </Typography>
                      <ArrowDropDownIcon
                        data-test-id="dislike-btn-q"
                        onClick={() => this.likeDislike(-1, index)}
                        style={{
                          color:
                            question.attributes.current_user_liked === -1
                              ? "red"
                              : "#1f1f1f",
                          cursor: "pointer",
                        }}
                      />
                    </MUIBox>
                    <MathJaxBox content={question.attributes.question} />
                    <MUIBox className="main-wrapper">
                      <MUIBox component={"ul"}>
                        {question.attributes.tag_list.map((tagList) => {
                          return (
                            <MUIBox component={"li"} key={tagList}>
                              {tagList
                                .slice(0, 1)
                                .toUpperCase()
                                .concat(tagList.slice(1))}
                            </MUIBox>
                          );
                        })}
                      </MUIBox>
                      <MUIBox
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        border={"1px solid rgb(254,110,82)"}
                        padding="0.5em"
                        width={"max-content"}
                        marginTop="0.6em"
                        borderRadius={"0.2em"}
                        style={{ cursor: "pointer" }}
                        onClick={() => this.viewAyqQuestion(question.id)}
                        data-test-id="question-view-btn"
                      >
                        <CheckCircleIcon className="check-circle" />
                        <span>{question.attributes.answer_count} Answers</span>
                      </MUIBox>
                      <MUIBox
                        position={"absolute"}
                        right="25px"
                        bottom="20px"
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        style={{ cursor: "pointer" }}
                        data-test-id="profile-btn"
                        onClick={() =>
                          this.goToProfile(
                            question.attributes.user_profile?.data.id
                          )
                        }
                      >
                        <img
                          src={
                            question.attributes.user_profile?.data.attributes
                              .image
                          }
                          alt="picture"
                          width={"25px"}
                          height={"25px"}
                        />
                        <span className="profile-span">
                          {
                            question.attributes.user_profile?.data.attributes
                              .name
                          }
                          <span style={{ marginLeft: "0.4em" }}>
                            {this.getTimeDifference(
                              question.attributes.created_at
                            )}
                          </span>
                        </span>
                      </MUIBox>
                    </MUIBox>
                  </MUIBox>
                );
              })
            ) : (
              <MUIBox>
                <Typography>There are no question found.</Typography>
              </MUIBox>
            )}
          </MUIBox>
          <Modal
            open={this.state.isNotLoggedIn}
            onClose={this.handleAyqLoginModal}
          >
            <MUIBox className={this.props.classes.modalStyle}>
              <HighlightOffIcon
                data-test-id="close-modal"
                onClick={this.handleAyqLoginModal}
                style={{
                  fontSize: "2rem",
                  cursor: "pointer",
                  color: "gray",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
              />
              <Typography variant="h6">
                Login / Signup with AYQ to ask a question
              </Typography>
              <MUIBox
                data-test-id="to-login-btn"
                onClick={this.navigateToLogin}
              >
                <Button
                  key={"buttonKey"}
                  color="white"
                  height="50px"
                  width="200px"
                  title="Login"
                />
              </MUIBox>
            </MUIBox>
          </Modal>
        </MUIBox>
      </MUIBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleAYQQuestion = () =>
  createStyles({
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "#510081",
      display: "flex",
      background: "rgb(247,242,249)",
      height: "71px",
      "& h5": {
        fontWeight: 700,
      },
    },
    navigationStyle: {
      display: "flex",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "2em",
      "& ul": {
        display: "flex",
        background: "rgb(240,240,240)",
        gridGap: "1em",
        "& li button": {
          padding: "1em",
        },
        "& .activeLink": {
          color: "#5C1580",
          fontWeight: "700",
        },
      },
      "& .ask-question-btn": {
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        color: "white",
        padding: "0.5em 2em",
        position: "absolute",
        right: "0",
        borderRadius: "0.7em",
      },
    },
    ayqContainer: {
      padding: "0 5em",
    },
    searchStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginTop: "2em",
      "& input": {
        border: "1px solid #510081",
        borderRadius: "2em",
        color: "#510081",
        outline: "none",
        width: "100%",
        height: "50px",
        padding: "0.5em 0.5em 0.5em 3em",
        "&::placeholder": {
          color: "#510081",
        },
      },
    },
    questionBoxStyle: {
      display: "flex",
      flexDirection: "column",
      padding: "1em 0.3em 3em 4em",
      border: "none",
      minHeight: "200px",
      background: "rgb(240,240,240)",
      height: "max-content",
      "& .main-wrapper": {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        "& .profile-span": {
          fontSize: "1.2em",
          fontWeight: "500",
          "& span": {
            fontSize: "0.8em",
            color: "gray",
          },
        },
        "& img": {
          marginRight: "0.2em",
          borderRadius: "50%",
        },
        "& p": {
          fontSize: "1.35em",
        },
        "& .check-circle": {
          color: "rgb(4,147,69)",
          fontSize: "1.2em",
          marginRight: "0.6em",
        },
        "& ul": {
          display: "flex",
          flexWrap: "wrap",
          margin: "1em 0",
          gap: "1em",
          "& li": {
            border: "none",
            borderRadius: "0.5em",
            background: "rgb(233,225,240)",
            padding: "0.3em 1em",
            fontWeight: "500",
          },
        },
      },
    },
    modalStyle: {
      flexDirection: "column",
      justifyContent: "center",
      borderRadius: "0.5em",
      display: "flex",
      top: "50%",
      transform: "translate(-50%, -50%)",
      alignItems: "center",
      gap: "0.5em",
      backgroundColor: "white",
      left: "50%",
      position: "absolute" as "absolute",
      height: "210px",
      border: "none",
      width: "calc(max-content + 100px)",
      padding: "2em",
      "& h6": {
        marginBottom: "1em",
      },
    },
    modalStyleFilter: {
      left: "50%",
      border: "2px solid #832890",
      backgroundColor: "white",
      width: 500,
      height: "max-content",
      display: "flex",
      position: "absolute" as "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "0.5em",
      justifyContent: "space-between",
      padding: "2em",
      gap: "1em",
      flexDirection: "column",
      outline: "none",
      textAlign: "left",
      "& input, & label": {
        width: "100%",
        padding: "1em",
        color: "#510081",
        outline: "none" as const,
        border: "1px solid #FF6B55",
        borderRadius: "0.5em",
        "&::placeholder": {
          color: "#510081",
        },
      },
      "& label": {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-start",
      },
      "& label input": {
        width: "20px",
        height: "20px",
        borderRadius: "50%",
      },
      "& label p": {
        fontWeight: 500,
        fontSize: "1.2em",
      },
      "& button": {
        borderRadius: "0.6em",
        border: "1px solid #BE888E",
        textTransform: "none",
        "&:hover": {
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          color: "white",
        },
        color: "#4F1f6d",
        height: "45px",
        minWidth: "130px",
        width: "max-content",
        fontWeight: 700,
        margin: "0 1em",
      },
    },
  });
export default withStyles(webStyleAYQQuestion)(AYQQuestion);
export { AYQQuestion };
// Customizable Area End
