import React from "react";

import {
  Box,
  // Customizable Area Start
  Typography,
  Button,
  withStyles,
  createStyles,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { submitIcon } from "./assets";
import BannerWeb from "../../../components/src/Banner.web";
// Customizable Area End

import SynopsisGuestController, {
  AllSubjects,
  Props,
} from "./SynopsisGuestController.web";

class SynopsisGuest extends SynopsisGuestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  subjectRender = (subject: AllSubjects, index: number) => {
    return (
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        className={this.props.classes.subjectBoxStyle}
        style={{ opacity: index > 0 ? "0.7" : "1" }}
        key={subject.attributes.name}
      >
        <img src={subject.attributes.image} alt={subject.attributes.name} />
        <Typography variant="h6">{subject.attributes.name}</Typography>
        <Button
          data-test-id="learn-more-btn"
          onClick={
            index > 0
              ? () => this.handleModalOpen()
              : () => this.navigateTo(subject.id)
          }
        >
          Learn More <Box className={this.props.classes.backIcon}>{">"}</Box>
        </Button>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Modal
          open={this.state.isModalOpen}
          BackdropProps={{ onClick: undefined }}
          onClose={this.handleModalOpen}
        >
          <Box className={this.props.classes.modalStyle}>
            <img src={submitIcon} alt="getstarted" />
            <h5>
              To get access to all subjects and more content you need to
              register
            </h5>
            <Box display={"flex"} gridGap={"1em"}>
              <Button
                data-test-id="navigate-btn"
                className={this.props.classes.buttonStyleYes}
                onClick={() => this.props.navigation.navigate("Register")}
              >
                Get Started
              </Button>
              <Button
                data-test-id="cancel-btn"
                className={this.props.classes.buttonStyleNo}
                onClick={this.handleModalOpen}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
        <BannerWeb title="Synopsis" />
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          minHeight={"90vh"}
          gridGap="2.5em"
        >
          {this.state.allSubjects.map((subject, index) => {
            return this.subjectRender(subject, index);
          })}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleSynopsis = () =>
  createStyles({
    backIcon: {
      borderRadius: "50%",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      marginRight: "0.3em",
      width: "24px",
      height: "24px",
      textAlign: "center",
      color: "white",
      marginLeft: "0.5em",
    },
    buttonStyleNo: {
      width: "130px",
      height: "50px",
      border: "1px solid #ffe9ed",
      color: "#4F1f6d",
      background: "white",
      borderRadius: "0.6em",
      textTransform: "none" as const,
    },
    subjectBoxStyle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      border: "2px solid #BA7080",
      width: "340px",
      height: "340px",
      borderRadius: "10px",
      "& img": {
        width: "140px",
        height: "140px",
        borderRadius: "50%",
      },
      "& h6": {
        margin: "1em 0",
      },
      "& button": {
        textAlign: "center",
        border: "1px solid #BA7080",
        padding: "1em 2em",
        color: "#3B087C",
        borderRadius: "10px",
      },
    },
    buttonStyleYes: {
      width: "130px",
      height: "50px",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      textTransform: "none" as const,
      marginLeft: "1em",
      color: "white",
    },
    modalStyle: {
      justifyContent: "center",
      display: "flex",
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      flexDirection: "column",
      alignItems: "center",
      transform: "translate(-50%, -50%)",
      width: "35%",
      background: "white",
      borderRadius: "1.5em",
      border: "2px solid #FFD0D8",
      padding: "2em",
      minHeight: "max-content",
      gap: "1.5em",
    },
  });
export default withStyles(webStyleSynopsis)(SynopsisGuest);
export { SynopsisGuest };
// Customizable Area End
