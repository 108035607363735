import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import React, { ChangeEvent, DragEvent } from "react";

export interface IUserDocument {
  id: string;
  type: string;
  attributes: {
    id: number;
    document: {
      id: number;
      document_name: string;
      description: string;
      maxfile_size: string;
      year: string;
      status: string;
      remarks: string;
      created_by: string;
      created_at: string;
      updated_at: string;
      pack_pricing_ids: Array<any>;
    };
    file_link: string;
    file_type: string;
    file_size: number;
    status: string;
    remarks: string;
    created_by: string;
    user_myrank_id: string;
    approved_by: string;
    doc_status: number;
    verified_date: string;
    created_at: string;
    updated_at: string;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeFilterIndex: number;
  loading: boolean;
  userDocuments: IUserDocument[];
  isDocumentSuccessUploadModal: boolean;
  isUploadingModal: boolean;
  dragActive: boolean;
  selectedFile: Blob | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DocumentsUploadController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  documentsFilterButtons = [
    {
      btnName: "Approved",
      btnValue: "approved",
    },
    {
      btnName: "Rejected",
      btnValue: "rejected",
    },
    {
      btnName: "Not yet processed",
      btnValue: "not_yet_processed",
    },
  ];
  getUserDocumentsMessageId = "";
  uploadUserDocumentMessageId = "";
  fileInputRef: React.RefObject<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeFilterIndex: 0,
      loading: false,
      userDocuments: [],
      isDocumentSuccessUploadModal: false,
      dragActive: false,
      isUploadingModal: false,
      selectedFile: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getUserDocumentsMessageId && response) {
      if (response?.data) {
        this.successUserDocumentsFetch(response.data);
      }
    }

    if (apiRequestCallId === this.uploadUserDocumentMessageId && response) {
      if (response?.data) {
        this.successUserDocumentsUpload();
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getUserDocuments(
      this.documentsFilterButtons[this.state.activeFilterIndex].btnValue
    );
  };

  // Customizable Area Start
  chooseFilterBtn = (btnValue: string) => {
    switch (btnValue) {
      case "approved":
        this.setState({
          activeFilterIndex: 0,
        });
        break;
      case "rejected":
        this.setState({
          activeFilterIndex: 1,
        });
        break;
      case "not_yet_processed":
        this.setState({
          activeFilterIndex: 2,
        });
        break;
      default:
        break;
    }

    this.getUserDocuments(btnValue);
  };

  getUserDocuments = async (documentType: string) => {
    const getUserDocumentsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getUserDocumentsMessageId = getUserDocumentsMessage.messageId;

    getUserDocumentsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getUserDocumentsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/user_documents/${documentType}`
    );

    getUserDocumentsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getUserDocumentsMessage.id, getUserDocumentsMessage);
  };
  successUserDocumentsFetch = (userDocuments: IUserDocument[]) => {
    this.setState({
      userDocuments,
      loading: false,
    });
  };

  handleDocumentSuccessModal = () => {
    // Toggles the state of the modal that shows the document upload success message.
    // If `isDocumentSuccessUploadModal` is true, it closes the modal, and vice versa.
    this.setState({
      isDocumentSuccessUploadModal: !this.state.isDocumentSuccessUploadModal,
    });
  };

  handleUploadingModal = () => {
    // Toggles the state of the modal that shows the uploading progress.
    // Also resets the selected file to null when the modal is closed.
    this.setState({
      isUploadingModal: !this.state.isUploadingModal,
      selectedFile: null,
    });
  };

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Updates the `selectedFile` state when a file is selected through the file input.
    // If no file is selected, `selectedFile` is set to null.
    const file = event.target.files ? event.target.files[0] : null;
    this.setState({ selectedFile: file });
  };

  handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    // Prevents the default behavior during a drag-over event.
    // Sets the `dragActive` state to true to indicate that an item is being dragged over the target element.
    event.preventDefault();
    this.setState({ dragActive: true });
  };

  handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    // Prevents the default behavior during a drag-leave event.
    // Sets the `dragActive` state to false to indicate that the dragged item has left the target area.
    event.preventDefault();
    this.setState({ dragActive: false });
  };

  handleDrop = (event: DragEvent<HTMLDivElement>) => {
    // Prevents the default behavior when a file is dropped.
    // Resets `dragActive` to false and updates the `selectedFile` state with the dropped file.
    event.preventDefault();
    this.setState({ dragActive: false });

    const file = event.dataTransfer.files[0];
    if (file) {
      this.setState({ selectedFile: file });
    }
  };

  uploadUserDocument = async () => {
    const uploadUserDocumentMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    let formdata = new FormData();
    formdata.append("document[attachment]", this.state.selectedFile!);
    formdata.append("document_id", "100");

    this.uploadUserDocumentMessageId = uploadUserDocumentMessage.messageId;

    uploadUserDocumentMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    uploadUserDocumentMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    uploadUserDocumentMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/user_documents`
    );

    uploadUserDocumentMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      uploadUserDocumentMessage.id,
      uploadUserDocumentMessage
    );
  };
  successUserDocumentsUpload = () => {
    this.setState({
      isDocumentSuccessUploadModal: true,
      isUploadingModal: false,
      loading: false,
    });
  };
  // Customizable Area End
}
