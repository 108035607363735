import React from "react";

import {
  Box as MUIBoxDiv,
  // Customizable Area Start
  Button as MUIButton,
  Modal,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Button from "../../../components/src/Button.web";
import { noreview, Delete, edit } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import GrandTestSavedNotesController, {
  Props,
} from "./GrandTestSavedNotesController.web";

export default class GrandTestSavedNotes extends GrandTestSavedNotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MUIBoxDiv width={"100%"}>
        <Loader loading={this.state.loading} />
        <Modal
          onClose={this.handleDeletePromptClose2}
          open={this.state.isConfirmDeletePromptOpen}
          BackdropProps={{ onClick: undefined }}
        >
          <MUIBoxDiv style={webStyle.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>Deleted Note Successfully</h3>
            <MUIBoxDiv>
              <MUIButton
                style={webStyle.buttonStyleYes}
                data-test-id="goback-btn"
                onClick={this.handleDeletePromptClose2}
              >
                Go Back
              </MUIButton>
            </MUIBoxDiv>
          </MUIBoxDiv>
        </Modal>
        <Modal
          open={this.state.isDeletePromptOpen}
          onClose={this.handleDeletePromptClose}
          BackdropProps={{ onClick: undefined }}
        >
          <MUIBoxDiv
            style={webStyle.modalStyle}
            className="submit-test-question"
          >
            <h3>Are you sure to delete this Note?</h3>
            <MUIBoxDiv>
              <MUIButton
                data-test-id="cancel-delete"
                style={webStyle.buttonStyleNo}
                onClick={this.handleDeletePromptClose}
              >
                Cancel
              </MUIButton>
              <MUIButton
                data-test-id="delete-confirm"
                onClick={() =>
                  this.deleteSavedNotes(this.noteIdToDelete, this.indexToDelete)
                }
                style={webStyle.buttonStyleYes}
              >
                Delete
              </MUIButton>
            </MUIBoxDiv>
          </MUIBoxDiv>
        </Modal>
        <MUIBoxDiv style={webStyle.bannerStyle}>
          <h2>Saved Notes</h2>
        </MUIBoxDiv>
        {this.state.notes.length === 0 ? (
          <MUIBoxDiv style={webStyle.setMiddleStyleColumn2}>
            <MUIBoxDiv style={webStyle.setMiddleStyleColumn2}>
              <img src={noreview} alt="noreview" width={"600px"} />
              <h2>You didn't take test to Review</h2>
              <p>Click on Take Test to get Review</p>
            </MUIBoxDiv>
            <Link
              to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                "packageId"
              )}/review-test/${this.props.navigation.getParam(
                "subjectId"
              )}/review/${this.props.navigation.getParam(
                "testId"
              )}/saved-notes/notes/New`}
            >
              <Button
                color="white"
                height="60px"
                title="Create Note"
                width="500px"
              />
            </Link>
          </MUIBoxDiv>
        ) : (
          <MUIBoxDiv
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
          >
            <MUIBoxDiv style={webStyle.centerVertically}>
              <Typography variant="h6">
                Exam : {this.state.breadcrumb[1].label}
              </Typography>
              <Typography variant="h6">
                Total Notes - {this.state.notes.length}
              </Typography>
            </MUIBoxDiv>
            <MUIBoxDiv style={webStyle.centerHorizontally}>
              {this.state.notes.map((note, index) => {
                return (
                  <MUIBoxDiv style={webStyle.cardBookmark}>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      Note: {note.notes_content}
                    </Typography>
                    <MUIBoxDiv style={webStyle.cardBookmark2}>
                      <p>Question No: {note.question_no}</p>
                      <MUIBoxDiv>
                        <MUIButton>
                          <Link
                            to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                              "packageId"
                            )}/review-test/${this.props.navigation.getParam(
                              "subjectId"
                            )}/review/${this.props.navigation.getParam(
                              "testId"
                            )}/saved-notes/notes/Edit/${note.question_no}/${
                              note.question_id
                            }/${note.id}`}
                          >
                            <img
                              src={edit}
                              alt="edit-png"
                              style={webStyle.imgStyle}
                            />
                          </Link>
                        </MUIButton>
                        <MUIButton
                          onClick={() =>
                            this.deletePromptOpen(String(note.id), index)
                          }
                          data-test-id="delete-btn"
                        >
                          <img
                            src={Delete}
                            alt="delete-png"
                            style={webStyle.imgStyle}
                          />
                        </MUIButton>
                      </MUIBoxDiv>
                    </MUIBoxDiv>
                  </MUIBoxDiv>
                );
              })}
            </MUIBoxDiv>
          </MUIBoxDiv>
        )}
      </MUIBoxDiv>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyleNo: {
    borderRadius: "0.6em",
    width: "130px",
    textTransform: "none" as const,
    cursor: "pointer",
    height: "50px",
    color: "#4F1f6d",
    background: "white",
    border: "1px solid #ffe9ed",
  },
  imgStyle: {
    width: "20px",
  },
  bannerStyle: {
    width: "100%",
    justifyContent: "center",
    height: "71px",
    display: "flex",
    background: "rgba(247, 214, 255, 0.19)",
    color: "rgb(82, 0, 130)",
    alignItems: "center",
  },
  setMiddleStyleColumn2: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "2em",
  },
  buttonStyleYes: {
    width: "130px",
    textTransform: "none" as const,
    height: "50px",
    color: "white",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    marginLeft: "1em",
    cursor: "pointer",
  },
  centerVertically: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "60%",
    margin: "2em",
  },
  modalStyle: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    transform: "translate(-50%, -50%)",
    width: "35%",
    minHeight: "max-content",
    alignItems: "center",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    borderRadius: "1.5em",
    padding: "2em",
    gap: "1.5em",
    backgroundColor: "white",
    border: "2px solid #FFD0D8",
  },
  centerHorizontally: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: "1em",
  },
  cardBookmark: {
    maxWidth: "60%",
    minWidth: "60%",
    border: "1px solid #FCC2C0",
    borderRadius: "10px",
    minHeight: "116.8px",
    height: "max-content",
    background: "rgba(209, 170, 255, 0.14)",
    padding: "1em",
    wordWrap: "break-word" as const,
  },
  cardBookmark2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
// Customizable Area End
