import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { WithStyles } from "@material-ui/core";
import { ValueType } from "react-select";

type ClassTimingType = {
  id: string;
  type: string;
  attributes: {
    id: number;
    content: string;
    created_at: string;
    updated_at: string;
  };
};

export interface TableUpcomingPrevious {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  subject_name: string;
  chapter_name: string;
  academic_year: string;
  class_url: string;
  recording_url: string;
  class_date: string;
  class_status: string;
  conducted_by: string;
  view_recordings_count: number;
  average_rating: number;
  created_at: string;
  updated_at: string;
  logo: string;
  search_key: any;
  notes: string;
  materials: string;
}

export interface Instructions {
  id: string;
  type: string;
  attributes: InstructionsAttributes;
}

export interface InstructionsAttributes {
  id: number;
  content: string;
  created_at: string;
  updated_at: string;
}

interface UserInput {
  currentSubject: string;
  currentDate: string;
  currentFaculty: string;
  currentAcademicYear: string;
}

type MaterialNotesType = {
  id: string;
  type: string;
  attributes: {
    id: number;
    subject_name: string;
    topic_name?: string;
    chapter_name?: string;
    academic_year: string;
    conducted_by: string;
    content: string;
    file: string;
    created_at: string;
    updated_at: string;
  };
};

type MaterialNotesData = {
  data: Array<MaterialNotesType>;
};

type OptionType = {
  subject: Array<{ value: string; label: string }>;
  faculty: Array<{ value: string; label: string }>;
  academicYear: Array<{ value: string; label: string }>;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeIndex: number;
  classTiming: ClassTimingType[];
  instructions: Instructions[];
  currentData: TableUpcomingPrevious[];
  currentTable: TableUpcomingPrevious[];
  currentDataMaterial: MaterialNotesType[];
  currentDataNotes: MaterialNotesType[];
  options: OptionType;
  currentTableMaterial: MaterialNotesType[];
  currentTableNotes: MaterialNotesType[];
  search: string;
  searchNotes: string;
  searchMaterial: string;
  modalCurrentFilter: boolean;
  modalCurrentSort: boolean;
  userInputCurrent: UserInput;
  selectedOption: string;
  currentTableType: string;
  loading: boolean;
  currentClass: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LiveClassController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getInstructionsMessageId = "";
  getPreviousMessageId = "";
  getUpcomingMessageId = "";
  getClassTimingsMessageId = "";
  getSortMessageId = "";
  getFilterMessageId = "";
  getNotesMessageId = "";
  getMaterialsMessageId = "";
  searchTimeout: NodeJS.Timeout | null = null;
  breadcrumb = [
    {
      label: "Live Classes",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      options: {
        academicYear: [],
        faculty: [],
        subject: [],
      },
      activeIndex: 0,
      loading: false,
      currentClass: "",
      classTiming: [],
      instructions: [],
      currentData: [],
      currentTable: [],
      currentDataMaterial: [],
      currentTableMaterial: [],
      currentDataNotes: [],
      currentTableNotes: [],
      search: "",
      searchMaterial: "",
      searchNotes: "",
      modalCurrentFilter: false,
      modalCurrentSort: false,
      userInputCurrent: {
        currentSubject: "",
        currentDate: "",
        currentFaculty: "",
        currentAcademicYear: "",
      },
      selectedOption: "",
      currentTableType: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.getPreviousMessageId:
      case this.getUpcomingMessageId:
        if (response && response.data) {
          this.handleUpcomingPreviousSuccess(response.data);
        }
        break;
      case this.getClassTimingsMessageId:
        this.handleClassTimingData(response);
        break;
      case this.getInstructionsMessageId:
        this.handleInstructionsData(response);
        break;
      case this.getSortMessageId:
      case this.getFilterMessageId:
        if (response && response.data) {
          this.handleSortFilterSuccess(response.data);
        }
        break;
      case this.getMaterialsMessageId:
        if (response && response.data) {
          this.handleMaterialFetchSuccess(response);
        }
        break;
      case this.getNotesMessageId:
        if (response && response.data) {
          this.handleNotesFetchSuccess(response);
        }
        break;
      default:
        break;
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events

  // Customizable Area Start
  handleInstructionsData = (response: { data: Instructions[] }) => {
    if (response && response.data) {
      this.handleInstructionsSuccess(response.data);
    }
  };

  handleClassTimingData = (response: { data: ClassTimingType[] }) => {
    if (response && response.data) {
      this.handleClassTimingSuccess(response.data);
    }
  };
  componentDidMount = async () => {
    const propPassingLC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingLC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingLC);
    this.getInstructions();
    this.getClassTimings();
    this.getMaterials();
    this.getNotes();
    this.handleActiveButton(0);
  };

  handleSortFilterSuccess = (data: TableUpcomingPrevious[]) => {
    this.setState({
      modalCurrentFilter: false,
      modalCurrentSort: false,
    });

    this.setState({
      currentTable: data,
    });
  };

  handleUserInputChangeCurrent = (
    event: ValueType<
      {
        value: string;
        label: string;
      },
      false
    >,
    type:
      | "currentSubject"
      | "currentFaculty"
      | "currentAcademicYear"
      | "currentDate"
  ) => {
    this.setState({
      userInputCurrent: {
        ...this.state.userInputCurrent,
        [type]: event?.value,
      },
    });
  };

  handleInstructionsSuccess = (response: Instructions[]) => {
    this.setState({
      instructions: response,
    });
  };

  handleClassTimingSuccess = (response: ClassTimingType[]) => {
    this.setState({
      classTiming: response,
    });
  };

  handleActiveButton = (index: number) => {
    if (index === 0) {
      this.getUpcoming();
    } else {
      this.getPrevious();
    }
    this.setState({
      activeIndex: index,
      currentClass: index === 0 ? "upcoming_classes" : "previous_classes",
    });
  };

  handleUpcomingPreviousSuccess = (data: TableUpcomingPrevious[]) => {
    const options: OptionType = {
      subject: [],
      academicYear: [],
      faculty: [],
    };

    data.forEach((response) => {
      const { subject_name, conducted_by, academic_year } = response.attributes;
      if (
        subject_name &&
        !options.subject.some((opt) => opt.value === subject_name)
      ) {
        options.subject.push({ value: subject_name, label: subject_name });
      }
      if (
        conducted_by &&
        !options.faculty.some((opt) => opt.value === conducted_by)
      ) {
        options.faculty.push({ value: conducted_by, label: conducted_by });
      }
      if (
        academic_year &&
        !options.academicYear.some((opt) => opt.value === academic_year)
      ) {
        options.academicYear.push({
          value: academic_year,
          label: academic_year,
        });
      }
    });

    this.setState({
      currentData: data,
      currentTable: data,
      options,
    });
  };

  handleMaterialFetchSuccess = (response: MaterialNotesData) => {
    this.setState({
      currentDataMaterial: response.data,
      currentTableMaterial: response.data,
    });
  };

  handleNotesFetchSuccess = (response: MaterialNotesData) => {
    this.setState({
      currentDataNotes: response.data,
      currentTableNotes: response.data,
    });
  };

  getOrdinalNumber = (day: number) => {
    if (day > 10 && day < 20) {
      return day + "th";
    } else {
      const lastDigit = day % 10;
      switch (lastDigit) {
        case 1:
          return day + "st";
        case 2:
          return day + "nd";
        case 3:
          return day + "rd";
        default:
          return day + "th";
      }
    }
  };

  handleSearch = (search: string, type: string) => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    const startSearch = () => {
      this.searchTimeout = setTimeout(() => {
        this.performSearch(search, type);
      }, 600);
    };

    if (type === "current") {
      this.setState(
        {
          search: search,
        },
        startSearch
      );
    }

    if (type === "material") {
      this.setState(
        {
          searchMaterial: search,
        },
        startSearch
      );
    }

    if (type === "notes") {
      this.setState(
        {
          searchNotes: search,
        },
        startSearch
      );
    }
  };

  performSearch = (search: string, type: string) => {
    search = search.toLowerCase();

    switch (type) {
      case "current":
        this.handleSearchLivePrevious(search);
        break;

      case "material":
        this.handleSearchMaterials(search);
        break;

      case "notes":
        this.handleSearchNotes(search);
        break;

      default:
        break;
    }
  };

  handleSearchLivePrevious = (search: string) => {
    if (search.length === 0) {
      return this.setState({
        currentTable: this.state.currentData,
      });
    }

    const searched = this.state.currentData.filter((subject) => {
      const subjectName = subject.attributes.subject_name
        ?.toLowerCase()
        .includes(search);
      const topic = subject.attributes.chapter_name
        ?.toLowerCase()
        .includes(search);
      const date = subject.attributes.academic_year
        ?.toLowerCase()
        .includes(search);
      if (subjectName || topic || date) {
        return subject;
      }
    });

    this.setState({
      currentTable: searched,
    });
  };

  handleSearchMaterials = (search: string) => {
    if (search.length === 0) {
      return this.setState({
        currentTableMaterial: this.state.currentDataMaterial,
      });
    }
    const searched = this.state.currentDataMaterial.filter((material) => {
      const subjectName = material.attributes.subject_name
        ?.toLowerCase()
        .includes(search);
      const topic = (material.attributes.topic_name as string)
        ?.toLowerCase()
        .includes(search);
      const date = material.attributes.academic_year
        ?.toLowerCase()
        .includes(search);
      if (subjectName || topic || date) {
        return material;
      }
    });

    this.setState({
      currentTableMaterial: searched,
    });
  };

  handleSearchNotes = (search: string) => {
    if (search === "") {
      return this.setState({
        currentTableNotes: this.state.currentDataNotes,
      });
    }

    const searched = this.state.currentDataNotes.filter((notes) => {
      const subjectName = notes.attributes.subject_name
        ?.toLowerCase()
        .includes(search);
      const topic = (notes.attributes.chapter_name as string)
        ?.toLowerCase()
        .includes(search);
      const date = notes.attributes.academic_year
        ?.toLowerCase()
        .includes(search);
      if (subjectName || topic || date) {
        return notes;
      }
    });

    this.setState({
      currentTableNotes: searched,
    });
  };

  handleModalFilterCurrent = () => {
    this.setState({
      modalCurrentFilter: !this.state.modalCurrentFilter,
    });
  };

  handleModalSortCurrent = () => {
    this.setState({
      modalCurrentSort: !this.state.modalCurrentSort,
    });
  };

  handleCheckboxChange = (option: string) => {
    this.setState({
      selectedOption: option,
    });
  };

  handleGoToRecordedClasses = (previousId: string) => {
    this.props.navigation.history.push(
      `/dashboard/classes/recorded-class/watch?id=${previousId}&type=live`
    );
  };

  getInstructions = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    const getInstructionsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getInstructionsMessageId = getInstructionsMessage.messageId;

    getInstructionsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/live_classes/instructions`
    );

    getInstructionsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getInstructionsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getInstructionsMessage.id, getInstructionsMessage);
  };

  getClassTimings = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getClassTimingsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClassTimingsMessageId = getClassTimingsMessage.messageId;

    getClassTimingsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/live_classes/class_timings`
    );

    getClassTimingsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getClassTimingsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getClassTimingsMessage.id, getClassTimingsMessage);
  };

  getUpcoming = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getUpcomingMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUpcomingMessageId = getUpcomingMessage.messageId;

    getUpcomingMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/live_classes/upcoming_classes`
    );

    getUpcomingMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUpcomingMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getUpcomingMessage.id, getUpcomingMessage);
  };

  getNotes = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNotesMessageId = getNotesMessage.messageId;

    getNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/live_class_notes`
    );

    getNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getNotesMessage.id, getNotesMessage);
  };

  getMaterials = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getMaterialsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMaterialsMessageId = getMaterialsMessage.messageId;

    getMaterialsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/materials`
    );

    getMaterialsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getMaterialsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getMaterialsMessage.id, getMaterialsMessage);
  };

  getPrevious = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getPreviousMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPreviousMessageId = getPreviousMessage.messageId;

    getPreviousMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/live_classes/previous_classes`
    );

    getPreviousMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getPreviousMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getPreviousMessage.id, getPreviousMessage);
  };

  sortUpcomingPrevious = (
    type: string,
    sortType: string,
    currentTableType: string
  ) => {
    this.setState(
      {
        currentTableType,
      },
      async () => {
        const headers = {
          token: await storage.get("authToken"),
        };

        const getSortMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getSortMessageId = getSortMessage.messageId;

        getSortMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_scheduling/live_classes/${type}?sort_by=${sortType}`
        );

        getSortMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );

        getSortMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );

        runEngine.sendMessage(getSortMessage.id, getSortMessage);
      }
    );
  };

  filterUpcomingPrevious = (
    type: string,
    filterType: {
      subject_name: string;
      date: string;
      faculty: string;
      academic_year: string;
    },
    currentTableType: string
  ) => {
    this.setState(
      {
        currentTableType,
      },
      async () => {
        const headers = {
          token: await storage.get("authToken"),
        };

        const getFilterMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getFilterMessageId = getFilterMessage.messageId;

        function buildQueryParamString(params: {
          subject_name: string;
          date: string;
          faculty: string;
          academic_year: string;
        }) {
          const queryParams = Object.entries(params)
            .filter(
              ([_, value]) =>
                value !== undefined && value !== null && value !== ""
            )
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

          return queryParams ? `?${queryParams}` : "";
        }

        getFilterMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_scheduling/live_classes/${type}${buildQueryParamString({
            subject_name: filterType.subject_name,
            date: filterType.date,
            faculty: filterType.faculty,
            academic_year: filterType.academic_year,
          })}`
        );

        getFilterMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );

        getFilterMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );

        runEngine.sendMessage(getFilterMessage.id, getFilterMessage);
      }
    );
  };
  // Customizable Area End
}
