import React from "react";

import {
  Box as BoxMUI,
  // Customizable Area Start
  withStyles,
  Typography,
  Button,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Loader from "../../../components/src/Loader.web";
import TestComponent from "../../../components/src/TestComponent.web";
import Stepper from "../../../components/src/Stepper.web";
import { webStyle } from "./GrandTestTaking.web";
import TimerIcon from "@material-ui/icons/Timer";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { clean } from "./assets";
// Customizable Area End

import GuestGrandTestController, {
  Props,
} from "./GuestGrandTestController.web";
import { styles } from "./styles";
import { webStylePTGuest } from "./GuestPracticeTest.web";

class GuestGrandTest extends GuestGrandTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const totalSeconds = Math.floor(this.state.timeRemaining / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = totalSeconds - hours * 3600 - minutes * 60;

    return (
      // Customizable Area Start
      <BoxMUI>
        <Loader loading={this.state.loading} />
        <BoxMUI style={webStyle.bannerStyle}>
          <h2>Grand Test</h2>
        </BoxMUI>
        <BoxMUI
          style={{ display: "flex", gap: "1em", padding: "1em 2em 0 2em" }}
        >
          <Stepper
            steps={this.state.currentSubjectTestData.length}
            activeStep={this.state.currentTestData + 1}
          />
          <Typography>
            {this.state.currentTestData + 1}/
            {this.state.currentSubjectTestData.length}
          </Typography>
        </BoxMUI>
        <BoxMUI
          style={{
            display: "flex",
            padding: "1em 2em",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Typography className={this.props.classes.practiceTestTimer}>
            <span>
              <TimerIcon />
            </span>
            {String(hours).length > 1 ? hours : `${hours}`}:
            {String(minutes).length > 1 ? minutes : `0${minutes}`}:
            {String(seconds).length > 1 ? seconds : `0${seconds}`}
          </Typography>
        </BoxMUI>
        <BoxMUI
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <BoxMUI style={{ display: "flex", gap: "1em", marginBottom: "1em" }}>
            {this.state.testSubjects.map((subject, index) => {
              return (
                <Button
                  data-test-id={`change-subject-btn`}
                  key={`${index}-gtsubject-${subject.subject_name}`}
                  onClick={() =>
                    this.handleSubjectChange(subject.subject_name, index)
                  }
                  style={{
                    padding: "1em 2em",
                    border: "none",
                    background:
                      this.state.currentSubjectTestDataIndex === index
                        ? "#FAE6FF"
                        : "#E9E9E9",
                    fontSize: "15px",
                    fontWeight: 500,
                  }}
                >
                  {subject.subject_name}
                </Button>
              );
            })}
          </BoxMUI>
          <Typography
            style={{ color: "#520082", fontWeight: 500, fontSize: "15px" }}
          >
            This question carries +
            {
              this.state.currentSubjectTestData[this.state.currentTestData]
                ?.attributes.p_mark
            }{" "}
            for Correct Answer and -
            {Number(
              this.state.currentSubjectTestData[this.state.currentTestData]
                ?.attributes.n_mark
            )}{" "}
            for wrong Answer
          </Typography>
        </BoxMUI>
        <BoxMUI className={this.props.classes.mainContainerPracticeTest}>
          {this.state.currentSubjectTestData.length > 0 && (
            <TestComponent
              question={
                this.state.currentSubjectTestData[this.state.currentTestData]
              }
              index={this.state.currentTestData}
              setAnswer={this.setAnswer}
              answers={this.state.currentAnswers}
            />
          )}
          <BoxMUI className="overview-practice-test">
            <BoxMUI className="overview-board">
              <h3>Overview</h3>
              <BoxMUI className="overview-btns">
                {this.state.currentSubjectTestData.map((question, index) => {
                  const className = this.getBtnClassName(index);
                  return (
                    <button
                      data-test-id={`specific-btn-test-id`}
                      key={`button-${index}`}
                      className={className}
                      onClick={() => this.handleSpecificQuestion(index)}
                    >
                      {question.attributes.question_no}
                    </button>
                  );
                })}
              </BoxMUI>
            </BoxMUI>
            <BoxMUI className="overview-legends">
              <Typography>
                <span>
                  <FiberManualRecordIcon style={{ color: "#9CBA58" }} />
                </span>
                Answered{" "}
                <span style={{ marginLeft: "0.8em" }}>
                  <FiberManualRecordIcon style={{ color: "#4B4B4B" }} />
                </span>
                Un-Answered
              </Typography>
              <Typography>
                <span>
                  <FiberManualRecordIcon style={{ color: "#FF8147" }} />
                </span>
                Answered & Marked for Review
              </Typography>
              <Typography>
                <span>
                  <FiberManualRecordIcon style={{ color: "#DD1000" }} />
                </span>
                Not Answered & Marked for Review
              </Typography>
              <Typography>
                <span>
                  <FiberManualRecordIcon style={{ color: "#69B5FF" }} />
                </span>
                Answered & Cleared the answer
              </Typography>
              <Typography>
                <span>
                  <FiberManualRecordIcon style={{ color: "#D426C9" }} />
                </span>
                Marked & Unmarked for review
              </Typography>
            </BoxMUI>
          </BoxMUI>
        </BoxMUI>
        <BoxMUI className={this.props.classes.btnsPracticeTest}>
          <BoxMUI className="submit-mark-practice-btns">
            <button
              data-test-id={`submitTest`}
              className="submit-test-btn"
              onClick={()=>this.submitTest()}
            >
              Submit Test
            </button>
          </BoxMUI>
          <BoxMUI className="navigation-practice-btns">
            <BoxMUI className="bookmark-clear-box">
              <BoxMUI
                className="bookmark-icon-review"
                onClick={() => this.markForReview()}
                data-test-id="markforreview"
              >
                <BookmarkIcon />
              </BoxMUI>
              <BoxMUI
                className="clear-icon"
                onClick={this.clearResponse}
                data-test-id="clrbtn"
              >
                <img src={clean} alt="clear-icon" />
              </BoxMUI>
            </BoxMUI>
            <BoxMUI>
              <button
                data-test-id={`prevbtn`}
                onClick={this.handlePreviousQuestion}
              >
                <ChevronLeftIcon />
              </button>
              <button data-test-id={`nxtbtn`} onClick={this.handleNextQuestion}>
                <ChevronRightIcon />
              </button>
            </BoxMUI>
          </BoxMUI>
        </BoxMUI>
        <Modal
          open={this.state.showModalSubmit}
          BackdropProps={{ onClick: undefined }}
          data-test-id="modal-here"
        >
          <BoxMUI
            sx={webStylePTGuest.modalStyle}
            className="submit-test-question"
          >
            <CheckCircleIcon style={webStylePTGuest.circleModal} />
            <Typography style={webStylePTGuest.headerStyleModal}>
              Demo Test Submitted
            </Typography>
            <Typography style={webStylePTGuest.subStyleModal}>
              To get access for real test and more content you need to register.
            </Typography>
            <Button
              style={webStylePTGuest.buttonStyleYes}
              data-test-id="get-here"
              onClick={()=>this.navigateToRegister()}
            >
              Get Started
            </Button>
            <Button
              style={webStylePTGuest.buttonStyleCancel}
              data-test-id="cancel-here"
              onClick={this.showModalSubmit}
            >
              Cancel
            </Button>
          </BoxMUI>
        </Modal>
      </BoxMUI>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(GuestGrandTest);
export { GuestGrandTest };
// Customizable Area End
