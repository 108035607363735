import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { WithStyles } from "@material-ui/core";
import { RootLogin } from "../../email-account-login/src/EmailAccountLoginBlockController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isModalOpenLogout: boolean;
  profilePopup: boolean;
  showSidebar: boolean;
  userData: RootLogin | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardCMController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isModalOpenLogout: false,
      profilePopup: false,
      showSidebar: false,
      userData: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    if (!(await storage.get("authToken"))) {
      this.props.navigation.navigate("Login");
    }
  };
  // Customizable Area Start
  toggleProfilePopUp = () => {
    this.setState({
      profilePopup: !this.state.profilePopup,
    });
  };

  navigateTo = (linkTo: string) => {
    this.props.navigation.history.push(linkTo);
  };

  setShowSidebar = () => {
    this.setState({
      showSidebar: !this.state.showSidebar,
    });
  };

  logout = async () => {
    await storage.clearStorage();
    this.handleOpenModal();
  };

  handleOpenModal = () => {
    this.setState({
      isModalOpenLogout: !this.state.isModalOpenLogout,
    });
  };

  determineIfActiveLink = (linkInfo: string): string => {
    const path = this.props.navigation.history.location.pathname;
    const searchParams = this.props.navigation.history.location.search;
    const totalPath = path + searchParams;

    if (totalPath.includes(linkInfo)) {
      return "active-link";
    }

    return "";
  };
  // Customizable Area End
}
