import React from "react";

import {
  Box as MUIBoxDiv,
  // Customizable Area Start
  Modal,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Link } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import GrandTestSavedNotesMainController, {
  Props,
} from "./GrandTestSavedNotesMainController.web";

export default class GrandTestSavedNotesMain extends GrandTestSavedNotesMainController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MUIBoxDiv>
        <Loader loading={this.state.loading} />
        <Modal
          BackdropProps={{ onClick: undefined }}
          open={this.state.isNoteCreateSavedSuccessfully}
          onClose={this.handleNoteCreateModalClose}
        >
          <MUIBoxDiv style={webStyle.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>
              {this.state.noteType === "Edit"
                ? "Changes Updated Successfully!"
                : "Note Saved Successfully"}
            </h3>
            <MUIBoxDiv>
              <Link
                to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                  "packageId"
                )}/review-test/${this.props.navigation.getParam(
                  "subjectId"
                )}/review/${this.props.navigation.getParam(
                  "testId"
                )}/saved-notes`}
              >
                <Button
                  data-test-id="go-saved-success"
                  onClick={this.handleNoteCreateModalClose}
                  style={webStyle.buttonStyleYes}
                >
                  Go to saved notes
                </Button>
              </Link>
            </MUIBoxDiv>
          </MUIBoxDiv>
        </Modal>
        <Modal
          onClose={this.handleNoteCancelModalClose}
          BackdropProps={{ onClick: undefined }}
          open={this.state.isNoteCancellationModalOpen}
        >
          <MUIBoxDiv style={webStyle.modalStyle}>
            <h3>Are you sure you want to cancel note without saving?</h3>
            <MUIBoxDiv>
              <Button
                style={webStyle.buttonStyleNo}
                data-test-id="go-saved-no"
                onClick={this.handleNoteCancelModalClose}
              >
                No
              </Button>
              <Button
                style={webStyle.buttonStyleYes}
                data-test-id="go-saved-yes"
                onClick={this.confirmCancel}
              >
                Yes
              </Button>
            </MUIBoxDiv>
          </MUIBoxDiv>
        </Modal>
        <Modal
          open={this.state.cancelModalConfirm}
          onClose={this.handleNoteCancelConfirmModalClose}
          BackdropProps={{ onClick: undefined }}
        >
          <MUIBoxDiv style={webStyle.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>Cancelled Note Successfully!</h3>
            <MUIBoxDiv>
              <Button
                onClick={this.handleNoteCancelConfirmModalClose}
                data-test-id="go-back"
                style={webStyle.buttonStyleYes}
              >
                Go Back
              </Button>
            </MUIBoxDiv>
          </MUIBoxDiv>
        </Modal>
        <MUIBoxDiv style={webStyle.bannerStyle}>
          <h2>{this.state.noteType} Note</h2>
        </MUIBoxDiv>
        <MUIBoxDiv
          display="flex"
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          marginTop={"4em"}
        >
          <MUIBoxDiv style={webStyle.inputStyle}>
            <MUIBoxDiv style={webStyle.inputBannerStyle}>
              <p>Exam : {this.state.savedNoteDetail?.meta.exam}</p>
              <p>Question No - {this.state.questionNumber}</p>
            </MUIBoxDiv>
            <textarea
              value={this.state.content}
              onChange={(event) => this.handleInputChange(event.target.value)}
              style={webStyle.textInputStyle}
              data-test-id="text-area"
              name="textarea"
              placeholder="Write your note here"
            />
          </MUIBoxDiv>
          <MUIBoxDiv marginTop={"3em"} marginBottom={"4em"}>
            <Button
              data-test-id="cancel-btn"
              onClick={this.handleNoteCancelModalOpen}
              style={webStyle.buttonStyleNo}
            >
              Cancel
            </Button>
            <Button
              data-test-id="save-btn"
              style={webStyle.buttonStyleYes}
              onClick={this.NoteApi}
            >
              Save
            </Button>
          </MUIBoxDiv>
        </MUIBoxDiv>
      </MUIBoxDiv>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  bannerStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "rgb(82, 0, 130)",
    background: "rgba(247, 214, 255, 0.19)",
    height: "71px",
  },
  buttonStyleYes: {
    textTransform: "none" as const,
    color: "white",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    width: "200px",
    height: "50px",
    marginLeft: "1em",
  },
  inputBannerStyle: {
    width: "100%",
    padding: "0em 2em",
    display: "flex",
    borderBottom: "1px solid gray",
    justifyContent: "space-between",
    height: "70px",
    fontSize: "24px",
    alignItems: "center",
  },
  buttonStyleNo: {
    background: "white",
    height: "50px",
    color: "#4F1f6d",
    width: "200px",
    border: "1px solid #ffe9ed",
    textTransform: "none" as const,
    borderRadius: "0.6em",
  },
  inputStyle: {
    height: "max-content",
    width: "90%",
    minHeight: "300px",
    border: "1px solid rgb(255,167,167)",
  },
  centerHorizontally: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    gap: "1em",
    flexDirection: "column" as const,
  },
  modalStyle: {
    top: "50%",
    position: "absolute" as "absolute",
    display: "flex",
    left: "50%",
    transform: "translate(-50%, -50%)",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    width: "35%",
    border: "2px solid #FFD0D8",
    borderRadius: "1.5em",
    padding: "2em",
    minHeight: "max-content",
    backgroundColor: "white",
    gap: "1.5em",
  },
  textInputStyle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    fontSize: "20px",
    minHeight: "300px",
    padding: "1.2em 1.2em",
    border: "none",
    width: "100%",
    height: "100%",
    outline: "none",
    resize: "none" as const,
    ":focus": {
      outline: "none",
    },
  },
};
// Customizable Area End
