import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button as MUIButton,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Button from "../../../components/src/Button.web";
import Loader from "../../../components/src/Loader.web";
import { webStyleWTGuest } from "./GuestWeeklyTest.web";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BannerWeb from "../../../components/src/Banner.web";
// Customizable Area End

import GuestWeeklyTestChaptersController, {
  Props,
} from "./GuestWeeklyTestChaptersController.web";

class GuestWeeklyTestChapters extends GuestWeeklyTestChaptersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className={this.props.classes.chapterContainerStyle}>
        <Loader loading={this.state.loading} />
        <BannerWeb title="Weekly Test" />
        <table
          cellPadding="0"
          className={this.props.classes.tableStyle}
          cellSpacing="0"
        >
          <thead>
            <tr>
              <th>Subject</th>
              <th>Test name</th>
              <th>Date</th>
              <th>Timings</th>
              <th>Take Test</th>
              <th>Review Test</th>
            </tr>
          </thead>
          <tbody>
            {this.state.chapters.map((test, index) => {
              const created = this.handleDate(test.attributes.created_at);
              const start = this.handleDays(test.attributes.start_time);
              return (
                <tr key={`${index}-tr-swayam`}>
                  <td>{test.attributes.test_subject}</td>
                  <td>{test.attributes.test_name}</td>
                  <td>{created}</td>
                  <td>{start}</td>
                  <td>
                    <Box
                      data-test-id="navigate-test"
                      onClick={() => {
                        if (index === 0) {
                          return this.navigateToTest(test.id);
                        }
                        return this.showModalSubmit();
                      }}
                    >
                      <Button
                        height="40px"
                        width="120px"
                        color="white"
                        title="Take Test"
                      />
                    </Box>
                  </td>
                  <td>
                    <MUIButton
                      data-test-id="navigate-review"
                      onClick={() => this.showModalSubmit()}
                      className={this.props.classes.buttonStyleReview}
                    >
                      Review
                    </MUIButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Modal
          open={this.state.showModalSubmit}
          BackdropProps={{ onClick: undefined }}
          data-test-id="modal-here"
        >
          <Box sx={webStyleWTGuest.modalStyle} className="submit-test-question">
            <CheckCircleIcon style={webStyleWTGuest.circleModal} />
            <Typography style={webStyleWTGuest.headerStyleModal}>
              Demo Done
            </Typography>
            <Typography style={webStyleWTGuest.subStyleModal}>
              To get access for real test and more content you need to register.
            </Typography>
            <MUIButton
              style={webStyleWTGuest.buttonStyleYes}
              onClick={() => this.navigateToRegister()}
              data-test-id="get-here"
            >
              Get Started
            </MUIButton>
            <MUIButton
              data-test-id="cancel-here"
              style={webStyleWTGuest.buttonStyleCancel}
              onClick={() => this.showModalSubmit()}
            >
              Cancel
            </MUIButton>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleGuestWeeklyTestChapters = () =>
  createStyles({
    chapterContainerStyle: {
      display: "flex",
      gap: "1em",
      width: "100%",
      flexWrap: "wrap" as const,
      justifyContent: "center",
      alignItems: "center",
      marginTop: "2em",
    },
    imageStyle: {
      width: "90px",
      borderRadius: "50%",
      height: "90px",
    },
    cardStyle: {
      width: "350px",
      minHeight: "130px",
      maxWidth: "100%",
      flexWrap: "wrap" as const,
      flexDirection: "column" as const,
      height: "max-content",
      display: "flex",
      justifyContent: "center",
      border: "1px solid rgba(255, 63, 89, 1)",
      borderRadius: "0.5em",
      padding: "2em 0.7em",
      gap: "1em",
      marginTop: "2em",
    },
    buttonStyle: {
      color: "rgb(82, 0, 130)",
      width: "125px",
      border: "1px solid #FF9486",
      height: "40px",
      display: "flex",
      position: "relative" as const,
      alignItems: "center",
      borderRadius: "10px",
      textTransform: "none" as const,
      justifyContent: "flex-start",
    },
    buttonStyleReview: {
      border: "1.5px solid #FD3959",
      padding: "0.5em 2em",
      borderRadius: "0.7em",
    },
    buttonStyles: {
      display: "flex",
      gap: "2em",
      justifyContent: "center",
      alignItems: "center",
    },
    goBackStep: {
      display: "flex",
      height: "20px",
      borderRadius: "50%",
      padding: "0.3em",
      justifyContent: "center",
      alignItems: "center",
      width: "20px",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      color: "white",
      cursor: "pointer",
      position: "absolute",
      right: "5px",
    },
    tableStyle: {
      width: "80%",
      margin: "4em 0",
      tableLayout: "fixed",
      textAlign: "left",
      "& tbody": {
        "& tr:last-child td:nth-child(1)": {
          borderBottomLeftRadius: "0.7em",
        },
        "& tr td": {
          padding: "1.2em 0",
          overflowWrap: "break-word",
          wordWrap: "break-word",
        },
        "& tr td:nth-child(1)": {
          paddingLeft: "1.2em",
        },
        "& tr:nth-child(even)": {
          background: "#E8E8E8",
        },
        "& tr:last-child td:last-child": {
          borderBottomRightRadius: "0.7em",
        },
      },
      "& thead": {
        border: "1px solid #E8E8E8",
        background: "#E8E8E8",
        "& tr th:last-child": {
          borderTopRightRadius: "0.7em",
        },
        "& tr th:nth-child(1)": {
          borderTopLeftRadius: "0.7em",
          paddingLeft: "1.2em",
        },
        "& tr th": {
          padding: "1.2em 0",
        },
      },
    },
  });
export default withStyles(webStyleGuestWeeklyTestChapters)(
  GuestWeeklyTestChapters
);
export { GuestWeeklyTestChapters };
// Customizable Area End
