import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

interface Package {
  id: number;
  package_name: string;
  created_at: string;
  updated_at: string;
  package_description: string;
  status: string;
  subject_id: string;
  class_zone: string;
  practice_test: string;
  subjective_test: string;
  gt_code: string;
  question_bank: string;
  created_by: string;
  image_url: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  packages: Array<Package>;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GuestGrandTestPackagesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPackagesMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      packages: [],
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getPackagesMessageId && response) {
      if (response?.data) {
        this.fetchedPackagesSuccessful(response.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getPackages();
  };

  // Customizable Area Start
  fetchedPackagesSuccessful = (packages: Package[]) => {
    this.setState({
      packages,
    });
  };

  navigateTo = (link: string) => {
    this.props.navigation.history.push(link);
  };

  getPackages = async () => {
    const getPackagesMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      uuid: await storage.get("guestToken"),
    };

    this.setState({
      loading: true,
    });

    this.getPackagesMessageId = getPackagesMessage.messageId;

    getPackagesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.guestGrandTestPackagesEndPoint + "?pack_final_id=1"
    );

    getPackagesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getPackagesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getPackagesMessage.id, getPackagesMessage);
  };
  // Customizable Area End
}
