import React from "react";

import {
  Box,
  // Customizable Area Start
  Typography,
  withStyles,
  createStyles,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController.web";

export class ExamNotifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className={this.props.classes.examNotif}>
        <h2>{configJSON.examNotificationLabel}</h2>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {this.state.examNotifications.map((notif, index) => {
            return (
              <Grid item key={`notifs-${index}`} sm={12} lg={6}>
                <Box className={this.props.classes.notifsExam}>
                  <Typography
                    variant={"body1"}
                    data-test-id="description-notif"
                  >
                    {notif.attributes.description}
                  </Typography>
                  <Box className={this.props.classes.goBackStep}>{`>`}</Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleExamNotification = () =>
  createStyles({
    examNotif: {
      display: "flex",
      flexDirection: "column",
      gap: "1em",
      border: "none",
      borderRadius: "1em",
      background: "rgb(239,238,243)",
      height: "max-content",
      padding: "2em 2em",
      marginTop: "3em",
      color: "#37363c",
      width: "100%",
    },
    notifsExam: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "none",
      borderRadius: "1em",
      cursor: "pointer",
      padding: "1em 4em 1em 1em",
      background: "white",
      minHeight: "100px",
      height: "max-content",
      position: "relative",
    },
    goBackStep: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      textAlign: "center",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      color: "white",
      fontSize: "1.3em",
      position: "absolute",
      right: "20px",
    },
    gridContainer: {
      gridRowGap: "1em",
      gridColumnGap: "1em",
    },
  });

export default withStyles(webStyleExamNotification)(ExamNotifications);
// Customizable Area End
