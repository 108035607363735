import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userInput: {
    name: string;
    email: string;
    phone: string;
    subject: string;
    message: string;
  };
  formComplete: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommunityForumController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postContactUsDocumentId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userInput: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
      formComplete: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJsonSuccess = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      apiRequestCallId === this.postContactUsDocumentId &&
      responseJsonSuccess
    ) {
      if (responseJsonSuccess.data) {
        this.setState({
          formComplete: true,
        });
      }
    }
    // Customizable Area End
  }

  // web events
  handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    this.setState({
      userInput: {
        ...this.state.userInput,
        [name]: value,
      },
    });
  };

  // Customizable Area Start
  clearForms = () => {
    this.setState({
      userInput: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
    });
  };

  handleContactUsSubmit = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    let formdata = new FormData();
    formdata.append("[data][name]", this.state.userInput.name);
    formdata.append("[data][email]", this.state.userInput.email);
    formdata.append("[data][phone_number]", this.state.userInput.phone);
    formdata.append("[data][subject]", this.state.userInput.subject);
    formdata.append("[data][message]", this.state.userInput.message);

    const header = {
      "Content-Type": undefined,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.postContactUsDocumentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_contact_us/contacts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
