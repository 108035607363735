Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "guestlogin2";
exports.labelBodyText = "guestlogin2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.packagesEP = "bx_block_plan/pack_finals"
exports.getPackages = "/bx_block_packages/packages";
exports.guestLoginGETEndPoint = "bx_block_plan/pack_finals";
exports.priceText = "View Pricing";
exports.choosePackageText= "Choose Package";
exports.pricingText = "Pricing";
exports.registrationText = "Registration"; 
exports.paymentText  = "Payment Successful";
// Customizable Area End