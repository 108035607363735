import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
export type CarouselData = {
  id: string;
  type: string;
  attributes: {
    id: number;
    exam_name: string;
    display_order: number;
    image: string;
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  carouselData: CarouselData[];
  currentIndex: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CarouselAdvertisementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCarouselDataMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      carouselData: [],
      currentIndex: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getCarouselDataMessageId) {
      if (response?.data) {
        this.handleCarouselData(response.data);
      }
    }
    // Customizable Area End
  }

  // web events

  // Customizable Area Start
  componentDidMount = async () => {
    this.fetchCarouselData();
  };

  fetchCarouselData = async () => {
    const getCarouselDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      "Content-Type": "application/json",
    };

    this.getCarouselDataMessageId = getCarouselDataMessage.messageId;

    getCarouselDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getCarouselDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage3/landingpages/get_hallfame`
    );

    getCarouselDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getCarouselDataMessage.id, getCarouselDataMessage);
  };

  handleCarouselData = (carouselData: CarouselData[]) => {
    this.setState({
      carouselData,
    });
  };

  handleCurrentIndex = (index: number) => {
    this.setState({
      currentIndex: index,
    });
  };
  // Customizable Area End
}
