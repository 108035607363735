Object.defineProperty(exports, "__esModule", {
  value: true
});





// Customizable Area Start
exports.question = "Question"
exports.Demolable = "This is for Demo Test"
exports.peoplegiveCortAns = "of people who got it correct"
exports.correctAn = "Your answer is CORRECT "
exports.wrongAns = "You answer is INCORRECT "
exports.unttedQuestion = "You didn't attend this question"
exports.NoQuestionFound = "No Questions Found !"
exports.DemoTextLabel = "Demo test Submitted"
exports.clearResponseEndPoint = "/bx_block_assessmenttest/grand_tests/{grandtestID}/clear_response"
exports.testModallabel = "Are you sure you want \n to leave this page !"
exports.forWrongAns = "for wrong Answer";
exports.corransLabel = "for Correct Answer and-"
exports.quesCarries = "This Question Carries +"
exports.DemoTestLabel = "This is for Demo Test";
exports.submitGuestPraticesTestEndPoint = "/bx_block_assessmenttest/practice_tests/{id}/submit_test?a_str={selectedOptions}&question_ids={QuestionIDs}&time_taken={testTime}"
exports.guestFlowTestEndPoint = "/bx_block_assessmenttest/guest_practice_tests/{id}/guest_take_test"
exports.getStarted = "Get Started"
exports.registrationLabel = 'To get access for Real test and  more content \nyou need to register'
exports.registrationOverviewLabel = 'To get access for overview and Real test content you need to register'
exports.guestflowEndPoint = "/guest_chapters"
exports.contentLabel = "You don't have access to this content"
exports.guestFlowChapterEndPoint = "/bx_block_assessmenttest/guest_practice_tests/"
exports.praticesTestLabel = "Practice Test"
exports.guestFlowSubjectEndPoint = "/bx_block_assessmenttest/guest_practice_tests/guest_subjects?pack_final_id={packageID}"
exports.deleteSaveNotes = "/bx_block_assessmenttest/notes/";
exports.takeTest = "Take test"
exports.attemptsNo = 'No. of attempts'
exports.instructionApiEndPoint = "/bx_block_assessmenttest/weekly_tests/instructions"
exports.ReviewHistory = "Review History"
exports.takeTest = "Take test"
exports.nodatalabel2 = 'Click on Take test to get Review'
exports.nodatalabel1 = "You did't Take Test to \n Review"
exports.TestAttempt = "Test Attempt"
exports.backlabel = "Go Back";
exports.bookQuestionNo = "Bookmarked Question no {questionno} succesfully"
exports.createBookmarkEndPoint = '/bx_block_content_management/bookmarks'
exports.RetakeTest = "Retake Test"
exports.View = "view"
exports.showNotesEndpoint = "/bx_block_assessmenttest/notes/"
exports.Chapter = "Chapter"
exports.updateNotes = "Update Note"
exports.placeHolder = 'Write your note here'
exports.PUTMethod = "PUT"
exports.editnotesEndPoint = "/bx_block_assessmenttest/notes/"
exports.cancel = "Cancel"
exports.saveNote = "Save Note"
exports.createNotes = "Create New Notes"
exports.bookmarksModallabel = "Choose What You Need"
exports.gotoSaveNotes = "Go To Saved Notes"
exports.reviewHistory = "/bx_block_assessmenttest/grand_tests/{id}/review_histories"
exports.createNotesendpoint = '/bx_block_assessmenttest/notes';
exports.TotalNotes = 'Total Notes'
exports.bookmarks = "Bookmark"
exports.Save = "Save";
exports.unmarksEndPoint = "/bx_block_assessmenttest/grand_tests/{id}/unmark_review"
exports.label = 'Come on, its half way'
exports.SaveNotes = "Saved notes"
exports.Topic = "Topic"
exports.gtSaveNotesEndPoint = 'bx_block_assessmenttest/grand_tests/{id}/gt_notes'
exports.bookmarkendpoint = '/bx_block_assessmenttest/grand_tests/{id}/gt_bookmarks';
exports.delete = "DELETE"
exports.bookmarkdelete = '/bx_block_content_management/bookmarks/'
exports.bookmarkQuestion = "You have Bookmarked this Question"
exports.QuestionNo = "Question No"
exports.Exam = "Exam";
exports.lable = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias, velit necessitatibus? Maiores?"
exports.Note = "Notes";
exports.bookmark = "Bookmarks"
exports.explanation = 'Explantaion'
exports.unttedQuestion = "You didn't attempt this question"
exports.correctAns = "Correct Answer is"
exports.mints = "Mins";
exports.reviewTestendPoint = "/bx_block_assessmenttest/grand_tests/{id}/review_test/{gt_reviewID}?sub_name={subjectName}"
exports.Review_Your_Test = "Review Your Test";
exports.Incorrect = "Incorrect"
exports.Correct = 'Correct'
exports.analysisEndPoint = "/bx_block_assessmenttest/gt_review/{id}"
exports.reviewTest = "Review Test";
exports.review = "Review";
exports.ReviewContent = "Click on review for detailed solutions and \n analysis of the test taken"
exports.performance = "PERFORMANCE ANALYSIS"
exports.total = "Total"
exports.Marks = "Marks"
exports.Unattempted = "Unattempted";
exports.wrong = "Wrong";
exports.Correct = "Correct";
exports.Category = "Category";
exports.no = "No";
exports.yes = "Yes";
exports.areyouSure = "Are you sure you \n want to submit your test?"
exports.marKUnmark = "marked_and_un_marked_for_review";
exports.acA = 'answered_and_cleared_the_answer';
exports.nAmr = "not_answered_and_marked_for_review";
exports.amfr = "answered_and_marked_for_review";
exports.answered = "answered";
exports.unanswered = 'un_answered';
exports.guestGrandTestPackagesEndPoint = "/bx_block_assessmenttest/grand_tests/get_packages";
exports.overviewEndPoint = "/bx_block_assessmenttest/grand_tests/{id}/overview"
exports.guestOverviewEndPoint = "/bx_block_assessmenttest/guest_grand_tests/{id}/overview"
exports.forUnmarked = "un-marked"
exports.markReview = '/bx_block_assessmenttest/grand_tests/{id}/mark_review'
exports.guestmarkForReview = "/bx_block_assessmenttest/guest_grand_tests/{id}/mark_review"
exports.guestUnMarkForReview = "/bx_block_assessmenttest/guest_grand_tests/{id}/unmark_review"
exports.answeredEndPoint = "/bx_block_assessmenttest/grand_tests/{id}/answered"
exports.guestAnsweredEndPoint = "bx_block_assessmenttest/guest_grand_tests/1/answered"
exports.PlaceHolder = 'Type your answer'
exports.upperTitle = "This Question Carries + {pmark} for Correct Answer and - {nmark} for wrong Answer";
exports.perview = "Prev";
exports.forReview = "Mark For Review";
exports.clear = "Clear response";
exports.next = "Next";
exports.submit = 'Submit';
exports.exportQues = "Biology: 60 to 90 Question";
exports.chemistryQues = "Chemistry : 45 to 60 Question"
exports.oneToFourfive = "Physics 1 to 45 Question"
exports.questionplate = "Question Palette";
exports.markandUnmark = 'Marked & Unmarked for Review';
exports.ansClear = "Answered & Cleared the Answer";
exports.unmark = 'Not Answered & Marked for Review';
exports.mark = 'Answered & Marked for Review'
exports.unanswerd = "Un-Answered";
exports.Answered = "Answered";
exports.Legend = 'Legend';
exports.Questions = 'Questions';
exports.sliderTitle = "Come on, it's half way !";
exports.subjestlistEndPoint = "/bx_block_assessmenttest/grand_tests/{id}/gt_subjects"
exports.Overview = 'Overview'
exports.testEndPoint = '/bx_block_assessmenttest/grand_tests/{id}/take_test?sub_name={subjectID}'
exports.guestGrandTestTakeTest = "bx_block_assessmenttest/guest_grand_tests/{id}/take_test?sub_name={subjectID}";
exports.reviewTest = "Review Test";
exports.takeTest = 'Take Test';
exports.emptyData = 'No Data Found !'
exports.UnattemptedTest = 'Tests Unattempted'
exports.AttemptedList = 'Tests Attempted '
exports.grandtestEndpoint = '/bx_block_assessmenttest/grand_tests?gt_code=';
exports.title = 'Grand Test';
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfTestManagement2";
exports.registrstionText = "To get access for all subjects and more \n content you need to register";
exports.labelBodyText = "CfTestManagement2 Body";
exports.title1 = "NEET";
exports.weeklyTestLabel = "Weekly Test";;
exports.weeklyTestChapterEndPOint = "bx_block_assessmenttest/weekly_tests/get_tests";
exports.packagesEndpoint = '/bx_block_assessmenttest/grand_tests/get_packages';
exports.UserToken = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzY4LCJleHAiOjE2OTYwNjQwNDEsInRva2VuX3R5cGUiOiJsb2dpbiJ9.55R7zGwclRVS98MijM45rjsAogykuBMPdEFgov8WcH9K8zF7-gf_AJlOYhXz3LBlMipXEm4sAgQ4M4S9BvDDoQ";
exports.btnExampleTitle = "CLICK ME";
exports.submitTestEndpoint = "/bx_block_assessmenttest/grand_tests/{id}/submit_test?a_str={answer}?time_taken={time}"
exports.guestSubmitTestEndpoint = "/bx_block_assessmenttest/guest_grand_tests/{id}/submit_test"
exports.getSubjectTests = "bx_block_assessmenttest/weekly_tests/get_subjects"
exports.getInstructions = "bx_block_assessmenttest/weekly_tests/instructions"
exports.validationApiDeleteMethodType = "DELETE";
exports.createBookmark = "/bx_block_content_management/bookmarks";
exports.testName = "Test Name"
exports.selectSubjectContinue = "Select Subject To Continue"

exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RolesPermissions2";
exports.labelBodyText = "RolesPermissions2 Body";
exports.weeklyTestEndPoint = "/bx_block_assessmenttest/weekly_tests/get_subjects"
exports.instructionApiEndPoint = "/bx_block_assessmenttest/weekly_tests/instructions"
exports.btnExampleTitle = "CLICK ME";
exports.TakeText = 'Take Test';
exports.ReviewTest = "Review Test";
exports.testName = "Test name";
exports.clear = "Clear response";
exports.Recentlyadded = "Recently added";
exports.Done = "Done";
exports.cancel = "Cancel";
exports.FilterTitle = "Filter By";
exports.selectdate = "select Date";
exports.Confirm = "Confirm";
exports.format = "DD/MM/YYYY";
exports.subject = "Enter subject";
exports.SortTitle = "Sort By"
exports.search = "Search"
exports.weeklyTestEndPoint = "/bx_block_assessmenttest/weekly_tests/get_tests?sort_by={sortedOption}&date={date}&test_subject={testsubject}";
exports.date = "Select Date"
exports.weeklyTestLabel = "Weekly Test";
exports.weeklyTestChapterEndPOint = "bx_block_assessmenttest/weekly_tests/get_tests"
// Customizable Area End