import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WeeklyTest } from "./ReviewTestWeeklyMainController.web";
import storage from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isNoteCreateSavedSuccessfully: boolean;
  content: string;
  cancelModalConfirm: boolean;
  isNoteCancellationModalOpen: boolean;
  questionId: string;
  noteId: string;
  questionNumber: string;
  noteType: string;
  reviewId: string;
  loading: boolean;
  examName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WeeklyTestSavedNotesAddEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  editSavedNotesMessageId = "";
  createSavedNotesMessageId = "";
  token = "";
  getSavedNotesMessageId = "";
  getReviewHistoriesWeeklyMessageId = "";
  breadcrumb = [
    {
      link: "/dashboard/weekly-test/instructions",
      label: "Weekly Test",
    },
    {
      link: "/dashboard/weekly-test/instructions",
      label: "Instructions",
    },
    {
      link: "/dashboard/weekly-test/tests",
      label: "Tests",
    },
    {
      label: "Subject",
      link: "/dashboard/weekly-test/tests",
    },
    {
      link: `/dashboard/weekly-test/tests/review-test/${this.props.navigation.getParam(
        "testId"
      )}`,
      label: "Reviews",
    },
    {
      link: `/dashboard/weekly-test/tests/review-test/${this.props.navigation.getParam(
        "testId"
      )}`,
      label: "Reviews",
    },
    {
      link: `/dashboard/weekly-test/tests/review-test/${this.props.navigation.getParam(
        "testId"
      )}/review/${this.props.navigation.getParam("reviewId")}`,
      label: "Review Test",
    },
    {
      link: `/dashboard/weekly-test/tests/review-test/${this.props.navigation.getParam(
        "testId"
      )}/review/${this.props.navigation.getParam("reviewId")}/saved-notes`,
      label: "Saved Notes",
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Add/Edit Notes",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isNoteCreateSavedSuccessfully: false,
      noteId: "",
      content: "",
      examName: "",
      isNoteCancellationModalOpen: false,
      noteType: "",
      questionNumber: "",
      cancelModalConfirm: false,
      questionId: "",
      loading: false,
      reviewId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.editSavedNotesMessageId) {
      if (response?.id) {
        this.setState({
          isNoteCreateSavedSuccessfully: true,
        });
      }
    }
    if (apiRequestCallId === this.createSavedNotesMessageId) {
      if (response?.id) {
        this.setState({
          isNoteCreateSavedSuccessfully: true,
        });
      }
    }
    if (apiRequestCallId === this.getSavedNotesMessageId) {
      if (response.id) {
        this.setState({
          content: response.content,
        });
      }
    }

    if (apiRequestCallId === this.getReviewHistoriesWeeklyMessageId) {
      if (response?.weekly_test) {
        this.handleSuccessFetchResponse(response.weekly_test);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingWTSNAE = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingWTSNAE.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingWTSNAE);

    const token = localStorage.getItem("authToken");
    if (token) {
      this.token = token;
    }
    const windowUrl = window.location.href;
    const urlParams = new URLSearchParams(windowUrl.split("?")[1]);
    const noteId = urlParams.get("noteId");
    const reviewId = urlParams.get("reviewId");
    const noteType = urlParams.get("noteType");
    const questionNumber = urlParams.get("qno");
    const questionId = urlParams.get("qid");

    if (noteId) {
      this.setState(
        {
          noteId,
        },
        () => {
          this.GetNote(noteId);
        }
      );
    }
    if (noteType && reviewId) {
      this.setState({
        questionNumber: questionNumber as string,
        questionId: questionId as string,
        noteType,
        reviewId,
      });
    }

    this.getReviewHistoriesWeekly();
  };

  componentDidUpdate = (prevProps: Props, prevState: S) => {
    const windowUrl = window.location.href;
    const urlParams = new URLSearchParams(windowUrl.split("?")[1]);
    const reviewId = urlParams.get("reviewId");

    if (reviewId !== prevState.reviewId) {
      this.handleReviewIdChange(reviewId as string);
    }
  };

  handleReviewIdChange = (reviewId: string) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      this.token = token;
    }

    this.setState({
      reviewId,
    });
  };

  handleNoteCancelModalClose = () => {
    this.setState({
      isNoteCancellationModalOpen: false,
    });
  };
  handleNoteCreateModalClose = () => {
    this.setState({
      isNoteCreateSavedSuccessfully: false,
    });
  };
  handleNoteCancelModalOpen = () => {
    this.setState({
      isNoteCancellationModalOpen: true,
    });
  };
  handleNoteCancelConfirmModalClose = () => {
    this.setState({
      cancelModalConfirm: false,
    });
  };
  handleNoteCancelConfirmModalOpen = () => {
    this.setState({
      cancelModalConfirm: true,
    });
  };
  confirmCancel = () => {
    this.handleNoteCancelModalClose();
    this.handleNoteCancelConfirmModalOpen();
  };
  handleInputChange = (value: string) => {
    this.setState({
      content: value,
    });
  };

  // Customizable Area Start
  NoteApi = () => {
    if (this.state.noteType === "Edit") {
      const editSavedNotesMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const headers = {
        token: this.token,
      };

      editSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      editSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
      );
      editSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_assessmenttest/notes/${this.state.noteId}?content=${this.state.content}`
      );

      this.editSavedNotesMessageId = editSavedNotesMessage.messageId;

      runEngine.sendMessage(editSavedNotesMessage.id, editSavedNotesMessage);
    } else {
      const headers = {
        token: this.token,
        "Content-Type": "application/json",
      };

      const body = {
        noteable_id: this.state.reviewId,
        noteable_type: "WeeklyTestReviewHistory",
        content: this.state.content,
        question_id: this.state.questionId,
        question_no: this.state.questionNumber,
      };

      const createSavedNotesMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      this.createSavedNotesMessageId = createSavedNotesMessage.messageId;

      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_assessmenttest/notes`
      );

      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      runEngine.sendMessage(
        createSavedNotesMessage.id,
        createSavedNotesMessage
      );
    }
  };

  GetNote = (noteId: string) => {
    const headers = {
      token: this.token,
      "Content-Type": "application/json",
    };

    const getSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSavedNotesMessageId = getSavedNotesMessage.messageId;

    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/notes/${noteId}`
    );

    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getSavedNotesMessage.id, getSavedNotesMessage);
  };

  getReviewHistoriesWeekly = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getHistoryMessageSNAEC = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReviewHistoriesWeeklyMessageId = getHistoryMessageSNAEC.messageId;

    getHistoryMessageSNAEC.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getHistoryMessageSNAEC.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/${this.props.navigation.getParam(
        "testId"
      )}/review_histories`
    );

    getHistoryMessageSNAEC.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getHistoryMessageSNAEC.id, getHistoryMessageSNAEC);
  };

  handleSuccessFetchResponse = (response: WeeklyTest) => {
    const breadcrumb = this.breadcrumb;
    breadcrumb[3].label = response.test_subject;
    breadcrumb[4].label = response.test_name;

    const propPassingRTWMC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingRTWMC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingRTWMC);

    this.setState({
      loading: false,
      examName: response.test_name,
    });
  };
  // Customizable Area End
}
