import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  Typography,
  Modal,
  Button as MUIButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import { webStyleGuestWeeklyTestChapters } from "../../CfTestManagement2/src/GuestWeeklyTestChapters.web";
import Button from "../../../components/src/Button.web";
import Loader from "../../../components/src/Loader.web";
import { webStylePTGuest } from "../../CfTestManagement2/src/GuestPracticeTest.web";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// Customizable Area End

import GuestQbTopicsController, { Props } from "./GuestQbTopicsController.web";

class GuestQbTopics extends GuestQbTopicsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <BannerWeb title="Question Bank" />
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Modal
            open={this.state.showModalSubmit}
            data-test-id="modal-here"
            BackdropProps={{ onClick: undefined }}
          >
            <Box
              sx={webStylePTGuest.modalStyle}
              className="submit-test-question"
            >
              <CheckCircleIcon style={webStylePTGuest.circleModal} />
              <Typography style={webStylePTGuest.headerStyleModal}>
                Demo Test Complete
              </Typography>
              <Typography style={webStylePTGuest.subStyleModal}>
                To get access for real test and more content you need to
                register.
              </Typography>
              <MUIButton
                style={webStylePTGuest.buttonStyleYes}
                data-test-id="get-here"
                onClick={this.navigateToRegister}
              >
                Get Started
              </MUIButton>
              <MUIButton
                style={webStylePTGuest.buttonStyleCancel}
                data-test-id="cancel-here"
                onClick={this.showModalSubmit}
              >
                Cancel
              </MUIButton>
            </Box>
          </Modal>
          <table
            cellPadding="0"
            cellSpacing="0"
            className={this.props.classes.tableStyle}
          >
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>Subject</th>
                <th>Topic</th>
                <th>Question Bank</th>
              </tr>
            </thead>
            <tbody>
              {this.state.topics.map((topic, index) => {
                return (
                  <tr
                    key={`${index}-tr-swayam`}
                    style={{ textAlign: "center" }}
                  >
                    <td>{topic.attributes.name}</td>
                    <td>{topic.attributes.subject.name}</td>
                    <td>
                      <Box
                        data-test-id="navigate-test"
                        onClick={() => {
                          if (index > 0) {
                            return this.showModalSubmit();
                          }
                          return this.navigateTo(
                            `${this.props.navigation.history.location.pathname}/${topic.id}`
                          );
                        }}
                      >
                        <Button
                          height="40px"
                          width="120px"
                          color="white"
                          title="View"
                        />
                      </Box>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(webStyleGuestWeeklyTestChapters)(GuestQbTopics);
export { GuestQbTopics };
// Customizable Area End
