import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isModalOpen: boolean;
  userInput: {
    emailAddress: string;
  };
  isModalOpenWrong: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPassword extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postForgotPasswordDocumentId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isModalOpen: false,
      userInput: {
        emailAddress: ""
      },
      isModalOpenWrong: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      apiRequestCallId === this.postForgotPasswordDocumentId &&
      responseJson
    ) {
      if (responseJson.data) {
        this.handleOpenModal();
      } else {
        this.handleOpenModalWrong();
      }
    }
    // Customizable Area End
  }

  // web events
  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  handleOpenModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  handleOpenModalWrong = () => {
    this.setState({
      isModalOpenWrong: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false
    });
    this.props.navigation.navigate("Login")
  };

  handleCloseModalWrong = () => {
    this.setState({
      isModalOpenWrong: false
    });
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    this.setState({
      userInput: {
        ...this.state.userInput,
        [name]: value
      }
    });
  };

  // Customizable Area Start
  sendForgotPasswordLink = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const header = {
      "Content-Type": "application/json",
    }

    const requestBody = {
      data: {
        attributes: {
          email: this.state.userInput.emailAddress,
          url: "https://myrankelearningapp-96162-react-native.b96162.dev.eastus.az.svc.builder.cafe/reset-password",
        }
      }
    };

    const apiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.postForgotPasswordDocumentId = apiMessage.messageId;

    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    apiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordEndpoint
    );

    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(apiMessage.id, apiMessage);
  };
  // Customizable Area End
}
