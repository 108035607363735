import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  stepper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    position: 'relative',
  },
  stepLine: {
    height: '6px',
    transition: 'width 0.3s ease',
  },
}));

interface StepperProps {
  steps: number;
  activeStep: number;
}

const Stepper: React.FC<StepperProps> = ({ steps, activeStep }) => {
  const classes = useStyles();

  return (
    <div className={classes.stepper}>
      <div className={classes.stepLine} style={{ width: `${(100 * activeStep) / steps}%`, backgroundColor: '#430A6E' }}></div>
      <div className={classes.stepLine} style={{ width: `${(100 * (steps - activeStep)) / steps}%`, backgroundColor: '#C8D4E0' }}></div>
    </div>
  );
};

export default Stepper;