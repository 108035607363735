import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { RootLogin } from "../../email-account-login/src/EmailAccountLoginBlockController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showSidebar: boolean;
  isModalOpenLogout: boolean;
  isNotLoggedIn: boolean;
  profilePopUp: boolean;
  isLoading: boolean;
  userData: null | RootLogin;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showSidebar: true,
      isModalOpenLogout: false,
      isNotLoggedIn: false,
      profilePopUp: false,
      isLoading: true,
      userData: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const authToken = await storage.get("authToken");

    if (authToken) {
      this.handleLoggedIn();
      this.fetchUserData();
    } else {
      this.handleLoggedInFalse();
    }
  };
  fetchUserData = async () => {
    const userData = JSON.parse(await storage.get("userData")) as RootLogin;
    this.setState({
      userData,
    });
  };

  navigateToDashboard = () => {
    this.props.navigation.history.push("/dashboard");
  };

  handleLoggedIn = () => {
    this.setState({
      isNotLoggedIn: false,
      isLoading: false,
    });
  };

  toggleProfilePopUp = () => {
    this.setState({
      profilePopUp: !this.state.profilePopUp,
    });
  };

  handleLoggedInFalse = () => {
    this.setState({
      isNotLoggedIn: true,
      isLoading: false,
    });
  };

  logout = async () => {
    await storage.clearStorage();
    this.handleOpenModal();
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpenLogout: false,
    });
  };

  handleOpenModal = () => {
    this.setState({
      isModalOpenLogout: true,
    });
  };

  handleAyqLoginModal = () => {
    this.setState({
      isNotLoggedIn: !this.state.isNotLoggedIn,
    });
  };

  navigateToLandingPage = () => {
    this.handleCloseModal();
    this.props.navigation.history.push("/");
  };

  navigateToLogin = () => {
    this.props.navigation.history.push("/ask-your-query/login");
  };

  setShowSidebar = () => {
    this.setState({
      showSidebar: !this.state.showSidebar,
    });
  };
  // Customizable Area Start
  navigateToQuestionsAsked = () => {
    this.props.navigation.history.push(
      "/dashboard-ask-your-query/questions-asked"
    );
  };

  navigateToProfile = () => {
    this.props.navigation.history.push("/dashboard-ask-your-query/profile");
  };

  navigateToHome = () => {
    this.props.navigation.history.push("/dashboard-ask-your-query/home");
  };

  determineIfActiveLink = (linkInfo: string): string => {
    const path = this.props.navigation.history.location.pathname;
    const searchParams = this.props.navigation.history.location.search;
    const totalPath = path + searchParams;

    if (totalPath.includes(linkInfo)) {
      return "active-link";
    }

    return "";
  };
  // Customizable Area End
}
