import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { styles } from "./styles";
import { WithStyles } from "@material-ui/core";
import { extractParams } from "../../../framework/src/UtilsWeb";
export interface Root {
  meta: Meta;
}

export interface Meta {
  chapter: Chapter;
  image: string;
  practice_test_review_history_id: number;
  correct_answer: number;
  incorrect_answer: number;
  unattended: number;
  marks: number;
  time_taken: string;
}

export interface Chapter {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  practice_test: string;
  avail_pt: string;
  date_pt: any;
  class_zone: string;
  avail_cz: string;
  date_cz: any;
  question_bank: string;
  avail_qb: string;
  date_qb: any;
  created_by: string;
  remarks: string;
  topic_db: string;
  qb_id: any;
  qb_db: string;
  id_pt: string;
  id_cz: string;
}
// Customizable Area End

export interface Props extends WithStyles<typeof styles> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  timeTaken: string;
  total: number | null;
  correctPercentage: number | null;
  incorrectPercentage: number | null;
  unattendedPercentage: number | null;
  reviewId: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PracticeTestReviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSubjectsMessageRCId = "";
  searchResolve = this.props.navigation.history.location.search;
  breadcrumb = [
    {
      label: "Practice Test",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}${this.searchResolve}`,
    },
    {
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}${this.searchResolve}`,
      label: "Chapters",
    },
    {
      label: "Test",
      link:
        this.props.navigation.history.location.pathname + this.searchResolve,
    },
    {
      label: "Review",
      link:
        this.props.navigation.history.location.pathname + this.searchResolve,
    },
  ];
  token: string = "";
  getResultsMessageReviewId: string = "";
  chapterId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      timeTaken: "",
      loading: false,
      total: null,
      correctPercentage: null,
      incorrectPercentage: null,
      unattendedPercentage: null,
      reviewId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getResultsMessageReviewId) {
      if (response.meta) {
        const total =
          response.meta.correct_answer +
          response.meta.incorrect_answer +
          response.meta.unattended;

        const breadcrumb = this.breadcrumb;
        breadcrumb[2].label = (response.meta.chapter.name as string) + " Test";

        const propPassingRC = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingRC.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );

        this.send(propPassingRC);

        this.setState({
          timeTaken: response.meta.time_taken,
          total,
          correctPercentage: Number(
            ((response.meta.correct_answer / total) * 100).toFixed(2)
          ),
          incorrectPercentage: Number(
            ((response.meta.incorrect_answer / total) * 100).toFixed(2)
          ),
          unattendedPercentage: Number(
            ((response.meta.unattended / total) * 100).toFixed(2)
          ),
          reviewId: String(response.meta.practice_test_review_history_id),
        });
      }
    }

    if (apiRequestCallId === this.getSubjectsMessageRCId && response) {
      if (response.data) {
        this.handleSuccessSubject(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingPTR = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingPTR.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingPTR);

    const token = await storage.get("authToken");
    if (token) {
      this.token = token;
    }
    const chapterId = this.props.navigation.getParam("chapterId");
    if (chapterId) {
      this.chapterId = chapterId;
      this.getReview(chapterId);
      this.getSubjects();
    }
  };

  // Customizable Area Start
  getReview = (chapterId: string) => {
    const headers = {
      token: this.token,
    };

    const getResultsMessageReview = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getResultsMessageReviewId = getResultsMessageReview.messageId;

    getResultsMessageReview.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${chapterId}/review_test`
    );

    getResultsMessageReview.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getResultsMessageReview.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getResultsMessageReview.id, getResultsMessageReview);
  };

  getSubjects = async () => {
    const urlSearch = this.props.navigation.history.location.search;
    const packageId = extractParams(urlSearch, "packageId");
    const getSubjectsMessageRC: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    this.getSubjectsMessageRCId = getSubjectsMessageRC.messageId;

    getSubjectsMessageRC.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${packageId}/get_subjects`
    );

    getSubjectsMessageRC.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectsMessageRC.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSubjectsMessageRC.id, getSubjectsMessageRC);
  };

  handleSuccessSubject = (
    response: {
      id: string;
      attributes: { name: string };
    }[]
  ) => {
    const subjectId = this.props.navigation.getParam("subjectId");
    for (const data of response) {
      if (data.id === subjectId) {
        const breadcrumb = this.breadcrumb;
        breadcrumb[1].label = data.attributes.name;

        const propPassingReviewTest = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingReviewTest.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingReviewTest);

        this.setState({
          loading: false,
        });
      }
    }
  };
  // Customizable Area End
}
