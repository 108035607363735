import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

export type AppointmentT = {
  id: string;
  type: string;
  attributes: {
    id: number;
    student_name: string;
    parent_name: string;
    mobile_number: string;
    email: string;
    slot_date: string;
    time_slot: string;
    cm_id: string;
    cm_officer_id: string;
    created_by: string;
    group: string;
    status: string;
    slot_type: string;
    zoom_url: string;
    comment: string;
    image_url: string;
    remarks: string;
    rating: number;
    package: Array<unknown>;
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  numberToLoad: number;
  loading: boolean;
  pendingData: AppointmentT[];
  upcomingData: AppointmentT[];
  todayData: AppointmentT[];
  cacheData: {
    pendingData: AppointmentT[];
    upcomingData: AppointmentT[];
    todayData: AppointmentT[];
  };
  searchInput: string;
  currentPageUpdating: number;
  currentPageUpcoming: number;
  currentPageToday: number;
  itemsPerPage: number;
  isSlotUpdatedModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUpcomingMessageId = "";
  getPendingMessageId = "";
  getTodayMessageId = "";
  putCancelSlotMessageId = "";
  status = [
    { value: "first_presentation", label: "First Presentation" },
    { value: "follow_up", label: "Follow up" },
    { value: "rejected", label: "Rejected" },
    { value: "registration_done", label: "Registration Done" },
  ];
  breadcrumb = [
    {
      label: "Book my Slot",
      link: this.props.navigation.history.location.pathname,
    },
    {
      label: "Slots",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      numberToLoad: 0,
      pendingData: [],
      todayData: [],
      upcomingData: [],
      searchInput: "",
      currentPageUpdating: 1,
      currentPageUpcoming: 1,
      currentPageToday: 1,
      itemsPerPage: 2,
      cacheData: {
        pendingData: [],
        upcomingData: [],
        todayData: [],
      },
      isSlotUpdatedModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getPendingMessageId && response) {
      this.handlePendingData(response);
    }

    if (apiRequestCallId === this.getTodayMessageId && response) {
      if (response.data) {
        this.handleTodayData(response.data);
      }
    }

    if (apiRequestCallId === this.getUpcomingMessageId && response) {
      if (response.data) {
        this.handleUpcomingData(response.data);
      }
    }

    if (apiRequestCallId === this.putCancelSlotMessageId) {
      if (response?.data) {
        this.successPostMessage();
      }
    }

    if (this.state.numberToLoad === 0) {
      this.setState({
        loading: false,
      });
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getUpcoming();
    this.getPending();
    this.getToday();

    const propPassing = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassing.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassing);
  };

  componentDidUpdate = (_prevProps: Props, _prevState: S) => {
    const { searchInput } = this.state;

    if (searchInput.length > 0 && searchInput !== _prevState.searchInput) {
      this.handleSearchOnAllAppointments();
    }

    if (searchInput.length === 0 && _prevState.searchInput.length > 0) {
      this.handleUseCacheData();
    }
  };

  // Customizable Area Start
  successPostMessage = () => {
    this.handleIsSlotUpdatedModal();
    this.getUpcoming();
    this.getPending();
    this.getToday();
  };

  handleUseCacheData = () => {
    this.setState({
      ...this.state.cacheData,
    });
  };

  handleSearchOnAllAppointments = () => {
    const { upcomingData, todayData, pendingData } = this.state.cacheData;
    const searchTerm = this.state.searchInput;

    const filterAppointments = (appointments: AppointmentT[]) => {
      return appointments.filter((appointment) => {
        const { student_name, parent_name, group, email } =
          appointment.attributes;
        return (
          student_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          parent_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          group.toLowerCase().includes(searchTerm.toLowerCase()) ||
          email.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    };

    const filteredPendingData = filterAppointments(pendingData);
    const filteredUpcomingData = filterAppointments(upcomingData);
    const filteredTodayData = filterAppointments(todayData);

    this.setState({
      upcomingData: filteredUpcomingData,
      pendingData: filteredPendingData,
      todayData: filteredTodayData,
    });
  };
  handleSearch = (searchInput: string) => {
    this.setState({
      searchInput,
    });
  };

  navigateToEdit = (slotId: string) => {
    this.props.navigation.history.push(
      `/dashboard/book-my-slot/add-edit-slot?slotId=${slotId}&edit=true`
    );
  };

  handlePendingData = (responseData: { data: AppointmentT[] }) => {
    if (responseData?.data) {
      this.setState({
        pendingData: responseData.data,
        numberToLoad: this.state.numberToLoad - 1,
        cacheData: {
          ...this.state.cacheData,
          pendingData: responseData.data,
        },
      });
    }
  };

  handleUpcomingData = (responseData: AppointmentT[]) => {
    this.setState({
      upcomingData: responseData,
      numberToLoad: this.state.numberToLoad - 1,
      cacheData: {
        ...this.state.cacheData,
        upcomingData: responseData,
      },
    });
  };

  handleTodayData = (responseData: AppointmentT[]) => {
    this.setState({
      todayData: responseData,
      numberToLoad: this.state.numberToLoad - 1,
      cacheData: {
        ...this.state.cacheData,
        todayData: responseData,
      },
    });
  };

  navigateToSlotBookings = () => {
    this.props.navigation.navigate("BookMySlotBookings");
  };

  getUpcoming = async () => {
    this.setState({
      loading: true,
      numberToLoad: this.state.numberToLoad + 1,
    });
    const getUpcomingMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getUpcomingMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/cms_bookeds/upcoming_stots`
    );
    this.getUpcomingMessageId = getUpcomingMessage.messageId;
    getUpcomingMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getUpcomingMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getUpcomingMessage.id, getUpcomingMessage);
  };

  getToday = async () => {
    this.setState({
      loading: true,
      numberToLoad: this.state.numberToLoad + 1,
    });
    const getTodayMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getTodayMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/cms_bookeds/today_stots`
    );
    this.getTodayMessageId = getTodayMessage.messageId;
    getTodayMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getTodayMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getTodayMessage.id, getTodayMessage);
  };

  getPending = async () => {
    this.setState({
      loading: true,
      numberToLoad: this.state.numberToLoad + 1,
    });
    const getPendingMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getPendingMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/cms_bookeds/pending_stots`
    );
    this.getPendingMessageId = getPendingMessage.messageId;
    getPendingMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPendingMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getPendingMessage.id, getPendingMessage);
  };

  navigateToAppointments = () => {
    this.props.navigation.navigate("BookMySlotAppointments");
  };

  handlePageChangeUpcoming = (value: number) => {
    this.setState({
      currentPageUpcoming: value,
    });
  };

  handlePageChangeToday = (value: number) => {
    this.setState({
      currentPageToday: value,
    });
  };

  handlePageChangeUpdating = (value: number) => {
    this.setState({
      currentPageUpdating: value,
    });
  };

  cancelSlot = async (slotId: string) => {
    const putCancelSlotMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const body = {
      data: {
        slot_type: "cancel_slot",
        comment: "",
      },
    };

    this.putCancelSlotMessageId = putCancelSlotMessage.messageId;

    putCancelSlotMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/cms_appoinments/${slotId}`
    );

    putCancelSlotMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    putCancelSlotMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    putCancelSlotMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(putCancelSlotMessage.id, putCancelSlotMessage);
  };

  handleIsSlotUpdatedModal = () => {
    this.setState({
      isSlotUpdatedModal: !this.state.isSlotUpdatedModal,
      loading: false,
    });
  };
  // Customizable Area End
}
