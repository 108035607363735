import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  topics: {
    id: string;
    attributes: { name: string; subject: { name: string } };
  }[];
  loading: boolean;
  showModalSubmit: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GuestQbTopicsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTopicsMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      topics: [],
      showModalSubmit: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getTopicsMessageId && response) {
      if (response?.data) {
        this.setState({
          topics: response.data,
        });
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const subjectId = this.props.navigation.getParam("subjectId");
    this.getTopics(subjectId);
  };

  // Customizable Area Start
  getTopics = async (subjectId: string) => {
    this.setState({
      loading: true,
    });
    const getTopicsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      uuid: await storage.get("guestToken"),
    };
    getTopicsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/${subjectId}/qb_chapters`
    );
    this.getTopicsMessageId = getTopicsMessage.messageId;
    getTopicsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getTopicsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getTopicsMessage.id, getTopicsMessage);
  };

  navigateTo = (location: string) => {
    this.props.navigation.history.push(location);
  };

  showModalSubmit = () => {
    this.setState({
      showModalSubmit: !this.state.showModalSubmit,
    });
  };

  navigateToRegister = () => {
    this.props.navigation.history.push("/register");
  };
  // Customizable Area End
}
