export const imgPasswordVisible = require('../assets/ic_password_visible.png')
export const imgPasswordInVisible = require('../assets/ic_password_invisible.png')
export const forwardArrowIcon = require('../assets/forwordArrow.png')
export const arrowIcon = require('../assets/arrowIcon.png')
export const closeIcon = require('../assets/close.png')
export const tickIcon = require('../assets/tick.png')
export const paymentSuccessful = require('../assets/verification.png')
export const user = require('../assets/user.png')
export const bookOne = require('../assets/bookOne.png')
export const bookTwo = require('../assets/bookTwo.png')
export const myranklogo = require("../assets/myranklogo.png")  
export const mainLoginImage = require("../assets/ayqlogin.png");
export const party = require("../assets/party.png");
export const myRankLogo = require("../assets/myrank_svg-logo.png");
export const bgLoginMain = require("../assets/login.png");
export const bgAYQ = require("../assets/stacked-waves-haikei.png");
export const toggle = require("../assets/toggle.png");
export const searchImg = require("../assets/searchImg.png");
export const backgroundImg = require("../assets/background.jpg");
export const packages = require("../assets/packages.png");
export const questionBank = require("../assets/questionBank.png");
export const synopsis = require("../assets/synopsis.png");
export const analysis = require("../assets/analysis.png");
export const practiceSet = require("../assets/practiceSet.png");
export const hallTicket = require("../assets/hallTicket.png");
export const btnView = require("../assets/btnView.png");
export const rightIcon = require("../assets/submitIcon.png");
export const ButtonBG = require('../assets/button_filled.png');
export const arrowCircle = require("../assets/arrowCircle.png");
export const linearButton = require("../assets/button_filled.png");
export const verifiedIcon = require("../assets/icon_copy.png");
export const courseOne = require("../assets/courseOne.png");
export const backButtonIcon = require("../assets/backIcon.png");
export const droupDown = require("../assets/arrow-circle-right-copy-6.png");
export const checkedBox = require("../assets/checkedBox.png");
export const uncheckedBox = require("../assets/uncheckedBox.png");
export const blueBackground = require("../assets/List_Copy_2.png");
export const purpleBackground = require("../assets/list_3.png");
export const darkBlueBackground = require("../assets/list_2.png");
export const backImg = require("../assets/back.png");
