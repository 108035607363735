import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography as TypoGraphyMUI,
  Button,
  Grid,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import DateRangeIcon from "@material-ui/icons/DateRange";
import BannerWeb from "../../../components/src/Banner.web";
import Loader from "../../../components/src/Loader.web";
import { Physics, emptyStar, starIcon } from "./assets";
import { boxStyles } from "./BookMySlot.web";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { webStyle } from "./BookMySlotAddEditSlot.web";
import { AppointmentT } from "./BookMySlotStudentAppointmentsController.web";
// Customizable Area End

import BookMySlotFacultyController, {
  Props,
  configJSON,
} from "./BookMySlotFacultyController.web";

class BookMySlotFaculty extends BookMySlotFacultyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  UpcomingAppointmentsPage = () => {
    return <Box>{this.RenderUpcomingAppointments()}</Box>;
  };
  PreviousBookingsPage = () => {
    return <Box>{this.RenderPreviousBookings()}</Box>;
  };
  RenderAppointmentBox = (data: AppointmentT) => {
    return (
      <Grid item key={`notifs-${data.id}`} sm={12}>
        <Box className={this.props.classes.appointmentBox}>
          <Box className="details-appointment">
            <Box display={"flex"} alignItems={"center"} gridGap={"0.3em"}>
              <img src={Physics} alt={"profileImage"} />
              <TypoGraphyMUI style={{ fontWeight: 500, marginLeft: "1em" }}>
                {data.attributes.account.data.attributes.name}
              </TypoGraphyMUI>
            </Box>
            <Box className="text-appointment-headers">
              <TypoGraphyMUI>
                <span>{configJSON.subjectTitle} -</span>{" "}
                {data.attributes.bms_slot.slot_subject}
              </TypoGraphyMUI>
              <TypoGraphyMUI>
                <span>Chapter -</span>{" "}
                {data.attributes.chapter.data.attributes.name}
              </TypoGraphyMUI>
              <TypoGraphyMUI>
                <span> Topic -</span>{" "}
                {data.attributes.topic.data.attributes.name}
              </TypoGraphyMUI>
              <Box display={"flex"}>
                <TypoGraphyMUI>
                  <span>Zoom Link - </span>
                </TypoGraphyMUI>
                <a href={data.attributes.meet_url}>
                  {data.attributes.meet_url}
                </a>
              </Box>
              <TypoGraphyMUI>
                <span>
                  {" "}
                  Date and Time : {data.attributes.bms_slot.slot_date},{" "}
                  {data.attributes.bms_slot.slot_start}-
                  {data.attributes.bms_slot.slot_end}
                </span>
              </TypoGraphyMUI>
            </Box>
            <Button
              className="summary"
              data-test-id="summaryCheck"
              onClick={() =>
                this.updateCurrentDoubt(
                  data.attributes.bms_query
                )
              }
            >
              Summary <ChevronRightIcon className="right-icon" />
            </Button>
          </Box>
        </Box>
      </Grid>
    );
  };
  RenderPreviousBox = (data: AppointmentT) => {
    const rating = data.attributes.student_rating
      ? data.attributes.student_rating - 1
      : -1;

    return (
      <Grid key={`notifs-${data.id}`} sm={12} item>
        <Box className={this.props.classes.appointmentBox}>
          <Box className="details-appointment" position={"relative"}>
            <Box display={"flex"} alignItems={"center"}>
              <img src={Physics} alt={"physics-image"} />
              <TypoGraphyMUI
                className="account-name"
                style={{ fontWeight: 500, marginLeft: "1em" }}
              >
                {data.attributes.account.data.attributes.name}
              </TypoGraphyMUI>
              <Box>
                <TypoGraphyMUI
                  className="date-time"
                  style={{
                    fontWeight: 500,
                    marginLeft: "1em",
                    color: "#909090",
                  }}
                >
                  Date and Time : {data.attributes.bms_slot.slot_date},{" "}
                  {data.attributes.bms_slot.slot_start}-
                  {data.attributes.bms_slot.slot_end}
                </TypoGraphyMUI>
              </Box>
            </Box>
            <Box position={"relative"}>
              <Box className="text-appointment-headers" position={"relative"}>
                <TypoGraphyMUI>
                  <span>{configJSON.subjectTitle} -</span>{" "}
                  {data.attributes.bms_slot.slot_subject}
                </TypoGraphyMUI>
                <TypoGraphyMUI>
                  <span>Chapter -</span>{" "}
                  {data.attributes.chapter.data.attributes.name}
                </TypoGraphyMUI>
                <TypoGraphyMUI>
                  <span> Topic -</span>{" "}
                  {data.attributes.topic.data.attributes.name}
                </TypoGraphyMUI>
              </Box>
            </Box>
          </Box>
          <Box className="buttons-appointment">
            <Button className="status">
              Status: {data.attributes.bms_slot.slot_status}
            </Button>
          </Box>
          <Box marginTop={"1.3em"}>
            <Box display={"flex"} gridGap={"1em"}>
              <TypoGraphyMUI className={this.props.classes.typographyStyle}>
                Feedback-{" "}
                <Box
                  display={"inline-block"}
                  onClick={() => this.navigateToFeedbacks(data.id)}
                  style={{ cursor: "pointer" }}
                >
                  {Array.from(Array(5).keys()).map((_, index) => {
                    return (
                      <>
                        {rating >= index ? (
                          <img
                            width={"20px"}
                            style={{ cursor: "pointer" }}
                            src={starIcon}
                            alt="filled"
                          />
                        ) : (
                          <img
                            width={"20px"}
                            data-test-id="rate-btn-star"
                            src={emptyStar}
                            alt="empty"
                          />
                        )}
                      </>
                    );
                  })}
                </Box>
              </TypoGraphyMUI>
            </Box>
            <Box display={"flex"} gridGap={"1em"}>
              <TypoGraphyMUI className={this.props.classes.typographyStyle}>
                Remarks - <span>{data.attributes.fac_feedback}</span>
              </TypoGraphyMUI>
              <TypoGraphyMUI></TypoGraphyMUI>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  };

  RenderUpcomingAppointments = () => {
    const appointments = this.state.upcomingAppointmentsData;
    if (appointments.length === 0) {
      return <TypoGraphyMUI>There are no upcoming appointments</TypoGraphyMUI>;
    }

    return (
      <Grid container direction="row" alignItems="center" spacing={4}>
        {appointments.map((appointment) => {
          return this.RenderAppointmentBox(appointment);
        })}
      </Grid>
    );
  };

  RenderPreviousBookings = () => {
    const appointments = this.state.previousAppointmentsData;

    if (appointments.length === 0) {
      return <TypoGraphyMUI>There are no previous bookings</TypoGraphyMUI>;
    }

    return (
      <Grid container direction="row" alignItems="center" spacing={4}>
        {appointments.map((appointment) => {
          return this.RenderPreviousBox(appointment);
        })}
      </Grid>
    );
  };
  PageDeterminer = (pageIndex: number) => {
    if (pageIndex === 0) {
      return this.UpcomingAppointmentsPage();
    }

    return this.PreviousBookingsPage();
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Modal
          open={this.state.isModalDoubt}
          onClose={this.handleIsModalDoubtModal}
          data-test-id="modal-slot-booked"
        >
          <Box sx={webStyle.modalStyle}>
            <TypoGraphyMUI>{this.state.currentDoubt}</TypoGraphyMUI>
            <Button
              style={webStyle.buttonStyle}
              onClick={this.handleIsModalDoubtModal}
              data-test-id="homebtn"
            >
              Close
            </Button>
          </Box>
        </Modal>
        <Box>
          <Box position="relative">
            <BannerWeb title="Faculty Bookings" />
            <Box className={this.props.classes.iconsContainer}>
              <Box
                onClick={() => this.navigateToSlotBookings()}
                data-test-id="goToSlotBooking"
                className={this.props.classes.iconBtn}
              >
                <Box>
                  <DateRangeIcon />
                </Box>
                <TypoGraphyMUI>Slots</TypoGraphyMUI>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box className={this.props.classes.navigationBox}>
              <Button
                onClick={() => this.pageChange(0)}
                className={this.state.pageIndex === 0 ? "active" : ""}
                data-test-id="button-upcoming"
              >
                Upcoming Appointments
              </Button>
              <Button
                onClick={() => this.pageChange(1)}
                className={this.state.pageIndex === 1 ? "active" : ""}
                data-test-id="button-previous"
              >
                Previous Appointments
              </Button>
            </Box>
            <Box padding={"2em"}>
              {this.PageDeterminer(this.state.pageIndex)}
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleBookMySlotFaculty = () =>
  createStyles({
    appointmentBox: {
      ...boxStyles,
      "& .details-appointment": {
        gap: "1em",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        "& span": {
          color: "#909090!important",
        },
        "& img": {
          width: "40px",
          borderRadius: "50%",
          height: "40px",
        },
        "& .summary": {
          position: "absolute",
          right: "5%",
          top: "40%",
          "& .right-icon": {
            background:
              "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
            color: "white",
            borderRadius: "50%",
            cursor: "pointer",
            marginLeft: "0.3em",
          },
        },
        "& .text-appointment-headers": {
          flexDirection: "column",
          display: "flex",
          gap: "1.2em",
          "& p": {
            fontWeight: 500,
            display: "inline",
          },
          "& a": {
            display: "inline",
          },
        },
        "& .text-appointment-description-dots": {
          marginRight: "1em",
          display: "flex",
          flexDirection: "column",
          gap: "1.2em",
        },
        "& .text-appointment-description": {
          display: "flex",
          gap: "1.2em",
          flexDirection: "column",
        },
      },
    },
    typographyStyle: {
      fontWeight: 500,
      fontSize: "1.1em",
    },
    navigationBox: {
      display: "flex",
      marginTop: "2em",
      justifyContent: "center",
      alignItems: "center",
      gap: "1em",
      "& button": {
        width: "300px",
        border: "1px solid #FF3A3A",
        borderRadius: "0.5em",
        fontWeight: 500,
        color: "#222",
        fontSize: "1.2em",
        height: "50px",
      },
      "& .active": {
        border: "none",
        background: "#CBCDFF",
      },
    },
    iconBtn: {
      color: "rgb(82, 0, 130)",
      cursor: "pointer",
      flexDirection: "column",
      display: "flex",
      position: "absolute",
      justifyContent: "center",
      right: "5%",
      top: "7%",
      alignItems: "center",
      "& p": { fontWeight: 500 },
      "& div": {
        background: "rgb(82, 0, 130, 0.2)",
        border: "none",
        padding: "0.6em",
        position: "relative",
        borderRadius: "50%",
      },
    },
  });
export default withStyles(webStyleBookMySlotFaculty)(BookMySlotFaculty);
export { BookMySlotFaculty };
// Customizable Area End
