import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
export type ChapterType = {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    image: string;
    practice_test: string;
    avail_pt: string;
    date_pt: string | null;
    class_zone: string;
    avail_cz: string;
    date_cz: string | null;
    question_bank: string;
    avail_qb: string;
    date_qb: string | null;
    created_by: string;
    remarks: string;
    search_key: string | null;
    chapter_read: boolean;
    viewed: boolean;
    continue_reading_topic_id: string | null;
    test_attempted: boolean;
    subject: {
      id: number;
      created_at: string;
      updated_at: string;
      name: string;
      created_by: string;
      class_zone: string;
      practice_test: string;
      question_bank: string;
      subjective_test: string;
      st_code: string;
      remarks: string;
      icon_code: string;
      st_db: string;
      score_db: string;
      chapter_db: string;
    };
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  chapters: ChapterType[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GuestPracticeTestChaptersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getChaptersMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      chapters: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getChaptersMessageId && response) {
      if (response?.data) {
        this.handleChapters(response.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const subjectId = this.props.navigation.getParam("subjectId");
    this.getChapters(subjectId);
  };

  // Customizable Area Start
  getChapters = async (subjectId: string) => {
    const getChaptersMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      uuid: await storage.get("guestToken"),
    };

    this.setState({
      loading: true,
    });

    this.getChaptersMessageId = getChaptersMessage.messageId;

    getChaptersMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/guest_practice_tests/${subjectId}${configJSON.guestflowEndPoint}`
    );

    getChaptersMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getChaptersMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getChaptersMessage.id, getChaptersMessage);
  };

  navigateTo = (navigateTo: string) => {
    this.props.navigation.history.push(navigateTo);
  };

  handleChapters = (response: ChapterType[]) => {
    this.setState({
      chapters: response,
    });
  };
  // Customizable Area End
}
