import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { WithStyles } from "@material-ui/core";
import { webStyleGrandTestReviewTest } from "./GrandTestReviewTest.web";
export interface Root {
  data: Root2[];
}

export interface Root2 {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  gt_review_id: number;
  grand_test_id: number;
  test_name: string;
  image_url: string;
  attempted_at: string;
}

interface BreadcrumbType {
  label: string;
  link: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleGrandTestReviewTest> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  review_histories_grand: Root2[];
  subjectId: string;
  loading: boolean;
  breadcrumb: BreadcrumbType[];
  testName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GrandTestReviewTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getReviewHistoriesWeeklyMessageId = "";
  getPackageReviewMessageId = "";
  getAttemptsMessageId = "";
  breadcrumb = [
    {
      label: "Grand Test",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}`,
    },
    {
      label: "Chapters",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}`,
    },
    {
      label: "Reviews",
      link: this.props.navigation.history.location.pathname,
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Reviews",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      review_histories_grand: [],
      subjectId: "",
      loading: false,
      breadcrumb: this.breadcrumb,
      testName: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getReviewHistoriesWeeklyMessageId) {
      if (response.data) {
        this.handleSuccessFetchHistories(response.data);
      }
    }

    if (apiRequestCallId === this.getPackageReviewMessageId) {
      if (response.data) {
        const packageId = this.props.navigation.getParam("packageId");
        response.data.forEach(
          (data: { gt_code: string; package_name: string }) => {
            if (data.gt_code === packageId) {
              const breadcrumb = this.state.breadcrumb;
              breadcrumb[1] = {
                ...breadcrumb[1],
                label: data.package_name,
              };
              this.setState(
                {
                  breadcrumb,
                },
                () => {
                  const propPassing = new Message(
                    getName(MessageEnum.NavigationBreadcrumbMessage)
                  );
                  propPassing.addData(
                    getName(MessageEnum.BreadcrumbDataMessage),
                    breadcrumb
                  );
                  this.send(propPassing);
                  this.setState({
                    loading: false,
                  });
                }
              );
            }
          }
        );
      }
    }

    if (apiRequestCallId === this.getAttemptsMessageId) {
      if (response.attempted) {
        this.handleSuccessfulFetch(response);
      }
    }
    // Customizable Area End
  }

  // web events
  handleSuccessFetchHistories = (review_histories_grand: Root2[]) => {
    const breadcrumb = this.state.breadcrumb;
    breadcrumb[2] = {
      ...breadcrumb[2],
      label: review_histories_grand[0].attributes.test_name,
    };
    this.setState({
      review_histories_grand,
      loading: false,
    });
  };

  handleSuccessfulFetch = (response: {
    attempted: { data: { attributes: { test_name: string } }[] };
    unattempted: { data: { attributes: { test_name: string } }[] };
  }) => {
    let testName = "";
    if (response.unattempted.data.length > 0) {
      testName = response.unattempted.data[0].attributes.test_name;
    }

    if (response.attempted.data.length > 0) {
      testName = response.attempted.data[0].attributes.test_name;
    }

    const breadcrumb = this.state.breadcrumb;
    breadcrumb[2] = { ...breadcrumb[2], label: testName };

    const propPassing = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassing.addData(getName(MessageEnum.BreadcrumbDataMessage), breadcrumb);
    this.send(propPassing);

    this.setState({
      testName,
      breadcrumb,
      loading: false,
    });
  };

  componentDidMount = async () => {
    const subjectId = this.props.navigation.getParam("subjectId");
    const packageId = this.props.navigation.getParam("packageId");
    this.getAttempts(packageId);
    this.subjectIdFetched(subjectId);
    this.getPackage();
  };

  subjectIdFetched = (subjectId: string) => {
    this.setState(
      {
        subjectId,
      },
      () => {
        this.getReviewHistoriesWeekly(this.state.subjectId);
      }
    );
  };

  convertToDate = (timestamp: string) => {
    const date = new Date(timestamp);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  // Customizable Area Start
  getReviewHistoriesWeekly = async (subjectId: string) => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getReviewHistoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReviewHistoriesWeeklyMessageId = getReviewHistoryMessage.messageId;

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/${subjectId}/review_histories`
    );

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getReviewHistoryMessage.id, getReviewHistoryMessage);
  };

  getPackage = async () => {
    const getPackageReviewMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getPackageReviewMessageId = getPackageReviewMessage.messageId;

    getPackageReviewMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getPackageReviewMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/get_packages`
    );

    getPackageReviewMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getPackageReviewMessage.id, getPackageReviewMessage);
  };

  getAttempts = async (packageId: string) => {
    const getAttemptsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    getAttemptsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    this.getAttemptsMessageId = getAttemptsMessage.messageId;

    getAttemptsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAttemptsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests?gt_code=${packageId}`
    );


    runEngine.sendMessage(getAttemptsMessage.id, getAttemptsMessage);
  };
  // Customizable Area End
}
