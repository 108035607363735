export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const submitIcon = require("../assets/submitIcon.png");
export const saveImg = require("../assets/saveImg.png");
export const bookmarkImg = require("../assets/bookrmarkImg.png");
export const report = require("../assets/icons8-warning-24.png");
export const star = require("../assets/icons8-star-48.png");
export const edit = require("../assets/edit-2.png");
export const deleteImg = require("../assets/trash.png");
export const addCircle = require("../assets/add_circle.png");
export const starFillIcon = require("../assets/starIcon.png");
export const starEmptyIcon = require("../assets/emptyStar.png");
export const nosavednotes = require("../assets/nosavednotes.png");
export const clock = require("../assets/clock.png");
export const btnView = require("../assets/btnView.png");
export const toggle = require("../assets/toggle.png");
export const searchImg = require("../assets/searchImg.png");
export const backgroundImg = require("../assets/background.jpg");
export const packages = require("../assets/packages.png");
export const questionBank = require("../assets/questionBank.png");
export const synopsis = require("../assets/synopsis.png");
export const analysis = require("../assets/analysis.png");
export const practiceSet = require("../assets/practiceSet.png");
export const hallTicket = require("../assets/hallTicket.png");
export const rightIcon = require("../assets/submitIcon.png");
export const ButtonBG = require('../assets/button_filled.png');
export const arrowCircle = require("../assets/arrowCircle.png");
export const backImg = require("../assets/back.png");
export const PhysicsImg = require("../assets/PhysicsImg.png");
export const timerImg = require("../assets/timerImg.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const button = require("../assets/button_filled.png");
export const greenSubmit = require('../assets/icon_copy.png');
export const bookrmarkImg = require('../assets/bookrmarkImg.png');
export const more = require("../assets/more.png");
export const AddButton = require("../assets/add_circle.png");
export const WarningButton = require("../assets/icons8-warning-24.png");
export const StarButton = require("../assets/icons8-star-48.png");
export const starIcon = require("../assets/starIcon.png");
export const emptyStar = require("../assets/emptyStar.png");
export const savedNotes = require("../assets/saveImg.png");
export const EditNotes = require("../assets/edit-2.png");
export const DeleteNotes = require("../assets/trash.png");
export const verifiedIcon = require('../assets/icon_copy.png')



