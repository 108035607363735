import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
export interface IGTPackage {
  id: number;
  package_name: string;
  created_at: string;
  updated_at: string;
  package_description: string;
  class_zone: string;
  practice_test: string;
  subjective_test: string;
  status: string;
  subject_id: string;
  gt_code: string;
  question_bank: string;
  created_by: string;
  image_url: string;
}

export type TGrandTestStat = {
  exam_name: string;
  exam_wise: Array<{
    grand_test: string;
    number_of_attempts: number;
    incorrect_answer: number;
    correct_answer: number;
    unattended: number;
  }>;
  exams_overall: {
    correct_answer: number;
    incorrect_answer: number;
    exam_wise_overall_attempts: number;
    unattended: number;
  };
  subject_wise: Array<{
    no_of_attempts: number;
    correct_answer: number;
    incorrect_answer: number;
    subject_name: string;
    unattended: number;
    total_questions: number;
  }>;
  subject_overall: {
    correct_answer: number;
    incorrect_answer: number;
    total_attempts: number;
    unattended: number;
  };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedChoice: string;
  selectedPackage: string;
  packages: IGTPackage[];
  stats: TGrandTestStat | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UsageStatsGrandTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGrandTestStatsMessageId = "";
  getGrandTestPackageMessageId = "";
  buttons = [
    "Synopsis",
    "Weekly Test",
    "Grand Test",
    "Practice Test",
    "Live Classes",
    "Recorded Classes",
    "Question Bank",
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      selectedChoice: "",
      selectedPackage: "",
      packages: [],
      stats: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getGrandTestPackageMessageId && response) {
      if (response?.data) {
        this.handleSuccessfulFetchPackages(response.data);
      }
    }

    if (apiRequestCallId === this.getGrandTestStatsMessageId && response) {
      if (response?.exam_name) {
        this.handleSuccessfulFetchStats(response);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getGrandTestPackage();
  };

  // Customizable Area Start

  getGrandTestPackage = async () => {
    const getGrandTestPackageMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getGrandTestPackageMessageId = getGrandTestPackageMessage.messageId;

    getGrandTestPackageMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getGrandTestPackageMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/get_packages`
    );

    getGrandTestPackageMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getGrandTestPackageMessage.id,
      getGrandTestPackageMessage
    );
  };
  handleSuccessfulFetchPackages = (response: IGTPackage[]) => {
    this.setState({
      packages: response,
      loading: false,
    });
  };

  getGrandTestStats = async (gtCode: string) => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getGrandTestStatsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGrandTestStatsMessageId = getGrandTestStatsMessage.messageId;

    getGrandTestStatsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.grandTestApiEndpoint + `?gt_code=${gtCode}`
    );

    getGrandTestStatsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getGrandTestStatsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getGrandTestStatsMessage.id,
      getGrandTestStatsMessage
    );
  };
  handleSuccessfulFetchStats = (response: TGrandTestStat) => {
    this.setState({
      stats: response,
    });
  };

  onChangeSelectSubject = (selectedChoice: string) => {
    this.setState({
      selectedChoice,
    });
  };

  onChangeSelectPackage = (selectedPackage: string) => {
    this.setState(
      {
        selectedPackage,
      },
      () => {
        this.getGrandTestStats(selectedPackage);
      }
    );
  };
  // Customizable Area End
}
