import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface IMegaMenuHeaders {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    icon: string;
    created_at: string;
    updated_at: string;
    exams: {
      data: Array<{
        id: string;
        type: string;
        attributes: {
          id: number;
          name: string;
          content: string;
          icon: string;
          created_at: string;
          updated_at: string;
        };
      }>;
    };
  };
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isOpen: boolean;
  toggleShowGuidance: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  headersWithExams: Array<IMegaMenuHeaders>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MegamenuLandingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMegaMenuHeadersMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      headersWithExams: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getMegaMenuHeadersMessageId && response) {
      if (response?.data) {
        this.successfulMegaMenuHeadersFetch(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getMegaMenuHeaders();
  };

  // Customizable Area Start
  getMegaMenuHeaders = async () => {
    const getMegaMenuHeadersMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMegaMenuHeadersMessageId = getMegaMenuHeadersMessage.messageId;

    getMegaMenuHeadersMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/megamenus`
    );

    getMegaMenuHeadersMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getMegaMenuHeadersMessage.id,
      getMegaMenuHeadersMessage
    );
  };
  successfulMegaMenuHeadersFetch = (data: Array<IMegaMenuHeaders>) => {
    this.setState({
      headersWithExams: data,
    });
  };

  navigateToMenuContents = (menuId: string, examId: string) => {
    this.props.navigation.history.push(
      `/guidance-content?menuId=${menuId}&examId=${examId}`
    );
  };
  // Customizable Area End
}
