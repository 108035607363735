Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.chapteroverAll = "Chapter overall"
exports.examoverAll = "Exam overAll"
exports.examVise = "Exam Vise"
exports.testTaken = "Test Taken"
exports.chapterWise = "Chapter Wise"
exports.praticesTestUsgeStatsEndPoint = "/bx_block_assessmenttest/practice_tests/practice_usage_stats"
exports.GrandTestExamOverallEndPoint = "/bx_block_assessmenttest/exams_overall"
exports.weeklyTestUsageStatsEndPoint = "/bx_block_assessmenttest/weekly_tests/weekly_usage_stats"
exports.ChapterpverView = "Chapter Overview"
exports.subjectOverView = "Subject Overview"
exports.synopsisUsageStatsEndPoint = "/bx_block_assessmenttest/synopsis/synopsis_usage_stats"
exports.usageStats = "Usage stats"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "UsageInsights Login";
exports.labelBodyText = "UsageInsights Registration";
exports.userName = "User Name";
exports.liveClassesEndPoint = "/bx_block_assessmenttest/user_live_classes/faculty_wise_count"
exports.Email = "User Email";
exports.Pass = "Enter Password";
exports.ConfirmPass = "Enter ConfirmPassword";
exports.UserEmail = "User Email";
exports.UserPass = "User Password";
exports.SearchBar = "Search User";
exports.Registration = "SignUp";
exports.LogIn = "LogIn"
exports.Token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NzU0LCJleHAiOjE3MDk5NzAwOTEsInRva2VuX3R5cGUiOiJsb2dpbiJ9.j-7wecH1KiRvhdHSRoMv9gbzclRqKXF3MjHj7wk3IBMuP6GywPqhFMyknSqIBoNJ0MHoRhcZyAyOKW9NZB_M3g"
exports.allSubject = "all_subjects"
exports.selectSujects = "Select Subject to show stats"
exports.btnExampleTitle = "Submit";
exports.weeklyTestEndpoint = "bx_block_assessmenttest/weekly_tests/get_tests?sort_by=test_name"
exports.subjectNotSelected = "SUBJECT : NOT SELECTED"
exports.grandTestApiEndpoint = "bx_block_assessmenttest/grand_test_usage_stats"
exports.getPackagesPracticeTest = "bx_block_assessmenttest/practice_tests/get_packages"
exports.liveClassPreviousClasses = "bx_block_scheduling/live_classes/previous_classes"
exports.usageStatsQB = "/bx_block_usageinsights/usage_stats/question_bank_usage_stats?sub_name={subjectName}";
exports.questionBankSub = "bx_block_questionbank/question_banks/qb_subjects"
exports.liveClassStats = "/bx_block_usageinsights/usage_stats/live_class_usage_stats?sub_name={subName}";
exports.synopsisSubjectListApi = "/all_subjects";
exports.inSubject = "In Subject";
exports.practiceTestChap = "/bx_block_assessmenttest/practice_tests/{packageID}/get_subjects"
exports.practiceTestTopic = "/bx_block_assessmenttest/practice_tests/{chapterID}/get_chapters"
// Customizable Area End