import React from "react";

import {
  Box,
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";

export default class PackageSelection extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className="package-select-container">
        <h2>You need to select course to continue with registration</h2>
        <Box className="signup-package-select">
          {this.props.allPackages &&
            this.props.allPackages.map((packages, packageIndex) => {
              return (
                <Box key={`${packageIndex}-select`}>
                  <p>{packages.attributes.pack_name}</p>
                  <Button
                    data-test-id="getPricingBtn"
                    onClick={() =>
                      this.props.getPackageDetails &&
                      this.props.getPackageDetails(packages.id)
                    }
                  >
                    Pricing
                  </Button>
                  <img
                    src={packages.attributes.image}
                    alt={packages.attributes.pack_name}
                  />
                </Box>
              );
            })}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
