import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

type CourseAllDataType = {
  data: Array<CourseAllData>;
};

type CourseAllData = {
  id: string;
  type: string;
  attributes: {
    id: number;
    course_name: string;
    duration: number;
    status: string;
    degree: string;
    jee_id: any;
    mpc: string;
    bipc: string;
    mbipc: string;
    english: string;
    mec: string;
    cec: string;
    hec: string;
    hslc: string;
    specialization: string;
    scope: string;
    short_code: string;
    remarks: string;
    remarks_deletion: string;
  };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  coursesData: CourseAllData[];
  loading: boolean;
  currentPage: number;
  numberOfPages: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseFinderCoursesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCoursesMessageId = "";
  dataPerPage = 10;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      coursesData: [],
      loading: false,
      currentPage: 0,
      numberOfPages: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (response && apiRequestCallId === this.getAllCoursesMessageId) {
      if (response.data) {
        this.successfulFetchOfCourses(response as CourseAllDataType);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getAllCourses();
  };

  successfulFetchOfCourses = (response: CourseAllDataType) => {
    this.setState(
      {
        coursesData: response.data,
        loading: false,
      },
      () => {
        const dataLength = this.state.coursesData.length;
        this.setState({
          numberOfPages: Math.ceil(dataLength / this.dataPerPage),
        });
      }
    );
  };

  // Customizable Area Start
  getAllCourses = async () => {
    this.setState({
      loading: true,
    });

    const getAllCoursesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getAllCoursesMessageId = getAllCoursesMessage.messageId;

    getAllCoursesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getAllCoursesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allCourses
    );

    getAllCoursesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getAllCoursesMessage.id, getAllCoursesMessage);
  };

  changePage = (pageNumber: number) => {
    if (pageNumber < this.state.numberOfPages) {
      this.setState({
        currentPage: pageNumber,
      });
    }
  };
  // Customizable Area End
}
