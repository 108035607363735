import React from "react";

import {
  Box,
  // Customizable Area Start
  Modal,
  Button,
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import TimerIcon from "@material-ui/icons/Timer";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Stepper from "../../../components/src/Stepper.web";
import { styles } from "./styles";
import { Link, Prompt } from "react-router-dom";
import TestComponent from "../../../components/src/TestComponent.web";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import { clean } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import PracticeTestTakingController, {
  Props,
} from "./PracticeTestTakingController.web";

class PracticeTestTaking extends PracticeTestTakingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const totalSeconds = Math.floor(this.state.timeRemaining / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = totalSeconds - hours * 3600 - minutes * 60;
    return (
      // Customizable Area Start
      <Box>
        <Prompt
          when={this.state.isBlocking}
          message="Are you sure you want to leave this page? Your changes may be lost."
        />
        <Loader loading={this.state.loading} />
        <Box sx={webStyle.bannerStyle}>
          <h2>Practice Test</h2>
        </Box>
        <Box>
          <Modal
            open={this.state.errorModalOpen}
            BackdropProps={{ onClick: undefined }}
          >
            <Box sx={webStyle.modalStyle} className="submit-test-question">
              <h3>No exam found</h3>
              <Button
                style={webStyle.buttonStyleYes}
                data-test-id="goBack"
                onClick={this.goBack}
              >
                Go back
              </Button>
            </Box>
          </Modal>
          <Modal
            open={this.state.submitModalOpen}
            onClose={this.handleSubmitModalClose}
            BackdropProps={{ onClick: undefined }}
          >
            <Box sx={webStyle.modalStyle} className="submit-test-question">
              <h3>Are you sure you want to submit your Test?</h3>
              <Box>
                <Button
                  style={webStyle.buttonStyleNo}
                  data-test-id="closeSubmit"
                  onClick={this.handleSubmitModalClose}
                >
                  No
                </Button>
                <Button
                  style={webStyle.buttonStyleYes}
                  data-test-id="submitBtnModal"
                  onClick={this.submitTest}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={this.state.reviewModalOpen}
            BackdropProps={{ onClick: undefined }}
          >
            <Box
              sx={{ ...webStyle.modalStyle, height: "300px" }}
              className="submit-test-done"
            >
              <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
              <Box
                display="flex"
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <h3>Your Test has been submitted Succesfully</h3>
                <h3>Click On Review button to know about the answers</h3>
              </Box>
              <Link
                to={`/dashboard/practice-test/chapters/${this.props.navigation.getParam(
                  "subjectId"
                )}/result-overview/${this.props.navigation.getParam(
                  "chapterId"
                )}${this.searchResolve}`}
              >
                <Button style={webStyle.buttonStyleYes}>Review</Button>
              </Link>
            </Box>
          </Modal>

          <Box
            style={{ display: "flex", gap: "1em", padding: "1em 2em 0 2em" }}
          >
            <Stepper
              steps={this.state.testData.length}
              activeStep={this.state.currentTestData + 1}
            />
            <p>
              {this.state.currentTestData + 1}/{this.state.testData.length}
            </p>
          </Box>
          <Box
            style={{
              display: "flex",
              gap: "1em",
              padding: "1em 2em",
              alignItems: "center",
            }}
          >
            <p className={this.props.classes.practiceTestTimer}>
              <span>
                <TimerIcon />
              </span>
              {String(hours).length > 1 ? hours : `${hours}`}:
              {String(minutes).length > 1 ? minutes : `0${minutes}`}:
              {String(seconds).length > 1 ? seconds : `0${seconds}`}
            </p>
          </Box>
          <Box className={this.props.classes.questionOverview}>
            This question carries +
            {this.state.testData[this.state.currentTestData]?.attributes.p_mark}{" "}
            for Correct Answer and -
            {Number(
              this.state.testData[this.state.currentTestData]?.attributes.n_mark
            )}{" "}
            for wrong Answer
          </Box>
          <Box className={this.props.classes.mainContainerPracticeTest}>
            {this.state.testData.length > 0 && (
              <TestComponent
                question={this.state.testData[this.state.currentTestData]}
                index={this.state.currentTestData}
                setAnswer={this.setAnswer}
                answers={this.state.answers}
              />
            )}
            <Box className="overview-practice-test">
              <Box className="overview-board">
                <h3>Overview</h3>
                <Box className="overview-btns">
                  {this.state.testData.map((question, index) => {
                    const className = this.getBtnClassName(index);
                    return (
                      <button
                        data-test-id={`specific-btn-test-id`}
                        key={`button-${index}`}
                        className={className}
                        onClick={() => this.handleSpecificQuestion(index)}
                      >
                        {question.attributes.question_no}
                      </button>
                    );
                  })}
                </Box>
              </Box>
              <Box className="overview-legends">
                <p>
                  <span>
                    <FiberManualRecordIcon style={{ color: "#9CBA58" }} />
                  </span>
                  Answered{" "}
                  <span style={{ marginLeft: "0.8em" }}>
                    <FiberManualRecordIcon style={{ color: "#4B4B4B" }} />
                  </span>
                  Un-Answered
                </p>
                <p>
                  <span>
                    <FiberManualRecordIcon style={{ color: "#FF8147" }} />
                  </span>
                  Answered & Marked for Review
                </p>
                <p>
                  <span>
                    <FiberManualRecordIcon style={{ color: "#DD1000" }} />
                  </span>
                  Not Answered & Marked for Review
                </p>
                <p>
                  <span>
                    <FiberManualRecordIcon style={{ color: "#69B5FF" }} />
                  </span>
                  Answered & Cleared the answer
                </p>
                <p>
                  <span>
                    <FiberManualRecordIcon style={{ color: "#D426C9" }} />
                  </span>
                  Marked & Unmarked for review
                </p>
              </Box>
            </Box>
          </Box>
          <Box className={this.props.classes.btnsPracticeTest}>
            <Box className="submit-mark-practice-btns">
              <button
                data-test-id={`submitTest`}
                className="submit-test-btn"
                onClick={this.handleSubmitModalOpen}
              >
                Submit Test
              </button>
            </Box>
            <Box className="navigation-practice-btns">
              <Box className="bookmark-clear-box">
                <Box
                  className="bookmark-icon-review"
                  onClick={this.markForReview}
                  data-test-id="markforreview"
                >
                  <BookmarkIcon />
                </Box>
                <Box
                  className="clear-icon"
                  onClick={this.clearResponse}
                  data-test-id="clrbtn"
                >
                  <img src={clean} alt="clear-icon" />
                </Box>
              </Box>
              <Box>
                <button
                  data-test-id={`prevbtn`}
                  onClick={this.handlePreviousQuestion}
                >
                  <ChevronLeftIcon />
                </button>
                <button
                  data-test-id={`nxtbtn`}
                  onClick={this.handleNextQuestion}
                >
                  <ChevronRightIcon />
                </button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    minHeight: "max-content",
    bgcolor: "background.paper",
    border: "2px solid #FFD0D8",
    borderRadius: "1.5em",
    padding: "2em",
    gap: "1.5em",
  },
  buttonStyleYes: {
    width: "130px",
    height: "50px",
    color: "white",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    textTransform: "none" as const,
    marginLeft: "1em",
  },
  bannerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    color: "#461479",
  },
  buttonStyleNo: {
    width: "130px",
    height: "50px",
    color: "#4F1f6d",
    background: "white",
    border: "1px solid #ffe9ed",
    borderRadius: "0.6em",
    textTransform: "none" as const,
  },
};

export default withStyles(styles)(PracticeTestTaking);
export { PracticeTestTaking };
// Customizable Area End
