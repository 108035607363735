import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import Loader from "../../../components/src/Loader.web";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End

import GuestNotificationsController, {
  Props,
  configJSON,
} from "./GuestNotificationsController.web";

class GuestNotifications extends GuestNotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const indexOfLastItem = this.state.currentPage * 20;
    const indexOfFirstItem = indexOfLastItem - 20;
    const currentItems = this.state.notifications.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    const totalPages = Math.ceil(this.state.notifications.length / 20);
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <BannerWeb title={configJSON.notifications} />
        <Box padding={"3em 6em"}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gridGap={"0.4em"}
          >
            <Button
              style={buttonStyle}
              onClick={() => this.getCourseNotifications(0)}
              className={
                this.state.activeIndex === 0
                  ? this.props.classes.buttonStyleActive
                  : ""
              }
              data-test-id="all-btn"
            >
              All
            </Button>
            {this.state.notificationButtons.map((button, index) => {
              return (
                <Button
                  onClick={() =>
                    this.getCourseNotifications(index + 1, button.id)
                  }
                  style={buttonStyle}
                  className={
                    this.state.activeIndex === index + 1
                      ? this.props.classes.buttonStyleActive
                      : ""
                  }
                  data-test-id="course-btn"
                >
                  {button.attributes.name}
                </Button>
              );
            })}
          </Box>
          <ul className={this.props.classes.ulStyle}>
            {currentItems.map((notif) => {
              return (
                <li>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: notif.attributes.notice_head as string,
                    }}
                  />
                </li>
              );
            })}
          </ul>
          <Box
            display="flex"
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Pagination
              data-test-id="pagination"
              count={totalPages}
              page={this.state.currentPage}
              onChange={(_: unknown, value: number) =>
                this.handlePageChange(value)
              }
              className={this.props.classes.muiPaginationStyle}
            />
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const buttonStyle = {
  padding: "0.6em 1em",
  background: "#222",
  color: "white",
  borderRadius: "0.4em",
};

export const webStyleGuestNotifications = () =>
  createStyles({
    buttonStyleActive: {
      ...buttonStyle,
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)!important",
    },
    muiPaginationStyle: {
      borderRadius: "4px",
      width: "max-content",
      border: "1px solid rgba(255, 58, 87, 0.7)",
      position: "relative",
      "& li": {
        color: "white",
        background: "white",
        width: "35px",
        borderRadius: "2px",
        height: "41px",
      },
      "& li:nth-child(1)": {
        zIndex: 999,
        top: 10,
        left: -20,
        background: "transparent",
        position: "absolute",
        "& button svg": {
          "& path": {
            fill: "white",
          },
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          borderRadius: "50%",
        },
        "& button": {
          height: "max-content",
          width: "max-content",
        },
        "& button.Mui-disabled": {
          display: "none",
        },
      },
      "& li:nth-last-child(1)": {
        position: "absolute",
        background: "transparent",
        top: 10,
        zIndex: 999,
        right: -20,
        "& button": {
          height: "max-content",
          width: "max-content",
        },
        "& button.Mui-disabled": {
          display: "none",
        },
        "& button svg": {
          "& path": {
            fill: "white",
          },
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          borderRadius: "50%",
        },
      },
      "& li button:hover": {
        background: "none",
      },
      "& li button": {
        height: "100%",
        width: "100%",
        alignItems: "center",
        display: "flex",
        margin: 0,
        padding: 0,
        justifyContent: "center",
        background: "none",
      },
      "& li div": {
        background: "none",
        display: "flex",
        alignItems: "center",
        height: "100%",
        width: "100%",
        justifyContent: "center",
      },
      "& li button.Mui-selected": {
        fontWeight: 500,
        color: "white",
        borderRadius: "2px",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      },
    },
    ulStyle: {
      listStyleType: "disc!important",
      marginTop: "3em",
      "& li": {
        listStyleType: "disc",
        margin: "1em 0",
        fontSize: "1.2em",
      },
    },
  });
export default withStyles(webStyleGuestNotifications)(GuestNotifications);
export { GuestNotifications };
// Customizable Area End
