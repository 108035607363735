import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export const firebaseAPI = initializeApp({
  apiKey: "AIzaSyAiixBWqhY-STgLGUiwnNUrK8OAqP2awbk",
  authDomain: "myrank-fb.firebaseapp.com",
  projectId: "myrank-fb",
  storageBucket: "myrank-fb.appspot.com",
  messagingSenderId: "346449454420",
  appId: "1:346449454420:web:cb8d53b451e2f1469614c1",
  measurementId: "G-33KR3V1P4E"
});

export const auth = getAuth(firebaseAPI);