import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { extractParams } from "../../../framework/src/UtilsWeb";
interface Root {
  id: number;
  notes_content: string;
  noteable_id: number;
  noteable_type: string;
  question_id: number;
  question_no: number;
  created_at: string;
  updated_at: string;
  serial_number: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  notes: Root[];
  isDeletePromptOpen: boolean;
  isConfirmDeletePromptOpen: boolean;
  reviewId: string;
  loading: boolean;
  examName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SavedNotesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  pathnameSearchResolve =
    this.props.navigation.history.location.pathname +
    this.props.navigation.history.location.search;
  searchResolve = this.props.navigation.history.location.search;
  breadcrumb = [
    {
      label: "Practice Test",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}${this.searchResolve}`,
    },
    {
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}${this.searchResolve}`,
      label: "Chapters",
    },
    {
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/review-test/${this.props.navigation.getParam("chapterId")}${
        this.searchResolve
      }`,
      label: "Reviews",
    },
    {
      label: "Reviews",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/review-test/${this.props.navigation.getParam("chapterId")}${
        this.searchResolve
      }`,
    },
    {
      label: "Review Test",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/review-test/${this.props.navigation.getParam(
        "chapterId"
      )}/review/${this.props.navigation.getParam("reviewId")}${
        this.searchResolve
      }`,
    },
    {
      label: "Saved Notes",
      link: this.pathnameSearchResolve,
    },
  ];
  getSavedNotesMessageId = "";
  token = "";
  noteIdToDelete = "";
  indexToDelete = Infinity;
  getSubjectsMessageSNCId = "";
  getResultsMessageSNCId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      notes: [],
      isDeletePromptOpen: false,
      isConfirmDeletePromptOpen: false,
      loading: false,
      reviewId: "",
      examName: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSavedNotesMessageId) {
      if (response?.length > 0) {
        this.successfulFetchNotes(response);
      }

      this.setState({
        loading: false,
      });
    }

    if (apiRequestCallId === this.getSubjectsMessageSNCId && response) {
      if (response.data) {
        this.handleSuccessSubject(response.data);
      }
    }

    if (apiRequestCallId === this.getResultsMessageSNCId) {
      if (response.meta) {
        const breadcrumb = this.breadcrumb;
        breadcrumb[2].label = response.meta.chapter.name;

        const propPassingSNC = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingSNC.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );

        this.send(propPassingSNC);

        this.setState({
          examName: response.meta.chapter.name,
        });
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const token = await storage.get("authToken");
    if (token) {
      this.token = token;
    }
    const reviewId = this.props.navigation.getParam("reviewId");
    if (reviewId) {
      this.getSavedNotes(reviewId);
    }
    const propPassingSNC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSNC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingSNC);

    this.getSubjectPT();
    this.getReview();
  };

  handleSuccessSubject = (
    response: {
      id: string;
      attributes: { name: string };
    }[]
  ) => {
    const subjectId = this.props.navigation.getParam("subjectId");
    for (const data of response) {
      if (data.id === subjectId) {
        const breadcrumb = this.breadcrumb;
        breadcrumb[1].label = data.attributes.name;

        const propPassingPTA = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingPTA.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingPTA);

        this.setState({
          loading: false,
        });
      }
    }
  };

  successfulFetchNotes = (response: Root[]) => {
    this.setState({
      notes: response,
    });
  };

  deletePromptOpen = (noteId: string, index: number) => {
    this.noteIdToDelete = noteId;
    this.indexToDelete = index;
    this.setState({
      isDeletePromptOpen: true,
    });
  };

  handleDeletePromptClose = () => {
    this.setState({
      isDeletePromptOpen: false,
    });
  };

  handleDeletePromptClose2 = () => {
    this.setState({
      isConfirmDeletePromptOpen: false,
    });
  };

  deleteSavedNotes = (noteId: string, index: number) => {
    let notes = this.state.notes;
    notes = notes.filter((_, noteIndex) => noteIndex !== index);
    this.deleteSavedNotesApi(noteId);
    this.setState({
      notes,
      isDeletePromptOpen: false,
      isConfirmDeletePromptOpen: true,
    });
  };

  // Customizable Area Start
  getSavedNotes = (reviewId: string) => {
    this.setState({ reviewId });

    const headers = {
      token: this.token,
    };

    this.setState({
      loading: true,
    });

    const getSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSavedNotesMessageId = getSavedNotesMessage.messageId;

    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/notes?practice_test_review_history_id=${reviewId}`
    );

    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getSavedNotesMessage.id, getSavedNotesMessage);
  };

  deleteSavedNotesApi = (noteId: string) => {
    const headers = {
      token: this.token,
    };

    const deleteSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    deleteSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    deleteSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/notes/${noteId}`
    );

    deleteSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(deleteSavedNotesMessage.id, deleteSavedNotesMessage);
  };

  getSubjectPT = async () => {
    const urlSearch = this.props.navigation.history.location.search;
    const packageId = extractParams(urlSearch, "packageId");
    const getSubjectsMessageSNC: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    this.getSubjectsMessageSNCId = getSubjectsMessageSNC.messageId;

    getSubjectsMessageSNC.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${packageId}/get_subjects`
    );

    getSubjectsMessageSNC.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectsMessageSNC.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSubjectsMessageSNC.id, getSubjectsMessageSNC);
  };

  getReview = () => {
    const headers = {
      token: this.token,
    };

    const getResultsMessageSNC = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    this.getResultsMessageSNCId = getResultsMessageSNC.messageId;

    getResultsMessageSNC.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${this.props.navigation.getParam(
        "chapterId"
      )}/review_test`
    );

    getResultsMessageSNC.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getResultsMessageSNC.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getResultsMessageSNC.id, getResultsMessageSNC);
  };
  // Customizable Area End
}
