export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const camera = require('../assets/Group3.png');
export const medal = require('../assets/medal-starcopy.png')
export const backIcon = require('../assets/backIcon.png')
export const userProfile = require('../assets/user_edit.png')
export const about = require('../assets/message-question.png')
export const contactUS = require('../assets/messages.png')
export const registration = require('../assets/message-add.png')
export const taskSquare = require('../assets/task-square.png')
export const leaderBoard = require('../assets/teacher.png')
export const courseNotif = require('../assets/category-2.png')
export const book = require('../assets/book.png')
export const DeactiveIcon = require('../assets/info-circle.png')
export const logOut = require('../assets/logout.png')
export const packageIcon = require('../assets/task-square.png')
export const bookIcon = require('../assets/bookIcon.png');
export const SubmitIcon = require('../assets/submitIcon.png');
export const button = require('../assets/button_filled.png');
export const buttonBG= require('../assets/button_filled.png')
export const Badges = require("../assets/Badeg.png")
export const medalCopy = require('../assets/medal copy.png')
export const Bronze = require('../assets/Bronze.png');
export const Diamond = require('../assets/Diamond.png');
export const gold = require('../assets/gold.png');
export const Platinum = require('../assets/Platinum.png');
export const silver = require('../assets/silver.png');
export const Titanium = require('../assets/Titanium.png');


