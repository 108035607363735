import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
import BannerWeb from "../../../components/src/Banner.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

import BookMySlotStudentController, {
  Props,
  configJSON,
} from "./BookMySlotStudentController.web";
import { Physics } from "./assets";

class BookMySlotStudent extends BookMySlotStudentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderSubjects = () => {
    const { subjects } = this.state;
    return (
      <>
        {subjects.map((subject, index) => {
          return (
            <Box position={"relative"} className="subjects-container">
              <Box
                display="flex"
                gridGap="3em"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img
                  src={Physics}
                  className={this.props.classes.imageSubject}
                />
                <Typography className="subjectName">
                  {subject.attributes.name}
                </Typography>
              </Box>
              {this.state.subjectActive && (
                <ExpandMoreIcon
                  className={this.props.classes.expandIcon}
                  data-test-id="expand-btn"
                  onClick={() =>
                    this.state.subjectActive
                      ? this.setPopUpStateToggle(index, subject.id)
                      : ""
                  }
                />
              )}
              {this.state.popUpStates[index] && (
                <Box className={this.props.classes.popUpStates}>
                  {this.state.currentChapters.map((chapter) => {
                    return (
                      <Button
                        data-test-id="bmsnav"
                        onClick={() =>
                          this.navigateToBookSlot(chapter.id, subject.id)
                        }
                      >
                        {chapter.attributes.name}
                      </Button>
                    );
                  })}
                </Box>
              )}
            </Box>
          );
        })}
      </>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box marginBottom="20em">
        <Loader loading={this.state.loading} />
        <Box position={"relative"}>
          <BannerWeb title="Book My Slot" />
          <Button
            onClick={() => this.handleToBookingHistory()}
            data-test-id="bmshistory"
            className={this.props.classes.bookingHistory}
          >
            {configJSON.bookingHistory}
          </Button>
        </Box>
        <Box className={this.props.classes.mainHeading}>
          <Typography>Available Slots - {this.state.meta.all_slots}</Typography>
          <Typography>
            Booked Slots - {this.state.meta.all_booked_slots}
          </Typography>
          <Button onClick={() => this.subjectActive()} data-test-id="add-btn">
            Add
          </Button>
        </Box>
        <Box className={this.props.classes.subjectContainer}>
          <Box width="80%">
            <Typography className="select-subject">
              Select Subject to book slot with faculty
            </Typography>
          </Box>
          {this.RenderSubjects()}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleBookMySlotStudent = () =>
  createStyles({
    expandIcon: {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      color: "white",
      borderRadius: "50%",
      cursor: "pointer",
      position: "absolute",
      right: "5%",
      top: "45%",
    },
    subjectContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      gap: "1.4em",
      "& .subjects-container": {
        display: "flex",
        width: "80%",
        minHeight: "250px",
        height: "max-content",
        "& .subjectName": {
          fontSize: "1.7em",
          fontWeight: 500,
        },
      },
      "& .select-subject": {
        alignSelf: "flex-start",
        fontSize: "2em",
        fontWeight: 500,
      },
    },
    imageSubject: {
      width: "300px",
      height: "170px",
      borderRadius: "0.7em",
    },
    popUpStates: {
      position: "absolute",
      right: "10%",
      top: "60%",
      border: "1px solid #FE5057",
      borderRadius: "0.8em",
      minHeight: "200px",
      minWidth: "300px",
      width: "min-content",
      height: "max-content",
      padding: "0.6em",
      zIndex: 999,
      "& button": {
        cursor: "pointer",
        fontWeight: 500,
        fontSize: "1.2em",
        width: "100%",
      },
    },
    mainHeading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "2em",
      marginBottom: "5em",
      gap: "1.5em",
      "& p": {
        fontSize: "1.2em",
      },
      "& button": {
        color: "#722E9A",
        border: "1px solid #FE5057",
      },
    },
    bookingHistory: {
      position: "absolute",
      right: "10%",
      top: "20%",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      padding: "0.2em 1em",
      color: "white",
      height: "40px",
    },
  });
export default withStyles(webStyleBookMySlotStudent)(BookMySlotStudent);
export { BookMySlotStudent };
// Customizable Area End
