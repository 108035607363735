import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface Root {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  account_id: number;
  bookmarkable_id: number;
  bookmarkable_type: string;
  question_id: any;
  highlighted_text: any;
  question_no: any;
  question: string;
  created_at: string;
  updated_at: string;
  bookmarkable: Bookmarkable;
}

export interface Bookmarkable {
  id: number;
  subject_id: number;
  chapter_id: number;
  question_type: string;
  question_num: number;
  question: string;
  solution: string;
  created_at: string;
  updated_at: string;
  db_type: string;
  qb_type: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bookmarks: Root[];
  submitModalOpen: boolean;
  submitModalOpen2: boolean;
  subjectName: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuestionBankBookmarksController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBookmarksNotesMessageId = "";
  token = "";
  reviewId = "";
  indexToDelete = Infinity;
  bookmarkIdToDelete = "";
  searchResolve = this.props.navigation.history.search;
  breadcrumb = [
    {
      label: "Question Bank",
      link: "/dashboard/question-bank",
    },
    {
      label: "Subject",
      link: `/dashboard/question-bank/subject/${this.props.navigation.getParam(
        "subjectId"
      )}`,
    },
    {
      label: "Chapter",
      link: `/dashboard/question-bank/subject/${this.props.navigation.getParam(
        "subjectId"
      )}/chapter/${this.props.navigation.getParam("chapterId")}`,
    },
    {
      label: "Bookmarks",
      link:
        this.props.navigation.history.location.pathname + this.searchResolve,
    },
  ];
  getSubjectDetailsBookmarkMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      bookmarks: [],
      submitModalOpen: false,
      submitModalOpen2: false,
      subjectName: "",
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getBookmarksNotesMessageId) {
      if (response.data) {
        this.successfulFetchBookmark(response.data);
        this.setState({
          subjectName: response.meta.exam,
        });
      }
      if (response.meta) {
        const breadcrumb = this.breadcrumb;
        breadcrumb[2].label = response.meta.exam;
        const propPassingQBBookmark = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingQBBookmark.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingQBBookmark);
      }
    }

    if (
      apiRequestCallId === this.getSubjectDetailsBookmarkMessageId &&
      response
    ) {
      if (response.data) {
        this.handleSubjectDetailSuccess(response.meta.subject_name);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingQBBC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingQBBC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingQBBC);
    const token = localStorage.getItem("authToken");
    if (token) {
      this.token = token;
    }
    const subjectId = this.props.navigation.getParam("subjectId");
    this.getBookmarksNotes(this.props.navigation.getParam("chapterId"));
    this.getSubjectDetailsBookmark(subjectId);
  };

  navigateToMain = (questionType: string) => {
    this.props.navigation.history.push(
      `/dashboard/question-bank/subject/${this.props.navigation.getParam(
        "subjectId"
      )}/chapter/${this.props.navigation.getParam(
        "chapterId"
      )}?question_type=${questionType}`
    );
  };

  handleSubmitModalClose = () => {
    this.setState({
      submitModalOpen: false,
    });
  };
  successfulFetchBookmark = (response: Root[]) => {
    this.setState({
      bookmarks: response,
    });
  };

  initializeBookmarkDeletion = (bookmarkId: string, index: number) => {
    this.bookmarkIdToDelete = bookmarkId;
    this.indexToDelete = index;
    this.setState({
      submitModalOpen: true,
    });
  };

  handleSubmitModalClose2 = () => {
    this.setState({
      submitModalOpen2: false,
    });
  };
  // Customizable Area Start
  getBookmarksNotes = (chapterId: string) => {
    this.setState({
      loading: true,
    });
    const headers = {
      token: this.token,
    };

    const getBookmarksNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBookmarksNotesMessageId = getBookmarksNotesMessage.messageId;

    getBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/${chapterId}/qb_bookmarks`
    );

    runEngine.sendMessage(
      getBookmarksNotesMessage.id,
      getBookmarksNotesMessage
    );
  };

  deleteBookmarkNotes = (bookmarkId: string, index: number) => {
    this.setState({
      loading: true,
    });
    const headers = {
      token: this.token,
    };
    let bookmarks = this.state.bookmarks;
    bookmarks = bookmarks.filter((_, indexFilter) => indexFilter !== index);
    this.setState({
      bookmarks,
      submitModalOpen: false,
      submitModalOpen2: true,
    });

    const deleteBookmarkNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    deleteBookmarkNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/bookmarks/${bookmarkId}`
    );
    deleteBookmarkNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deleteBookmarkNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(
      deleteBookmarkNotesMessage.id,
      deleteBookmarkNotesMessage
    );
  };

  getSubjectDetailsBookmark = (subjectId: string) => {
    this.setState({
      loading: true,
    });
    const getSubjectDetailsBookmarkMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: localStorage.getItem("authToken"),
    };
    getSubjectDetailsBookmarkMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/${subjectId}/qb_chapters`
    );
    this.getSubjectDetailsBookmarkMessageId =
      getSubjectDetailsBookmarkMessage.messageId;
    getSubjectDetailsBookmarkMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSubjectDetailsBookmarkMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getSubjectDetailsBookmarkMessage.id,
      getSubjectDetailsBookmarkMessage
    );
  };

  handleSubjectDetailSuccess = (subjectName: string) => {
    const breadcrumb = this.breadcrumb;
    breadcrumb[1].label = subjectName;

    const propPassingQBMC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingQBMC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingQBMC);
  };
  // Customizable Area End
}
