import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { ISlot } from "./BookMySlotDateTimePickController.web";

export interface ISlotDetails {
  id: string;
  type: string;
  attributes: {
    id: number;
    student_name: string;
    parent_name: string;
    mobile_number: string;
    email: string;
    slot_date: string;
    time_slot: string;
    cm_id: string;
    cm_officer_id: string;
    created_by: string;
    group: string;
    status: string;
    slot_type: string;
    zoom_url: string;
    comment: string;
    image_url: string;
    remarks: string;
    rating: number;
    package: Array<{
      id: number;
      account_id: number;
      pack_final_id: number;
      pack_pricing_id: number;
    }>;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  valueDate: string;
  slotId: string;
  loading: boolean;
  slots: ISlot[];
  timeSlot: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotChangeSlotController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSlotIdResponseMessageId = "";
  getSlotBookingsMessageId = "";
  breadcrumb = [
    {
      label: "Book my Slot",
      link: `/dashboard/book-my-slot`,
    },
    {
      link: `/dashboard/book-my-slot`,
      label: "Slots",
    },
    {
      label: "Slot Bookings",
      link: `/dashboard/book-my-slot/slot-bookings`,
    },
    {
      label: "Change Slot",
      link:
        this.props.navigation.history.location.pathname +
        this.props.navigation.history.location.search,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      valueDate: "",
      slotId: "",
      loading: false,
      slots: [],
      timeSlot: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSlotBookingsMessageId && response) {
      if (!response?.error) {
        this.successfulFetchOfTimeSlots(response);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getSlotsForDate();

    const propPassingChange = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingChange.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingChange);
  };

  componentDidUpdate = (_: Props, prevState: S) => {
    const search = this.props.navigation.history.location.search;
    if (
      this.state.timeSlot.length > 0 &&
      this.state.valueDate.length > 0 &&
      search
    ) {
      const slotId = search.split("=")[1];
      this.props.navigation.history.push(
        `/dashboard/book-my-slot/change-slot/update?slotId=${slotId}&timeSlot=${this.state.timeSlot}&dateSlot=${this.state.valueDate}`
      );
    }

    if (this.state.valueDate !== prevState.valueDate) {
      this.getSlotsForDate();
    }
  };

  successfulFetchOfTimeSlots = (slots: ISlot[]) => {
    this.setState({
      slots,
      loading: false,
    });
  };

  // Customizable Area Start
  changeDateChange = (value: string) => {
    const valueDate = this.formatDate(value);

    this.setState({
      valueDate,
    });
  };

  formatDate = (data: string) => {
    const date = new Date(data);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  getSlotsForDate = async () => {
    const getSlotBookingsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getSlotBookingsMessageId = getSlotBookingsMessage.messageId;

    getSlotBookingsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSlotsEndPoint
    );

    getSlotBookingsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSlotBookingsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSlotBookingsMessage.id, getSlotBookingsMessage);
  };

  setTimeSlot = (timeSlot: string) => {
    this.setState({
      timeSlot,
    });
  };
  // Customizable Area End
}
