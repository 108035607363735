import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { styles } from "./QuestionBank.web";

export interface Root {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  name: string;
  image: string;
  created_by: string;
  class_zone: string;
  practice_test: string;
  question_bank: string;
  subjective_test: string;
  st_code: string;
  remarks: string;
  icon_code: string;
  total_topics: number;
  saved_notes: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof styles> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  subjects: Root[];
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuestionBankController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSubjectsMessageId = "";
  breadcrumb = [
    {
      label: "Question Bank",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      subjects: [],
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSubjectsMessageId) {
      if (response?.data) {
        this.handleSubjectSuccess(response.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getSubjects();
    const propPassingQBC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingQBC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingQBC);
  };

  handleSubjectSuccess = (data: Root[]) => {
    this.setState({
      subjects: data,
    });
  };

  // Customizable Area Start
  getSubjects = () => {
    this.setState({
      loading: true,
    });
    const getSubjectsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: localStorage.getItem("authToken"),
    };
    getSubjectsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/question_banks/qb_subjects`
    );
    this.getSubjectsMessageId = getSubjectsMessage.messageId;
    getSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getSubjectsMessage.id, getSubjectsMessage);
  };
  // Customizable Area End
}
