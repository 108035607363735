import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

export interface IPackagesResponse {
  data: Array<Package>;
}
type Package = {
  id: string;
  type: string;
  attributes: {
    id: number;
    package_name: string;
    package_description: string;
    status: string;
    subject_id: string;
    class_zone: string;
    practice_test: string;
    subjective_test: string;
    gt_code: string;
    question_bank: string;
    created_by: string;
    created_at: string;
    updated_at: string;
  };
};

export interface ISubjectsResponse {
  data: Array<Subject>;
}
type Subject = {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    created_by: string;
    class_zone: string;
    practice_test: string;
    question_bank: string;
    subjective_test: string;
    st_code: string;
    remarks: string;
    icon_code: string;
    total_topics: number;
    saved_notes: number;
  };
};

export interface IChaptersResponse {
  data: Array<Chapter>;
}
type Chapter = {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    practice_test: string;
    avail_pt: string;
    class_zone: string;
    avail_cz: string;
    question_bank: string;
    avail_qb: string;
    created_by: string;
    remarks: string;
    chapter_read: boolean;
    viewed: boolean;
    test_attempted: boolean;
  };
};

export interface IStatsPackageResponse {
  exam_wise_overall_attempts: number | null;
  exam_wise_overall_correct_percentage: number | null;
  exam_wise_overall_wrong_percentage: number | null;
  exam_wise_overall_unattempted_percentage: number | null;
  subject_wise_overall_attempts: number | null;
  subject_wise_attempts: Array<{
    subject: string;
    no_of_attempts: number | null;
    correct_answer_percentage: number | null;
    incorrect_answer_percentage: number | null;
    unattended_percentage: number | null;
    correct_answer: number | null;
    incorrect_answer: number | null;
    unattended: number | null;
    total_questions: number | null;
  }>;
}

export interface IStatsSubjectResponse {
  exam_wise_overall_attempts: number | null;
  exam_wise_overall_correct_percentage: number | null;
  exam_wise_overall_wrong_percentage: number | null;
  exam_wise_overall_unattempted_percentage: number | null;
  subject_wise_overall_attempts: number | null;
  subject_wise_overall_correct_percentage: number | null;
  subject_wise_overall_wrong_percentage: number | null;
  subject_wise_overall_unattempted_percentage: number | null;
}

export interface IStatsChapterResponse {
  exam_wise_overall_attempts: number | null;
  exam_wise_overall_correct_percentage: number | null;
  exam_wise_overall_wrong_percentage: number | null;
  exam_wise_overall_unattempted_percentage: number | null;
  subject_chapter_wise_overall_attempts: number | null;
  subject_chapter_wise_overall_correct_percentage: number | null;
  subject_chapter_wise_overall_wrong_percentage: number | null;
  subject_chapter_wise_overall_unattempted_percentage: number | null;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedPackage: string;
  selectedSubject: string;
  selectedChapter: string;
  statsPackage: IStatsPackageResponse | null;
  statsSubject: IStatsSubjectResponse | null;
  statsChapter: IStatsChapterResponse | null;
  packages: Package[];
  subjects: Subject[];
  chapters: Chapter[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UsageStatsPracticeTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPracticeTestStatsPackageMessageId = "";
  getPracticeTestStatsSubjectMessageId = "";
  getPracticeTestStatsChapterMessageId = "";
  getPracticeTestPackagesMessageId = "";
  getPracticeTestChaptersMessageId = "";
  getPracticeTestSubjectsMessageId = "";
  buttons = [
    "Synopsis",
    "Weekly Test",
    "Grand Test",
    "Practice Test",
    "Live Classes",
    "Recorded Classes",
    "Question Bank",
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      statsPackage: null,
      statsChapter: null,
      statsSubject: null,
      chapters: [],
      packages: [],
      subjects: [],
      selectedChapter: "",
      selectedPackage: "",
      selectedSubject: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.getPracticeTestStatsPackageMessageId:
        this.handlePracticeTestStatsPackageData(
          response as IStatsPackageResponse
        );
        this.setLoadingFalse();
        break;
      case this.getPracticeTestStatsSubjectMessageId:
        this.handlePracticeTestStatsSubjectData(
          response as IStatsSubjectResponse
        );
        this.setLoadingFalse();
        break;
      case this.getPracticeTestStatsChapterMessageId:
        this.handlePracticeTestStatsChapterData(
          response as IStatsChapterResponse
        );
        this.setLoadingFalse();
        break;
      case this.getPracticeTestChaptersMessageId:
        this.handlePracticeTestChaptersData(response as IChaptersResponse);
        this.setLoadingFalse();
        break;
      case this.getPracticeTestPackagesMessageId:
        this.handlePracticeTestPackagesData(response as IPackagesResponse);
        this.setLoadingFalse();
        break;
      case this.getPracticeTestSubjectsMessageId:
        this.handlePracticeTestSubjectsData(response as ISubjectsResponse);
        this.setLoadingFalse();
        break;
      default:
        break;
    }

    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getPracticeTestPackages();
  };

  // Customizable Area Start
  setLoadingFalse = () => {
    this.setState({
      loading: false,
    });
  };

  getPracticeTestChapters = async (subjectName: string) => {
    const { subjects } = this.state;

    const subjectId = subjects.find(
      (subjectData) => subjectData.attributes.name === subjectName
    )!.id;

    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getPracticeTestChaptersMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPracticeTestChaptersMessageId =
      getPracticeTestChaptersMessage.messageId;

    getPracticeTestChaptersMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${subjectId}/get_chapters`
    );

    getPracticeTestChaptersMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getPracticeTestChaptersMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getPracticeTestChaptersMessage.id,
      getPracticeTestChaptersMessage
    );
  };
  handlePracticeTestChaptersData = (response: IChaptersResponse) => {
    if (response && response.data) {
      this.setState(
        {
          chapters: response.data,
        },
        () => {
          this.getPracticeTestStats();
        }
      );
    }
  };

  getPracticeTestSubjects = async (packageName: string) => {
    const { packages } = this.state;

    const packageId = packages.find(
      (packageData) => packageData.attributes.package_name === packageName
    )!.id;

    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getPracticeTestSubjectsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPracticeTestSubjectsMessageId =
      getPracticeTestSubjectsMessage.messageId;

    getPracticeTestSubjectsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${packageId}/get_subjects`
    );

    getPracticeTestSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getPracticeTestSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getPracticeTestSubjectsMessage.id,
      getPracticeTestSubjectsMessage
    );
  };
  handlePracticeTestSubjectsData = (response: ISubjectsResponse) => {
    if (response && response.data) {
      this.setState(
        {
          subjects: response.data,
        },
        () => {
          this.getPracticeTestStats();
        }
      );
    }
  };

  getPracticeTestPackages = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getPracticeTestPackagesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPracticeTestPackagesMessageId =
      getPracticeTestPackagesMessage.messageId;

    getPracticeTestPackagesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPackagesPracticeTest
    );

    getPracticeTestPackagesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getPracticeTestPackagesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getPracticeTestPackagesMessage.id,
      getPracticeTestPackagesMessage
    );
  };
  handlePracticeTestPackagesData = (response: IPackagesResponse) => {
    if (response && response.data) {
      this.setState({
        packages: response.data,
      });
    }
  };

  getPracticeTestStats = async () => {
    const {
      selectedChapter,
      selectedPackage,
      selectedSubject,
      chapters,
      subjects,
      packages,
    } = this.state;

    const getPracticeTestStatsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let baseEndpoint =
      "bx_block_assessmenttest/practice_tests/practice_usage_stats?";

    const endpointParams = [];

    if (selectedPackage) {
      const packageId = packages.find(
        (packageData) => packageData.attributes.package_name === selectedPackage
      )!.id;

      endpointParams.push(`package_id=${packageId}`);

      this.getPracticeTestStatsPackageMessageId =
        getPracticeTestStatsMessage.messageId;
    }

    if (selectedSubject) {
      const subjectId = subjects.find(
        (subjectData) => subjectData.attributes.name === selectedSubject
      )!.id;

      endpointParams.push(`subject_id=${subjectId}`);

      this.getPracticeTestStatsSubjectMessageId =
        getPracticeTestStatsMessage.messageId;
      this.getPracticeTestStatsPackageMessageId = "";
    }

    if (selectedChapter) {
      const chaptertId = chapters.find(
        (chapterData) => chapterData.attributes.name === selectedChapter
      )!.id;

      endpointParams.push(`chapter_id=${chaptertId}`);

      this.getPracticeTestStatsChapterMessageId =
        getPracticeTestStatsMessage.messageId;
      this.getPracticeTestStatsSubjectMessageId = "";
      this.getPracticeTestStatsPackageMessageId = "";
    }

    baseEndpoint += endpointParams.join("&");

    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    getPracticeTestStatsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseEndpoint
    );

    getPracticeTestStatsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getPracticeTestStatsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getPracticeTestStatsMessage.id,
      getPracticeTestStatsMessage
    );
  };
  handlePracticeTestStatsPackageData = (response: IStatsPackageResponse) => {
    if (
      response &&
      (response.exam_wise_overall_attempts ||
        !response.exam_wise_overall_attempts)
    ) {
      this.setState({
        statsPackage: response,
      });
    }
  };
  handlePracticeTestStatsSubjectData = (response: IStatsSubjectResponse) => {
    if (
      response &&
      (response.exam_wise_overall_attempts ||
        !response.exam_wise_overall_attempts)
    ) {
      this.setState({
        statsSubject: response,
      });
    }
  };
  handlePracticeTestStatsChapterData = (response: IStatsChapterResponse) => {
    if (
      response &&
      (response.exam_wise_overall_attempts ||
        !response.exam_wise_overall_attempts)
    ) {
      this.setState({
        statsChapter: response,
      });
    }
  };

  onChangeSelectChapter = (selectedChapter: string) => {
    const { chapters } = this.state;

    const chapter = chapters.find(
      (chapterData) => selectedChapter === chapterData.attributes.name
    );

    this.setState(
      {
        selectedChapter: chapter!.attributes.name,
      },
      () => {
        this.getPracticeTestStats();
      }
    );
  };

  onChangeSelectSubject = (selectedSubject: string) => {
    const { subjects } = this.state;

    const subject = subjects.find(
      (subjectData) => selectedSubject === subjectData.attributes.name
    );

    this.setState(
      {
        selectedSubject: subject!.attributes.name,
        selectedChapter: "",
        chapters: [],
      },
      () => {
        this.getPracticeTestChapters(selectedSubject);
      }
    );
  };

  onChangeSelectPackage = (selectedPackage: string) => {
    const { packages } = this.state;

    const packageFound = packages.find(
      (packageData) => selectedPackage === packageData.attributes.package_name
    );

    this.setState(
      {
        selectedPackage: packageFound!.attributes.package_name,
        selectedChapter: "",
        selectedSubject: "",
        chapters: [],
        subjects: [],
      },
      () => {
        this.getPracticeTestStats();
        this.getPracticeTestSubjects(selectedPackage);
      }
    );
  };
  // Customizable Area End
}
