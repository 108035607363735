import React from "react";

import {
  Box,
  // Customizable Area Start
  Modal,
  Button,
  withStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import TimerIcon from "@material-ui/icons/Timer";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Stepper from "../../../components/src/Stepper.web";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { saveImg, Bookmark } from "./assets";
import ReviewTestComponent from "../../../components/src/ReviewTestComponent.web";
import Loader from "../../../components/src/Loader.web";
import { styles } from "./styles";
import ProgressContainer from "../../../components/src/ProgressContainer.web";
// Customizable Area End

import ReviewTestController, { Props } from "./ReviewTestController.web";

class ReviewTest extends ReviewTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    let totalTime: string = "00:00";
    const { correctPercentage, unattendedPercentage, incorrectPercentage } =
      this.state;
    if (this.state.metaData) {
      totalTime = this.state.metaData.time_taken;
    }

    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Modal
          open={this.state.openSaveNotesModal}
          onClose={this.handleNotesModalClose}
        >
          <Box sx={webStyle.modalStyle}>
            <Typography variant="h6">Choose What You Need</Typography>
            <Box display={"flex"} flexDirection={"column"} gridGap={"1em"}>
              <Link
                to={`/dashboard/practice-test/chapters/${this.props.navigation.getParam(
                  "subjectId"
                )}/review-test/${this.props.navigation.getParam(
                  "chapterId"
                )}/saved-notes/${this.props.navigation.getParam("reviewId")}${this.searchResolve}`}
              >
                <Button className={this.props.classes.buttonStyleModals}>
                  Go to saved notes
                </Button>
              </Link>
              <Link
                to={`/dashboard/practice-test/chapters/${this.props.navigation.getParam(
                  "subjectId"
                )}/review-test/${this.props.navigation.getParam(
                  "chapterId"
                )}/saved-notes/${this.props.navigation.getParam(
                  "reviewId"
                )}/note/New/${
                  this.state.reviewData[this.state.currentReviewData]
                    ?.attributes.id
                }/${
                  this.state.reviewData[this.state.currentReviewData]
                    ?.attributes.question_no
                }/${
                  this.state.reviewData[this.state.currentReviewData]
                    ?.attributes.id
                }${this.searchResolve}`}
              >
                <Button className={this.props.classes.buttonStyleModals}>
                  Create New Note
                </Button>
              </Link>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.openBookmarkModal}
          onClose={this.handleBookmarkModalClose}
        >
          <Box sx={webStyle.modalStyle}>
            <Typography variant="h6">Choose What You Need</Typography>
            <Box display={"flex"} flexDirection={"column"} gridGap={"1em"}>
              <Link
                to={`/dashboard/practice-test/chapters/${this.props.navigation.getParam(
                  "subjectId"
                )}/review-test/${this.props.navigation.getParam(
                  "chapterId"
                )}/bookmarks/${this.props.navigation.getParam("reviewId")}${this.searchResolve}`}
              >
                <Button
                  className={this.props.classes.buttonStyleModals}
                  data-test-id="gotobookmarks-modal"
                >
                  Go to Saved Bookmark
                </Button>
              </Link>
              <Button
                className={this.props.classes.buttonStyleModals}
                data-test-id="save-bookmarks-modal"
                onClick={()=>this.bookmarkSave()}
              >
                Create New Bookmark
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.openBookmarkSuccessModal}
          onClose={this.handleBookmarkSuccessModalClose}
        >
          <Box sx={webStyle.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>
              You have Bookmarked Question no -{" "}
              {
                this.state.reviewData[this.state.currentReviewData]?.attributes
                  .question_no
              }{" "}
              successfully!
            </h3>
            <Box>
              <Link
                to={`/dashboard/practice-test/chapters/${this.props.navigation.getParam(
                  "subjectId"
                )}/review-test/${this.props.navigation.getParam(
                  "chapterId"
                )}/bookmarks/${this.props.navigation.getParam("reviewId")}${this.searchResolve}`}
              >
                <Button className={this.props.classes.buttonStyleModals}>
                  Go to Saved bookmarks
                </Button>
              </Link>
            </Box>
          </Box>
        </Modal>
        <Box sx={webStyle.bannerStyle}>
          <h2>Practice Test - Review</h2>
        </Box>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <ProgressContainer
            correctPercentage={correctPercentage as number}
            incorrectPercentage={incorrectPercentage as number}
            unattendedPercentage={unattendedPercentage as number}
          />
        </Box>
        <Box style={{ display: "flex", gap: "1em", padding: "1em 2em 0 2em" }}>
          <Stepper
            steps={this.state.reviewData.length}
            activeStep={this.state.currentReviewData + 1}
          />
          <p>
            {this.state.currentReviewData + 1}/{this.state.reviewData.length}
          </p>
        </Box>
        <Box
          style={{
            display: "flex",
            gap: "1em",
            padding: "1em 2em",
            alignItems: "center",
          }}
        >
          <p className={this.props.classes.practiceTestTimer}>
            <span>
              <TimerIcon />
            </span>
            {totalTime}
          </p>
        </Box>
        <Box className={this.props.classes.questionOverview}>
          This question carries +
          {
            this.state.reviewData[this.state.currentReviewData]?.attributes
              .p_mark
          }{" "}
          for Correct Answer and -
          {Number(
            this.state.reviewData[this.state.currentReviewData]?.attributes
              .n_mark
          )}{" "}
          for wrong Answer
          <Box>
            <p className={this.props.classes.savedNotesIcon}>
              <img
                src={saveImg}
                width={"25px"}
                data-test-id="notes-modal"
                onClick={this.handleNotesModalOpen}
              />
            </p>
            <p className={this.props.classes.bookmarkIcon}>
              <img
                src={Bookmark}
                width={"25px"}
                data-test-id="bookmark-modal"
                onClick={this.handleBookmarkModalOpen}
              />
            </p>
          </Box>
        </Box>
        <Box className={this.props.classes.mainContainerPracticeTest}>
          {this.state.reviewData.length > 0 && (
            <ReviewTestComponent
              question={this.state.reviewData[this.state.currentReviewData]}
              index={this.state.currentReviewData}
            />
          )}
          <Box className="overview-practice-test">
            <Box className="overview-board">
              <h3>Overview</h3>
              <Box className="overview-btns">
                {this.state.reviewData.map((question, index) => {
                  const className = this.getBtnClassName(index);
                  return (
                    <button
                      data-test-id={`specific-btn-test-id`}
                      key={`button-${index}`}
                      className={className}
                      onClick={() => this.handleSpecificQuestion(index)}
                    >
                      {question.attributes.question_no}
                    </button>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={this.props.classes.btnsPracticeTest}>
          <Box className="submit-mark-practice-btns">
            <Link
              to={`/dashboard/practice-test/chapters/${this.props.navigation.getParam(
                "subjectId"
              )}/test/${this.props.navigation.getParam("chapterId")}${this.searchResolve}`}
            >
              <button data-test-id={`submitTest`} className="submit-test-btn">
                Retake Test
              </button>
            </Link>
          </Box>
          <Box className="navigation-practice-btns">
            <Box>
              <button
                data-test-id={`prevbtn`}
                onClick={this.handlePreviousQuestion}
              >
                <ChevronLeftIcon />
              </button>
              <button data-test-id={`nxtbtn`} onClick={this.handleNextQuestion}>
                <ChevronRightIcon />
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  bannerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    color: "rgb(82, 0, 130)",
    position: "relative",
  },
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    height: "300px",
    bgcolor: "background.paper",
    border: "2px solid #FFD0D8",
    borderRadius: "1.5em",
    padding: "4em",
    gap: "1.5em",
  },
};

export default withStyles(styles)(ReviewTest);
export { ReviewTest };
// Customizable Area End
