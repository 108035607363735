import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { webStyleAYQEditProfile } from "./AYQEditProfile.web";
import { ProfileType } from "./AYQProfileController.web";
import storage from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleAYQEditProfile> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userProfile: ProfileType | null;
  inputs: {
    fname: string;
    lname: string;
    number: string;
    email: string;
    city: string;
  };
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AYQEditProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserDataMessageId = "";
  patchUserEditProfileMessageId = "";
  breadcrumb = [
    {
      label: "Profile",
      link: "/dashboard/ask-your-query/profile",
    },
    {
      label: "Edit profile",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userProfile: null,
      loading: false,
      inputs: {
        fname: "",
        lname: "",
        number: "",
        email: "",
        city: "",
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getUserDataMessageId) {
      this.successfulFetchUserData(response);
    }

    if (apiRequestCallId === this.patchUserEditProfileMessageId) {
      if (response?.data) {
        storage.set("userData", JSON.stringify(response.data));
        window.location.reload();
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const userId = await storage.get("userId");
    this.getUserData(userId);
    const propPassingAEPC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingAEPC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingAEPC);
  };
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({
      inputs: {
        ...this.state.inputs,
        [name]: value,
      },
    });
  };
  successfulFetchUserData = (response: { profile: ProfileType }) => {
    if (response.profile) {
      this.setState({
        userProfile: response.profile,
      });
    }
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.patchUserEditProfile();
  };

  // Customizable Area Start
  patchUserEditProfile = async () => {
    const userId = await storage.get("userId");
    const patchUserEditProfileMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({
      loading: true,
    });
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": undefined,
    };

    let formData = new FormData();

    if (this.state.inputs.fname) {
      formData.append("profile[first_name]", this.state.inputs.fname);
    }

    if (this.state.inputs.lname) {
      formData.append("profile[last_name]", this.state.inputs.lname);
    }

    if (this.state.inputs.city) {
      formData.append("profile[city]", this.state.inputs.city);
    }

    if (this.state.inputs.email) {
      formData.append("profile[email]", this.state.inputs.email);
    }

    if (this.state.inputs.number) {
      formData.append("profile[phone_number]", this.state.inputs.number);
    }

    patchUserEditProfileMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/profile_settings/${userId}`
    );
    this.patchUserEditProfileMessageId = patchUserEditProfileMessage.messageId;
    patchUserEditProfileMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    patchUserEditProfileMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    patchUserEditProfileMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(
      patchUserEditProfileMessage.id,
      patchUserEditProfileMessage
    );
  };
  getUserData = async (userId: string) => {
    const getUserDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getUserDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/visit_user_profile?account_id=${userId}`
    );
    this.setState({
      loading: true,
    });
    this.getUserDataMessageId = getUserDataMessage.messageId;
    getUserDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getUserDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getUserDataMessage.id, getUserDataMessage);
  };

  // Customizable Area End
}
