import React from "react";

import {
  Box as MBox,
  // Customizable Area Start
  Typography,
  Button as MUIButton,
  withStyles,
  createStyles,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Footer from "../../../components/src/Footer.web";
import { submitIcon } from "./assets";
import BannerWeb from "../../../components/src/Banner.web";
// Customizable Area End

import SynopsisGuestTopicsController, {
  ChapterTopic,
  Props,
} from "./SynopsisGuestTopicsController.web";

class SynopsisGuestTopics extends SynopsisGuestTopicsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  topicsRender = (topic: ChapterTopic, index: number) => {
    return (
      <MBox
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        className={this.props.classes.topicMBoxStyle}
        style={{ opacity: index > 0 ? "0.4" : "1" }}
        key={topic.attributes.name}
        onClick={
          index > 0
            ? () => this.handleModalOpen()
            : () => this.navigateTo(String(this.state.meta?.chapter.id))
        }
        data-test-id="learn-btn"
      >
        <Typography variant="h6">{topic.attributes.name}</Typography>
        <MBox style={{ position: "absolute", bottom: "25px", right: "20px" }}>
          Learn Now <MBox className={this.props.classes.circleIcon}></MBox>
        </MBox>
      </MBox>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MBox>
        <Modal
          open={this.state.isModalOpen}
          onClose={this.handleModalOpen}
          BackdropProps={{ onClick: undefined }}
        >
          <MBox className={this.props.classes.modalStyle}>
            <img src={submitIcon} alt="getstarted" />
            <h5>
              To get access to all subjects and more content you need to
              register
            </h5>
            <MBox display={"flex"} gridGap={"1em"}>
              <MUIButton
                className={this.props.classes.buttonStyleYes}
                data-test-id="navigate-btn"
                onClick={() => this.props.navigation.navigate("Register")}
              >
                Get Started
              </MUIButton>
              <MUIButton
                className={this.props.classes.buttonStyleNo}
                data-test-id="cancel-btn"
                onClick={this.handleModalOpen}
              >
                Cancel
              </MUIButton>
            </MBox>
          </MBox>
        </Modal>
        <BannerWeb title="Synopsis" />
        <MBox
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          minHeight={"90vh"}
          gridGap="2.5em"
        >
          {this.state.allTopics.length > 0 ? (
            this.state.allTopics.map((topic, index) => {
              return this.topicsRender(topic, index);
            })
          ) : (
            <Typography variant="body1">No Topics for this Subject</Typography>
          )}
        </MBox>
        <Footer />
      </MBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleSynopsisTopics = () =>
  createStyles({
    buttonStyleNo: {
      width: "130px",
      background: "white",
      border: "1px solid #ffe9ed",
      borderRadius: "0.6em",
      textTransform: "none" as const,
      height: "50px",
      color: "#4F1f6d",
    },
    circleIcon: {
      position: "absolute",
      top: "10px",
      left: "-20px",
      borderRadius: "50%",
      textAlign: "center",
      display: "inline-block",
      color: "white",
      marginLeft: "0.5em",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      marginRight: "0.3em",
      width: "5px",
      height: "5px",
    },
    modalStyle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "35%",
      minHeight: "max-content",
      background: "white",
      border: "2px solid #FFD0D8",
      borderRadius: "1.5em",
      alignItems: "center",
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "2em",
      gap: "1.5em",
    },
    buttonStyleYes: {
      width: "130px",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      textTransform: "none" as const,
      marginLeft: "1em",
      height: "50px",
      color: "white",
    },
    topicMBoxStyle: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: "2px solid #BA7080",
      position: "relative",
      width: "340px",
      height: "340px",
      borderRadius: "10px",
      cursor: "pointer",
      "& img": {
        height: "140px",
        width: "140px",
        borderRadius: "50%",
      },
      "& button": {
        color: "#3B087C",
        textAlign: "center",
        border: "1px solid #BA7080",
        padding: "1em 2em",
        borderRadius: "10px",
      },
      "& h6": {
        margin: "1em 0",
      },
    },
  });
export default withStyles(webStyleSynopsisTopics)(SynopsisGuestTopics);
export { SynopsisGuestTopics };
// Customizable Area End
