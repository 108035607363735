import React from "react";

import {
  Box,
  // Customizable Area Start
  Typography,
  Modal,
  withStyles,
  createStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import MenuIcon from "@material-ui/icons/Menu";
import Footer from "../../../components/src/Footer.web";
import {
  computer,
  document,
  myranklogo,
  home,
  book,
  calculator,
  sms,
  calendar,
} from "./assets";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Button from "../../../components/src/Button.web";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import RoutesDashboard from "../../../components/src/RoutesDashboard.web";
import withAuth from "../../../components/src/withAuth.web";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AdvancedSearchDashboard from "../../advancedsearch/src/AdvancedSearchDashboard.web";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  sidebarClasses,
  menuClasses,
} from "react-pro-sidebar";
// Customizable Area End

import DashboardController, {
  Props,
  QbChapter,
  SubjectPracticeTestType,
} from "./DashboardController.web";

class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderQuestionBankSubmenuLevel_1 = () => {
    return (
      <>
        {this.state.questionBankSubjectsName.map((subject, index) => {
          return (
            <SubMenu
              label={`${subject.attributes.name}`}
              key={`${subject.attributes.name}-${subject.id}`}
              onClick={() =>
                this.navigateTo(
                  `/dashboard/question-bank/subject/${subject.id}`
                )
              }
              className={this.determineIfActiveLink(
                `/dashboard/question-bank/subject/${subject.id}`
              )}
              data-test-id="navigate-question-bank-level-1"
            >
              {this.state.qbSubMenuName[index] &&
                this.RenderQuestionBankSubmenuLevel_2(
                  this.state.qbSubMenuName[index],
                  subject.id
                )}
            </SubMenu>
          );
        })}
      </>
    );
  };

  RenderQuestionBankSubmenuLevel_2 = (
    qbSubmenuLevel2Names: QbChapter[],
    subjectId: string
  ) => {
    return (
      <>
        {qbSubmenuLevel2Names.map((chapter, index) => {
          return (
            <MenuItem
              key={`${chapter.attributes.id}-${chapter.attributes.name}-${index}-chapter`}
              onClick={() =>
                this.navigateTo(
                  `/dashboard/question-bank/subject/${subjectId}/chapter/${chapter.id}`
                )
              }
              className={this.determineIfActiveLink(
                `/dashboard/question-bank/subject/${subjectId}/chapter/${chapter.id}`
              )}
              data-test-id="navigate-question-bank-level-2"
            >
              {chapter.attributes.name}
            </MenuItem>
          );
        })}
      </>
    );
  };

  RenderPracticeTestSubmenuLevel_1 = () => {
    return (
      <>
        {this.state.practiceTestPackagesName.map((subject, index) => {
          return (
            <SubMenu
              label={`${subject.attributes.package_name}`}
              key={`${subject.attributes.package_name}-${subject.id}-pt-subject-${index}`}
              className={this.determineIfActiveLink(`?packageId=${subject.id}`)}
              data-test-id="open-practice-level-1"
            >
              {this.state.practiceTestSubMenuName[index] &&
                this.RenderPracticeTestSubmenuLevel_2(
                  this.state.practiceTestSubMenuName[index],
                  index,
                  subject.id
                )}
            </SubMenu>
          );
        })}
      </>
    );
  };

  RenderPracticeTestSubmenuLevel_2 = (
    practiceTestSubMenuLevel2: SubjectPracticeTestType[],
    indexUpperLevel: number,
    packageId: string
  ) => {
    return (
      <>
        {practiceTestSubMenuLevel2.map((subject, index) => {
          return (
            <MenuItem
              key={`${index}-subject-${subject.id}-practice-test-${indexUpperLevel}`}
              onClick={() =>
                this.navigateTo(
                  `/dashboard/practice-test/chapters/${subject.id}?packageId=${packageId}`
                )
              }
              className={this.determineIfActiveLink(
                `/dashboard/practice-test/chapters/${subject.id}`
              )}
              data-test-id="navigate-practice-level-2"
            >
              {subject.attributes.name}
            </MenuItem>
          );
        })}
      </>
    );
  };

  RenderGrandTestSubmenuLevel_1 = () => {
    return (
      <>
        {this.state.grandTestPackagesName.map((testNames, index) => (
          <MenuItem
            key={`grandtest-${index}-${testNames.package_name}`}
            onClick={() =>
              this.navigateTo(
                `/dashboard/grand-test/subjects/${testNames.gt_code}`
              )
            }
            className={this.determineIfActiveLink(
              `/dashboard/grand-test/subjects/${testNames.gt_code}`
            )}
            data-test-id="navigate-grand-level-1"
          >
            {testNames.package_name}
          </MenuItem>
        ))}
      </>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Modal
          open={this.state.isModalOpenLogout}
          onClose={this.handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box className={this.props.classes.modalStyle}>
            <HighlightOffIcon
              data-test-id="close-modal"
              onClick={this.handleCloseModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                fontSize: "2rem",
                cursor: "pointer",
                color: "gray",
              }}
            />
            <p>You Successfully Logged Out</p>
            <Box data-test-id="logout-btn" onClick={this.navigateToLandingPage}>
              <Button
                key={"buttonKey"}
                color="white"
                height="50px"
                width="200px"
                title="Go Back To Landing Page"
              />
            </Box>
          </Box>
        </Modal>
        <Box className={this.props.classes.dashboard}>
          <Box
            className="dashboard-header"
            component={"header"}
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            boxShadow={"1px 1px 1px gray"}
            padding="0 3em"
          >
            <Box className="header-nav">
              <img
                src={myranklogo}
                alt="logomyrank"
                height={"39px"}
                data-test-id="navigate-to-landing-page"
                onClick={() => this.navigateTo("/")}
              />
              <MenuIcon
                data-test-id="sidebar-btn"
                className="menu-icon"
                onClick={() => this.setShowSidebar()}
              />
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gridGap={"5em"}
              position={"relative"}
            >
              <Box
                width={"170px"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <img
                  style={{ cursor: "pointer" }}
                  src={calculator}
                  alt="calculator"
                  data-test-id="navigate-calculate-btn"
                  onClick={() => this.handleNavigateToCalculate()}
                  width={"23px"}
                />
                <img
                  style={{ cursor: "pointer" }}
                  src={sms}
                  alt="sms"
                  width={"30px"}
                />
                <img
                  style={{ cursor: "pointer" }}
                  src={calendar}
                  alt="calendar"
                  onClick={() => this.bookmySlotNavigate()}
                  data-test-id="bms-click-btn"
                  width={"23px"}
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                style={{ cursor: "pointer" }}
                data-test-id="toggle-profile-popup"
                onClick={() => this.toggleProfilePopUp()}
              >
                {this.state.userData?.attributes.image ? (
                  <img
                    src={this.state.userData.attributes.image}
                    style={{ borderRadius: "50%", width: "30px" }}
                  />
                ) : (
                  <AccountCircleIcon
                    className={this.props.classes.accountImg}
                  />
                )}
                <Typography variant="h6">
                  {this.state.userData?.attributes.name}
                </Typography>
                <span style={{ position: "relative", left: "-5px" }}>
                  <ArrowDropDownIcon style={{ color: "#949497" }} />
                </span>
              </Box>
              <Box
                className={
                  this.state.profilePopup
                    ? this.props.classes.profilePopUp
                    : this.props.classes.profilePopUpHidden
                }
              >
                <Box
                  className={this.props.classes.profilePopUpTypo}
                  height={"50%"}
                  data-test-id="navigate-to-packages"
                  onClick={() => {
                    this.navigateToPackages();
                    this.toggleProfilePopUp();
                  }}
                >
                  <Typography>My Registration</Typography>
                </Box>
                <Box
                  className={this.props.classes.profilePopUpTypo}
                  height={"50%"}
                  onClick={this.logout}
                >
                  <Typography>Sign Out</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box component="main" className="dashboard-main">
            <Sidebar
              className={`dashboard-sidebar`}
              rootStyles={{
                [`.${sidebarClasses.container}`]: {
                  backgroundColor: "inherit",
                  position: "relative",
                  minHeight: "97vh",
                },
              }}
              collapsed={!this.state.showSidebar}
              collapsedWidth="0"
              data-test-id="sidebar-container"
            >
              <Box className={this.props.classes.boxSearch}>
                <AdvancedSearchDashboard
                  navigation={this.props.navigation}
                  id="advanced-search"
                />
              </Box>
              <Menu
                rootStyles={{
                  paddingBottom: "15em",
                  ".ps-menu-button:hover": {
                    background: "inherit",
                  },
                }}
              >
                <MenuItem
                  icon={<img src={home} className="nav-icon" />}
                  onClick={() => this.navigateTo("/dashboard/home")}
                  className={this.determineIfActiveLink("/dashboard/home")}
                  data-test-id="navigate-home"
                >
                  Home
                </MenuItem>
                <MenuItem
                  icon={<LibraryBooksIcon className="nav-icon" />}
                  onClick={() => this.navigateTo("/dashboard/synopsis")}
                  className={this.determineIfActiveLink("/dashboard/synopsis")}
                  data-test-id="navigate-synopsis"
                >
                  Synopsis
                </MenuItem>
                <SubMenu
                  label="Question Bank"
                  rootStyles={rootStyles}
                  icon={<img src={book} className="nav-icon" />}
                  onClick={() => this.navigateTo("/dashboard/question-bank")}
                  className={this.determineIfActiveLink(
                    "/dashboard/question-bank"
                  )}
                  data-test-id="open-questionbank"
                >
                  {this.RenderQuestionBankSubmenuLevel_1()}
                </SubMenu>
                <SubMenu
                  icon={<img src={document} className="nav-icon" />}
                  rootStyles={rootStyles}
                  label="Practice Test"
                  className={this.determineIfActiveLink(
                    "/dashboard/practice-test"
                  )}
                  data-test-id="open-practice"
                >
                  {this.RenderPracticeTestSubmenuLevel_1()}
                </SubMenu>
                <SubMenu
                  icon={<img src={document} className="nav-icon" />}
                  label="Grand Test"
                  rootStyles={rootStyles}
                  className={this.determineIfActiveLink(
                    "/dashboard/grand-test"
                  )}
                  data-test-id="open-grand"
                >
                  {this.RenderGrandTestSubmenuLevel_1()}
                </SubMenu>
                <MenuItem
                  icon={<img src={document} className="nav-icon" />}
                  onClick={() =>
                    this.navigateTo(`/dashboard/weekly-test/instructions`)
                  }
                  className={this.determineIfActiveLink(
                    "/dashboard/weekly-test"
                  )}
                  data-test-id="navigate-weekly"
                >
                  Weekly Test
                </MenuItem>
                <SubMenu
                  icon={<img src={computer} className="nav-icon" />}
                  label="Classes"
                  rootStyles={rootStyles}
                  className={this.determineIfActiveLink("/dashboard/classes")}
                  data-test-id="open-class"
                >
                  <MenuItem
                    onClick={() =>
                      this.navigateTo(`/dashboard/classes/live-class`)
                    }
                    className={this.determineIfActiveLink(
                      "/dashboard/classes/live-class"
                    )}
                    data-test-id="navigate-live"
                  >
                    Live Classes
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      this.navigateTo(`/dashboard/classes/recorded-class`)
                    }
                    className={this.determineIfActiveLink(
                      "/dashboard/classes/recorded-class"
                    )}
                    data-test-id="navigate-recorded"
                  >
                    Recorded Classes
                  </MenuItem>
                </SubMenu>
                <MenuItem
                  icon={<img src={computer} className="nav-icon" />}
                  onClick={() => this.navigateTo(`/dashboard/swayam-prabha`)}
                  className={this.determineIfActiveLink(
                    "/dashboard/swayam-prabha"
                  )}
                  data-test-id="navigate-swayam"
                >
                  Swayam Prabha
                </MenuItem>
                <SubMenu
                  icon={<img src={computer} className="nav-icon" />}
                  label="Ask Your Query"
                  rootStyles={rootStyles}
                  className={this.determineIfActiveLink(
                    "/dashboard/ask-your-query"
                  )}
                  data-test-id="open-ayq"
                >
                  <MenuItem
                    onClick={() =>
                      this.navigateTo(`/dashboard/ask-your-query/questions-all`)
                    }
                    className={this.determineIfActiveLink(
                      "/dashboard/ask-your-query/questions-all"
                    )}
                    data-test-id="navigate-ayq"
                  >
                    Questions
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      this.navigateTo(
                        `/dashboard/ask-your-query/questions-asked`
                      )
                    }
                    className={this.determineIfActiveLink(
                      "/dashboard/ask-your-query/questions-asked"
                    )}
                    data-test-id="navigate-ayq"
                  >
                    Questions Asked
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      this.navigateTo(`/dashboard/ask-your-query/profile`)
                    }
                    className={this.determineIfActiveLink(
                      "/dashboard/ask-your-query/profile"
                    )}
                    data-test-id="navigate-ayq"
                  >
                    Profile
                  </MenuItem>
                </SubMenu>
              </Menu>
              <Menu
                rootStyles={{
                  ".ps-menu-button:hover": {
                    background: "inherit",
                  },
                }}
                className={this.props.classes.logoutStyle}
              >
                <MenuItem
                  icon={<ExitToAppIcon className="nav-icon" />}
                  onClick={this.logout}
                  data-test-id="logout-btn-clear"
                >
                  Logout
                </MenuItem>
              </Menu>
            </Sidebar>
            <Box component="section" className="dashboard-content">
              <RoutesDashboard />
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const rootStyles = {
  ["." + menuClasses.subMenuContent]: {
    backgroundColor: "inherit",
  },
};

export const webStyle = () =>
  createStyles({
    menuStyle: {
      display: "flex",
      flexDirection: "column" as const,
    },
    modalStyle: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.5em",
      position: "absolute" as "absolute",
      height: "210px",
      border: "none",
      backgroundColor: "white",
      borderRadius: "0.5em",
      display: "flex",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "389px",
    },
    accountImg: { fontSize: "2.5em" },
    profilePopUp: {
      display: "flex",
      flexDirection: "column" as const,
      position: "absolute" as const,
      width: "200px",
      height: "100px",
      textAlign: "center" as const,
      top: "60px",
      right: "0px",
      color: "black",
      borderRadius: "0.7em",
      border: "1px solid gray",
      background: "white",
    },
    profilePopUpTypo: {
      "&:hover": {
        background: "rgb(81,0,129)",
        color: "white",
        borderRadius: "0.35em",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    profilePopUpHidden: {
      display: "none",
    },
    dashboard: {
      display: "flex",
      flexDirection: "column",

      "& input": {
        outline: "none",
      },

      "& .dashboard-header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "71px",
        width: "100%",

        "& .header-nav": {
          display: "flex",
          alignItems: "center",
          gap: "1em",

          "& h1": {
            fontSize: "2rem",
            fontWeight: "bold",
          },

          "& img": {
            cursor: "pointer",
          },

          "& .menu-icon": {
            display: "inline-block",
            width: "100px",
            fontSize: "2em",
            cursor: "pointer",
          },
        },
      },

      "& .dashboard-main": {
        display: "flex",
        width: "100%",
        marginBottom: "5em",

        "& .active-link": {
          "& > .ps-menu-button > .ps-menu-label": {
            color: "white",
            fontWeight: 700,
          },
        },

        "& .submenu": {
          marginLeft: "2em",
          fontSize: "14px",

          "& > div": {
            margin: "0.5em 0",
          },

          "& .submenu-inner": {
            "& > a": {
              margin: "0.5em 0",
            },
          },
        },

        "& .dashboard-sidebar": {
          backgroundColor: "#520082",
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderBottomRightRadius: "2em",
          padding: "2em 0",
          transition: "all 0.3s ease",
          position: "relative",

          "& *": {
            whiteSpace: "wrap",
          },

          "& .nav-icon": {
            width: "20px",
            height: "20px",
            color: "#a77ec0",
          },
        },

        "& .dashboard-content": {
          minHeight: "100vh",
          height: "max-content",
          width: "100%",
        },
      },
    },
    boxSearch: {
      padding: "1em 1em",
    },
    logoutStyle: {
      position: "absolute",
      bottom: "25px",
    },
  });

export default withAuth(withStyles(webStyle)(Dashboard));
export { Dashboard };
// Customizable Area End
