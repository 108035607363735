import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
export type Subject = {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    image: string;
    created_by: string;
    class_zone: string;
    practice_test: string;
    question_bank: string;
    subjective_test: string;
    st_code: string;
    remarks: string;
    icon_code: string;
    search_key: any;
    total_topics: number;
    saved_notes: number;
  };
};

export type Chapter = {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    image: string;
    practice_test: string;
    avail_pt: string;
    date_pt: string;
    class_zone: string;
    avail_cz: string;
    date_cz: string;
    question_bank: string;
    avail_qb: string;
    date_qb: string;
    created_by: string;
    remarks: string;
    chapter_read: boolean;
    viewed: boolean;
    continue_reading_topic_id: number;
    test_attempted: boolean;
    subject: {
      id: number;
      created_at: string;
      updated_at: string;
      name: string;
      created_by: string;
      class_zone: string;
      practice_test: string;
      question_bank: string;
      subjective_test: string;
      st_code: string;
      remarks: string;
      icon_code: string;
      st_db: string;
      score_db: string;
      chapter_db: string;
    };
  };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  subjects: Subject[];
  popUpStates: boolean[];
  subjectActive: boolean;
  currentChapters: Chapter[];
  meta: {
    all_slots: number;
    all_booked_slots: number;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotStudentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStudentSubjectsMessageId = "";
  getChaptersOnSubjectIdMessageId = "";
  getBookedSlotsMessageId = "";
  breadcrumb = [
    {
      link: `/dashboard/book-my-slot-student`,
      label: "Book my Slot Students",
    },
    {
      link: `/dashboard/book-my-slot-student`,
      label: "Bookings",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      subjects: [],
      subjectActive: false,
      popUpStates: [],
      currentChapters: [],
      meta: {
        all_booked_slots: 0,
        all_slots: 0,
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getStudentSubjectsMessageId && response) {
      if (response?.data) {
        this.handleDataSubjects(response.data);
      }
    }

    if (apiRequestCallId === this.getChaptersOnSubjectIdMessageId && response) {
      if (response?.data) {
        this.handleDataChapters(response.data);
      }
    }

    if (apiRequestCallId === this.getBookedSlotsMessageId && response) {
      if (response?.meta) {
        this.handleAvailableBookedSlots(response.meta);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getStudentSubjects();
    this.getBookedSlots();

    const propPassingStudent = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingStudent.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingStudent);
  };

  handleAvailableBookedSlots = (meta: {
    all_slots: number;
    all_booked_slots: number;
  }) => {
    this.setState({
      meta,
      loading: false,
    });
  };

  // Customizable Area Start
  handleDataSubjects = (response: Subject[]) => {
    const popUpStates = new Array(response.length).fill(false);

    this.setState({
      subjects: response,
      loading: false,
      popUpStates,
    });
  };

  handleDataChapters = (response: Chapter[]) => {
    this.setState({
      currentChapters: response,
      loading: false,
    });
  };

  getStudentSubjects = async () => {
    const getStudentSubjectsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getStudentSubjectsMessageId = getStudentSubjectsMessage.messageId;

    getStudentSubjectsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bookingSubjectsStudent
    );

    getStudentSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getStudentSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getStudentSubjectsMessage.id,
      getStudentSubjectsMessage
    );
  };

  setPopUpStateToggle = (index: number, subjectId: string) => {
    this.setState(
      (prevState) => {
        const popUpStates = new Array(prevState.popUpStates.length).fill(false);

        if (!prevState.popUpStates[index]) {
          popUpStates[index] = true;
          return {
            popUpStates,
            currentChapters: [],
          };
        }

        return {
          popUpStates,
          currentChapters: prevState.currentChapters,
        };
      },
      () => {
        if (this.state.popUpStates[index]) {
          this.getChaptersOnSubjectId(subjectId);
        }
      }
    );
  };

  subjectActive = () => {
    this.setState({
      subjectActive: true,
    });
  };

  getChaptersOnSubjectId = async (subjectId: string) => {
    const getChaptersOnSubjectIdMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getChaptersOnSubjectIdMessageId =
      getChaptersOnSubjectIdMessage.messageId;

    getChaptersOnSubjectIdMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/bms_bookeds/${subjectId}/chapters`
    );

    getChaptersOnSubjectIdMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getChaptersOnSubjectIdMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getChaptersOnSubjectIdMessage.id,
      getChaptersOnSubjectIdMessage
    );
  };

  getBookedSlots = async () => {
    const getBookedSlotsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getBookedSlotsMessageId = getBookedSlotsMessage.messageId;

    getBookedSlotsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/bms_bookeds`
    );

    getBookedSlotsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getBookedSlotsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getBookedSlotsMessage.id, getBookedSlotsMessage);
  };

  handleToBookingHistory = () => {
    this.props.navigation.navigate("BookMySlotStudentAppointments");
  };

  navigateToBookSlot = (chapterId: string, subjectId: string) => {
    this.props.navigation.history.push(
      `/dashboard/book-my-slot-student/book-slot?chapterId=${chapterId}&subjectId=${subjectId}`
    );
  };
  // Customizable Area End
}
