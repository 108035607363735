import React, { ComponentType } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface WithAuthProps extends RouteComponentProps {
  WrappedComponent: ComponentType<any>;
}

class WithAuth extends React.Component<WithAuthProps> {
  componentDidMount() {
    const authId = localStorage.getItem('authToken');

    if (!authId) {
      const currentPath = this.props.location.pathname + this.props.location.search;
      this.props.history.push(`/login?callback=${encodeURIComponent(currentPath)}`);
    }
  }

  render() {
    const { WrappedComponent, ...restProps } = this.props;
    return <WrappedComponent {...restProps} />;
  }
}

const withAuth = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const WithAuthComponent: React.FC<P & RouteComponentProps> = (props) => (
    <WithAuth {...props} WrappedComponent={WrappedComponent} />
  );

  return withRouter(WithAuthComponent);
};

export default withAuth;
