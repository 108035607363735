import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import { createStyles, Theme } from "@material-ui/core/styles";
export const styles = (theme: Theme) =>
  createStyles({
    questionBankStyle: {
      padding: "0 2em",
      width: "100%",
      "& .test-box-container": {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "1.5em",
        height: "max-content",
        marginTop: "1.5em",
        "& .test-box": {
          borderRadius: "0.5em",
          border: "1px solid rgb(178, 176, 176)",
          minHeight: "140px",
          width: "200px",
          height: "max-content",
          "& .icon-container": {
            justifyContent: "center",
            display: "flex",
            height: "100px",
            alignItems: "center",
            width: "100%",
            "& .sample-image": {
              border: "none",
              background: "rgb(178, 176, 176)",
              width: "70px",
              borderRadius: "50%",
              height: "70px",
            },
          },
          "& .link-test": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTop: "1px solid rgb(178, 176, 176)",
            height: "max-content",
            background: "rgb(178, 176, 176)",
            borderRadius: "0 0 0.43em 0.43em",
            textAlign: "center",
            color: "white",
            padding: "1.5em",
            fontWeight: "bold",
          },
          "&:hover": {
            border: "1px solid #500084",
            "& .link-test": {
              backgroundColor: "#500084",
            },
          },
        },
      },
    },
  });
// Customizable Area End

import QuestionBankController, {
  Props,
  configJSON,
} from "./QuestionBankController.web";

class QuestionBank extends QuestionBankController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const classes = this.props.classes;
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box sx={webStyles.bannerStyle}>
          <h2>Question Bank</h2>
        </Box>
        <Box className={classes.questionBankStyle}>
          <p style={webStyles.selectSubject}>
            {configJSON.selectSubjectContinue}
          </p>
          <Box className="test-box-container">
            {this.state.subjects.map((subject) => {
              return (
                <NavLink
                  key={subject.attributes.name}
                  to={`/dashboard/question-bank/subject/${subject.id}`}
                >
                  <Box className="test-box">
                    <Box className="icon-container">
                      <img
                        src={subject.attributes.image}
                        className="sample-image"
                        alt={subject.attributes.name}
                      />
                    </Box>
                    <p className="link-test">{subject.attributes.name}</p>
                  </Box>
                </NavLink>
              );
            })}
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyles = {
  bannerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    color: "rgb(82, 0, 130)",
  },
  selectSubject: {
    fontSize: "1.5em",
    fontWeight: 500,
    marginTop: "3em",
  },
};
export default withStyles(styles)(QuestionBank);
export { QuestionBank };
// Customizable Area End
