import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import AYQEditProfileController, {
  Props,
} from "./AYQEditProfileController.web";

class AYQEditProfile extends AYQEditProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Box className={this.props.classes.bannerStyle}>
          <Typography variant="h5">Edit Profile</Typography>
        </Box>
        <Loader loading={this.state.loading} />
        <Box padding={"3em 5em"}>
          <Box className={this.props.classes.editContainerStyle}>
            {this.state.userProfile ? (
              <>
                <Box
                  display="flex"
                  gridGap="1.5em"
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  width="500px"
                >
                  <img
                    width={"100px"}
                    className={this.props.classes.profileImage}
                    src={this.state.userProfile.data.attributes.image}
                    alt="profile-img"
                  />
                  <Box>
                    <Typography className={this.props.classes.textWeight}>
                      {this.state.userProfile.data.attributes.first_name
                        ? this.state.userProfile.data.attributes.first_name +
                          " " +
                          this.state.userProfile.data.attributes.last_name
                        : this.state.userProfile.data.attributes.name}
                    </Typography>
                    <Typography className={this.props.classes.textWeight}>
                      {this.state.userProfile.data.attributes.myrank_id}
                    </Typography>
                  </Box>
                </Box>
                <form
                  onSubmit={(event) => this.handleSubmit(event)}
                  data-test-id="submit"
                >
                  <input
                    style={{ fontWeight: 700, background: "#F3EEF5" }}
                    value={this.state.userProfile.data.attributes.myrank_id}
                    disabled
                  />
                  <Box
                    display="flex"
                    width={"100%"}
                    justifyContent={"space-between"}
                    gridGap={"1em"}
                  >
                    <input
                      value={this.state.inputs.fname}
                      name="fname"
                      onChange={(event) => this.handleInputChange(event)}
                      placeholder="First Name"
                      data-test-id="input"
                    />
                    <input
                      value={this.state.inputs.lname}
                      name="lname"
                      onChange={(event) => this.handleInputChange(event)}
                      placeholder="Last Name"
                      data-test-id="input"
                    />
                  </Box>
                  <input
                    value={this.state.inputs.number}
                    name="number"
                    type="tel"
                    pattern="[0-9]{10}"
                    onChange={(event) => this.handleInputChange(event)}
                    placeholder="Phone Number"
                    data-test-id="input"
                  />
                  <input
                    value={this.state.inputs.email}
                    name="email"
                    onChange={(event) => this.handleInputChange(event)}
                    type="email"
                    placeholder="Email Id"
                    data-test-id="input"
                  />
                  <input
                    value={this.state.inputs.city}
                    name="city"
                    onChange={(event) => this.handleInputChange(event)}
                    type="text"
                    placeholder="City"
                    data-test-id="input"
                  />
                  <Button
                    className={this.props.classes.buttonStyleYes}
                    type="submit"
                  >
                    Save
                  </Button>
                </form>
              </>
            ) : (
              <Box>No Data Found</Box>
            )}
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleAYQEditProfile = () =>
  createStyles({
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "#510081",
      display: "flex",
      background: "rgb(247,242,249)",
      height: "71px",
      "& h5": {
        fontWeight: 700,
      },
    },
    editContainerStyle: {
      background: "#eee",
      minHeight: "1000px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "2em",
      "& form": {
        marginTop: "4em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1.5em",
        "& input": {
          width: "500px",
          height: "70px",
          padding: "0 1.5em",
          borderRadius: "0.9em",
          border: "1.5px solid #F86156",
          color: "black",
          fontSize: "1.1em",
          outline: "none",
        },
        "& div": {
          width: "500px",
        },
        "& div input": {
          width: "50%",
        },
      },
    },
    buttonStyleYes: {
      color: "white",
      marginTop: "2.5em",
      textTransform: "none" as const,
      height: "50px",
      marginLeft: "1em",
      borderRadius: "1em",
      width: "400px",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    },
    textWeight: {
      fontWeight: 600,
    },
    profileImage: {
      width: "100px",
      borderRadius: "50%",
      border: "none",
      height: "100px",
      objectFit: "cover",
    },
  });
export default withStyles(webStyleAYQEditProfile)(AYQEditProfile);
export { AYQEditProfile };
// Customizable Area End
