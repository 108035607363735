export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backImg = require("../assets/back.png");
export const ButtonBG = require('../assets/button_filled.png');
export const Buttonoutline = require('../assets/button_ouyline.png');
export const Bookmark = require('../assets/bookrmarkImg.png');
export const warning = require('../assets/icons8-warning-24(1).png');
export const saveImg = require('../assets/saveImg.png');
export const Arrow = require('../assets/arrow-circle-right-copy-6.png');
export const Delete = require('../assets/trash.png');
export const PlusIcon = require('../assets/pngwing.png');
export const rightIcon = require('../assets/submitIcon.png')
export const noreview = require('../assets/no-review.png');