import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { SubjectData } from "../../../blocks/ContentManagement/src/SynopsisSubjectsController.web";
export type StatsQuestionBank = {
  chapters: Array<{
    chapter_name: string;
    covered: number;
    pending: number;
  }>;
  meta: {
    subject: string;
    covered: number;
    pending: number;
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedChoice: string;
  subjects: SubjectData[];
  stats: StatsQuestionBank | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UsageStatsQuestionBankController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUsageStatsQuestionBankStatsMessageId: string = "";
  getUsageStatsQuestionBankSubjectsMessageId: string = "";
  buttons = [
    "Synopsis",
    "Weekly Test",
    "Grand Test",
    "Practice Test",
    "Live Classes",
    "Recorded Classes",
    "Question Bank",
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      selectedChoice: "",
      subjects: [],
      stats: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      apiRequestCallId === this.getUsageStatsQuestionBankSubjectsMessageId &&
      response
    ) {
      if (response?.data) {
        this.handleQuestionBankSubjectData(response.data);
      }
    }

    if (apiRequestCallId === this.getUsageStatsQuestionBankStatsMessageId) {
      if (response?.chapters) {
        this.handleStatsData(response);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getUsageStatsQuestionBankSubjects();
  };

  handleStatsData = (stats: StatsQuestionBank) => {
    this.setState({
      stats,
      loading: false,
    });
  };

  handleNoneSubject = () => {
    this.setState({
      stats: null,
    });
  };

  // Customizable Area Start
  getUsageStatsQuestionBankSubjects = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };
    const endpoint = `bx_block_questionbank/question_banks/qb_subjects`;
    const requestType = "GET";

    this.setState({
      loading: true,
    });
    const getUsageStatsQuestionBankSubjectsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUsageStatsQuestionBankSubjectsMessageId =
      getUsageStatsQuestionBankSubjectsMessage.messageId;

    getUsageStatsQuestionBankSubjectsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    getUsageStatsQuestionBankSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    getUsageStatsQuestionBankSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      requestType
    );
    runEngine.sendMessage(
      getUsageStatsQuestionBankSubjectsMessage.id,
      getUsageStatsQuestionBankSubjectsMessage
    );
  };

  onChangeSelect = (selectedChoice: string) => {
    this.setState(
      {
        selectedChoice,
      },
      () => this.getUsageStatsQuestionBankStats(selectedChoice)
    );
  };

  getUsageStatsQuestionBankStats = async (subject: string) => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getUsageStatsQuestionBankStatsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUsageStatsQuestionBankStatsMessageId =
      getUsageStatsQuestionBankStatsMessage.messageId;

    getUsageStatsQuestionBankStatsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.usageStatsQB}${subject}`
    );

    getUsageStatsQuestionBankStatsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUsageStatsQuestionBankStatsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getUsageStatsQuestionBankStatsMessage.id,
      getUsageStatsQuestionBankStatsMessage
    );
  };
  handleQuestionBankSubjectData = (data: SubjectData[]) => {
    this.setState({
      subjects: data,
      loading: false,
    });
  };
  // Customizable Area End
}
