import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CalendarComponent from "../../../components/src/Calendar.web";
import Loader from "../../../components/src/Loader.web";
import BannerWeb from "../../../components/src/Banner.web";
// Customizable Area End

import BookMySlotChangeSlotController, {
  Props,
} from "./BookMySlotChangeSlotController.web";

class BookMySlotChangeSlot extends BookMySlotChangeSlotController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <BannerWeb title="Slot Booking" />
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          minHeight={"90vh"}
          marginTop={"3em"}
        >
          <Typography></Typography>
          <Box width={"70%"}>
            <CalendarComponent
              onChangeDate={(value) => this.changeDateChange(value)}
            />
            <Box marginTop={"3em"}>
              <Box display="flex" justifyContent={"space-between"}>
                <Typography className={this.props.classes.bookedslotsTypo}>
                  Booked Slots (
                  {this.state.slots.map((timeSlots) => {
                    if (timeSlots.date === this.state.valueDate) {
                      return timeSlots.slots.length;
                    }
                  })}
                  )
                </Typography>
                <Typography className={this.props.classes.availableTypo}>
                  Available -{" "}
                  {this.state.slots.map((timeSlots) => {
                    if (timeSlots.date === this.state.valueDate) {
                      return timeSlots.slots.filter(
                        (slot) => slot.availability === "available"
                      ).length;
                    }
                  })}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                gridGap={"1em"}
                flexWrap={"wrap"}
                margin={"1em 0"}
              >
                {this.state.slots.map((timeSlots) => {
                  if (timeSlots.date === this.state.valueDate) {
                    return timeSlots.slots.map((slots) => {
                      return (
                        <Button
                          onClick={() => this.setTimeSlot(slots.slot_time)}
                          data-test-id="set-time-slot"
                          className={`${this.props.classes.slotTime} ${
                            this.state.timeSlot === slots.slot_time
                              ? "active"
                              : ""
                          } ${
                            slots.availability === "notavailable"
                              ? "inactive"
                              : ""
                          }`}
                          disabled={slots.availability === "notavailable"}
                        >
                          {slots.slot_time}
                        </Button>
                      );
                    });
                  }
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleBookMySlotChangeSlot = () =>
  createStyles({
    slotTime: {
      border: "none",
      background: "rgb(148,250,143)",
      color: "#808080",
      "&.active": {
        background: "rgb(113,215,108)",
        color: "white",
      },
      "&.inactive": {
        background: "#E91B1B",
        color: "white",
        border: "none",
      },
    },
    bookedslotsTypo: {
      fontWeight: 700,
      fontSize: "1.3rem",
    },
    availableTypo: { fontWeight: 500, fontSize: "1.3rem", color: "#520082" },
  });
export default withStyles(webStyleBookMySlotChangeSlot)(BookMySlotChangeSlot);
export { BookMySlotChangeSlot };
// Customizable Area End
