import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { extractParams } from "../../../framework/src/UtilsWeb";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  noteType: string;
  questionId: string;
  questionNumber: string;
  content: string;
  noteId: string;
  isNoteCreateSavedSuccessfully: boolean;
  isNoteCancellationModalOpen: boolean;
  cancelModalConfirm: boolean;
  reviewId: string;
  loading: boolean;
  chapterName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SavedNotesAddEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  token = "";
  searchResolve = this.props.navigation.history.location.search;
  editSavedNotesMessageId = "";
  createSavedNotesMessageId = "";
  getResultsMessageSNAECId = "";
  getSubjectsMessageSNAECId = "";
  pathnameSearchResolve =
    this.props.navigation.history.location.pathname +
    this.props.navigation.history.location.search;
  breadcrumb = [
    {
      label: "Practice Test",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}${this.searchResolve}`,
    },
    {
      label: "Chapters",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}${this.searchResolve}`,
    },
    {
      label: "Reviews",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/review-test/${this.props.navigation.getParam("chapterId")}${
        this.searchResolve
      }`,
    },
    {
      label: "Reviews",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/review-test/${this.props.navigation.getParam("chapterId")}${
        this.searchResolve
      }`,
    },
    {
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/review-test/${this.props.navigation.getParam("chapterId")}${
        this.searchResolve
      }/review/${this.props.navigation.getParam("reviewId")}${
        this.searchResolve
      }`,
      label: "Review Test",
    },
    {
      label: "Saved Notes",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/review-test/${this.props.navigation.getParam(
        "chapterId"
      )}/saved-notes/${this.props.navigation.getParam("reviewId")}${
        this.searchResolve
      }`,
    },
    {
      label: "Add / Edit Notes",
      link: this.pathnameSearchResolve,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      noteType: "",
      questionId: "",
      questionNumber: "",
      noteId: "",
      content: "",
      isNoteCreateSavedSuccessfully: false,
      isNoteCancellationModalOpen: false,
      cancelModalConfirm: false,
      reviewId: "",
      loading: false,
      chapterName: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.createSavedNotesMessageId) {
      if (response?.id) {
        this.setState({
          isNoteCreateSavedSuccessfully: true,
        });
      }
    }

    if (apiRequestCallId === this.editSavedNotesMessageId) {
      if (response?.id) {
        this.setState({
          isNoteCreateSavedSuccessfully: true,
        });
      }
    }

    if (apiRequestCallId === this.getSubjectsMessageSNAECId && response) {
      if (response.data) {
        this.handleSuccessSubject(response.data);
      }
    }

    if (apiRequestCallId === this.getResultsMessageSNAECId) {
      if (response.meta) {
        const breadcrumb = this.breadcrumb;
        breadcrumb[2].label = response.meta.chapter.name;

        const propPassingSNAEC = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingSNAEC.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );

        this.send(propPassingSNAEC);
      }

      this.setState({
        loading: false,
        chapterName: response.meta.chapter.name,
      });
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const token = await storage.get("authToken");
    if (token) {
      this.token = token;
    }

    const noteType = this.props.navigation.getParam("noteType");
    const reviewId = this.props.navigation.getParam("reviewId");
    const questionId = this.props.navigation.getParam("qid");
    const questionNumber = this.props.navigation.getParam("qno");
    const noteId = this.props.navigation.getParam("noteId");

    if (noteType && reviewId) {
      this.setState({
        noteType,
        reviewId,
      });
    }

    if (noteId) {
      this.setState({
        questionId: questionId as string,
        questionNumber: questionNumber as string,
        noteId,
      });
    }

    const propPassingSNAEC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSNAEC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingSNAEC);

    this.getSubjects();
    this.getReview();
  };

  handleInputChange = (value: string) => {
    this.setState({
      content: value,
    });
  };

  handleNoteCreateModalClose = () => {
    this.setState({
      isNoteCreateSavedSuccessfully: false,
    });
  };

  handleNoteCancelModalClose = () => {
    this.setState({
      isNoteCancellationModalOpen: false,
    });
  };

  handleNoteCancelModalOpen = () => {
    this.setState({
      isNoteCancellationModalOpen: true,
    });
  };

  handleNoteCancelConfirmModalOpen = () => {
    this.setState({
      cancelModalConfirm: true,
    });
  };

  handleNoteCancelConfirmModalClose = () => {
    this.setState({
      cancelModalConfirm: false,
    });
  };

  confirmCancel = () => {
    this.handleNoteCancelModalClose();
    this.handleNoteCancelConfirmModalOpen();
  };

  // Customizable Area Start
  NoteApi = () => {
    if (this.state.noteType === "Edit") {
      const headers = {
        token: this.token,
      };

      const editSavedNotesMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editSavedNotesMessageId = editSavedNotesMessage.messageId;

      editSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
      );

      editSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_assessmenttest/notes/${this.state.noteId}?content=${this.state.content}`
      );

      editSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      runEngine.sendMessage(editSavedNotesMessage.id, editSavedNotesMessage);
    } else {
      const headers = {
        token: this.token,
        "Content-Type": "application/json",
      };

      const body = {
        noteable_id: this.state.reviewId,
        noteable_type: "PracticeTestReviewHistory",
        content: this.state.content,
        question_id: this.state.questionId,
        question_no: this.state.questionNumber,
      };

      const createSavedNotesMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      this.createSavedNotesMessageId = createSavedNotesMessage.messageId;

      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_assessmenttest/notes`
      );

      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      runEngine.sendMessage(
        createSavedNotesMessage.id,
        createSavedNotesMessage
      );
    }
  };

  getSubjects = async () => {
    const urlSearch = this.props.navigation.history.location.search;
    const packageId = extractParams(urlSearch, "packageId");
    const getSubjectsMessageSNAEC: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    this.getSubjectsMessageSNAECId = getSubjectsMessageSNAEC.messageId;

    getSubjectsMessageSNAEC.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${packageId}/get_subjects`
    );

    getSubjectsMessageSNAEC.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectsMessageSNAEC.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSubjectsMessageSNAEC.id, getSubjectsMessageSNAEC);
  };

  handleSuccessSubject = (
    response: {
      id: string;
      attributes: { name: string };
    }[]
  ) => {
    const subjectId = this.props.navigation.getParam("subjectId");
    for (const data of response) {
      if (data.id === subjectId) {
        const breadcrumb = this.breadcrumb;
        breadcrumb[1].label = data.attributes.name;

        const propPassingReviewSNAEC = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingReviewSNAEC.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingReviewSNAEC);

        this.setState({
          loading: false,
        });
      }
    }
  };

  getReview = () => {
    const headers = {
      token: this.token,
    };

    const getResultsMessageSNAEC = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    this.getResultsMessageSNAECId = getResultsMessageSNAEC.messageId;

    getResultsMessageSNAEC.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${this.props.navigation.getParam(
        "chapterId"
      )}/review_test`
    );

    getResultsMessageSNAEC.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getResultsMessageSNAEC.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getResultsMessageSNAEC.id, getResultsMessageSNAEC);
  };
  // Customizable Area End
}
