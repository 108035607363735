import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { format } from "date-fns";
import { WithStyles } from "@material-ui/core/styles";
import storage from "../../../framework/src/StorageProvider.web";
import { ValueType } from "react-select";
export type Root = {
  id: string;
  type: string;
  attributes: {
    id: number;
    test_name: string;
    test_subject: string;
    test_category: string;
    no_of_questions: string | number | null;
    duration: number;
    start_time: string;
    end_time: string;
    status: string;
    created_at: string;
    test_submitted_already: boolean;
    image: string;
    search_key: string | number | null;
  };
};

type OptionType = {
  testName: Array<{ value: string; label: string }>;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tests: Root[];
  instructions: { content: string; display_order: number }[];
  isModalOpenFilter: boolean;
  isModalSort: boolean;
  search: string;
  userInput: {
    currentDate: string;
  };
  selectedOption: string;
  option: OptionType;
  subject: string;
  loading: boolean;
  currentTestsPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WeeklyTestTakeTestReviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getResultsMessageId = "";
  private searchTimeout: NodeJS.Timeout | null = null;
  filterMessageId = "";
  searchMessageId = "";
  sortMessageId = "";
  breadcrumb = [
    {
      link: "/dashboard/weekly-test/instructions",
      label: "Weekly Test",
    },
    {
      label: "Instructions",
      link: "/dashboard/weekly-test/instructions",
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Tests",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      tests: [],
      instructions: [],
      isModalOpenFilter: false,
      isModalSort: false,
      search: "",
      userInput: {
        currentDate: "",
      },
      selectedOption: "",
      option: {
        testName: [],
      },
      subject: "",
      loading: false,
      currentTestsPage: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getResultsMessageId && response) {
      if (response.data) {
        const option: OptionType = {
          testName: [],
        };

        response.data.forEach((responseData: Root) => {
          const { test_name } = responseData.attributes;
          if (
            test_name &&
            !option.testName.some((opt) => opt.value === test_name)
          ) {
            option.testName.push({ value: test_name, label: test_name });
          }
        });

        this.setState({
          tests: response.data,
          option,
          loading: false,
          currentTestsPage: 1,
        });
      }
    }

    if (
      (apiRequestCallId === this.filterMessageId ||
        apiRequestCallId === this.searchMessageId ||
        apiRequestCallId === this.sortMessageId) &&
      response
    ) {
      if (response.data) {
        this.setState({
          tests: response.data,
          userInput: {
            currentDate: "",
          },
          subject: "",
          selectedOption: "",
          isModalOpenFilter: false,
          isModalSort: false,
          loading: false,
          currentTestsPage: 1,
        });
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getTests();
    const propPassingWTTTRC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingWTTTRC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingWTTTRC);
  };

  handleDate = (date: string) => {
    const formattedDate = format(new Date(date), "dd MMM yyyy");
    return formattedDate;
  };

  handleDays = (date: string) => {
    const formattedTime = format(new Date(date), "hh:mm a");
    return formattedTime;
  };

  handleModalFilter = () => {
    this.setState({
      isModalOpenFilter: !this.state.isModalOpenFilter,
    });
  };

  handleUserInputChangeCurrent = (
    event: ValueType<
      {
        value: string;
        label: string;
      },
      false
    >
  ) => {
    this.setState({
      subject: event?.value as string,
    });
  };

  navigateToReview = (testId: string) => {
    this.props.navigation.history.push(
      `/dashboard/weekly-test/tests/review-test/${testId}`
    );
  };

  navigateToTest = (testId: string) => {
    this.props.navigation.history.push(
      `/dashboard/weekly-test/tests/take-test/${testId}`
    );
  };

  handleModalSort = () => {
    this.setState({
      isModalSort: !this.state.isModalSort,
    });
  };

  onKeyEnterPressSearch = (eventKey: string) => {
    if (eventKey === "Enter") {
      this.performSearch(this.state.search);
    }
  };

  handleUserInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;

    this.setState({
      userInput: {
        ...this.state.userInput,
        [name]: event.target.value,
      },
    });
  };

  handleSearch = (search: string) => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.setState(
      {
        search,
      },
      () => {
        this.searchTimeout = setTimeout(() => {
          this.performSearch(search);
        }, 600);
      }
    );
  };

  handleCheckboxChange = (option: string) => {
    this.setState({
      selectedOption: option,
    });
  };

  handleTestPage = (value: number) => {
    this.setState({
      currentTestsPage: value,
    });
  };

  // Customizable Area Start
  getTests = async () => {
    this.setState({
      loading: true,
    });
    const headers = {
      token: await storage.get("authToken"),
    };

    const getResultsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getResultsMessageId = getResultsMessage.messageId;

    getResultsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/get_tests`
    );

    getResultsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getResultsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getResultsMessage.id, getResultsMessage);
  };

  performSearch = async (search: string) => {
    this.setState({
      loading: true,
    });
    const headers = {
      token: await storage.get("authToken"),
    };

    const searchMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchMessageId = searchMessage.messageId;

    searchMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/get_tests?test_subject=${search}&test_name=${search}`
    );

    searchMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    searchMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(searchMessage.id, searchMessage);
  };

  doneFilter = async () => {
    this.setState({
      loading: true,
    });
    const headers = {
      token: await storage.get("authToken"),
    };

    const filterMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.filterMessageId = filterMessage.messageId;

    filterMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/get_tests?test_name=${this.state.subject}&date=${this.state.userInput.currentDate}`
    );

    filterMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    filterMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(filterMessage.id, filterMessage);
  };

  doneSort = async () => {
    this.setState({
      loading: true,
    });
    const headers = {
      token: await storage.get("authToken"),
    };

    const sortMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.sortMessageId = sortMessage.messageId;

    sortMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/get_tests?sort_by=${this.state.selectedOption}`
    );

    sortMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    sortMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(sortMessage.id, sortMessage);
  };
  // Customizable Area End
}
