import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { nosavednotes } from "./assets";
import CButton from "../../../components/src/Button.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import SynopsisBookmarksController, {
  Props,
} from "./SynopsisBookmarksController.web";
import { addCircle } from "./assets";

class SynopsisBookmarks extends SynopsisBookmarksController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Loader loading={this.state.loading} />
        <Box className={this.props.classes.bannerStyle}>
          <Typography variant="h5">Bookmarks</Typography>
        </Box>
        <Box
          width={"80%"}
          marginTop="2em"
          display="flex"
          flexDirection={"column"}
          justifyContent={"center"}
        >
          {this.state.bookmarks.length > 0 && (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h6">
                Subject: {this.state.subject?.attributes.name}
              </Typography>
              <Typography variant="h6">
                Total Bookmarks: {this.state.bookmarks.length}
              </Typography>
            </Box>
          )}
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"1em"}
          >
            {this.state.bookmarks.map((bookmark) => {
              return (
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  width={"100%"}
                  border={"none"}
                  borderRadius={"0.7em"}
                  height={"100px"}
                  bgcolor={"#F0EEF2"}
                  position="relative"
                >
                  <Box padding="1em">
                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                      {bookmark.attributes.bookmarkable.name}
                    </Typography>
                    <Typography style={{ color: "#777" }}>
                      Saved Notes - 1{" "}
                      <span
                        onClick={this.navigateToTopicRead}
                        style={{
                          textDecoration: "underline",
                          color: "#F64752",
                          fontSize: "0.8em",
                          cursor: "pointer",
                        }}
                      >
                        View
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    onClick={this.navigateToTopicRead}
                    position="absolute"
                    right="30px"
                    top="26px"
                    style={{ cursor: "pointer" }}
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <img src={addCircle} width="25px" />
                    <Typography>Add New</Typography>
                  </Box>
                </Box>
              );
            })}

            {this.state.bookmarks.length === 0 && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                gridGap={"1em"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography variant="h5">
                  There is no Saved Bookmarks
                </Typography>{" "}
                <img src={nosavednotes} alt="illustration-bookmark-empty" />
                <CButton
                  color="white"
                  height="50px"
                  width="220px"
                  title="Add New Bookmark"
                  key={"button"}
                  onClick={this.navigateToNewBookmark}
                  data-test-id="nav-bookmark"
                />
                <CButton
                  color="#FE495E"
                  height="50px"
                  width="220px"
                  title="Cancel"
                  background="white"
                  border="1px solid #FE495E"
                  key={"button-cancel"}
                  onClick={this.navigateToTopic}
                  data-test-id="nav-cancel"
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleSynopsisBookmarks = () =>
  createStyles({
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "#480B83",
      display: "flex",
      background: "rgb(247,242,249)",
      height: "71px",
      "& h5": {
        fontWeight: 600,
      },
    },
  });
export default withStyles(webStyleSynopsisBookmarks)(SynopsisBookmarks);
export { SynopsisBookmarks };
// Customizable Area End
