import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { WithStyles } from "@material-ui/core";
import { webStyleSynopsisChapters } from "./SynopsisChapters.web";
import { SubjectData } from "./SynopsisSubjectsController.web";
export interface ChapterData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    image: string;
    practice_test: ActivityStatus;
    avail_pt: ActivityStatus;
    date_pt: string | null;
    class_zone: ActivityStatus;
    avail_cz: ActivityStatus;
    date_cz: string | null;
    question_bank: ActivityStatus;
    avail_qb: ActivityStatus;
    date_qb: string | null;
    created_by: string;
    remarks: string;
    chapter_read: boolean;
    viewed: boolean;
    continue_reading_topic_id: number;
  };
}

type ActivityStatus = "inactive" | "active";
// Customizable Area End

export interface Props extends WithStyles<typeof webStyleSynopsisChapters> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  subjectName: SubjectData | null;
  chaptersCovered: ChapterData[];
  chapterId: string;
  chaptersNotCovered: ChapterData[];
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SynopsisChaptersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getChaptersMessageId = "";
  getSubjectsForChaptersMessageId = "";
  breadcrumb = [
    {
      label: "Synopsis",
      link: `/dashboard/synopsis`,
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Chapters",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      subjectName: null,
      chaptersCovered: [],
      chaptersNotCovered: [],
      loading: false,
      chapterId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getChaptersMessageId) {
      if (response.data) {
        this.handleChaptersData(response.data);
      }
    }

    if (apiRequestCallId === this.getSubjectsForChaptersMessageId) {
      if (response.data) {
        this.handleSubjects(response.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingSCC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSCC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingSCC);
    this.getChapters();
    this.getSubjectsForChapters();
  };

  handleSubjects = (response: SubjectData[]) => {
    let subjectData: SubjectData | null = null;
    let subjectId = this.props.navigation.getParam("subjectId");
    for (let index = 0; index <= response.length - 1; index++) {
      if (response[index].id === subjectId) {
        subjectData = response[index];
        break;
      }
    }
    const breadcrumb = this.breadcrumb;
    breadcrumb[1].label = subjectData?.attributes.name as string;

    const propPassingSCC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSCC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingSCC);
    this.setState({
      subjectName: subjectData,
      loading: false,
    });
  };

  // Customizable Area Start
  getChapters = async () => {
    const storageGet = JSON.parse(await storage.get("continueReading"));
    if (storageGet) {
      this.setState({
        chapterId: storageGet.chapterId,
      });
    }

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    const subjectId = this.props.navigation.getParam("subjectId");

    const getChaptersMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChaptersMessageId = getChaptersMessage.messageId;

    getChaptersMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `subject_chapters/${subjectId}`
    );

    getChaptersMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getChaptersMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getChaptersMessage.id, getChaptersMessage);
  };

  navigateToRead = async () => {
    const params = JSON.parse(await storage.get("continueReading"));
    this.props.navigation.navigate("SynopsisTopicRead", params);
  };

  handleChaptersData = (data: ChapterData[]) => {
    let covered: ChapterData[] | null = data.filter(
      (chapters) => chapters.attributes.chapter_read
    );
    let notCovered: ChapterData[] | null = data.filter(
      (chapters) => !chapters.attributes.chapter_read
    );

    this.setState({
      chaptersCovered: covered,
      chaptersNotCovered: notCovered,
      loading: false,
    });
  };

  getSubjectsForChapters = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getSubjectsForChaptersMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubjectsForChaptersMessageId =
      getSubjectsForChaptersMessage.messageId;

    getSubjectsForChaptersMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `all_subjects`
    );

    getSubjectsForChaptersMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectsForChaptersMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getSubjectsForChaptersMessage.id,
      getSubjectsForChaptersMessage
    );
  };
  // Customizable Area End
}
