import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { contactUS, medalCopy, userProfile } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import AYQProfileController, { Props } from "./AYQProfileController.web";

class AYQProfile extends AYQProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box className={this.props.classes.bannerStyle}>
          <Typography variant="h5">My Profile</Typography>
        </Box>
        <Box className={this.props.classes.containerStyle}>
          {this.state.userProfile ? (
            <>
              <Box
                display="flex"
                gridGap="1.5em"
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  className={this.props.classes.profileImage}
                  src={this.state.userProfile.data.attributes.image}
                  alt="profile-img"
                />
                <Box
                  display="flex"
                  flexDirection={"column"}
                  justifyContent={"center"}
                  gridGap={"0.6em"}
                >
                  <Typography className={this.props.classes.textWeight}>
                    {this.state.userProfile.data.attributes.name}
                  </Typography>
                  <Typography className={this.props.classes.textWeight}>
                    {this.state.userProfile.data.attributes.myrank_id}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box className="buttons-nav">
                  <button
                    data-test-id="edit-btn"
                    onClick={this.navigateToEditProfile}
                  >
                    <img src={userProfile} alt="user-profile" />
                    Edit Profile Details
                    <KeyboardArrowRightIcon className="right-icon" />
                  </button>
                  <button
                    data-test-id="question-btn"
                    onClick={this.navigateToQuestionsAsked}
                  >
                    <img src={contactUS} alt="icon-contact" />
                    Questions asked
                    <KeyboardArrowRightIcon className="right-icon" />
                  </button>
                  <button
                    data-test-id="badges-btn"
                    onClick={this.navigateToBadges}
                  >
                    <img src={medalCopy} alt="badge" />
                    Badges
                    <KeyboardArrowRightIcon className="right-icon" />
                  </button>
                </Box>
              </Box>
            </>
          ) : (
            <Box>No Data Found</Box>
          )}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleAYQProfile = () =>
  createStyles({
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "#510081",
      display: "flex",
      background: "rgb(247,242,249)",
      height: "71px",
      "& h5": {
        fontWeight: 700,
      },
    },
    containerStyle: {
      padding: "2em",
      "& .buttons-nav": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginTop: "1.5em",
        "& button": {
          textAlign: "left !important",
          paddingLeft: "4em",
          position: "relative",
          height: "70px",
          border: "none",
          background: "transparent",
          borderBottom: "1px solid #ccc",
          width: "100%",
          fontSize: "1.2em",
          cursor: "pointer",
          "& .right-icon": {
            position: "absolute",
            right: "20px",
          },
          "& img": {
            position: "absolute",
            left: "20px",
            width: "20px",
          },
        },
      },
    },
    profileImage: {
      width: "100px",
      height: "100px",
      borderRadius: "50%",
      border: "none",
      objectFit: "cover",
    },
    textWeight: {
      fontWeight: 600,
    },
  });
export default withStyles(webStyleAYQProfile)(AYQProfile);
export { AYQProfile };
// Customizable Area End
