import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
export type PackagesData = {
  id: string;
  type: string;
  attributes: {
    id: number;
    image: string;
    pack_name: string;
    pack_description: string;
    status: string;
    ayq: string;
    lc: string;
    wb: string;
    bms: string;
    tuition: string;
    subdomain_name: string;
    pack_pricing: {
      data: Array<{
        id: string;
        type: string;
        attributes: {
          id: number;
          feature_name: string;
          description: string;
          status_admin: string;
          status_online: string;
          status_sm: string;
          ayq: number;
          lc: number;
          wb: number;
          sms: string;
          apply_a: string;
          apply_b: string;
          bms: string;
          tuition: string;
          display_order: number;
          highlight: number;
          price: string;
          pack_highlights: {
            data: Array<string>;
          };
        };
      }>;
    };
    packfinal_features: {
      data: Array<{
        id: string;
        type: string;
        attributes: {
          id: number;
          pack_final_id: number;
          feature: string;
          subject: string;
          icon_code: string;
          status: string;
        };
      }>;
    };
    package: {
      data: Array<{
        id: string;
        type: string;
        attributes: {
          id: number;
          package_name: string;
          package_description: string;
          status: string;
          subject_id: string;
          class_zone: string;
          practice_test: string;
          subjective_test: string;
          gt_code: string;
          question_bank: string;
          created_by: string;
        };
      }>;
    };
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  packagesData: PackagesData[];
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GuestPackagesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGuestPackagesMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      packagesData: [],
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getGuestPackagesMessageId && response) {
      if (response?.data) {
        this.successfulPackageFetch(response.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getGuestPackages();
  };

  // Customizable Area Start
  successfulPackageFetch = (response: PackagesData[]) => {
    this.setState({
      packagesData: response,
    });
  };

  navigateToAndAssignPackageId = (packageId: string) => {
    storage.set("guestPackageId", packageId);
    this.props.navigation.navigate("GuestPageHome");
  };

  getGuestPackages = async () => {
    this.setState({
      loading: true,
    });
    const getGuestPackagesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getGuestPackagesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.packagesEP
    );
    this.getGuestPackagesMessageId = getGuestPackagesMessage.messageId;
    getGuestPackagesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getGuestPackagesMessage.id, getGuestPackagesMessage);
  };
  // Customizable Area End
}
