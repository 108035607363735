import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Banner from "../../../components/src/Banner.web";
import { rightarrow } from "./assets";
// Customizable Area End

import CutOffAnalysisController, {
  Props,
  configJSON,
} from "./CutOffAnalysisController.web";

class CutOffAnalysis extends CutOffAnalysisController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <Box>
        <Banner title="Cut off Analysis" />
        <Box className={classes.dataContainer}>
          <Box className={classes.mpcguidanceStyle}>
            <Typography className="box-label">
              {configJSON.AnalyisData[0]}
            </Typography>
            <button
              data-test-id="mpc-main-btn"
              onClick={() => this.navigateTo("MPCGuidanceMain")}
            >
              <Typography>{configJSON.clickHereToView}</Typography>
              <img src={rightarrow} alt="arrow" />
            </button>
          </Box>
          <Box className={classes.bipcguidanceStyle}>
            <Typography className="box-label">
              {configJSON.AnalyisData[1]}
            </Typography>
            <button
              data-test-id="mpc-main-btn"
              onClick={() => this.navigateTo("MPCGuidanceMain")}
            >
              <Typography>{configJSON.clickHereToView}</Typography>
              <img src={rightarrow} alt="arrow" />
            </button>
          </Box>
          <Box className={classes.neetpgStyle}>
            <Typography className="box-label">
              {configJSON.AnalyisData[2]}
            </Typography>
            <button
              data-test-id="mpc-main-btn"
              onClick={() => this.navigateTo("MPCGuidanceMain")}
            >
              <Typography>{configJSON.clickHereToView}</Typography>
              <img src={rightarrow} alt="arrow" />
            </button>
          </Box>
          <Box className={classes.neetmdsStyle}>
            <Typography className="box-label">
              {configJSON.AnalyisData[3]}
            </Typography>
            <button
              data-test-id="mpc-main-btn"
              onClick={() => this.navigateTo("MPCGuidanceMain")}
            >
              <Typography>{configJSON.clickHereToView}</Typography>
              <img src={rightarrow} alt="arrow" />
            </button>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const analysisBoxStyle = {
  width: "80%",
  height: "200px",
  position: "relative" as const,
  paddingLeft: "4em",
  paddingTop: "4em",
  "& .box-label": {
    fontSize: "32px",
    color: "white",
  },
};

export const webStyleCutOffAnalysis = () =>
  createStyles({
    dataContainer: {
      flexDirection: "column",
      justifyContent: "center",
      display: "flex",
      marginTop: "2em",
      alignItems: "center",
      width: "100%",
      gap: "2em",
      "& button": {
        width: "100%",
        display: "flex",
        height: "47px",
        cursor: "pointer",
        border: "none",
        fontSize: "18px",
        background: "rgba(0, 0, 0, 0.46)",
        justifyContent: "center",
        color: "white",
        position: "absolute",
        alignItems: "center",
        bottom: "0",
        gap: "1em",
        left: "0",
        "& img": {
          width: "25px",
          height: "25px",
        },
      },
    },
    mpcguidanceStyle: {
      background: "rgb(2, 191, 239)",
      ...analysisBoxStyle,
    },
    bipcguidanceStyle: {
      background: "rgb(245, 156, 14)",
      ...analysisBoxStyle,
    },
    neetpgStyle: {
      background: "rgb(5, 165, 90)",
      ...analysisBoxStyle,
    },
    neetmdsStyle: {
      background: "rgb(222, 76, 58)",
      ...analysisBoxStyle,
    },
  });
export default withStyles(webStyleCutOffAnalysis)(CutOffAnalysis);
export { CutOffAnalysis };
// Customizable Area End
