export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backArrow = require('../assets/backIcon.png')
export const courseIcon = require('../assets/courses.png')
export const gradiantColorImg = require('../assets/buttonfilled.png')
export const previousImg = require('../assets/lessthanArrow.png')
export const nextImg = require('../assets/morethanArrow.png')
export const timerIcon = require('../assets/timer.png')
export const circle = require('../assets/circle.png')
export const clean = require('../assets/noun-broom.png')
export const bookmark = require('../assets/bookmark.png')
export const infoIcon = require('../assets/Shape.png')
export const badgeIcon = require('../assets/badge.png')
export const tickIcon = require('../assets/tick.png')
export const exclamation = require('../assets/exclamation.png')
export const check = require('../assets/check.png')
export const backImg = require("../assets/back.png");
export const rightIcon = require("../assets/submitIcon.png");
export const ButtonBG = require('../assets/button_filled.png');
export const edit = require('../assets/edit.png');
export const arrowCircle = require("../assets/arrowCircle.png");
export const Bookmark = require('../assets/bookrmarkImg.png');
export const alarmWatch = require("../assets/Timer.png");
export const Home = require('../assets/bottomHome.png');
export const saveImg = require('../assets/saveImg.png');
export const Delete = require('../assets/trash.png');
export const OvalCopy = require("../assets/OvalCopy.png");
export const PlusIcon = require('../assets/pngwing.png')
export const noreview = require('../assets/no-review.png')
export const iconcopy = require('../assets/iconcopy.png');
export const Correct = require("../assets/Timer.png");
export const Incorrect = require("../assets/Shield.png");
export const Minutes = require("../assets/Correct.png");
export const leftImg = require("../assets/left.png");
export const rightImg = require("../assets/right.png");
export const bottomHome = require("../assets/bottomHome.png");
export const correctAnswer = require("../assets/correctAnswer.png");
export const savedNotes = require("../assets/saveImg.png");
export const bookrmarkImg = require("../assets/bookrmarkImg.png");
export const more = require("../assets/more.png");
export const submiticon = require("../assets/submitIcon.png");
export const button_filled = require("../assets/button_filled.png");
export const checkmark = require("../assets/checkmark.png");
export const exclamation2 = require("../assets/exclamation2.png")
export const incorrect = require("../assets/incorrect.png")
export const filterBtn = require("../assets/setting-4.png");
export const sortBtn = require("../assets/setting-5.png");
export const takeTestImg = require("../assets/takeTestImg.png");
export const PrevImg = require("../assets/PrevImg.png");
export const timerImg = require("../assets/timerImg.png");
export const info = require("../assets/info.png");
export const buttonFilled = require("../assets/buttonFilled.png");
export const submitIcon = require("../assets/submitIcon.png");
export const searchIcon = require("../assets/searchImg.png");
export const PhysicsImg = require("../assets/PhysicsImg.png");
export const clearResponse = require("../assets/clear.png");
export const BTNbackgrrud = require('../assets/buttonFilled.png')
export const calendar = require("../assets/calendar.png");
export const camera = require('../assets/Group.png')
export const Edit = require('../assets/edit.png');
export const starIcon = require('../assets/starIcon.png');
export const subjectImage = require('../assets/subjectImage.png');
export const profileImg = require('../assets/profileImg.png');
export const OvelImage = require("../assets/Oval.png");
export const searchImg = require("../assets/searchImg.png");
export const sorting = require("../assets/setting-4.png");
export const filter = require("../assets/setting-5.png");



