import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { AppointmentT } from "./BookMySlotStudentAppointmentsController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  pageIndex: number;
  upcomingAppointmentsData: AppointmentT[];
  previousAppointmentsData: AppointmentT[];
  isModalDoubt: boolean;
  currentDoubt: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotFacultyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPreviousAppointmentsMessageId = "";
  getUpcomingAppointmentsMessageId = "";
  breadcrumb = [
    {
      link: `/dashboard/book-my-slot-faculty`,
      label: "Book my Slot Faculty",
    },
    {
      link: `/dashboard/book-my-slot-faculty`,
      label: "Bookings",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      pageIndex: 0,
      previousAppointmentsData: [],
      upcomingAppointmentsData: [],
      isModalDoubt: false,
      currentDoubt: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      apiRequestCallId === this.getUpcomingAppointmentsMessageId &&
      response
    ) {
      if (response.data) {
        this.handleUpcomingData(response.data);
      }
    }

    if (
      apiRequestCallId === this.getPreviousAppointmentsMessageId &&
      response
    ) {
      if (response.data) {
        this.handlePreviousData(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getUpcomingAppointments();
    this.getPreviousAppointments();

    const propPassingFaculty = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingFaculty.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingFaculty);
  };

  handleIsModalDoubtModal = () => {
    this.setState({
      isModalDoubt: !this.state.isModalDoubt,
    });
  };

  updateCurrentDoubt = (doubt: string) => {
    this.setState({
      currentDoubt: doubt ? doubt : "No student doubt provided",
      isModalDoubt: true,
    });
  };

  // Customizable Area Start
  navigateToSlotBookings = () => {
    this.props.navigation.navigate("BookMySlotFacultySlotAvailability");
  };
  pageChange = (pageIndex: number) => {
    this.setState({
      pageIndex,
    });
  };
  navigateToFeedbacks = (slotId: string) => {
    this.props.navigation.history.push(
      `/dashboard/book-my-slot-faculty/feedback?slotId=${slotId}`
    );
  };
  handleUpcomingData = (upcomingAppointmentsData: AppointmentT[]) => {
    this.setState({
      upcomingAppointmentsData,
      loading: false,
    });
  };
  handlePreviousData = (previousAppointmentsData: AppointmentT[]) => {
    this.setState({
      previousAppointmentsData,
      loading: false,
    });
  };

  getUpcomingAppointments = async () => {
    this.setState({
      loading: true,
    });
    const getUpcomingAppointmentsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getUpcomingAppointmentsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/faculty_bms/upcoming_appoints`
    );
    this.getUpcomingAppointmentsMessageId =
      getUpcomingAppointmentsMessage.messageId;
    getUpcomingAppointmentsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getUpcomingAppointmentsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getUpcomingAppointmentsMessage.id,
      getUpcomingAppointmentsMessage
    );
  };

  getPreviousAppointments = async () => {
    this.setState({
      loading: true,
    });
    const getPreviousAppointmentsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getPreviousAppointmentsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.prevFacultAppoint
    );
    this.getPreviousAppointmentsMessageId =
      getPreviousAppointmentsMessage.messageId;
    getPreviousAppointmentsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPreviousAppointmentsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getPreviousAppointmentsMessage.id,
      getPreviousAppointmentsMessage
    );
  };
  // Customizable Area End
}
