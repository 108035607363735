import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

export type Notification = {
  id: string;
  type: string;
  attributes: {
    id: number;
    notice_head: string;
    notice_final: string;
    notice_desc: string;
    meta_disc: string;
    meta_key: string;
    application_mode: string;
    application_cost: string;
    mode_of_payment: string;
    notice_cat: Array<{
      id: number;
      name: string;
    }>;
  };
};

export type NotificationCategory = {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  notificationButtons: NotificationCategory[];
  notifications: Notification[];
  loading: boolean;
  activeIndex: number;
  currentPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GuestNotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  CourseNotificationsMessageId = "";
  CategoryNotificationsMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      notificationButtons: [],
      notifications: [],
      loading: false,
      activeIndex: 0,
      currentPage: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.CategoryNotificationsMessageId && response) {
      if (response?.data) {
        this.handleSuccessfulButtonsFetched(response.data);
      }
    }

    if (apiRequestCallId === this.CourseNotificationsMessageId && response) {
      if (response?.data) {
        this.handleSuccessfulNotificationsFetched(response.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events

  // Customizable Area Start
  componentDidMount = async () => {
    this.getCategoryNotifications();
  };

  handlePageChange = (value: number) => {
    this.setState({
      currentPage: value,
    });
  };
  handleSuccessfulButtonsFetched = (response: NotificationCategory[]) => {
    this.setState(
      {
        notificationButtons: response,
      },
      () => {
        this.getCourseNotifications(0);
      }
    );
  };

  handleSuccessfulNotificationsFetched = (response: Notification[]) => {
    this.setState({
      notifications: response,
    });
  };

  getCourseNotifications = async (index: number, categoryId?: string) => {
    const header = {
      uuid: await storage.get("guestToken"),
    };

    this.setState({
      activeIndex: index,
      loading: true,
    });

    const CourseNotificationsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.CourseNotificationsMessageId = CourseNotificationsMessage.messageId;
    CourseNotificationsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    CourseNotificationsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationEndpointCourse +
        `?category_id=${categoryId ?? "all"}`
    );
    CourseNotificationsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(
      CourseNotificationsMessage.id,
      CourseNotificationsMessage
    );
  };

  getCategoryNotifications = async () => {
    const header = {
      uuid: await storage.get("guestToken"),
    };
    this.setState({
      loading: true,
    });

    const CategoryNotificationsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.CategoryNotificationsMessageId =
      CategoryNotificationsMessage.messageId;
    CategoryNotificationsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notiCategoriesEndPoint
    );
    CategoryNotificationsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    CategoryNotificationsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(
      CategoryNotificationsMessage.id,
      CategoryNotificationsMessage
    );
  };
  // Customizable Area End
}
