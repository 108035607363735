import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
export interface AllSubjects {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  name: string;
  image: string;
  created_by: string;
  class_zone: string;
  practice_test: string;
  question_bank: string;
  subjective_test: string;
  st_code: string;
  remarks: string;
  icon_code: string;
  search_key: any;
  total_topics: number;
  saved_notes: number;
}

import { WithStyles } from "@material-ui/core";
import { webStyleSynopsis } from "./SynopsisGuest.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleSynopsis> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  allSubjects: AllSubjects[];
  isModalOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SynopsisGuestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSynopsisSubjectsMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      allSubjects: [],
      isModalOpen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSynopsisSubjectsMessageId) {
      this.handleSuccessfulSubject(response.data);
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const guestToken = await storage.get("guestToken");
    if (!guestToken) {
      this.props.navigation.navigate("LandingPage");
    } else {
      this.getSynopsisSubjects();
    }
  };

  handleModalOpen = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  handleSuccessfulSubject = (response: AllSubjects[]) => {
    this.setState({
      allSubjects: response,
    });
  };

  navigateTo = (subjectId: string) => {
    this.props.navigation.navigate("GuestSynopsisChapterPage", { subjectId });
  };

  // Customizable Area Start
  getSynopsisSubjects = async () => {
    const getSynopsisSubjectsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      uuid: await storage.get("guestToken"),
    };
    const packageId = await storage.get("guestPackageId");
    getSynopsisSubjectsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/guestsynopsis/get_subject?pack_final_id=${packageId}`
    );
    this.getSynopsisSubjectsMessageId = getSynopsisSubjectsMessage.messageId;
    getSynopsisSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSynopsisSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getSynopsisSubjectsMessage.id,
      getSynopsisSubjectsMessage
    );
  };
  // Customizable Area End
}
