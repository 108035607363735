import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

import { ValueType } from "react-select";
export interface Swayam {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  subject_id: number;
  url: string;
  date_telecast: string;
  video_by: string;
  video_name_origin: string;
  created_by: string;
  chapter_id: number;
  subject_name: string;
  chapter_name: string;
  remarks: string;
  status: string;
  image_url: any;
  viewed: boolean;
  created_at: string;
  class_name: string;
  video_name: string;
  part: number;
  updated_at: string;
  youtube_video_id: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  swayamButtons: string[];
  isModalOpenSort: boolean;
  activeIndex: number;
  isModalOpenFilter: boolean;
  about: string;
  guestSwayam: Swayam[];
  search: string;
  currentSwayam: Swayam[];
  userInput: {
    subject: string;
    chapterSort: string;
    chapter: string;
  };
  chapters: { value: string; label: string }[];
  subjects: { value: string; label: string }[];
  showModalSubmit: boolean;
  currentVideo: string;
  showVideoModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GuestSwayamPrabhaController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGuestAboutMessageId = "";
  getGuestSwayamPrabhasMessageId = "";
  getGuestSortMessageId = "";
  getGuestSearchMessageId = "";
  getGuestFilterMessageId = "";
  private searchTimeout: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      about: "",
      guestSwayam: [],
      search: "",
      swayamButtons: [],
      activeIndex: 0,
      isModalOpenFilter: false,
      isModalOpenSort: false,
      showModalSubmit: false,
      showVideoModal: false,
      currentVideo: "",
      currentSwayam: [],
      userInput: {
        subject: "",
        chapter: "",
        chapterSort: "",
      },
      subjects: [],
      chapters: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getGuestAboutMessageId) {
      if (response?.length > 0) {
        this.handleSuccessAboutFetch(response[0].content);
      }
    }
    if (apiRequestCallId === this.getGuestSwayamPrabhasMessageId) {
      if (response?.data) {
        this.handleSuccessSwayamFetch(response.data);
      }
    }

    if (
      apiRequestCallId === this.getGuestFilterMessageId ||
      apiRequestCallId === this.getGuestSortMessageId
    ) {
      if (response.data) {
        this.handleSuccessSwayamSearchFetch(response.data);
      }
    }
    if (apiRequestCallId === this.getGuestSearchMessageId) {
      if (response.data) {
        this.handleSuccessSwayamSearchFetch(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getGuestSwayamPrabhas();
    this.getGuestAbout();
  };

  componentDidUpdate = (_: Props, prevState: S) => {
    if (prevState.userInput.subject !== this.state.userInput.subject) {
      this.changeCurrentChapters();
    }
  };

  changeCurrentChapters = () => {
    const { guestSwayam } = this.state;

    const chapters = guestSwayam
      .filter(
        (data) => data.attributes.subject_name === this.state.userInput.subject
      )
      .map((data) => ({
        label: data.attributes.chapter_name,
        value: data.attributes.chapter_name,
      }));

    const filteredChapters = chapters.filter(
      (chapter, index) =>
        chapters.findIndex((item) => item.label === chapter.label) === index
    );

    this.setState({
      chapters: filteredChapters,
    });
  };

  handleUserInputChange = (
    event: ValueType<
      {
        value: string;
        label: string;
      },
      false
    >,
    type: "chapter" | "subject" | "chapterSort"
  ) => {
    const value = event?.value;
    const label = event?.label;

    if (label && value) {
      this.setState({
        userInput: {
          ...this.state.userInput,
          [type]: value,
        },
      });
    }
  };

  handleSuccessSwayamFetch = (guestSwayam: Swayam[]) => {
    const swayamMap: { [guestSwayam: string]: number } = {};
    guestSwayam.forEach((swayamData) => {
      swayamMap[swayamData.attributes.subject_name] = 1;
    });

    const subjects = guestSwayam.map((data) => ({
      label: data.attributes.subject_name,
      value: data.attributes.subject_name,
    }));

    const filteredSubjects = subjects.filter(
      (subject, index) =>
        subjects.findIndex((item) => item.label === subject.label) === index
    );

    this.setState(
      {
        // chapters: filteredChapters,
        subjects: filteredSubjects,
        swayamButtons: Object.keys(swayamMap),
        guestSwayam,
      },
      () => {
        return this.handleActiveSubject(0);
      }
    );
  };
  handleSuccessSwayamSearchFetch = (guestSwayam: Swayam[]) => {
    this.setState({
      isModalOpenFilter: false,
      currentSwayam: guestSwayam,
      isModalOpenSort: false,
      activeIndex: -1,
    });
  };

  handleSuccessAboutFetch = (about: string) => {
    this.setState({ about });
  };

  handleActiveSubject = (index: number) => {
    const currentSwayam = this.state.guestSwayam.filter((guestSwayams) => {
      if (
        guestSwayams.attributes.subject_name === this.state.swayamButtons[index]
      ) {
        return guestSwayams;
      }
    });

    this.setState({
      activeIndex: index,
      currentSwayam,
    });
  };
  onKeyEnterPressSearch = (eventKey: string) => {
    if (eventKey === "Enter") {
      this.performSearch(this.state.search);
    }
  };

  handleOpenModalFilter = () => {
    this.setState({
      isModalOpenFilter: true,
    });
  };
  handleCloseModalFilter = () => {
    this.setState({
      isModalOpenFilter: false,
    });
  };

  handleOpenModalSort = () => {
    this.setState({
      isModalOpenSort: true,
    });
  };
  handleCloseModalSort = () => {
    this.setState({
      isModalOpenSort: false,
    });
  };

  handleSearch = (search: string) => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.setState(
      {
        search,
      },
      () => {
        this.searchTimeout = setTimeout(() => {
          this.performSearch(search);
        }, 600);
      }
    );
  };

  performSearch = async (search: string) => {
    const getGuestSearchMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      uuid: await storage.get("guestToken"),
    };

    const packFinalId = await storage.get("guestPackageId");

    this.getGuestSearchMessageId = getGuestSearchMessage.messageId;

    getGuestSearchMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getGuestSearchMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_videos8/swayamprabhas?search=${search}&pack_final_id=${packFinalId}`
    );

    getGuestSearchMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getGuestSearchMessage.id, getGuestSearchMessage);
  };

  performSort = async () => {
    const getGuestSortMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      uuid: await storage.get("guestToken"),
    };

    const packFinalId = await storage.get("guestPackageId");

    this.getGuestSortMessageId = getGuestSortMessage.messageId;

    getGuestSortMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getGuestSortMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_videos8/swayamprabhas?sort_by=chapter_name&pack_final_id=${packFinalId}`
    );

    getGuestSortMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getGuestSortMessage.id, getGuestSortMessage);
  };

  performFilter = async (subjectName: string, chapterName: string) => {
    const getGuestFilterMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      uuid: await storage.get("guestToken"),
    };

    const packFinalId = await storage.get("guestPackageId");

    this.getGuestFilterMessageId = getGuestFilterMessage.messageId;

    getGuestFilterMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getGuestFilterMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_videos8/swayamprabhas?subject_name=${subjectName}&chapter_name=${chapterName}&pack_final_id=${packFinalId}`
    );

    getGuestFilterMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getGuestFilterMessage.id, getGuestFilterMessage);
  };

  showModalSubmit = () => {
    this.setState({
      showModalSubmit: !this.state.showModalSubmit,
    });
  };

  showModalVideo = (video: string) => {
    this.setState({
      showVideoModal: !this.state.showVideoModal,
      currentVideo: video,
    });
  };

  navigateToRegister = () => {
    this.props.navigation.history.push("/register");
  };

  doneFilter = () => {
    this.performFilter(
      this.state.userInput.subject,
      this.state.userInput.chapter
    );
    this.setState({
      userInput: {
        ...this.state.userInput,
        chapter: "",
        subject: "",
      },
    });
  };

  doneSort = () => {
    this.performSort();
  };

  // Customizable Area Start
  getGuestSwayamPrabhas = async () => {
    const getGuestSwayamPrabhasMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      uuid: await storage.get("guestToken"),
    };

    const packFinalId = await storage.get("guestPackageId");

    this.getGuestSwayamPrabhasMessageId =
      getGuestSwayamPrabhasMessage.messageId;

    getGuestSwayamPrabhasMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getGuestSwayamPrabhasMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_videos8/swayamprabhas?pack_final_id=${packFinalId}`
    );

    getGuestSwayamPrabhasMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getGuestSwayamPrabhasMessage.id,
      getGuestSwayamPrabhasMessage
    );
  };

  getGuestAbout = async () => {
    const getGuestAboutMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      uuid: await storage.get("guestToken"),
    };

    const packFinalId = await storage.get("guestPackageId");

    this.getGuestAboutMessageId = getGuestAboutMessage.messageId;

    getGuestAboutMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getGuestAboutMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_videos8/swayamprabhas/about?pack_final_id=${packFinalId}`
    );

    getGuestAboutMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getGuestAboutMessage.id, getGuestAboutMessage);
  };
  // Customizable Area End
}
