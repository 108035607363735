import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SearchIcon from "@material-ui/icons/Search";
import { setting, submitIcon } from "./assets";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../../../components/src/Loader.web";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CustomButton from "../../../components/src/Button.web";
import MathJaxBox from "../../../components/src/MathJaxBox.web";
// Customizable Area End

import AYQQuestionAnswerController, {
  Props,
} from "./AYQQuestionAnswerController.web";

class AYQQuestionAnswer extends AYQQuestionAnswerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  navigationTitles = ["MyQueries", "Top", "Hot", "Week", "Month"];

  renderNavigation = (title: string, index: number) => {
    return (
      <Box component={"li"} key={`${index}-title-${title}`}>
        <Button
          data-test-id="navigate-click-btn"
          className={this.state.currentIndex === index ? "activeLink" : ""}
          onClick={() => this.clickToNavigate(index)}
        >
          {title}
        </Button>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    const question = this.state.currentQuestion;
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Modal
          open={this.state.isModalOpen}
          onClose={this.handleModalOpen}
          BackdropProps={{ onClick: undefined }}
        >
          <Box className={this.props.classes.modalStyle}>
            <img src={submitIcon} alt="getstarted" />
            <h3>Your Query Posted Successfully</h3>
            <Box display={"flex"} gridGap={"1em"}>
              <Button
                className={this.props.classes.buttonStyleYes}
                data-test-id="navigate-btn"
                onClick={this.handleModalOpen}
              >
                Go Back
              </Button>
            </Box>
          </Box>
        </Modal>
        <Box className={this.props.classes.bannerStyle}>
          <Typography variant="h5">Ask Your Query</Typography>
        </Box>
        <Box className={this.props.classes.ayqAnswerContainer}>
          <Box className={this.props.classes.navigationStyle}>
            <Box component={"ul"}>
              {this.navigationTitles
                .filter((title) => {
                  if (title === "MyQueries" && !this.state.token) {
                    return false;
                  }
                  return true;
                })
                .map((title, index) => {
                  return this.renderNavigation(title, index);
                })}
            </Box>
            <Button
              data-test-id="ask-question-btn"
              className="ask-question-btn"
              onClick={() =>
                this.state.token
                  ? this.navigateToAskQuestion()
                  : this.handleAyqLoginModal()
              }
            >
              Ask Question
            </Button>
          </Box>
          <Box className={this.props.classes.searchStyle}>
            <Box position="relative" width={"100%"}>
              <SearchIcon
                style={{
                  position: "absolute",
                  left: "15px",
                  top: "13px",
                  color: "#510081",
                }}
              />
              <input
                placeholder="Search"
                value={this.state.search}
                data-test-id="input-search"
                onChange={(event) => this.handleSearch(event.target.value)}
              />
            </Box>
            <img
              src={setting}
              style={{ width: "30px", cursor: "pointer", margin: "0 1em" }}
              data-test-id="filter"
              onClick={this.handleModalFilterCurrent}
            />
          </Box>
          <Box
            margin="3em 0"
            display="flex"
            flexDirection={"column"}
            gridGap="1.5em"
          >
            {question ? (
              <>
                <Box
                  className={this.props.classes.questionBoxStyle}
                  position={"relative"}
                >
                  <Box
                    position="absolute"
                    left="20px"
                    top="20px"
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ArrowDropUpIcon
                      data-test-id="like-btn-q"
                      onClick={() => this.likeDislikeQuestion(1)}
                      style={{
                        color:
                          question.attributes.current_user_liked === 1
                            ? "green"
                            : "#1f1f1f",
                        cursor: "pointer",
                      }}
                    />
                    <Typography variant="body1">
                      {question.attributes.like_count -
                        question.attributes.dislike_count}
                    </Typography>
                    <ArrowDropDownIcon
                      data-test-id="dislike-btn-q"
                      style={{
                        color:
                          question.attributes.current_user_liked === -1
                            ? "red"
                            : "#1f1f1f",
                        cursor: "pointer",
                      }}
                      onClick={() => this.likeDislikeQuestion(-1)}
                    />
                  </Box>
                  <MathJaxBox content={question.attributes.question} />
                  <Box className="main-wrapper">
                    <Box component={"ul"}>
                      {question.attributes.tag_list.map((tagList) => {
                        return (
                          <Box component={"li"} key={tagList}>
                            {tagList
                              .slice(0, 1)
                              .toUpperCase()
                              .concat(tagList.slice(1))}
                          </Box>
                        );
                      })}
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      border={"1px solid rgb(254,110,82)"}
                      padding="0.5em"
                      width={"max-content"}
                      marginTop="0.6em"
                      borderRadius={"0.2em"}
                      style={{ cursor: "pointer" }}
                    >
                      <CheckCircleIcon className="check-circle" />
                      <span>{question.attributes.answer_count} Answers</span>
                    </Box>
                    <Box
                      position={"absolute"}
                      right="25px"
                      bottom="20px"
                      display="flex"
                      justifyContent={"center"}
                      alignItems={"center"}
                      style={{ cursor: "pointer" }}
                      data-test-id="profile-btn"
                      onClick={() =>
                        this.goToProfile(
                          question.attributes.user_profile?.data.id
                        )
                      }
                    >
                      <img
                        src={
                          question.attributes.user_profile.data.attributes.image
                        }
                        alt="picture"
                        width={"25px"}
                        height={"25px"}
                      />
                      <span className="profile-span">
                        {
                          question.attributes.user_profile.data.attributes
                            .first_name
                        }{" "}
                        {
                          question.attributes.user_profile.data.attributes
                            .last_name
                        }{" "}
                        <span>
                          {this.getTimeDifference(
                            question.attributes.created_at
                          )}
                        </span>
                      </span>
                    </Box>
                  </Box>
                </Box>
                {question.attributes.ayq_answers.data.map((answer, index) => {
                  return (
                    <Box
                      className={this.props.classes.questionBoxStyle}
                      position={"relative"}
                      key={`${index}-answer-${answer.id}`}
                    >
                      <Box
                        position="absolute"
                        left="20px"
                        top="20px"
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <ArrowDropUpIcon
                          data-test-id="click-up"
                          onClick={() => this.likeDislike(1, index)}
                          style={{
                            color:
                              answer.attributes.current_user_liked === 1
                                ? "green"
                                : "#1f1f1f",
                            cursor: "pointer",
                          }}
                        />
                        <Typography variant="body1">
                          {answer.attributes.like_count -
                            answer.attributes.dislike_count}
                        </Typography>
                        <ArrowDropDownIcon
                          data-test-id="click-down"
                          style={{
                            color:
                              answer.attributes.current_user_liked === -1
                                ? "red"
                                : "#1f1f1f",
                            cursor: "pointer",
                          }}
                          onClick={() => this.likeDislike(-1, index)}
                        />
                      </Box>
                      <Box className="main-wrapper">
                        <Typography
                          variant="body1"
                          style={{ color: "#60704B", fontWeight: 700 }}
                        >
                          Answer {index + 1}
                        </Typography>
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: answer.attributes.answer,
                          }}
                          style={{ marginTop: "1em" }}
                        />
                        <Typography
                          variant="body1"
                          style={{ fontSize: "0.9em", marginTop: "2em" }}
                        >
                          Comments:
                        </Typography>
                        {answer.attributes.comments.data.map(
                          (comment, indexComment) => {
                            return (
                              <p
                                key={`${comment.type}-${indexComment}`}
                                style={{
                                  fontSize: "1.1em",
                                  marginLeft: "2em",
                                }}
                              >
                                <span>
                                  <img
                                    src={comment.attributes.account.image}
                                    alt="profile-img"
                                    width={"20px"}
                                    height={"20px"}
                                    style={{ borderRadius: "50%" }}
                                  />{" "}
                                  {comment.attributes.account.name}:{" "}
                                </span>
                                {comment.attributes.comment}
                              </p>
                            );
                          }
                        )}
                        <textarea
                          placeholder="Comment here"
                          value={this.state.comments[index]}
                          onChange={(event) =>
                            this.handleCommentChange(index, event.target.value)
                          }
                          data-test-id="text-comment"
                          onKeyDown={(event) =>
                            this.handleKeyDown(
                              event.key,
                              index,
                              answer.id,
                              this.state.comments[index]
                            )
                          }
                        />
                        <Box
                          position={"absolute"}
                          right="25px"
                          bottom="20px"
                          display="flex"
                          justifyContent={"center"}
                          alignItems={"center"}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.goToProfile(
                              answer.attributes.user_profile?.data.id
                            )
                          }
                        >
                          <img
                            src={
                              answer.attributes.user_profile.data.attributes
                                .image
                            }
                            alt="picture"
                            width={"25px"}
                            height={"25px"}
                          />
                          <span className="profile-span">
                            {
                              answer.attributes.user_profile.data.attributes
                                .first_name
                            }{" "}
                            {
                              answer.attributes.user_profile.data.attributes
                                .last_name
                            }{" "}
                            <span>
                              {this.getTimeDifference(
                                answer.attributes.created_at
                              )}
                            </span>
                          </span>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </>
            ) : (
              <Typography>No data found</Typography>
            )}
          </Box>
          <Box className={this.props.classes.postAnswerStyle}>
            <Box
              className={
                !this.state.token
                  ? this.props.classes.hideAnswering
                  : this.props.classes.hideAnsweringFalse
              }
            >
              <Typography>
                You need to Register with AYQ to post answer
              </Typography>
              <Button onClick={() => this.navigateToRegister()}>
                Register
              </Button>
            </Box>
            <Typography className="answer-typo">Your Answer</Typography>
            <ReactQuill
              value={this.state.answer}
              data-test-id="quill-test"
              onChange={this.handleChange}
            />
            <Button
              data-test-id="quill-test-submit"
              onClick={this.answerQuestion}
            >
              Post your Answer
            </Button>
          </Box>
        </Box>
        <Modal
          open={this.state.modalCurrentFilter}
          onClose={this.handleModalFilterCurrent}
        >
          <Box className={this.props.classes.modalStyleFilter}>
            <Typography variant={"h6"} style={{ fontWeight: 700 }}>
              Filter By Tag
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              gridGap={"1.5em"}
            >
              <input
                name="tag"
                data-test-id="filter-tag"
                value={this.state.filter}
                onChange={(event) => this.handleFilter(event.target.value)}
                placeholder="Tag"
              />
            </Box>
            <Box alignSelf={"center"}>
              <Button
                data-test-id="close-filter"
                onClick={this.handleModalFilterCurrent}
              >
                Cancel
              </Button>
              <Button
                data-test-id="filter-init"
                onClick={() => this.filterQuestionNavigate()}
              >
                Done
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.isNotLoggedIn}
          onClose={this.handleAyqLoginModal}
        >
          <Box className={this.props.classes.modalStyle}>
            <HighlightOffIcon
              data-test-id="close-modal"
              onClick={this.handleAyqLoginModal}
              style={{
                fontSize: "2rem",
                cursor: "pointer",
                color: "gray",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            />
            <Typography variant="h6">
              Login / Signup with AYQ to ask a question
            </Typography>
            <Box data-test-id="to-login-btn" onClick={this.navigateToLogin}>
              <CustomButton
                key={"buttonKey"}
                color="white"
                height="50px"
                width="200px"
                title="Login"
              />
            </Box>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleAYQQuestionAnswer = () =>
  createStyles({
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      color: "#510081",
      background: "rgb(247,242,249)",
      height: "71px",
      width: "100%",
      "& h5": {
        fontWeight: 700,
      },
    },
    ayqAnswerContainer: {
      padding: "0 5em",
    },
    modalStyleFilter: {
      left: "50%",
      border: "2px solid #832890",
      backgroundColor: "white",
      width: 500,
      height: "max-content",
      display: "flex",
      position: "absolute" as "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "0.5em",
      justifyContent: "space-between",
      padding: "2em",
      gap: "1em",
      flexDirection: "column",
      outline: "none",
      textAlign: "left",
      "& label input": {
        width: "20px",
        height: "20px",
        borderRadius: "50%",
      },
      "& input, & label": {
        width: "100%",
        padding: "1em",
        outline: "none" as const,
        border: "1px solid #FF6B55",
        borderRadius: "0.5em",
        color: "#510081",
        "&::placeholder": {
          color: "#510081",
        },
      },
      "& label": {
        alignItems: "center",
        justifyContent: "flex-start",
        display: "flex",
      },
      "& button": {
        borderRadius: "0.6em",
        textTransform: "none",
        border: "1px solid #BE888E",
        "&:hover": {
          color: "white",
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        },
        color: "#4F1f6d",
        height: "45px",
        width: "max-content",
        fontWeight: 700,
        margin: "0 1em",
        minWidth: "130px",
      },
      "& label p": {
        fontWeight: 500,
        fontSize: "1.2em",
      },
    },
    postAnswerStyle: {
      display: "flex",
      position: "relative",
      flexDirection: "column",
      "& button": {
        padding: "1em 1em",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        color: "white",
        width: "180px",
        borderRadius: "0.7em",
      },
      "& .answer-typo": {
        fontWeight: "700",
      },
      "& .quill": {
        margin: "1.5em 0",
        "& .ql-toolbar.ql-snow": {
          borderTop: "2px solid #E7C6CC",
          borderLeft: "2px solid #E7C6CC",
          borderRight: "2px solid #E7C6CC",
          borderTopRightRadius: "1em",
          borderTopLeftRadius: "1em",
        },
        "& .ql-container.ql-snow": {
          borderBottom: "2px solid #E7C6CC",
          borderLeft: "2px solid #E7C6CC",
          borderRight: "2px solid #E7C6CC",
          borderBottomRightRadius: "1em",
          borderBottomLeftRadius: "1em",
          height: "max-content",
          minHeight: "150px",
        },
      },
    },
    navigationStyle: {
      display: "flex",
      marginTop: "2em",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      "& ul": {
        background: "rgb(240,240,240)",
        display: "flex",
        gridGap: "1em",
        "& .activeLink": {
          fontWeight: "700",
          color: "#5C1580",
        },
        "& li button": {
          padding: "1em",
        },
      },
      "& .ask-question-btn": {
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        right: "0",
        color: "white",
        padding: "0.5em 2em",
        position: "absolute",
        borderRadius: "0.7em",
      },
    },
    searchStyle: {
      alignItems: "center",
      justifyContent: "center",
      marginTop: "2em",
      width: "100%",
      display: "flex",
      "& input": {
        width: "100%",
        outline: "none",
        border: "1px solid #510081",
        padding: "0.5em 0.5em 0.5em 3em",
        borderRadius: "2em",
        color: "#510081",
        height: "50px",
        "&::placeholder": {
          color: "#510081",
        },
      },
    },
    questionBoxStyle: {
      display: "flex",
      padding: "1em 3em 3em 4em",
      border: "none",
      background: "rgb(240,240,240)",
      flexDirection: "column",
      height: "max-content",
      minHeight: "200px",
      "& .main-wrapper": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        "& textarea": {
          background: "rgb(246,231,250)",
          padding: "1em",
          border: "none",
          resize: "none",
          margin: "1em 0 2em 0",
          outline: "none",
        },
        "& .profile-span": {
          fontWeight: "500",
          fontSize: "1.2em",
          "& span": {
            color: "gray",
            fontSize: "0.8em",
          },
        },
        "& img": {
          borderRadius: "50%",
          marginRight: "0.2em",
        },
        "& .check-circle": {
          fontSize: "1.2em",
          marginRight: "0.6em",
          color: "rgb(4,147,69)",
        },
        "& p": {
          fontSize: "1.35em",
        },
        "& ul": {
          flexWrap: "wrap",
          margin: "1em 0",
          display: "flex",
          gap: "1em",
          "& li": {
            borderRadius: "0.5em",
            background: "rgb(233,225,240)",
            padding: "0.3em 1em",
            border: "none",
            fontWeight: "500",
          },
        },
      },
    },
    buttonStyleYes: {
      color: "white",
      height: "50px",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      width: "130px",
      textTransform: "none" as const,
      marginLeft: "1em",
    },
    modalStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      flexDirection: "column",
      justifyContent: "center",
      padding: "2em",
      display: "flex",
      alignItems: "center",
      left: "50%",
      borderRadius: "1.5em",
      width: "35%",
      minHeight: "max-content",
      background: "white",
      border: "2px solid #FFD0D8",
      gap: "1.5em",
      transform: "translate(-50%, -50%)",
    },
    hideAnswering: {
      position: "absolute",
      transform: "translate(-50%,-50%)",
      top: "50%",
      left: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      zIndex: 40,
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backdropFilter: "blur(1px)",
        zIndex: -1,
      },
      "& p": {
        fontSize: "1.3em",
        fontWeight: 500,
        textAlign: "center",
        marginBottom: "1.3em",
      },
    },
    hideAnsweringFalse: {
      display: "none",
    },
  });
export default withStyles(webStyleAYQQuestionAnswer)(AYQQuestionAnswer);
export { AYQQuestionAnswer };
// Customizable Area End
