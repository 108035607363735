import React from "react";

import {
  Box,
  // Customizable Area Start
  Button,
  Modal,
  FormControl,
  createStyles,
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { myrankLogo2, mainLoginImage, party, bgLoginMain } from "./assets";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// Customizable Area End

import EmailAccountLoginBlockController, {
  Props,
} from "./EmailAccountLoginBlockController.web";

export class EmailAccountLogin extends EmailAccountLoginBlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className={this.props.classes.loginbyId}>
        <Box
          className={this.props.classes.goBackBtn}
          onClick={this.handleGoBack}
          data-test-id="go-back"
        >
          <div className="back-icon">{"<"}</div>
        </Box>
        <Box className="leftscreen">
          <img src={mainLoginImage} alt="mainloginimage" />
        </Box>
        <Box className="rightscreen">
          <img className="myranklogo" src={myrankLogo2} alt="myranklogo" />
          <Box className="main-rightscreen">
            <h1>User Login</h1>
            <Box className="login-btns" data-test-id="loginbyid">
              <Button
                className={this.state.isLoggingByMobile ? "" : "button-chosen"}
                onClick={this.handleLoginById}
                data-test-id="loginbyid-btn"
              >
                My Rank ID
              </Button>
              <Button
                data-test-id="loginbynumber"
                className={this.state.isLoggingByMobile ? "button-chosen" : ""}
                onClick={this.handleLoginByMobile}
              >
                Mobile number
              </Button>
            </Box>
            <form
              data-test-id="formsubmit"
              onSubmit={(event) =>
                this.state.isLoggingByMobile
                  ? this.handleMobileLoginSubmit(event)
                  : this.handleUserIdLoginSubmit(event)
              }
            >
              {this.state.isLoggingByMobile ? (
                <>
                  <Box>
                    <p>Please enter your registed mobile number.</p>
                    <p>
                      An <span style={{ color: "#FE6158" }}>OTP</span> will be
                      sent
                    </p>
                  </Box>
                  <FormControl style={{ width: "100%" }}>
                    <PhoneInput
                      data-test-id="mobilenumberfield"
                      country="in"
                      disableDropdown={false}
                      enableSearch={true}
                      value={this.state.phone}
                      onChange={(phone: string) =>
                        this.handleChangePhone(phone)
                      }
                      inputClass={`input-form-myrank ${
                        this.state.phoneDoesNotExist && "input-error"
                      }`}
                    />
                  </FormControl>
                  <Button
                    style={{ width: "100%", color: "white" }}
                    data-test-id="mobilenumbersubmit"
                    type="submit"
                  >
                    SEND OTP
                  </Button>
                </>
              ) : (
                <>
                  <input
                    data-test-id="useridfield"
                    className={`input-form-myrank ${
                      this.state.userNotFound && "input-error"
                    }`}
                    id="outlined-adornment-userid"
                    value={this.state.userInput.userId}
                    name="userId"
                    onChange={(event) => this.handleChangeInput(event)}
                    placeholder="Enter MYRANK ID"
                    style={{ paddingLeft: "1em" }}
                  />
                  <Box className="passwordInput">
                    <input
                      data-test-id="passwordfield"
                      className={`input-form-myrank ${
                        this.state.passwordIncorrect && "input-error"
                      }`}
                      id="outlined-adornment-password"
                      type={this.state.showPassword ? "text" : "password"}
                      value={this.state.userInput.password}
                      name="password"
                      onChange={(event) => this.handleChangeInput(event)}
                      placeholder="Enter Password"
                      style={{ paddingLeft: "1em" }}
                    />
                    <Box
                      data-test-id="peekbtn"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <Visibility style={{ color: "#520082" }} />
                      ) : (
                        <VisibilityOff style={{ color: "#520082" }} />
                      )}
                    </Box>
                  </Box>
                  <Button
                    style={{
                      textTransform: "none",
                      width: "100%",
                      color: "white",
                    }}
                    className={"login-btn-submit"}
                    data-test-id="submituserlogin"
                    type="submit"
                  >
                    Login
                  </Button>
                </>
              )}
            </form>
            {!this.state.isLoggingByMobile && (
              <Box style={{ marginTop: "1em" }} className="rememberme">
                <label>
                  <input
                    type="checkbox"
                    checked={this.state.rememberMe}
                    onChange={this.handleRememberMe}
                    className="checkbox-remember-me"
                    style={{ marginRight: "0.5em", accentColor: "#520082" }}
                    data-test-id="remember-me-btn"
                  />
                  Remember me
                </label>
                <Button
                  style={{ textTransform: "none", color: "#FE5F56" }}
                  onClick={this.handleToForgotPassword}
                >
                  Forgot password ?
                </Button>
              </Box>
            )}
            <Box style={{ marginTop: "2em" }} className="dont-have-account">
              <p>Don't have an account?</p>
              <Button
                style={{ textTransform: "none", textDecoration: "underline" }}
                onClick={this.handleGoToSignUp}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </Box>
        <Modal open={this.state.isModalOpen} onClose={this.handleCloseModal}>
          <Box sx={webStyle.modalStyle}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              position={"relative"}
              margin={"2em 0"}
            >
              <img
                src={party}
                alt={"party-pop"}
                style={{ position: "absolute", zIndex: 200, width: "150px" }}
              />
              <CheckCircleIcon
                style={{
                  color: "#009644",
                  fontSize: "70px",
                  zIndex: 300,
                }}
              />
            </Box>
            <p style={{ fontWeight: 500, fontSize: "18px" }}>
              Logged in Successfully !
            </p>
            <Button
              data-test-id="gotodashboard"
              style={webStyle.buttonDashboard}
              onClick={this.handleGoToDashboard}
            >
              Go to Dashboard
            </Button>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpenVerify}
          onClose={this.handleCloseModalVerify}
        >
          <Box sx={webStyle.modalStyle}>
            <CheckCircleIcon style={{ color: "#FE6B50", fontSize: "70px" }} />
            <p>OTP sent to Mobile Number</p>
            <Button
              data-test-id="gotoverifyotp"
              style={webStyle.buttonDashboard}
              onClick={this.handleVerifyOTP}
            >
              Verify Now
            </Button>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpenOTP}
          onClose={this.handleCloseModalOTPVerify}
        >
          <Box sx={webStyle.modalStyle}>
            <h3>Verify Code</h3>
            <p>
              Check your SMS inbox, we have sent you the code at{" "}
              {this.state.userInput.userNumber}
            </p>
            <form
              data-test-id="otp-input-submit"
              style={{ ...webStyle.centerVertically, flexDirection: "column" }}
              onSubmit={(event) => this.handleOTPSubmit(event)}
            >
              <Box>
                {this.state.otpInput.map((value, index) => (
                  <input
                    data-test-id={`otp-${index + 1}`}
                    key={index}
                    type="tel"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={value}
                    onChange={(event) =>
                      this.handleChangeInputOTP(index, event)
                    }
                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                      this.handleKeyDown(index, event)
                    }
                    ref={this.inputRefs[index]}
                    style={{
                      ...webStyle.otpStyle,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                    maxLength={1}
                  />
                ))}
              </Box>
              <p style={{ fontWeight: 700, marginTop: "2em" }}>
                (00:{this.state.timerSeconds})
              </p>
              {this.state.isOtpError && (
                <p style={{ color: "red", fontSize: "13px" }}>
                  <HighlightOffIcon
                    style={{ position: "relative", top: "10px" }}
                  />{" "}
                  Wrong OTP Entered. Please check and enter it again
                </p>
              )}
              <Box style={{ display: "flex", gap: "2em", marginTop: "2em" }}>
                <Button
                  data-test-id="resendcode"
                  style={webStyle.buttonDashboardDiffuse}
                  onClick={this.handleResendCode}
                >
                  Resend Code
                </Button>
                <Button
                  type="submit"
                  style={webStyle.buttonDashboard}
                  disabled={this.state.timerSeconds === 0}
                >
                  Verify Code
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
        <Modal
          open={this.state.resendOTPModal}
          onClose={this.handleResendOTPModalClose}
        >
          <Box sx={webStyle.modalStyle}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              position={"relative"}
              margin={"2em 0"}
            >
              <img
                src={party}
                alt={"party-pop"}
                style={{ position: "absolute", zIndex: 200, width: "150px" }}
              />
              <CheckCircleIcon
                style={{
                  color: "#009644",
                  fontSize: "70px",
                  zIndex: 300,
                }}
              />
            </Box>
            <p>OTP Resent Successfully</p>
            <Button
              style={webStyle.buttonDashboard}
              onClick={this.handleVerifyOTP}
            >
              Verify Now
            </Button>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpenSuccessVerify}
          onClose={this.handleCloseModalSuccessVerify}
        >
          <Box sx={webStyle.modalStyle}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              position={"relative"}
              margin={"2em 0"}
            >
              <img
                src={party}
                alt={"party-pop"}
                style={{ position: "absolute", zIndex: 200, width: "150px" }}
              />
              <CheckCircleIcon
                style={{
                  color: "#009644",
                  fontSize: "70px",
                  zIndex: 300,
                }}
              />
            </Box>
            <p style={{ fontWeight: 500, fontSize: "18px" }}>
              Verified Successfully !
            </p>
            <Button
              style={webStyle.buttonDashboard}
              onClick={this.handleGoToDashboard}
            >
              Go to Dashboard
            </Button>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpenWrongCredential}
          onClose={() => this.handleCloseModalWrongCredential("modal")}
        >
          <Box sx={webStyle.modalStyle}>
            <p style={{ fontWeight: 500, fontSize: "16px" }}>
              You have entered wrong credentials
            </p>
            <Button
              data-test-id="get-credentials-btn"
              style={webStyle.buttonDashboard}
              onClick={() =>
                this.handleCloseModalWrongCredential("toCredential")
              }
            >
              Get Credentials
            </Button>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpenWrongPassword}
          onClose={this.handleCloseModalWrongPassword}
        >
          <Box sx={webStyle.modalStyle}>
            <p style={{ fontWeight: 500, fontSize: "16px" }}>
              You have entered wrong password
            </p>
            <Button
              style={webStyle.buttonDashboard}
              onClick={this.handleCloseModalWrongPassword}
            >
              Retry
            </Button>
            <Button
              style={{ textTransform: "none", color: "#FE5F56" }}
              onClick={this.handleToForgotPassword}
            >
              Forgot password ?
            </Button>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyle: {
    width: "100%",
    marginTop: "40px",
    height: "45px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonDashboard: {
    height: "50px",
    width: "250px",
    color: "white",
    fontSize: "16px",
    background:
      "linear-gradient(90deg, rgba(255,63,89,1) 0%, rgba(254,98,86,1) 100%)",
  },
  buttonDashboardDiffuse: {
    height: "50px",
    width: "250px",
    fontSize: "16px",
    background: "white",
    border: "1px solid #FF7151",
    color: "#FF7151",
  },
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    left: "50%",
    minWidth: "450px",
    minHeight: "300px",
    width: "max-content",
    border: "2px solid #832890",
    boxShadow: 24,
    backgroundColor: "white",
    p: 4,
    borderRadius: "0.5em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
  },
  otpStyle: {
    width: "80px",
    marginRight: "10px",
    height: "80px",
    borderRadius: "0.7em",
    border: "1px solid gray",
    backgroundColor: "#FFE3E5",
    fontSize: "22px",
  },
  centerVertically: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const webStyleLoginMain = () =>
  createStyles({
    goBackBtn: {
      position: "absolute",
      color: "white",
      top: "20px",
      left: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
      cursor: "pointer",
      "& .back-icon": {
        borderRadius: "50%",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        marginRight: "0.3em",
        width: "24px",
        height: "24px",
        textAlign: "center",
      },
    },
    loginbyId: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
      backgroundSize: "90% 120%",
      backgroundImage: `url(${bgLoginMain})`,

      "@media only screen and (max-width: 1200px)": {
        "&": {
          background: "none",
          backgroundColor: "white",
        },
      },

      "& .circle-icon": {
        fontSize: "70px",
      },

      "& .leftscreen": {
        minHeight: "100vh",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "60%",

        "@media only screen and (max-width: 1200px)": {
          "&": {
            display: "none",
          },
        },

        "& img": {
          width: "450px",
          height: "450px",
        },
      },

      "& .rightscreen": {
        width: "40%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "relative",
        backgroundColor: "white",
        paddingTop: "4em",

        "& .myranklogo": {
          width: "250px",
          marginBottom: "5em",
        },

        "& .main-rightscreen": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          width: "462px",

          "& h1": {
            fontSize: "1.6em",
            fontWeight: 500,
          },

          "& .login-btns": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginTop: "4em",
            gap: "1em",

            "& button": {
              borderRadius: "3px",
              backgroundColor: "rgba(235, 226, 241, 1)",
              width: "226px",
              height: "67px",
              color: "#520082",
            },

            "& .button-chosen": {
              color: "white",
              backgroundColor: "#520082",
            },
          },

          "& form": {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: "2em",
            gap: "2em",

            "& .input-form-myrank": {
              width: "100%",
              height: "80px",
              fontSize: "19px",
              border: "1px solid #520082",
            },

            "& .input-form-myrank:focus": {
              outline: "1px solid #520082",
            },

            "& .input-error": {
              borderColor: "#ff3956",
              color: "#ff3956",
            },

            "& .input-error:focus": {
              outline: "1px solid #ff3956",
            },

            "& button": {
              margin: "0 !important",
              height: "70px",
              fontSize: "22px",
              background:
                "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
            },

            "& .passwordInput": {
              width: "100%",
              position: "relative",
              cursor: "pointer",

              "& div": {
                position: "absolute",
                right: "20px",
                top: "30%",
              },
            },
          },

          "& .dont-have-account": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "13px",

            "& button": {
              color: "#ff3956",
            },
          },

          "& .rememberme": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          },
        },

        "@media only screen and (max-width: 500px)": {
          "&": {
            width: "100%",
            fontSize: "14px",
          },

          "& .main-rightscreen": {
            width: "100% !important",
            padding: "0 1em",
          },

          "& .myranklogo": {
            width: "200px",
          },

          "& form": {
            justifyContent: "center !important",
            alignItems: "center !important",
          },
        },
      },
    },
  });

export default withStyles(webStyleLoginMain)(EmailAccountLogin);
// Customizable Area End
