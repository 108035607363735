import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  testComponent: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '500px',
    height: 'max-content',
    color: '#272727',
    animation: 'animate-in 0.3s forwards',
    width: "100%",
    maxWidth: "800px"
  },
  h3: {
    fontSize: '18px',
    marginBottom: '0.7em',
    maxWidth: '815.17px',
    minWidth: '300px',
    display: "flex",
    fontWeight: 500,
    gap: "0.3em",
    "& img": {
      maxWidth: "300px!important",
    }
  },
  p: {
    fontSize: '18px',
    margin: 0,
    maxWidth: '815.17px',
    minWidth: '300px',
    display: "inline-block",
  },
  testComponentChoice: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    minWidth: '300px',
    height: 'max-content',
    flexWrap: 'wrap',
    minHeight: '70px',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '10px',
    border: '1px solid #969696',
    color: '#5d5d5d',
    cursor: 'pointer',
    backgroundColor: 'white',
    transition: '0.3s all ease',
    "& input": {
      width: '36px',
      height: '36px',
    },
    "& > div": {
      width: '90%',
      wordWrap: 'break-word',
    },
    "& img": {
      maxWidth: "500px!important",
      height: "auto!important",
      objectFit: "cover",
    },
  },
  testComponentChecked: {
    background: 'linear-gradient(90deg, rgba(255, 54, 86, 1) 0%, rgba(255, 113, 81, 1) 100%)',
    color: 'white',
    border: 'none',
    '& input[type="checkbox"]': {
      border: 'none',
    },
  },
  checkbox: {
    appearance: 'none',
    width: '36px',
    height: '36px',
    border: '2px solid #969696',
    borderRadius: '50%',
    marginRight: '10px',
    position: 'relative',
    transition: '0.3s all ease',
    '&:checked': {
      backgroundColor: 'white',
      '&:after': {
        content: '""',
        display: 'block',
        width: '15px',
        height: '9px',
        border: '3px solid rgba(255, 54, 86, 1)',
        borderTop: 'none',
        borderRight: 'none',
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(-47deg)',
      },
    },
  },
  textarea: {
    resize: 'none',
    width: '100%',
    maxWidth: '815.17px',
    minWidth: '300px',
    maxHeight: '450px',
    height: '450px',
    padding: '1em',
    border: '1px solid #ffa297',
    borderRadius: '5px',
    fontSize: '20px',
    fontWeight: 500,
    transition: 'border 0.3s ease',
    backgroundColor: 'rgb(254, 249, 255)',
    '&:focus': {
      border: '2px solid #ffa297',
      outline: 'none',
    },
  },
  animateIn: {
    animation: '$animate-in 0.3s forwards',
  },
  '@keyframes animate-in': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
});

interface Option {
  id: string;
  option: string;
}

interface TopicQuestion {
  id: string;
  type: string;
  attributes: {
    id: number;
    topic_id?: number;
    question_no?: number;
    question?: string;
    grand_test_id?: number;
    sub_name?: string;
    question_1?: string;
    question_2?: string;
    question_type: string;
    options: Option[];
  };
}

const TestComponent = ({
  question,
  index,
  setAnswer,
  answers,
}: {
  question: TopicQuestion;
  index: number;
  setAnswer: (index: number, option: string[][]) => void;
  answers: { question_id: number; option: string[][]; }[];
}) => {
  const [animate, setAnimate] = useState(true);
  const classes = useStyles();
  const prevSelectedOptionsRef = useRef<string[]>([]);
  const answerOption = answers[index]?.option;
  const initialInput = answerOption && answerOption[0]?.[0] || "";
  const [input, setInput] = useState<string>(initialInput);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(() => {
    if (answers && answers[index] && Array.isArray(answers[index]?.option)) {
      return answers[index]?.option[0] || [];
    } else {
      return [];
    }
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setInput(value);
    setSelectedOptions([value]);
  };

  const handleSingleChoiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!selectedOptions.includes(value)) {
      setSelectedOptions([value]);
    }
  };

  const handleMultipleChoiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!selectedOptions.includes(value)) {
      setSelectedOptions((prevSelected) => [...prevSelected, value]);
    }
  };

  useEffect(() => {
    if (JSON.stringify(prevSelectedOptionsRef.current) !== JSON.stringify(selectedOptions)) {
      prevSelectedOptionsRef.current = selectedOptions;
      setAnswer(index, [selectedOptions]);
    }
  }, [selectedOptions]);


  useEffect(() => {
    const currentOption = answers[index]?.option;
    if (currentOption && Array.isArray(currentOption[0])) {
      setSelectedOptions(currentOption[0] || []);
    } else {
      setSelectedOptions([]);
    }
  
    if (question.attributes.question_type === "numerical" && currentOption?.[0]?.[0]) {
      setInput(currentOption[0][0] || "");
    } else {
      setInput("");
    }
  }, [question, index, answers]);
  
  useEffect(()=>{
    const animationTimeout = setTimeout(() => {
      setAnimate(true);
    }, 1000);

    return () => clearTimeout(animationTimeout);
  },[])
  return (
    <Box className={`${classes.testComponent} ${animate ? classes.animateIn : ''}`} key={`question-${index}`}>
      <div className={classes.h3}>
        <div>{question.attributes.question_no}.</div>
        <div
          style={{ maxWidth: "100%" }}
          dangerouslySetInnerHTML={{
            __html: (question.attributes.question_1 || question.attributes.question) as string,
          }}
        />
      </div>
      <div className={classes.h3}>
        <div
          style={{ maxWidth: "100%", marginTop: "1em", marginLeft: "1em", }}
          dangerouslySetInnerHTML={{
            __html: question.attributes.question_2 as string,
          }}
        />
      </div>
      <Box>
        {question.attributes.question_type === "single_choice"
          && question.attributes.options.map((option: Option, index) => (
              <label key={option.id} className={`${classes.testComponentChoice} ${selectedOptions.includes(option.id) && classes.testComponentChecked}`}>
                <input
                  type="checkbox"
                  name={`single-choice-${index}`}
                  value={option.id}
                  checked={selectedOptions.includes(option.id)}
                  onChange={handleSingleChoiceChange}
                  className={classes.checkbox}
                />
                <span>{`${String.fromCharCode(index + 65)})`}&nbsp;</span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: option.option
                  }}
                />
              </label>
            ))
          }
          {question.attributes.question_type === "multiple_choice" && question.attributes.options.map((option: Option, index) => (
              <label key={option.id} className={`${classes.testComponentChoice} ${selectedOptions.includes(option.id) && classes.testComponentChecked}`}>
                <input
                  type="checkbox"
                  name={`multiple-choice-${index}`}
                  value={option.id}
                  checked={selectedOptions.includes(option.id)}
                  onChange={handleMultipleChoiceChange}
                  className={classes.checkbox}
                />
                <span>{`${String.fromCharCode(index + 65)})`}&nbsp;</span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: option.option
                  }}
                />
              </label>
            ))}
        {question.attributes.question_type === "numerical" && (
          <Box>
            <textarea
              placeholder="Type your answer"
              onChange={handleInputChange}
              value={input}
              className={classes.textarea}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TestComponent;
