import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { ISlotDetails } from "./BookMySlotChangeSlotController.web";

export interface ISlot {
  date: string;
  slots: Array<{
    slot_subject: string;
    slot_time: string;
    availability: string;
  }>;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  valueDate: string;
  slots: Array<ISlot>;
  loading: boolean;
  timeSlot: string;
  slotId: string;
  slotDetails: ISlotDetails | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotDateTimePickController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSlotsForDateMessageId = "";
  breadcrumb = [
    {
      link: `/dashboard/book-my-slot`,
      label: "Book my Slot",
    },
    {
      link: `/dashboard/book-my-slot`,
      label: "Slots",
    },
    {
      label: "Appointments",
      link: `/dashboard/book-my-slot/appointments`,
    },
    {
      label: "Date/Time Pick",
      link:
        this.props.navigation.history.location.pathname +
        this.props.navigation.history.location.search,
    },
  ];
  getSlotIdOfAppointmentMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      valueDate: "",
      timeSlot: "",
      slots: [],
      loading: false,
      slotId: "",
      slotDetails: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSlotsForDateMessageId && response) {
      if (!response?.error) {
        this.successfulFetchOfTimeSlots(response);
      }
    }

    if (apiRequestCallId === this.getSlotIdOfAppointmentMessageId) {
      if (response?.data) {
        this.successFetch(response.data);
      }
    }
    // Customizable Area End
  }

  // web events

  // Customizable Area Start
  componentDidMount = async () => {
    const search = this.props.navigation.history.location.search;
    const params = new URLSearchParams(search);
    if (search) {
      const slotId = params.get("slotId") as string;

      if (slotId) {
        this.getSlotIdOfAppointment(slotId);
        this.successSlotIdFetch(slotId);
      }
    }
    this.getSlotsForDate();

    const propPassingDateTime = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingDateTime.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingDateTime);
  };

  successFetch = (slotDetails: ISlotDetails) => {
    this.setState({
      slotDetails,
      valueDate: slotDetails.attributes.slot_date,
      loading: false,
    });
  };

  successSlotIdFetch = (slotId: string) => {
    this.setState({
      slotId,
    });
  };

  successfulFetchOfTimeSlots = (slots: ISlot[]) => {
    this.setState({
      slots,
      loading: false,
    });
  };

  changeDateChange = (value: string) => {
    const valueDate = this.formatDate(value);

    this.setState({
      valueDate,
    });
  };

  setTimeSlot = (timeSlot: string) => {
    this.setState({
      timeSlot,
    });
  };

  goToNewSlots = () => {
    storage.set(
      "dateTimeSlot",
      JSON.stringify({ date: this.state.valueDate, time: this.state.timeSlot })
    );
    const search = this.props.navigation.history.location.search;
    const params = new URLSearchParams(search);
    const slotId = params.get("slotId") as string;

    if (slotId) {
      return this.props.navigation.history.push(
        `/dashboard/book-my-slot/add-edit-slot?slotId=${slotId}&edit=true`
      );
    }

    this.props.navigation.history.push(`/dashboard/book-my-slot/add-edit-slot`);
  };

  formatDate = (data: string) => {
    const date = new Date(data);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  getSlotsForDate = async () => {
    const getSlotsForDateMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getSlotsForDateMessageId = getSlotsForDateMessage.messageId;

    getSlotsForDateMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSlotsEndPoint
    );

    getSlotsForDateMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSlotsForDateMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSlotsForDateMessage.id, getSlotsForDateMessage);
  };

  getSlotIdOfAppointment = async (slotId: string) => {
    const getSlotIdOfAppointmentMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (!slotId) {
      this.props.navigation.history.goBack();
    }
    this.setState({
      loading: true,
      slotId,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getSlotIdOfAppointmentMessageId =
      getSlotIdOfAppointmentMessage.messageId;

    getSlotIdOfAppointmentMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/cms_appoinments/${slotId}`
    );

    getSlotIdOfAppointmentMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSlotIdOfAppointmentMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getSlotIdOfAppointmentMessage.id,
      getSlotIdOfAppointmentMessage
    );
  };
  // Customizable Area End
}
