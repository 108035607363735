import React from "react";

import {
  Box as MUIBox,
  // Customizable Area Start
  withStyles,
  Button,
  Typography,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AccordionComponent from "../../../components/src/AccordionComponent.web";
import Loader from "../../../components/src/Loader.web";
import { webStyle, styles } from "./QuestionBankMain.web";
import { webStylePTGuest } from "../../CfTestManagement2/src/GuestPracticeTest.web";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { warning } from "./assets";
// Customizable Area End

import GuestQbMainController, { Props } from "./GuestQbMainController.web";

class GuestQbMain extends GuestQbMainController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MUIBox>
        <Modal
          open={this.state.showModalSubmit}
          data-test-id="modal-here"
          BackdropProps={{ onClick: undefined }}
        >
          <MUIBox
            sx={webStylePTGuest.modalStyle}
            className="submit-test-question"
          >
            <CheckCircleIcon style={webStylePTGuest.circleModal} />
            <Typography style={webStylePTGuest.headerStyleModal}>
              Demo Test Complete
            </Typography>
            <Typography style={webStylePTGuest.subStyleModal}>
              To get access for real test and more content you need to register.
            </Typography>
            <Button
              style={webStylePTGuest.buttonStyleYes}
              onClick={this.navigateToRegister}
              data-test-id="get-here"
            >
              Get Started
            </Button>
            <Button
              style={webStylePTGuest.buttonStyleCancel}
              onClick={this.showModalSubmit}
              data-test-id="cancel-here"
            >
              Cancel
            </Button>
          </MUIBox>
        </Modal>
        <Loader loading={this.state.loading} />
        <MUIBox
          padding={"2em"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <MUIBox width={"80%"}>
            <MUIBox sx={webStyle.buttonNavigation}>
              {this.state.questionTypes.map((questionType, index) => {
                return (
                  <Button
                    data-test-id="add-exr-testid"
                    className={
                      this.state.activeButton === index
                        ? this.props.classes.activeLink
                        : ""
                    }
                    onClick={() => {
                      if (index > 0) {
                        return this.showModalSubmit();
                      }
                      return this.getQuestions(
                        this.props.navigation.getParam("topicId"),
                        questionType.question_type,
                        index
                      );
                    }}
                  >
                    {questionType.question_type}
                  </Button>
                );
              })}
            </MUIBox>
            <MUIBox sx={webStyle.listContainerStyle}>
              {this.state.questions.length > 0 ? (
                this.state.questions.map((list, index) => {
                  return (
                    <MUIBox
                      key={list.attributes.id}
                      onClick={() => {
                        return index > 0 && this.showModalSubmit();
                      }}
                      data-test-id="navigate-test"
                    >
                      <AccordionComponent
                        {...list}
                        withNotesBookmark={false}
                        disableClick={index > 0 ? true : false}
                      />
                    </MUIBox>
                  );
                })
              ) : (
                <p style={{ fontWeight: 500 }}>No data found in this section</p>
              )}
            </MUIBox>
            {this.state.questions.length > 0 && (
              <MUIBox display={"flex"} justifyContent={"flex-end"}>
                <Button
                  data-test-id="report-testid"
                  onClick={this.handleReportModal}
                  className={this.props.classes.buttonReport}
                >
                  <img src={warning} alt="report" />
                </Button>
              </MUIBox>
            )}
          </MUIBox>
        </MUIBox>
        <Modal
          open={this.state.showModalReport}
          onClose={this.handleReportModal}
        >
          <MUIBox sx={webStyle.modalStyle} className="submit-test-done">
            <MUIBox style={{ width: "100%", marginTop: "3em" }}>
              <Typography variant="h6">Do you want to report?</Typography>
              <form
                data-test-id="report-field-submit"
                onSubmit={(event) => this.sendReport(event)}
              >
                <textarea
                  className={this.props.classes.textAreaStyle}
                  data-test-id="report-field"
                  placeholder="Enter your message"
                  value={this.state.reportInput}
                  onChange={(event) =>
                    this.handleReportInput(event.target.value)
                  }
                />
                <MUIBox sx={webStyle.centerButtonStyle}>
                  <Button
                    className={this.props.classes.buttonStyleNo}
                    onClick={this.handleReportModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={this.props.classes.buttonStyleNo}
                    type="submit"
                  >
                    Send
                  </Button>
                </MUIBox>
              </form>
            </MUIBox>
          </MUIBox>
        </Modal>
        <Modal
          open={this.state.reportSuccessModal}
          onClose={this.handleReportSuccessModal}
        >
          <MUIBox sx={webStyle.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>Report Submitted</h3>
          </MUIBox>
        </Modal>
      </MUIBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(GuestQbMain);
export { GuestQbMain };
// Customizable Area End
