import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { format } from "date-fns";
import storage from "../../../framework/src/StorageProvider.web";
export type ChapterType = {
  id: string;
  type: string;
  attributes: {
    id: number;
    test_name: string;
    test_subject: string;
    test_category: string;
    no_of_questions: number | null;
    duration: number;
    start_time: string;
    end_time: string;
    status: string;
    created_at: string;
    test_submitted_already: boolean;
    image: string | null;
    search_key: string | null;
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  chapters: ChapterType[];
  showModalSubmit: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GuestWeeklyTestChaptersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTestsMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      chapters: [],
      showModalSubmit: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getTestsMessageId && response) {
      if (response?.data) {
        this.handleTests(response.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getTests();
  };

  // Customizable Area Start
  handleDate = (date: string) => {
    const formattedDate = format(new Date(date), "dd MMM yyyy");
    return formattedDate;
  };

  handleDays = (date: string) => {
    const formattedTime = format(new Date(date), "hh:mm a");
    return formattedTime;
  };

  navigateToRegister = () => {
    this.props.navigation.history.push("/register");
  };

  showModalSubmit = () => {
    this.setState({
      showModalSubmit: !this.state.showModalSubmit,
    });
  };

  navigateToTest = (testId: string) => {
    this.props.navigation.navigate("GuestWeeklyTest", {
      testId,
    });
  };

  getTests = async () => {
    const getTestsMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      uuid: await storage.get("guestToken"),
    };

    this.setState({
      loading: true,
    });

    this.getTestsMessageId = getTestsMessage.messageId;

    getTestsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/get_tests`
    );

    getTestsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getTestsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getTestsMessage.id, getTestsMessage);
  };

  navigateTo = (navigateTo: string) => {
    this.props.navigation.history.push(navigateTo);
  };

  handleTests = (response: ChapterType[]) => {
    this.setState({
      chapters: response,
    });
  };
  // Customizable Area End
}
