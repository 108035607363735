import React from "react";

import {
  Box as BoxMaterial,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography as MUITypo,
  Button,
  Grid as MUIGrid,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import { Physics, emptyStar, starIcon } from "./assets";
import { boxStyles } from "./BookMySlot.web";
import Loader from "../../../components/src/Loader.web";
import { webStyle } from "./BookMySlotAddEditSlot.web";
import { AppointmentT } from "./BookMySlotStudentAppointmentsController.web";
// Customizable Area End

import BookMySlotStudentFeedbacksController, {
  Props,
  configJSON,
} from "./BookMySlotStudentFeedbacksController.web";

class BookMySlotStudentFeedbacks extends BookMySlotStudentFeedbacksController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderAppointmentBox = (data: AppointmentT) => {
    return (
      <MUIGrid key={`bookSlot-${this.state.slotDetails?.id}`} sm={12} item>
        <BoxMaterial className={this.props.classes.appointmentBox}>
          <BoxMaterial className="details-appointment" position={"relative"}>
            <img src={Physics} alt={"physics-image"} />
            <BoxMaterial
              gridGap={"1em"}
              display="flex"
              flexDirection={"column"}
              marginLeft={"3em"}
            >
              <MUITypo>
                {configJSON.subjectTitle} -{" "}
                {this.state.slotDetails?.attributes.bms_slot.slot_subject}
              </MUITypo>
              <MUITypo>
                Chapter -{" "}
                {
                  this.state.slotDetails?.attributes.chapter.data.attributes
                    .name
                }
              </MUITypo>
              <MUITypo>
                Topic -{" "}
                {this.state.slotDetails?.attributes.topic.data.attributes.name}
              </MUITypo>
              <MUITypo>
                Time - {this.state.slotDetails?.attributes.bms_slot.slot_start}{" "}
                - {this.state.slotDetails?.attributes.bms_slot.slot_end}
              </MUITypo>
            </BoxMaterial>
          </BoxMaterial>
          <BoxMaterial className="buttons-appointment">
            <Button className="status">
              Status: {this.state.slotDetails?.attributes.bms_slot.slot_status}
            </Button>
            <MUITypo>
              Faculty -{" "}
              {this.state.slotDetails?.attributes.faculty_account.data.attributes.name}
            </MUITypo>
          </BoxMaterial>
        </BoxMaterial>
      </MUIGrid>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <BoxMaterial>
        <Modal
          open={this.state.isSlotUpdatedModal}
          onClose={this.handleIsSlotUpdatedModal}
          data-test-id="modal-slot-booked"
        >
          <BoxMaterial sx={webStyle.modalStyle}>
            <MUITypo>Feedback Submitted Successfully</MUITypo>
            <Button
              style={webStyle.buttonStyle}
              onClick={this.navigateToBookings}
              data-test-id="homebtn"
            >
              Go to Home
            </Button>
          </BoxMaterial>
        </Modal>
        <BannerWeb title="Feedback" />
        <Loader loading={this.state.loading} />
        <BoxMaterial
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <BoxMaterial
            display="flex"
            flexDirection={"column"}
            width={"70%"}
            marginTop={"5em"}
          >
            <BoxMaterial
              alignSelf={"center"}
              width={"100%"}
              marginBottom={"3em"}
            >
              {this.state.slotDetails &&
                this.RenderAppointmentBox(this.state.slotDetails)}
            </BoxMaterial>
            <BoxMaterial>
              <BoxMaterial
                display="flex"
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent="center"
                width={"100%"}
                marginBottom={"3em"}
              >
                <MUITypo className={this.props.classes.typographyStyle2}>
                  Please Rate and Review your doubt session with{" "}
                  {
                    this.state.slotDetails?.attributes.faculty_account.data
                      .attributes.name
                  }
                </MUITypo>
                <BoxMaterial>
                  {Array.from(Array(5).keys()).map((_, index) => {
                    return (
                      <>
                        {this.state.starsIndex >= index ? (
                          <img
                            width={"30px"}
                            src={starIcon}
                            alt="filled"
                            onClick={() => this.handleRatedStars(index)}
                            style={{ cursor: "pointer" }}
                            data-test-id="rate-btn-star"
                          />
                        ) : (
                          <img
                            width={"30px"}
                            src={emptyStar}
                            alt="empty"
                            onClick={() => this.handleRatedStars(index)}
                            style={{ cursor: "pointer" }}
                            data-test-id="rate-btn-star"
                          />
                        )}
                      </>
                    );
                  })}
                </BoxMaterial>
              </BoxMaterial>
              <BoxMaterial>
                <MUITypo className={this.props.classes.typographyStyle}>
                  Write your review
                </MUITypo>
                <textarea
                  className={this.props.classes.textAreaStyle}
                  onChange={(event) => this.setComments(event.target.value)}
                  placeholder="How's the experience?"
                  value={this.state.comments}
                  data-test-id="textarea"
                />
              </BoxMaterial>
              <BoxMaterial
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Button
                  className={this.props.classes.submitButtonStyle}
                  onClick={this.postStudentFeedback}
                  data-test-id="submit"
                >
                  Update
                </Button>
              </BoxMaterial>
            </BoxMaterial>
          </BoxMaterial>
        </BoxMaterial>
      </BoxMaterial>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleBookMySlotStudentFeedbacks = () =>
  createStyles({
    appointmentBox: {
      ...boxStyles,
      "& .details-appointment p": {
        fontWeight: 500,
        fontSize: "1.4em",
      },
      "& .details-appointment img": {
        height: "180px!important",
        width: "300px!important",
        borderRadius: "0.7em!important",
      },
      padding: "2em!important",
      border: "1px solid #ccc!important",
      "& .buttons-appointment": {
        alignItems: "center",
        display: "flex",
        marginTop: "1.3em",
        "& .status": {
          borderRadius: "4px",
          background: "rgba(173, 135, 197, 0.25)",
          minWidth: "226px",
          color: "rgb(82, 0, 130)",
          height: "48px",
          width: "max-content",
          fontWeight: 500,
        },
        "& p": {
          marginLeft: "1em",
          fontWeight: 500,
        },
      },
    },
    selectStyle: {
      width: "100%",
      fontSize: "1.4em",
      outline: 0,
      border: "1px solid #FF4D4F",
      borderRadius: "0.7em",
      height: "70px",
      color: "#5C0F89",
      fontWeight: 500,
      paddingLeft: "1.5em",
      WebkitAppearance: "none",
      marginTop: "1.3em",
    },
    expandMoreIcon: {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      color: "white",
      borderRadius: "50%",
      right: "30px",
      top: "47px",
      cursor: "pointer",
      position: "absolute",
    },
    typographyStyle: {
      fontSize: "1.3em",
      fontWeight: 500,
    },
    textAreaStyle: {
      width: "100%",
      height: "200px",
      border: "1px solid #FF4D4F",
      borderRadius: "0.7em",
      resize: "none",
      padding: "1.5em",
      fontSize: "1.2em",
      outline: 0,
      marginTop: "1.3em",
    },
    submitButtonStyle: {
      width: "400px",
      color: "white",
      borderRadius: "0.5em",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      height: "60px",
      margin: "2em 0 3em 0",
      alignSelf: "center",
    },
    typographyStyle2: {
      fontSize: "1.7em",
      fontWeight: 500,
    },
  });
export default withStyles(webStyleBookMySlotStudentFeedbacks)(
  BookMySlotStudentFeedbacks
);
export { BookMySlotStudentFeedbacks };
// Customizable Area End
