import React from "react";

import {
  Box,
  // Customizable Area Start
  Button as MUIButton,
  createStyles,
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
import { noreview, Bookmark, saveImg } from "./assets";
import Button from "../../../components/src/Button.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import ReviewTestMainController, {
  Props,
} from "./ReviewTestMainController.web";

export class ReviewTestMain extends ReviewTestMainController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box className={this.props.classes.bannerStyle}>
          <h2>Review History</h2>
        </Box>
        <Box margin="2em">
          {this.state.review_histories.length > 0 ? (
            <>
              <p style={{ fontSize: "19px" }}>
                No. of attempts : {this.state.review_histories.length}
              </p>
              <p style={{ fontSize: "20px" }}>
                Test Name : {this.state.chapterData?.chapter.name}
              </p>
              <Box className={this.props.classes.setMiddleStyleColumn}>
                {this.state.review_histories.map((review, index) => {
                  const subjectId = this.props.navigation.getParam("subjectId");
                  const chapterId = this.props.navigation.getParam("chapterId");
                  return (
                    <Box>
                      <div className={this.props.classes.cardStyle}>
                        <h3>
                          Test Attempt{" "}
                          {index + 1}
                        </h3>
                        <div className={this.props.classes.setMiddleStyle}>
                          <Link
                            to={`/dashboard/practice-test/chapters/${subjectId}/review-test/${chapterId}/review/${review.id}${this.searchResolve}`}
                          >
                            <MUIButton
                              className={this.props.classes.buttonStyle}
                            >
                              Review Test
                              <Box
                                data-test-id="gobackbtn"
                                className={this.props.classes.goBackStep}
                              >{`>`}</Box>
                            </MUIButton>
                          </Link>
                          <p className={this.props.classes.textStyle}>
                            {this.convertToDate(review.created_at)}
                          </p>
                        </div>
                        <Box
                          display="flex"
                          position="absolute"
                          top="0"
                          right="0"
                          gridGap="1em"
                          padding={"0.5em 1em"}
                          className={this.props.classes.savedNotesBookmarkStyle}
                        >
                          <Link
                            to={`/dashboard/practice-test/chapters/${this.props.navigation.getParam(
                              "subjectId"
                            )}/review-test/${this.props.navigation.getParam(
                              "chapterId"
                            )}/saved-notes/${review.id}${this.searchResolve}`}
                          >
                            <img
                              src={saveImg}
                              alt="save-notes"
                              style={{ marginLeft: "4px" }}
                              data-test-id="saved-notes-btn"
                            />
                            <p style={{ fontSize: "10px", color: "#999" }}>
                              Notes
                            </p>
                          </Link>
                          <Link
                            to={`/dashboard/practice-test/chapters/${this.props.navigation.getParam(
                              "subjectId"
                            )}/review-test/${this.props.navigation.getParam(
                              "chapterId"
                            )}/bookmarks/${review.id}${this.searchResolve}`}
                          >
                            <img
                              src={Bookmark}
                              alt="bookmark-notes"
                              style={{ marginLeft: "15px" }}
                              data-test-id="bookmark-btn"
                            />
                            <p style={{ fontSize: "10px", color: "#999" }}>
                              Bookmark
                            </p>
                          </Link>
                        </Box>
                      </div>
                    </Box>
                  );
                }).reverse()}
              </Box>
            </>
          ) : (
            <Box className={this.props.classes.setMiddleStyleColumn2}>
              <Box className={this.props.classes.setMiddleStyleColumn2}>
                <img src={noreview} alt="noreview" width={"600px"} />
                <h2>You didn't take test to Review</h2>
                <p>Click on Take Test to get Review</p>
              </Box>
              <Link
                to={`/dashboard/practice-test/test/${this.props.navigation.getParam(
                  "chapterId"
                )}${this.searchResolve}`}
              >
                <Button
                  color="white"
                  height="40px"
                  title="Take Test"
                  width="500px"
                />
              </Link>
            </Box>
          )}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleReviewTestMain = () =>
  createStyles({
    setMiddleStyle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginTop: "1em",
    },
    setMiddleStyleColumn: {
      display: "flex",
      justifyContent: "flex-start",
      gap: "2em",
      alignItems: "flex-start",
      marginTop: "1.5em",
      flexWrap: "wrap",
      flexDirection: "row",
    },
    setMiddleStyleColumn2: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "2em",
    },
    savedNotesBookmarkStyle: {
      border: "1px solid #FF9486",
      borderRadius: "0.5em",
      "& img": {
        width: "20px",
        height: "20px",
        cursor: "pointer",
      },
    },
    cardStyle: {
      height: "142px",
      border: "1px solid #FF9486",
      width: "366px",
      position: "relative",
      borderRadius: "10px",
      paddingLeft: "2em",
      paddingTop: "1em",
    },
    buttonStyle: {
      color: "rgb(82, 0, 130)",
      width: "125px",
      borderRadius: "10px",
      textTransform: "none" as const,
      border: "1px solid #FF9486",
      height: "40px",
      position: "relative",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    goBackStep: {
      display: "flex",
      justifyContent: "center",
      width: "20px",
      alignItems: "center",
      height: "20px",
      borderRadius: "50%",
      padding: "0.3em",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      color: "white",
      right: "5px",
      cursor: "pointer",
      position: "absolute",
    },
    bannerStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "71px",
      background: "rgba(247, 214, 255, 0.19)",
      color: "#461479",
    },
    textStyle: {
      fontSize: "12px",
      fontFamily: "Helvetica, system-ui, sans serif",
      fontWeight: "bold" as const,
      color: "rgb(72, 100, 132)",
    },
  });

export default withStyles(webStyleReviewTestMain)(ReviewTestMain);
// Customizable Area End
