import React from "react";

import {
  Box as AddEditBox,
  // Customizable Area Start
  Modal as AddEditModal,
  Button as ButtonAddEdit,
  withStyles,
  createStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { NavLink } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import SynopsisSavedNotesAddEditController, {
  Props,
} from "./SynopsisSavedNotesAddEditController.web";

class SynopsisSavedNotesAddEdit extends SynopsisSavedNotesAddEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <AddEditBox className={this.props.classes.centerHorizontally}>
        <Loader loading={this.state.loading} />
        <AddEditModal
          BackdropProps={{ onClick: undefined }}
          open={this.state.isNoteCreateSavedSuccessfully}
          onClose={this.handleNoteCreateModalClose}
        >
          <AddEditBox className={this.props.classes.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>
              {this.state.noteType === "Edit"
                ? "Changes Updated"
                : "Note Saved"}
            </h3>
            <AddEditBox>
              <ButtonAddEdit
                data-test-id="go-saved-success"
                onClick={this.handleNoteCreateModalClose}
                className={this.props.classes.buttonStyleYes}
              >
                <NavLink
                  to={`/dashboard/synopsis/chapters/${this.props.navigation.getParam(
                    "subjectId"
                  )}/topics/${this.props.navigation.getParam(
                    "chapterId"
                  )}/saved-notes/${this.props.navigation.getParam("topicId")}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Go to saved notes
                </NavLink>
              </ButtonAddEdit>
            </AddEditBox>
          </AddEditBox>
        </AddEditModal>
        <AddEditModal
          onClose={this.handleNoteCancelModalClose}
          BackdropProps={{ onClick: undefined }}
          open={this.state.isNoteCancellationModalOpen}
        >
          <AddEditBox className={this.props.classes.modalStyle}>
            <h3>Are you sure you want to cancel note without saving?</h3>
            <AddEditBox>
              <ButtonAddEdit
                className={this.props.classes.buttonStyleNo}
                data-test-id="go-saved-no"
                onClick={this.handleNoteCancelModalClose}
              >
                No
              </ButtonAddEdit>
              <ButtonAddEdit
                className={this.props.classes.buttonStyleYes}
                data-test-id="go-saved-yes"
                onClick={this.confirmCancel}
              >
                Yes
              </ButtonAddEdit>
            </AddEditBox>
          </AddEditBox>
        </AddEditModal>
        <AddEditModal
          open={this.state.cancelModalConfirm}
          onClose={this.handleNoteCancelConfirmModalClose}
          BackdropProps={{ onClick: undefined }}
        >
          <AddEditBox className={this.props.classes.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>Cancelled Note Successfully!</h3>
            <AddEditBox>
              <ButtonAddEdit
                onClick={this.handleNoteCancelConfirmModalClose}
                data-test-id="go-back"
                className={this.props.classes.buttonStyleYes}
              >
                Go Back
              </ButtonAddEdit>
            </AddEditBox>
          </AddEditBox>
        </AddEditModal>
        <AddEditBox className={this.props.classes.bannerStyle}>
          <h2>{this.state.noteType} Note</h2>
        </AddEditBox>
        <AddEditBox className={this.props.classes.inputStyle}>
          <AddEditBox className={this.props.classes.inputBannerStyle}>
            <p>Subject : {this.state.subjectName}</p>
            <p>Topic - {this.state.topicName}</p>
          </AddEditBox>
          <textarea
            value={this.state.content}
            onChange={(event) => this.handleInputChange(event.target.value)}
            className={this.props.classes.textInputStyle}
            data-test-id="text-area"
            name="textarea"
            placeholder="Write your note here"
          />
        </AddEditBox>
        <AddEditBox>
          <ButtonAddEdit
            data-test-id="cancel-btn"
            onClick={this.handleNoteCancelModalOpen}
            className={this.props.classes.buttonStyleNo}
          >
            Cancel
          </ButtonAddEdit>
          <ButtonAddEdit
            data-test-id="save-btn"
            className={this.props.classes.buttonStyleYes}
            onClick={this.NoteApi}
          >
            {this.state.noteType === "New" ? "Save Note" : "Update Changes"}
          </ButtonAddEdit>
        </AddEditBox>
      </AddEditBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleSavedNotesAddEdit = () =>
  createStyles({
    bannerStyle: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#222",
      background: "rgba(247, 214, 255, 0.19)",
      height: "71px",
    },
    buttonStyleYes: {
      textTransform: "none" as const,
      color: "white",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      width: "200px",
      height: "50px",
      marginLeft: "1em",
    },
    inputBannerStyle: {
      width: "100%",
      padding: "0em 2em",
      display: "flex",
      borderBottom: "1px solid gray",
      flexDirection: "column" as const,
      height: "70px",
      fontSize: "24px",
      alignItems: "flex-start",
    },
    buttonStyleNo: {
      background: "white",
      height: "50px",
      color: "#4F1f6d",
      width: "200px",
      border: "1px solid #ffe9ed",
      textTransform: "none" as const,
      borderRadius: "0.6em",
    },
    inputStyle: {
      height: "max-content",
      width: "90%",
      background: "white",
      border: "1px solid rgb(255,167,167)",
      borderRadius: "0.7em",
    },
    centerHorizontally: {
      justifyContent: "center",
      display: "flex",
      gap: "1em",
      flexDirection: "column" as const,
      alignItems: "center",
    },
    modalStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      display: "flex",
      alignItems: "center",
      transform: "translate(-50%, -50%)",
      flexDirection: "column" as const,
      justifyContent: "center",
      minHeight: "max-content",
      padding: "2em",
      backgroundColor: "white",
      border: "2px solid #FFD0D8",
      width: "35%",
      borderRadius: "1.5em",
      gap: "1.5em",
    },
    textInputStyle: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      outline: "none",
      resize: "none" as const,
      borderRadius: "0.7em",
      padding: "1.2em 1.2em",
      minHeight: "500px",
      border: "none",
      fontSize: "20px",
      height: "max-content",
      width: "100%",
      ":focus": {
        outline: "none",
      },
    },
  });

export default withStyles(webStyleSavedNotesAddEdit)(SynopsisSavedNotesAddEdit);
export { SynopsisSavedNotesAddEdit };
// Customizable Area End
