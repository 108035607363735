import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { Slot } from "./BookMySlotFacultySlotAvailabilityController.web";
import { Chapter, Subject } from "./BookMySlotStudentController.web";
import { ValueType } from "react-select";

export type Topic = {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    remarks: string;
    remark_2: string;
    question_status: string;
    synopsis: string;
    topic_read: boolean;
    saved_notes_count: number;
    is_bookmarked: boolean;
    chapter: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          name: string;
          image: string;
          practice_test: string;
          avail_pt: string;
          date_pt: string;
          class_zone: string;
          avail_cz: string;
          date_cz: string;
          question_bank: string;
          avail_qb: string;
          date_qb: string;
          created_by: string;
          remarks: string;
          chapter_read: boolean;
          viewed: boolean;
          continue_reading_topic_id: number;
          test_attempted: boolean;
          subject: {
            id: number;
            created_at: string;
            updated_at: string;
            name: string;
            created_by: string;
            class_zone: string;
            practice_test: string;
            question_bank: string;
            subjective_test: string;
            st_code: string;
            remarks: string;
            icon_code: string;
            st_db: string;
            score_db: string;
            chapter_db: string;
          };
        };
      };
    };
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loadingQueues: number;
  doubtComment: string;
  valueDate: string;
  slots: Slot[];
  slotsArrayCurrentData: Slot[];
  subject: Subject | null;
  meta: {
    all_slots: number;
    all_booked_slots: number;
  };
  subjectId: string;
  chapterId: string;
  chapter: Chapter | null;
  slotId: string;
  isSlotUpdatedModal: boolean;
  isErrorModal: boolean;
  userInputTopic: Topic | null;
  topics: { value: Topic; label: string }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotStudentBookSlotController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSlotsAvailableStudentMessageId = "";
  getBookedSlotsForBookSlotsMessageId = "";
  getStudentSubjectsForBookSlotsMessageId = "";
  getChaptersOnSubjectBookSlotMessageId = "";
  bookStudentSlotMessageId = "";
  getDataTopicsMessageId = "";
  breadcrumb = [
    {
      link: `/dashboard/book-my-slot-student`,
      label: "Book my Slot",
    },
    {
      label: "Bookings",
      link: `/dashboard/book-my-slot-student`,
    },
    {
      link: `/dashboard/book-my-slot-student/book-slot`,
      label: "Book Slot",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loadingQueues: 0,
      doubtComment: "",
      valueDate: "",
      slots: [],
      slotsArrayCurrentData: [],
      subject: null,
      meta: {
        all_booked_slots: 0,
        all_slots: 0,
      },
      chapter: null,
      chapterId: "",
      subjectId: "",
      slotId: "",
      isSlotUpdatedModal: false,
      isErrorModal: false,
      topics: [],
      userInputTopic: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      apiRequestCallId === this.getSlotsAvailableStudentMessageId &&
      response
    ) {
      if (response?.data) {
        this.handleData(response.data);
      }
    }

    if (
      apiRequestCallId === this.getBookedSlotsForBookSlotsMessageId &&
      response
    ) {
      if (response?.meta) {
        this.handleAvailableBookedSlots(response.meta);
      }
    }

    if (
      apiRequestCallId === this.getStudentSubjectsForBookSlotsMessageId &&
      response
    ) {
      if (response?.data) {
        this.handleDataSubjects(response.data);
      }
    }

    if (
      apiRequestCallId === this.getChaptersOnSubjectBookSlotMessageId &&
      response
    ) {
      if (response?.data) {
        this.handleDataChapters(response.data);
      }
    }

    if (apiRequestCallId === this.bookStudentSlotMessageId && response) {
      if (response?.data) {
        this.successPostMessage();
      }

      if (response?.errors) {
        this.showErrorModal();
      }
    }

    if (apiRequestCallId === this.getDataTopicsMessageId && response) {
      if (response?.data) {
        this.handleTopicData(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const search = this.props.navigation.history.location.search;
    const params = new URLSearchParams(search);

    const propPassingStudentBookSlot = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingStudentBookSlot.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingStudentBookSlot);

    if (search) {
      const subjectId = params.get("subjectId") as string;
      const chapterId = params.get("chapterId") as string;

      return this.setSubjectAndChapter(subjectId, chapterId);
    }

    this.props.navigation.history.goBack();
  };

  // Customizable Area Start
  handleTopicData = (topics: Topic[]) => {
    const topicSelections: { value: Topic; label: string }[] = [];

    topics.forEach((topic) => {
      const metaData = { value: topic, label: topic.attributes.name };

      topicSelections.push(metaData);
    });

    this.setState({
      topics: topicSelections,
    });
  };

  showErrorModal = () => {
    this.setState({
      loadingQueues: this.state.loadingQueues - 1,
      isErrorModal: !this.state.isErrorModal,
    });
  };
  successPostMessage = () => {
    this.handleIsSlotUpdatedModal();
  };
  handleIsSlotUpdatedModal = () => {
    this.setState({
      loadingQueues: this.state.loadingQueues - 1,
      isSlotUpdatedModal: !this.state.isSlotUpdatedModal,
    });
  };
  setSubjectAndChapter = (subjectId: string, chapterId: string) => {
    this.setState(
      {
        subjectId,
        chapterId,
      },
      () => {
        this.getStudentSubjectsForBookSlots();
        this.getBookedSlotsForBookSlots();
        this.getChaptersOnSubjectBookSlot(subjectId);
      }
    );
  };

  handleDataSubjects = (response: Subject[]) => {
    const subject =
      response.filter(
        (subjectCurrent) => subjectCurrent.id === this.state.subjectId
      )[0] || null;

    this.setState(
      {
        loadingQueues: this.state.loadingQueues - 1,
        subject,
      },
      () => {
        this.getSlotsAvailableStudent();
      }
    );
  };

  handleAvailableBookedSlots = (meta: {
    all_slots: number;
    all_booked_slots: number;
  }) => {
    this.setState({
      meta,
      loadingQueues: this.state.loadingQueues - 1,
    });
  };

  setDoubtComments = (value: string) => {
    this.setState({
      doubtComment: value,
    });
  };

  handleData = (slots: Slot[]) => {
    this.setState(
      {
        slots,
        loadingQueues: this.state.loadingQueues - 1,
      },
      () => {
        this.handleCurrentDataForCurrentSlotsStudent();
      }
    );
  };
  handleCurrentDataForCurrentSlotsStudent = () => {
    const slots = this.state.slots;

    const slotsArrayCurrentData = slots.filter(
      (slot) => slot.attributes.slot_date === this.state.valueDate
    );

    this.setState({
      slotsArrayCurrentData,
    });
  };

  changeDateChange = (value: string) => {
    const valueDate = this.formatDate(value);

    this.setState(
      {
        valueDate,
      },
      () => {
        this.handleCurrentDataForCurrentSlotsStudent();
      }
    );
  };

  formatDate = (data: string) => {
    const dateFromData = new Date(data);
    const year = dateFromData.getFullYear();
    const month = String(dateFromData.getMonth() + 1).padStart(2, "0");
    const day = String(dateFromData.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  getSlotsAvailableStudent = async () => {
    this.setState({
      loadingQueues: this.state.loadingQueues + 1,
    });
    const getSlotsAvailableStudentMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getSlotsAvailableStudentMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.slotsGetAll}?subject=${this.state.subject?.attributes.name}`
    );
    this.getSlotsAvailableStudentMessageId =
      getSlotsAvailableStudentMessage.messageId;
    getSlotsAvailableStudentMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSlotsAvailableStudentMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getSlotsAvailableStudentMessage.id,
      getSlotsAvailableStudentMessage
    );
  };

  getBookedSlotsForBookSlots = async () => {
    const getBookedSlotsForBookSlotsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loadingQueues: this.state.loadingQueues + 1,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getBookedSlotsForBookSlotsMessageId =
      getBookedSlotsForBookSlotsMessage.messageId;

    getBookedSlotsForBookSlotsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/bms_bookeds`
    );

    getBookedSlotsForBookSlotsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getBookedSlotsForBookSlotsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getBookedSlotsForBookSlotsMessage.id,
      getBookedSlotsForBookSlotsMessage
    );
  };

  getStudentSubjectsForBookSlots = async () => {
    const getStudentSubjectsForBookSlotsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loadingQueues: this.state.loadingQueues + 1,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getStudentSubjectsForBookSlotsMessageId =
      getStudentSubjectsForBookSlotsMessage.messageId;

    getStudentSubjectsForBookSlotsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bookingSubjectsStudent
    );

    getStudentSubjectsForBookSlotsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getStudentSubjectsForBookSlotsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getStudentSubjectsForBookSlotsMessage.id,
      getStudentSubjectsForBookSlotsMessage
    );
  };

  getChaptersOnSubjectBookSlot = async (subjectId: string) => {
    const getChaptersOnSubjectBookSlotMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loadingQueues: this.state.loadingQueues + 1,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getChaptersOnSubjectBookSlotMessageId =
      getChaptersOnSubjectBookSlotMessage.messageId;

    getChaptersOnSubjectBookSlotMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/bms_bookeds/${subjectId}/chapters`
    );

    getChaptersOnSubjectBookSlotMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getChaptersOnSubjectBookSlotMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getChaptersOnSubjectBookSlotMessage.id,
      getChaptersOnSubjectBookSlotMessage
    );
  };

  handleDataChapters = (response: Chapter[]) => {
    const chapter =
      response.filter(
        (chapterCurrent) => chapterCurrent.id === this.state.chapterId
      )[0] || null;

    this.setState(
      {
        loadingQueues: this.state.loadingQueues - 1,
        chapter,
      },
      () => {
        this.getDataTopics();
      }
    );
  };

  selectTimeDate = (slotId: string) => {
    this.setState({
      slotId,
    });
  };

  bookSlot = async () => {
    const bookStudentSlotMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loadingQueues: this.state.loadingQueues + 1,
    });

    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const body = {
      data: {
        bms_slot_id: this.state.slotId,
        subject_id: this.state.subjectId,
        chapter_id: this.state.chapterId,
        topic_id: this.state.userInputTopic?.id,
        bms_query: this.state.doubtComment,
      },
    };

    this.bookStudentSlotMessageId = bookStudentSlotMessage.messageId;

    bookStudentSlotMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/bms_bookeds`
    );

    bookStudentSlotMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    bookStudentSlotMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    bookStudentSlotMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(bookStudentSlotMessage.id, bookStudentSlotMessage);
  };

  getDataTopics = async () => {
    const getDataTopicsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loadingQueues: this.state.loadingQueues + 1,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getDataTopicsMessageId = getDataTopicsMessage.messageId;

    getDataTopicsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/bms_bookeds/${this.state.chapterId}/topics`
    );

    getDataTopicsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getDataTopicsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getDataTopicsMessage.id, getDataTopicsMessage);
  };

  handleToBookSlot = () => {
    this.props.navigation.navigate("BookMySlotStudentAppointments");
  };

  handleUserInputChange = (
    event: ValueType<
      {
        value: Topic;
        label: string;
      },
      false
    >
  ) => {
    const label = event?.label;
    const value = event?.value;

    if (label && value) {
      this.setState({
        userInputTopic: value,
      });
    }
  };
  // Customizable Area End
}
