import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { pcIcon, documentIcon, noteIcon, bookIcon } from "./assets";
import * as Yup from "yup";
import storage from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isHome: boolean;
  modalSubmitted: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardGuestHomeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  navigationData = [
    { name: "Synopsis", image: noteIcon, link: "/guest/synopsis" },
    { name: "Question Bank", image: bookIcon, link: "/guest/question-bank" },
    { name: "Practice Test", image: documentIcon, link: "/guest/practice-test" },
    { name: "Grand Test", image: documentIcon, link: "/guest/grand-test" },
    { name: "Live Classes", image: pcIcon, link: "/guest/live-classes" },
    { name: "Weekly Test", image: documentIcon, link: "/guest/weekly-test" },
    { name: "Swayam Prabha", image: pcIcon, link: "/guest/swayam-prabha" },
    { name: "Notifications", image: pcIcon, link: "/guest/notifications" },
    { name: "Contact US", image: pcIcon, link: "/contact-us" },
    { name: "Recording", image: pcIcon, link: "/guest/live-classes?isRecorded=true" },
  ];

  bookMySlotSchema = Yup.object({
    nameInput: Yup.string().required(),
    mobileInput: Yup.string().required(),
    emailInput: Yup.string().email().required(),
    messageInput: Yup.string().required(),
  });

  postSubmitId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isHome: true,
      modalSubmitted: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.postSubmitId && response) {
      this.handleSubmit();
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const packageId = await storage.get("guestPackageId");
    const guestToken = await storage.get("guestToken");
    if (!packageId || !guestToken) {
      this.props.navigation.navigate("GuestPackages");
    }
  };

  // Customizable Area Start
  handleNavigationTo = (linkTo: string) => {
    this.props.navigation.history.push(linkTo);
  };

  
  handleToBookMySlot = () => {
    this.setState({
      isHome: false,
    });
  };
  
  handleSubmit = () => {
    this.setState({
      modalSubmitted: !this.state.modalSubmitted,
    });
  };

  handleToHome = () => {
    this.setState({
      isHome: true,
    });
  };

  handleBookMySlotSubmit = async (values: {
    nameInput: string;
    messageInput: string;
    emailInput: string;
    mobileInput: string;
  }) => {
    const postSubmit = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const headers = {
      uuid: await storage.get("guestToken"),
      "Content-Type": "application/json",
    };

    const body = {
      data: {
        name: values.nameInput,
        phone_number: values.mobileInput,
        email: values.emailInput,
        message: values.messageInput,
      },
    };

    this.postSubmitId = postSubmit.messageId;

    postSubmit.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    postSubmit.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.guestBookMySlotAPI
    );

    postSubmit.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    postSubmit.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(postSubmit.id, postSubmit);
  };
  // Customizable Area End
}
