import React from "react";

import {
  Box as BoxMUI,
  // Customizable Area Start
  withStyles,
  Typography,
  Button as ButtonMUI,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { webStyleGuestPracticeTestChapters } from "./GuestPracticeTestChapters.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import GuestTestsGrandTestController, {
  Props,
} from "./GuestTestsGrandTestController.web";

class GuestTestsGrandTest extends GuestTestsGrandTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <BoxMUI className={this.props.classes.chapterContainerStyle}>
        <Loader loading={this.state.loading} />
        {this.state.tests.map((test) => {
          const packageId = this.props.navigation.getParam("packageId");
          return (
            <BoxMUI className={this.props.classes.cardStyle}>
              <BoxMUI
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  src={test.attributes.image_url}
                  alt={`chapter-${test.id}`}
                  className={this.props.classes.imageStyle}
                />
              </BoxMUI>
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {test.attributes.test_name}
              </Typography>
              <BoxMUI className={this.props.classes.buttonStyles}>
                <ButtonMUI
                  style={webStyleButton}
                  onClick={() =>
                    this.navigateTo(
                      `/guest/grand-test/${packageId}/test/${test.id}`
                    )
                  }
                  data-test-id="take-test"
                >
                  Take Test
                </ButtonMUI>
                <ButtonMUI className={this.props.classes.buttonStyle}>
                  Review Test
                  <BoxMUI
                    className={this.props.classes.goBackStep}
                    data-test-id="gobackbtn"
                  >{`>`}</BoxMUI>
                </ButtonMUI>
              </BoxMUI>
            </BoxMUI>
          );
        })}
      </BoxMUI>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyleButton = {
  background:
    "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
  color: "white",
  height: "40px",
  width: "121px",
  title: "Take Test",
};
export default withStyles(webStyleGuestPracticeTestChapters)(
  GuestTestsGrandTest
);
export { GuestTestsGrandTest };
// Customizable Area End
