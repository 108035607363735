import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { ISlotDetails } from "./BookMySlotChangeSlotController.web";
import storage from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  timeSlot: string;
  dateSlot: string;
  slotDetails: ISlotDetails | null;
  loading: boolean;
  selectedChoice: string;
  comments: string;
  slotId: string;
  isSlotUpdatedModal: boolean;
  cancelSlot: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotUpdateSlotController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSlotByIdMessageId = "";
  putSubmitChangeOrCancelMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      slotDetails: null,
      timeSlot: "",
      dateSlot: "",
      loading: false,
      selectedChoice: "change",
      comments: "",
      slotId: "",
      isSlotUpdatedModal: false,
      cancelSlot: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSlotByIdMessageId) {
      if (response?.data) {
        this.successFetch(response.data);
      }
    }

    if (apiRequestCallId === this.putSubmitChangeOrCancelMessageId) {
      if (response?.data) {
        this.successPostMessage();
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const search = this.props.navigation.history.location.search;
    const params = new URLSearchParams(search);
    if (search) {
      const slotId = params.get("slotId") as string;
      const timeSlot = params.get("timeSlot") as string;
      const dateSlot = params.get("dateSlot") as string;
      const cancelSlot = Boolean(params.get("cancelSlot"));

      this.getSlotById(slotId);
      this.successSearch(timeSlot, dateSlot, cancelSlot);
    }
  };

  successPostMessage = () => {
    this.handleIsSlotUpdatedModal();
  };

  successFetch = (slotDetails: ISlotDetails) => {
    this.setState({
      slotDetails,
      loading: false,
    });
  };

  setComments = (comments: string) => {
    this.setState({
      comments,
    });
  };

  successSearch = (timeSlot: string, dateSlot: string, cancelSlot: boolean) => {
    if (cancelSlot) {
      this.setState({
        selectedChoice: "cancel",
      });
    }

    this.setState({
      timeSlot,
      dateSlot,
      cancelSlot,
    });
  };

  // Customizable Area Start
  getSlotById = async (slotId: string) => {
    const getSlotByIdMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (!slotId) {
      this.props.navigation.history.goBack();
    }
    this.setState({
      loading: true,
      slotId,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getSlotByIdMessageId = getSlotByIdMessage.messageId;

    getSlotByIdMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/cms_appoinments/${slotId}`
    );

    getSlotByIdMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSlotByIdMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSlotByIdMessage.id, getSlotByIdMessage);
  };

  postSubmitChangeOrCancel = async () => {
    const putSubmitChangeOrCancelMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const body = {
      data: {
        slot_type: this.state.cancelSlot ? "cancel_slot" : "change_slot",
        slot_date: this.state.cancelSlot
          ? this.state.slotDetails?.attributes.slot_date
          : this.state.dateSlot,
        time_slot: this.state.cancelSlot
          ? this.state.slotDetails?.attributes.time_slot
          : this.state.timeSlot,
        comment: this.state.comments
      },
    };

    this.putSubmitChangeOrCancelMessageId =
      putSubmitChangeOrCancelMessage.messageId;

    putSubmitChangeOrCancelMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/cms_appoinments/${this.state.slotId}`
    );

    putSubmitChangeOrCancelMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    putSubmitChangeOrCancelMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    putSubmitChangeOrCancelMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(
      putSubmitChangeOrCancelMessage.id,
      putSubmitChangeOrCancelMessage
    );
  };

  handleIsSlotUpdatedModal = () => {
    this.setState({
      isSlotUpdatedModal: !this.state.isSlotUpdatedModal,
      loading: false,
    });
  };

  navigateToSlotsScreen = () => {
    this.props.navigation.navigate("BookMySlotBookings");
  };
  // Customizable Area End
}
