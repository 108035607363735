import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

type ApiData = {
  data: TopicQuestion[];
  meta: Test;
};

interface Option {
  id: string;
  option: string;
}

export interface TestSubject {
  id: number;
  status: string;
  grand_test_id: number;
  created_at: string;
  gt_code: string;
  subject_name: string;
  subject_questions: number;
  updated_at: string;
  order_preference: number;
  created_by: string;
  remarks: string;
}

interface GrandTestMeta {
  id: number;
  calc_page: string;
  created_at: string;
  updated_at: string;
  test_no: number;
  test_name: string;
  avail_test: boolean;
  gt_code: string;
  created_by: string;
  avail_analysis: string;
  limit_test: string;
  test_page: string;
  analysis_page: string;
}

interface Test {
  grand_test: GrandTestMeta;
  question_ids: number[];
  test_time: number;
}

export interface TopicQuestion {
  id: string;
  type: string;
  attributes: {
    n_mark: string;
    question_db: string;
    id: number;
    grand_test_id: number;
    p_mark: string;
    question_type: string;
    sub_name: string;
    question_no: number;
    question: string;
    options: Option[] | [];
    heading: string;
    is_marked: boolean;
  };
}

interface Answers {
  question_id: number;
  option: string[][];
  legend?: string;
  question_db: string;
  is_marked?: boolean;
}
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  timeRemaining: number;
  endTimeGrandTest: number;
  currentTestData: number;
  testData: TopicQuestion[];
  answerGrandTest: Answers[][];
  currentAnswers: Answers[];
  currentSubjectTestData: TopicQuestion[];
  currentSubjectTestDataIndex: number;
  meta: Test | null;
  loading: boolean;
  errorModalOpen: boolean;
  testSubjects: TestSubject[];
  showModalSubmit: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GrandTestTakingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTestMessageId = "";
  intervalId: NodeJS.Timeout = setTimeout(() => {}, 0);
  submitTestMessageId = "";
  testId = "";
  getTestSubjectMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.tick = this.tick.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      endTimeGrandTest: 0,
      loading: false,
      timeRemaining: 0,
      testData: [],
      currentTestData: 0,
      answerGrandTest: [[]],
      meta: null,
      testSubjects: [],
      currentSubjectTestData: [],
      currentSubjectTestDataIndex: 0,
      errorModalOpen: false,
      currentAnswers: [],
      showModalSubmit: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getTestMessageId && response) {
      this.handleTestDataResponse(response);
    }

    if (apiRequestCallId === this.getTestSubjectMessageId && response) {
      this.handleTestSubjectResponse(response);
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const testId = this.props.navigation.getParam("testId");
    this.testId = testId;

    this.getTest(testId);
  };

  componentWillUnmount = async () => {
    clearInterval(this.intervalId);
  };

  showModalSubmit = () => {
    this.setState({
      showModalSubmit: !this.state.showModalSubmit,
    });
  };

  navigateToRegister = () => {
    this.props.navigation.history.push("/register");
  };

  handleTestDataResponse(response: ApiData) {
    if (response.meta) {
      this.handleTestData(response.data, response.meta);
      const endTimeGrandTest = this.calculateEndTime(response.meta.test_time);
      const timeRemaining = Math.max(endTimeGrandTest - Date.now(), 0);
      this.handleTimerStart(endTimeGrandTest, timeRemaining);
    } else {
      this.setState({ errorModalOpen: true, loading: false });
    }
  }

  handleTestSubjectResponse(response: { data: TestSubject[] }) {
    if (response.data) {
      this.handleTestSubjects(response.data);
    }
  }

  calculateEndTime(testTime: number) {
    return Date.now() + testTime * 1000;
  }

  handleTestSubjects = (response: TestSubject[]) => {
    const currentTestSubjectData = this.state.testData.filter(
      (testSubject) =>
        testSubject.attributes.sub_name === response[0].subject_name
    );

    this.setState({
      testSubjects: response,
      currentSubjectTestData: currentTestSubjectData,
      answerGrandTest: Array.from({ length: response.length }, () => []),
      loading: false,
    });
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  tick = () => {
    const newTimeRemaining = Math.max(
      this.state.endTimeGrandTest - Date.now(),
      0
    );
    this.setState({ timeRemaining: newTimeRemaining });

    if (newTimeRemaining <= 0) {
      clearInterval(this.intervalId);
      this.timeReachedZero();
    }
  };

  timeReachedZero = () => {
    clearInterval(this.intervalId);
    this.showModalSubmit();
  };

  handleTimerStart = (endTimeGrandTest: number, timeRemaining: number) => {
    this.setState(
      {
        timeRemaining,
        endTimeGrandTest,
      },
      () => {
        this.intervalId = setInterval(this.tick, 1000);
      }
    );
  };

  handleTestData = (response: TopicQuestion[], meta: Test) => {
    if (response.length > 0) {
      this.setState(
        {
          testData: response,
          currentTestData: 0,
          meta,
          answerGrandTest: [],
          currentAnswers: [],
        },
        () => {
          this.getTestSubject(this.props.navigation.getParam("testId"));
        }
      );
    } else {
      this.setState({
        errorModalOpen: true,
      });
    }
  };

  handleSubjectChange = (subject: string, index: number) => {
    const currentTestData = this.state.testData.filter(
      (subjectData) => subjectData.attributes.sub_name === subject
    );
    const currentAnswers = [...this.state.currentAnswers];
    const answersGrandTest = [...this.state.answerGrandTest];

    answersGrandTest[this.state.currentSubjectTestDataIndex] = currentAnswers;

    if (currentTestData.length !== 0) {
      this.setState({
        currentSubjectTestDataIndex: index,
        currentSubjectTestData: currentTestData,
        answerGrandTest: answersGrandTest,
        currentTestData: 0,
        currentAnswers: answersGrandTest[index],
      });
    }
  };

  handleSpecificQuestion = (index: number) => {
    this.setState({
      currentTestData: index,
    });
  };

  handleNextQuestion = () => {
    if (
      this.state.currentTestData !==
      this.state.currentSubjectTestData.length - 1
    ) {
      this.setState({
        currentTestData: this.state.currentTestData + 1,
      });
    }
  };

  handlePreviousQuestion = () => {
    if (this.state.currentTestData !== 0) {
      this.setState({
        currentTestData: this.state.currentTestData - 1,
      });
    }
  };

  setAnswer = (index: number, option: string[][]) => {
    const answers = [...this.state.currentAnswers];

    answers[index] = {
      ...this.state.currentAnswers[index],
      question_id: this.state.testData[index].attributes.id,
      option,
    };

    this.setState({ currentAnswers: answers });
  };

  componentDidUpdate(_: Props, prevState: S) {
    if (
      JSON.stringify(prevState.currentAnswers) !==
        JSON.stringify(this.state.currentAnswers) ||
      (JSON.stringify(prevState.currentTestData) !==
        JSON.stringify(this.state.currentTestData) &&
        prevState.currentSubjectTestDataIndex ===
          this.state.currentSubjectTestDataIndex)
    ) {
      this.handleAnswersChanged(prevState);
    }

    if (
      prevState.currentTestData !== this.state.currentTestData &&
      prevState.currentSubjectTestDataIndex ===
        this.state.currentSubjectTestDataIndex
    ) {
      this.submitOverviewPerQuestion(prevState.currentTestData);
    }
  }

  handleAnswersChanged = (prevState: S) => {
    let currentAnswers = this.state.currentAnswers[this.state.currentTestData];

    const isAnswered = currentAnswers?.option?.[0]?.[0]?.length > 0;
    const isMarked = currentAnswers?.is_marked;

    if (isMarked) {
      currentAnswers = { ...currentAnswers, is_marked: true };
    } else {
      currentAnswers = { ...currentAnswers, is_marked: false };
    }

    if (isAnswered) {
      currentAnswers = { ...currentAnswers, legend: "answered" };
    }

    if (isAnswered && isMarked) {
      currentAnswers = {
        ...currentAnswers,
        legend: "answered_and_marked_for_review",
      };
    }

    if (!isAnswered && isMarked) {
      currentAnswers = {
        ...currentAnswers,
        legend: "not_answered_and_marked_for_review",
      };
    }

    if (
      prevState.currentAnswers[this.state.currentTestData]?.option?.[0]?.[0]
        ?.length > 0 &&
      !currentAnswers?.option?.[0]?.[0]?.length &&
      prevState.currentSubjectTestDataIndex ===
        this.state.currentSubjectTestDataIndex
    ) {
      currentAnswers = {
        ...currentAnswers,
        legend: "answered_and_cleared_the_answer",
      };
    }

    if (
      prevState.currentAnswers[this.state.currentTestData]?.is_marked &&
      !currentAnswers?.is_marked &&
      prevState.currentSubjectTestDataIndex ===
        this.state.currentSubjectTestDataIndex
    ) {
      currentAnswers = {
        ...currentAnswers,
        legend: "marked_and_un_marked_for_review",
      };
    }

    const answers = this.state.currentAnswers;
    answers[this.state.currentTestData] = currentAnswers;

    this.setState({
      currentAnswers: answers,
    });
  };

  markForReview = () => {
    const newAnswer = [...this.state.currentAnswers];

    newAnswer[this.state.currentTestData] = {
      ...newAnswer[this.state.currentTestData],
      is_marked: !newAnswer[this.state.currentTestData].is_marked,
    };

    this.setState({
      currentAnswers: newAnswer,
    });
  };

  clearResponse = () => {
    const answers = [...this.state.currentAnswers];

    answers[this.state.currentTestData] = {
      ...answers[this.state.currentTestData],
      option: [[]],
    };

    this.setState({
      currentAnswers: answers,
    });
  };

  getBtnClassName = (index: number) => {
    const current = this.state.currentAnswers[index];

    if (current?.legend === "answered") {
      return "btn-answered";
    }

    if (current?.legend === "un_answered") {
      return "btn-unanswered";
    }

    if (current?.legend === "answered_and_marked_for_review") {
      return "btn-answered-marked";
    }

    if (current?.legend === "not_answered_and_marked_for_review") {
      return "btn-unanswered-marked";
    }

    if (current?.legend === "marked_and_un_marked_for_review") {
      return "btn-marked-unmarked";
    }

    if (current?.legend === "answered_and_cleared_the_answer") {
      return "btn-answered-cleared";
    }

    return "";
  };

  // Customizable Area Start
  getTest = async (gtId: string) => {
    const headers = {
      uuid: await storage.get("guestToken"),
    };

    this.setState({
      loading: true,
    });

    const getTestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTestMessageId = getTestMessage.messageId;

    getTestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/guest_grand_tests/${gtId}/take_test`
    );

    getTestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getTestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getTestMessage.id, getTestMessage);
  };

  secondsToHHMMSS = (totalSeconds: number): string => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const hh = hours.toString().padStart(2, "0");
    const mm = minutes.toString().padStart(2, "0");
    const ss = seconds.toString().padStart(2, "0");

    return `${hh}:${mm}:${ss}`;
  };

  submitTest = async () => {
    clearInterval(this.intervalId);
    this.showModalSubmit();
  };

  submitOverviewPerQuestion = (prevIndex: number) => {
    let currentAnswers = this.state.currentAnswers[prevIndex];
    const isAnswered = currentAnswers?.option?.[0]?.[0]?.length > 0;
    const legend = currentAnswers?.legend;

    if (!isAnswered && legend === undefined) {
      currentAnswers = { ...currentAnswers, legend: "un_answered" };
    }

    const answers = this.state.currentAnswers;
    answers[prevIndex] = currentAnswers;

    this.setState({
      currentAnswers: answers,
    });
  };

  getTestSubject = async (gtId: string) => {
    const headers = {
      uuid: await storage.get("guestToken"),
    };

    const getTestSubjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTestSubjectMessageId = getTestSubjectMessage.messageId;

    getTestSubjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/guest_grand_tests/${gtId}/gt_subjects`
    );

    getTestSubjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getTestSubjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getTestSubjectMessage.id, getTestSubjectMessage);
  };
  // Customizable Area End
}
