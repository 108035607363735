import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

import UsageStatsSynopsisController, {
  configJSON,
  Props,
} from "./UsageStatsSynopsisController.web";

class UsageStatsSynopsis extends UsageStatsSynopsisController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ProgressBar = ({
    covered_percentage,
    pending_percentage,
  }: {
    covered_percentage: number;
    pending_percentage: number;
  }) => {
    return (
      <Box className={this.props.classes.progressBar}>
        <Box
          className={this.props.classes.covered}
          style={{ width: `${covered_percentage}%` }}
        >
          {covered_percentage}% covered
        </Box>
        <Box
          className={this.props.classes.notCovered}
          style={{ width: `${pending_percentage}%` }}
        >
          {pending_percentage}% not covered
        </Box>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box marginTop="2em" padding="2em">
          <Box marginBottom={"3em"}>
            <Typography className={this.props.classes.typographyStyle}>
              {configJSON.selectSujects}
            </Typography>
            <Box position={"relative"}>
              <select
                onChange={(event) => this.onChangeSelect(event.target.value)}
                value={this.state.selectedChoice}
                data-test-id="select-btn"
                className={this.props.classes.selectStyle}
              >
                <option value="none"> </option>
                {this.state.subjects.map((subject) => {
                  return (
                    <option value={subject.attributes.name} key={subject.id}>
                      {subject.attributes.name}
                    </option>
                  );
                })}
              </select>
              <ExpandMoreIcon className={this.props.classes.expandMoreIcon} />
            </Box>
          </Box>
          {this.state.stats && (
            <Box>
              <Box>
                <Typography className={this.props.classes.typoMain}>
                  Subject {this.state.stats?.subject_overview[0].subject}{" "}
                  overall
                </Typography>
                <Box marginTop={"2em"}>
                  {this.ProgressBar({
                    covered_percentage:
                      this.state.stats.subject_overview[0]
                        .subject_covered_percentage,
                    pending_percentage:
                      this.state.stats.subject_overview[0]
                        .subject_pending_percentage,
                  })}
                </Box>
              </Box>
              <Box marginTop={"2em"}>
                <Typography className={this.props.classes.typoMain}>
                  Chapters covered in{" "}
                  {this.state.stats?.subject_overview[0].subject}
                </Typography>
                {this.state.stats.chapter_overview.map((chapter) => {
                  return (
                    <Box marginTop={"2em"}>
                      <Typography className={this.props.classes.typoSub}>
                        {chapter.chapter}
                      </Typography>
                      <Box marginTop="1em">
                        {this.ProgressBar({
                          covered_percentage:
                            chapter.chapter_covered_percentage,
                          pending_percentage:
                            chapter.chapter_pending_percentage,
                        })}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleUsageStatsSynopsis = () =>
  createStyles({
    selectStyle: {
      width: "100%",
      fontSize: "1.4em",
      outline: 0,
      fontWeight: 500,
      paddingLeft: "1.5em",
      WebkitAppearance: "none",
      border: "1px solid #808080",
      height: "70px",
      color: "#5C0F89",
      marginTop: "1.3em",
    },
    expandMoreIcon: {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      color: "white",
      borderRadius: "50%",
      top: "47px",
      right: "30px",
      position: "absolute",
      cursor: "pointer",
    },
    typographyStyle: {
      fontWeight: 500,
      fontSize: "1.3em",
    },
    subjectTitle: {
      color: "#ff3366",
      fontSize: "1.2em",
    },
    progressBar: {
      display: "flex",
      width: "100%",
      height: "60px",
      borderRadius: "5px",
      overflow: "hidden",
      fontWeight: 500,
      fontSize: "1.3em",
    },
    covered: {
      backgroundColor: "#7500BE",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    notCovered: {
      backgroundColor: "lightgray",
      color: "black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "right",
    },
    typoMain: {
      color: "#FF5C5C",
      fontWeight: 700,
      fontSize: "1.5em",
    },
    typoSub: {
      fontWeight: 500,
      fontSize: "1.2em",
    },
  });
export default withStyles(webStyleUsageStatsSynopsis)(UsageStatsSynopsis);
export { UsageStatsSynopsis };
// Customizable Area End
