import { Route } from "react-router-dom";
import React, { ReactElement, Fragment } from "react";
import PropTypes from "prop-types";

interface NavigationProps {
  navigate: (to: string, params: Record<string, string>) => void;
  getParam: (param: string, alternative: string) => string;
  goBack: () => void;
  history: any;
}

interface WrapperProps {
  element: ReactElement;
  history: any;
  match: any;
  routeMap: any;
  closeModal: () => void;
}

function Wrapper({
  element,
  history,
  match,
  routeMap,
  closeModal,
}: WrapperProps): ReactElement {
  const navigate = (to: string, params: Record<string, string>): void => {
    let url = routeMap[to].path;
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach((param) => {
        const re = new RegExp(`:${param}\\??`);
        url = url.replace(re, escape(params[param]));
      });
    }
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    history.push(url);
  };

  const getParam = (param: string, alternative: string): string => {
    return match.params[param] || alternative;
  };

  const goBack = (): void => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack, history },
    closeModal,
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object,
};

const WebRoutesGenerator = ({
  routeMap,
}: {
  routeMap: any;
}): any => {
  return (
    <Fragment>
      {Object.keys(routeMap).map((route) => {
        const currentRoute = routeMap[route];
        const Component = currentRoute.component;
        return (
          <Route
            key={currentRoute.path}
            path={currentRoute.path}
            exact={currentRoute.exact}
            render={(props: any) => (
              <Wrapper element={<Component />} {...props} routeMap={routeMap} />
            )}
          />
        );
      })}
    </Fragment>
  );
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object,
};

export default WebRoutesGenerator;
