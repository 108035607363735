import React from "react";

import {
  Box,
  // Customizable Area Start
  Button,
  Modal,
  Typography,
  withStyles,
  createStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { NavLink } from "react-router-dom";
import AccordionComponent from "../../../components/src/AccordionComponent.web";
export const styles = () => {
  return createStyles({
    reportTextfield: {
      width: "100%",
    },
    buttonStyleNo: {
      border: "1px solid #BE888E",
      borderRadius: "0.6em",
      textTransform: "none" as const,
      "&:hover": {
        background: "#FF5D5B",
        color: "white",
      },
      width: "200px",
      height: "50px",
      color: "#4F1f6d",
      background: "white",
    },
    textAreaStyle: {
      width: "100%",
      border: "1px solid #BE888E",
      borderRadius: "0.7em",
      height: "300px",
      padding: "1em",
      margin: "1em 0 2em 0",
      resize: "none",
      "&:focus": {
        outline: "none",
      },
    },
    activeLink: {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      color: "white",
    },
    chapterContainer: {
      "& button": {
        textTransform: "none",
      },
    },
    buttonReport: {
      border: "none",
      borderRadius: "50%",
      background: "rgb(254,228,227)",
      width: "10px",
      height: "60px",
      cursor: "pointer",
      marginTop: "3em",
    },
  });
};
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import QuestionBankMainController, {
  Props,
} from "./QuestionBankMainController.web";

class QuestionBankMain extends QuestionBankMainController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const subjectId = this.props.navigation.getParam("subjectId");
    const chapterId = this.props.navigation.getParam("chapterId");
    return (
      // Customizable Area Start
      <Box className={this.props.classes.chapterContainer}>
        <Loader loading={this.state.loading} />
        <Modal
          open={this.state.reportSuccessModal}
          onClose={this.handleReportSuccessModal}
        >
          <Box sx={webStyle.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>Report Submitted</h3>
          </Box>
        </Modal>
        <Modal
          open={this.state.openBookmarkSuccessModal}
          onClose={this.handleBookmarkSuccessModal}
        >
          <Box sx={webStyle.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>
              You have Bookmarked Question no -{" "}
              {this.state.bookmarkedQuestionNumber} successfully!
            </h3>
            <div>
              <NavLink
                to={`/dashboard/question-bank/subject/${subjectId}/chapter/${chapterId}/bookmark`}
              >
                <Button style={webStyle.buttonStyleNo}>
                  Go to Saved bookmarks
                </Button>
              </NavLink>
            </div>
          </Box>
        </Modal>
        <Modal
          open={this.state.reportModalOpen}
          onClose={this.handleReportModal}
        >
          <Box sx={webStyle.modalStyle} className="submit-test-done">
            <Box style={{ width: "100%", marginTop: "3em" }}>
              <Typography variant="h6">Do you want to report?</Typography>
              <form
                data-test-id="report-field-submit"
                onSubmit={(event) => this.sendReport(event)}
              >
                <textarea
                  className={this.props.classes.textAreaStyle}
                  data-test-id="report-field"
                  placeholder="Enter your message"
                  value={this.state.reportInput}
                  onChange={(event) =>
                    this.handleInputChange(event.target.value)
                  }
                />
                <Box sx={webStyle.centerButtonStyle}>
                  <Button
                    className={this.props.classes.buttonStyleNo}
                    onClick={this.handleReportModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={this.props.classes.buttonStyleNo}
                    type="submit"
                  >
                    Send
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Modal>
        <Box sx={webStyle.bannerStyle}>
          <h2>{this.state.chapterName}</h2>
        </Box>
        <Box style={{ margin: "0 2em" }}>
          <Box sx={webStyle.buttonNavigation}>
            {this.state.questionTypes.map((questionType, index) => {
              return (
                <Button
                  data-test-id="add-exr-testid"
                  className={
                    this.state.activeButton === index
                      ? this.props.classes.activeLink
                      : ""
                  }
                  onClick={() =>
                    this.handleListClick(questionType.question_type, index)
                  }
                >
                  {questionType.question_type}
                </Button>
              );
            })}
          </Box>
          <Box sx={webStyle.listContainerStyle}>
            {this.state.list.length > 0 ? (
              this.state.list.map((list) => {
                return (
                  <Box key={list.attributes.id}>
                    <AccordionComponent
                      {...list}
                      chapterName={this.state.chapterName}
                      createBookmark={(bookmarkId, questionNumber) =>
                        this.bookmarkSave(bookmarkId, questionNumber)
                      }
                      subjectId={subjectId}
                      chapterId={chapterId}
                      withNotesBookmark={true}
                    />
                  </Box>
                );
              })
            ) : (
              <p style={{ fontWeight: 500 }}>No data found in this section</p>
            )}
          </Box>
          {this.state.list.length > 0 && (
            <Box sx={webStyle.reportStyle}>
              <Button
                data-test-id="report-testid"
                onClick={this.handleReportModal}
              >
                <ReportProblemIcon style={{ color: "red" }} />
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
  reportStyle: {
    marginTop: "2em",
    display: "flex",
    justifyContent: "flex-end",
    "& button": {
      width: "10px",
      height: "60px",
      border: "none",
      borderRadius: "50%",
      background: "#FCE5E0",
      scale: "0.6",
    },
  },
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "max-content",
    width: "35%",
    bgcolor: "background.paper",
    border: "2px solid #BE888E",
    borderRadius: "1.5em",
    padding: "2em",
    gap: "1.5em",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  bannerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    color: "rgb(82, 0, 130)",
  },
  buttonStyleNo: {
    border: "1px solid #BE888E",
    borderRadius: "0.6em",
    textTransform: "none" as const,
    ":hover": {
      background: "#FF5D5B",
      color: "white",
    },
    width: "200px",
    height: "50px",
    color: "white",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
  },
  listContainerStyle: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "2em",
  },
  centerButtonStyle: {
    display: "flex",
    gap: "1em",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonNavigation: {
    display: "flex",
    gap: "1em",
    justifyContent: "center",
    alignItems: "center",
    margin: "3em 0",
    "& button": {
      padding: "1em 2em",
      border: "1px solid #EAC8D0",
      borderRadius: "0.9em",
      "&:hover": {
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        color: "white",
        fontWeight: 500,
      },
    },
  },
};

export default withStyles(styles)(QuestionBankMain);
export { QuestionBankMain };
// Customizable Area End
