import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import PersonIcon from "@material-ui/icons/Person";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import AYQProfilePeekController, {
  Props,
} from "./AYQProfilePeekController.web";

class AYQProfilePeek extends AYQProfilePeekController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCurrentData = (activeIndex: number) => {
    const state = this.state;
    if (activeIndex === 0) {
      return (
        <>
          {state.currentDataAll?.map((current) => {
            return (
              <Box className={this.props.classes.questionAnswer}>
                <Typography>
                  {current.type === "ayq_answer" ? "A" : "Q"}
                </Typography>
                <Typography>
                  {current.attributes.answer || current.attributes.question}
                </Typography>
              </Box>
            );
          })}
        </>
      );
    } else if (activeIndex === 1) {
      return (
        <>
          {state.currentDataQue?.map((current) => {
            return (
              <Box className={this.props.classes.questionAnswer}>
                <Typography>
                  {current.type === "ayq_answer" ? "A" : "Q"}
                </Typography>
                <Typography>{current.attributes.question}</Typography>
              </Box>
            );
          })}
        </>
      );
    } else {
      return (
        <>
          {state.currentDataAns?.map((current) => {
            return (
              <Box className={this.props.classes.questionAnswer}>
                <Typography>
                  {current.type === "ayq_answer" ? "A" : "Q"}
                </Typography>
                <Typography>{current.attributes.answer}</Typography>
              </Box>
            );
          })}
        </>
      );
    }
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box className={this.props.classes.bannerStyle}>
          <Typography variant="h5">User Profile</Typography>
        </Box>
        <Box className={this.props.classes.profileContainer}>
          {this.state.userProfile ? (
            <Box>
              <Box className={this.props.classes.profilePicName}>
                <img
                  src={
                    this.state.userProfile.profile.data.attributes
                      .image as string
                  }
                  alt="user-image"
                />
                <Typography variant="h5">
                  {this.state.userProfile.profile.data.attributes.first_name +
                    " " +
                    this.state.userProfile.profile.data.attributes.last_name}
                </Typography>
              </Box>
              <Box className={this.props.classes.profileDetail}>
                <PersonIcon className={this.props.classes.personIcon} />
                <Typography variant="body1">
                  Member since {this.state.userProfile.member_since}
                </Typography>
                <Box>
                  <WatchLaterIcon className={this.props.classes.watchIcon} />
                  <Typography variant="body1">Last seen ago</Typography>
                </Box>
              </Box>
              <Box className={this.props.classes.profileButtons}>
                <Typography variant="h5">Top Posts</Typography>
                <Box component={"ul"}>
                  <Box
                    component={"li"}
                    data-test-id="set-active-btn"
                    className={this.state.activeNav === 0 ? "active" : ""}
                    onClick={() => this.setActiveIndex(0)}
                  >
                    All
                  </Box>
                  <Box
                    component={"li"}
                    data-test-id="set-active-btn"
                    className={this.state.activeNav === 1 ? "active" : ""}
                    onClick={() => this.setActiveIndex(1)}
                  >
                    Que
                  </Box>
                  <Box
                    component={"li"}
                    data-test-id="set-active-btn"
                    className={this.state.activeNav === 2 ? "active" : ""}
                    onClick={() => this.setActiveIndex(2)}
                  >
                    Ans
                  </Box>
                </Box>
              </Box>
              <Box className={this.props.classes.profileBox}>
                {this.renderCurrentData(this.state.activeNav)}
              </Box>
              <Box className={this.props.classes.badgesStyle}>
                <Typography variant="h6">Badges</Typography>
                <Box>
                  {this.state.userProfile.badges.data.map((badge) => {
                    return (
                      <img
                        src={badge.attributes.image as string}
                        alt="badge-image"
                      />
                    );
                  })}
                </Box>
              </Box>
            </Box>
          ) : (
            <Typography>No data found</Typography>
          )}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleAYQProfilePeek = () =>
  createStyles({
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "#510081",
      display: "flex",
      background: "rgb(247,242,249)",
      height: "71px",
      "& h5": {
        fontWeight: 700,
      },
    },
    profileContainer: {
      padding: "2em 4em",
    },
    profilePicName: {
      display: "flex",
      gap: "1em",
      alignItems: "center",
      "& img": {
        width: "65px",
        height: "65px",
        borderRadius: "50%",
        objectFit: "cover",
      },
    },
    profileDetail: {
      display: "flex",
      alignItems: "center",
      marginTop: "1em",
      "& div": {
        display: "flex",
        alignItems: "center",
        marginLeft: "1em",
      },
    },
    profileButtons: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "2.5em",
      "& ul": {
        display: "flex",
      },
      "& li": {
        border: "1px solid #C89AA5",
        cursor: "pointer",
        fontSize: "1.1em",
        padding: "0.2em 1.5em",
      },
      "& .active": {
        color: "#C89AA5",
      },
    },
    profileBox: {
      padding: "2em 1em",
      background: "rgb(250,245,250)",
      marginTop: "2em",
    },
    questionAnswer: {
      margin: "1.2em 0",
      "& p:nth-child(1)": {
        display: "inline-block",
        padding: "0.2em 1em",
        background: "#639939",
        color: "white",
      },
    },
    badgesStyle: {
      marginTop: "2em",
      padding: "0 2.5em",
      "& div": {
        display: "flex",
        gap: "1em",
        "& img": {
          width: "60px",
          height: "60px",
        },
      },
    },
    personIcon: {
      color: "#B28AC7",
    },
    watchIcon: {
      color: "#4A0287",
    },
  });
export default withStyles(webStyleAYQProfilePeek)(AYQProfilePeek);
export { AYQProfilePeek };
// Customizable Area End
