import React from "react";

import {
  Box,
  // Customizable Area Start
  Typography,
  Button,
  Modal,
  withStyles,
  createStyles,
  Theme,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import Select, { OptionTypeBase, StylesConfig } from "react-select";
interface OptionType extends OptionTypeBase {
  value: string;
  label: string;
}
import { filterBtn, sortBtn } from "./assets";
// Customizable Area End

import RecordedClassController, { Props } from "./RecordedClassController.web";

class RecordedClass extends RecordedClassController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const classes = this.props.classes;

    const colourStyles: StylesConfig<OptionType, false> = {
      input: (styles) => ({
        ...styles,
        color: "#510081",
      }),
      singleValue: (styles) => ({
        ...styles,
        color: "#510081",
      }),
      placeholder: (styles) => ({
        ...styles,
        color: "#510081",
      }),
      control: (styles) => ({
        ...styles,
        height: "51px",
        border: "1px solid #FF6B55",
        "&:hover": {
          outline: "none",
        },
        borderRadius: "0.5em",
        boxShadow: "none",
      }),
    };
    return (
      // Customizable Area Start
      <Box>
        <Typography
          variant="h5"
          style={{ fontWeight: 700, textAlign: "center", marginTop: "2em" }}
        >
          Recorded Classes
        </Typography>
        <Box className={classes.recordedClassContainer} marginTop={"2em"}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gridGap={"1em"}
          >
            {this.state.subjectButtons.map((subject, index) => {
              return (
                <Button
                  data-test-id="btn-subject-test"
                  className={classes.buttonStyle}
                  style={{
                    background:
                      this.state.activeSubject === index
                        ? "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)"
                        : "white",
                    color:
                      this.state.activeSubject === index ? "white" : "black",
                  }}
                  key={`${subject}-button`}
                  onClick={() => this.handleActiveSubject(subject)}
                >
                  {subject}
                </Button>
              );
            })}
          </Box>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gridGap={"1.2em"}
          >
            <Box position={"relative"}>
              <SearchIcon
                style={{
                  position: "absolute",
                  left: "4px",
                  top: "5px",
                  color: "#510081",
                }}
              />
              <input
                className={this.props.classes.inputSearchStyle}
                data-test-id="search"
                placeholder="Search"
                onChange={(event) => this.handleSearch(event.target.value)}
                onKeyDown={(event) => this.onKeyEnterPressSearch(event.key)}
                value={this.state.search}
              />
            </Box>
            <img
              src={filterBtn}
              data-test-id="filter"
              onClick={this.handleOpenModalFilter}
              style={{ width: "20px", cursor: "pointer" }}
            />
            <img
              src={sortBtn}
              data-test-id="sort"
              onClick={this.handleOpenModalSort}
              style={{ width: "20px", cursor: "pointer" }}
            />
          </Box>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <table
              cellPadding="0"
              className={classes.tableStyle}
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>Subject</th>
                  <th>Topic</th>
                  <th>Class Date</th>
                  <th>Recording</th>
                </tr>
              </thead>
              <tbody>
                {this.state.currentSubjectTable.map((recordedClass, index) => {
                  return (
                    <tr key={`${index}-tr-swayam`}>
                      <td>
                        {recordedClass.attributes.subject_name
                          ? recordedClass.attributes.subject_name
                          : "Unnamed Subject"}
                      </td>
                      <td>{recordedClass.attributes.chapter_name}</td>
                      <td>{recordedClass.attributes.academic_year}</td>
                      <td>
                        <NavLink
                          to={`/dashboard/classes/recorded-class/watch?id=${recordedClass.id}&type=recorded`}
                          className={classes.buttonStyleView}
                        >
                          View Recording
                        </NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
          <Modal
            open={this.state.isModalOpenFilter}
            onClose={this.handleCloseModalFilter}
          >
            <Box className={this.props.classes.modalStyle}>
              <Typography variant={"h6"} style={{ fontWeight: 700 }}>
                Filter By
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"column"}
                width={"100%"}
                gridGap={"1.5em"}
              >
                <Select
                  isSearchable={true}
                  options={this.state.options.subjects}
                  isClearable={true}
                  name="currentSubject"
                  data-test-id="filter-subject"
                  onChange={(event) =>
                    this.handleUserInputChangeCurrent(event, "currentSubject")
                  }
                  placeholder="Subject"
                  styles={colourStyles}
                />
                <Select
                  isSearchable={true}
                  options={this.state.options.chapters}
                  isClearable={true}
                  name="currentChapter"
                  data-test-id="filter-chapter"
                  onChange={(event) =>
                    this.handleUserInputChangeCurrent(event, "currentChapter")
                  }
                  placeholder="Chapter"
                  styles={colourStyles}
                />
              </Box>
              <Box alignSelf={"center"}>
                <Button
                  onClick={this.handleCloseModalFilter}
                  data-test-id="close-filter"
                >
                  Cancel
                </Button>
                <Button data-test-id="filter-init" onClick={this.doneFilter}>
                  Done
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={this.state.isModalOpenSort}
            onClose={this.handleCloseModalSort}
          >
            <Box className={this.props.classes.modalStyle}>
              <Typography variant={"h6"} style={{ fontWeight: 700 }}>
                Sort By
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"column"}
                width={"100%"}
                gridGap={"1.5em"}
              >
                <Select
                  isSearchable={true}
                  options={this.state.options.chapters}
                  isClearable={true}
                  name="currentChapter"
                  data-test-id="sort-chapter"
                  onChange={(event) =>
                    this.handleUserInputChangeCurrent(event, "currentSortChapter")
                  }
                  placeholder="Chapter"
                  styles={colourStyles}
                />
              </Box>
              <Box alignSelf={"center"}>
                <Button
                  data-test-id="close-sort"
                  onClick={this.handleCloseModalSort}
                >
                  Cancel
                </Button>
                <Button data-test-id="sort-init" onClick={this.doneSort}>
                  Done
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleRecordedClass = (theme: Theme) =>
  createStyles({
    modalStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      left: "50%",
      width: 500,
      height: "300px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "2em",
      border: "2px solid #832890",
      backgroundColor: "white",
      borderRadius: "0.5em",
      gap: "1em",
      outline: "none",
      textAlign: "left",
      "& input": {
        width: "100%",
        padding: "1em",
        outline: "none" as const,
        border: "1px solid #FF6B55",
        borderRadius: "0.5em",
        color: "#510081",
        "&::placeholder": {
          color: "#510081",
        },
      },
      "& button": {
        borderRadius: "0.6em",
        border: "1px solid #BE888E",
        textTransform: "none",
        "&:hover": {
          color: "white",
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        },
        minWidth: "130px",
        height: "45px",
        color: "#4F1f6d",
        fontWeight: 700,
        width: "max-content",
        margin: "0 1em",
      },
    },
    tableStyle: {
      width: "100%",
      margin: "4em 0",
      textAlign: "left",
      tableLayout: "fixed",
      "& thead": {
        background: "#E8E8E8",
        border: "1px solid #E8E8E8",
        "& tr th:nth-child(1)": {
          paddingLeft: "1.2em",
          borderTopLeftRadius: "0.7em",
        },
        "& tr th:last-child": {
          borderTopRightRadius: "0.7em",
        },
        "& tr th": {
          padding: "1.2em 0",
        },
      },
      "& tbody": {
        "& tr td": {
          padding: "1.2em 0",
        },
        "& tr:last-child td:nth-child(1)": {
          borderBottomLeftRadius: "0.7em",
        },
        "& tr:last-child td:last-child": {
          borderBottomRightRadius: "0.7em",
        },
        "& tr td:nth-child(1)": {
          paddingLeft: "1.2em",
        },
        "& tr:nth-child(even)": {
          background: "#E8E8E8",
        },
      },
    },
    buttonStyle: {
      borderRadius: "0.6em",
      border: "1px solid #BE888E",
      textTransform: "none" as const,
      "&:hover": {
        background: "#FF5D5B",
        color: "white",
      },
      padding: "0 2em",
      height: "50px",
      color: "#4F1f6d",
      width: "max-content",
    },
    buttonStyleView: {
      border: "none",
      textTransform: "none" as const,
      display: "flex",
      borderRadius: "0.6em",
      justifyContent: "center",
      height: "45px",
      color: "white",
      alignItems: "center",
      width: "180px",
      fontSize: "0.9em",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    },
    inputSearchStyle: {
      border: "1px solid #510081",
      borderRadius: "1.2em",
      color: "#510081",
      padding: "0.5em 0.5em 0.5em 2em",
      outline: "none",
      "&::placeholder": {
        color: "#510081",
      },
    },
    recordedClassContainer: {
      padding: "0 2em",
    },
  });

export default withStyles(webStyleRecordedClass)(RecordedClass);
export { RecordedClass };
// Customizable Area End
