// Customizable Area Start
import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import {
  Box,
  Modal,
  Typography,
  Button as MUIButton,
  CircularProgress,
} from "@material-ui/core";
import Button from "../../../components/src/Button.web";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import AdvancedSearchDashboardController, {
  Props,
} from "./AdvancedSearchDashboardController.web";
import {
  GrandTestDataType,
  LiveClassDataType,
  PracticeTestDataType,
  RecordedClassDataType,
  QuestionBankDataType,
  SynopsisDataType,
  SwayamPrabhaDataType,
  WeeklyTestDataType,
} from "./SearchResults";

// Customizable Area End

// Customizable Area Start
export const advancedDashboardSearchStyle = () =>
  createStyles({
    modalStyle: {
      position: "absolute",
      top: "150px",
      left: "22px",
      background: "white",
      borderRadius: "1.5em",
      width: "97%",
      height: "75%",
      transition: "all 0.3s ease",
      overflowY: "scroll",
      overflowX: "hidden",
      padding: "2em",
      "&:hover": {
        overflowY: "scroll",
      },
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderRadius: "0.2em",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "0.2em",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },
    searchInput: {
      width: "97%",
      position: "absolute",
      top: "98.5px",
      left: "22px",
    },
    imageStyle: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
    },
    cardStyle: {
      width: "500px",
      height: "140px",
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "center",
      border: "1px solid rgba(255, 63, 89, 1)",
      borderRadius: "0.5em",
      padding: "4em 0.7em",
      gap: "1em",
    },
    buttonStyles: {
      display: "flex",
      gap: "2em",
      marginLeft: "3.5em",
    },
    buttonStyle: {
      color: "rgb(82, 0, 130)",
      width: "125px",
      borderRadius: "10px",
      textTransform: "none" as const,
      border: "1px solid #FF9486",
      height: "40px",
      display: "flex",
      position: "relative",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    goBackStep: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      padding: "0.3em",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      color: "white",
      cursor: "pointer",
      position: "absolute",
      right: "5px",
    },
    forwardIcon: {
      display: "flex",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.3em",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      color: "white",
      right: "-20px",
      position: "absolute",
    },
    chapterBox: {
      display: "flex",
      border: "1px solid #C1A09D",
      borderRadius: "0.3em",
      minWidth: "500px",
      height: "140px",
      padding: "1em",
      marginTop: "1.5em",
      gap: "0.8em",
      position: "relative",
      "& .chapter-image": {
        background: "rgb(178, 176, 176)",
        border: "none",
        borderRadius: "50%",
        width: "55px",
        height: "55px",
      },
      "& .nameBox": {
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "space-around",
      },
      "& .learnNow": {
        position: "absolute" as const,
        right: "15px",
        bottom: "24px",
        fontSize: "0.8em",
      },
      "& .learnNowCircle": {
        display: "inline-block",
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: "#FF5B5F" as const,
        marginRight: "0.6em",
      },
    },
  });

// Customizable Area End

export class AdvancedSearchDashboard extends AdvancedSearchDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;

    const renderSearchResultQuestionBank = (questionBank: {
      data: QuestionBankDataType[];
    }) => {
      if (questionBank.data.length > 0) {
        return (
          <Box marginTop={"2em"}>
            <Typography variant="h6">Question Bank</Typography>
            <Box display="flex" width="100%" gridGap="1em" flexWrap="wrap">
              {questionBank.data.slice(-3).map((question, index) => {
                return (
                  <Box
                    key={`${question.id}-${index}-qb`}
                    className={this.props.classes.chapterBox}
                    style={{ cursor: "pointer" }}
                    data-test-id="questionBank-navigate-btn"
                    onClick={() => {
                      this.navigateQuestionBank(question.id);
                      this.handleInputState();
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"flex-start"}
                      justifyContent={"center"}
                      marginTop={"1em"}
                    >
                      <img
                        src={question.attributes.image as string}
                        className={this.props.classes.imageStyle}
                        alt={"qb-image"}
                      />
                      <Typography
                        style={{ marginLeft: "1em", marginTop: "0.5em" }}
                      >
                        {question.attributes.name}
                      </Typography>
                    </Box>
                    <p className="learnNow">
                      <span className="learnNowCircle" />
                      Learn now
                    </p>
                  </Box>
                );
              })}
              <ViewMoreElement link="/dashboard/question-bank" />
            </Box>
          </Box>
        );
      }
    };

    const ViewMoreElement = ({ link }: { link: string }) => {
      return (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Link to={link} onClick={this.handleInputState}>
            <MUIButton
              style={{
                position: "relative",
                backgroundColor: "transparent",
              }}
            >
              View More
              <Box
                data-test-id="gobackbtn"
                className={this.props.classes.forwardIcon}
              >{`>`}</Box>
            </MUIButton>
          </Link>
        </Box>
      );
    };

    const renderSynopsisResult = (synopsisData: {
      data: SynopsisDataType[];
    }) => {
      if (synopsisData.data.length > 0) {
        return (
          <Box marginTop={"2em"}>
            <Typography variant="h6">Synopsis</Typography>
            <Box display="flex" width="100%" gridGap="1em" flexWrap="wrap">
              {synopsisData.data.slice(-3).map((synopsis, index) => {
                return (
                  <Box
                    key={`${synopsis.attributes.id}-${index}-synopsis`}
                    className={this.props.classes.chapterBox}
                    style={{ cursor: "pointer" }}
                    data-test-id="synopsis-navigate-btn"
                    onClick={() => {
                      this.navigateSynopsis(
                        synopsis.id,
                        String(
                          synopsis.attributes.chapter.data.attributes.subject.id
                        ),
                        synopsis.attributes.chapter.data.id
                      );
                      this.handleInputState();
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"flex-start"}
                      justifyContent={"center"}
                      marginTop={"1em"}
                    >
                      <img
                        src={synopsis.attributes.chapter.data.attributes.image}
                        className={this.props.classes.imageStyle}
                        alt={"synopsis-image"}
                      />
                      <Typography
                        style={{ marginLeft: "1em", marginTop: "0.5em" }}
                      >
                        {synopsis.attributes.name}
                      </Typography>
                    </Box>
                    <p className="learnNow">
                      <span className="learnNowCircle" />
                      Learn now
                    </p>
                  </Box>
                );
              })}
              <ViewMoreElement link="/dashboard/synopsis" />
            </Box>
          </Box>
        );
      }
    };

    const renderSwayamPrabhaResult = (swayamPrabhaData: {
      data: SwayamPrabhaDataType[];
    }) => {
      if (swayamPrabhaData.data.length > 0) {
        return (
          <Box marginTop={"2em"}>
            <Typography variant="h6">Swayam Prabha</Typography>
            <Box display="flex" width="100%" gridGap="1em" flexWrap="wrap">
              {swayamPrabhaData.data.slice(0, 3).map((swayam, index) => {
                return (
                  <Box
                    key={`${swayam.attributes.id}-${index}-swayam`}
                    className={this.props.classes.chapterBox}
                  >
                    <Box className="nameBox">
                      <p>{swayam.attributes.chapter_name}</p>
                      <Link
                        to={`/dashboard/classes/recorded-class/watch?id=${
                          swayam.id
                        }&type=swayam`}
                        onClick={this.handleInputState}
                      >
                        <Button
                          color="white"
                          height="45px"
                          title="View Recording"
                          width="160px"
                        />
                      </Link>
                    </Box>
                  </Box>
                );
              })}
              <ViewMoreElement link="/dashboard/swayam-prabha" />
            </Box>
          </Box>
        );
      }
    };

    const renderRecordedClassResult = (recordedClassData: {
      data: RecordedClassDataType[];
    }) => {
      if (recordedClassData.data.length > 0) {
        return (
          <Box marginTop={"2em"}>
            <Typography variant="h6">Recorded Classes</Typography>
            <Box display="flex" width="100%" gridGap="1em" flexWrap="wrap">
              {recordedClassData.data.slice(0, 3).map((recordedData, index) => {
                return (
                  <Box
                    key={`${recordedData.attributes.id}-${index}-recorded`}
                    className={this.props.classes.chapterBox}
                  >
                    <Box className="nameBox">
                      <p>{recordedData.attributes.chapter_name}</p>
                      <Link
                        to={`/dashboard/classes/recorded-class/watch?id=${
                          recordedData.id
                        }&type=recorded`}
                        onClick={this.handleInputState}
                        target="_blank"
                      >
                        <Button
                          color="white"
                          height="45px"
                          title="View Recording"
                          width="160px"
                        />
                      </Link>
                    </Box>
                  </Box>
                );
              })}
              <ViewMoreElement link="/dashboard/classes/recorded-class" />
            </Box>
          </Box>
        );
      }
    };

    const renderLiveClassResult = (liveClassData: {
      data: LiveClassDataType[];
    }) => {
      if (liveClassData.data.length > 0) {
        return (
          <Box marginTop={"2em"}>
            <Typography variant="h6">Live Class</Typography>
            <Box display="flex" width="100%" gridGap="1em" flexWrap="wrap">
              {liveClassData.data.slice(0, 3).map((liveData, index) => {
                return (
                  <Box
                    key={`${liveData.attributes.id}-${index}-live-class`}
                    className={this.props.classes.chapterBox}
                  >
                    <Box className="nameBox">
                      <p>{liveData.attributes.chapter_name}</p>
                      <a
                        href={liveData.attributes.class_url}
                        onClick={this.handleInputState}
                        target="_blank"
                      >
                        <Button
                          color="white"
                          height="45px"
                          title="Attend Class"
                          width="160px"
                        />
                      </a>
                    </Box>
                  </Box>
                );
              })}
              <ViewMoreElement link="/dashboard/classes/live-class" />
            </Box>
          </Box>
        );
      }
    };

    const renderSearchResultPractice = (practiceTest: {
      data: PracticeTestDataType[];
    }) => {
      if (practiceTest.data.length > 0) {
        return (
          <Box marginTop={"2em"}>
            <Typography variant="h6">Practice Test</Typography>
            <Box display="flex" width="100%" gridGap="1em" flexWrap="wrap">
              {practiceTest.data
                .slice(0, 3)
                .slice(0, 3)
                .map((test) => {
                  return (
                    <Box
                      key={`${test.id}-practice`}
                      className={this.props.classes.cardStyle}
                      style={{
                        border: "1px solid #55624D",
                      }}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                      >
                        <img
                          src={test.attributes.image as string}
                          className={this.props.classes.imageStyle}
                          alt={`chapter-${test.id}`}
                        />
                        <p
                          style={{
                            fontSize: "15px",
                            marginLeft: "1em",
                            fontWeight: "bold",
                          }}
                        >
                          {test.attributes.name}
                        </p>
                      </Box>
                      <Box className={this.props.classes.buttonStyles}>
                        <Link
                          to={`/dashboard/practice-test/chapters/${
                            test.attributes.subject.id
                          }/test/${test.id}`}
                          onClick={this.handleInputState}
                        >
                          <Button
                            color="white"
                            width="121px"
                            height="40px"
                            title="Take Test"
                          />
                        </Link>
                        <Link
                          to={`/dashboard/practice-test/chapters/${
                            test.attributes.subject.id
                          }/review-test/${test.id}`}
                          data-test-id="review-test"
                          onClick={this.handleInputState}
                        >
                          <MUIButton className={this.props.classes.buttonStyle}>
                            Review Test
                            <Box
                              className={this.props.classes.goBackStep}
                              data-test-id="gobackbtn"
                            >{`>`}</Box>
                          </MUIButton>
                        </Link>
                      </Box>
                    </Box>
                  );
                })}
              <ViewMoreElement
                link={`/dashboard/practice-test/chapters/${
                  practiceTest.data[0].attributes.subject.id
                }`}
              />
            </Box>
          </Box>
        );
      }
    };

    const renderSearchResultGrand = (grandTest: {
      data: GrandTestDataType[];
    }) => {
      if (grandTest.data.length > 0) {
        return (
          <Box marginTop={"2em"}>
            <Typography variant="h6">Grand Test</Typography>
            <Box display="flex" width="100%" gridGap="1em" flexWrap="wrap">
              {grandTest.data.slice(0, 3).map((test) => {
                return (
                  <Box
                    key={`${test.id}-grand`}
                    className={this.props.classes.cardStyle}
                    style={{
                      border: "1px solid #55624D",
                    }}
                  >
                    <Box
                      justifyContent={"flex-start"}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <img
                        alt={`chapter-${test.id}`}
                        src={test.attributes.image_url as string}
                        className={this.props.classes.imageStyle}
                      />
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                          marginLeft: "1em",
                        }}
                      >
                        {test.attributes.test_name}
                      </p>
                    </Box>
                    <Box className={this.props.classes.buttonStyles}>
                      <Link
                        onClick={this.handleInputState}
                        to={`/dashboard/grand-test/subjects/${
                          test.attributes.gt_code
                        }/test/${test.id}`}
                      >
                        <Button
                          height="40px"
                          width="121px"
                          color="white"
                          title="Take Test"
                        />
                      </Link>
                      <Link
                        to={`/dashboard/grand-test/subjects/${
                          test.attributes.gt_code
                        }/review-test/${test.id}`}
                        onClick={this.handleInputState}
                      >
                        <MUIButton className={this.props.classes.buttonStyle}>
                          Review Test
                          <Box
                            className={this.props.classes.goBackStep}
                            data-test-id="gobackbtn"
                          >{`>`}</Box>
                        </MUIButton>
                      </Link>
                    </Box>
                  </Box>
                );
              })}
              <ViewMoreElement
                link={`/dashboard/grand-test/subjects/${
                  grandTest.data[0].attributes.gt_code
                }`}
              />
            </Box>
          </Box>
        );
      }
    };

    const renderSearchResultWeekly = (weeklyTest: {
      data: WeeklyTestDataType[];
    }) => {
      if (weeklyTest.data.length > 0) {
        return (
          <Box marginTop={"2em"}>
            <Typography variant="h6">Weekly Test</Typography>
            <Box display="flex" width="100%" gridGap="1em" flexWrap="wrap">
              {weeklyTest.data.slice(0, 3).map((test) => {
                return (
                  <Box
                    className={this.props.classes.cardStyle}
                    style={{
                      border: "1px solid #55624D",
                    }}
                    key={`${test.id}-weekly`}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <img
                        src={test.attributes.image}
                        className={this.props.classes.imageStyle}
                        alt={`chapter-${test.id}`}
                      />
                      <p
                        style={{
                          marginLeft: "1em",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        {test.attributes.test_name}
                      </p>
                    </Box>
                    <Box className={this.props.classes.buttonStyles}>
                      <Link
                        to={`/dashboard/weekly-test/tests/take-test/${test.id}`}
                        onClick={this.handleInputState}
                      >
                        <Button
                          color="white"
                          width="121px"
                          title="Take Test"
                          height="40px"
                        />
                      </Link>
                      <Link
                        to={`/dashboard/weekly-test/tests/review-test/${
                          test.id
                        }`}
                        onClick={this.handleInputState}
                      >
                        <MUIButton className={this.props.classes.buttonStyle}>
                          Review Test
                          <Box
                            data-test-id="gobackbtn"
                            className={this.props.classes.goBackStep}
                          >{`>`}</Box>
                        </MUIButton>
                      </Link>
                    </Box>
                  </Box>
                );
              })}
              <ViewMoreElement link="/dashboard/weekly-test/tests" />
            </Box>
          </Box>
        );
      }
    };

    return (
      <Box>
        <Modal open={this.state.inputState} onClose={this.handleInputState}>
          <Box>
            <Box className={classes.searchInput}>
              <SearchIcon
                style={{
                  color: "#520082",
                  position: "absolute",
                  left: "0.5%",
                  top: "15%",
                }}
              />
              <input
                placeholder="Search"
                style={{
                  borderRadius: "3em",
                  border: "none",
                  height: "36px",
                  padding: "0 0.3em 0 2.4em",
                  width: "100%",
                  outline: "none",
                }}
                onChange={(event) => this.handleSearch(event.target.value)}
                data-test-id="search-input"
                autoFocus
              />
            </Box>
            <Box className={this.props.classes.modalStyle}>
              {this.state.loading ? (
                <Box
                  display="flex"
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"100%"}
                >
                  <CircularProgress color="secondary" />
                </Box>
              ) : (
                <>
                  {this.state.noData && (
                    <Typography variant="h6">
                      Search found no results
                    </Typography>
                  )}
                  {renderSearchResultPractice(
                    this.state.searchResults.practice_test
                  )}
                  {renderSearchResultWeekly(
                    this.state.searchResults.weekly_test
                  )}
                  {renderSearchResultGrand(this.state.searchResults.grand_test)}
                  {renderSynopsisResult(this.state.searchResults.synopsis)}
                  {renderSearchResultQuestionBank(
                    this.state.searchResults.question_bank
                  )}
                  {renderLiveClassResult(this.state.searchResults.live_classes)}
                  {renderRecordedClassResult(
                    this.state.searchResults.recorded_classes
                  )}
                  {renderSwayamPrabhaResult(
                    this.state.searchResults.swayamprabha
                  )}
                </>
              )}
            </Box>
          </Box>
        </Modal>
        <Box
          style={{ position: "relative" }}
          onClick={this.handleInputState}
          data-test-id="search-btn"
        >
          <SearchIcon
            style={{
              color: "#520082",
              position: "absolute",
              left: "3%",
              top: "15%",
            }}
          />
          <input
            placeholder="Search"
            style={{
              borderRadius: "3em",
              border: "none",
              height: "36px",
              padding: "0 0.3em 0 2.4em",
              width: "100%",
            }}
          />
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(advancedDashboardSearchStyle)(
  AdvancedSearchDashboard
);
// Customizable Area End
