Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.notificationDataEndPoint = "/bx_block_notifications/cource_notifications?page={pageNo}&category_id={categoryId}"
exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.allBTN = { "id": "0","type": "All","attributes": { "id": 0, "name": "All" }};
exports.okText = "OK"
exports.notif = "Notifications"
exports.deleteMessage = "Notifications deleted!"
exports.notiCategoriesEndPoint = "/bx_block_notifications/notification_categories"
exports.examNotificationLabel = "Exam Notifications"
exports.examNotificationEndpoint = "bx_block_notifications/exam_notifications"
exports.notificationEndpointCourse = "/bx_block_notifications/cource_notifications"
exports.headerTitle = "Notification";
exports.SearchData = "Search";
exports.categoryText = "Category";
exports.categoryTextWp = "Whatsapp";
exports.categoryTextEmail = "Email";
exports.categoryTextSms = "Sms";
exports.markRead = "Mark as read";
exports.notificationData = "Test the notification demo text";
exports.universityName = "Betch - Amerita University ";
exports.notificationHeader = "Notifications";
// Customizable Area End