import React from "react";

import {
  Box as MuiBox,
  // Customizable Area Start
  Modal,
  Button,
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import TimerIcon from "@material-ui/icons/Timer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Stepper from "../../../components/src/Stepper.web";
import { styles } from "./styles";
import { clean } from "./assets";
import TestComponent from "../../../components/src/TestComponent.web";
import { Link, Prompt } from "react-router-dom";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import GrandTestTakingController, {
  Props,
} from "./GrandTestTakingController.web";

class GrandTestTaking extends GrandTestTakingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const totalSeconds = Math.floor(this.state.timeRemaining / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = totalSeconds - hours * 3600 - minutes * 60;

    return (
      // Customizable Area Start
      <MuiBox>
        <Prompt
          when={this.state.isBlocking}
          message="Are you sure you want to leave this page? Your changes may be lost."
        />
        <Modal
          open={this.state.submitModalOpen}
          onClose={this.handleSubmitModalClose}
          BackdropProps={{ onClick: undefined }}
        >
          <MuiBox sx={webStyle.modalStyle} className="submit-test-question">
            <h3>Are you sure you want to submit your Test?</h3>
            <MuiBox>
              <Button
                style={webStyle.buttonStyleNo}
                data-test-id="closeSubmit"
                onClick={this.handleSubmitModalClose}
              >
                No
              </Button>
              <Button
                style={webStyle.buttonStyleYes}
                data-test-id="submitBtnModal"
                onClick={this.submitTest}
              >
                Yes
              </Button>
            </MuiBox>
          </MuiBox>
        </Modal>
        <Modal
          open={this.state.reviewModalOpen}
          onClose={this.handleReviewModalClose}
          BackdropProps={{ onClick: undefined }}
        >
          <MuiBox
            sx={{ ...webStyle.modalStyle, height: "300px" }}
            className="submit-test-done"
          >
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <MuiBox
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3>Your Test has been submitted Succesfully</h3>
              <h3>Click On Review button to know about the answers</h3>
            </MuiBox>
            <Link
              to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                "packageId"
              )}/review/${this.state.reviewId}/${this.subjectId}`}
            >
              <Button style={webStyle.buttonStyleYes}>Review</Button>
            </Link>
          </MuiBox>
        </Modal>
        <Modal
          open={this.state.errorModalOpen}
          BackdropProps={{ onClick: undefined }}
        >
          <MuiBox sx={webStyle.modalStyle} className="submit-test-question">
            <h3>No exam found</h3>
            <Button
              style={webStyle.buttonStyleYes}
              data-test-id="goBack"
              onClick={this.goBack}
            >
              Go back
            </Button>
          </MuiBox>
        </Modal>
        <Loader loading={this.state.loading} />
        <MuiBox style={webStyle.bannerStyle}>
          <h2>Grand Test</h2>
        </MuiBox>
        <MuiBox
          style={{ display: "flex", gap: "1em", padding: "1em 2em 0 2em" }}
        >
          <Stepper
            steps={this.state.currentSubjectTestData.length}
            activeStep={this.state.currentTestData + 1}
          />
          <p>
            {this.state.currentTestData + 1}/
            {this.state.currentSubjectTestData.length}
          </p>
        </MuiBox>
        <MuiBox
          style={{
            display: "flex",
            gap: "1em",
            padding: "1em 2em",
            alignItems: "center",
          }}
        >
          <p className={this.props.classes.practiceTestTimer}>
            <span>
              <TimerIcon />
            </span>
            {String(hours).length > 1 ? hours : `${hours}`}:
            {String(minutes).length > 1 ? minutes : `0${minutes}`}:
            {String(seconds).length > 1 ? seconds : `0${seconds}`}
          </p>
        </MuiBox>
        <MuiBox
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MuiBox style={{ display: "flex", gap: "1em", marginBottom: "1em" }}>
            {this.state.testSubjects.map((subject, index) => {
              return (
                <Button
                  data-test-id={`change-subject-btn`}
                  key={`${index}-gtsubject-${subject.subject_name}`}
                  onClick={() =>
                    this.handleSubjectChange(subject.subject_name, index)
                  }
                  style={{
                    border: "none",
                    padding: "1em 2em",
                    background:
                      this.state.currentSubjectTestDataIndex === index
                        ? "#FAE6FF"
                        : "#E9E9E9",
                    fontWeight: 500,
                    fontSize: "15px",
                  }}
                >
                  {subject.subject_name}
                </Button>
              );
            })}
          </MuiBox>
          <p style={{ color: "#520082", fontWeight: 500, fontSize: "15px" }}>
            This question carries +
            {
              this.state.currentSubjectTestData[this.state.currentTestData]
                ?.attributes.p_mark
            }{" "}
            for Correct Answer and -
            {Number(
              this.state.currentSubjectTestData[this.state.currentTestData]
                ?.attributes.n_mark
            )}{" "}
            for wrong Answer
          </p>
        </MuiBox>
        <MuiBox className={this.props.classes.mainContainerPracticeTest}>
          {this.state.currentSubjectTestData.length > 0 && (
            <TestComponent
              question={
                this.state.currentSubjectTestData[this.state.currentTestData]
              }
              index={this.state.currentTestData}
              setAnswer={this.setAnswer}
              answers={this.state.currentAnswers}
            />
          )}
          <MuiBox className="overview-practice-test">
            <MuiBox className="overview-board">
              <h3>Overview</h3>
              <MuiBox className="overview-btns">
                {this.state.currentSubjectTestData.map((question, index) => {
                  const className = this.getBtnClassName(index);
                  return (
                    <button
                      data-test-id={`specific-btn-test-id`}
                      key={`button-${index}`}
                      className={className}
                      onClick={() => this.handleSpecificQuestion(index)}
                    >
                      {question.attributes.question_no}
                    </button>
                  );
                })}
              </MuiBox>
            </MuiBox>
            <MuiBox className="overview-legends">
              <p>
                <span>
                  <FiberManualRecordIcon style={{ color: "#9CBA58" }} />
                </span>
                Answered{" "}
                <span style={{ marginLeft: "0.8em" }}>
                  <FiberManualRecordIcon style={{ color: "#4B4B4B" }} />
                </span>
                Un-Answered
              </p>
              <p>
                <span>
                  <FiberManualRecordIcon style={{ color: "#FF8147" }} />
                </span>
                Answered & Marked for Review
              </p>
              <p>
                <span>
                  <FiberManualRecordIcon style={{ color: "#DD1000" }} />
                </span>
                Not Answered & Marked for Review
              </p>
              <p>
                <span>
                  <FiberManualRecordIcon style={{ color: "#69B5FF" }} />
                </span>
                Answered & Cleared the answer
              </p>
              <p>
                <span>
                  <FiberManualRecordIcon style={{ color: "#D426C9" }} />
                </span>
                Marked & Unmarked for review
              </p>
            </MuiBox>
          </MuiBox>
        </MuiBox>
        <MuiBox className={this.props.classes.btnsPracticeTest}>
          <MuiBox className="submit-mark-practice-btns">
            <button
              data-test-id={`submitTest`}
              className="submit-test-btn"
              onClick={this.handleSubmitModalOpen}
            >
              Submit Test
            </button>
          </MuiBox>
          <MuiBox className="navigation-practice-btns">
            <MuiBox className="bookmark-clear-box">
              <MuiBox
                className="bookmark-icon-review"
                onClick={this.markForReview}
                data-test-id="markforreview"
              >
                <BookmarkIcon />
              </MuiBox>
              <MuiBox
                className="clear-icon"
                onClick={this.clearResponse}
                data-test-id="clrbtn"
              >
                <img src={clean} alt="clear-icon" />
              </MuiBox>
            </MuiBox>
            <MuiBox>
              <button
                data-test-id={`prevbtn`}
                onClick={this.handlePreviousQuestion}
              >
                <ChevronLeftIcon />
              </button>
              <button data-test-id={`nxtbtn`} onClick={this.handleNextQuestion}>
                <ChevronRightIcon />
              </button>
            </MuiBox>
          </MuiBox>
        </MuiBox>
      </MuiBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
  modalStyle: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute" as "absolute",
    minHeight: "max-content",
    bgcolor: "background.paper",
    border: "2px solid #FFD0D8",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    borderRadius: "1.5em",
    padding: "2em",
    gap: "1.5em",
  },
  buttonStyleYes: {
    width: "130px",
    color: "white",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    textTransform: "none" as const,
    height: "50px",
    marginLeft: "1em",
  },
  bannerStyle: {
    display: "flex",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    color: "rgb(82, 0, 130)",
  },
  buttonStyleNo: {
    width: "130px",
    color: "#4F1f6d",
    background: "white",
    border: "1px solid #ffe9ed",
    borderRadius: "0.6em",
    height: "50px",
    textTransform: "none" as const,
  },
};

export default withStyles(styles)(GrandTestTaking);
export { GrandTestTaking };
// Customizable Area End
