import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { styles } from "./styles";
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { extractParams } from "../../../framework/src/UtilsWeb";
interface Root {
  data: Daum[];
  meta: Meta;
}

interface Daum {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Attributes {
  id: number;
  topic_id: number;
  question_no: number;
  question_1: string;
  question_2: string;
  correct_answer: string;
  explanation: string;
  passage: string;
  question_type: string;
  p_mark: number;
  n_mark: string;
  remark: string;
  created_by: string;
  options: Option[];
  opt_count?: number;
  is_correct: boolean;
  is_unattended: boolean;
  user_answer: null | string;
  percentage_people_correct: number;
}

interface Option {
  id: string;
  option: string;
}

interface Meta {
  chapter: Chapter;
  correct_answer: number;
  incorrect_answer: number;
  unattended: number;
  marks: number;
  time_taken: string;
}

interface Chapter {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  practice_test: string;
  avail_pt: string;
  date_pt: string;
  class_zone: string;
  avail_cz: string;
  date_cz: string;
  question_bank: string;
  avail_qb: string;
  date_qb: string;
  created_by: string;
  remarks: string;
  topic_db: string;
  qb_id: any;
  qb_db: string;
  id_pt: string;
  id_cz: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof styles> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  reviewData: Daum[];
  metaData: Meta | null;
  currentReviewData: number;
  openSaveNotesModal: boolean;
  openBookmarkModal: boolean;
  openBookmarkSuccessModal: boolean;
  loading: boolean;
  correctPercentage: number | null;
  incorrectPercentage: number | null;
  unattendedPercentage: number | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReviewTestController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getReviewHistoryMessageId = "";
  bookmarkHistoryMessageId = "";
  getSubjectReviewTestMessageId = "";
  reviewId = "";
  pathnameSearchResolve =
    this.props.navigation.history.location.pathname +
    this.props.navigation.history.location.search;
  searchResolve = this.props.navigation.history.location.search;
  breadcrumb = [
    {
      label: "Practice Test",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}${this.searchResolve}`,
    },
    {
      label: "Chapters",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}${this.searchResolve}`,
    },
    {
      label: "Reviews",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/review-test/${this.props.navigation.getParam("chapterId")}${
        this.searchResolve
      }`,
    },
    {
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/review-test/${this.props.navigation.getParam("chapterId")}${
        this.searchResolve
      }`,
      label: "Reviews",
    },
    {
      link: this.pathnameSearchResolve,
      label: "Review Test",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      reviewData: [],
      metaData: null,
      currentReviewData: 0,
      openSaveNotesModal: false,
      openBookmarkModal: false,
      loading: false,
      openBookmarkSuccessModal: false,
      correctPercentage: null,
      incorrectPercentage: null,
      unattendedPercentage: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getReviewHistoryMessageId) {
      if (response.data) {
        this.handleSuccessfulReviewFetch(response);
      }
    }

    if (apiRequestCallId === this.bookmarkHistoryMessageId) {
      if (response.data) {
        this.handleSuccessfulBookmark();
      }
    }

    if (apiRequestCallId === this.getSubjectReviewTestMessageId && response) {
      if (response.data) {
        this.handleSuccessSubject(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.reviewId = this.props.navigation.getParam("reviewId");

    this.getReviewHistory(this.reviewId);
    this.getSubjectReviewTest();

    const propPassingRTC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingRTC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingRTC);
  };

  handleSuccessSubject = (
    response: {
      id: string;
      attributes: { name: string };
    }[]
  ) => {
    const subjectId = this.props.navigation.getParam("subjectId");
    for (const data of response) {
      if (data.id === subjectId) {
        const breadcrumb = this.breadcrumb;
        breadcrumb[1].label = data.attributes.name;

        const propPassingRT = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingRT.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingRT);

        this.setState({
          loading: false,
        });
      }
    }
  };

  handleSuccessfulReviewFetch = (response: Root) => {
    const total =
      response.meta.incorrect_answer +
      response.meta.correct_answer +
      response.meta.unattended;

    const breadcrumb = this.breadcrumb;
    breadcrumb[2].label = response.meta.chapter.name;

    const propPassingRT = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingRT.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingRT);

    this.setState({
      reviewData: response.data,
      metaData: response.meta,
      loading: false,
      correctPercentage: Number(
        ((response.meta.correct_answer / total) * 100).toFixed(2)
      ),
      incorrectPercentage: Number(
        ((response.meta.incorrect_answer / total) * 100).toFixed(2)
      ),
      unattendedPercentage: Number(
        ((response.meta.unattended / total) * 100).toFixed(2)
      ),
    });
  };

  handleNotesModalClose = () => {
    this.setState({
      openSaveNotesModal: false,
    });
  };

  handleNotesModalOpen = () => {
    this.setState({
      openSaveNotesModal: true,
    });
  };

  handleBookmarkModalClose = () => {
    this.setState({
      openBookmarkModal: false,
    });
  };

  handleBookmarkModalOpen = () => {
    this.setState({
      openBookmarkModal: true,
    });
  };

  handleBookmarkSuccessModalOpen = () => {
    this.setState({
      openBookmarkSuccessModal: true,
    });
  };

  handleBookmarkSuccessModalClose = () => {
    this.setState({
      openBookmarkSuccessModal: false,
    });
  };

  handleSuccessfulBookmark = () => {
    this.handleBookmarkModalClose();
    this.handleBookmarkSuccessModalOpen();
  };

  getBtnClassName = (index: number) => {
    const current = this.state.reviewData[index].attributes;

    if (current.is_correct) {
      return "btn-answered";
    }

    if (!current.is_correct && !current.is_unattended) {
      return "btn-unanswered-marked";
    }

    if (current.is_unattended) {
      return "btn-unanswered";
    }
  };

  handleSpecificQuestion = (index: number) => {
    this.setState({
      currentReviewData: index,
    });
  };

  handleNextQuestion = () => {
    if (this.state.currentReviewData !== this.state.reviewData.length - 1) {
      this.setState({
        currentReviewData: this.state.currentReviewData + 1,
      });
    }
  };

  handlePreviousQuestion = () => {
    if (this.state.currentReviewData !== 0) {
      this.setState({
        currentReviewData: this.state.currentReviewData - 1,
      });
    }
  };

  // Customizable Area Start
  getReviewHistory = async (reviewId: string) => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getReviewHistoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReviewHistoryMessageId = getReviewHistoryMessage.messageId;

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${reviewId}/review_history`
    );

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getReviewHistoryMessage.id, getReviewHistoryMessage);
  };

  bookmarkSave = async () => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const body = {
      bookmarkable_id: this.reviewId,
      bookmarkable_type: "PracticeTestReviewHistory",
      question_id:
        this.state.reviewData[this.state.currentReviewData].attributes.id,
      question_no:
        this.state.reviewData[this.state.currentReviewData].attributes
          .question_no,
    };

    const bookmarkHistoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bookmarkHistoryMessageId = bookmarkHistoryMessage.messageId;

    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/bookmarks`
    );

    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(bookmarkHistoryMessage.id, bookmarkHistoryMessage);
  };

  getSubjectReviewTest = async () => {
    const urlSearch = this.props.navigation.history.location.search;
    const packageId = extractParams(urlSearch, "packageId");
    const getSubjectReviewTestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    this.getSubjectReviewTestMessageId = getSubjectReviewTestMessage.messageId;

    getSubjectReviewTestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${packageId}/get_subjects`
    );

    getSubjectReviewTestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectReviewTestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSubjectReviewTestMessage.id, getSubjectReviewTestMessage);
  };
  // Customizable Area End
}
