import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";

export interface Root {
  data: Daum[];
}

export interface Daum {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  account_id: number;
  subject_name: string;
  topic_name: string;
  question: string;
  like_count: number;
  dislike_count: number;
  view: number;
  score: number;
  created_at: string;
  updated_at: string;
  tag_list: string[];
  view_badge: ViewBadge;
  question_badge: QuestionBadge;
  user_profile: UserProfile;
  answer_count: number;
  current_user_liked: number;
  ayq_answers: AyqAnswers;
}

export interface ViewBadge {
  data: string | number;
}

export interface QuestionBadge {
  data: string | number;
}

export interface UserProfile {
  data: Data;
}

export interface Data {
  id: string;
  type: string;
  attributes: Attributes2;
}

export interface Attributes2 {
  name: string;
  first_name?: string;
  last_name?: string;
  phone_number: number;
  email: string;
  city: string | number;
  status: string;
  myrank_id: string;
  image: string;
  uuid: string | number;
  course_notification_enable: boolean;
  exam_notification_enable: boolean;
  package: Package[];
}

export interface Package {
  id: number;
  account_id: number;
  pack_final_id: number;
  pack_pricing_id: number;
  packfinal_feature_id: string | number;
  ayq_level: number;
  lc_level: number;
  wb_level: number;
  bms_level: number;
  tution_level: number;
  acat_apply: number;
  bcat_apply: number;
  actual_price: number;
  amount: number;
  validity?: string;
  expiry_date: string;
  sales_manager_name: string | number;
  agent1_name: string | number;
  agent2_name: string | number;
  transaction_type: string;
  status: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  sms_level: string | number;
  active: boolean;
}

export interface AyqAnswers {
  data: Daum2[];
}

export interface Daum2 {
  id: string;
  type: string;
  attributes: Attributes3;
}

export interface Attributes3 {
  account_id: number;
  ayq_question_id: number;
  answer: string;
  like_count: number;
  dislike_count: number;
  score: number;
  is_correct: boolean;
  created_at: string;
  updated_at: string;
  answer_badge: AnswerBadge;
  user_profile: UserProfile2;
  current_user_liked: number;
  comments: Comments;
  ayq_question: AyqQuestion;
}

export interface AnswerBadge {
  data: string | number;
}

export interface UserProfile2 {
  data: Data2;
}

export interface Data2 {
  id: string;
  type: string;
  attributes: Attributes4;
}

export interface Attributes4 {
  name: string;
  first_name: string;
  last_name: string;
  phone_number: number;
  email: string;
  city: string | number;
  status: string;
  myrank_id: string;
  image: string;
  uuid: string | number;
  course_notification_enable: boolean;
  exam_notification_enable: boolean;
  package: Package2[];
}

export interface Package2 {
  id: number;
  account_id: number;
  pack_final_id: number;
  pack_pricing_id: number;
  sales_manager_name: string | number;
  agent1_name: string | number;
  agent2_name: string | number;
  transaction_type: string;
  status: string;
  packfinal_feature_id: string | number;
  updated_at: string;
  ayq_level: number;
  lc_level: number;
  wb_level: number;
  validity: string;
  bms_level: number;
  tution_level: number;
  acat_apply: number;
  actual_price: number;
  amount: number;
  expiry_date: string;
  created_by: string;
  created_at: string;
  sms_level: string | number;
  active: boolean;
  bcat_apply: number;
}

export interface Comments {
  data: Daum3[];
}

export interface Daum3 {
  id: string;
  type: string;
  attributes: Attributes5;
}

export interface Attributes5 {
  id: number;
  account_id: number;
  commentable_id: number;
  commentable_type: string;
  comment: string;
  created_at: string;
  updated_at: string;
  liked: boolean;
  account: Account;
}

export interface Account {
  id: number;
  name: string;
  email: string;
  myrank_id: string;
  image: string;
}

export interface AyqQuestion {
  id: number;
  account_id: number;
  question: string;
  like_count: number;
  dislike_count: number;
  created_at: string;
  updated_at: string;
  subject_name: string;
  topic_name: string;
  score: number;
  view: number;
  tag_list: string[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ayqQuestions: Daum[];
  currentIndex: number;
  search: string;
  modalCurrentFilter: boolean;
  filter: string;
  token: string;
  loading: boolean;
  isNotLoggedIn: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AYQQuestionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAyqQuestionsMessageId = "";
  viewAyqQuestionMessageId = "";
  questionIdView = "";
  getAyqSearchMessageId = "";
  searchTimeout: NodeJS.Timeout | null = null;
  getAyqFilterMessageId = "";
  likeDislikeMessageId = "";
  indexOfLikeDislike: { index: number; status: number } = {
    index: Infinity,
    status: Infinity,
  };
  breadcrumb = [
    {
      label: "Questions",
      link: "/dashboard/ask-your-query/questions",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      ayqQuestions: [],
      currentIndex: 1,
      search: "",
      modalCurrentFilter: false,
      filter: "",
      token: "",
      isNotLoggedIn: false,
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      apiRequestCallId === this.getAyqQuestionsMessageId ||
      apiRequestCallId === this.getAyqSearchMessageId ||
      apiRequestCallId === this.getAyqFilterMessageId
    ) {
      if (response?.data) {
        this.handleSuccessfulFetchQuestions(response.data);
      } else {
        this.handleFailFetchQuestions();
      }
    }

    if (apiRequestCallId === this.viewAyqQuestionMessageId) {
      this.handleSuccessfulView(this.questionIdView);
    }

    if (apiRequestCallId === this.likeDislikeMessageId && !response.errors) {
      if (response.message) {
        this.handleLikeDislike();
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingAQC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingAQC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingAQC);
    const windowUrl = window.location.href;
    const urlParams = new URLSearchParams(windowUrl.split("?")[1]);
    const query = urlParams.get("query");
    const filter = urlParams.get("filter");
    const param = this.props.navigation.getParam("navigationCurr");
    if (query) {
      this.handleSearch(query);
    } else if (filter) {
      this.handleFilter(filter);
      this.filterQuestion();
    } else {
      if (param === "1" && query) {
        this.getAyqQuestions("top", Number(param));
      } else if (param === "2") {
        this.getAyqQuestions("hot", Number(param));
      } else if (param === "3") {
        this.getAyqQuestions("week", Number(param));
      } else if (param === "4") {
        this.getAyqQuestions("month", Number(param));
      } else if (param === "0") {
        this.getAyqQuestions("myqueries", 0);
      } else {
        this.getAyqQuestions("top", 1);
      }
    }

    this.handleToken();
  };

  handleToken = async () => {
    const token = await storage.get("authToken");
    this.setState({
      token,
    });
  };

  handleAyqLoginModal = () => {
    this.setState({
      isNotLoggedIn: !this.state.isNotLoggedIn,
    });
  };

  navigateToLogin = () => {
    this.props.navigation.history.push("/ask-your-query/login");
  };

  handleLikeDislike = () => {
    const questions = this.state.ayqQuestions;
    const question = questions[this.indexOfLikeDislike.index];

    if (this.indexOfLikeDislike.status === 1) {
      question.attributes.like_count +=
        question.attributes.current_user_liked === 1 ? -1 : 1;
      question.attributes.dislike_count +=
        question.attributes.current_user_liked === -1 ? -1 : 0;
      question.attributes.current_user_liked =
        question.attributes.current_user_liked === 1 ? 0 : 1;
    } else if (this.indexOfLikeDislike.status === -1) {
      question.attributes.dislike_count +=
        question.attributes.current_user_liked === -1 ? -1 : 1;
      question.attributes.like_count +=
        question.attributes.current_user_liked === 1 ? -1 : 0;
      question.attributes.current_user_liked =
        question.attributes.current_user_liked === -1 ? 0 : -1;
    }

    this.setState({
      ayqQuestions: questions,
    });
  };

  handleFilter = (filter: string) => {
    this.setState({
      filter,
    });
  };

  handleModalFilterCurrent = () => {
    this.setState({
      modalCurrentFilter: !this.state.modalCurrentFilter,
    });
  };

  goToProfile = (dataId: string) => {
    this.props.navigation.navigate("AskYourQueryProfilePeek", {
      profileId: dataId,
    });
  };

  handleSuccessfulView = (questionId: string) => {
    this.props.navigation.navigate("AskYourQueryQuestionAnswer", {
      questionId,
    });
  };

  handleSearch = (search: string) => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.setState(
      {
        search,
      },
      () => {
        this.searchTimeout = setTimeout(() => {
          this.searchData(search);
        }, 600);
      }
    );
  };

  handleSuccessfulFetchQuestions = (data: Daum[]) => {
    this.setState({
      ayqQuestions: data,
      modalCurrentFilter: false,
      filter: "",
    });
  };

  handleFailFetchQuestions = () => {
    this.setState({
      ayqQuestions: [],
    });
  };

  setActiveLink = (index: number, type: string) => {
    this.getAyqQuestions(type.toLowerCase());
    this.setState({
      currentIndex: index,
    });
  };

  getTimeDifference = (timestampStr: string): string => {
    const timestamp = new Date(timestampStr);
    const relativeTime = formatDistanceToNow(timestamp, { addSuffix: true });
    return relativeTime;
  };

  navigateToAskQuestion = () => {
    this.props.navigation.navigate("AskYourQueryQuestionToAsk");
  };

  // Customizable Area Start
  getAyqQuestions = async (type: string, index?: number) => {
    if (index !== undefined) {
      if (index >= 0) {
        this.setState({
          currentIndex: index,
        });
      }
    }

    const token = await storage.get("authToken");

    const headers = {
      token,
    };

    this.setState({
      loading: true,
    });

    const getAyqQuestionsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAyqQuestionsMessageId = getAyqQuestionsMessage.messageId;

    if (token) {
      getAyqQuestionsMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
    }

    getAyqQuestionsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    if (type === "myqueries") {
      getAyqQuestionsMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_communityforum/ayq_questions/user_ayq_question`
      );
    } else {
      getAyqQuestionsMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_communityforum/ayq_questions?data_by=${type}`
      );
    }

    runEngine.sendMessage(getAyqQuestionsMessage.id, getAyqQuestionsMessage);
  };

  viewAyqQuestion = async (questionId: string) => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    this.setState({
      loading: true,
    });

    const viewAyqQuestionsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewAyqQuestionMessageId = viewAyqQuestionsMessage.messageId;
    this.questionIdView = questionId;

    viewAyqQuestionsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    viewAyqQuestionsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    viewAyqQuestionsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/${questionId}/view`
    );

    runEngine.sendMessage(viewAyqQuestionsMessage.id, viewAyqQuestionsMessage);
  };

  searchData = async (search: string) => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getAyqSearchMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAyqSearchMessageId = getAyqSearchMessage.messageId;

    getAyqSearchMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAyqSearchMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getAyqSearchMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/search?search_text=${search}`
    );

    runEngine.sendMessage(getAyqSearchMessage.id, getAyqSearchMessage);
  };

  filterQuestion = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getAyqFilterMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAyqFilterMessageId = getAyqFilterMessage.messageId;

    getAyqFilterMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAyqFilterMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getAyqFilterMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/search_by_tag?tag=${this.state.filter}`
    );

    runEngine.sendMessage(getAyqFilterMessage.id, getAyqFilterMessage);
  };

  likeDislike = async (status: number, index: number) => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    if (!this.state.token) {
      return this.handleAyqLoginModal();
    }

    this.setState({
      loading: true,
    });

    this.indexOfLikeDislike = { index, status };

    const likeDislikeMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeDislikeMessageId = likeDislikeMessage.messageId;

    likeDislikeMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    likeDislikeMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    likeDislikeMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/create_ayq_like?ayq_question_id=${this.state.ayqQuestions[index].id}&status=${status}`
    );

    runEngine.sendMessage(likeDislikeMessage.id, likeDislikeMessage);
  };
  // Customizable Area End
}
