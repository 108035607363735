import React from "react";

import {
  Box,
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { CheckCircle, Cancel } from "@material-ui/icons";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";

export default class PackagePricingSelection extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className="signup-package-pricing">
        <Box className="packpricing">
          {this.props.package?.attributes.pack_pricing.data.map(
            (packPricing, packPricingIndex) => {
              return (
                <Box
                  key={`${packPricingIndex}-pricing`}
                  className="pricing-div"
                >
                  <p>{`${packPricing.attributes.feature_name} - Rs ${packPricing.attributes.price}/Year`}</p>
                  <hr />
                  <ul style={webStyle.ulStyle}>
                    {
                      packPricing.attributes.pack_highlights.data.map(highlight=>{
                        const removeTags = highlight.attributes.category_value.replace(/<i[^>]*>.*?<\/i>/g, '');

                        return <li key={`${highlight.id}-li-ulStyle`} style={webStyle.liStyle}><CheckCircle />{removeTags}</li>
                      })
                    }
                  </ul>
                  <Button
                    data-test-id="setPricingBtn"
                    onClick={() =>
                      this.props.setPackageChosen &&
                      this.props.chosenPackageId &&
                      this.props.setPackageChosen(
                        this.props.chosenPackageId,
                        packPricing.id,
                        packPricingIndex
                      )
                    }
                  >
                    Purchase
                  </Button>
                </Box>
              );
            }
          )}
        </Box>
        <table className="datatable">
          <thead>
            <tr className="main-tr">
              <th>Pay Yearly</th>
              <th>Basic</th>
              <th>Premium</th>
              <th>Live Classes</th>
            </tr>
          </thead>
          <tbody>
            {this.tableData.map((data, index) => {
              return (
                <tr key={`tr-table-${index}`} className="data-tr">
                  <td>{data.name}</td>
                  <td>{data.basic ? <CheckCircle /> : <Cancel />}</td>
                  <td>{data.premium ? <CheckCircle /> : <Cancel />}</td>
                  <td>{data.live ? <CheckCircle /> : <Cancel />}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  ulStyle: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    gap: "1em",
    marginTop: "1em",
  },
  liStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 1em",
    gap: "0.3em",
  }
}
// Customizable Area End
