import React from "react";

import {
  Box,
  // Customizable Area Start
  Typography as MUITypography,
  Modal,
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Footer from "../../../components/src/Footer.web";
import WebRoutesGenerator from "../../../components/src/NativeWebRouteWrapper";
import { routeMapDashboardCM } from "../../../web/src/App";
import Button from "../../../components/src/Button.web";
import MenuIcon from "@material-ui/icons/Menu";
import { computer, myranklogo, calculator, sms, calendar } from "./assets";
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AccountCircle from "@material-ui/icons/AccountCircle";
// Customizable Area End

import DashboardCMController, { Props } from "./DashboardCMController.web";
import { webStyle } from "./Dashboard.web";

export class DashboardCM extends DashboardCMController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box data-test-id="home-screen">
        <Box className={this.props.classes.dashboard}>
          <Box
            className="dashboard-header"
            component={"header"}
            alignItems={"center"}
            boxShadow={"1px 1px 1px gray"}
            padding="0 3em"
            display="flex"
            justifyContent={"space-between"}
          >
            <Box className="header-nav">
              <img
                data-test-id="navigate-to-landing-page"
                src={myranklogo}
                alt="logomyrank"
                height={"39px"}
                onClick={() => this.navigateTo("/")}
              />
              <MenuIcon
                className="menu-icon"
                data-test-id="sidebar-btn"
                onClick={() => this.setShowSidebar()}
              />
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              gridGap={"5em"}
              position={"relative"}
              justifyContent={"center"}
            >
              <Box
                width={"170px"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                display={"flex"}
              >
                <img
                  style={{ cursor: "pointer" }}
                  src={calculator}
                  data-test-id="navigate-calculate-btn"
                  onClick={() => this.navigateTo("/dashboard-cm/calculate")}
                  width={"23px"}
                  alt="calculator"
                />
                <img
                  src={sms}
                  alt="sms"
                  width={"30px"}
                  style={{ cursor: "pointer" }}
                />
                <img
                  alt="calendar"
                  style={{ cursor: "pointer" }}
                  src={calendar}
                  width={"23px"}
                />
              </Box>
              <Box
                style={{ cursor: "pointer" }}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                data-test-id="toggle-profile-popup"
                onClick={() => this.toggleProfilePopUp()}
              >
                {this.state.userData?.attributes.image ? (
                  <img
                    style={{ borderRadius: "50%", width: "30px" }}
                    src={this.state.userData.attributes.image}
                  />
                ) : (
                  <AccountCircle className={this.props.classes.accountImg} />
                )}
                <MUITypography variant="h6">
                  {this.state.userData?.attributes.name}
                </MUITypography>
                <span style={{ left: "-5px", position: "relative" }}>
                  <ArrowDropDown style={{ color: "#949497" }} />
                </span>
              </Box>
              <Box
                className={
                  this.state.profilePopup
                    ? this.props.classes.profilePopUp
                    : this.props.classes.profilePopUpHidden
                }
              >
                <Box
                  className={this.props.classes.profilePopUpTypo}
                  data-test-id="navigate-to-packages"
                  height={"50%"}
                  onClick={() => {
                    this.navigateTo("/dashboard-cm/packages");
                    this.toggleProfilePopUp();
                  }}
                >
                  <MUITypography>My Registration</MUITypography>
                </Box>
                <Box
                  height={"50%"}
                  className={this.props.classes.profilePopUpTypo}
                  onClick={this.logout}
                >
                  <MUITypography>Sign Out</MUITypography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box component="main" className="dashboard-main">
            <Sidebar
              className={`dashboard-sidebar`}
              rootStyles={{
                [`.${sidebarClasses.container}`]: {
                  backgroundColor: "inherit",
                  minHeight: "97vh",
                  position: "relative",
                },
              }}
              collapsed={!this.state.showSidebar}
              collapsedWidth="0"
              data-test-id="sidebar-container"
            >
              <Menu
                rootStyles={{
                  paddingBottom: "15em",
                  ".ps-menu-button:hover": {
                    background: "inherit",
                  },
                }}
              >
                <MenuItem
                  icon={<img src={computer} className="nav-icon" />}
                  onClick={() => this.navigateTo(`/dashboard-cm`)}
                  className={this.determineIfActiveLink("/dashboard-cm")}
                  data-test-id="navigate-home"
                >
                  Home
                </MenuItem>
                <MenuItem
                  icon={<img src={computer} className="nav-icon" />}
                  onClick={() => this.navigateTo(`/dashboard-cm/appointments`)}
                  className={this.determineIfActiveLink(
                    "/dashboard-cm/appointments"
                  )}
                  data-test-id="navigate-appointment"
                >
                  Appointments
                </MenuItem>
                <MenuItem
                  icon={<img src={computer} className="nav-icon" />}
                  onClick={() => this.navigateTo(`/dashboard-cm/slots`)}
                  data-test-id="navigate-slots"
                  className={this.determineIfActiveLink("/dashboard-cm/slots")}
                >
                  Slots
                </MenuItem>
              </Menu>
              <Menu
                rootStyles={{
                  ".ps-menu-button:hover": {
                    background: "inherit",
                  },
                }}
                className={this.props.classes.logoutStyle}
              >
                <MenuItem
                  onClick={() => this.logout()}
                  icon={<ExitToAppIcon className="nav-icon" />}
                  data-test-id="logout-btn-clear"
                >
                  Logout
                </MenuItem>
              </Menu>
            </Sidebar>
            <Box
              component={"section"}
              data-test-id="home-section"
              className="dashboard-content"
            >
              {WebRoutesGenerator({ routeMap: routeMapDashboardCM })}
            </Box>
          </Box>
        </Box>
        {!this.props.navigation.history.location.pathname.includes(
          "live-classes"
        ) && <Footer />}
        <Modal
          open={this.state.isModalOpenLogout}
          aria-labelledby="simple-modal-title"
          onClose={this.handleOpenModal}
          aria-describedby="simple-modal-description"
        >
          <Box className={this.props.classes.modalStyle}>
            <HighlightOffIcon
              data-test-id="close-modal"
              onClick={this.handleOpenModal}
              style={{
                position: "absolute",
                top: "10px",
                cursor: "pointer",
                right: "10px",
                fontSize: "2rem",
                color: "gray",
              }}
            />
            <p>You Successfully Logged Out</p>
            <Box data-test-id="logout-btn" onClick={() => this.navigateTo("/")}>
              <Button
                key={"buttonKey"}
                width="200px"
                color="white"
                height="50px"
                title="Go Back To Landing Page"
              />
            </Box>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(webStyle)(DashboardCM);
// Customizable Area End
