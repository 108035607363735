import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { ISlotDetails } from "./BookMySlotChangeSlotController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  slotId: string;
  slotDetails: ISlotDetails | null;
  starsIndex: number;
  selectedChoice: string;
  comments: string;
  isSlotUpdatedModal: boolean;
  isEditable: boolean;
  isErrorModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotFeedbacksController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSlotOfIdMessageId = "";
  postUpdateMessageId = "";
  status = [
    { value: "first_presentation", label: "First Presentation" },
    { value: "follow_up", label: "Follow up" },
    { value: "rejected", label: "Rejected" },
    { value: "registered", label: "Registration Done" },
  ];
  breadcrumb = [
    {
      link: `/dashboard/book-my-slot`,
      label: "Book my Slot",
    },
    {
      link: `/dashboard/book-my-slot`,
      label: "Slots",
    },
    {
      link: `/dashboard/book-my-slot/slot-bookings`,
      label: "Slot Bookings",
    },
    {
      label: "Feedback",
      link:
        this.props.navigation.history.location.pathname +
        this.props.navigation.history.location.search,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      slotId: "",
      slotDetails: null,
      starsIndex: -1,
      selectedChoice: "follow_up",
      comments: "",
      isSlotUpdatedModal: false,
      isEditable: false,
      isErrorModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSlotOfIdMessageId) {
      if (response?.data) {
        this.successFetch(response.data);
      }
    }

    if (apiRequestCallId === this.postUpdateMessageId) {
      if (response?.meta) {
        this.successPostMessage();
      }

      if (response?.errors) {
        this.showErrorModal();
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const search = this.props.navigation.history.location.search;
    const params = new URLSearchParams(search);
    const propPassingFeedback = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingFeedback.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingFeedback);
    if (search) {
      const slotId = params.get("slotId") as string;
      const isEditable = Boolean(params.get("editable"));

      return this.getSlotOfId(slotId, isEditable);
    }

    this.props.navigation.history.goBack();
  };

  setComments = (comments: string) => {
    this.setState({
      comments,
    });
  };

  showErrorModal = () => {
    this.setState({
      isErrorModal: !this.state.isErrorModal,
      loading: false,
    });
  };

  handleRatedStars = (index: number) => {
    this.setState((prev) => ({
      ...prev,
      starsIndex: index,
    }));
  };

  successFetch = (slotDetails: ISlotDetails) => {
    let isFirstPresentation = false;
    if (slotDetails.attributes?.status === "first_presentation") {
      isFirstPresentation = true;
    }

    this.setState({
      slotDetails,
      comments: slotDetails.attributes.remarks,
      selectedChoice: isFirstPresentation
        ? this.state.selectedChoice
        : slotDetails.attributes.status,
      loading: false,
      starsIndex: slotDetails.attributes.rating
        ? slotDetails.attributes.rating as number
        : 0,
    });
  };

  // Customizable Area Start
  successPostMessage = () => {
    this.handleIsSlotUpdatedModal();
  };

  onChangeSelect = (selectedChoice: string) => {
    this.setState({
      selectedChoice,
    });
  };

  handleIsSlotUpdatedModal = () => {
    this.setState({
      isSlotUpdatedModal: !this.state.isSlotUpdatedModal,
      loading: false,
    });
  };

  getSlotOfId = async (slotId: string, isEditable?: boolean) => {
    const getSlotOfIdMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
      slotId,
      isEditable: Boolean(isEditable),
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getSlotOfIdMessageId = getSlotOfIdMessage.messageId;

    getSlotOfIdMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/cms_appoinments/${slotId}`
    );

    getSlotOfIdMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSlotOfIdMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSlotOfIdMessage.id, getSlotOfIdMessage);
  };

  postUpdate = async () => {
    const postUpdateMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const body = {
      data: {
        attributes: {
          rating: this.state.starsIndex + 1,
          remarks: this.state.comments,
          call_status: this.state.selectedChoice,
        },
      },
    };

    this.postUpdateMessageId = postUpdateMessage.messageId;

    postUpdateMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_reviewprompt/${this.state.slotId}/feedback`
    );

    postUpdateMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    postUpdateMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    postUpdateMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(postUpdateMessage.id, postUpdateMessage);
  };

  navigateToBookings = () => {
    this.props.navigation.history.push("/dashboard/book-my-slot/slot-bookings");
  };
  // Customizable Area End
}
