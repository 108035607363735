import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string | undefined;
  // Customizable Area Start
  chosenPackageId?: string;
  chosenPackageIndex?: number;
  package?: PackFinal | null;
  allPackages?: PackFinal[];
  getPackageDetails?: (packageId: string) => void;
  setPackageChosen?: (
    packageId: string,
    packPricingId: string,
    packPricingIndex: number
  ) => void;
  chosenPackageToken?: string;
  // Customizable Area End
}

interface PackHighlight {
  id: string;
  type: string;
  attributes: {
    id: number;
    category: string;
    category_value: string;
    highlight: string;
  };
}

interface PackPricing {
  id: string;
  type: string;
  attributes: {
    id: number;
    feature_name: string;
    description: string;
    status_admin: string;
    status_online: string;
    status_sm: string;
    ayq: number;
    lc: number;
    wb: number;
    sms: string;
    apply_a: string;
    apply_b: string;
    bms: string;
    tuition: string;
    display_order: number;
    highlight: number;
    price: string;
    pack_highlights: {
      data: PackHighlight[];
    };
  };
}

interface PackFinalFeature {
  id: string;
  type: string;
  attributes: {
    id: number;
    pack_final_id: number;
    feature: string;
    subject: string;
    icon_code: string;
    status: string;
  };
}

interface Package {
  id: string;
  type: string;
  attributes: {
    id: number;
    package_name: string;
    package_description: string;
    status: string;
    subject_id: string;
    class_zone: string;
    practice_test: string;
    subjective_test: string;
    gt_code: string;
    question_bank: string;
    created_by: string;
  };
}

export interface PackFinal {
  id: string;
  type: string;
  attributes: {
    id: number;
    image: string;
    pack_name: string;
    pack_description: string;
    status: string;
    ayq: string;
    lc: string;
    wb: string;
    bms: string;
    tuition: string;
    pack_pricing: {
      data: PackPricing[];
    };
    packfinal_features: {
      data: PackFinalFeature[];
    };
    package: {
      data: Package[];
    };
  };
}

interface S {
  // Customizable Area Start
  activeStep: number;
  allPackagesData: PackFinal[];
  currentChosenPackageId: string;
  currentChosenPackagePricingId: string;
  currentPackageChosen: PackFinal | null;
  chosenPackageToken: string;
  userInput: {
    name: string;
    email: string;
    emailOtp: string;
    mobileNumber: string;
    numberOtp: string;
    password: string;
    confirmPassword: string;
    validity: string;
    paymentMode: string;
  };
  currentPricingIndex: number;
  mobileOTPToken: string;
  emailOTPToken: string;
  isOtpMobileVerified: boolean;
  isOtpEmailVerified: boolean;
  isOtpMobileError: boolean;
  isOtpEmailError: boolean;
  checkedTerms: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  isPaymentSuccessful: boolean;
  isPaymentFailure: boolean;
  isPasswordValid: boolean | null;
  isConfirmPasswordValid: boolean | null;
  isConfirmTheSameAsPassword: boolean | null;
  terms: any;
  modalTermsOpen: boolean;
  modalPaymentOpen: boolean;
  paymentLink: string;
  myrankIdGenerated: string;
  dates: { multiplier: number; date: string }[];
  multiplier: number;
  modalErrorRegisterOpen: boolean;
  modalEmailExistOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  steps: string[] = ["CHOOSE PACKAGE", "PRICING", "REGISTRATION", "PAYMENT"];
  getPackagesDocumentId: string = "";
  getPackageDetailsDocumentId: string = "";
  setPackageChosenDocumentId: string = "";
  signUpDocumentId: string = "";
  otpPostDocumentId: string = "";
  resendOtpPostDocumentId: string = "";
  emailOTPDocumentId: string = "";
  verifyMobileOtpPostDocumentId: string = "";
  verifyEmailOtpPostDocumentId: string = "";
  signUpPostDocumentId: string = "";
  termsDocumentId: string = "";
  postPaymentSuccessDocumentId: string = "";
  googleAuthId: string = "";
  googleEmail: string = "";
  tableData = [
    {
      name: "Study materials",
      basic: false,
      premium: false,
      live: true,
    },
    {
      name: "Question Bank",
      basic: true,
      premium: true,
      live: true,
    },
    {
      name: "Guests",
      basic: true,
      premium: true,
      live: true,
    },
    {
      name: "Study materials",
      basic: true,
      premium: true,
      live: true,
    },
    {
      name: "Mock tests",
      basic: true,
      premium: true,
      live: true,
    },
    {
      name: "Previous Year papers",
      basic: false,
      premium: false,
      live: false,
    },
    {
      name: "SMS Information",
      basic: true,
      premium: true,
      live: true,
    },
    {
      name: "Applicant Support",
      basic: false,
      premium: false,
      live: false,
    },
    {
      name: "Counselling Guidance",
      basic: false,
      premium: false,
      live: true,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeStep: 0,
      allPackagesData: [],
      currentChosenPackageId: "",
      currentPackageChosen: null,
      currentChosenPackagePricingId: "",
      chosenPackageToken: "",
      userInput: {
        name: "",
        email: "",
        emailOtp: "",
        mobileNumber: "",
        numberOtp: "",
        password: "",
        confirmPassword: "",
        validity: "",
        paymentMode: "",
      },
      currentPricingIndex: Infinity,
      emailOTPToken: "",
      mobileOTPToken: "",
      isOtpMobileVerified: false,
      isOtpEmailVerified: false,
      isOtpMobileError: false,
      isOtpEmailError: false,
      checkedTerms: false,
      showConfirmPassword: false,
      showPassword: false,
      isPaymentSuccessful: false,
      isPaymentFailure: false,
      isPasswordValid: null,
      isConfirmPasswordValid: null,
      isConfirmTheSameAsPassword: null,
      terms: "",
      modalTermsOpen: false,
      modalPaymentOpen: false,
      paymentLink: "",
      myrankIdGenerated: "",
      dates: [],
      multiplier: 1,
      modalErrorRegisterOpen: false,
      modalEmailExistOpen: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.postPaymentSuccessDocumentId:
        this.handlePaymentSuccess(responseJson);
        break;

      case this.setPackageChosenDocumentId:
        this.handlePackageChosenDocumentResponse(responseJson);
        break;

      case this.getPackageDetailsDocumentId:
        this.handlePackageDetailsDocumentResponse(responseJson);
        break;

      case this.emailOTPDocumentId:
        this.handleEmailOTPDocumentResponse(responseJson);
        break;
      case this.verifyEmailOtpPostDocumentId:
        this.handleVerifyEmailOTPPostDocumentResponse(responseJson);
        break;

      case this.termsDocumentId:
        this.handleTerms(responseJson);
        break;

      case this.getPackagesDocumentId:
        this.handlePackagesDocumentResponse(responseJson);
        break;

      case this.verifyMobileOtpPostDocumentId:
        this.handleVerifyMobileOTPPostDocumentResponse(responseJson);
        break;

      case this.otpPostDocumentId:
        this.handleOTPPostDocumentResponse(responseJson);
        break;

      case this.signUpPostDocumentId:
        this.handleToPayment(responseJson);
        break;

      case this.signUpDocumentId:
        this.handleSignUpResponse(responseJson);
        break;

      default:
        break;
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.addDatesForTheNextThreeYears();
    this.getTermsAndCondition();
    this.checkGoogleSignUp();

    if (this.props.id === undefined) {
      this.handleLocalStorageData();

      if (this.state.activeStep === 0) {
        this.getAllPackages();
      }
    }
  };

  checkGoogleSignUp = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (params) {
      this.googleAuthId = params.get("googleAuthId") as string;
      this.googleEmail = params.get("googleEmail") as string;
      if (this.googleAuthId && this.googleEmail) {
        this.updateEmailState();
      }
    }
  };

  updateEmailState = () => {
    this.setState({
      userInput: {
        ...this.state.userInput,
        email: this.googleEmail,
      },
      isOtpEmailVerified: true,
      emailOTPToken: "token",
    });
  };

  addDatesForTheNextThreeYears = () => {
    const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const dates = [];

  for (let i = 0; i < 3; i++) {
    const year = currentYear + i;
    const isPastAugust31 = currentMonth > 8;
    const formattedFutureDate = isPastAugust31 ? `31-08-${year + 1}` : `31-08-${year}`;
    dates.push({ multiplier: i + 1, date: formattedFutureDate });
  }

  this.handleValidityDates(dates);
  };

  handleLocalStorageData = async () => {
    const registerToken = await storage.get("registerToken");
    const link = await storage.get("registerLink");
    const paymentFail = await storage.get("paymentFail");

    if (link) {
      this.handlePaymentLink(link);
    }

    this.checkPaymentStatus(registerToken, paymentFail);
  };

  checkPaymentStatus = (
    registerToken: string | null,
    paymentFail: string | null
  ) => {
    if (registerToken && this.state.activeStep === 0) {
      const windowUrl = window.location.href;
      const urlParams = new URLSearchParams(windowUrl.split("?")[1]);
      const paymentStatus = urlParams.get("status");

      this.handlePaymentSuccessBrief(registerToken, paymentStatus);
      this.handlePaymentFailure(paymentFail, paymentStatus);
    }
  };

  handlePaymentSuccessBrief = (
    registerToken: string | null,
    paymentStatus: string | null
  ) => {
    if (paymentStatus === "success" && registerToken) {
      const { token, id } = JSON.parse(registerToken);
      this.updatePaymentToRegister(token, id);
    }
  };

  handlePaymentFailure = (
    paymentFail: string | null,
    paymentStatus: string | null
  ) => {
    if (
      paymentStatus === "fail" ||
      (paymentFail === "true" && paymentStatus !== "success")
    ) {
      this.handlePaymentFail();
    }
  };

  handleCloseErrorEmailExistModal = () => {
    this.setState({
      modalEmailExistOpen: false,
    });
  };

  handleCloseErrorRegisterModal = () => {
    this.setState({
      modalErrorRegisterOpen: false,
    });
  };

  handleValidityDates = (dates: { multiplier: number; date: string }[]) => {
    this.setState({
      dates,
    });
  };

  handleChangeInput(
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    const { name, value } = event.target;

    if (!name) return;

    const passwordPattern = /^.{4,}$/;
    const valueAsString: string = value as string;

    const updatedUserInput = {
      ...this.state.userInput,
      [name]: valueAsString,
    };

    this.setState({ userInput: updatedUserInput });

    // Define checks based on updated state.
    const isPasswordValid =
      updatedUserInput.password.length === 0
        ? null
        : passwordPattern.test(updatedUserInput.password);
    const isConfirmPasswordValid =
      updatedUserInput.confirmPassword.length === 0
        ? null
        : passwordPattern.test(updatedUserInput.confirmPassword);
    const isConfirmTheSameAsPassword =
      updatedUserInput.password === updatedUserInput.confirmPassword;

    this.setState({
      isPasswordValid,
      isConfirmPasswordValid,
      isConfirmTheSameAsPassword,
    });
  }

  handleChangeDateMultiplier = (multiplier: number) => {
    this.setState({
      multiplier: multiplier,
    });
  };

  handlePaymentSuccess = (response: {
    data: { attributes: { myrank_id: string } };
  }) => {
    if (response.data) {
      localStorage.clear();

      this.setState({
        myrankIdGenerated: response.data.attributes.myrank_id,
        activeStep: 4,
        isPaymentSuccessful: true,
      });
    }
  };

  handleRegisterTruth = (...args: (boolean | null)[]) => {
    const trueRegister = args.every((truth) => truth === true);
    return trueRegister;
  };

  handleToPayment = async (responseJson: {
    data: { access_code: string; encrypt_request: string };
  }) => {
    if (responseJson.data) {
      const link = `https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&access_code=${responseJson.data.access_code}&encRequest=${responseJson.data.encrypt_request}`;
      await storage.set("registerLink", JSON.stringify(link));

      this.setState({
        paymentLink: link,
        modalPaymentOpen: true,
      });
    }
  };

  handleTerms = (responsJson: {
    terms_and_conditions: { content: string };
  }) => {
    if (responsJson?.terms_and_conditions) {
      this.setState({
        terms: responsJson.terms_and_conditions.content,
      });
    }
  };

  handlePackageDetailsDocumentResponse(responseJson: { data: PackFinal }) {
    if (responseJson && responseJson.data) {
      this.successfulFetchPackageData(responseJson.data);
    }
  }

  handleEmailOTPDocumentResponse(responseJson: {
    meta: { token: string };
    errors: { account: string }[];
  }) {
    if (responseJson && responseJson.errors) {
      this.failureOTPEmailSend();
    }

    if (responseJson && responseJson.meta) {
      this.successfulOTPEmailSend(responseJson.meta.token);
    }
  }

  handlePackagesDocumentResponse(responseJson: { data: PackFinal[] }) {
    if (responseJson && responseJson.data) {
      this.successfulAllPackageFetch(responseJson.data);
    }
  }

  handleCheckbox = () => {
    this.setState({
      checkedTerms: !this.state.checkedTerms,
    });
  };

  handleOTPPostDocumentResponse(responseJson: { meta: { token: string } }) {
    if (responseJson && responseJson.meta) {
      this.successfulOTPSend(responseJson.meta.token);
    }
  }

  handleCurrentPrice = (index: number | undefined) => {
    if (index || index === 0) {
      return this.props.package?.attributes.pack_pricing.data[index].attributes
        .price;
    }
    return 0;
  };

  handleVerifyEmailOTPPostDocumentResponse(responseJson: {
    meta: { token: string };
  }) {
    if (responseJson && responseJson.meta) {
      this.successfulOTPEmailVerify();
    } else {
      this.errorOtpEmailVerify();
    }
  }

  handleVerifyMobileOTPPostDocumentResponse(responseJson: {
    meta: { token: string };
  }) {
    if (responseJson && responseJson.meta) {
      this.successfulOTPVerify();
    } else {
      this.errorOtpVerify();
    }
  }

  failureOTPEmailSend = () => {
    this.setState({
      modalEmailExistOpen: true,
    });
  };

  successfulOTPSend = (token: string) => {
    this.setState({
      mobileOTPToken: token,
    });
  };

  successfulOTPEmailSend = (token: string) => {
    this.setState({
      emailOTPToken: token,
    });
  };

  successfulAllPackageFetch = (response: PackFinal[]) => {
    this.setState({
      allPackagesData: response,
    });
  };

  handleSignUpResponse = async(responseJson: {
    data: { id: string };
    meta: { token: string };
    errors: { email: string; phone_number: string }[];
  }) => {
    if (responseJson.data && responseJson.meta) {
      this.registerToPayment(responseJson.meta.token);
      await storage.set(
        "registerToken",
        JSON.stringify({
          id: responseJson.data.id,
          token: responseJson.meta.token,
        })
      );
    }

    if (responseJson.errors) {
      const errors = { email: false, number: false };
      responseJson.errors.forEach((error) => {
        if (error.email) {
          errors.email = true;
        }
        if (error.phone_number) {
          errors.number = true;
        }
      });

      this.handleErrorRegister(errors);
    }
  };

  handleErrorRegister = (errors: { email: boolean; number: boolean }) => {
    if (errors.email) {
      this.googleAuthId = "";
      this.googleEmail = "";
      this.setState({
        isOtpEmailVerified: false,
        userInput: {
          ...this.state.userInput,
          email: "",
          emailOtp: "",
        },
        emailOTPToken: "",
      });
    }
    if (errors.number) {
      this.setState({
        isOtpMobileVerified: false,
        mobileOTPToken: "",
        userInput: {
          ...this.state.userInput,
          mobileNumber: "",
          numberOtp: "",
        },
      });
    }

    this.setState({
      modalErrorRegisterOpen: true,
    });
  };

  handleGoToLogin = () => {
    this.props.navigation.navigate("Login");
  };

  handlePaymentLink = (link: string) => {
    if (link) {
      link = JSON.parse(link);
    }

    this.setState({
      paymentLink: link,
    });
  };

  handlePaymentFail = async () => {
    await storage.set("paymentFail", JSON.stringify(true));
    this.setState({
      isPaymentFailure: true,
      activeStep: 4,
    });
  };

  handlePaymentNo = () => {
    localStorage.clear();
    this.setState({
      paymentLink: "",
      activeStep: 0,
      isPaymentFailure: false,
    });
  };

  errorOtpVerify = () => {
    this.setState({
      isOtpMobileError: true,
    });
  };
  handlePackageChosenDocumentResponse(responseJson: { token: string }) {
    if (responseJson && responseJson.token) {
      this.sucessfulChosenPackage(responseJson.token);
    }
  }

  sucessfulChosenPackage = (token: string) => {
    this.handleNextStep();
    this.setState({
      chosenPackageToken: token,
    });
  };

  errorOtpEmailVerify = () => {
    this.setState({
      isOtpEmailError: true,
    });
  };

  successfulOTPVerify = () => {
    this.setState({
      isOtpMobileVerified: true,
    });
  };

  successfulOTPEmailVerify = () => {
    this.setState({
      isOtpEmailVerified: true,
    });
  };

  successfulFetchPackageData = (response: PackFinal) => {
    this.handleNextStep();
    this.setState({
      currentChosenPackageId: response.id,
      currentPackageChosen: response,
    });
  };

  // Customizable Area Start

  handleCloseModalTerms = () => {
    this.setState({
      modalTermsOpen: false,
    });
  };

  handleOpenModalTerms = () => {
    this.setState({
      modalTermsOpen: true,
    });
  };

  handleCloseModalPayment = () => {
    this.setState({
      modalPaymentOpen: false,
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  getValidity = () => {
    if(this.state.userInput.validity){
      return `Valid up to - 31st August ${this.state.userInput.validity.slice(-4)}`
    }
  }

  verifyEmailOTP = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.emailOTPToken,
    };

    const body = {
      data: {
        attributes: {
          pin: this.state.userInput.emailOtp,
        },
      },
    };

    const postVerifyEmailOTPMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyEmailOtpPostDocumentId = postVerifyEmailOTPMessage.messageId;

    postVerifyEmailOTPMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/verify_email`
    );

    postVerifyEmailOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    postVerifyEmailOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    postVerifyEmailOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(
      postVerifyEmailOTPMessage.id,
      postVerifyEmailOTPMessage
    );
  };

  handlePrevStep = () => {
    if (this.state.activeStep !== 0) {
      this.setState({
        activeStep: this.state.activeStep - 1,
      });
    } else {
      this.props.navigation.navigate("LandingPage");
    }
  };

  handleNextStep = () => {
    if (this.state.activeStep !== 4) {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };

  verifyMobileOTP = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.mobileOTPToken,
    };

    const body = {
      data: {
        attributes: {
          pin: this.state.userInput.numberOtp,
        },
      },
    };

    const verifyOtpMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyMobileOtpPostDocumentId = verifyOtpMessage.messageId;

    verifyOtpMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/verify_mobile_otp`
    );

    verifyOtpMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    verifyOtpMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    verifyOtpMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(verifyOtpMessage.id, verifyOtpMessage);
  };

  getTermsAndCondition = () => {
    const getTermsMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.termsDocumentId = getTermsMessage.messageId;

    getTermsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/terms_and_conditions`
    );

    getTermsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getTermsMessage.id, getTermsMessage);
  };

  getPackageDetails = (packageId: string) => {
    const getPackageDetailMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPackageDetailsDocumentId = getPackageDetailMessage.messageId;

    getPackageDetailMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_plan/pack_finals/${packageId}`
    );

    getPackageDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getPackageDetailMessage.id, getPackageDetailMessage);
  };

  sendEmailOTP = () => {
    const header = {
      "Content-Type": "application/json",
    };

    const body = {
      data: {
        attributes: {
          email: this.state.userInput.email,
        },
      },
    };

    const postEmailOTPMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailOTPDocumentId = postEmailOTPMessage.messageId;

    postEmailOTPMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/send_email_otp`
    );

    postEmailOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    postEmailOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    postEmailOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(postEmailOTPMessage.id, postEmailOTPMessage);
  };

  setPackageChosen = (
    packageId: string,
    packPricingId: string,
    packPricingIndex: number
  ) => {
    this.setState({
      currentChosenPackagePricingId: packPricingId,
      currentPricingIndex: packPricingIndex,
    });

    const header = {
      "Content-Type": "application/json",
    };

    const postSetPackageMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setPackageChosenDocumentId = postSetPackageMessage.messageId;

    postSetPackageMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `select_package?pack_final_id=${packageId}&pack_pricing_id=${packPricingId}`
    );

    postSetPackageMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    postSetPackageMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(postSetPackageMessage.id, postSetPackageMessage);
  };

  registerToPayment = (token: string) => {
    const header = {
      "Content-Type": "application/json",
    };

    const body = {
      online_account_token: token,
      type: "Web",
    };

    const postRegisterMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signUpPostDocumentId = postRegisterMessage.messageId;

    postRegisterMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_payments/ccavanues`
    );

    postRegisterMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    postRegisterMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    postRegisterMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(postRegisterMessage.id, postRegisterMessage);
  };

  isStepFinished = (index: number) => {
    return index < this.state.activeStep;
  };

  getAllPackages = () => {
    const getAllPackageMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPackagesDocumentId = getAllPackageMessage.messageId;

    getAllPackageMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_plan/pack_finals`
    );

    getAllPackageMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getAllPackageMessage.id, getAllPackageMessage);
  };

  sendOTP = () => {
    const header = {
      "Content-Type": "application/json",
    };

    const body = {
      data: {
        attributes: {
          full_phone_number: this.state.userInput.mobileNumber,
        },
      },
    };

    const postOtpMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.otpPostDocumentId = postOtpMessage.messageId;

    postOtpMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/send_sms_otp`
    );

    postOtpMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    postOtpMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    postOtpMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(postOtpMessage.id, postOtpMessage);
  };

  signUpUser = (event: React.FormEvent<HTMLFormElement>) => {
    interface Root {
      data: Data
    }
    
    interface Data {
      type: string
      attributes: Attributes
    }
    
    interface Attributes {
      name: string
      email: string
      phone_number: string
      password: string
      password_confirmation: string
      validity: string
      unique_auth_id?: string
      platform?: string
    }

    event.preventDefault();

    const header = {
      "Content-Type": "application/json",
      token: this.props.chosenPackageToken,
    };

    let body: Root = {
      data: {
        type: "sms_account",
        attributes: {
          name: this.state.userInput.name,
          email: this.state.userInput.email,
          phone_number: this.state.userInput.mobileNumber,
          password: this.state.userInput.password,
          password_confirmation: this.state.userInput.confirmPassword,
          validity: this.state.userInput.validity,
        },
      },
    };

    if (this.googleAuthId && this.googleEmail) {
      body = {
        data: {
          type: "social_account",
          attributes: {
            name: this.state.userInput.name,
            email: this.state.userInput.email,
            phone_number: this.state.userInput.mobileNumber,
            password: this.state.userInput.password,
            password_confirmation: this.state.userInput.confirmPassword,
            validity: this.state.userInput.validity,
            unique_auth_id: this.googleAuthId,
            platform: "google",
          },
        },
      };
    }

    const postSignupMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signUpDocumentId = postSignupMessage.messageId;

    postSignupMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts`
    );

    postSignupMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    postSignupMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    postSignupMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    runEngine.sendMessage(postSignupMessage.id, postSignupMessage);
  };

  updatePaymentToRegister = (token: string, id: string) => {
    this.setState(
      {
        activeStep: 3,
      },
      () => {
        const header = {
          "Content-Type": "application/json",
          token: token,
        };

        const postRegisterPaymentMessage: Message = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.postPaymentSuccessDocumentId =
          postRegisterPaymentMessage.messageId;

        postRegisterPaymentMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `account_block/accounts/${id}`
        );

        postRegisterPaymentMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PATCH"
        );

        postRegisterPaymentMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          header
        );

        runEngine.sendMessage(
          postRegisterPaymentMessage.id,
          postRegisterPaymentMessage
        );
      }
    );
  };
  // Customizable Area End
}
