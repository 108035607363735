import React from "react";

import {
  Box as BoxMUI,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// Customizable Area End

import AYQReceivedAnswersController, {
  Props,
} from "./AYQReceivedAnswersController.web";
import MathJaxBox from "../../../components/src/MathJaxBox.web";

class AYQReceivedAnswers extends AYQReceivedAnswersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const question = this.state.currentQuestion;
    return (
      // Customizable Area Start
      <BoxMUI>
        <BoxMUI className={this.props.classes.bannerStyle}>
          <Typography variant="h5">Received answers</Typography>
        </BoxMUI>
        <BoxMUI className={this.props.classes.receivedContainerStyle}>
          {question ? (
            <>
              <BoxMUI
                className={this.props.classes.questionBoxStyle}
                position={"relative"}
              >
                <BoxMUI
                  top="20px"
                  display={"flex"}
                  flexDirection={"column"}
                  position="absolute"
                  left="20px"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <ArrowDropUpIcon
                    data-test-id="like-btn-q"
                    onClick={() => this.likeDislikeQuestion(1)}
                    style={{
                      color:
                        question.attributes.current_user_liked === 1
                          ? "green"
                          : "#1f1f1f",
                      cursor: "pointer",
                    }}
                  />
                  <Typography variant="body1">
                    {question.attributes.like_count}
                  </Typography>
                  <ArrowDropDownIcon
                    data-test-id="dislike-btn-q"
                    style={{
                      color:
                        question.attributes.current_user_liked === -1
                          ? "red"
                          : "#1f1f1f",
                      cursor: "pointer",
                    }}
                    onClick={() => this.likeDislikeQuestion(-1)}
                  />
                </BoxMUI>
                <MathJaxBox content={question.attributes.question} />
                <BoxMUI className="main-wrapper">
                  <BoxMUI component={"ul"}>
                    {question.attributes.tag_list.map((tagList) => {
                      return (
                        <BoxMUI component={"li"} key={tagList}>
                          {tagList
                            .slice(0, 1)
                            .toUpperCase()
                            .concat(tagList.slice(1))}
                        </BoxMUI>
                      );
                    })}
                  </BoxMUI>
                  <BoxMUI
                    alignItems={"center"}
                    border={"1px solid rgb(254,110,82)"}
                    padding="0.5em"
                    width={"max-content"}
                    display={"flex"}
                    justifyContent={"center"}
                    marginTop="0.6em"
                    borderRadius={"0.2em"}
                    style={{ cursor: "pointer" }}
                  >
                    <CheckCircleIcon className="check-circle" />
                    <span>{question.attributes.answer_count} Answers</span>
                  </BoxMUI>
                  <BoxMUI
                    position={"absolute"}
                    right="25px"
                    bottom="20px"
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                    style={{ cursor: "pointer" }}
                    data-test-id="profile-btn"
                    onClick={() =>
                      this.goToProfile(
                        question.attributes.user_profile?.data.id
                      )
                    }
                  >
                    <img
                      src={
                        question.attributes.user_profile.data.attributes.image
                      }
                      alt="picture"
                      width={"25px"}
                      height={"25px"}
                    />
                    <span className="profile-span">
                      {question.attributes.user_profile.data.attributes.name}
                      <span style={{ marginLeft: "0.4em" }}>
                        {this.getTimeDifference(question.attributes.created_at)}
                      </span>
                    </span>
                  </BoxMUI>
                </BoxMUI>
              </BoxMUI>
              {question.attributes.ayq_answers.data.map((answer, index) => {
                return (
                  <BoxMUI
                    className={this.props.classes.questionBoxStyle}
                    position={"relative"}
                    key={`${index}-answer-${answer.id}`}
                  >
                    <BoxMUI
                      position="absolute"
                      left="20px"
                      top="20px"
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <ArrowDropUpIcon
                        data-test-id="click-up"
                        style={{
                          color:
                            answer.attributes.current_user_liked === 1
                              ? "green"
                              : "#1f1f1f",
                          cursor: "pointer",
                        }}
                        onClick={() => this.likeDislike(1, index)}
                      />
                      <Typography variant="body1">
                        {answer.attributes.like_count}
                      </Typography>
                      <ArrowDropDownIcon
                        style={{
                          color:
                            answer.attributes.current_user_liked === -1
                              ? "red"
                              : "#1f1f1f",
                          cursor: "pointer",
                        }}
                        data-test-id="click-down"
                        onClick={() => this.likeDislike(-1, index)}
                      />
                    </BoxMUI>
                    <BoxMUI className="main-wrapper">
                      <Typography
                        variant="body1"
                        style={{ color: "#60704B", fontWeight: 700 }}
                      >
                        Answer {index + 1}
                      </Typography>
                      <BoxMUI
                        dangerouslySetInnerHTML={{
                          __html: answer.attributes.answer,
                        }}
                        style={{ marginTop: "1em" }}
                      />
                      <Typography
                        variant="body1"
                        style={{ fontSize: "0.9em", marginTop: "2em" }}
                      >
                        Comments:
                      </Typography>
                      {answer.attributes.comments.data.map(
                        (comment, indexComment) => {
                          return (
                            <p
                              key={`${comment.type}-${indexComment}`}
                              style={{
                                fontSize: "1.1em",
                                marginLeft: "2em",
                              }}
                            >
                              <span>
                                <img
                                  height={"20px"}
                                  width={"20px"}
                                  alt="profile-img"
                                  style={{ borderRadius: "50%" }}
                                  src={comment.attributes.account.image}
                                />{" "}
                                {comment.attributes.account.name}:{" "}
                              </span>
                              {comment.attributes.comment}
                            </p>
                          );
                        }
                      )}
                      <textarea
                        placeholder="Comment here"
                        onChange={(event) =>
                          this.handleCommentChange(index, event.target.value)
                        }
                        value={this.state.comments[index]}
                        onKeyDown={(event) =>
                          this.handleKeyDown(
                            event.key,
                            index,
                            answer.id,
                            this.state.comments[index]
                          )
                        }
                        data-test-id="text-comment"
                      />
                      <BoxMUI
                        position={"absolute"}
                        right="25px"
                        bottom="20px"
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        style={{ cursor: "pointer" }}
                        data-test-id="profile-btn"
                        onClick={() =>
                          this.goToProfile(
                            answer.attributes.user_profile?.data.id
                          )
                        }
                      >
                        <img
                          src={
                            answer.attributes.user_profile.data.attributes.image
                          }
                          alt="picture"
                          height={"25px"}
                          width={"25px"}
                        />
                        <span className="profile-span">
                          {answer.attributes.user_profile.data.attributes.name}
                          <span style={{ marginLeft: "0.4em" }}>
                            {this.getTimeDifference(
                              answer.attributes.created_at
                            )}
                          </span>
                        </span>
                      </BoxMUI>
                    </BoxMUI>
                  </BoxMUI>
                );
              })}
            </>
          ) : (
            <Typography>No data found</Typography>
          )}
        </BoxMUI>
      </BoxMUI>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleAYQReceivedAnswers = () =>
  createStyles({
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "#510081",
      display: "flex",
      background: "rgb(247,242,249)",
      height: "71px",
      "& h5": {
        fontWeight: 700,
      },
    },
    questionBoxStyle: {
      display: "flex",
      minHeight: "200px",
      background: "rgb(240,240,240)",
      flexDirection: "column",
      padding: "1em 0.3em 5em 4em",
      border: "none",
      margin: "2em 0",
      height: "max-content",
      "& textarea": {
        background: "rgb(246,231,250)",
        padding: "1em",
        border: "none",
        resize: "none",
        margin: "1em 0 2em 0",
        outline: "none",
      },
      "& .main-wrapper": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        "& img": {
          marginRight: "0.2em",
          borderRadius: "50%",
        },
        "& .profile-span": {
          "& span": {
            fontSize: "0.8em",
            color: "gray",
          },
          fontSize: "1.2em",
          fontWeight: "500",
        },
        "& .check-circle": {
          color: "rgb(4,147,69)",
          fontSize: "1.2em",
          marginRight: "0.6em",
        },
        "& ul": {
          display: "flex",
          flexWrap: "wrap",
          margin: "1em 0",
          gap: "1em",
          "& li": {
            border: "none",
            borderRadius: "0.5em",
            background: "rgb(233,225,240)",
            padding: "0.3em 1em",
            fontWeight: "500",
          },
        },
        "& p": {
          fontSize: "1.35em",
        },
      },
    },
    receivedContainerStyle: {
      padding: "2em",
    },
  });
export default withStyles(webStyleAYQReceivedAnswers)(AYQReceivedAnswers);
export { AYQReceivedAnswers };
// Customizable Area End
