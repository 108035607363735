import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { RootLogin } from "../../email-account-login/src/EmailAccountLoginBlockController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userData: RootLogin | null;
  userName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardGuidanceHomeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPackagesMessageId = "";
  getPackagesMessageIdContainer: Array<string> = [];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userData: null,
      userName: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // web events

  // Customizable Area Start
  componentDidMount = async () => {
    const getCredsGuidance = await storage.get("userData");

    if (getCredsGuidance) {
      this.successfulGetCred(JSON.parse(getCredsGuidance));
    }
  };

  componentWillUnmount = async () => {
    runEngine.unSubscribeFromMessages(this as IBlock, this.subScribedMessages);
  };

  successfulGetCred = (userData: RootLogin) => {
    let userName = "";
    if (!userData.attributes.first_name && !userData.attributes.last_name) {
      userName = userData.attributes.name;
    } else {
      userName = `${userData.attributes.first_name} ${userData.attributes.last_name}`;
    }
    this.setState(
      {
        userData,
        userName,
      },
      () => {
        const data = this.state.userData as RootLogin;

        for (const packageData of data.attributes.package) {
          const getPackagesMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          getPackagesMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_plan/pack_finals/${packageData.pack_final_id}`
          );
          this.getPackagesMessageId = getPackagesMessage.messageId;
          this.getPackagesMessageIdContainer.push(this.getPackagesMessageId);

          this.fetchPackages(getPackagesMessage);
        }
      }
    );
  };

  fetchPackages = async (PackageMessage: Message) => {
    const headers = {
      token: await storage.get(configJSON.getUserAuth),
    };

    PackageMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    PackageMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(PackageMessage.id, PackageMessage);
  };
  // Customizable Area End
}
