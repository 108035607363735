import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
// Customizable Area End

import AYQLeaderboardController, {
  Props,
} from "./AYQLeaderboardController.web";

class AYQLeaderboard extends AYQLeaderboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box className={this.props.classes.bannerStyle}>
          <Typography variant="h5">Leaderboard</Typography>
        </Box>
        <Box className={this.props.classes.leaderboardContainerStyle}>
          <Box
            display={"flex"}
            gridGap={"4em"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {this.state.badgesData.map((badge) => {
              return (
                <Box
                  key={badge.type}
                  className={this.props.classes.badgeContainer}
                >
                  <img src={badge.image} alt={badge.image} />
                  <Typography style={this.determineColor(badge.bgcolor)}>
                    {badge.type}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Box className={this.props.classes.breakLine} />
          </Box>
          <Box>
            <Stepper
              activeStep={10}
              orientation="vertical"
              className={this.props.classes.stepperContainer}
            >
              {this.state.leaderboard.map((data) => {
                return (
                  <Step key={data.id}>
                    <StepLabel>{data.attributes.description}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleAYQLeaderboard = () =>
  createStyles({
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "#510081",
      display: "flex",
      background: "rgb(247,242,249)",
      height: "71px",
      "& h5": {
        fontWeight: 700,
      },
    },
    leaderboardContainerStyle: {
      padding: "2em",
    },
    badgeContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "2em",
      "& img": {
        width: "100px",
      },
      "& p": {
        fontWeight: 700,
        fontSize: "1.3em",
      },
    },
    breakLine: {
      width: "70%",
      height: "1px",
      background: "#c0c0c0",
      marginTop: "4em",
    },
    stepperContainer: {
      background: "transparent",
      "& *": {
        fontSize: "1.1em",
      },
      "& .MuiStepConnector-vertical .MuiStepConnector-line": {
        borderColor: "#A16509",
      },
      "& .MuiSvgIcon-root .MuiStepIcon-root .MuiStepIcon-completed": {
        width: "2em",
      },
    },
  });
export default withStyles(webStyleAYQLeaderboard)(AYQLeaderboard);
export { AYQLeaderboard };
// Customizable Area End
