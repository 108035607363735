import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { SubjectData } from "../../../blocks/ContentManagement/src/SynopsisSubjectsController.web";
export type StatsSynopsis = {
  subject_overview: Array<{
    subject: string;
    subject_covered_percentage: number;
    subject_pending_percentage: number;
  }>;
  chapter_overview: Array<{
    chapter: string;
    chapter_covered_percentage: number;
    chapter_pending_percentage: number;
  }>;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedChoice: string;
  subjects: SubjectData[];
  stats: StatsSynopsis | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UsageStatsSynopsisController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSubjectsSynopsisStatsMessageId: string = "";
  getSynopsisStatsMessageId: string = "";
  buttons = [
    "Synopsis",
    "Weekly Test",
    "Grand Test",
    "Practice Test",
    "Live Classes",
    "Recorded Classes",
    "Question Bank",
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      selectedChoice: "",
      subjects: [],
      stats: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSynopsisStatsMessageId && response) {
      if (response?.subject_overview) {
        this.handleSynopsisData(response);
      }
    }

    if (apiRequestCallId === this.getSubjectsSynopsisStatsMessageId) {
      if (response?.data) {
        this.handleSubjectsData(response.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getSubjectsSynopsisStats();
  };

  componentDidUpdate(_: Readonly<Props>, prevState: Readonly<S>): void {
    if (
      prevState.selectedChoice !== this.state.selectedChoice &&
      this.state.selectedChoice !== ""
    ) {
      this.getSynopsisStats();
    }

    if (this.state.selectedChoice === "none") {
      this.handleNoneSubject();
    }
  }

  handleSynopsisData = (stats: StatsSynopsis) => {
    this.setState({
      stats,
      loading: false,
    });
  };

  handleNoneSubject = () => {
    this.setState({
      stats: null,
    });
  };

  // Customizable Area Start
  getSynopsisStats = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };
    const endpoint = `bx_block_assessmenttest/synopsis/synopsis_usage_stats?sub_name=${this.state.selectedChoice}`;
    const requestType = "GET";

    this.setState({
      loading: true,
    });
    const getSynopsisStatsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSynopsisStatsMessageId = getSynopsisStatsMessage.messageId;

    getSynopsisStatsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    getSynopsisStatsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    getSynopsisStatsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      requestType
    );
    runEngine.sendMessage(getSynopsisStatsMessage.id, getSynopsisStatsMessage);
  };

  onChangeSelect = (selectedChoice: string) => {
    this.setState({
      selectedChoice,
    });
  };

  getSubjectsSynopsisStats = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getSubjectsSynopsisStatsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubjectsSynopsisStatsMessageId =
      getSubjectsSynopsisStatsMessage.messageId;

    getSubjectsSynopsisStatsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allSubject
    );

    getSubjectsSynopsisStatsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectsSynopsisStatsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getSubjectsSynopsisStatsMessage.id,
      getSubjectsSynopsisStatsMessage
    );
  };
  handleSubjectsData = (data: SubjectData[]) => {
    this.setState({
      subjects: data,
      loading: false,
    });
  };
  // Customizable Area End
}
