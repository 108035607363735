import React from "react";

import {
  // Customizable Area Start
  Box as MUIBoxDiv,
  Button as MUIButton,
  createStyles,
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
import { noreview, Bookmark, saveImg } from "./assets";
import Button from "../../../components/src/Button.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import GrandTestReviewTestController, {
  Props,
} from "./GrandTestReviewTestController.web";

export class GrandTestReviewTest extends GrandTestReviewTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MUIBoxDiv>
        <Loader loading={this.state.loading} />
        <MUIBoxDiv className={this.props.classes.bannerStyle}>
          <h2>Review History</h2>
        </MUIBoxDiv>
        <MUIBoxDiv style={{ margin: "2em" }}>
          {this.state.review_histories_grand.length > 0 ? (
            <>
              <p style={{ fontSize: "19px" }}>
                No. of attempts : {this.state.review_histories_grand.length}
              </p>
              <p style={{ fontSize: "20px" }}>Test Name : {this.state.review_histories_grand[0].attributes.test_name}</p>
              <MUIBoxDiv className={this.props.classes.setMiddleStyleColumn}>
                {this.state.review_histories_grand
                  .map((review, index) => {
                    return (
                      <MUIBoxDiv
                        key={`grandtest-review-component-${index}`}
                        className={this.props.classes.cardStyle}
                      >
                        <h4>Test Attempt {this.state.review_histories_grand.length - index}</h4>
                        <MUIBoxDiv
                          className={this.props.classes.setMiddleStyle}
                        >
                          <Link
                            to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                              "packageId"
                            )}/review-test/${this.props.navigation.getParam(
                              "subjectId"
                            )}/review/${review.id}`}
                          >
                            <MUIButton
                              className={this.props.classes.buttonStyle}
                            >
                              Review Test
                              <MUIBoxDiv
                                data-test-id="gobackbtn"
                                className={this.props.classes.goBackStep}
                              >{`>`}</MUIBoxDiv>
                            </MUIButton>
                          </Link>
                          <p className={this.props.classes.textStyle}>
                            {this.convertToDate(review.attributes.attempted_at)}
                          </p>
                          <MUIBoxDiv
                            display="flex"
                            position="absolute"
                            top="0"
                            right="0"
                            gridGap="1em"
                            padding={"0.5em 1em"}
                            className={
                              this.props.classes.savedNotesBookmarkStyle
                            }
                          >
                            <Link
                              to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                                "packageId"
                              )}/review-test/${this.props.navigation.getParam(
                                "subjectId"
                              )}/review/${review.id}/saved-notes`}
                            >
                              <img
                                src={saveImg}
                                alt="save-notes"
                                style={{ marginLeft: "4px" }}
                                data-test-id="saved-notes-btn"
                              />
                              <p style={{ fontSize: "10px", color: "#999" }}>
                                Notes
                              </p>
                            </Link>
                            <Link
                              to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                                "packageId"
                              )}/review-test/${this.props.navigation.getParam(
                                "subjectId"
                              )}/review/${review.id}/bookmarks`}
                            >
                              <img
                                src={Bookmark}
                                alt="bookmark-notes"
                                style={{ marginLeft: "15px" }}
                                data-test-id="bookmark-btn"
                              />
                              <p style={{ fontSize: "10px", color: "#999" }}>
                                Bookmark
                              </p>
                            </Link>
                          </MUIBoxDiv>
                        </MUIBoxDiv>
                      </MUIBoxDiv>
                    );
                  })}
              </MUIBoxDiv>
            </>
          ) : (
            <MUIBoxDiv className={this.props.classes.setMiddleStyleColumn2}>
              <MUIBoxDiv className={this.props.classes.setMiddleStyleColumn2}>
                <img src={noreview} alt="noreview" width={"600px"} />
                <h2>You didn't take test to Review</h2>
                <p>Click on Take Test to get Review</p>
              </MUIBoxDiv>
              <Link
                to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                  "packageId"
                )}/test/${this.state.subjectId}`}
              >
                <Button
                  height="40px"
                  color="white"
                  width="500px"
                  title="Take Test"
                />
              </Link>
            </MUIBoxDiv>
          )}
        </MUIBoxDiv>
      </MUIBoxDiv>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleGrandTestReviewTest = () =>
  createStyles({
    setMiddleStyleColumn2: {
      display: "flex",
      flexDirection: "column" as const,
      gap: "2em",
      alignItems: "center",
      justifyContent: "center",
    },
    cardStyle: {
      height: "142px",
      border: "1px solid #FF9486",
      borderRadius: "10px",
      paddingLeft: "2em",
      paddingTop: "1em",
      width: "366px",
      position: "relative",
    },
    setMiddleStyle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginTop: "1em",
    },
    setMiddleStyleColumn: {
      display: "flex",
      justifyContent: "flex-start",
      gap: "2em",
      alignItems: "flex-start",
      marginTop: "1.5em",
      flexWrap: "wrap",
      flexDirection: "row",
    },
    bannerStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "71px",
      background: "rgba(247, 214, 255, 0.19)",
      color: "#461479",
    },
    goBackStep: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      padding: "0.3em",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      color: "white",
      cursor: "pointer",
      position: "absolute",
      right: "5px",
    },
    textStyle: {
      fontSize: "12px",
      fontFamily: "Helvetica, system-ui, sans serif",
      fontWeight: "bold" as const,
      color: "rgb(72, 100, 132)",
    },
    savedNotesBookmarkStyle: {
      border: "1px solid #FF9486",
      borderRadius: "0.5em",
      "& img": {
        width: "20px",
        height: "20px",
        cursor: "pointer",
      },
    },
    buttonStyle: {
      color: "rgb(82, 0, 130)",
      width: "125px",
      borderRadius: "10px",
      textTransform: "none" as const,
      position: "relative",
      justifyContent: "flex-start",
      alignItems: "center",
      border: "1px solid #FF9486",
      height: "40px",
      display: "flex",
    },
  });

export default withStyles(webStyleGrandTestReviewTest)(GrandTestReviewTest);
// Customizable Area End
