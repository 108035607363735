import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
export interface Root {
  notes: Note[];
  meta: Meta;
}

export interface Note {
  id: number;
  notes_content: string;
  noteable_id: number;
  noteable_type: string;
  question_id: string;
  question_no: string;
  created_at: string;
  updated_at: string;
  serial_number: number;
}

export interface Meta {
  exam: string;
  notes_count: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  subjectId: string;
  questionId: string;
  noteId: string;
  isNoteCreateSavedSuccessfully: boolean;
  questionNumber: string;
  cancelModalConfirm: boolean;
  isNoteCancellationModalOpen: boolean;
  noteType: string;
  testId: string;
  savedNoteDetail: Root | null;
  content: string;
  loading: boolean;
  breadcrumb: { link: string; label: string }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GrandTestSavedNotesMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  token = "";
  editSavedNotesMessageId = "";
  getSavedNotesMessageId = "";
  createSavedNotesMessageId = "";
  breadcrumb = [
    {
      label: "Grand Test",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}`,
    },
    {
      label: "Chapters",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}`,
    },
    {
      label: "Reviews",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}/review-test/${this.props.navigation.getParam("subjectId")}`,
    },
    {
      label: "Reviews",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}/review-test/${this.props.navigation.getParam("subjectId")}`,
    },
    {
      label: "Review Test",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}/review-test/${this.props.navigation.getParam(
        "subjectId"
      )}/review/${this.props.navigation.getParam("testId")}`,
    },
    {
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}/review-test/${this.props.navigation.getParam(
        "subjectId"
      )}/review/${this.props.navigation.getParam("testId")}/saved-notes`,
      label: "Saved Notes",
    },
    {
      label: "Add/Edit Note",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  getPackageReviewTestMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      content: "",
      noteId: "",
      questionNumber: "",
      isNoteCreateSavedSuccessfully: false,
      questionId: "",
      cancelModalConfirm: false,
      isNoteCancellationModalOpen: false,
      subjectId: "",
      noteType: "",
      testId: "",
      savedNoteDetail: null,
      breadcrumb: this.breadcrumb,
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseData = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSavedNotesMessageId) {
      if (responseData.notes) {
        this.setState(
          {
            savedNoteDetail: responseData,
          },
          () => {
            this.handleRetrieveNoteData(responseData.notes);
            const breadcrumb = this.state.breadcrumb;
            breadcrumb[2] = {
              ...breadcrumb[2],
              label: this.state.savedNoteDetail?.meta.exam as string,
            };

            const propPassingSNM = new Message(
              getName(MessageEnum.NavigationBreadcrumbMessage)
            );
            propPassingSNM.addData(
              getName(MessageEnum.BreadcrumbDataMessage),
              breadcrumb
            );
            this.send(propPassingSNM);

            this.setState({
              breadcrumb,
            });
          }
        );
      }
    }
    if (apiRequestCallId === this.createSavedNotesMessageId) {
      if (responseData.id) {
        this.setState({
          isNoteCreateSavedSuccessfully: true,
        });
      }
    }
    if (apiRequestCallId === this.editSavedNotesMessageId) {
      if (responseData.id) {
        this.setState({
          isNoteCreateSavedSuccessfully: true,
        });
      }
    }

    if (apiRequestCallId === this.getPackageReviewTestMessageId) {
      if (responseData.data) {
        this.handleSuccessfulPackage(responseData.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const token = await storage.get("authToken");
    const questionId = this.props.navigation.getParam("qid");
    const noteId = this.props.navigation.getParam("noteId");
    const testId = this.props.navigation.getParam("testId");
    const noteType = this.props.navigation.getParam("noteType");
    const subjectId = this.props.navigation.getParam("subjectId");
    const questionNumber = this.props.navigation.getParam("qno");
    const propPassingGTSNMC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingGTSNMC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingGTSNMC);
    if (token) {
      this.token = token;
    }

    this.getPackageReviewTestMessage();

    this.setState(
      {
        questionNumber: questionNumber,
        questionId: questionId,
        noteType,
        subjectId,
        noteId,
        testId,
      },
      () => {
        this.GetNote(testId);
      }
    );
  };

  componentDidUpdate = (_: Props, prevState: S) => {
    const subjectId = this.props.navigation.getParam("subjectId");

    if (subjectId !== prevState.subjectId) {
      this.handlesubjectIdChange(subjectId as string);
    }
  };

  handleRetrieveNoteData = (notes: Note[]) => {
    const content = notes.filter(
      (note) => String(note.id) === this.state.noteId
    )[0]?.notes_content;
    this.setState({
      content,
    });
  };

  handleNoteCancelModalClose = () => {
    this.setState({
      isNoteCancellationModalOpen: false,
    });
  };
  handleNoteCreateModalClose = () => {
    this.setState({
      isNoteCreateSavedSuccessfully: false,
    });
  };
  handlesubjectIdChange = async (subjectId: string) => {
    const token = await storage.get("authToken");
    if (token) {
      this.token = token;
    }

    this.setState({
      subjectId,
    });
  };
  handleNoteCancelConfirmModalClose = () => {
    this.props.navigation.history.goBack();
    this.setState({
      cancelModalConfirm: false,
    });
  };
  handleNoteCancelConfirmModalOpen = () => {
    this.setState({
      cancelModalConfirm: true,
    });
  };
  handleNoteCancelModalOpen = () => {
    this.setState({
      isNoteCancellationModalOpen: true,
    });
  };
  handleInputChange = (value: string) => {
    this.setState({
      content: value,
    });
  };
  confirmCancel = () => {
    this.handleNoteCancelConfirmModalOpen();
    this.handleNoteCancelModalClose();
  };

  // Customizable Area Start
  GetNote = (testId: string) => {
    const headers = {
      token: this.token,
      "Content-Type": "application/json",
    };

    this.setState({
      loading: true,
    });

    const getSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSavedNotesMessageId = getSavedNotesMessage.messageId;

    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/${testId}/gt_notes`
    );

    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getSavedNotesMessage.id, getSavedNotesMessage);
  };
  NoteApi = () => {
    if (this.state.noteType === "Edit") {
      const headers = {
        token: this.token,
      };
      const editSavedNotesMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      editSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
      );
      editSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      editSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_assessmenttest/notes/${this.state.noteId}?content=${this.state.content}`
      );

      this.editSavedNotesMessageId = editSavedNotesMessage.messageId;

      runEngine.sendMessage(editSavedNotesMessage.id, editSavedNotesMessage);
    } else {
      const headers = {
        token: this.token,
        "Content-Type": "application/json",
      };

      const body = {
        content: this.state.content,
        noteable_id: this.state.testId,
        question_id: this.state.questionId,
        question_no: this.state.questionNumber,
        noteable_type: "grand_test_review",
      };

      const createSavedNotesMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createSavedNotesMessageId = createSavedNotesMessage.messageId;

      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_assessmenttest/notes`
      );

      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      runEngine.sendMessage(
        createSavedNotesMessage.id,
        createSavedNotesMessage
      );
    }
  };

  handleSuccessfulPackage = (
    response: { gt_code: number; package_name: string }[]
  ) => {
    const packageId = this.props.navigation.getParam("packageId");
    response.forEach((data) => {
      if (data.gt_code === packageId) {
        const breadcrumb = this.state.breadcrumb;
        breadcrumb[1] = { ...breadcrumb[1], label: data.package_name };

        const propPassingSNM = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingSNM.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingSNM);

        this.setState({
          breadcrumb,
          loading: false,
        });
      }
    });
  };

  getPackageReviewTestMessage = async () => {
    const getPackageMessageSNM = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getPackageReviewTestMessageId =
      getPackageMessageSNM.messageId;

    getPackageMessageSNM.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getPackageMessageSNM.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/get_packages`
    );

    getPackageMessageSNM.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getPackageMessageSNM.id,
      getPackageMessageSNM
    );
  };
  // Customizable Area End
}
