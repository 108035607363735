import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { advancedDashboardSearchStyle } from "./AdvancedSearchDashboard.web";
import storage from "../../../framework/src/StorageProvider.web";
import { SearchResultsDataType } from "./SearchResults";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof advancedDashboardSearchStyle> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchResults: SearchResultsDataType;
  search: string;
  inputState: boolean;
  loading: boolean;
  noData: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchTimeout: NodeJS.Timeout | null = null;
  getSearchResultsMessageId = "";
  searchRes = {
    weekly_test: {
      data: [],
    },
    practice_test: {
      data: [],
    },
    live_classes: {
      data: [],
    },
    grand_test: {
      data: [],
    },
    question_bank: {
      data: [],
    },
    synopsis: {
      data: [],
    },
    recorded_classes: {
      data: [],
    },
    swayamprabha: {
      data: [],
    },
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      search: "",
      inputState: false,
      searchResults: {
        ...this.searchRes,
      },
      loading: false,
      noData: false,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        apiRequestCallId === this.getSearchResultsMessageId &&
        !responseJson.message
      ) {
        this.handleSuccessSearch(responseJson as SearchResultsDataType);
      } else if (responseJson.message) {
        this.setState({
          loading: false,
          noData: true,
          searchResults: this.searchRes,
        });
      }
    }
    // Customizable Area End
  }

  handleInputState = () => {
    this.setState((prev) => {
      return {
        inputState: !prev.inputState,
      };
    });
  };

  navigateQuestionBank = (questionId: string) => {
    this.props.navigation.history.push(
      `/dashboard/question-bank/chapter/${questionId}`
    );
  };

  handleSearch = (search: string) => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.setState(
      {
        search,
      },
      () => {
        this.searchTimeout = setTimeout(() => {
          this.apiFetchSearchResult(search);
        }, 600);
      }
    );
  };

  handleSuccessSearch = (data: SearchResultsDataType) => {
    this.setState({
      searchResults: data,
      loading: false,
      noData: false,
    });
  };

  navigateSynopsis = (sysId: string, subjId: string, chapId: string) => {
    this.props.navigation.history.push(
      `/dashboard/synopsis/chapters/${subjId}/topics/${chapId}/topic-read/${sysId}/off`
    );
  };

  // Customizable Area Start
  apiFetchSearchResult = async (search: string) => {
    this.setState({
      loading: true,
    });
    const headers = {
      token: await storage.get("authToken"),
    };

    const getSearchResultsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchResultsMessageId = getSearchResultsMessage.messageId;

    getSearchResultsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSearchResultsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/search?query=${search}`
    );

    getSearchResultsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSearchResultsMessage.id, getSearchResultsMessage);
  };
  // Customizable Area End
}
