Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.Number = "Number"
exports.getAnalyies = "GET ANALYSIS"
exports.farmerQutaStatus = "Farmar Quota Status"
exports.Catgories21 =[
  {
      name: "OPEN(OC)",
      catgKey:1,
      key: 'OPEN',
  },
  {
      name: "EWS",
      catgKey:2,
      key: 'EWS',
  },
  {
      name: "BC - A",
      catgKey:3,
      key: 'BCA',
  },
  {
      name: "BC - B",
      catgKey:4,
      key: 'BCB',
  },
  {
      name: "BC - C",
      catgKey:5,
      key: 'BCC',
  },
  {
      name: "BC - D",
      catgKey:6,
      key: 'BCD',
  },
  {
      name: "BC - E",
      catgKey:7,
      key: 'BCE',
  },
  {
      name: "SC",
      catgKey:8,
      key: 'SC',
  },
  {
      name: "ST",
      catgKey:9,
      key: 'ST',
  },
  {
      name: "MINORITY",
      catgKey:10,
      key: 'MIN',
  },
  {
      name: "PH - GN(General Category)",
      catgKey:11,
      key: 'GN_PH',
  },
  {
      name: "PH - OBC(Other Backward Castes Category)",
      catgKey:12,
      key: 'OBC_PH',
  },
  {
      name: "PH - SC(Scheduled Castes)",
      catgKey:13,
      key: 'SC_PH',
  },
  {
      name: "PH - ST(Scheduled Tribes)",
      catgKey:14,
      key: 'ST_PH',
  },

];
exports.CourseDropDown = "Course Name"
exports.courseListEndPoint = "/bx_block_tutorials2/course_list?exam_name={examName}"
exports.universityName= "University Region"
exports.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NTY2LCJleHAiOjE3MDc0NTg2MzQsInRva2VuX3R5cGUiOiJsb2dpbiJ9.u1lmxoGC0QAu8zx-Q7hY389ovzsgjlQ_gynyzA_VPDoEt5iux9fncJQP9jGTS071YG9f3-juEcFTAQQ55_yeYw"
exports.MPC20CollegelistEndPoint = "/bx_block_tutorials2/college_list?exam_name={examName}"
exports.Catgories = "Category"
exports.catgorie2019 = [
  "GN (General Category)",
  "EWS(Economically Weaker Sections)",
  "OBC (Other Backward Castes Category)",
  "SC(Scheduled Castes)",
  "ST(Scheduled Tribes)",
];
exports.chooseCatgories = [
  "Category Wise Analysis",
  "College wise Analysis",
  "Course Wise Analysis",
];
exports.choosesAnalayies = "Choose to Get Analysis";
exports.selectExam = "Select Exam";
exports.selectYear = "Select year";
exports.cutOfflabel = "Cutt Off Analysis";
exports.AnalyisData = [
  "M.P.C Guidance",
  "Bi.P.C Guidance",
  "NEET PG Guidance",
  "NEET MDS Guidance",
];
exports.examGreeneBG = "#05a55a";
exports.EamcetPjetsu= "TS Eamcet(PJTSAU)(Vet,agri,Horti and Fishery science)";
exports.EamcetAngrau = "TS Eamcet(ANGRAU)(Vet,agri,Horti and Fishery science)";
exports.examBlueBG = "#00c0ef";
exports.clickHereToView = "Click here to view";
  exports.redBG = "#de4c39"
exports.colors = ["#03bfef", "#f59c0e", "#0ca65a", "#dd4d3a"]
exports.searchLabel = "Search"
exports.courseDetailEndPoint = "/bx_block_tutorials2/courses/{courseID}"
exports.courseListEndPoint = "/bx_block_tutorials2/courses";
exports.collegeType = "College Type -";
exports.region = "Region - ";
exports.courseavailble = "Course Available - ";
exports.email = "Email - ";
exports.contact = "Contact - ";
exports.address = "Address - ";
exports.established = "Established - ";
exports.stateName = "State Name - ";
exports.colleageName = "College Name - ";
exports.courseName = "Course Name - ";
exports.Degree = "Degree - ";
exports.duration = "Duration - ";
exports.eligibleCourse = "Eligible Course - ";
exports.remark = "Remarks - ";
exports.counsellingName = "Counselling Name -";
exports.collegesite = "College link - ";
exports.notification = "Notification - ";
exports.website = "Website - ";
exports.counsellingDetailsEndPoint =
  "/bx_block_tutorials2/councellings/{counsellingID}";
exports.CounsellingEndPoint = "/bx_block_tutorials2/councellings";
exports.CounsellingDetails = "Counselling Details";
exports.Counselling = "Counselling";
exports.colleageLabel = "Colleges";
exports.collegeDetails = "College Details";
exports.colleageDetailEndPoint =
  "/bx_block_tutorials2/colleges/{collegeID}/college_course";
exports.colleageListEndpoint = "/bx_block_tutorials2/colleges";
exports.orText = "OR";
exports.webLinkBtntext = "Register through Website link";
exports.resgiterBTNtext = "Register with MyRank";
exports.CounsellingLinked = "Counselling Linked -";
exports.EligibleGroups = "Eligible Groups -";
exports.examLabel = "Exam Name -";
exports.examDetailsEndPoint = "/bx_block_tutorials2/examinations/{examid}";
exports.examListEndPoint = "/bx_block_tutorials2/examinations";
exports.examDetailsLabel = "Exam Details";
exports.Courses = "Courses";
exports.Counsellings = "Counsellings";
exports.CollegesLabel = "Colleges";
exports.examtionLabel = "Examinations";
exports.courseFinder = "Course Finder";
exports.SelectYear = "Select year";
exports.delete = "DELETE";
exports.patch = "PATCH";
exports.SelectExam = "Select Exam";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DynamicContent";
exports.labelBodyText = "DynamicContent Body";
exports.ModalTitle = "Settings";
exports.btnExampleTitle = "Display content";
exports.displaySettings = "Settings Page";
exports.Title = "Enter Title";
exports.buttontext = "Save";
exports.startPoint = "Start";
exports.endpoint = "End";
exports.State = "Select State";
exports.Country = "Select Country";
exports.SignUp_Button = "Sign Up";
exports.UserName = "Enter your username";
exports.UserEmail = "Enter Your Email-ID";
exports.Password = "Enter Password";
exports.ConfirmPassword = "Enter Confirm Password";
exports.SignUpBTN = "Sign up";
exports.SignUpendpoint = "/bx_block_dynamiccontent/users";
exports.LoginEndpoint = "/bx_block_dynamiccontent/logins";
exports.Loginlable = "Log In";
exports.Siguplable = "Sign up";
exports.DefaultContent = "/bx_block_dynamiccontent/default_contents";
exports.DefaultLable = "Default Text";
exports.CountryListEndPoint = "/bx_block_dynamiccontent/countries";
exports.stateListEndPoint = "/bx_block_dynamiccontent/countries/get_all_state";
exports.dynamicContent = "/bx_block_dynamiccontent/dynamiccontents";
exports.allContentEndpoint = "/bx_block_dynamiccontent/dynamiccontents";
exports.stateEndpoint = "/bx_block_dynamiccontent/countries/get_all_state";
exports.editContent = "/bx_block_dynamiccontent/update_content";
exports.deleteContent = "/bx_block_dynamiccontent/destroy_content/";
exports.defaultContentEditendPoint = "/bx_block_dynamiccontent/default_edit";
exports.title = 'Geolocation Permission';
exports.message = 'Can we access your location?';
exports.buttonNeutral = 'Ask Me Later';
exports.buttonNegative = 'Cancel';
exports.buttonPositive = 'OK';
exports.locationEndPostive = 'https://nominatim.openstreetmap.org/reverse?format=geojson';
exports.mpc20CollegeEndPoint = "/bx_block_tutorials2/college_list?exam_name={examName}"
exports.Mpc2020 = [
  "JEE ADVANCED",
  "JEE MAINS(JOSAA only)",
  "JEE MAINS(CSAB only)",
  "TS EAMCET ENGINEERING",
  "AP EAMCET ENGINEERING",
];
exports.Course = "Course";
exports.selctRudCourse = "Select Course";
exports.selctUniversity = "Select University";
exports.collegeWise = "College wise Analysis";
exports.courseWise = "Course Wise Analysis";
exports.selectCatg = "Select Categories";
exports.round = "Round";
exports.selctRud = "Select Round";
exports.homeState = "Home State/UT*";
exports.collegeN = "College Name";
exports.selctHomeState = "Do Not Consider";
exports.courseFinderEndpoint = "bx_block_tutorials2/courses/course_finder";
exports.allCourses = "bx_block_tutorials2/courses";
exports.allExams = "bx_block_tutorials2/examinations";
exports.allCounselings = "bx_block_tutorials2/councellings";
exports.allColleges = "bx_block_tutorials2/colleges";
exports.completeListLabel = "Complete List"
exports.counsellingN = "Counselling Name"
exports.coursesN = "Course name"
exports.examN = "Exam Name"
// Customizable Area End
exports.selctclg = "Assam University, Silchar(GFTI)"
// Customizable Area End
