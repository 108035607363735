import React, { Component, ReactNode } from "react";
import {
  withStyles,
  createStyles,
  Box,
  Typography,
  WithStyles,
} from "@material-ui/core";

// Customizable Area Start
interface Props extends WithStyles {
  title: string;
  children?: ReactNode;
}
// Customizable Area End

class Banner extends Component<Props> {
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      <Box className={this.props.classes.bannerStyle}>
        <Typography variant="h5">{this.props.title}</Typography>
        {this.props.children}
      </Box>
    );
  }
}

const componentStyles = () =>
  createStyles({
    bannerStyle: {
      display: "flex",
      height: "71px",
      width: "100%",
      background: "rgba(247, 214, 255, 0.19)",
      justifyContent: "center",
      alignItems: "center",
      color: "rgb(82, 0, 130)",
      position: "relative",
      "& h5": {
        fontWeight: 500,
        fontSize: "24px",
      },
    },
  });

export default withStyles(componentStyles)(Banner);
