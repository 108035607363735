import React from "react";

import {
  Box as MUIBoxDiv,
  // Customizable Area Start
  Button,
  Modal,
  Typography,
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ReviewTestComponent from "../../../components/src/ReviewTestComponent.web";
import { styles } from "./styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Stepper from "../../../components/src/Stepper.web";
import TimerIcon from "@material-ui/icons/Timer";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { saveImg, Bookmark } from "./assets";
import Loader from "../../../components/src/Loader.web";
import ProgressContainer from "../../../components/src/ProgressContainer.web";
// Customizable Area End

import GrandTestReviewTestMainController, {
  Props,
} from "./GrandTestReviewTestMainController.web";

class GrandTestReviewTestMain extends GrandTestReviewTestMainController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    let totalTime: string = "00:00";

    if (this.state.metaDataGrand) {
      totalTime = this.state.metaDataGrand.taken_time;
    }

    const { correctPercentage, unattendedPercentage, incorrectPercentage } =
      this.state;

    return (
      // Customizable Area Start
      <MUIBoxDiv>
        <Loader loading={this.state.loading} />
        <MUIBoxDiv style={webStyle.bannerStyle}>
          <h2>Grand Test - Review</h2>
        </MUIBoxDiv>
        <MUIBoxDiv
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          gridGap={"2em"}
          alignItems={"center"}
          margin={"4em 0"}
        >
          <MUIBoxDiv style={webStyle.containerMainStyle}>
            <Button
              data-test-id="graphical-btn"
              onClick={this.setGraphical}
              style={
                !this.state.isTabular
                  ? webStyle.buttonStyleYes
                  : webStyle.buttonStyleNo
              }
            >
              Graphical
            </Button>
            <Button
              data-test-id="tabular-btn"
              onClick={this.setTabular}
              style={
                this.state.isTabular
                  ? webStyle.buttonStyleYes
                  : webStyle.buttonStyleNo
              }
            >
              Tabular
            </Button>
          </MUIBoxDiv>
          {this.state.isTabular ? (
            <MUIBoxDiv style={webStyle.container1Style}>
              <table style={webStyle.table}>
                <thead>
                  <tr>
                    <th style={webStyle.tableHeaderTh}>Category</th>
                    <th style={webStyle.tableHeaderTh}>Correct</th>
                    <th style={webStyle.tableHeaderTh}>Wrong</th>
                    <th style={webStyle.tableHeaderTh}>Unattempted</th>
                    <th style={webStyle.tableHeaderTh}>Marks</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.reviewData.map((reviewData, index) => (
                    <tr key={`${index}-reviewData`}>
                      <td style={webStyle.tableCell}>
                        {reviewData.subject_name}
                      </td>
                      <td style={webStyle.tableCell}>{reviewData.correct}</td>
                      <td style={webStyle.tableCell}>{reviewData.incorrect}</td>
                      <td style={webStyle.tableCell}>
                        {reviewData.unattempted}
                      </td>
                      <td style={webStyle.tableCell}>
                        {reviewData.totalMarks}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot style={webStyle.tableFooter}>
                  <tr>
                    <td style={webStyle.tableFooterTd}>
                      <p>Total</p>
                    </td>
                    <td style={webStyle.tableFooterTd} />
                    <td style={webStyle.tableFooterTd} />
                    <td style={webStyle.tableFooterTd} />
                    <td style={webStyle.tableFooterTd}>
                      <p>{this.state.metaDataGrand?.marks}</p>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </MUIBoxDiv>
          ) : (
            <ProgressContainer
              correctPercentage={correctPercentage as number}
              incorrectPercentage={incorrectPercentage as number}
              unattendedPercentage={unattendedPercentage as number}
            />
          )}
        </MUIBoxDiv>
        <MUIBoxDiv
          style={{ display: "flex", gap: "1em", padding: "1em 2em 0 2em" }}
        >
          <Stepper
            steps={this.state.currentSubjectTestData.length}
            activeStep={this.state.currentDataIndex + 1}
          />
          <p>
            {this.state.currentDataIndex + 1}/
            {this.state.currentSubjectTestData.length}
          </p>
        </MUIBoxDiv>
        <MUIBoxDiv
          style={{
            display: "flex",
            gap: "1em",
            padding: "1em 2em",
            alignItems: "center",
          }}
        >
          <p className={this.props.classes.practiceTestTimer}>
            <span>
              <TimerIcon />
            </span>
            {totalTime}
          </p>
        </MUIBoxDiv>
        <MUIBoxDiv
          display={"flex"}
          justifyContent={"center"}
          gridGap={"1em"}
          alignItems={"center"}
          margin={"1em 0"}
        >
          {this.state.reviewData.map((subject, index) => {
            return (
              <Button
                data-test-id={`change-subject-btn`}
                key={`${index}-gtsubject-${subject.subject_name}`}
                onClick={() =>
                  this.handleSubjectChange(subject.subject_name, index)
                }
                style={{
                  border: "none",
                  padding: "1em 2em",
                  background:
                    this.state.currentSubjectTestDataIndex === index
                      ? "#FAE6FF"
                      : "#E9E9E9",
                  fontWeight: 500,
                  fontSize: "15px",
                }}
              >
                {subject.subject_name}
              </Button>
            );
          })}
        </MUIBoxDiv>
        <MUIBoxDiv className={this.props.classes.questionOverview}>
          This question carries +
          {
            this.state.currentSubjectTestData[this.state.currentDataIndex]
              ?.attributes.p_mark
          }{" "}
          for Correct Answer and -
          {Number(
            this.state.currentSubjectTestData[this.state.currentDataIndex]
              ?.attributes.n_mark
          )}{" "}
          for Wrong Answer
          <MUIBoxDiv>
            <p className={this.props.classes.savedNotesIcon}>
              <img
                src={saveImg}
                width={"25px"}
                onClick={this.handleNotesModalOpen}
                data-test-id="notes-modal"
              />
            </p>
            <p className={this.props.classes.bookmarkIcon}>
              <img
                src={Bookmark}
                width={"25px"}
                onClick={this.handleBookmarkModalOpen}
                data-test-id="bookmark-modal"
              />
            </p>
          </MUIBoxDiv>
        </MUIBoxDiv>
        <MUIBoxDiv className={this.props.classes.mainContainerPracticeTest}>
          {this.state.currentSubjectTestData.length > 0 && (
            <ReviewTestComponent
              index={this.state.currentDataIndex}
              question={
                this.state.currentSubjectTestData[this.state.currentDataIndex]
              }
            />
          )}
          <MUIBoxDiv className="overview-practice-test">
            <MUIBoxDiv className="overview-board">
              <h3>Overview</h3>
              <MUIBoxDiv className="overview-btns">
                {this.state.currentSubjectTestData.map((question, index) => {
                  const className = this.getBtnClassName(index);
                  return (
                    <button
                      data-test-id={`specific-btn-test-id`}
                      key={`button-${index}`}
                      className={className}
                      onClick={() => this.handleSpecificQuestion(index)}
                    >
                      {question.attributes.question_no}
                    </button>
                  );
                })}
              </MUIBoxDiv>
            </MUIBoxDiv>
          </MUIBoxDiv>
        </MUIBoxDiv>
        <MUIBoxDiv className={this.props.classes.btnsPracticeTest}>
          <MUIBoxDiv className="submit-mark-practice-btns">
            <Link
              to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                "packageId"
              )}/test/${this.props.navigation.getParam("subjectId")}`}
            >
              <button data-test-id={`submitTest`} className="submit-test-btn">
                Retake Test
              </button>
            </Link>
          </MUIBoxDiv>
          <MUIBoxDiv className="navigation-practice-btns">
            <MUIBoxDiv>
              <button
                data-test-id={`prevbtn`}
                onClick={this.handlePreviousQuestion}
              >
                <ChevronLeftIcon />
              </button>
              <button data-test-id={`nxtbtn`} onClick={this.handleNextQuestion}>
                <ChevronRightIcon />
              </button>
            </MUIBoxDiv>
          </MUIBoxDiv>
        </MUIBoxDiv>
        <Modal
          open={this.state.openSaveNotesModalGrand}
          onClose={this.handleNotesModalClose}
        >
          <MUIBoxDiv sx={webStyle.modalStyle}>
            <Typography variant="h6">Choose What You Need</Typography>
            <MUIBoxDiv
              display={"flex"}
              flexDirection={"column"}
              gridGap={"1em"}
            >
              <Link
                to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                  "packageId"
                )}/review-test/${this.props.navigation.getParam(
                  "subjectId"
                )}/review/${this.props.navigation.getParam(
                  "testId"
                )}/saved-notes`}
              >
                <Button className={this.props.classes.buttonStyleModals}>
                  Go to saved notes
                </Button>
              </Link>
              <Link
                to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                  "packageId"
                )}/review-test/${this.props.navigation.getParam(
                  "subjectId"
                )}/review/${this.props.navigation.getParam(
                  "testId"
                )}/saved-notes/notes/New/${
                  this.state.currentSubjectTestData[this.state.currentDataIndex]
                    ?.attributes.question_no
                }/${
                  this.state.currentSubjectTestData[this.state.currentDataIndex]
                    ?.attributes.question_id
                }`}
              >
                <Button className={this.props.classes.buttonStyleModals}>
                  Create New Note
                </Button>
              </Link>
            </MUIBoxDiv>
          </MUIBoxDiv>
        </Modal>
        <Modal
          open={this.state.openBookmarkSuccessModalGrand}
          onClose={this.handleBookmarkSuccessModalClose}
        >
          <MUIBoxDiv sx={webStyle.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>
              You have Bookmarked Question no -{" "}
              {
                this.state.currentSubjectTestData[this.state.currentDataIndex]
                  ?.attributes.question_no
              }{" "}
              successfully!
            </h3>
            <MUIBoxDiv>
              <Link
                to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                  "packageId"
                )}/review-test/${this.props.navigation.getParam(
                  "subjectId"
                )}/review/${this.props.navigation.getParam(
                  "testId"
                )}/bookmarks`}
              >
                <Button className={this.props.classes.buttonStyleModals}>
                  Go to Saved bookmarks
                </Button>
              </Link>
            </MUIBoxDiv>
          </MUIBoxDiv>
        </Modal>
        <Modal
          onClose={this.handleBookmarkModalClose}
          open={this.state.openBookmarkModalGrand}
        >
          <MUIBoxDiv sx={webStyle.modalStyle}>
            <Typography variant="h6">Choose What You Need</Typography>
            <MUIBoxDiv
              display={"flex"}
              flexDirection={"column"}
              gridGap={"1em"}
            >
              <Link
                to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                  "packageId"
                )}/review-test/${this.props.navigation.getParam(
                  "subjectId"
                )}/review/${this.props.navigation.getParam(
                  "testId"
                )}/bookmarks`}
              >
                <Button
                  className={this.props.classes.buttonStyleModals}
                  data-test-id="gotobookmarks-modal"
                >
                  Go to Saved Bookmark
                </Button>
              </Link>
              <Button
                className={this.props.classes.buttonStyleModals}
                data-test-id="save-bookmarks-modal"
                onClick={this.bookmarkSave}
              >
                Create New Bookmark
              </Button>
            </MUIBoxDiv>
          </MUIBoxDiv>
        </Modal>
      </MUIBoxDiv>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  bannerStyle: {
    height: "71px",
    color: "rgb(82, 0, 130)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    background: "rgba(247, 214, 255, 0.19)",
    position: "relative" as const,
  },
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "max-content",
    width: "35%",
    padding: "2em",
    gap: "1.5em",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #FFD0D8",
    borderRadius: "1.5em",
  },
  table: {
    width: "900px",
    borderCollapse: "collapse" as const,
    border: "2px solid #510081",
    height: "480px",
    background: "#FAF8FB",
  },
  tableHeaderTh: {
    border: "2px solid #510081",
    padding: "40px",
    color: "#510081",
  },
  tableFooter: {
    color: "white",
    backgroundColor: "#510081",
    textAlign: "center" as const,
  },
  tableFooterP: {
    padding: "10px",
  },
  tableFooterTd: {
    padding: "30px",
  },
  tableCell: {
    textAlign: "center" as const,
    border: "2px solid #510081",
    padding: "10px",
  },
  buttonStyleYes: {
    color: "white",
    width: "130px",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    height: "50px",
    textTransform: "none" as const,
    cursor: "pointer",
  },
  buttonStyleNo: {
    width: "130px",
    height: "50px",
    background: "white",
    color: "#FE6156",
    borderRadius: "0.6em",
    border: "1px solid #FE6156",
    cursor: "pointer",
    textTransform: "none" as const,
  },
  pStyle: {
    fontWeight: 500,
    marginTop: "2.5em",
    fontSize: "17px",
  },
  container1Style: {
    flexDirection: "column" as const,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  containerMainStyle: {
    gap: "1em",
    display: "flex",
    marginBottom: "2em",
  },
};

export default withStyles(styles)(GrandTestReviewTestMain);
export { GrandTestReviewTestMain };
// Customizable Area End
