import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
export type ISavedLater = {
  id: string;
  type: string;
  attributes: {
    id: number;
    cdf_univ: {
      id: number;
      univ_name: string;
      established: number;
      state: string;
      address: string;
      univ_type: string;
      status: string;
      website: string;
      link_myrank: string;
      wikipedia: string;
      contact1: string;
      contact2: string;
      email: string;
      description: string;
      remarks: string;
      created_at: string;
      updated_at: string;
      rating: any;
    };
    marked: string;
  };
};

import { WithStyles } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  savedApplications: ISavedLater[];
  loading: boolean;
  currentPageApplication: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApplicationSavedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSavedApplicationsMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      savedApplications: [],
      loading: false,
      currentPageApplication: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSavedApplicationsMessageId && response) {
      if (response?.data) {
        this.handleSuccessfulSavedApplicationsFetch(response.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getSavedApplications();
  };

  handleSuccessfulSavedApplicationsFetch = (response: ISavedLater[]) => {
    this.setState({
      loading: false,
      savedApplications: response,
    });
  };

  handlePageChangeApplication = (value: number) => {
    this.setState({
      currentPageApplication: value,
    });
  };

  // Customizable Area Start
  getSavedApplications = async () => {
    this.setState({
      loading: true,
    });

    const getSavedApplicationsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };

    getSavedApplicationsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tutorials2/guidance_applications/marked_applications?marked=save_for_later`
    );
    this.getSavedApplicationsMessageId = getSavedApplicationsMessage.messageId;
    getSavedApplicationsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSavedApplicationsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getSavedApplicationsMessage.id,
      getSavedApplicationsMessage
    );
  };
  // Customizable Area End
}
