// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import {
  CssBaseline,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core';

import { MathJaxContext } from 'better-react-mathjax';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import MegamenuContent from '../../blocks/ContentManagement/src/MegamenuCommon.web.tsx';
import EmailAccountLogin from '../../blocks/email-account-login/src/EmailAccountLogin.web';
import EmailAccountLoginMain from '../../blocks/email-account-login/src/EmailAccountLoginMain.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import ContactUsNew from '../../blocks/contactus/src/ContactUsNew.web';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import GetCredentials from '../../blocks/forgot-password/src/GetCredentials.web';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import DashboardGuest from '../../blocks/dashboard/src/DashboardGuest.web.tsx';
import DashboardGuestHome from '../../blocks/dashboard/src/DashboardGuestHome.web';
import GuestSynopsisPage from '../../blocks/ContentManagement/src/SynopsisGuest.web';
import GuestSynopsisCheckPage from '../../blocks/ContentManagement/src/SynopsisGuestCheck.web';
import GuestSynopsisChapterPage from '../../blocks/ContentManagement/src/SynopsisGuestChapter.web';
import GuestSynopsisTopicsPage from '../../blocks/ContentManagement/src/SynopsisGuestTopics.web';
import DashboardGuidance from '../../blocks/dashboard/src/DashboardGuidance.web';
import DashboardGuidanceHome from '../../blocks/dashboard/src/DashboardGuidanceHome.web';
import DashboardAYQ from '../../blocks/dashboard/src/DashboardAYQ.web';
import DashboardCM from '../../blocks/dashboard/src/DashboardCM.web.tsx';
import CutOffAnalysis from '../../blocks/DynamicContent/src/CutOffAnalysis.web';
// COURSE FINDER IMPORTS
import CourseFinder from '../../blocks/DynamicContent/src/CourseFinder.web';
import CourseFinderColleges from '../../blocks/DynamicContent/src/CourseFinderColleges.web';
import CourseFinderCounselling from '../../blocks/DynamicContent/src/CourseFinderCounselling.web';
import CourseFinderCourses from '../../blocks/DynamicContent/src/CourseFinderCourses.web';
import CourseFinderExamination from '../../blocks/DynamicContent/src/CourseFinderExamination.web';
// MPC GUIDANCE IMPORTS
import MPCGuidanceAnalysis from '../../blocks/DynamicContent/src/MPCGuidanceAnalysis.web';
import MPCGuidanceCutOffs from '../../blocks/DynamicContent/src/MPCGuidanceCutOffs.web';
import MPCGuidanceMain from '../../blocks/DynamicContent/src/MPCGuidanceMain.web';
// AYQ REGISTER LOGIN IMPORTS
import AYQLogin from '../../blocks/email-account-registration/src/AYQLogin.web';
import AYQRegister from '../../blocks/email-account-registration/src/AYQRegister.web';
// Ask your Query
import AskYourQueryBadges from '../../blocks/CustomisableUserProfiles/src/AYQBadges.web';
import AskYourQueryLeaderboard from '../../blocks/CustomisableUserProfiles/src/AYQLeaderboard.web';
import AskYourQueryQuestion from '../../blocks/CommunityForum/src/AYQQuestion.web';
import AskYourQueryQuestionAnswer from '../../blocks/CommunityForum/src/AYQQuestionAnswer.web';
import AskYourQueryProfilePeek from '../../blocks/CommunityForum/src/AYQProfilePeek.web';
import AskYourQueryProfile from '../../blocks/CustomisableUserProfiles/src/AYQProfile.web';
import AskYourQueryEditProfile from '../../blocks/CustomisableUserProfiles/src/AYQEditProfile.web';
import AskYourQueryQuestionsAsked from '../../blocks/CustomisableUserProfiles/src/AYQQuestionsAsked.web';
import AskYourQueryReceivedAnswers from '../../blocks/CustomisableUserProfiles/src/AYQReceivedAnswers.web';
import AskYourQueryQuestionToAsk from '../../blocks/CustomisableUserProfiles/src/AYQAskQuestion.web';
import AskYourQuerySimilarQuestion from '../../blocks/CustomisableUserProfiles/src/AYQSimilarQuestion.web';
// Guest Live Class
import GuestLiveClass from '../../blocks/CfZoomIntegration16/src/GuestLiveClass.web';
// Guest Practice Test
import GuestPracticeTest from '../../blocks/CfTestManagement2/src/GuestPracticeTest.web';
import GuestPracticeTestChapters from '../../blocks/CfTestManagement2/src/GuestPracticeTestChapters.web';
import GuestPracticeTestSubjects from '../../blocks/CfTestManagement2/src/GuestPracticeTestSubjects.web';
// Guest Weekly Test
import GuestWeeklyTestChapter from '../../blocks/CfTestManagement2/src/GuestWeeklyTestChapters.web.tsx';
import GuestWeeklyTest from '../../blocks/CfTestManagement2/src/GuestWeeklyTest.web.tsx';
// Guest Grand Test
import GuestGrandTest from '../../blocks/CfTestManagement2/src/GuestGrandTest.web.tsx';
import GuestTestsGrandTest from '../../blocks/CfTestManagement2/src/GuestTestsGrandTest.web.tsx';
import GuestGrandTestPackages from '../../blocks/CfTestManagement2/src/GuestGrandTestPackages.web.tsx';
// Guest Swayam Prabha
import GuestSwayamPrabha from '../../blocks/VideoManagement/src/GuestSwayamPrabha.web.tsx';
// Guest Question Bank
import GuestQbMain from '../../blocks/QuestionBank/src/GuestQbMain.web.tsx';
import GuestQbSubjects from '../../blocks/QuestionBank/src/GuestQbSubjects.web.tsx';
import GuestQbTopics from '../../blocks/QuestionBank/src/GuestQbTopics.web.tsx';
// Guest Notifications
import GuestNotifications from '../../blocks/notifications/src/GuestNotifications.web.tsx';
// Guest Packages
import GuestPackages from '../../blocks/guestlogin2/src/GuestPackages.web.tsx';
// Calculate
import Calculate from '../../blocks/Scoring/src/Calculate.web';
// Package
import MyPackage from '../../blocks/categoriessubcategories/src/MyPackages.web';
// Book my slot
import BookMySlot from '../../blocks/scheduling/src/BookMySlot.web.tsx';
import BookMySlotAppointments from '../../blocks/scheduling/src/BookMySlotAppointments.web.tsx';
// Usage Stats
import UsageStatsMain from '../../blocks/UsageInsights/src/UsageStatsMain.web.tsx';
import UsageStatsSynopsis from '../../blocks/UsageInsights/src/UsageStatsSynopsis.web.tsx';
import UsageStatsWeeklyTest from '../../blocks/UsageInsights/src/UsageStatsWeeklyTest.web.tsx';
import UsageStatsGrandTest from '../../blocks/UsageInsights/src/UsageStatsGrandTest.web.tsx';
import UsageStatsPracticeTest from '../../blocks/UsageInsights/src/UsageStatsPracticeTest.web.tsx';
import UsageStatsLiveClasses from '../../blocks/UsageInsights/src/UsageStatsLiveClass.web.tsx';
import UsageStatsRecordedClasses from '../../blocks/UsageInsights/src/UsageStatsRecordedClass.web.tsx';
import UsageStatsQuestionBank from '../../blocks/UsageInsights/src/UsageStatsQuestionBank.web.tsx';

import DocumentsUpload from '../../blocks/BulkUploading/src/DocumentsUpload.web.tsx';

import ApplicationFlow from '../../blocks/ContentManagement/src/ApplicationFlow.web.tsx';
import ApplicationNewOpening from '../../blocks/ContentManagement/src/ApplicationNewOpening.web.tsx';
import ApplicationPending from '../../blocks/ContentManagement/src/ApplicationPending.web.tsx';
import { ApplicationSavedPage } from '../../blocks/ContentManagement/src/ApplicationSaved.web.tsx';

export const routeMapDashboardCM = {
  DashboardCalculator: {
    component: Calculate,
    path: '/dashboard-cm/calculate',
    exact: true,
  },
  DashboardPackages: {
    component: MyPackage,
    path: '/dashboard-cm/packages',
    exact: true,
  },
  BookMySlot: {
    component: BookMySlot,
    path: '/dashboard-cm/book-my-slot',
    exact: true,
  },
  BookMySlotAppointments: {
    component: BookMySlotAppointments,
    path: '/dashboard-cm/appointments',
    exact: true,
  },
};

export const routeMapDashboardAYQ = {
  DashboardHome: {
    component: AskYourQueryQuestion,
    path: '/dashboard-ask-your-query/home',
    exact: true,
  },
  DashboardAYQ: {
    component: AskYourQueryQuestion,
    path: '/dashboard-ask-your-query',
    exact: true,
  },
  // ASK YOUR QUERYY ROUTES
  AskYourQueryBadges: {
    component: AskYourQueryBadges,
    path: '/dashboard-ask-your-query/badges',
    exact: true,
  },
  AskYourQueryLeaderboard: {
    component: AskYourQueryLeaderboard,
    path: '/dashboard-ask-your-query/leaderboard',
    exact: true,
  },
  AskYourQueryQuestion: {
    component: AskYourQueryQuestion,
    path: '/dashboard-ask-your-query/questions/:navigationCurr?',
    exact: true,
  },
  AskYourQueryQuestionAnswer: {
    component: AskYourQueryQuestionAnswer,
    path: '/dashboard-ask-your-query/answer/:questionId',
    exact: true,
  },
  AskYourQueryProfilePeek: {
    component: AskYourQueryProfilePeek,
    path: '/dashboard-ask-your-query/profile-view/:profileId',
    exact: true,
  },
  AskYourQueryProfile: {
    component: AskYourQueryProfile,
    path: '/dashboard-ask-your-query/profile',
    exact: true,
  },
  AskYourQueryEditProfile: {
    component: AskYourQueryEditProfile,
    path: '/dashboard-ask-your-query/profile/edit-profile',
    exact: true,
  },
  AskYourQueryQuestionsAsked: {
    component: AskYourQueryQuestionsAsked,
    path: '/dashboard-ask-your-query/questions-asked',
    exact: true,
  },
  AskYourQueryReceivedAnswers: {
    component: AskYourQueryReceivedAnswers,
    path: '/dashboard-ask-your-query/received-answers/:questionId',
    exact: true,
  },
  AskYourQueryQuestionToAsk: {
    component: AskYourQueryQuestionToAsk,
    path: '/dashboard-ask-your-query/ask-a-question',
    exact: true,
  },
  AskYourQuerySimilarQuestion: {
    component: AskYourQuerySimilarQuestion,
    path: '/dashboard-ask-your-query/similar-question/:search',
    exact: true,
  },
};

export const routeMapGuest = {
  GuestPageHome: {
    component: DashboardGuestHome,
    path: '/guest/home',
    exact: true,
  },
  DashboardHomeGuest: {
    component: DashboardGuestHome,
    path: '/guest',
    exact: true,
  },
  GuestSynopsisPage: {
    component: GuestSynopsisPage,
    path: '/guest/synopsis',
    exact: true,
  },
  GuestSynopsisCheckPage: {
    component: GuestSynopsisCheckPage,
    path: '/guest/synopsis/chapter-read/:chapterId',
    exact: true,
  },
  GuestSynopsisChapterPage: {
    component: GuestSynopsisChapterPage,
    path: '/guest/synopsis/chapter/:subjectId',
    exact: true,
  },
  GuestSynopsisTopicsPage: {
    component: GuestSynopsisTopicsPage,
    path: '/guest/synopsis/topics/:chapterId',
    exact: true,
  },
  GuestLiveClassPage: {
    component: GuestLiveClass,
    path: '/guest/live-classes',
    exact: true,
  },
  GuestPracticeTestSubject: {
    component: GuestPracticeTestSubjects,
    path: '/guest/practice-test',
    exact: true,
  },
  GuestPracticeTestChapter: {
    component: GuestPracticeTestChapters,
    path: '/guest/practice-test/:subjectId/chapters',
    exact: true,
  },
  GuestPracticeTest: {
    component: GuestPracticeTest,
    path: '/guest/practice-test/:subjectId/chapters/:chapterId/test',
    exact: true,
  },
  GuestWeeklyTestChapter: {
    component: GuestWeeklyTestChapter,
    path: '/guest/weekly-test',
    exact: true,
  },
  GuestWeeklyTest: {
    component: GuestWeeklyTest,
    path: '/guest/weekly-test/:testId/test',
    exact: true,
  },
  GuestGrandTest: {
    component: GuestGrandTest,
    path: '/guest/grand-test/:packageId/test/:testId',
    exact: true,
  },
  GuestTestsGrandTest: {
    component: GuestTestsGrandTest,
    path: '/guest/grand-test/:packageId/list-test',
    exact: true,
  },
  GuestGrandTestPackages: {
    component: GuestGrandTestPackages,
    path: '/guest/grand-test',
    exact: true,
  },
  GuestSwayamPrabha: {
    component: GuestSwayamPrabha,
    path: '/guest/swayam-prabha',
  },
  GuestQbSubjects: {
    component: GuestQbSubjects,
    path: '/guest/question-bank',
    exact: true,
  },
  GuestQbTopics: {
    component: GuestQbTopics,
    path: '/guest/question-bank/:subjectId/topics',
    exact: true,
  },
  GuestQbMain: {
    component: GuestQbMain,
    path: '/guest/question-bank/:subjectId/topics/:topicId',
    exact: true,
  },
  GuestNotifications: {
    component: GuestNotifications,
    path: '/guest/notifications',
  },
  GuestRecordedClasses: {
    component: GuestLiveClass,
    path: '/guest/recorded-classes',
    exact: true,
  },
  GuestPackages: {
    component: GuestPackages,
    path: '/guest/packages',
    exact: true,
  },
};

export const routeMapDashboard = {
  DashboardGuidanceHome: {
    component: DashboardGuidanceHome,
    path: '/dashboard-guidance/home',
    exact: true,
  },
  DashboardGuidance: {
    component: DashboardGuidanceHome,
    path: '/dashboard-guidance',
    exact: true,
  },
  // Cut Off Analysis Routes
  CutOffAnalysis: {
    component: CutOffAnalysis,
    path: '/dashboard-guidance/cut-off-analysis',
    exact: true,
  },
  MPCGuidanceMain: {
    component: MPCGuidanceMain,
    path: '/dashboard-guidance/cut-off-analysis/mpc-guidance',
    exact: true,
  },
  MPCGuidanceCutOffs: {
    component: MPCGuidanceCutOffs,
    path: '/dashboard-guidance/cut-off-analysis/mpc-guidance/cut-offs/:year/:mpc_data',
    exact: true,
  },
  MPCGuidanceAnalysis: {
    component: MPCGuidanceAnalysis,
    path: '/dashboard-guidance/cut-off-analysis/mpc-guidance/cut-offs/:year/:mpc_data/analysis/:category/:round?/:collegeId?/:courseId?/:stateId?/:univId',
    exact: true,
  },
  // Course Finder Routes
  CourseFinder: {
    component: CourseFinder,
    path: '/dashboard-guidance/course-finder',
    exact: true,
  },
  CourseFinderExamination: {
    component: CourseFinderExamination,
    path: '/dashboard-guidance/course-finder/examinations',
    exact: true,
  },
  CourseFinderColleges: {
    component: CourseFinderColleges,
    path: '/dashboard-guidance/course-finder/colleges',
    exact: true,
  },
  CourseFinderCounselling: {
    component: CourseFinderCounselling,
    path: '/dashboard-guidance/course-finder/counselling',
    exact: true,
  },
  CourseFinderCourses: {
    component: CourseFinderCourses,
    path: '/dashboard-guidance/course-finder/courses',
    exact: true,
  },
  UsageStatsMain: {
    component: UsageStatsMain,
    path: '/dashboard-guidance/usage-stats',
  },
  DocumentsUpload: {
    component: DocumentsUpload,
    path: '/dashboard-guidance/documents-upload',
    exact: true,
  },
  ApplicationFlow: {
    component: ApplicationFlow,
    path: '/dashboard-guidance/applications',
    exact: true,
  },
  ApplicationNewOpening: {
    component: ApplicationNewOpening,
    path: '/dashboard-guidance/applications/new-opening',
    exact: true,
  },
  ApplicationPending: {
    component: ApplicationPending,
    path: '/dashboard-guidance/applications/pending',
    exact: true,
  },
  ApplicationSaved: {
    component: ApplicationSavedPage,
    path: '/dashboard-guidance/applications/saved',
    exact: true,
  },
};

export const routeMapUsageStats = {
  UsageStatsSynopsis: {
    component: UsageStatsSynopsis,
    path: '/dashboard-guidance/usage-stats/synopsis',
    exact: true,
  },
  UsageStatsWeeklyTest: {
    component: UsageStatsWeeklyTest,
    path: '/dashboard-guidance/usage-stats/weekly-test',
    exact: true,
  },
  UsageStatsGrandTest: {
    component: UsageStatsGrandTest,
    path: '/dashboard-guidance/usage-stats/grand-test',
    exact: true,
  },
  UsageStatsPracticeTest: {
    component: UsageStatsPracticeTest,
    path: '/dashboard-guidance/usage-stats/practice-test',
    exact: true,
  },
  UsageStatsLiveClasses: {
    component: UsageStatsLiveClasses,
    path: '/dashboard-guidance/usage-stats/live-class',
    exact: true,
  },
  UsageStatsRecordedClasses: {
    component: UsageStatsRecordedClasses,
    path: '/dashboard-guidance/usage-stats/recorded-class',
    exact: true,
  },
  UsageStatsQuestionBank: {
    component: UsageStatsQuestionBank,
    path: '/dashboard-guidance/usage-stats/question-bank',
    exact: true,
  },
};

const routeMap = {
  LandingPage: {
    component: LandingPage,
    path: '/',
    exact: true,
  },
  MegamenuContent: {
    component: MegamenuContent,
    path: '/guidance-content',
    exact: true,
  },
  Register: {
    component: EmailAccountRegistration,
    path: '/register',
  },
  Login: {
    component: EmailAccountLoginMain,
    path: '/login',
    exact: true,
  },
  EmailAccountLogin: {
    component: EmailAccountLogin,
    path: '/login/loginbyid',
    exact: true,
  },
  ContactUs: {
    component: ContactUsNew,
    path: '/contact-us',
    exact: true,
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/forgot-password',
    exact: true,
  },
  NewPassword: {
    component: NewPassword,
    path: '/reset-password',
    exact: true,
  },
  GetCredentials: {
    component: GetCredentials,
    path: '/get-credentials',
    exact: true,
  },
  Dashboard: {
    component: Dashboard,
    path: '/dashboard',
  },
  DashboardGuidance: {
    component: DashboardGuidance,
    path: '/dashboard-guidance',
  },
  DashboardAYQ: {
    component: DashboardAYQ,
    path: '/dashboard-ask-your-query',
  },
  DashboardGuest: {
    component: DashboardGuest,
    path: '/guest',
  },
  DashboardCM: {
    component: DashboardCM,
    path: '/dashboard-cm',
  },
  AYQRegister: {
    component: AYQRegister,
    path: '/ask-your-query/register',
    exact: true,
  },
  AYQLogin: {
    component: AYQLogin,
    path: '/ask-your-query/login',
    exact: true,
  },
};

let theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: 'Poppins, serif',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
            textTransform: 'initial',
            fontFamily: 'Poppins, serif',
          },
          '*::before, *::after': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
          },
          'a, li, ul': {
            listStyleType: 'none',
            textDecoration: 'none',
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 700,
        lg: 1200,
        xl: 1400,
      },
    },
  })
);

class App extends Component {
  render() {
    return (
      <View>
        {/* <TopNav /> */}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MathJaxContext>{WebRoutesGenerator({ routeMap })}</MathJaxContext>
          {/* <ModalContainer /> */}
        </ThemeProvider>
      </View>
    );
  }
}

export default App;
