import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { RefObject, ChangeEvent, KeyboardEvent } from "react";
import { auth } from "../../../framework/src/firebase_auth";
import {
  signInWithPopup,
  GoogleAuthProvider,
  UserCredential,
} from "firebase/auth";
import storage from "../../../framework/src/StorageProvider.web";
export type RootLogin = {
  id: string;
  type: string;
  attributes: {
    name: string;
    first_name: string;
    last_name: string;
    phone_number: number;
    email: string;
    city: string;
    status: string;
    myrank_id: string;
    image: string;
    uuid: string | number | null;
    course_notification_enable: boolean;
    exam_notification_enable: boolean;
    package: Array<{
      id: number;
      account_id: number;
      pack_final_id: number;
      pack_pricing_id: number;
      packfinal_feature_id: string | number | null;
      ayq_level: number;
      lc_level: number;
      wb_level: number;
      bms_level: number;
      tution_level: number;
      acat_apply: number;
      bcat_apply: number;
      amount: number;
      validity: string | number | null;
      expiry_date: string;
      sales_manager_name: string | number | null;
      agent1_name: string | number | null;
      agent2_name: string | number | null;
      transaction_type: string;
      status: string;
      created_by: string;
      created_at: string;
      updated_at: string;
      sms_level: string | number | null;
      active: boolean;
    }>;
  };
};
import { WithStyles } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userInput: {
    userId: string;
    password: string;
    userNumber: string;
  };
  showPassword: boolean;
  isLoggingByMobile: boolean;
  emailGoogle: string;
  googleAuthID: string;
  googleToken: string;
  userSelectLoginByIdNumber: boolean;
  isModalOpen: boolean;
  isModalOpenVerify: boolean;
  isModalOpenOTP: boolean;
  otpToken: string;
  isModalOpenSuccessVerify: boolean;
  otpInput: string[];
  resendOTPModal: boolean;
  rememberMe: boolean;
  phone: string;
  userNotFound: boolean;
  passwordIncorrect: boolean;
  phoneDoesNotExist: boolean;
  isModalOpenWrongCredential: boolean;
  isModalOpenWrongPassword: boolean;
  isOtpError: boolean;
  timerSeconds: number;
  timerActive: boolean;
  userType: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountLoginBlockController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postUserIdLoginDocumentId: string = "";
  postMobileLoginDocumentId: string = "";
  postAPIGoogleLoginDocumentId: string = "";
  postMobileOTPLoginDocumentId: string = "";
  inputRefs: RefObject<HTMLInputElement>[];
  timerInterval: NodeJS.Timeout = setTimeout(() => {}, 0);
  postResendOTPDocumentId: string = "";
  postGuestLoginMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      timerSeconds: 59,
      timerActive: false,
      userInput: {
        userId: "",
        password: "",
        userNumber: "",
      },
      showPassword: false,
      isLoggingByMobile: false,
      emailGoogle: "",
      googleAuthID: "",
      googleToken: "",
      userSelectLoginByIdNumber: false,
      isModalOpen: false,
      isModalOpenVerify: false,
      isModalOpenOTP: false,
      otpToken: "",
      isModalOpenSuccessVerify: false,
      otpInput: ["", "", "", ""],
      resendOTPModal: false,
      rememberMe: false,
      phone: "",
      passwordIncorrect: false,
      phoneDoesNotExist: false,
      userNotFound: false,
      isModalOpenWrongPassword: false,
      isModalOpenWrongCredential: false,
      isOtpError: false,
      userType: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.inputRefs = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.postUserIdLoginDocumentId:
        this.handleUserIdLoginResponse(responseJson);
        break;
      case this.postMobileLoginDocumentId:
        this.handleMobileLoginResponse(responseJson);
        break;
      case this.postAPIGoogleLoginDocumentId:
        this.handleGoogleLoginResponse(responseJson);
        break;
      case this.postMobileOTPLoginDocumentId:
        this.handleMobileOTPLoginResponse(responseJson);
        break;
      case this.postResendOTPDocumentId:
        this.handleResendOTPResponse(responseJson);
        break;
      case this.postGuestLoginMessageId:
        this.handleGuestLogin(responseJson);
        break;
      default:
        break;
    }
    // Customizable Area End
  }

  // web events
  componentWillMount = async () => {
    const userLoggedInToken = await storage.get("authToken");
    const cookieExisting = this.getCookie("userCookie");
    if (cookieExisting) {
      this.rememberMeStateChange(
        JSON.parse(cookieExisting) as { userId: string; password: string }
      );
    }

    if (userLoggedInToken) {
      this.props.navigation.navigate("Dashboard");
    }
  };

  getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");

    for (let c of ca) {
      let trimmedCookie = c.trim();
      if (trimmedCookie.indexOf(name) == 0) {
        return trimmedCookie.substring(name.length);
      }
    }

    return "";
  }

  rememberMeStateChange = (cookieData: {
    userId: string;
    password: string;
  }) => {
    this.setState({
      userInput: {
        ...this.state.userInput,
        password: cookieData.password,
        userId: cookieData.userId,
      },
      rememberMe: true,
    });
  };

  componentDidUpdate(_: Props, prevState: S) {
    if (prevState.timerSeconds === 1 && this.state.timerSeconds === 0) {
      this.resetTimer();
    }
  }

  componentWillUnmount = async () => {
    clearInterval(this.timerInterval);
  };

  handleGuestLogin = async (response?: {
    data: {
      attributes: {
        uuid: string;
      };
    };
  }) => {
    storage.set("guestToken", response?.data.attributes.uuid);
    this.props.navigation.history.push("/guest/packages");
  };

  startTimer = () => {
    this.setState({
      isOtpError: false,
      timerSeconds: 59,
      timerActive: true,
    });

    this.timerInterval = setInterval(() => {
      this.setState((prevState) => ({
        timerSeconds: prevState.timerSeconds - 1,
      }));
    }, 1000);
  };

  resetTimer = () => {
    clearInterval(this.timerInterval);
    this.setState({
      timerActive: false,
    });
  };

  handleUserIdLoginResponse(responseJson: {
    data: {
      attributes: {
        name: string;
        myrank_id: string;
        email: string;
        user_type: string;
      };
      id: string;
    };
    errors: { failed_login: string }[];
    meta: { token: string };
  }) {
    if (responseJson && responseJson.errors) {
      const { failed_login } = responseJson.errors[0];
      this.loginError(failed_login);
    }

    if (responseJson && responseJson.data) {
      storage.set("authToken", responseJson.meta.token);
      storage.set("userName", responseJson.data.attributes.name);
      storage.set("myrank_id", responseJson.data.attributes.myrank_id);
      storage.set("userEmail", responseJson.data.attributes.email);
      storage.set("userId", responseJson.data.id);
      storage.set("userData", JSON.stringify(responseJson.data));

      const { userInput, rememberMe } = this.state;
      if (rememberMe) {
        document.cookie = `userCookie=${encodeURIComponent(
          JSON.stringify(userInput)
        )}; path=/;`;
      }

      this.setState({ userType: responseJson.data.attributes.user_type });
      this.loginSuccessful();
    }
  }

  handleMobileLoginResponse(responseJson: {
    data: { attributes: { full_phone_number: string } };
    meta: { token: string };
    errors: unknown;
  }) {
    if (responseJson && responseJson.errors) {
      this.handleOpenModalWrongCredential();
      this.loginErrorMobile();
    }

    if (responseJson && responseJson.meta) {
      this.setState({
        userInput: {
          ...this.state.userInput,
          userNumber: responseJson.data.attributes.full_phone_number,
        },
      });
      this.loginMobileNextPhase(responseJson.meta.token);
    }
  }

  handleGoToLandingPage = () => {
    this.props.navigation.navigate("LandingPage");
  };

  handleGoogleLoginResponse(responseJson: {
    data: {
      attributes: { name: string; myrank_id: string; email: string };
      id: string;
    };
    errors: unknown;
    meta: { token: string };
  }) {
    if (responseJson) {
      if (responseJson.data) {
        storage.set("authToken", responseJson.meta.token);
        storage.set("userName", responseJson.data.attributes.name);
        storage.set("myrank_id", responseJson.data.attributes.myrank_id);
        storage.set("userEmail", responseJson.data.attributes.email);
        storage.set("userId", responseJson.data.id);
        storage.set("userData", JSON.stringify(responseJson.data));
        this.props.navigation.navigate("Dashboard");
      }
      if (responseJson.errors) {
        this.props.navigation.history.push(
          `/register?googleAuthId=${this.state.googleAuthID}&googleEmail=${this.state.emailGoogle}`
        );
      }
    }
  }

  handleMobileOTPLoginResponse(responseJson: {
    data: { attributes: { name: string; myrank_id: string; email: string } };
    errors: unknown;
    meta: { token: string };
  }) {
    if (responseJson && responseJson.data) {
      storage.set("authToken", responseJson.meta.token);
      storage.set("userName", responseJson.data.attributes.name);
      storage.set("myrank_id", responseJson.data.attributes.myrank_id);
      storage.set("userEmail", responseJson.data.attributes.email);
      storage.set("userData", JSON.stringify(responseJson.data));
      this.handleCloseModalOTPVerify();
      this.handleOpenModalSuccessVerify();
      this.setState({
        isOtpError: false,
      });
    }

    if (responseJson && responseJson.errors) {
      this.setState({
        isOtpError: true,
      });
    }
  }

  handleResendOTPResponse(responseJson: {
    data: unknown;
    meta: { token: string };
  }) {
    if (responseJson && responseJson.data) {
      this.resetTimer();
      this.setState({
        otpToken: responseJson.meta.token,
        isModalOpenOTP: false,
        resendOTPModal: true,
        isOtpError: false,
      });
    }
  }

  handleGoogleLogIn = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    const result: UserCredential = await signInWithPopup(auth, provider);
    const { email, providerData, accessToken } = result.user as any;
    this.handleGoogleSignInCredential(
      accessToken,
      providerData[0].uid,
      email as string
    );
  };

  loginMobileNextPhase = (token: string) => {
    this.setState(
      {
        otpToken: token,
      },
      () => {
        this.handleOpenModalVerify();
      }
    );
  };

  loginSuccessful = () => {
    this.handleOpenModal();
  };

  // Customizable Area Start
  handleChangePhone = (value: string) => {
    this.setState({
      phone: value,
    });
  };

  handleRememberMe = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    if (!checked) {
      document.cookie =
        "userCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    } else {
      const { userInput } = this.state;
      document.cookie = `userCookie=${encodeURIComponent(
        JSON.stringify(userInput)
      )}; path=/;`;
    }

    this.setState({
      rememberMe: checked,
    });
  };

  handleChangeInputOTP(
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ): void {
    const { value } = event.target;
    if (value.length >= 3) {
      return;
    }

    if (!/^\d*$/.test(value)) {
      return;
    }

    const otpInput = [...this.state.otpInput];
    otpInput[index] = value;
    if (value !== "" && index < this.inputRefs.length - 1) {
      this.inputRefs[index + 1].current?.focus();
    }

    this.setState({ otpInput });
  }

  handleKeyDown(index: number, e: KeyboardEvent<HTMLInputElement>): void {
    if (
      e.key === "Backspace" &&
      index > 0 &&
      this.state.otpInput[index] === ""
    ) {
      this.inputRefs[index - 1].current?.focus();
    }
  }

  handleOTPSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const requestBody = {
      data: {
        token: this.state.otpToken,
        pin: this.state.otpInput.join(""),
      },
    };

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postMobileOTPLoginDocumentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_login/logins/login_otp_confirmation"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChangeInput(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { name, value } = event.target;
    this.setState({
      userInput: {
        ...this.state.userInput,
        [name]: value,
      },
    });
  }

  loginAsGuest = async () => {
    const requestBody = {
      data: {
        type: "guest_account",
      },
    };

    const header = {
      "Content-Type": "application/json",
    };

    const requestGuestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postGuestLoginMessageId = requestGuestMessage.messageId;

    requestGuestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/guest_account"
    );

    requestGuestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestGuestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestGuestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestGuestMessage.id, requestGuestMessage);
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleLoginByMobile = () => {
    this.setState({
      isLoggingByMobile: true,
    });
  };

  handleLoginById = () => {
    this.setState({
      isLoggingByMobile: false,
    });
  };

  handleGoogleSignInCredential = (
    token: string,
    googleId: string,
    email: string
  ) => {
    this.setState(
      {
        googleAuthID: googleId,
        googleToken: token,
        emailGoogle: email,
      },
      () => {
        this.handleGoogleAPIMyRankSubmit();
      }
    );
  };

  handleToForgotPassword = () => {
    this.props.navigation.navigate("LinkForgotPassword");
  };

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  handleGoogleAPIMyRankSubmit = () => {
    const header = {
      token: this.state.googleToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAPIGoogleLoginDocumentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `social_accountcheck?email=${this.state.emailGoogle}&unique_auth_id=${this.state.googleAuthID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMobileLoginSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const phoneNumber = String(this.state.phone).slice(2);
    const requestBody = {
      data: {
        type: "sms_account",
        attributes: {
          phone_number: phoneNumber,
        },
      },
    };

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postMobileLoginDocumentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/logins"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUserIdLoginSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const requestBody = {
      data: {
        type: "sms_account",
        attributes: {
          myrank_id: this.state.userInput.userId,
          password: this.state.userInput.password,
        },
      },
    };

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postUserIdLoginDocumentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/logins"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleOpenModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleGoToDashboard = () => {
    const windowUrl = window.location.href;
    const urlParams = new URLSearchParams(windowUrl.split("?")[1]);
    const callbackUrl = urlParams.get("callback");
    if (callbackUrl) {
      return this.props.navigation.history.push(
        decodeURIComponent(callbackUrl)
      );
    }
    return this.props.navigation.navigate("Dashboard");
  };

  handleCloseModalVerify = () => {
    this.setState({
      isModalOpenVerify: false,
    });
  };

  handleOpenModalVerify = () => {
    this.setState({
      isModalOpenVerify: true,
    });
  };

  handleOpenModalOTPVerify = () => {
    this.resetTimer();
    this.startTimer();
    this.setState({
      isModalOpenOTP: true,
    });
  };

  handleCloseModalOTPVerify = () => {
    this.setState({
      isModalOpenOTP: false,
    });
  };

  handleVerifyOTP = () => {
    this.setState({
      otpInput: ["", "", "", ""],
    });
    this.handleCloseModalVerify();
    this.handleOpenModalOTPVerify();
  };

  handleCloseModalSuccessVerify = () => {
    this.setState({
      isModalOpenSuccessVerify: false,
    });
  };

  handleOpenModalSuccessVerify = () => {
    this.setState({
      isModalOpenSuccessVerify: true,
    });
  };

  handleResendCode = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.otpToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postResendOTPDocumentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/logins/resend_otp_for_login"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResendOTPModalClose = () => {
    this.setState({
      resendOTPModal: false,
    });
  };

  loginError = (error: string) => {
    if (error === "Account not found, or not activated") {
      this.handleOpenModalWrongCredential();
      this.setState({
        userNotFound: true,
      });
    } else {
      this.handleOpenModalWrongPassword();
      this.setState({
        userNotFound: false,
        passwordIncorrect: true,
      });
    }
  };

  loginErrorMobile = () => {
    this.setState({
      phoneDoesNotExist: true,
    });
  };

  handleCloseModalWrongCredential = (userInput: string) => {
    if (userInput === "modal") {
      this.setState({
        isModalOpenWrongCredential: false,
      });
    } else {
      this.props.navigation.navigate("GetCredentials");
    }
  };

  handleCloseModalWrongPassword = () => {
    this.setState({
      isModalOpenWrongPassword: false,
    });
  };

  handleOpenModalWrongPassword = () => {
    this.setState({
      isModalOpenWrongPassword: true,
    });
  };

  handleOpenModalWrongCredential = () => {
    this.setState({
      isModalOpenWrongCredential: true,
    });
  };

  handleGoToSignUp = () => {
    this.props.navigation.navigate("Register");
  };
  // Customizable Area End
}
