import React from "react";

import {
  Box,
  // Customizable Area Start
  Typography,
  Button,
  withStyles,
  createStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import Loader from "../../../components/src/Loader.web";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import StarIcon from "@material-ui/icons/Star";
import { bookmarkImg } from "./assets";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End

import ApplicationNewOpeningController, {
  IApplicationOpened,
  Props,
} from "./ApplicationNewOpeningController.web";

class ApplicationNewOpening extends ApplicationNewOpeningController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ApplicationRender = (application: IApplicationOpened) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gridGap={"0.5em"}
        className={this.props.classes.applicationBoxStyle}
        width="45%"
        minHeight="230px"
        height="max-content"
        padding="1.5em"
        key={application.attributes.id}
      >
        <Typography variant="h5">{application.attributes.univ_name}</Typography>
        <Box display={"flex"} alignItems={"center"}>
          <LocationOnOutlinedIcon />
          <Typography variant="body1">
            {application.attributes.address}
          </Typography>
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <StarIcon htmlColor={"#FAC530"} fontSize="small" />
          <Typography variant="body1">
            {application.attributes.rating ? application.attributes.rating : 0}{" "}
            / 5
          </Typography>
        </Box>
        <Typography variant="body1">
          {application.attributes.univ_type} Estd.{" "}
          {application.attributes.established}
        </Typography>
        <Box display={"flex"} gridGap={"1em"}>
          <Button className={this.props.classes.interestedButton}>
            Interested To Apply
          </Button>
          <Button className={this.props.classes.notInterestedButton}>
            Not Interested
          </Button>
        </Box>
        <Typography variant="body1">
          Notification Date :{" "}
          {application.attributes.notification[0].notification_date}
        </Typography>
        <Box
          className={this.props.classes.saveForLaterBtn}
          onClick={() => this.postSavedForLater(application.id)}
        >
          <img src={bookmarkImg} alt="save-icon" />
          <Typography variant="subtitle1">Save for later</Typography>
        </Box>
      </Box>
    );
  };

  ApplicationPage = (application: IApplicationOpened[]) => {
    return (
      <Box
        display="flex"
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignItems={"center"}
        gridGap={"2.5em"}
        marginBottom={"5em"}
      >
        {application.map((application) => {
          return this.ApplicationRender(application);
        })}
      </Box>
    );
  };
  // Customizable Area End

  render() {
    const { latestApplications, allApplications, currentPageApplication } =
      this.state;

    const application =
      this.state.currentPage === 0 ? latestApplications : allApplications;

    const itemsPerPage = 10;
    const indexOfLastItem = currentPageApplication * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = application.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(application.length / itemsPerPage);

    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <BannerWeb title="New Applications Openings" />
        <Box padding={"2em 6em"}>
          <Typography variant="h5">
            {this.state.currentPage === 0
              ? latestApplications.length
              : allApplications.length}{" "}
            have opened applications
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gridGap={"2em"}
          margin={"3em"}
        >
          <Button
            className={`${this.props.classes.buttons} ${
              this.state.currentPage === 0 && this.props.classes.active
            }`}
            data-test-id="current-page-latest"
            onClick={() => this.selectCurrentPage(0)}
          >
            Latest Applications
          </Button>
          <Button
            className={`${this.props.classes.buttons} ${
              this.state.currentPage === 1 && this.props.classes.active
            }`}
            data-test-id="current-page-all"
            onClick={() => this.selectCurrentPage(1)}
          >
            All Applications
          </Button>
        </Box>
        <Box padding={"2em"}>{this.ApplicationPage(currentItems)}</Box>
        <Box
          display="flex"
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          marginBottom={"5em"}
        >
          <Pagination
            data-test-id="pagination"
            count={totalPages}
            page={this.state.currentPageApplication}
            onChange={(_: unknown, value: number) =>
              this.handlePageChangeApplication(value)
            }
            className={this.props.classes.muiPaginationStyle}
          />
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleApplicationNewOpening = () =>
  createStyles({
    buttons: {
      border: "1px solid #FF5C5C",
      color: "#FF5C5C",
      padding: "0.7em 2em",
    },
    active: {
      color: "white",
      background: "#FF5C5C",
      "&:hover": {
        background: "#FF5C5C",
      },
    },
    interestedButton: {
      color: "white",
      background: "#2271B1",
      "&:hover": {
        background: "#2271B1",
      },
    },
    notInterestedButton: {
      color: "black",
      border: "1px solid #2271B1",
    },
    applicationBoxStyle: {
      border: "1px solid gray",
      position: "relative",
    },
    saveForLaterBtn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      bottom: "15px",
      right: "15px",
      cursor: "pointer",
      "& img": {
        width: "30px",
      },
    },
    muiPaginationStyle: {
      borderRadius: "4px",
      position: "relative",
      border: "1px solid rgba(255, 58, 87, 0.7)",
      width: "max-content",
      "& li": {
        color: "white",
        width: "35px",
        background: "white",
        height: "41px",
        borderRadius: "2px",
      },
      "& li:nth-child(1)": {
        background: "transparent",
        position: "absolute",
        zIndex: 999,
        left: -20,
        top: 10,
        "& button": {
          height: "max-content",
          width: "max-content",
        },
        "& button.Mui-disabled": {
          display: "none",
        },
        "& button svg": {
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          borderRadius: "50%",
          "& path": {
            fill: "white",
          },
        },
      },
      "& li:nth-last-child(1)": {
        background: "transparent",
        position: "absolute",
        right: -20,
        top: 10,
        zIndex: 999,
        "& button svg": {
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          borderRadius: "50%",
          "& path": {
            fill: "white",
          },
        },
        "& button": {
          height: "max-content",
          width: "max-content",
        },
        "& button.Mui-disabled": {
          display: "none",
        },
      },
      "& li button:hover": {
        background: "none",
      },
      "& li button": {
        height: "100%",
        alignItems: "center",
        width: "100%",
        background: "none",
        justifyContent: "center",
        margin: 0,
        display: "flex",
        padding: 0,
      },
      "& li div": {
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        background: "none",
        display: "flex",
      },
      "& li button.Mui-selected": {
        color: "white",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        fontWeight: 500,
        borderRadius: "2px",
      },
    },
  });
export default withStyles(webStyleApplicationNewOpening)(ApplicationNewOpening);
export { ApplicationNewOpening };
// Customizable Area End
