import React from "react";
import {
  // Customizable Area Start
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import WeeklyTestReviewController from "./WeeklyTestReviewController.web";
import Button from "../../../components/src/Button.web";
import TimerIcon from "@material-ui/icons/Timer";
import { Link } from "react-router-dom";
import { styles } from "./styles";
import ProgressContainer from "../../../components/src/ProgressContainer.web";
// Customizable Area End

class WeeklyTestReview extends WeeklyTestReviewController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      timeTaken,
      correctPercentage,
      incorrectPercentage,
      unattendedPercentage,
    } = this.state;

    return (
      // Customizable Area Start
      <div className={this.props.classes.practiceTestReview}>
        <p className={this.props.classes.practiceTestTimer}>
          <span>
            <TimerIcon />
          </span>{" "}
          {timeTaken}
        </p>
        <ProgressContainer
          correctPercentage={correctPercentage as number}
          incorrectPercentage={incorrectPercentage as number}
          unattendedPercentage={unattendedPercentage as number}
        />
        <Link
          to={`/dashboard/weekly-test/tests/review-test/${this.props.navigation.getParam(
            "testId"
          )}/review/${this.props.navigation.getParam("reviewId")}`}
        >
          <div style={webStyleReviewWeekly.reviewButton}>
            <Button
              title="Review Test"
              width={"156"}
              height={"60"}
              color={"white"}
            />
          </div>
        </Link>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyleReviewWeekly = {
  reviewButton: {
    marginTop: "3em",
  },
};

export default withStyles(styles)(WeeklyTestReview);
export { WeeklyTestReview };
// Customizable Area End
