import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { webStyleAYQProfile } from "./AYQProfile.web";
import storage from "../../../framework/src/StorageProvider.web";
export type ProfileType = {
  data: {
    id: string;
    type: string;
    attributes: {
      name: string;
      first_name: string;
      last_name: string;
      phone_number: number;
      email: string;
      city: string;
      status: string;
      myrank_id: string;
      image: string;
      uuid: string;
      course_notification_enable: boolean;
      exam_notification_enable: boolean;
      package: Array<{
        id: number;
        account_id: number;
        pack_final_id: number;
        pack_pricing_id: number;
        packfinal_feature_id: string;
        ayq_level: number;
        lc_level: number;
        wb_level: number;
        bms_level: number;
        tution_level: number;
        acat_apply: number;
        bcat_apply: number;
        actual_price: number;
        amount: number;
        validity: string;
        expiry_date: string;
        sales_manager_name: string;
        agent1_name: string;
        agent2_name: string;
        transaction_type: string;
        status: string;
        created_by: string;
        created_at: string;
        updated_at: string;
        sms_level: string;
        active: boolean;
      }>;
    };
  };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleAYQProfile> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userProfile: null | ProfileType;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AYQProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getDataMessageId = "";
  breadcrumb = [
    {
      label: "Profile",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userProfile: null,
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getDataMessageId) {
      this.successfulFetchUserData(response);
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const userId = await storage.get("userId");
    this.getData(userId);
    const propPassingAPC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingAPC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingAPC);
  };

  successfulFetchUserData = (response: { profile: ProfileType }) => {
    if (response.profile) {
      this.setState({
        userProfile: response.profile,
      });
    }
  };

  navigateToEditProfile = () => {
    this.props.navigation.navigate("AskYourQueryEditProfile");
  };

  navigateToQuestionsAsked = () => {
    this.props.navigation.navigate("AskYourQueryQuestionsAsked");
  };

  navigateToBadges = () => {
    this.props.navigation.navigate("AskYourQueryBadges");
  };

  // Customizable Area Start
  getData = async (userId: string) => {
    const getDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });
    getDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/visit_user_profile?account_id=${userId}`
    );
    this.getDataMessageId = getDataMessage.messageId;
    getDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getDataMessage.id, getDataMessage);
  };
  // Customizable Area End
}
