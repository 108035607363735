export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backImg = require("../assets/back.png");
export const more = require("../assets/more.png");
export const searchImg = require("../assets/searchImg.png");
export const sorting = require("../assets/setting-4.png");
export const linearButton = require('../assets/button_filled.png')
export const filter = require("../assets/setting-5.png");
export const SortIcon = require("../assets/setting-4.png");
export const FilterIcon = require('../assets/setting-5.png');
export const button = require("../assets/button_filled.png");
export const AddButton = require("../assets/add_circle.png");
export const starIcon = require("../assets/starIcon.png");
export const emptyStar = require("../assets/emptyStar.png");
export const savedNotes = require("../assets/saveImg.png");
export const EditNotes = require("../assets/edit-2.png");
export const DeleteNotes = require("../assets/trash.png");
export const SubmitIcon = require('../assets/submitIcon.png');
export const startIcon = require('../assets/icons8-star-48.png');
export const warningIcon = require('../assets/icons8-warning-24.png');
export const filterBtn = require("../assets/setting-4.png");
export const sortBtn = require("../assets/setting-5.png");
