import React from "react";

import {
  Box as BoxDiv,
  // Customizable Area Start
  Button as MUIButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
import Button from "../../../components/src/Button.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import GrandTestAttemptsController from "./GrandTestAttemptsController.web";

export default class GrandTestAttempts extends GrandTestAttemptsController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <BoxDiv>
        <Loader loading={this.state.loading} />
        <BoxDiv sx={webStyle.bannerStyle}>
          <h2>{this.state.chapterName}</h2>
        </BoxDiv>
        <BoxDiv sx={webStyle.testAttemptsStyles}>
          {this.state.attempted.length > 0 && (
            <BoxDiv>
              <h2>Tests attempted</h2>
              <BoxDiv style={webStyle.chapterContainerStyle}>
                {this.state.attempted.map((attempted, index) => {
                  return (
                    <BoxDiv
                      style={{
                        ...webStyle.cardStyle,
                        background: "#F7FFF4",
                        border: "1px solid #55624D",
                      }}
                      key={`grandtest-${index}-attempted`}
                    >
                      <BoxDiv
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                      >
                        <img
                          src={attempted.attributes.image_url}
                          style={webStyle.imageStyle}
                          alt={`chapter-${attempted.id}`}
                        />
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginLeft: "1em",
                          }}
                        >
                          {attempted.attributes.test_name}
                        </p>
                      </BoxDiv>
                      <BoxDiv sx={webStyle.buttonStyles}>
                        <Link
                          to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                            "packageId"
                          )}/test/${attempted.id}`}
                        >
                          <Button
                            color="white"
                            width="121px"
                            height="40px"
                            title="Take Test"
                          />
                        </Link>
                        <Link
                          to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                            "packageId"
                          )}/review-test/${attempted.id}`}
                        >
                          <MUIButton style={webStyle.buttonStyle}>
                            Review Test
                            <BoxDiv
                              sx={webStyle.goBackStep}
                              data-test-id="gobackbtn"
                            >{`>`}</BoxDiv>
                          </MUIButton>
                        </Link>
                      </BoxDiv>
                    </BoxDiv>
                  );
                })}
              </BoxDiv>
            </BoxDiv>
          )}
          {this.state.unattempted.length > 0 && (
            <BoxDiv>
              <h2>Tests Unattempted</h2>
              <BoxDiv style={webStyle.chapterContainerStyle}>
                {this.state.unattempted.map((unattempted, index) => {
                  return (
                    <BoxDiv
                      style={webStyle.cardStyle}
                      key={`grandtest-${index}-unattempted`}
                    >
                      <BoxDiv
                        justifyContent={"flex-start"}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <img
                          src={unattempted.attributes.test_name}
                          alt={`chapter-${unattempted.id}`}
                          style={webStyle.imageStyle}
                        />
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginLeft: "1em",
                          }}
                        >
                          {unattempted.attributes.test_name}
                        </p>
                      </BoxDiv>
                      <BoxDiv sx={webStyle.buttonStyles}>
                        <Link
                          to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                            "packageId"
                          )}/test/${unattempted.id}`}
                        >
                          <Button
                            color="white"
                            width="121px"
                            height="40px"
                            title="Take Test"
                          />
                        </Link>
                        <Link
                          to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
                            "packageId"
                          )}/review-test/${unattempted.id}`}
                        >
                          <MUIButton style={webStyle.buttonStyle}>
                            Review Test
                            <BoxDiv
                              sx={webStyle.goBackStep}
                              data-test-id="gobackbtn"
                            >{`>`}</BoxDiv>
                          </MUIButton>
                        </Link>
                      </BoxDiv>
                    </BoxDiv>
                  );
                })}
              </BoxDiv>
            </BoxDiv>
          )}
        </BoxDiv>
      </BoxDiv>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  imageStyle: {
    height: "40px",
    width: "40px",
    borderRadius: "50%",
  },
  chapterContainerStyle: {
    display: "flex",
    gap: "1em",
    width: "100%",
    flexWrap: "wrap" as const,
  },
  buttonStyles: {
    gap: "2em",
    display: "flex",
    marginLeft: "3.5em",
  },
  goBackStep: {
    justifyContent: "center",
    display: "flex",
    width: "20px",
    alignItems: "center",
    padding: "0.3em",
    height: "20px",
    borderRadius: "50%",
    background:
      "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
    color: "white",
    position: "absolute",
    cursor: "pointer",
    right: "5px",
  },
  testAttemptsStyles: {
    display: "flex",
    padding: "2em 3em",
    gap: "2em" as const,
    flexDirection: "column",
  },
  buttonStyle: {
    color: "rgb(82, 0, 130)",
    textTransform: "none" as const,
    border: "1px solid #FF9486",
    height: "40px",
    display: "flex",
    width: "125px",
    position: "relative" as const,
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "10px",
  },
  bannerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    color: "rgb(82, 0, 130)",
    position: "relative",
  },
  cardStyle: {
    width: "500px",
    height: "140px",
    flexDirection: "column" as const,
    display: "flex",
    border: "1px solid rgba(255, 63, 89, 1)",
    justifyContent: "center",
    padding: "4em 0.7em",
    borderRadius: "0.5em",
    marginTop: "2em",
    gap: "1em",
  },
};
// Customizable Area End
