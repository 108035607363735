import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
type CollegeType = {
  id: string;
  type: string;
  attributes: {
    id: number;
    college_name: string;
    state_name: string;
    councelling_id: any;
    contact1: string;
    district: string;
    hostel: string;
    established: number;
    website: string;
    email: string;
    address: string;
    remarks: string;
    status: string;
    link_myrank: string;
    wikipedia: string;
    contact2: string;
    region: string;
    college_type: string;
    college_type2: string;
    remarks_2: string;
    courses: {
      data: Array<{
        id: string;
        type: string;
        attributes: {
          id: number;
          course_name: string;
          duration: number;
          status: string;
          degree: string;
          jee_id: string | null;
          mpc: string;
          bipc: string;
          mbipc: string;
          english: string;
          mec: string;
          cec: string;
          hec: string;
          hslc: string;
          specialization: string;
          scope: string;
          short_code: string;
          remarks: string;
          remarks_deletion: string;
        };
      }>;
    };
    universities: {
      data: Array<{
        id: string;
        type: string;
        attributes: {
          id: number;
          univ_name: string;
          established: number;
          state: string;
          address: string;
          univ_type: string;
          status: string;
          website: string;
          link_myrank: string;
          wikipedia: string;
          contact1: string;
          contact2: string;
          email: string;
          description: string;
          remarks: string;
          application_status: string | null;
        };
      }>;
    };
  };
};

export type CourseType = {
  id: string;
  type: string;
  attributes: {
    id: number;
    course_name: string;
    duration: number;
    status: string;
    degree: string;
    jee_id: string | number | null;
    mpc: string;
    bipc: string | number | null;
    mbipc: string | number | null;
    english: string | number | null;
    mec: string | number | null;
    cec: string | number | null;
    hec: string | number | null;
    hslc: string | number | null;
    specialization: string;
    scope: string;
    short_code: string;
    remarks: string;
    remarks_deletion: string;
    created_at: string;
    updated_at: string;
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userInput: {
    categoryAnalysis: string;
    categoryAnalysisCollege: string;
    categoryAnalysisCourse: string;
  };
  userRounds: {
    roundAnalysis: string;
    roundAnalysisCollege: string;
    roundAnalysisCourse: string;
  };
  userState: {
    stateAnalysis: string;
    stateAnalysisCollege: string;
    stateAnalysisCourse: string;
  };
  collegeAnalysis: string;
  courseAnalysis: string;
  rounds: Array<{ value: string; label: string }>;
  category: Array<{ title: string; key: string }>;
  courseList: Array<CourseType>;
  collegeList: Array<CollegeType>;
  loading: boolean;
  mpc_data: string;
  year: string;
  activeMPCName: string;
  states: Array<string>;
  university: Array<{ value: string; label: string }>;
  userUniversity: {
    universityAnalysis: string;
    universityAnalysisCollege: string;
    universityAnalysisCourse: string;
  };
  categorySet2: Array<{ title: string; key: string }>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MPCGuidanceCutOffsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCollegeListMessageId = "";
  getStateListMessageId = "";
  getCourseListMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      states: ["MADHYA PRADESH", "ANDHRA PRADESH"],
      collegeAnalysis: "",
      courseAnalysis: "",
      loading: false,
      activeMPCName: "",
      mpc_data: "",
      year: "",
      userInput: {
        categoryAnalysis: "",
        categoryAnalysisCollege: "",
        categoryAnalysisCourse: "",
      },
      userRounds: {
        roundAnalysis: "",
        roundAnalysisCollege: "",
        roundAnalysisCourse: "",
      },
      rounds: [
        { value: "0", label: "Over All" },
        { value: "1", label: "Round 1" },
        { value: "2", label: "Round 2" },
        { value: "3", label: "Round 3" },
        { value: "4", label: "Round 4" },
        { value: "5", label: "Round 5" },
        { value: "6", label: "Round 6" },
      ],
      category: [
        { key: "GN", title: "GN (General Category)" },
        { key: "EWS", title: "EWS (Economically Weaker Sections)" },
        { key: "OBC", title: "OBC (Other Backward Castes Category)" },
        { key: "SC", title: "SC (Scheduled Castes)" },
        { key: "ST", title: "ST (Scheduled Tribes)" },
        { key: "GN_PH", title: "PH - GN (General Category)" },
        { key: "EWS_PH", title: "PH - EWS (Economically Weaker Sections)" },
        { key: "OBC_PH", title: "PH - OBC (Other Backward Castes Category)" },
        { key: "SC_PH", title: "PH - SC (Scheduled Castes)" },
        { key: "ST_PH", title: "PH - ST (Scheduled Tribes)" },
      ],
      categorySet2: [
        { key: "GN", title: "GN(General Category)" },
        { key: "BCA", title: "BC - A(Backward Castes - A)" },
        { key: "BCB", title: "BC - B(Backward Castes - B)" },
        { key: "BCC", title: "BC - C(Backward Castes - C)" },
        { key: "BCD", title: "BC - D(Backward Castes - D)" },
        { key: "BCE", title: "BC - E(Backward Castes - E)" },
        { key: "SC", title: "SC(Scheduled Castes)" },
        { key: "ST", title: "ST(Scheduled Tribes)" },
        { key: "MSM", title: "MSM(Muslim Minority)" },
        { key: "CHR", title: "CHR(Christian Minority)" },
      ],
      university: [
        { value: "OU", label: "Osmania University" },
        { value: "AU", label: "Andhra University" },
        { value: "SVU", label: "Sri Venkateswara University" },
        { value: "NL", label: "Non Local" },
      ],
      userState: {
        stateAnalysis: "",
        stateAnalysisCollege: "",
        stateAnalysisCourse: "",
      },
      userUniversity: {
        universityAnalysis: "",
        universityAnalysisCollege: "",
        universityAnalysisCourse: "",
      },
      collegeList: [],
      courseList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getCollegeListMessageId && response) {
      if (response.data) {
        this.successfulCollegeFetch(response);
      }
    }

    if (apiRequestCallId === this.getCourseListMessageId && response) {
      if (response.data) {
        this.successfulCourseFetch(response);
      }
    }

    if (apiRequestCallId === this.getStateListMessageId && response) {
      if (response.length > 0) {
        this.successfulStateFetch(response);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getCollegeList();
    this.getMPCYear();
    this.getCourseList();
    this.getStateList();
    const mpcData = this.props.navigation.getParam("mpc_data");
    this.setMpcActive(mpcData);
  };

  successfulStateFetch = (response: Array<string>) => {
    this.setState({
      states: response,
    });
  };

  handleChangeState = (
    eventObject: React.ChangeEvent<{
      value: unknown;
    }>,
    analysisType:
      | "stateAnalysis"
      | "stateAnalysisCollege"
      | "stateAnalysisCourse"
  ) => {
    const selectedValue = eventObject.target.value;
    this.setState({
      userState: {
        ...this.state.userState,
        [analysisType]: selectedValue as string,
      },
    });
  };

  setMpcActive = (mpcData: string) => {
    this.setState({
      activeMPCName: mpcData || "",
    });
  };

  successfulCourseFetch = (response: { data: Array<CourseType> }) => {
    this.setState({
      courseList: response.data,
      loading: false,
    });
  };

  successfulCollegeFetch = (response: { data: Array<CollegeType> }) => {
    this.setState({
      collegeList: response.data,
      loading: false,
    });
  };

  // Customizable Area Start
  getMPCYear = () => {
    this.setState({
      mpc_data: this.props.navigation.getParam("mpc_data"),
      year: this.props.navigation.getParam("year"),
    });
  };

  handleChangeCategory = (
    eventObject: React.ChangeEvent<{
      value: unknown;
    }>,
    analysisType:
      | "categoryAnalysis"
      | "categoryAnalysisCollege"
      | "categoryAnalysisCourse"
  ) => {
    const selectedValue = eventObject.target.value;
    this.setState({
      userInput: {
        ...this.state.userInput,
        [analysisType]: selectedValue as string,
      },
    });
  };

  handleChangeUnivesity = (
    eventObject: React.ChangeEvent<{
      value: unknown;
    }>,
    analysisType:
      | "universityAnalysis"
      | "universityAnalysisCollege"
      | "universityAnalysisCourse"
  ) => {
    const selectedValue = eventObject.target.value;
    this.setState({
      userUniversity: {
        ...this.state.userUniversity,
        [analysisType]: selectedValue as string,
      },
    });
  };

  handleChangeRound = (
    eventObject: React.ChangeEvent<{
      value: unknown;
    }>,
    analysisType:
      | "roundAnalysis"
      | "roundAnalysisCollege"
      | "roundAnalysisCourse"
  ) => {
    const selectedValue = eventObject.target.value;
    this.setState({
      userRounds: {
        ...this.state.userRounds,
        [analysisType]: selectedValue as string,
      },
    });
  };

  navigateToAnalysisMPC = (
    event: React.FormEvent<HTMLFormElement>,
    dataToPass: {
      category: string;
      round?: string;
      univId?: string;
      stateId?: string;
      collegeId?: string;
      courseId?: string;
    }
  ) => {
    event.preventDefault();

    /*
        ROUTE FOR ANALYSIS
        /dashboard-guidance/cut-off-analysis/mpc-guidance/cut-offs/:year/:mpc_data/analysis/:category/:round?/:collegeId?/:courseId?/:stateId?/:univId
    */

    const mpc_data = this.props.navigation.getParam("mpc_data");
    const year = this.props.navigation.getParam("year");
    const { category, collegeId, courseId, round, stateId, univId } =
      dataToPass;

    let defaultValues: {
      mpc_data: string;
      year: string;
      category: string;
      round?: string;
      stateId?: string;
      univId?: string;
    } = {
      mpc_data,
      year,
      category,
    };

    if (mpc_data === "jeemainsjosaa" || mpc_data === "jeemainscsab") {
      defaultValues = {
        ...defaultValues,
        stateId,
        round,
      };
    }

    if (mpc_data === "tseamcetengg" || mpc_data === "apeamcetengg") {
      defaultValues = {
        ...defaultValues,
        univId,
      };
    }

    if (courseId && category) {
      return this.props.navigation.navigate("MPCGuidanceAnalysis", {
        ...defaultValues,
        courseId,
        category,
      });
    }

    if (collegeId && category) {
      return this.props.navigation.navigate("MPCGuidanceAnalysis", {
        ...defaultValues,
        collegeId,
        category,
      });
    }

    if (category) {
      return this.props.navigation.navigate("MPCGuidanceAnalysis", {
        ...defaultValues,
        category,
      });
    }
  };

  handleCollegeSelect = (
    eventObject: React.ChangeEvent<{
      value: unknown;
    }>
  ) => {
    const selectedValue = eventObject.target.value;
    this.setState({
      collegeAnalysis: selectedValue as string,
    });
  };

  handleCourseSelect = (
    eventObject: React.ChangeEvent<{
      value: unknown;
    }>
  ) => {
    const selectedValue = eventObject.target.value;
    this.setState({
      courseAnalysis: selectedValue as string,
    });
  };

  getCollegeList = async () => {
    this.setState({
      loading: true,
    });

    const getCollegeList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getCollegeListMessageId = getCollegeList.messageId;

    getCollegeList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getCollegeList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allColleges
    );

    getCollegeList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getCollegeList.id, getCollegeList);
  };

  getCourseList = async () => {
    this.setState({
      loading: true,
    });

    const mpcdata = this.props.navigation.getParam("mpcData");
    const year = this.props.navigation.getParam("year");

    const getCourseListMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getCourseListMessageId = getCourseListMessage.messageId;

    getCourseListMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getCourseListMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tutorials2/course_list?exam_name=${mpcdata}_${year}`
    );

    getCourseListMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getCourseListMessage.id, getCourseListMessage);
  };

  getStateList = async () => {
    this.setState({
      loading: true,
    });

    const mpcdata = this.props.navigation.getParam("mpcData");
    const year = this.props.navigation.getParam("year");

    const getStateListMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getStateListMessageId = getStateListMessage.messageId;

    getStateListMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getStateListMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tutorials2/state_list?exam_name=${mpcdata}_${year}`
    );

    getStateListMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getStateListMessage.id, getStateListMessage);
  };
  // Customizable Area End
}
