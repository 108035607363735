import React from "react";

import {
  Box,
  // Customizable Area Start
  Typography,
  Button,
  withStyles,
  createStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
// Customizable Area End

import ApplicationFlowController, {
  IApplicationNavigations,
  Props,
} from "./ApplicationFlowController.web";

class ApplicationFlow extends ApplicationFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  applicationRender = (application: IApplicationNavigations) => {
    return (
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
        className={this.props.classes.applicationBoxStyle}
        key={application.name}
      >
        <Typography variant="h6">{application.name}</Typography>
        <Button
          className={this.props.classes.buttonStyleView}
          data-test-id="navigate-btn"
          onClick={() => this.navigateTo(application.link)}
        >
          View
        </Button>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <BannerWeb title="Applications" />
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          gridGap="2.5em"
          padding={"2em"}
        >
          {this.applications.map((application) => {
            return this.applicationRender(application);
          })}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleApplicationFlow = () =>
  createStyles({
    buttonStyleView: {
      width: "160px",
      height: "50px",
      border: "none",
      color: "white",
      background: "#520082",
      borderRadius: "0.6em",
      "&:hover": {
        background: "rgb(92,10,140)",
      },
    },
    applicationBoxStyle: {
      width: "100%",
      height: "190px",
      background: "rgb(239,234,250)",
      padding: "0 4em",
    },
  });
export default withStyles(webStyleApplicationFlow)(ApplicationFlow);
export { ApplicationFlow };
// Customizable Area End
