import React from "react";

import {
  Box,
  // Customizable Area Start
  Typography,
  withStyles,
  createStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import BannerWeb from "../../../components/src/Banner.web";
import Loader from "../../../components/src/Loader.web";
import StarIcon from "@material-ui/icons/Star";
import Pagination from "@material-ui/lab/Pagination";
import { IApplicationOpened } from "./ApplicationNewOpeningController.web";
// Customizable Area End

import ApplicationPendingController, {
  Props,
} from "./ApplicationPendingController.web";

class ApplicationPending extends ApplicationPendingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ApplicationRender = (pendingApplication: IApplicationOpened) => {
    return (
      <Box
        flexDirection="column"
        display="flex"
        width="45%"
        className={this.props.classes.applicationBoxStyle}
        minHeight="230px"
        height="max-content"
        gridGap={"0.5em"}
        key={pendingApplication.attributes.univ_name}
        padding="1.5em"
      >
        <Typography variant="h5">
          {pendingApplication.attributes.univ_name}
        </Typography>
        <Box alignItems={"center"} display={"flex"}>
          <LocationOnOutlinedIcon />
          <Typography variant="body1">
            {pendingApplication.attributes.address}
          </Typography>
        </Box>
        <Box alignItems={"center"} display={"flex"}>
          <StarIcon htmlColor={"#FAC530"} fontSize="small" />
          <Typography variant="body1">
            {pendingApplication.attributes.rating
              ? pendingApplication.attributes.rating
              : 0}{" "}
            / 5
          </Typography>
        </Box>
        <Typography variant="body1">
          {pendingApplication.attributes.univ_type} Estd.{" "}
          {pendingApplication.attributes.established}
        </Typography>
        <Box className={this.props.classes.pendingBox}>Pending</Box>
        <Box className={this.props.classes.continueBtn}>
          <Typography>
            Continue <Box className={this.props.classes.backIcon}>{">"}</Box>
          </Typography>
        </Box>
      </Box>
    );
  };

  PendingApplicationPage = (application: IApplicationOpened[]) => {
    return (
      <Box
        flexWrap={"wrap"}
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        marginBottom={"5em"}
        gridGap={"2.5em"}
      >
        {application.map((application) => {
          return this.ApplicationRender(application);
        })}
      </Box>
    );
  };
  // Customizable Area End

  render() {
    const { pendingApplications, currentPageApplication } = this.state;

    const itemsPerPage = 10;
    const indexOfLastItem = currentPageApplication * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = pendingApplications.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    const totalPages = Math.ceil(pendingApplications.length / itemsPerPage);

    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <BannerWeb title="Pending Applications" />
        <Box padding={"2em"}>
          <Typography variant="h5" className={this.props.classes.pendingHeader}>
            You have {this.state.pendingApplications.length} Pending
            Applications
          </Typography>
          {this.PendingApplicationPage(currentItems)}
        </Box>
        <Box
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          marginBottom={"5em"}
          flexDirection={"row"}
        >
          <Pagination
            count={totalPages}
            data-test-id="pagination"
            page={this.state.currentPageApplication}
            className={this.props.classes.muiPaginationStyle}
            onChange={(_: unknown, value: number) =>
              this.handlePageChangeApplication(value)
            }
          />
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleApplicationPending = () =>
  createStyles({
    buttons: {
      border: "1px solid #FF5C5C",
      color: "#FF5C5C",
      padding: "0.7em 2em",
    },
    active: {
      color: "white",
      background: "#FF5C5C",
      "&:hover": {
        background: "#FF5C5C",
      },
    },
    pendingBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "0.5em",
      color: "white",
      background: "#A61E1E",
      width: "120px",
      height: "50px",
      "&:hover": {
        background: "#A61E1E",
      },
    },
    applicationBoxStyle: {
      border: "1px solid gray",
      position: "relative",
    },
    continueBtn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      bottom: "15px",
      right: "15px",
      cursor: "pointer",
      "& p": {
        fontWeight: 500,
        color: "rgb(255,92,92)",
      },
    },
    muiPaginationStyle: {
      width: "max-content",
      borderRadius: "4px",
      position: "relative",
      border: "1px solid rgba(255, 58, 87, 0.7)",
      "& li": {
        color: "white",
        width: "35px",
        background: "white",
        height: "41px",
        borderRadius: "2px",
      },
      "& li div": {
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        background: "none",
        display: "flex",
      },

      "& li:nth-last-child(1)": {
        background: "transparent",
        position: "absolute",
        right: -20,
        top: 10,
        zIndex: 999,
        "& button": {
          height: "max-content",
          width: "max-content",
        },
        "& button svg": {
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          borderRadius: "50%",
          "& path": {
            fill: "white",
          },
        },
        "& button.Mui-disabled": {
          display: "none",
        },
      },
      "& li button:hover": {
        background: "none",
      },
      "& li button": {
        height: "100%",
        margin: 0,
        alignItems: "center",
        width: "100%",
        background: "none",
        display: "flex",
        justifyContent: "center",
        padding: 0,
      },
      "& li:nth-child(1)": {
        zIndex: 999,
        position: "absolute",
        left: -20,
        top: 10,
        background: "transparent",
        "& button": {
          height: "max-content",
          width: "max-content",
        },
        "& button.Mui-disabled": {
          display: "none",
        },
        "& button svg": {
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          borderRadius: "50%",
          "& path": {
            fill: "white",
          },
        },
      },

      "& li button.Mui-selected": {
        color: "white",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        fontWeight: 500,
        borderRadius: "2px",
      },
    },
    backIcon: {
      borderRadius: "50%",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      marginRight: "0.3em",
      width: "24px",
      textAlign: "center",
      height: "24px",
      marginLeft: "0.5em",
      color: "white",
      display: "inline-block",
    },
    pendingHeader: {
      textAlign: "center",
      margin: "2em",
    },
  });
export default withStyles(webStyleApplicationPending)(ApplicationPending);
export { ApplicationPending };
// Customizable Area End
