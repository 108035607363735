import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import CalendarComponent from "../../../components/src/Calendar.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import BookMySlotDateTimePickController, {
  Props,
  configJSON,
} from "./BookMySlotDateTimePickController.web";

class BookMySlotDateTimePick extends BookMySlotDateTimePickController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box
        width={"100%"}
        alignItems={"center"}
        minHeight={"100vh"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Loader loading={this.state.loading} />
        <BannerWeb title="Select Date and Time" />
        <Box width={"70%"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            margin={"2em"}
          >
            {this.state.slotId ? (
              <Typography className={this.props.classes.selectDateNamePrevious}>
                Your previous slot has been booked on{" "}
                {this.state.slotDetails?.attributes.slot_date}
              </Typography>
            ) : (
              <Typography className={this.props.classes.selectDateName}>
                {configJSON.selectDate}
              </Typography>
            )}

            <Box marginTop="2em">
              <CalendarComponent
                onChangeDate={(value) => this.changeDateChange(value)}
              />
            </Box>
          </Box>
          <Box>
            <Typography className={this.props.classes.availableSlotsHeader}>
              Available Slots (
              {this.state.slots.map((timeSlots) => {
                if (timeSlots.date === this.state.valueDate) {
                  return timeSlots.slots.length;
                }
              })}
              )
            </Typography>
            <Box
              display={"flex"}
              gridGap={"1em"}
              flexWrap={"wrap"}
              margin={"1em 0"}
            >
              {this.state.slots.map((timeSlots) => {
                if (timeSlots.date === this.state.valueDate) {
                  return timeSlots.slots.map((slots) => {
                    return (
                      <Button
                        onClick={() => this.setTimeSlot(slots.slot_time)}
                        data-test-id="time-slot"
                        className={`${this.props.classes.slotTime} ${
                          this.state.timeSlot === slots.slot_time
                            ? "active"
                            : ""
                        } ${
                          slots.availability === "notavailable"
                            ? "inactive"
                            : ""
                        }`}
                        disabled={slots.availability === "notavailable"}
                      >
                        {slots.slot_time}
                      </Button>
                    );
                  });
                }
              })}
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Button
              onClick={() => this.goToNewSlots()}
              data-test-id="gotoNewSlots"
              className={this.props.classes.update}
            >
              Update and Go back
            </Button>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleBookMySlotDateTimePick = () =>
  createStyles({
    availableSlotsHeader: {
      fontSize: "1.2em",
      fontWeight: 700,
    },
    slotTime: {
      border: "1px solid #FF4E5F",
      "&.active": {
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        border: "none",
        color: "white",
      },
      "&.inactive": {
        background: "red",
        border: "none",
        color: "white",
      },
    },
    update: {
      borderRadius: "0.5em",
      width: "400px",
      color: "white",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      height: "70px",
      margin: "2em 0 3em 0",
      fontSize: "20px",
    },
    selectDateName: {
      alignSelf: "flex-start",
      fontSize: "1.3em",
      fontWeight: 700,
    },
    selectDateNamePrevious: {
      alignSelf: "center",
      fontSize: "1.5em",
      fontWeight: 700,
    },
  });
export default withStyles(webStyleBookMySlotDateTimePick)(
  BookMySlotDateTimePick
);
export { BookMySlotDateTimePick };
// Customizable Area End
