import React from "react";
import { MathJaxContext, MathJax } from "better-react-mathjax";

const MathJaxBox = ({ content }: { content: string }) => {
  return (
    <MathJaxContext>
      <MathJax>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </MathJax>
    </MathJaxContext>
  );
};

export default MathJaxBox;
