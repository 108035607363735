import React from "react";

import {
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";
import { myrankLogo } from "../../email-account-login/src/assets";

export default class Footer extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className="footer-container">
        <Box className="footer">
          <Box>
            <Box className="myrankfooter">
              <img src={myrankLogo} alt="myranklogo" />
              <p>
                Top learning experiences that create more talent in the world.
              </p>
            </Box>
            <Box className="navigationfooter">
              <Box>
                <h4>Product</h4>
                <ul>
                  <li>AYQ</li>
                  <li>FAQ</li>
                  <li>Pricing</li>
                  <li>Articles</li>
                </ul>
              </Box>
              <Box>
                <h4>Company</h4>
                <ul>
                  <li>About Us</li>
                  <li>Notifications</li>
                  <li>Login</li>
                  <li>Blog</li>
                </ul>
              </Box>
              <Box>
                <h4>Social</h4>
                <ul>
                  <li>Twitter</li>
                  <li>LinkedIn</li>
                  <li>Instagram</li>
                  <li>Facebook</li>
                </ul>
              </Box>
              <Box>
                <h4>Legal</h4>
                <ul>
                  <li>Terms</li>
                  <li>Privacy</li>
                  <li>Cookies</li>
                  <li>Contact</li>
                </ul>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="footer-bottom">
          <Box className="footer-last">
            <Box className="footer2023">
              <p>&copy; 2023 MyRank. All rights reserved.</p>
            </Box>
            <Box>
              <TwitterIcon />
              <LinkedInIcon />
              <FacebookIcon />
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
