import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

type MetaType = {
  weekly_test: {
    id: number;
    test_name: string;
    test_subject: string;
    test_category: string;
    no_of_questions: number;
    duration: number;
    start_time: string;
    end_time: string;
    status: string;
    remarks: string;
    created_by: string;
    created_at: string;
    updated_at: string;
  };
};

export interface TestType {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  question_no: number;
  question_1: string;
  question_2: string;
  passage: string;
  question_type: string;
  correct_answer: string;
  explanation: string;
  p_mark: string;
  n_mark: string;
  option_count: number;
  no_of_users_correct: number;
  no_of_users_attempted: number;
  percentage_people_correct: number;
  options: Option[];
  user_answer: string | null;
  is_correct: boolean;
  is_unattended: boolean | null;
}

export interface Option {
  option: string;
  id: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  timeRemaining: number;
  testData: TestType[];
  showModalSubmit: boolean;
  loading: boolean;
  testDataIndex: number;
  endTime: number;
  meta: MetaType | null;
  answers: {
    legend?: string;
    is_marked?: boolean;
    question_id: number;
    option: string[][];
  }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GuestWeeklyTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTestMessageId = "";
  intervalId: NodeJS.Timeout = setTimeout(() => {}, 0);
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.tick = this.tick.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      meta: null,
      showModalSubmit: false,
      answers: [],
      testDataIndex: 0,
      testData: [],
      endTime: 0,
      loading: false,
      timeRemaining: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getTestMessageId && response) {
      if (response?.data) {
        this.handleDataFromApiForTest(response);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const testId = this.props.navigation.getParam("testId");
    this.getTest(testId);
  };

  componentDidUpdate(_: Props, prevState: S) {
    if (prevState.testDataIndex !== this.state.testDataIndex) {
      this.submitOverviewPerQuestion(prevState.testDataIndex);
    }
    if (
      JSON.stringify(prevState.answers) !==
        JSON.stringify(this.state.answers) ||
      JSON.stringify(prevState.testDataIndex) !==
        JSON.stringify(this.state.testDataIndex)
    ) {
      this.handleAnswersChanged(prevState);
    }
  }

  // Customizable Area Start
  handleDataFromApiForTest = (response: {
    data: TestType[];
    meta: MetaType;
  }) => {
    const endTime = Date.now() + response.meta.weekly_test.duration * 1000;
    const timeRemaining = Math.max(endTime - Date.now(), 0);
    this.handleTest(response.data);
    this.handleMeta(response.meta);
    this.handleTimerStart(endTime, timeRemaining);
  };

  submitOverviewPerQuestion = (prevIndex: number) => {
    let currentAnswered = this.state.answers[prevIndex];
    const isAnswered = currentAnswered?.option?.[0]?.[0]?.length > 0;
    const legend = currentAnswered?.legend;

    if (!isAnswered && legend === undefined) {
      currentAnswered = { ...currentAnswered, legend: "un_answered" };
    }

    const answers = this.state.answers;
    answers[prevIndex] = currentAnswered;

    this.setState({
      answers,
    });
  };

  handlePreviousQuestion = () => {
    if (this.state.testDataIndex !== 0) {
      this.setState({
        testDataIndex: this.state.testDataIndex - 1,
      });
    }
  };

  handleSpecificQuestion = (index: number) => {
    this.setState({
      testDataIndex: index,
    });
  };

  handleNextQuestion = () => {
    if (this.state.testDataIndex !== this.state.testData.length - 1) {
      this.setState({
        testDataIndex: this.state.testDataIndex + 1,
      });
    }
  };

  tick = () => {
    const newTimeRemaining = Math.max(this.state.endTime - Date.now(), 0);
    this.setState({ timeRemaining: newTimeRemaining });

    if (newTimeRemaining <= 0) {
      this.timeReachedZero();
      clearInterval(this.intervalId);
    }
  };

  handleTimerStart = (endTime: number, timeRemaining: number) => {
    this.setState(
      {
        endTime,
        timeRemaining,
      },
      () => {
        this.intervalId = setInterval(this.tick, 1000);
      }
    );
  };

  getBtnClassName = (index: number) => {
    const currentAnswer = this.state.answers[index];

    if (currentAnswer?.legend === "answered") {
      return "btn-answered";
    }
    if (currentAnswer?.legend === "un_answered") {
      return "btn-unanswered";
    }
    if (currentAnswer?.legend === "answered_and_marked_for_review") {
      return "btn-answered-marked";
    }
    if (currentAnswer?.legend === "not_answered_and_marked_for_review") {
      return "btn-unanswered-marked";
    }
    if (currentAnswer?.legend === "marked_and_un_marked_for_review") {
      return "btn-marked-unmarked";
    }
    if (currentAnswer?.legend === "answered_and_cleared_the_answer") {
      return "btn-answered-cleared";
    }

    return "";
  };

  setAnswer = (index: number, option: string[][]) => {
    const answers = [...this.state.answers];

    answers[index] = {
      ...this.state.answers[index],
      question_id: this.state.testData[index].attributes.id,
      option,
    };

    this.setState({ answers });
  };

  timeReachedZero = () => {
    this.showModalSubmit();
    clearInterval(this.intervalId);
  };

  clearResponse = () => {
    const answersArray = [...this.state.answers];

    answersArray[this.state.testDataIndex] = {
      ...answersArray[this.state.testDataIndex],
      option: [[]],
    };

    this.setState({
      answers: answersArray,
    });
  };

  showModalSubmit = () => {
    this.setState(
      {
        showModalSubmit: !this.state.showModalSubmit,
      },
      () => {
        clearInterval(this.intervalId);
      }
    );
  };

  handleAnswersChanged = (prevState: S) => {
    const { answers, testDataIndex } = this.state;
    const currentAnswers = { ...answers[testDataIndex] };

    const isAnswered = currentAnswers?.option?.[0]?.[0]?.length > 0;
    const isMarked = currentAnswers?.is_marked;

    if (isMarked) {
      currentAnswers.is_marked = true;
    } else {
      currentAnswers.is_marked = false;
    }

    if (isAnswered) {
      currentAnswers.legend = "answered";
    }

    if (isAnswered && isMarked) {
      currentAnswers.legend = "answered_and_marked_for_review";
    }

    if (!isAnswered && isMarked) {
      currentAnswers.legend = "not_answered_and_marked_for_review";
    }

    if (
      prevState.answers[testDataIndex]?.option?.[0]?.[0]?.length > 0 &&
      !currentAnswers?.option?.[0]?.[0]?.length
    ) {
      currentAnswers.legend = "answered_and_cleared_the_answer";
    }

    if (
      prevState.answers[testDataIndex]?.is_marked &&
      !currentAnswers.is_marked
    ) {
      currentAnswers.legend = "marked_and_un_marked_for_review";
    }

    answers[testDataIndex] = currentAnswers;

    this.setState({
      answers,
    });
  };

  markForReview = () => {
    const newAnswer = [...this.state.answers];

    newAnswer[this.state.testDataIndex] = {
      ...newAnswer[this.state.testDataIndex],
      is_marked: !newAnswer[this.state.testDataIndex].is_marked,
    };

    this.setState({
      answers: newAnswer,
    });
  };

  navigateToRegister = () => {
    this.props.navigation.history.push("/register");
  };

  getTest = async (testId: string) => {
    const getTestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      uuid: await storage.get("guestToken"),
    };

    this.setState({
      loading: true,
    });

    this.getTestMessageId = getTestMessage.messageId;

    getTestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getTestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    getTestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/${testId}/take_test`
    );

    runEngine.sendMessage(getTestMessage.id, getTestMessage);
  };

  handleMeta = (response: MetaType) => {
    this.setState({
      meta: response,
    });
  };

  handleTest = (response: TestType[]) => {
    this.setState({
      testData: response,
    });
  };
  // Customizable Area End
}
