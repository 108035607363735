import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
type QbType = {
  id: string;
  type: string;
  attributes: {
    id: number;
    subject_id: number;
    chapter_id: number;
    question_type: string;
    question_num: number;
    question: string;
    solution: string;
    db_type: string;
    created_at: string;
    updated_at: string;
  };
};

// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  questionTypes: { question_type: string }[];
  questions: QbType[];
  loading: boolean;
  activeButton: number;
  showModalSubmit: boolean;
  showModalReport: boolean;
  reportInput: string;
  reportSuccessModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GuestQbMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuestionsMessageId = "";
  getQuestionTypesMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      questionTypes: [],
      questions: [],
      loading: false,
      activeButton: 0,
      showModalSubmit: false,
      showModalReport: false,
      reportInput: "",
      reportSuccessModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const topicId = this.props.navigation.getParam("topicId");

    if (apiRequestCallId === this.getQuestionsMessageId && response) {
      if (response?.data) {
        this.setState({
          questions: response.data,
        });
      }
    }

    if (apiRequestCallId === this.getQuestionTypesMessageId && response) {
      if (response?.question_types) {
        this.getQuestions(topicId, response.question_types[0].question_type, 0);
        this.setState({
          questionTypes: response.question_types,
        });
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const topicIdFromParam = this.props.navigation.getParam("topicId");
    this.getQuestionTypes(topicIdFromParam);
  };

  // Customizable Area Start
  getQuestions = async (
    topicId: string,
    questionType: string,
    activeIndex: number
  ) => {
    this.setState({
      loading: true,
      activeButton: activeIndex,
    });
    const getQuestionsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      uuid: await storage.get("guestToken"),
    };
    getQuestionsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/${topicId}/qb_lists?question_type=${questionType}`
    );
    this.getQuestionsMessageId = getQuestionsMessage.messageId;
    getQuestionsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getQuestionsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getQuestionsMessage.id, getQuestionsMessage);
  };

  showModalSubmit = () => {
    this.setState({
      showModalSubmit: !this.state.showModalSubmit,
    });
  };

  navigateToRegister = () => {
    this.props.navigation.history.push("/register");
  };

  getQuestionTypes = async (topicId: string) => {
    this.setState({
      loading: true,
    });
    const getQuestionTypesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      uuid: await storage.get("guestToken"),
    };
    getQuestionTypesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/${topicId}/question_types`
    );
    this.getQuestionTypesMessageId = getQuestionTypesMessage.messageId;
    getQuestionTypesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getQuestionTypesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getQuestionTypesMessage.id, getQuestionTypesMessage);
  };

  handleReportModal = () => {
    this.setState({
      showModalReport: !this.state.showModalReport,
    });
  };

  handleReportSuccessModal = () => {
    this.setState({
      reportSuccessModal: !this.state.reportSuccessModal,
    });
  };

  handleReportInput = (value: string) => {
    this.setState({
      reportInput: value,
    });
  };

  sendReport = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.setState({
      reportSuccessModal: true,
      showModalReport: false,
    });
  };
  // Customizable Area End
}
