import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import Loader from "../../../components/src/Loader.web";
import { webStyle } from "./BookMySlotAddEditSlot.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

import BookMySlotUpdateSlotController, {
  Props,
  configJSON,
} from "./BookMySlotUpdateSlotController.web";

class BookMySlotUpdateSlot extends BookMySlotUpdateSlotController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const style = this.props.classes;
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Modal
          open={this.state.isSlotUpdatedModal}
          onClose={this.handleIsSlotUpdatedModal}
          data-test-id="modal-slot-booked"
        >
          <Box sx={webStyle.modalStyle}>
            <Typography>
              {this.state.cancelSlot
                ? configJSON.successCancelSlotBook
                : configJSON.successChangeSlotBook}
            </Typography>
            <Button style={webStyle.buttonStyle} onClick={this.navigateToSlotsScreen}>Go to Home</Button>
          </Box>
        </Modal>
        <BannerWeb title="Slot Booking" />
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          minHeight={"70vh"}
        >
          <Box
            width={"70%"}
            marginTop={"5em"}
            display={"flex"}
            flexDirection={"column"}
          >
            <Typography className={style.typographyStyle}>Slot Type</Typography>
            <Box position={"relative"}>
              <select
                value={this.state.selectedChoice}
                className={style.selectStyle}
              >
                <option value="change">Change Slot</option>
                <option value="cancel">Cancel Slot</option>
              </select>
              <ExpandMoreIcon className={style.expandMoreIcon} />
            </Box>
            <Box display={"flex"} gridGap={"2em"} margin={"2em 0 4em 0"}>
              <Box>
                <Typography className={style.typographyStyle}>
                  Previously Selected Slot and Time
                </Typography>
                <Box className={style.slotTimingStyle}>
                  <Typography className={style.typographyDateStyle}>
                    Date: {this.state.slotDetails?.attributes.slot_date}
                  </Typography>
                  <Typography className={style.typographyTimeStyle}>
                    {this.state.slotDetails?.attributes.time_slot}
                  </Typography>
                </Box>
              </Box>
              {!this.state.cancelSlot && (
                <Box>
                  <Typography className={style.typographyStyle}>
                    Updated Selected Slot and Time
                  </Typography>
                  <Box className={style.slotTimingStyle}>
                    <Typography className={style.typographyDateStyle}>
                      Date: {this.state.dateSlot}
                    </Typography>
                    <Typography className={style.typographyTimeUpdatedStyle}>
                      {this.state.timeSlot}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Box>
              <Typography className={style.typographyStyle}>
                Comments
              </Typography>
              <textarea
                onChange={(event) => this.setComments(event.target.value)}
                className={style.textAreaStyle}
                placeholder="Why do you want to change slot?"
                value={this.state.comments}
                data-test-id="textarea"
              />
            </Box>
            <Button
              className={style.submitButtonStyle}
              onClick={this.postSubmitChangeOrCancel}
              data-test-id="submit"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleBookMySlotUpdateSlot = () =>
  createStyles({
    typographyStyle: {
      fontWeight: 500,
      fontSize: "1.3em",
    },
    typographyDateStyle: {
      fontWeight: 500,
      fontSize: "1.2em",
    },
    typographyTimeStyle: {
      fontWeight: 500,
      fontSize: "1.2em",
      border: "none",
      borderRadius: "0.3em",
      padding: "0.4em 1em",
      background: "#ccc",
    },
    typographyTimeUpdatedStyle: {
      fontWeight: 500,
      fontSize: "1.2em",
      border: "none",
      borderRadius: "0.3em",
      padding: "0.4em 1em",
      background: "rgb(143,245,138)",
    },
    slotTimingStyle: {
      display: "flex",
      gap: "1.3em",
      marginTop: "1.5em",
      alignItems: "center",
    },
    selectStyle: {
      width: "100%",
      height: "70px",
      color: "#5C0F89",
      fontWeight: 500,
      fontSize: "1.4em",
      outline: 0,
      border: "1px solid #FF4D4F",
      borderRadius: "0.7em",
      paddingLeft: "1.5em",
      WebkitAppearance: "none",
      marginTop: "1.3em",
    },
    textAreaStyle: {
      width: "100%",
      resize: "none",
      height: "200px",
      border: "1px solid #FF4D4F",
      borderRadius: "0.7em",
      padding: "1.5em",
      fontSize: "1.2em",
      outline: 0,
      marginTop: "1.3em",
    },
    submitButtonStyle: {
      width: "400px",
      borderRadius: "0.5em",
      color: "white",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      height: "60px",
      margin: "2em 0 3em 0",
      alignSelf: "center",
    },
    expandMoreIcon: {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      color: "white",
      borderRadius: "50%",
      position: "absolute",
      right: "30px",
      top: "47px",
      cursor: "pointer",
    },
  });
export default withStyles(webStyleBookMySlotUpdateSlot)(BookMySlotUpdateSlot);
export { BookMySlotUpdateSlot };
// Customizable Area End
