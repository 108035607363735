import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface Root {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  name: string;
  image: string;
  practice_test: string;
  avail_pt: string;
  date_pt: any;
  class_zone: string;
  avail_cz: string;
  date_cz: any;
  question_bank: string;
  avail_qb: string;
  date_qb: any;
  created_by: string;
  remarks: string;
  chapter_read: boolean;
  viewed: boolean;
  continue_reading_topic_id: any;
  test_attempted: boolean;
}

import { WithStyles } from "@material-ui/core";
import { styles } from "./QuestionBankChapters.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof styles> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  chapters: Root[];
  subjectName: string;
  subjectId: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuestionBankChaptersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getChaptersMessageId = "";
  breadcrumb = [
    {
      label: "Question Bank",
      link: "/dashboard/question-bank",
    },
    {
      label: "Subjects",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      chapters: [],
      subjectName: "",
      subjectId: "",
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getChaptersMessageId) {
      if (response.data) {
        this.handleChaptersSuccess(response.data, response.meta.subject_name);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingQBCC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingQBCC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingQBCC);
    const subjectId = this.props.navigation.getParam("subjectId");
    this.getChapters(subjectId);
    this.setState({
      subjectId,
    });
  };

  componentDidUpdate = (prevProps: Props, prevState: S) => {
    if (this.props.navigation.getParam("subjectId") !== this.state.subjectId) {
      this.setState(
        {
          subjectId: this.props.navigation.getParam("subjectId"),
        },
        () => {
          this.getChapters(this.props.navigation.getParam("subjectId"));
        }
      );
    }
  };

  handleChaptersSuccess = (data: Root[], subjectName: string) => {
    const breadcrumb = this.breadcrumb;
    breadcrumb[1].label = subjectName;
    const propPassingQBCC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingQBCC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingQBCC);

    this.setState({
      chapters: data,
      subjectName,
    });
  };

  // Customizable Area Start
  getChapters = (subjectId: string) => {
    this.setState({
      loading: true,
    });
    const getChaptersMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: localStorage.getItem("authToken"),
    };
    getChaptersMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/${subjectId}/qb_chapters`
    );
    this.getChaptersMessageId = getChaptersMessage.messageId;
    getChaptersMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getChaptersMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getChaptersMessage.id, getChaptersMessage);
  };
  // Customizable Area End
}
