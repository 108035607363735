import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { IApplicationOpened } from "./ApplicationNewOpeningController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pendingApplications: IApplicationOpened[];
  loading: boolean;
  currentPageApplication: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApplicationPendingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPendingApplicationsMessageId = "";
  getAllApplicationsMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pendingApplications: [],
      loading: false,
      currentPageApplication: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getPendingApplicationsMessageId && response) {
      if (response?.data) {
        this.handleSuccessfulPendingApplicationsFetch(response.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getPendingApplications();
  };

  handleSuccessfulPendingApplicationsFetch = (data: IApplicationOpened[]) => {
    this.setState({
      loading: false,
      pendingApplications: data,
    });
  };

  handlePageChangeApplication = (value: number) => {
    this.setState({
      currentPageApplication: value,
    });
  };

  // Customizable Area Start
  getPendingApplications = async () => {
    this.setState({
      loading: true,
    });

    const getPendingApplicationsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };

    getPendingApplicationsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tutorials2/guidance_applications/applications_status?status=application_pending`
    );
    this.getPendingApplicationsMessageId =
      getPendingApplicationsMessage.messageId;
    getPendingApplicationsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPendingApplicationsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getPendingApplicationsMessage.id,
      getPendingApplicationsMessage
    );
  };
  // Customizable Area End
}
