import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  Grid,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
interface NavigationContainerType {
  name: string;
  link: string;
  image: string;
}
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Formik } from "formik";
import HomeIcon from "@material-ui/icons/Home";
import EventIcon from "@material-ui/icons/Event";
// Customizable Area End

import DashboardGuestHomeController, {
  Props,
  configJSON,
} from "./DashboardGuestHomeController.web";

class DashboardGuestHome extends DashboardGuestHomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  NavigationContainer = ({ name, link, image }: NavigationContainerType) => {
    return (
      <Grid
        key={name}
        item
        xs={6}
        sm={4}
        md={3}
        lg={3}
        className={this.props.classes.gridStyleBox}
      >
        <Box
          className={this.props.classes.containerBox}
          onClick={() => this.handleNavigationTo(link)}
          data-test-id="navigate-to"
        >
          <Box className="img-style">
            <img src={image} alt="name-btn" />
          </Box>
          <Typography>{name}</Typography>
        </Box>
      </Grid>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box width={"100%"}>
        <Modal
          onClose={this.handleSubmit}
          open={this.state.modalSubmitted}
          BackdropProps={{ onClick: undefined }}
          data-test-id="modal-submit"
        >
          <Box className={this.props.classes.modalStyle}>
            <CheckCircleIcon className={this.props.classes.checkIcon} />
            <h3>Slot is Booked</h3>
            <p>Or counselling officer will contact you shortly</p>
            <Box>
              <Button
                className={this.props.classes.muiButton}
                data-test-id="goback-btn"
                onClick={this.handleSubmit}
              >
                Go Back
              </Button>
            </Box>
          </Box>
        </Modal>
        <Box className={this.props.classes.buttonStyles}>
          <Button
            onClick={this.handleToHome}
            className={this.state.isHome ? "active" : ""}
            data-test-id="go-home"
          >
            <HomeIcon />
            {configJSON.home}
          </Button>
          <Button
            onClick={this.handleToBookMySlot}
            className={this.state.isHome ? "" : "active"}
            data-test-id="go-book"
          >
            <EventIcon />
            {configJSON.bookMySlot}
          </Button>
        </Box>
        {this.state.isHome ? (
          <Grid
            container
            spacing={10}
            className={this.props.classes.containerGrid}
            justifyContent="center"
            alignContent="center"
          >
            {this.navigationData.map((data) => {
              return this.NavigationContainer(data);
            })}
          </Grid>
        ) : (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            marginTop={"5em"}
          >
            <Formik
              initialValues={{
                nameInput: "",
                mobileInput: "",
                emailInput: "",
                messageInput: "",
              }}
              onSubmit={(values, { resetForm }) => {
                this.handleBookMySlotSubmit(values);
                resetForm();
              }}
              validationSchema={this.bookMySlotSchema}
              data-testid="formik"
            >
              {(props) => (
                <form
                  className={this.props.classes.formBox}
                  onSubmit={props.handleSubmit}
                >
                  <Box>
                    <label>Enter Name</label>
                    <input
                      placeholder="Enter Your name"
                      name="nameInput"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.nameInput}
                      data-test-id="name-input"
                      required
                    />
                  </Box>
                  <Box>
                    <label>Mobile Number</label>
                    <input
                      placeholder="Enter Mobile Number"
                      name="mobileInput"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.mobileInput}
                      data-test-id="mobile-input"
                      required
                    />
                  </Box>
                  <Box>
                    <label>Email ID</label>
                    <input
                      placeholder="Enter Email ID"
                      name="emailInput"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.emailInput}
                      data-test-id="email-input"
                      required
                    />
                  </Box>
                  <Box>
                    <label>Message</label>
                    <textarea
                      placeholder="Type what you want to ask for"
                      name="messageInput"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.messageInput}
                      data-test-id="message-input"
                      required
                    />
                  </Box>
                  <Button type="submit" data-test-id="submit-btn">
                    Submit
                  </Button>
                </form>
              )}
            </Formik>
          </Box>
        )}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleDashboardGuestHome = () =>
  createStyles({
    buttonStyles: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "1em",
      marginTop: "5em",
      "& button": {
        width: "300px",
        height: "70px",
        borderRadius: "0.7em",
        border: "none",
        background: "#ccc",
        color: "#333",
        fontSize: "1.6em",
        fontWeight: 500,
        "&:hover": {
          backgroundColor: "#ccc",
        },
      },
      "& .active": {
        background: "#520082",
        color: "white",
        "&:hover": {
          backgroundColor: "#520082",
        },
      },
    },
    containerGrid: {
      padding: "1em 7em",
      marginTop: "3em",
    },
    containerBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "1em",
      borderRadius: "1em",
      background: "#eee",
      height: "250px",
      width: "320px",
      cursor: "pointer",
      "& .img-style": {
        borderRadius: "50%",
        padding: "1.6em",
        width: "max-content",
        background: "#D4B0E5",
        "& img": {
          width: "40px",
        },
      },
      "& p": {
        fontWeight: 500,
        fontSize: "1.3em",
      },
    },
    gridStyleBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "3em",
      padding: "4em 5em",
      background: "#eee",
      marginBottom: "5em",
      width: "70%",
      "& button": {
        width: "300px",
        height: "60px",
        background:
          "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
        color: "white",
        fontWeight: 500,
        fontSize: "1.2em",
      },
      "& div": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "1.2em",
        "& label": {
          fontWeight: 500,
          fontSize: "1.3em",
        },
        "& input": {
          padding: "0.7em 1em 0.7em 1.2em",
          fontSize: "1.1em",
          color: "#222",
          borderRadius: "0.5em",
          border: "1px solid #FF425B",
          outline: "none",
          "&::placeholder": {
            color: "#bbb",
          },
        },
        "& textarea": {
          padding: "0.7em 1em 0.7em 1.2em",
          border: "1px solid #FF425B",
          outline: "none",
          resize: "none",
          fontSize: "1.1em",
          color: "#222",
          borderRadius: "0.5em",
          height: "200px",
          "&::placeholder": {
            color: "#bbb",
          },
        },
      },
    },
    muiButton: {
      width: "300px",
      height: "60px",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      color: "white",
      fontWeight: 500,
      fontSize: "1.2em",
    },
    modalStyle: {
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "center",
      transform: "translate(-50%, -50%)",
      width: "400px",
      minHeight: "max-content",
      alignItems: "center",
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      borderRadius: "1.5em",
      padding: "2em",
      gap: "1.5em",
      backgroundColor: "white",
      border: "2px solid #FFD0D8",
      outline: "none",
    },
    checkIcon: { color: "ff5d5b", fontSize: "4.5em" },
  });
export default withStyles(webStyleDashboardGuestHome)(DashboardGuestHome);
export { DashboardGuestHome };
// Customizable Area End
