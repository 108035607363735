import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

export interface ILiveClassTakenSubjects {
  id: string;
  type: string;
  attributes: {
    id: number;
    subject_name: string;
  };
}

export type ILiveClassStats = {
  attended_percentage: number;
  unattended_percentage: number;
  live_classes: Array<{
    id: number;
    category_name: string;
    subject_name: string;
  }>;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  liveClassStats: ILiveClassStats | null;
  liveClassSubjects: string[];
  selectedChoice: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UsageStatsGrandTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLiveClassStatsMessageId = "";
  getLiveClassTakenSubjectsMessageId = "";
  buttons = [
    "Synopsis",
    "Weekly Test",
    "Grand Test",
    "Practice Test",
    "Live Classes",
    "Recorded Classes",
    "Question Bank",
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      selectedChoice: "",
      liveClassStats: null,
      liveClassSubjects: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      apiRequestCallId === this.getLiveClassTakenSubjectsMessageId &&
      response
    ) {
      if (response?.data) {
        this.handleSuccessfulFetchTakenSubjects(response.data);
      }
    }

    if (apiRequestCallId === this.getLiveClassStatsMessageId && response) {
      if (response?.live_classes) {
        this.handleSuccessfulFetchStats(response);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getLiveClassTakenSubjects();
  };

  // Customizable Area Start

  getLiveClassTakenSubjects = async () => {
    const getLiveClassTakenSubjectsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getLiveClassTakenSubjectsMessageId =
      getLiveClassTakenSubjectsMessage.messageId;

    getLiveClassTakenSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getLiveClassTakenSubjectsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.liveClassPreviousClasses
    );

    getLiveClassTakenSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getLiveClassTakenSubjectsMessage.id,
      getLiveClassTakenSubjectsMessage
    );
  };
  handleSuccessfulFetchTakenSubjects = (
    response: ILiveClassTakenSubjects[]
  ) => {
    const uniqueSubjectsMap: { [x: string]: number } = {};
    const uniqueSubjects: string[] = [];

    response.forEach((data) => {
      if (uniqueSubjectsMap[data.attributes.subject_name] === undefined) {
        uniqueSubjectsMap[data.attributes.subject_name] = 1;
        uniqueSubjects.push(data.attributes.subject_name);
      }
    });

    this.setState({
      loading: false,
      liveClassSubjects: uniqueSubjects,
    });
  };

  getLiveClassStats = async (subjectName: string) => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getLiveClassStatsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLiveClassStatsMessageId = getLiveClassStatsMessage.messageId;

    getLiveClassStatsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_usageinsights/usage_stats/live_class_usage_stats?sub_name=${subjectName}`
    );

    getLiveClassStatsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getLiveClassStatsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getLiveClassStatsMessage.id,
      getLiveClassStatsMessage
    );
  };
  handleSuccessfulFetchStats = (response: ILiveClassStats) => {
    this.setState({
      liveClassStats: response,
    });
  };

  onChangeSelectSubject = (selectedChoice: string) => {
    this.setState(
      {
        selectedChoice,
      },
      () => {
        this.getLiveClassStats(selectedChoice);
      }
    );
  };
  // Customizable Area End
}
