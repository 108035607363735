import React from "react";

import {
  Box,
  Button,
  // Customizable Area Start
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Checkbox,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { CheckCircle } from "@material-ui/icons";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";

export default class SignupScreen extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const isOtpEmailError = this.state.isOtpEmailError && (
      <Box className="resend-otp">
        <p>Invalid OTP</p>
        <Button
          data-test-id="resend-email-otp-button"
          onClick={this.sendEmailOTP}
        >
          Resend OTP
        </Button>
      </Box>
    );
    const isOtpEmailVerified = this.state.isOtpEmailVerified;
    const isOtpMobileVerified = this.state.isOtpMobileVerified;
    const checkedTerms = this.state.checkedTerms;
    const paymentModeNotEmpty = this.state.userInput.paymentMode.length > 0;
    const validityNotEmpty = this.state.userInput.validity.length > 0;
    const isConfirmPasswordValid = this.state.isConfirmPasswordValid;
    const isConfirmTheSameAsPassword = this.state.isConfirmTheSameAsPassword;

    const isReadyToRegister = this.handleRegisterTruth(
      isOtpEmailVerified,
      isOtpMobileVerified,
      checkedTerms,
      paymentModeNotEmpty,
      validityNotEmpty,
      isConfirmPasswordValid,
      isConfirmTheSameAsPassword
    );

    let currentPrice = this.handleCurrentPrice(this.props.chosenPackageIndex);

    const termsAndCondition = this.state.terms.replace(
      /\{&#39;\\n&#39;\}/g,
      "<br />"
    );

    return (
      // Customizable Area Start
      <Box className="signup-form-register">
        <Modal
          open={this.state.modalTermsOpen}
          onClose={this.handleCloseModalTerms}
        >
          <Box sx={webStyle.modalStyle}>
            <div
              id="terms-and-condition"
              style={{ overflowY: "scroll" }}
              dangerouslySetInnerHTML={{ __html: termsAndCondition }}
            />
          </Box>
        </Modal>
        <Modal
          open={this.state.modalEmailExistOpen}
          onClose={this.handleCloseErrorEmailExistModal}
        >
          <Box sx={webStyle.modalStyle}>
            <h5>Email Account Already Activated. Please use a different one.</h5>
            <Button data-test-id="closemodal-emailexist" onClick={this.handleCloseErrorEmailExistModal}>Close</Button>
          </Box>
        </Modal>
        <Modal
          open={this.state.modalPaymentOpen}
          onClose={this.handleCloseModalPayment}
        >
          <Box sx={webStyle.modalStyle}>
            <h5>You're one step from getting your account registered</h5>
            <a data-test-id="to-payment" href={this.state.paymentLink} rel="noopener noreferrer">
              Proceed to Payment
            </a>
          </Box>
        </Modal>
        <Modal
          open={this.state.modalErrorRegisterOpen}
          onClose={this.handleCloseErrorRegisterModal}
        >
          <Box sx={webStyle.modalStyle}>
            <h5>The email or number you provided has already been registered.</h5>
            <Button data-test-id="error-post-register" onClick={this.handleCloseErrorRegisterModal}>
              Go Back
            </Button>
          </Box>
        </Modal>
        <Box className="registrationheadersignup">
          <h2>Registration: For {this.props.package?.attributes.pack_name}</h2>
          <p>
            Rs:
            {Number(currentPrice)}
            /year
          </p>
        </Box>
        <form
          data-test-id="submitSignup"
          onSubmit={(event) => this.signUpUser(event)}
        >
          <Box className="form-box">
            <p>Student Profile</p>
            <TextField
              data-test-id="name-input"
              name="name"
              label="Student's Name"
              type="text"
              onChange={(event) => this.handleChangeInput(event)}
              value={this.state.userInput.name}
              required
            />
            <Box className="getOTP">
              <TextField
                data-test-id="email-input"
                name="email"
                label="Email"
                type="email"
                onChange={(event) => this.handleChangeInput(event)}
                value={this.state.userInput.email}
                disabled={this.state.isOtpEmailVerified}
                required
              />
              {this.state.isOtpEmailVerified && (
                <Button data-test-id="email-verified-button">
                  <CheckCircle /> Verified
                </Button>
              )}
              {!this.state.emailOTPToken && (
                <Button
                  data-test-id="get-email-otp-button"
                  onClick={this.sendEmailOTP}
                >
                  GET OTP
                </Button>
              )}
            </Box>
            {this.state.emailOTPToken && !this.state.isOtpEmailVerified && (
              <Box className="verify">
                <input
                  data-test-id="email-otp-input"
                  name="emailOtp"
                  value={this.state.userInput.emailOtp}
                  onChange={(event) => this.handleChangeInput(event)}
                  placeholder="Enter OTP"
                  type="tel"
                  required
                />
                <Button
                  data-test-id="verify-email-otp-button"
                  onClick={this.verifyEmailOTP}
                >
                  Verify
                </Button>
                {isOtpEmailError}
              </Box>
            )}
            <Box className="getOTP">
              <TextField
                data-test-id="mobile-number-input"
                name="mobileNumber"
                label="Mobile Number"
                type="tel"
                onChange={(event) => this.handleChangeInput(event)}
                value={this.state.userInput.mobileNumber}
                disabled={this.state.isOtpMobileVerified}
                required
              />
              {this.state.isOtpMobileVerified && (
                <Button data-test-id="mobile-verified-button">
                  <CheckCircle /> Verified
                </Button>
              )}
              {!this.state.mobileOTPToken && (
                <Button
                  data-test-id="get-mobile-otp-button"
                  onClick={this.sendOTP}
                >
                  GET OTP
                </Button>
              )}
            </Box>
            {this.state.mobileOTPToken && !this.state.isOtpMobileVerified && (
              <Box className="verify">
                <input
                  data-test-id="mobile-otp-input"
                  name="numberOtp"
                  value={this.state.userInput.numberOtp}
                  onChange={(event) => this.handleChangeInput(event)}
                  placeholder="Enter OTP"
                  type="tel"
                  required
                />
                <Button
                  data-test-id="verify-mobile-otp-button"
                  onClick={this.verifyMobileOTP}
                >
                  Verify
                </Button>
                {this.state.isOtpMobileError && (
                  <Box className="resend-otp">
                    <p>Invalid OTP</p>
                    <Button
                      data-test-id="resend-mobile-otp-button"
                      onClick={this.sendOTP}
                    >
                      Resend OTP
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            <Box className="passwordcontainer">
              <TextField
                data-test-id="password-input"
                name="password"
                label="Password"
                type={this.state.showPassword ? "text" : "password"}
                onChange={(event) => this.handleChangeInput(event)}
                value={this.state.userInput.password}
                required
              />
              <Box
                data-test-id="peekbtn"
                className="peekbtn"
                onClick={this.handleClickShowPassword}
              >
                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
              </Box>
              {this.state.isPasswordValid === false && (
                <p>
                  Password must atleast be 4 in character length.
                </p>
              )}
            </Box>
            <Box className="passwordcontainer">
              <TextField
                data-test-id="confirm-password-input"
                name="confirmPassword"
                label="Confirm Password"
                type={this.state.showConfirmPassword ? "text" : "password"}
                onChange={(event) => this.handleChangeInput(event)}
                value={this.state.userInput.confirmPassword}
                required
              />
              {this.state.isConfirmPasswordValid === false && (
                <p>
                  Password must atleast be 4 in character length.
                </p>
              )}
              {this.state.isConfirmTheSameAsPassword === false && (
                <p>Confirm Password must be the same as password above.</p>
              )}
              <Box
                data-test-id="peekbtn"
                className="peekbtn"
                onClick={this.handleClickShowConfirmPassword}
              >
                {this.state.showConfirmPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </Box>
            </Box>
            <FormControl>
              <InputLabel data-test-id="validity-label" id="validity">
                Validity
              </InputLabel>
              <Select
                data-test-id="validity-select"
                labelId="validity"
                id="validity"
                name="validity"
                value={this.state.userInput.validity}
                onChange={(event) => this.handleChangeInput(event)}
                required
              >
                {
                  this.state.dates.map((date)=>{
                    return <MenuItem data-test-id="menu-date-item" key={date.date} value={date.date} onClick={()=>this.handleChangeDateMultiplier(date.multiplier)}>{date.date}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel data-test-id="payment-mode-label" id="paymentMode">
                Payment Mode
              </InputLabel>
              <Select
                data-test-id="payment-mode-select"
                labelId="paymentMode"
                id="paymentMode"
                name="paymentMode"
                value={this.state.userInput.paymentMode}
                onChange={(event) => this.handleChangeInput(event)}
                required
              >
                <MenuItem value={"ONLINE"}>ONLINE</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className="package-selected-terms-container">
            <Box className="package-selected">
              <h3>Package Selected By You</h3>
              <Box>
                <p>{this.props.package?.attributes.pack_name}{" "} {this.getValidity()}</p>
                <p>
                  Payment : Rs {Number(currentPrice)}
                  /year
                </p>
              </Box>
            </Box>
            <Box>
              <Checkbox
                data-test-id="checkboxTerms"
                checked={this.state.checkedTerms}
                onChange={this.handleCheckbox}
                inputProps={{ "aria-label": "controlled" }}
                required
              />{" "}
              I agree to{" "}
              <span
                data-test-id="terms-btn"
                style={{ cursor: "pointer" }}
                onClick={this.handleOpenModalTerms}
              >
                Terms and Conditions
              </span>
            </Box>
          </Box>
          <Button
            className="btn-submit"
            data-test-id="register-button"
            type="submit"
          >
            Register Now
          </Button>
        </form>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "2px solid #832890",
    boxShadow: 24,
    backgroundColor: "white",
    p: 4,
    borderRadius: "0.5em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
    maxHeight: "80%",
  },
};
// Customizable Area End
