import React from "react";

import {
  Box as MBox,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button as MButton,
  Modal as MModal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { submitIcon } from "./assets";
// Customizable Area End

import SynopsisGuestCheckController, {
  Props,
} from "./SynopsisGuestCheckController.web";

class SynopsisGuestCheck extends SynopsisGuestCheckController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MBox>
        <MModal
          open={this.state.isModalOpen}
          onClose={this.handleModalOpen}
          BackdropProps={{ onClick: undefined }}
        >
          <MBox className={this.props.classes.modalStyle}>
            <img src={submitIcon} alt="getstarted" />
            <h5>
              To get access to all subjects and more content you need to
              register
            </h5>
            <MBox display={"flex"} gridGap={"1em"}>
              <MButton
                className={this.props.classes.buttonStyleYes}
                data-test-id="navigate-btn"
                onClick={() => this.props.navigation.navigate("Register")}
              >
                Get Started
              </MButton>
              <MButton
                className={this.props.classes.buttonStyleNo}
                data-test-id="cancel-btn"
                onClick={this.handleModalOpen}
              >
                Cancel
              </MButton>
            </MBox>
          </MBox>
        </MModal>
        <MBox className={this.props.classes.bannerStyle}>
          <Typography variant="h6" style={{ margin: "0 auto" }}>
            Chapter - {this.state.meta?.chapter.name}
          </Typography>
        </MBox>
        <MBox padding={"2em"}>
          <MBox className={this.props.classes.synopsisMaterialContainer}>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  this.state.allChapterTopic[this.state.currentActiveIndex]
                    ?.attributes.synopsis,
              }}
            ></div>
          </MBox>
        </MBox>
        <MBox
          display="flex"
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <MBox
            className={this.props.classes.navigationStyle}
            display="flex"
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            position={"relative"}
            marginBottom={"4em"}
          >
            {this.state.allChapterTopic.map((_, index) => (
              <MButton
                key={`${index}-page`}
                onClick={
                  index > 0
                    ? () => this.handleModalOpen()
                    : () => this.setActiveIndex(index)
                }
                className={
                  index === this.state.currentActiveIndex ? "active" : ""
                }
                data-test-id="active-btn"
              >
                {index + 1}
              </MButton>
            ))}
          </MBox>
        </MBox>
      </MBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleSynopsisChapterTopic = () =>
  createStyles({
    bannerStyle: {
      display: "flex",
      height: "71px",
      background: "rgb(244,239,246)",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: "0 2em",
      width: "100%",
      "& h6": {
        fontWeight: 500,
        color: "#702F8C",
      },
    },
    synopsisMaterialContainer: {
      overflowY: "hidden",
      padding: "1em 2em",
      background: "#F9F9F9",
      height: "65vh",
      "& .header-class": {
        marginBottom: "2em",
        color: "#702F8C",
      },
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "#888",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
      "&:hover": {
        overflowY: "scroll",
      },
    },
    buttonStyleNo: {
      width: "130px",
      background: "white",
      border: "1px solid #ffe9ed",
      height: "50px",
      borderRadius: "0.6em",
      color: "#4F1f6d",
      textTransform: "none" as const,
    },
    buttonStyleYes: {
      height: "50px",
      color: "white",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      width: "130px",
      textTransform: "none" as const,
      marginLeft: "1em",
    },
    modalStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      left: "50%",
      borderRadius: "1.5em",
      padding: "2em",
      transform: "translate(-50%, -50%)",
      width: "35%",
      minHeight: "max-content",
      background: "white",
      border: "2px solid #FFD0D8",
      gap: "1.5em",
    },
    navigationStyle: {
      border: "1px solid #FC5859",
      borderRadius: "0.5em",
      height: "max-content",
      fontWeight: 500,
      "& a": {
        color: "black",
        padding: "1.2em 1.3em",
        background: "white",
        borderRadius: "0.4em",
      },
      "& .active": {
        color: "white",
        padding: "1.2em 1.3em",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        borderRadius: "0.4em",
      },
    },
  });
export default withStyles(webStyleSynopsisChapterTopic)(SynopsisGuestCheck);
export { SynopsisGuestCheck };
// Customizable Area End
