import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  Button,
  Modal,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styles, webStyles } from "./QuestionBank.web";
import Loader from "../../../components/src/Loader.web";
import { webStylePTGuest } from "../../CfTestManagement2/src/GuestPracticeTest.web";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// Customizable Area End

import GuestQbSubjectsController, {
  Props,
  configJSON,
} from "./GuestQbSubjectsController.web";

class GuestQbSubjects extends GuestQbSubjectsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const classes = this.props.classes;
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box sx={webStyles.bannerStyle}>
          <h2>Question Bank</h2>
        </Box>
        <Box className={classes.questionBankStyle}>
          <p style={webStyles.selectSubject}>
            {configJSON.selectSubjectContinue}
          </p>
          <Box className="test-box-container">
            {this.state.subjects.map((subject, index) => {
              return (
                <Button
                  key={subject.attributes.name}
                  onClick={() => {
                    if (index > 0) {
                      return this.showModalSubmit();
                    }
                    return this.navigateTo(
                      `/guest/question-bank/${subject.id}/topics`
                    );
                  }}
                  data-test-id="test-navigate"
                >
                  <Box className="test-box">
                    <Box className="icon-container">
                      <img
                        src={subject.attributes.image}
                        className="sample-image"
                        alt={subject.attributes.name}
                      />
                    </Box>
                    <p className="link-test">{subject.attributes.name}</p>
                  </Box>
                </Button>
              );
            })}
          </Box>
        </Box>
        <Modal
          open={this.state.showModalSubmit}
          BackdropProps={{ onClick: undefined }}
          data-test-id="modal-here"
        >
          <Box sx={webStylePTGuest.modalStyle} className="submit-test-question">
            <CheckCircleIcon style={webStylePTGuest.circleModal} />
            <Typography style={webStylePTGuest.headerStyleModal}>
              Demo Test Complete
            </Typography>
            <Typography style={webStylePTGuest.subStyleModal}>
              To get access for real test and more content you need to register.
            </Typography>
            <Button
              style={webStylePTGuest.buttonStyleYes}
              data-test-id="get-here"
              onClick={this.navigateToRegister}
            >
              Get Started
            </Button>
            <Button
              style={webStylePTGuest.buttonStyleCancel}
              data-test-id="cancel-here"
              onClick={this.showModalSubmit}
            >
              Cancel
            </Button>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(GuestQbSubjects);
export { GuestQbSubjects };
// Customizable Area End
