import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { webStyleAYQQuestionsAsked } from "./AYQQuestionsAsked.web";
import storage from "../../../framework/src/StorageProvider.web";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";

export type QuestionsAskedType = {
  id: string;
  type: string;
  attributes: {
    account_id: number;
    subject_name: string;
    view: number;
    score: number;
    created_at: string;
    topic_name: string;
    like_count: number;
    question: string;
    updated_at: string;
    dislike_count: number;
    tag_list: Array<string>;
    user_profile: {
      data: {
        id: string;
        type: string;
        attributes: {
          name: string;
          first_name: string;
          status: string;
          myrank_id: string;
          last_name: string;
          phone_number: number;
          email: string;
          city: string;
          image: string;
          uuid: string;
          exam_notification_enable: boolean;
          course_notification_enable: boolean;
        };
      };
    };
    current_user_liked: number;
    answer_count: number;
  };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleAYQQuestionsAsked> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  questionsAsked: QuestionsAskedType[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AYQQuestionsAskedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserQuestionAnswerMessageId = "";
  viewAyqQuestionMessageId = "";
  likeDislikeMessageId = "";
  breadcrumb = [
    {
      label: "Profile",
      link: "/dashboard/ask-your-query/profile",
    },
    {
      label: "Questions Asked",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  questionIdView = "";
  indexOfLikeDislike: { index: number; status: number } = {
    index: Infinity,
    status: Infinity,
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      questionsAsked: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getUserQuestionAnswerMessageId) {
      if (response.data) {
        this.successfulFetch(response.data);
      }
    }
    if (apiRequestCallId === this.viewAyqQuestionMessageId) {
      this.handleSuccessfulView(this.questionIdView);
    }

    if (apiRequestCallId === this.likeDislikeMessageId && !response.errors) {
      if (response.message) {
        this.handleLikeDislike();
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingAQAC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingAQAC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingAQAC);
    this.getUserData();
  };

  getTimeDifference = (timestampStr: string): string => {
    const timestamp = new Date(timestampStr);
    const relativeTime = formatDistanceToNow(timestamp, { addSuffix: true });
    return relativeTime;
  };
  goToProfile = (dataId: string) => {
    this.props.navigation.navigate("AskYourQueryProfilePeek", {
      profileId: dataId,
    });
  };

  getUserData = async () => {
    const getUserQuestionAnswerMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getUserQuestionAnswerMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/user_ayq_question`
    );
    this.getUserQuestionAnswerMessageId =
      getUserQuestionAnswerMessage.messageId;
    getUserQuestionAnswerMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    getUserQuestionAnswerMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(
      getUserQuestionAnswerMessage.id,
      getUserQuestionAnswerMessage
    );
  };
  successfulFetch = (data: QuestionsAskedType[]) => {
    this.setState({
      questionsAsked: data,
    });
  };
  // Customizable Area Start
  viewAyqQuestion = async (questionId: string) => {
    const viewQuestionsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    this.viewAyqQuestionMessageId = viewQuestionsMessage.messageId;

    this.questionIdView = questionId;
    viewQuestionsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    viewQuestionsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    viewQuestionsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/${questionId}/view`
    );

    runEngine.sendMessage(viewQuestionsMessage.id, viewQuestionsMessage);
  };

  handleSuccessfulView = (questionId: string) => {
    this.props.navigation.navigate("AskYourQueryReceivedAnswers", {
      questionId,
    });
  };
  likeDislike = async (status: number, index: number) => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    this.indexOfLikeDislike = { index, status };

    const likeDislikeMessage2 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeDislikeMessageId = likeDislikeMessage2.messageId;

    likeDislikeMessage2.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    likeDislikeMessage2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    likeDislikeMessage2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/create_ayq_like?ayq_question_id=${this.state.questionsAsked[index].id}&status=${status}`
    );

    runEngine.sendMessage(likeDislikeMessage2.id, likeDislikeMessage2);
  };

  handleLikeDislike = () => {
    const questions2 = this.state.questionsAsked;
    const question2 = questions2[this.indexOfLikeDislike.index];

    if (this.indexOfLikeDislike.status === 1) {
      question2.attributes.like_count +=
        question2.attributes.current_user_liked === 1 ? -1 : 1;
      question2.attributes.dislike_count +=
        question2.attributes.current_user_liked === -1 ? -1 : 0;
      question2.attributes.current_user_liked =
        question2.attributes.current_user_liked === 1 ? 0 : 1;
    } else if (this.indexOfLikeDislike.status === -1) {
      question2.attributes.dislike_count +=
        question2.attributes.current_user_liked === -1 ? -1 : 1;
      question2.attributes.like_count +=
        question2.attributes.current_user_liked === 1 ? -1 : 0;
      question2.attributes.current_user_liked =
        question2.attributes.current_user_liked === -1 ? 0 : -1;
    }

    this.setState({
      questionsAsked: questions2,
    });
  };
  // Customizable Area End
}
