import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

export type AdvantagesType = {
  id: number;
  text: string;
  display_order: number;
  created_at: string;
  updated_at: string;
  image: string | null;
};

export type LecturesType = {
  id: string;
  type: string;
  attributes: {
    id: number;
    created_at: string;
    updated_at: string;
    video: string;
  };
};

// Customizable Area End
export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  advantages: AdvantagesType[];
  loading: boolean;
  lectures: LecturesType[];
  isRecorded: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GuestLiveClassController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAdvantagesMessageId = "";
  getSampleLecturesMessageId = "";
  isRecordedClass = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      advantages: [],
      loading: false,
      lectures: [],
      isRecorded: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getAdvantagesMessageId && response) {
      if (response?.length > 0) {
        this.handleResponse(response);
      }
    }

    if (apiRequestCallId === this.getSampleLecturesMessageId && response) {
      if (response?.data) {
        this.handleResponseLecture(response.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const isRecordedClasses = this.props.navigation.history.location.search;

    if (isRecordedClasses) {
      this.isRecordedClass = true;
      this.handleIsRecorded();
    }

    this.getSampleLecture();
    this.getAdvantages();
  };

  // Customizable Area Start
  handleIsRecorded = () => {
    this.setState({
      isRecorded: true,
    });
  };

  navigateToAYQ = () => {
    this.props.navigation.history.push("/dashboard-ask-your-query");
  };

  navigateToRegister = () => {
    this.props.navigation.history.push("/register");
  };

  handleResponseLecture = (response: LecturesType[]) => {
    this.setState({
      lectures: response,
    });
  };

  limitText = (text: string) => {
    if (text.length > 50) {
      return text.slice(0, 50) + "...";
    }
  };

  handleResponse = (response: AdvantagesType[]) => {
    const sortedResponse = this.handleSort(response);

    this.setState({
      advantages: sortedResponse,
    });
  };

  handleSort = (arrayToSort: AdvantagesType[]) => {
    return arrayToSort.sort(
      (pointA, pointB) => pointA.display_order - pointB.display_order
    );
  };

  getAdvantages = async () => {
    const headers = {
      uuid: await storage.get("guestToken"),
    };

    this.setState({
      loading: true,
    });

    let endpoint = "";

    if (this.isRecordedClass) {
      endpoint = "account_block/guest_users/mbbs_class_advantages";
    } else {
      endpoint = "account_block/guest_users/live_class_advantages";
    }

    const getAdvantages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getAdvantages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    this.getAdvantagesMessageId = getAdvantages.messageId;

    getAdvantages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    getAdvantages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getAdvantages.id, getAdvantages);
  };

  getSampleLecture = async () => {
    const headers = {
      token: await storage.get("guestToken"),
    };

    this.setState({
      loading: true,
    });

    let endpoint = "";
    if (this.isRecordedClass) {
      endpoint = "account_block/guest_users/mbbs_class_sample_lectures";
    } else {
      endpoint = configJSON.lectureAPI;
    }

    const getSampleLecturesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getSampleLecturesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    this.getSampleLecturesMessageId = getSampleLecturesMessage.messageId;

    getSampleLecturesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    getSampleLecturesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getSampleLecturesMessage.id,
      getSampleLecturesMessage
    );
  };
  // Customizable Area End
}
