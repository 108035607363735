export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backGroundImg = require("../assets/Group.png");
export const buttonBG = require("../assets/button_filled.png");
export const setting = require("../assets/setting.png");
export const searchIcon = require("../assets/searchImg.png");
export const correctIcon = require("../assets/iconcopy.png");
export const submitIcon = require("../assets/submitIcon.png");
export const bronzeBadge = require("../assets/bronzeBadge.png");
export const silverBadge = require("../assets/silverBadge.png");
export const goldBadge = require("../assets/goldBadge.png");
export const goldLead = require("../assets/goldLead.png");
export const silverLead = require("../assets/silverLead.png");
export const bronzeLead = require("../assets/bronzeLead.png");
export const msgpng = require('../assets/messages.png');
export const Badges = require("../assets/Badeg.png")
export const Bronze = require("../assets/Bronze.png")
export const Titanium = require("../assets/Titanium.png");
export const silver = require("../assets/silver.png");
export const gold  = require('../assets/gold.png');
export const Diamond = require('../assets/Diamond.png');
export const Platinum = require('../assets/Platinum.png');