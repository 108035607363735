import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showPassword: boolean;
  showConfirmPassword: boolean;
  isRegisterSuccess: boolean;
  modalTermsOpen: boolean;
  modalEmailExistOpen: boolean;
  emailOTPToken: string;
  isOtpEmailVerified: boolean;
  emailOTPInput: string;
  isOtpEmailError: boolean;
  isReadyForEmailVerification: boolean;
  modalMobileExistOpen: boolean;
  mobileOTPToken: string;
  isOtpMobileVerified: boolean;
  mobileOTPInput: string;
  isOtpMobileError: boolean;
  isReadyForMobileVerification: boolean;
  terms: string;
  completeOTPModal: boolean;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AYQRegisterController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postUserIdRegisterDocumentId = "";
  phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\(\d{2,3}\\)[ \\-]*)|(\d{2,4})[ \\-]*)*?\d{3,4}?[ \\-]*\d{3,4}?$/;
  registerSchema = Yup.object({
    studentName: Yup.string().required("Please input your Complete Name here"),
    email: Yup.string()
      .email("Input must be an email")
      .required("Please enter an email in this field"),
    mobileNumber: Yup.string()
      .matches(this.phoneRegExp, "Input must be a valid number")
      .min(10, "Input must be a valid number")
      .max(15, "Input must be a valid number")
      .required("Please enter a mobile number in this field"),
    initialPassword: Yup.string()
      .min(4, "Password must be 4 characters long")
      .matches(/[A-Z]/, "Password must contain at least one capital letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one valid symbol"
      )
      .required("Please enter your desired password here"),
    finalPassword: Yup.string()
      .oneOf([Yup.ref("initialPassword")], "Passwords must match")
      .required("Please enter confirm password in this field"),
  });
  postEmailOTPDocumentId = "postEmailOTP";
  postVerifyEmailOTPDocumentId = "postVerifyEmail";
  postMobileOTPDocumentId = "postMobileOTP";
  postVerifyMobileOTPDocumentId = "postVerifyMobile";
  getTermsAndConditionId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showConfirmPassword: false,
      showPassword: false,
      modalTermsOpen: false,
      isRegisterSuccess: false,
      modalEmailExistOpen: false,
      emailOTPToken: "",
      isOtpEmailVerified: false,
      isOtpEmailError: false,
      emailOTPInput: "",
      mobileOTPInput: "",
      isReadyForEmailVerification: false,
      isOtpMobileError: false,
      isOtpMobileVerified: false,
      isReadyForMobileVerification: false,
      mobileOTPToken: "",
      modalMobileExistOpen: false,
      terms: "",
      completeOTPModal: false,
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      apiRequestCallId === this.postUserIdRegisterDocumentId &&
      responseJson
    ) {
      if (responseJson.data) {
        this.handleUserIdRegisterResponse();
      } else {
        this.handleErrorEmailExistModal();
      }
    }

    if (apiRequestCallId === this.postEmailOTPDocumentId) {
      this.handleEmailOTPMessage(responseJson);
    }

    if (apiRequestCallId === this.postVerifyEmailOTPDocumentId) {
      this.handleVerifiedEmailOTPMessage(responseJson);
    }

    if (apiRequestCallId === this.postMobileOTPDocumentId) {
      this.handleMobileOTPMessage(responseJson);
    }

    if (apiRequestCallId === this.postVerifyMobileOTPDocumentId) {
      this.handleVerifiedMobileOTPMessage(responseJson);
    }

    if (apiRequestCallId === this.getTermsAndConditionId) {
      this.handleTerms(responseJson);
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getTermsAndCondition();
  };

  // Customizable Area Start
  handleTerms = (response: { terms_and_conditions: { content: string } }) => {
    if (response?.terms_and_conditions) {
      this.setState({
        terms: response.terms_and_conditions.content,
      });
    }
  };

  peekInitial = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleVerifiedEmailOTPMessage = (responseJson: {
    errors: unknown;
    meta: { message: string };
  }) => {
    if (responseJson.meta) {
      this.setState({
        isOtpEmailVerified: true,
        isReadyForEmailVerification: false,
      });
    }

    if (responseJson.errors) {
      this.setState({
        isOtpEmailError: true,
      });
    }
  };

  handleVerifiedMobileOTPMessage = (responseJson: {
    errors: unknown;
    meta: { message: string };
  }) => {
    if (responseJson.meta) {
      this.setState({
        isOtpMobileVerified: true,
        isReadyForMobileVerification: false,
      });
    }

    if (responseJson.errors) {
      this.setState({
        isOtpMobileError: true,
      });
    }
  };

  handleEmailChangeInput = (input: string) => {
    this.setState({
      emailOTPInput: input,
    });
  };

  handleMobileChangeInput = (input: string) => {
    this.setState({
      mobileOTPInput: input,
    });
  };

  handleEmailOTPMessage = (responseJson: {
    errors: Array<{ account: string }>;
    meta: {
      token: string;
    };
  }) => {
    if (responseJson.errors) {
      this.handleErrorEmailExistModal();
    }

    if (responseJson.meta) {
      this.setState({
        isReadyForEmailVerification: true,
        emailOTPToken: responseJson.meta.token,
      });
    }
  };

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  handleMobileOTPMessage = (responseJson: {
    errors: Array<{ account: string }>;
    meta: {
      token: string;
    };
  }) => {
    if (responseJson.errors) {
      this.handleErrorMobileExistModal();
    }

    if (responseJson.meta) {
      this.setState({
        isReadyForMobileVerification: true,
        mobileOTPToken: responseJson.meta.token,
      });
    }
  };

  handleModalTerms = () => {
    this.setState({
      modalTermsOpen: !this.state.modalTermsOpen,
    });
  };

  handleErrorEmailExistModal = () => {
    this.setState({
      modalEmailExistOpen: !this.state.modalEmailExistOpen,
    });
  };

  handleErrorMobileExistModal = () => {
    this.setState({
      modalMobileExistOpen: !this.state.modalMobileExistOpen,
    });
  };

  peekConfirm = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  navigateToLogin = () => {
    this.props.navigation.navigate("AYQLogin");
  };

  handleUserIdRegisterResponse = () => {
    this.handleRegisterSuccessModal();
  };

  handleRegisterSuccessModal = () => {
    this.setState({
      isRegisterSuccess: !this.state.isRegisterSuccess,
    });
  };

  openSubmitOtpModal = () => {
    this.setState({
      completeOTPModal: !this.state.completeOTPModal,
    });
  };

  handleSubmit = (values: {
    studentName: string;
    email: string;
    mobileNumber: string;
    finalPassword: string;
  }) => {
    if (this.state.isOtpMobileVerified && this.state.isOtpEmailVerified) {
      this.handleRegisterSubmit(values);
    } else {
      this.openSubmitOtpModal();
    }
  };

  sendEmailOTP = (email: string) => {
    this.setState({
      emailOTPInput: "",
      isOtpEmailError: false,
      loading: true,
    });

    const requestBody = {
      data: {
        attributes: {
          email,
        },
      },
    };

    const header = {
      "Content-Type": "application/json",
    };

    const emailOTPMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postEmailOTPDocumentId = emailOTPMessage.messageId;

    emailOTPMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "send_email_otp"
    );

    emailOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    emailOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    emailOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(emailOTPMessage.id, emailOTPMessage);
  };

  sendMobileOTP = (mobileNumber: string) => {
    this.setState({
      mobileOTPInput: "",
      isOtpMobileError: false,
      loading: true,
    });

    const requestBody = {
      data: {
        attributes: {
          full_phone_number: mobileNumber,
        },
      },
    };

    const header = {
      "Content-Type": "application/json",
    };

    const mobileOTPMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postMobileOTPDocumentId = mobileOTPMessage.messageId;

    mobileOTPMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "send_sms_otp"
    );

    mobileOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    mobileOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    mobileOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(mobileOTPMessage.id, mobileOTPMessage);
  };

  verifyEmailOTP = (otpInput: string) => {
    this.setState({
      loading: true,
    });

    const requestBody = {
      data: {
        attributes: {
          pin: otpInput,
        },
      },
    };

    const header = {
      "Content-Type": "application/json",
      token: this.state.emailOTPToken,
    };

    const verifyOTPMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postVerifyEmailOTPDocumentId = verifyOTPMessage.messageId;

    verifyOTPMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "verify_email"
    );

    verifyOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    verifyOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    verifyOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(verifyOTPMessage.id, verifyOTPMessage);
  };

  verifyMobileOTP = (otpInput: string) => {
    this.setState({
      loading: true,
    });

    const requestBody = {
      data: {
        attributes: {
          pin: otpInput,
        },
      },
    };

    const header = {
      "Content-Type": "application/json",
      token: this.state.mobileOTPToken,
    };

    const verifyMobileOTPMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postVerifyMobileOTPDocumentId = verifyMobileOTPMessage.messageId;

    verifyMobileOTPMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "verify_mobile_otp"
    );

    verifyMobileOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    verifyMobileOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    verifyMobileOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(verifyMobileOTPMessage.id, verifyMobileOTPMessage);
  };

  handleRegisterSubmit = (values: {
    studentName: string;
    email: string;
    mobileNumber: string;
    finalPassword: string;
  }) => {
    this.setState({
      loading: true,
    });

    const requestBody = {
      data: {
        type: "ayq_account",
        attributes: {
          name: values.studentName,
          email: values.email,
          phone_number: values.mobileNumber,
          password: values.finalPassword,
        },
      },
    };

    const header = {
      "Content-Type": "application/json",
    };

    const registerMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postUserIdRegisterDocumentId = registerMessage.messageId;

    registerMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/ayq_account"
    );

    registerMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    registerMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    registerMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(registerMessage.id, registerMessage);
  };

  getTermsAndCondition = () => {
    this.setState({
      loading: true,
    });

    const termsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsAndConditionId = termsMessage.messageId;

    termsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/terms_and_conditions"
    );

    termsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(termsMessage.id, termsMessage);
  };
  // Customizable Area End
}
