import React from "react";

import {
  Box as BoxMUI,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography as TypographyMUI,
  Button as ButtonMUI,
  Grid as GridMUI,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Physics, emptyStar, starIcon } from "./assets";
import BannerWeb from "../../../components/src/Banner.web";
import { boxStyles } from "./BookMySlot.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import BookMySlotStudentAppointmentsController, {
  AppointmentT,
  Props,
  configJSON,
} from "./BookMySlotStudentAppointmentsController.web";

class BookMySlotStudentAppointments extends BookMySlotStudentAppointmentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  UpcomingAppointmentsPage = () => {
    return <BoxMUI>{this.RenderUpcomingAppointments()}</BoxMUI>;
  };

  PreviousBookingsPage = () => {
    return <BoxMUI>{this.RenderPreviousBookings()}</BoxMUI>;
  };

  PageDeterminer = (pageIndex: number) => {
    if (pageIndex === 0) {
      return this.UpcomingAppointmentsPage();
    }

    return this.PreviousBookingsPage();
  };

  RenderAppointmentBox = (dataUpcoming: AppointmentT) => {
    return (
      <GridMUI item key={`notifs-${dataUpcoming.id}`} sm={12}>
        <BoxMUI className={this.props.classes.appointmentBox}>
          <BoxMUI className="details-appointment">
            <BoxMUI display={"flex"} alignItems={"center"} gridGap={"0.3em"}>
              <img src={Physics} alt={"profileImage"} />
              <TypographyMUI style={{ fontWeight: 500, marginLeft: "1em" }}>
                {dataUpcoming.attributes.faculty_account.data.attributes.name}
              </TypographyMUI>
            </BoxMUI>
            <BoxMUI className="text-appointment-headers">
              <TypographyMUI>
                <span>{configJSON.subjectTitle} -</span>{" "}
                {dataUpcoming.attributes.bms_slot.slot_subject}
              </TypographyMUI>
              <TypographyMUI>
                <span>Chapter -</span>{" "}
                {dataUpcoming.attributes.chapter.data.attributes.name}
              </TypographyMUI>
              <TypographyMUI>
                <span> Topic -</span>{" "}
                {dataUpcoming.attributes.topic.data.attributes.name}
              </TypographyMUI>
              <BoxMUI display={"flex"}>
                <TypographyMUI>
                  <span>Zoom Link - </span>
                </TypographyMUI>
                <a href={dataUpcoming.attributes.meet_url}>
                  {dataUpcoming.attributes.meet_url}
                </a>
              </BoxMUI>
              <TypographyMUI>
                <span>
                  {" "}
                  Date and Time : {
                    dataUpcoming.attributes.bms_slot.slot_date
                  }, {dataUpcoming.attributes.bms_slot.slot_start}-
                  {dataUpcoming.attributes.bms_slot.slot_end}
                </span>
              </TypographyMUI>
            </BoxMUI>
            <ButtonMUI className="summary" data-test-id="summaryCheck">
              <a href={dataUpcoming.attributes.meet_url}>Join</a>
            </ButtonMUI>
          </BoxMUI>
        </BoxMUI>
      </GridMUI>
    );
  };
  RenderPreviousBox = (dataPrevious: AppointmentT) => {
    const rating = dataPrevious.attributes.student_status
      ? dataPrevious.attributes.student_status - 1
      : -1;

    return (
      <GridMUI key={`notifs-${dataPrevious.id}`} sm={12} item>
        <BoxMUI className={this.props.classes.appointmentBox}>
          <BoxMUI className="details-appointment" position={"relative"}>
            <BoxMUI display={"flex"} alignItems={"center"}>
              <img src={Physics} alt={"physics-image"} />
              <TypographyMUI
                className="account-name"
                style={{ fontWeight: 500, marginLeft: "1em" }}
              >
                {dataPrevious.attributes.faculty_account.data.attributes.name}
              </TypographyMUI>
              <BoxMUI>
                <TypographyMUI
                  className="date-time"
                  style={{
                    fontWeight: 500,
                    marginLeft: "1em",
                    color: "#909090",
                  }}
                >
                  Date and Time : {dataPrevious.attributes.bms_slot.slot_date},{" "}
                  {dataPrevious.attributes.bms_slot.slot_start}-
                  {dataPrevious.attributes.bms_slot.slot_end}
                </TypographyMUI>
              </BoxMUI>
            </BoxMUI>
            <BoxMUI position={"relative"}>
              <BoxMUI
                className="text-appointment-headers"
                position={"relative"}
              >
                <TypographyMUI>
                  <span>{configJSON.subjectTitle} -</span>{" "}
                  {dataPrevious.attributes.bms_slot.slot_subject}
                </TypographyMUI>
                <TypographyMUI>
                  <span>Chapter -</span>{" "}
                  {dataPrevious.attributes.chapter.data.attributes.name}
                </TypographyMUI>
                <TypographyMUI>
                  <span> Topic -</span>{" "}
                  {dataPrevious.attributes.topic.data.attributes.name}
                </TypographyMUI>
              </BoxMUI>
            </BoxMUI>
          </BoxMUI>
          <BoxMUI className="buttons-appointment">
            <ButtonMUI className="status">
              Status: {dataPrevious.attributes.bms_slot.slot_status}
            </ButtonMUI>
          </BoxMUI>
          <BoxMUI marginTop={"1.3em"}>
            <BoxMUI display={"flex"} gridGap={"1em"}>
              <TypographyMUI className={this.props.classes.typographyStyle}>
                Feedback-{" "}
                <BoxMUI
                  display={"inline-block"}
                  onClick={() => this.navigateToFeedback(dataPrevious.id)}
                  style={{ cursor: "pointer" }}
                >
                  {Array.from(Array(5).keys()).map((_, index) => {
                    return (
                      <>
                        {rating >= index ? (
                          <img
                            width={"20px"}
                            style={{ cursor: "pointer" }}
                            src={starIcon}
                            alt="filled"
                          />
                        ) : (
                          <img
                            width={"20px"}
                            data-test-id="rate-btn-star"
                            src={emptyStar}
                            alt="empty"
                          />
                        )}
                      </>
                    );
                  })}
                </BoxMUI>
              </TypographyMUI>
            </BoxMUI>
            <BoxMUI display={"flex"} gridGap={"1em"}>
              <TypographyMUI className={this.props.classes.typographyStyle}>
                Remarks -{" "}
                <span>{dataPrevious.attributes.student_feedback}</span>
              </TypographyMUI>
              <TypographyMUI></TypographyMUI>
            </BoxMUI>
          </BoxMUI>
        </BoxMUI>
      </GridMUI>
    );
  };

  RenderUpcomingAppointments = () => {
    const appointments = this.state.upcomingData;
    if (appointments.length === 0) {
      return <TypographyMUI>There are no upcoming appointments</TypographyMUI>;
    }

    return (
      <GridMUI container direction="row" alignItems="center" spacing={4}>
        {appointments
          .sort((dataA, dataB) => {
            const dateA = new Date(
              dataA.attributes.bms_slot.slot_date
            ).getTime();
            const dateB = new Date(
              dataB.attributes.bms_slot.slot_date
            ).getTime();

            if (dateA !== dateB) {
              return dateA - dateB;
            }

            const startA = dataA.attributes.bms_slot.slot_start;
            const startB = dataB.attributes.bms_slot.slot_end;
            return this.timeToMinutes(startA) - this.timeToMinutes(startB);
          })
          .map((appointment) => {
            return this.RenderAppointmentBox(appointment);
          })}
      </GridMUI>
    );
  };

  RenderPreviousBookings = () => {
    let appointments = this.state.previousData;
    appointments = appointments.sort((dataA, dataB) => {
      const dateA = new Date(dataA.attributes.bms_slot.slot_date).getTime();
      const dateB = new Date(dataB.attributes.bms_slot.slot_date).getTime();

      if (dateA !== dateB) {
        return dateB - dateA;
      }

      const startA = dataA.attributes.bms_slot.slot_start;
      const startB = dataB.attributes.bms_slot.slot_end;
      return this.timeToMinutes(startA) - this.timeToMinutes(startB);
    });

    if (appointments.length === 0) {
      return <TypographyMUI>There are no previous bookings</TypographyMUI>;
    }

    return (
      <GridMUI container direction="row" alignItems="center" spacing={4}>
        {appointments.map((appointment) => {
          return this.RenderPreviousBox(appointment);
        })}
      </GridMUI>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <BoxMUI>
        <Loader loading={this.state.loading} />
        <BannerWeb title="Booking History" />
        <BoxMUI className={this.props.classes.navigationBox}>
          <ButtonMUI
            onClick={() => this.pageChange(0)}
            data-test-id="button-upcoming"
            className={this.state.pageIndex === 0 ? "active" : ""}
          >
            Upcoming Bookings
          </ButtonMUI>
          <ButtonMUI
            onClick={() => this.pageChange(1)}
            data-test-id="button-previous"
            className={this.state.pageIndex === 1 ? "active" : ""}
          >
            Previous Bookings
          </ButtonMUI>
        </BoxMUI>
        <BoxMUI padding={"2em"}>
          {this.PageDeterminer(this.state.pageIndex)}
        </BoxMUI>
      </BoxMUI>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleBookMySlotStudentAppointments = () =>
  createStyles({
    appointmentBox: {
      ...boxStyles,
      "& .details-appointment": {
        gap: "1em",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        "& span": {
          color: "#909090!important",
        },
        "& img": {
          height: "40px",
          width: "40px",
          borderRadius: "50%",
        },
        "& .summary": {
          right: "5%",
          position: "absolute",
          top: "40%",
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          border: "none",
          "& a": {
            color: "white",
            textDecoration: "none",
          },
        },
        "& .text-appointment-headers": {
          flexDirection: "column",
          display: "flex",
          gap: "1.2em",
          "& a": {
            display: "inline",
          },
          "& p": {
            fontWeight: 500,
            display: "inline",
          },
        },
      },
    },
    dateslotStyle: {
      fontWeight: 500,
      color: "#808080",
    },
    navigationBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "2em",
      gap: "1em",
      "& button": {
        width: "300px",
        height: "50px",
        color: "#222",
        border: "1px solid #FF3A3A",
        borderRadius: "0.5em",
        fontWeight: 500,
        fontSize: "1.2em",
      },
      "& .active": {
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        border: "none",
        color: "white",
      },
    },
    typographyStyle: {
      fontSize: "1.1em",
      fontWeight: 500,
    },
  });
export default withStyles(webStyleBookMySlotStudentAppointments)(
  BookMySlotStudentAppointments
);
export { BookMySlotStudentAppointments };
// Customizable Area End
