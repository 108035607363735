import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import GuestPackagesController, { Props } from "./GuestPackagesController.web";

class GuestPackages extends GuestPackagesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          margin={"5em 0 8em 0"}
        >
          <Box className="signup-package-select" minHeight={"85vh"}>
            {this.state.packagesData.map((packages, packageIndex) => {
              return (
                <Box
                  key={`${packageIndex}-select`}
                  className={this.props.classes.boxPackage}
                  onClick={() => this.navigateToAndAssignPackageId(packages.id)}
                  data-test-id="package-btn"
                >
                  <Typography>{packages.attributes.pack_name}</Typography>
                  <img
                    src={packages.attributes.image}
                    alt={packages.attributes.pack_name}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleGuestPackages = () =>
  createStyles({
    boxPackage: {
      cursor: "pointer",
    },
  });
export default withStyles(webStyleGuestPackages)(GuestPackages);
export { GuestPackages };
// Customizable Area End
