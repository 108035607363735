Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'
exports.labelTitle = "Search"
exports.getSearchHistoryEndPoint = "/bx_block_searchhistory/search_histories"
exports.httpPostMethod = 'POST'
exports.searchApiEndPoint = "bx_block_advanced_search/search?query="
exports.previousHistioryT = "Previous History"
exports.learnNowTest = " Learn now"
// Customizable Area End
