import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import storage from "../../framework/src/StorageProvider.web";
import { phonecall } from "../../blocks/landingpage/src/assets";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MegamenuLanding from "../../blocks/ContentManagement/src/MegamenuLanding.web";

function Header({ image, navigation }: { image: string; navigation: any }) {
  const styles = useStyles();
  const [token, setToken] = useState<string>("");
  const [guestToken, setGuestToken] = useState<string>("");
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isNumberShown, setIsNumberShown] = useState<boolean>(false);
  const [isGuidanceMenuOpen, setGuidanceMenuOpen] = useState<boolean>(false);

  const showNumber = () => {
    setIsNumberShown(!isNumberShown);
  };

  const toggleShowGuidance = () => {
    setGuidanceMenuOpen(!isGuidanceMenuOpen);
  };

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleGoToDashboard = () => {
    return navigation.navigate("Dashboard");
  };

  const handleLoginClick = () => {
    return navigation.navigate("Login");
  };

  const handleRegistrationClick = () => {
    return navigation.navigate("Register");
  };

  const handleContactUsClick = () => {
    return navigation.navigate("ContactUs");
  };

  const handleLandingPageClick = () => {
    return navigation.navigate("LandingPage");
  };

  const handleGuidanceClick = () => {
    return navigation.navigate("DashboardGuidance");
  };

  const handleNotificationClick = async () => {
    interface IUserData {
      attributes: {
        user_type: string;
      };
    }

    const userType = JSON.parse(await storage.get("userData")) as IUserData;
    const token = await storage.get("authToken");
    const guesttoken = await storage.get("guestToken");

    if (token) {
      if (userType.attributes.user_type === "cm") {
        return navigation.history.push("/dashboard-cm");
      }

      return navigation.history.push("/dashboard");
    }

    if (guesttoken) {
      return navigation.history.push("/guest/notifications");
    }
  };

  const getToken = useCallback(async () => {
    const authToken = await storage.get("authToken");
    const guestTokenGet = await storage.get("guestToken");
    setGuestToken(guestTokenGet);
    setToken(authToken);
  }, []);

  useEffect(() => {
    getToken();
  }, []);

  return (
    <Box
      className={styles.headerGlobalComponent}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      component={"header"}
      position={"relative"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        className={styles.desktopStyle}
      >
        <img
          className={"logo"}
          src={image}
          alt="myrank-logo"
          onClick={handleLandingPageClick}
          style={webStyle.mainLogoStyle}
        />
        <Box component={"ul"}>
          <Box component={"li"} onClick={handleLandingPageClick}>
            Home
          </Box>
          <Box
            component={"li"}
            onClick={handleGuidanceClick}
            style={{ position: "relative" }}
          >
            Guidance
            <span
              onClick={(event) => {
                event.stopPropagation();
                toggleShowGuidance();
              }}
              style={{ position: "absolute" }}
            >
              <ExpandMoreIcon />
            </span>
          </Box>
          <Box component={"li"}>Live classes</Box>
          <Box component={"li"}>MBBS classes</Box>
          <Box component={"li"}>Packages</Box>
          {(guestToken || token) && (
            <Box component={"li"} onClick={handleNotificationClick}>
              Notifications
            </Box>
          )}
          <Box component={"li"} onClick={handleContactUsClick}>
            Contact Us
          </Box>
          <Box position={"relative"}>
            <img
              src={phonecall}
              alt={"phonecall-img"}
              style={webStyle.imagePhoneStyle}
              onClick={showNumber}
            />
            <Box
              component={"p"}
              position={"absolute"}
              width={"max-content"}
              sx={webStyle.phoneNumber}
              color={"#510081"}
              display={`${isNumberShown ? "block" : "none"} `}
            >
              040-49521580/81/82 (9.30 AM TO 5.30 PM)
            </Box>
          </Box>
        </Box>
        <Box sx={webStyle.flexCenterRow}>
          {!token ? (
            <>
              <Button
                data-test-id="goToLogin"
                onClick={handleLoginClick}
                className={styles.buttonLoginRegister}
              >
                Login
              </Button>

              <Button
                data-test-id="goToRegister"
                onClick={handleRegistrationClick}
                className={styles.buttonLoginRegister}
              >
                Register
              </Button>
            </>
          ) : (
            <Button
              data-test-id="goToLogin"
              onClick={handleGoToDashboard}
              className={styles.buttonLoginRegister}
            >
              Dashboard
            </Button>
          )}
        </Box>
      </Box>
      <Box
        className={styles.menuBurger}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <img
          className={"logo"}
          src={image}
          alt="myrank-logo"
          onClick={handleLandingPageClick}
          style={webStyle.mainLogoStyle}
        />
        <MenuIcon style={webStyle.menuIconStyle} onClick={handleToggleMenu} />
      </Box>
      <Box
        display={showMenu ? "flex" : "none"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        gridGap={"2em"}
        className={styles.mobileStyle}
      >
        <HighlightOffIcon
          style={webStyle.closeIconMobile}
          onClick={handleToggleMenu}
        />
        <img
          className={"logo"}
          src={image}
          alt="myrank-logo"
          onClick={handleLandingPageClick}
          style={webStyle.mainLogoStyle}
        />
        <Box component={"ul"}>
          <Box component={"li"} onClick={handleLandingPageClick}>
            Home
          </Box>
          <Box component={"li"} onClick={handleGuidanceClick}>
            Guidance
          </Box>
          <Box component={"li"}>Live classes</Box>
          <Box component={"li"}>MBBS classes</Box>
          <Box component={"li"}>Packages</Box>
          <Box component={"li"}>Notifications</Box>
          <Box component={"li"} onClick={handleContactUsClick}>
            Contact Us
          </Box>
          <img
            src={phonecall}
            alt={"phonecall-img"}
            style={webStyle.imagePhoneStyle}
          />
        </Box>
        <Box sx={webStyle.flexCenterRowMobile}>
          {!token ? (
            <>
              <Button
                data-test-id="goToLogin"
                onClick={handleLoginClick}
                className={styles.buttonLoginRegisterMobile}
              >
                Login
              </Button>
              <Button
                data-test-id="goToRegister"
                onClick={handleRegistrationClick}
                className={styles.buttonLoginRegisterMobile}
              >
                Register
              </Button>
            </>
          ) : (
            <Button
              data-test-id="goToLogin"
              onClick={handleGoToDashboard}
              className={styles.buttonLoginRegisterMobile}
            >
              Dashboard
            </Button>
          )}
        </Box>
      </Box>
      <MegamenuLanding
        id="megamenu"
        navigation={navigation}
        isOpen={isGuidanceMenuOpen}
        toggleShowGuidance={toggleShowGuidance}
      />
    </Box>
  );
}

const webStyle = {
  flexCenterRow: {
    display: "flex",
    gap: "1em",
    justifyContent: "center",
    alignItems: "center",
  },
  flexCenterRowMobile: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    justifyContent: "center",
    alignItems: "center",
  },
  mainLogoStyle: {
    cursor: "pointer",
  },
  imagePhoneStyle: {
    width: "20px",
    cursor: "pointer",
  },
  menuIconStyle: {
    fontSize: "2.5rem",
    cursor: "pointer",
  },
  closeIconMobile: {
    position: "absolute" as const,
    fontSize: "2.5rem",
    right: "16px",
    top: "16px",
    cursor: "pointer",
  },
  phoneNumber: {
    background: "white",
    borderRadius: "0.3em",
    padding: "0.3em 0.7em",
    bottom: "-40px",
  },
};

const useStyles = makeStyles((theme) => {
  return {
    headerGlobalComponent: {
      width: "100%",
      transition: "0.3s",
      padding: "2em 3em",
      backgroundColor: "#510081",
      color: "white",
      "& .logo": {
        transition: "all 0.3s ease",
        "&:hover": {
          scale: "1.1",
        },
      },
      "& ul": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        gap: "3em",

        "& li": {
          textAlign: "center",
          transition: "all 0.3s ease",
          userSelect: "none",
          cursor: "pointer",
          fontSize: "16px",
          "&:hover": {
            color: "#F75E79",
            transform: "scale(1.1)",
            fontWeight: "bold",
          },
        },
      },
      "& img": {
        width: "150px",
      },
    },
    buttonLoginRegister: {
      borderRadius: "10px",
      border: "1px solid rgba(255,0,0, 0.700858)",
      color: "#FE6257",
      width: "160px",
      maxWidth: "160px",
      height: "50px",
      transition: "all 0.3s ease",
      fontSize: "16px",
      "&:hover": {
        color: "white",
        backgroundImage:
          "linear-gradient(90deg, rgba(255, 52, 84, 1) 0%, rgba(255, 75, 96, 1) 29%, rgba(254, 94, 88, 1) 67%, rgba(255, 113, 81, 1) 100%)",
        border: "none",
      },
    },
    buttonLoginRegisterMobile: {
      borderRadius: "10px",
      border: "1px solid rgba(255,0,0, 0.700858)",
      color: "#FE6257",
      width: "250px",
      height: "70px",
      transition: "all 0.3s ease",
      "&:hover": {
        color: "white",
        backgroundImage:
          "linear-gradient(90deg, rgba(255, 52, 84, 1) 0%, rgba(255, 75, 96, 1) 29%, rgba(254, 94, 88, 1) 67%, rgba(255, 113, 81, 1) 100%)",
        border: "none",
      },
    },
    menuBurger: {
      [theme.breakpoints.down("md")]: {
        display: "flex",
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    desktopStyle: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    mobileStyle: {
      [theme.breakpoints.down("md")]: {
        position: "fixed",
        background: "#510081",
        height: "100%",
        padding: "3em 0",
        top: 0,
        left: 0,
        zIndex: "9999",
        "& .logo": {
          transition: "all 0.3s ease",
          "&:hover": {
            scale: "1.1",
          },
        },
        "& ul": {
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          gap: "2em",

          "& li": {
            textAlign: "center",
            transition: "all 0.3s ease",
            userSelect: "none",
            cursor: "pointer",
            fontSize: "1.5rem",
            "&:hover": {
              color: "#F75E79",
              transform: "scale(1.1)",
              fontWeight: "bold",
            },
          },
        },
        "& img": {
          width: "250px",
        },
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  };
});

export default Header;
