import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

export type AppointmentT = {
  id: string;
  type: string;
  attributes: {
    id: number;
    bms_slot: {
      id: number;
      slot_date: string;
      slot_subject: string;
      slot_status: string;
      availability_status: string;
      created_at: string;
      updated_at: string;
      year: string | number | null;
      slot_start: string;
      slot_end: string;
    };
    account: {
      data: {
        id: string;
        type: string;
        attributes: {
          name: string;
          first_name: string | number | null;
          last_name: string | number | null;
          phone_number: number;
          email: string;
          city: string | number | null;
          status: string;
          myrank_id: string;
          image: string | number | null;
          user_type: string;
          uuid: string | number | null;
          course_notification_enable: boolean;
          exam_notification_enable: boolean;
          subject_dealing: Array<string | number | null>;
          package: Array<{
            id: number;
            account_id: number;
            pack_final_id: number;
            pack_pricing_id: number;
            packfinal_feature_id: string | number | null;
            ayq_level: number;
            lc_level: number;
            wb_level: number;
            bms_level: number;
            tution_level: number;
            acat_apply: number;
            bcat_apply: number;
            actual_price: number;
            amount: number;
            validity: string;
            expiry_date: string;
            sales_manager_name: string | number | null;
            agent1_name: string | number | null;
            agent2_name: string | number | null;
            transaction_type: string;
            status: string;
            created_by: string;
            created_at: string;
            updated_at: string;
            sms_level: string | number | null;
            active: boolean;
          }>;
        };
      };
    };
    user_name: string | number | null;
    user_contact: string | number | null;
    slot_status: string | number | null;
    subject_id: number;
    chapter_id: number;
    topic_id: number;
    bms_query: string;
    attended_by: string | number | null;
    fac_feedback: string | number | null;
    student_status: number | null;
    fac_status: string | number | null;
    student_rating: number | null;
    student_feedback: string | null;
    call_status: string | number | null;
    doubt_status: string | number | null;
    meet_url: string;
    faculty_account: {
      data: {
        id: string;
        type: string;
        attributes: {
          name: string;
          first_name: string | number | null;
          last_name: string | number | null;
          phone_number: number;
          email: string;
          city: string | number | null;
          status: string;
          myrank_id: string;
          image: string | number | null;
          user_type: string;
          uuid: string | number | null;
          course_notification_enable: boolean;
          exam_notification_enable: boolean;
          subject_dealing: Array<string>;
          package: Array<string | number | null>;
        };
      };
    };
    subject: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          name: string;
          image: string;
          created_by: string;
          class_zone: string;
          practice_test: string;
          question_bank: string;
          subjective_test: string;
          st_code: string;
          remarks: string;
          icon_code: string;
          search_key: string | number | null;
          total_topics: string | number | null;
          saved_notes: string | number | null;
        };
      };
    };
    chapter: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          name: string;
          image: string;
          practice_test: string;
          date_pt: string;
          date_qb: string;
          avail_pt: string;
          created_by: string;
          remarks: string;
          class_zone: string;
          avail_cz: string;
          date_cz: string;
          question_bank: string;
          avail_qb: string;
          search_key: string | number | null;
          chapter_read: boolean;
          viewed: boolean;
          continue_reading_topic_id: string | number | null;
          test_attempted: string | number | null;
          subject: {
            id: number;
            created_at: string;
            updated_at: string;
            name: string;
            created_by: string;
            class_zone: string;
            practice_test: string;
            question_bank: string;
            subjective_test: string;
            st_code: string;
            remarks: string;
            icon_code: string;
            st_db: string;
            score_db: string;
            chapter_db: string;
          };
        };
      };
    };
    topic: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          name: string;
          created_at: string;
          updated_at: string;
          remarks: string;
          remark_2: string;
          date_synopsis: string | number | null;
          question_status: string;
          search_key: string | number | null;
          synopsis: string;
          topic_read: string | number | null;
          saved_notes_count: string | number | null;
          is_bookmarked: string | number | null;
          chapter: {
            data: {
              id: string;
              type: string;
              attributes: {
                id: number;
                name: string;
                image: string;
                avail_cz: string;
                date_cz: string;
                question_bank: string;
                avail_qb: string;
                date_qb: string;
                created_by: string;
                practice_test: string;
                viewed: boolean;
                continue_reading_topic_id: string | number | null;
                avail_pt: string;
                date_pt: string;
                class_zone: string;
                remarks: string;
                search_key: string | number | null;
                chapter_read: boolean;
                test_attempted: string | number | null;
                subject: {
                  id: number;
                  created_at: string;
                  updated_at: string;
                  name: string;
                  created_by: string;
                  class_zone: string;
                  practice_test: string;
                  question_bank: string;
                  subjective_test: string;
                  st_code: string;
                  remarks: string;
                  icon_code: string;
                  st_db: string;
                  score_db: string;
                  chapter_db: string;
                };
              };
            };
          };
        };
      };
    };
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pageIndex: number;
  upcomingData: AppointmentT[];
  loading: boolean;
  previousData: AppointmentT[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotStudentAppointmentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUpcomingMessageId = "";
  getPreviousMessageId = "";
  breadcrumb = [
    {
      link: `/dashboard/book-my-slot-student`,
      label: "Book my Slot Student",
    },
    {
      link: `/dashboard/book-my-slot-student`,
      label: "Bookings",
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Appointments",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pageIndex: 0,
      upcomingData: [],
      loading: false,
      previousData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getUpcomingMessageId && response) {
      if (response?.data) {
        this.handleUpcomingData(response.data);
      }
    }

    if (apiRequestCallId === this.getPreviousMessageId && response) {
      if (response?.data) {
        this.handlePreviousData(response.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getUpcoming();
    this.getPrevious();

    const propPassingStudentAppointments = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingStudentAppointments.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingStudentAppointments);
  };

  // Customizable Area Start
  pageChange = (pageIndex: number) => {
    this.setState({
      pageIndex,
    });
  };

  handleUpcomingData = (responseData: AppointmentT[]) => {
    this.setState({
      upcomingData: responseData,
    });
  };

  handlePreviousData = (responseData: AppointmentT[]) => {
    this.setState({
      previousData: responseData,
    });
  };

  getUpcoming = async () => {
    this.setState({
      loading: true,
    });
    const getUpcomingMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getUpcomingMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/bms_bookeds/upcoming_appoints`
    );
    this.getUpcomingMessageId = getUpcomingMessage.messageId;
    getUpcomingMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getUpcomingMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getUpcomingMessage.id, getUpcomingMessage);
  };

  getPrevious = async () => {
    this.setState({
      loading: true,
    });
    const getPreviousMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getPreviousMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/bms_bookeds/previous_appoints`
    );
    this.getPreviousMessageId = getPreviousMessage.messageId;
    getPreviousMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPreviousMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getPreviousMessage.id, getPreviousMessage);
  };

  timeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  navigateToFeedback = (slotId: string) => {
    this.props.navigation.history.push(
      `/dashboard/book-my-slot-student/appointments/feedback?slotId=${slotId}`
    );
  };
  // Customizable Area End
}
