import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography as TypoMUI,
  Button,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Physics, emptyStar, starIcon } from "./assets";
import BannerWeb from "../../../components/src/Banner.web";
import { AppointmentT } from "./BookMySlotController.web";
import { boxStyles } from "./BookMySlot.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import BookMySlotBookingsController, {
  Props,
  configJSON,
} from "./BookMySlotBookingsController.web";

class BookMySlotBookings extends BookMySlotBookingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  UpcomingAppointmentsPage = () => {
    return <Box>{this.RenderUpcomingAppointments()}</Box>;
  };

  PreviousBookingsPage = () => {
    return <Box>{this.RenderPreviousBookings()}</Box>;
  };

  PageDeterminer = (pageIndex: number) => {
    if (pageIndex === 0) {
      return this.UpcomingAppointmentsPage();
    }

    return this.PreviousBookingsPage();
  };

  RenderAppointmentBox = (dataUpcoming: AppointmentT) => {
    return (
      <Grid item key={`notifs-${dataUpcoming.id}`} sm={12}>
        <Box className={this.props.classes.appointmentBox}>
          <Box className="details-appointment">
            <img src={Physics} alt={"physics-image"} />
            <Box>
              <Box className="text-appointment-headers">
                <TypoMUI>{configJSON.StudentName}</TypoMUI>
                <TypoMUI>Parent Name</TypoMUI>
                <TypoMUI>Group</TypoMUI>
                <TypoMUI>Zoom Link</TypoMUI>
              </Box>
              <Box className="text-appointment-description-dots">
                <TypoMUI>:</TypoMUI>
                <TypoMUI>:</TypoMUI>
                <TypoMUI>:</TypoMUI>
                <TypoMUI>:</TypoMUI>
                <TypoMUI />
              </Box>
              <Box className="text-appointment-description">
                <TypoMUI>{dataUpcoming.attributes.student_name}</TypoMUI>
                <TypoMUI>{dataUpcoming.attributes.parent_name}</TypoMUI>
                <TypoMUI>{dataUpcoming.attributes.group}</TypoMUI>
                <a href={dataUpcoming.attributes.zoom_url}>
                  {dataUpcoming.attributes.zoom_url}
                </a>
                <TypoMUI className={this.props.classes.dateslotStyle}>
                  Time Slot: {dataUpcoming.attributes.slot_date}{" "}
                  {dataUpcoming.attributes.time_slot}
                </TypoMUI>
              </Box>
            </Box>
          </Box>
          <Box className="buttons-appointment">
            <Button className="status">
              Status:{" "}
              {
                this.status.filter(
                  (status) => status.value === dataUpcoming.attributes.status
                )[0].label
              }
            </Button>
            <Grid spacing={3} container justifyContent="flex-end">
              <Button
                className="change"
                data-test-id="booking-change"
                onClick={() => this.changeSlot(dataUpcoming.id)}
              >
                Change
              </Button>
              <Button
                className="cancel"
                data-test-id="booking-change"
                onClick={() => this.cancelSlot(dataUpcoming.id)}
              >
                Cancel
              </Button>
            </Grid>
          </Box>
        </Box>
      </Grid>
    );
  };
  RenderPreviousBox = (dataPrevious: AppointmentT) => {
    const rating = dataPrevious.attributes.rating
      ? dataPrevious.attributes.rating
      : -1;

    return (
      <Grid key={`notifs-${dataPrevious.id}`} sm={12} item>
        <Box className={this.props.classes.appointmentBox}>
          <Box className="details-appointment" position={"relative"}>
            <img src={Physics} alt={"physics-image"} />
            <Box position={"relative"}>
              <Box className="text-appointment-headers" position={"relative"}>
                <TypoMUI>{configJSON.StudentName}</TypoMUI>
                <TypoMUI>Parent Name</TypoMUI>
                <TypoMUI>Group</TypoMUI>
                <TypoMUI>Zoom Link</TypoMUI>
              </Box>
              <Box
                className="text-appointment-description-dots"
                position={"relative"}
              >
                <TypoMUI>:</TypoMUI>
                <TypoMUI>:</TypoMUI>
                <TypoMUI>:</TypoMUI>
                <TypoMUI>:</TypoMUI>
                <TypoMUI />
              </Box>
              <Box
                className="text-appointment-description"
                position={"relative"}
              >
                <TypoMUI>{dataPrevious.attributes.student_name}</TypoMUI>
                <TypoMUI>{dataPrevious.attributes.parent_name}</TypoMUI>
                <TypoMUI>{dataPrevious.attributes.group}</TypoMUI>
                <a href={dataPrevious.attributes.zoom_url}>
                  {dataPrevious.attributes.zoom_url}
                </a>
                <TypoMUI className={this.props.classes.dateslotStyle}>
                  Time Slot: {dataPrevious.attributes.slot_date}{" "}
                  {dataPrevious.attributes.time_slot}
                </TypoMUI>
              </Box>
            </Box>
          </Box>
          <Box className="buttons-appointment">
            <Button className="status">
              Status:{" "}
              {
                this.status.filter(
                  (status) => status.value === dataPrevious.attributes.status
                )[0].label
              }
            </Button>
          </Box>
          <Box marginTop={"1.3em"}>
            <Box display={"flex"} gridGap={"1em"}>
              <TypoMUI className={this.props.classes.typographyStyle}>
                Feedback:{" "}
                <Box
                  display={"inline-block"}
                  onClick={() => this.navigateToFeedbacks(dataPrevious.id)}
                  style={{ cursor: "pointer" }}
                >
                  {Array.from(Array(5).keys()).map((_, index) => {
                    return (
                      <>
                        {rating > index ? (
                          <img
                            width={"20px"}
                            src={starIcon}
                            alt="filled"
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <img
                            width={"20px"}
                            src={emptyStar}
                            alt="empty"
                            data-test-id="rate-btn-star"
                          />
                        )}
                      </>
                    );
                  })}
                </Box>
              </TypoMUI>
            </Box>
            <Box display={"flex"} gridGap={"1em"}>
              <TypoMUI className={this.props.classes.typographyStyle}>
                Remarks:
              </TypoMUI>
              <TypoMUI>{dataPrevious.attributes.remarks}</TypoMUI>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  };

  RenderUpcomingAppointments = () => {
    const appointments = this.state.upcomingData;
    if (appointments.length === 0) {
      return <TypoMUI>There are no upcoming appointments</TypoMUI>;
    }

    return (
      <Grid container direction="row" alignItems="center" spacing={4}>
        {appointments
          .filter((data) => data.attributes.slot_type !== "cancel_slot")
          .sort((dataA, dataB) => {
            const dateA = new Date(dataA.attributes.slot_date).getTime();
            const dateB = new Date(dataB.attributes.slot_date).getTime();

            if (dateA !== dateB) {
              return dateA - dateB;
            }

            const [startA] = dataA.attributes.time_slot.split(" - ");
            const [startB] = dataB.attributes.time_slot.split(" - ");
            return this.timeToMinutes(startA) - this.timeToMinutes(startB);
          })
          .map((appointment) => {
            return this.RenderAppointmentBox(appointment);
          })}
      </Grid>
    );
  };

  RenderPreviousBookings = () => {
    let appointments = this.state.previousData;
    appointments = appointments.sort((dataA, dataB) => {
      const dateA = new Date(dataA.attributes.slot_date).getTime();
      const dateB = new Date(dataB.attributes.slot_date).getTime();

      if (dateA !== dateB) {
        return dateB - dateA;
      }

      const [startA] = dataA.attributes.time_slot.split(" - ");
      const [startB] = dataB.attributes.time_slot.split(" - ");
      return this.timeToMinutes(startA) - this.timeToMinutes(startB);
    });

    if (appointments.length === 0) {
      return <TypoMUI>There are no previous bookings</TypoMUI>;
    }

    return (
      <Grid container direction="row" alignItems="center" spacing={4}>
        {appointments.map((appointment) => {
          return this.RenderPreviousBox(appointment);
        })}
      </Grid>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <BannerWeb title="Slot Bookings" />
        <Box className={this.props.classes.navigationBox}>
          <Button
            onClick={() => this.pageChange(0)}
            data-test-id="button-upcoming"
            className={this.state.pageIndex === 0 ? "active" : ""}
          >
            Upcoming Appointments
          </Button>
          <Button
            onClick={() => this.pageChange(1)}
            data-test-id="button-previous"
            className={this.state.pageIndex === 1 ? "active" : ""}
          >
            Previous Bookings
          </Button>
        </Box>
        <Box padding={"2em"}>{this.PageDeterminer(this.state.pageIndex)}</Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleBookMySlotBookings = () =>
  createStyles({
    appointmentBox: {
      ...boxStyles,
      "& .cancel": {
        width: "200px !important",
      },
      "& .change": {
        width: "200px !important",
        color: "#FF3A3A",
        border: "1px solid #FF3A3A",
        marginRight: "1em",
      },
    },
    dateslotStyle: {
      fontWeight: 500,
      color: "#808080",
    },
    navigationBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "2em",
      gap: "1em",
      "& button": {
        width: "300px",
        height: "50px",
        color: "#222",
        border: "1px solid #FF3A3A",
        borderRadius: "0.5em",
        fontWeight: 500,
        fontSize: "1.2em",
      },
      "& .active": {
        background: "#DFCAFF",
        border: "none",
      },
    },
    typographyStyle: {
      fontSize: "1.1em",
      fontWeight: 500,
    },
  });
export default withStyles(webStyleBookMySlotBookings)(BookMySlotBookings);
export { BookMySlotBookings };
// Customizable Area End
