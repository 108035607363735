import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { WeeklyTest } from "./ReviewTestWeeklyMainController.web";
type Root = Root2[];

interface Root2 {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Attributes {
  id: number;
  account_id: number;
  bookmarkable_id: number;
  bookmarkable_type: string;
  question_id: number;
  highlighted_text: any;
  question_no: number;
  question: string;
  created_at: string;
  updated_at: string;
  bookmarkable: Bookmarkable;
}

interface Bookmarkable {
  id: number;
  account_id: number;
  weekly_test_id: number;
  time_taken: string;
  correct_answer: number;
  unattended: number;
  incorrect_answer: number;
  marks: number;
  total_questions: number;
  score_db: string;
  question_ids: string[];
  score_id: number;
  created_at: string;
  updated_at: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bookmarks: Root;
  submitModalOpen: boolean;
  submitModalOpen2: boolean;
  loading: boolean;
  examName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WeeklyTestBookmarkController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  reviewId = "";
  getBookmarksNotesMessageId = "";
  getReviewHistoriesWeeklyMessageId = "";
  breadcrumb = [
    {
      link: "/dashboard/weekly-test/instructions",
      label: "Weekly Test",
    },
    {
      link: "/dashboard/weekly-test/instructions",
      label: "Instructions",
    },
    {
      link: "/dashboard/weekly-test/tests",
      label: "Tests",
    },
    {
      link: "/dashboard/weekly-test/tests",
      label: "Subject",
    },
    {
      link: `/dashboard/weekly-test/tests/review-test/${this.props.navigation.getParam(
        "testId"
      )}`,
      label: "Reviews",
    },
    {
      label: "Reviews",
      link: `/dashboard/weekly-test/tests/review-test/${this.props.navigation.getParam(
        "testId"
      )}`,
    },
    {
      link: `/dashboard/weekly-test/tests/review-test/${this.props.navigation.getParam(
        "testId"
      )}/review/${this.props.navigation.getParam("reviewId")}`,
      label: "Review Test",
    },
    {
      label: "Bookmarks",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  token = "";
  bookmarkIdToDelete = "";
  indexToDelete = Infinity;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      submitModalOpen2: false,
      bookmarks: [],
      submitModalOpen: false,
      loading: false,
      examName: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getBookmarksNotesMessageId) {
      if (responseJson?.data) {
        this.successfulFetchBookmark(responseJson.data);
      }
    }

    if (apiRequestCallId === this.getReviewHistoriesWeeklyMessageId) {
      if (responseJson?.weekly_test) {
        this.handleSuccessFetchResponse(responseJson.weekly_test);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingWTBC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingWTBC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingWTBC);
    const token = await storage.get("authToken");
    if (token) {
      this.token = token;
    }
    const windowUrl = window.location.href;
    const urlParams = new URLSearchParams(windowUrl.split("?")[1]);
    const reviewId = urlParams.get("reviewId");
    if (reviewId) {
      this.reviewId = reviewId;
      this.getBookmarksNotes(reviewId);
    }

    this.getReviewHistoriesWeekly();
  };

  handleSubmitModalClose2 = () => {
    this.setState({
      submitModalOpen2: false,
    });
  };

  successfulFetchBookmark = (response: Root) => {
    this.setState({
      bookmarks: response,
    });
  };
  handleSubmitModalClose = () => {
    this.setState({
      submitModalOpen: false,
    });
  };

  initializeBookmarkDeletion = (bookmarkId: string, index: number) => {
    this.bookmarkIdToDelete = bookmarkId;
    this.indexToDelete = index;
    this.setState({
      submitModalOpen: true,
    });
  };

  // Customizable Area Start
  deleteBookmarkNotes = (bookmarkId: string, index: number) => {
    const deleteBookmarkNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: this.token,
    };
    let bookmarks = this.state.bookmarks;
    bookmarks = bookmarks.filter((_, indexFilter) => indexFilter !== index);

    this.setState({
      bookmarks,
      submitModalOpen: false,
      submitModalOpen2: true,
    });
    deleteBookmarkNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deleteBookmarkNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    deleteBookmarkNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/bookmarks/${bookmarkId}`
    );

    runEngine.sendMessage(
      deleteBookmarkNotesMessage.id,
      deleteBookmarkNotesMessage
    );
  };

  getBookmarksNotes = (reviewId: string) => {
    const getBookmarksNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: this.token,
    };

    this.getBookmarksNotesMessageId = getBookmarksNotesMessage.messageId;

    getBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/bookmarks?weekly_test_review_history_id=${reviewId}`
    );
    getBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getBookmarksNotesMessage.id,
      getBookmarksNotesMessage
    );
  };

  getReviewHistoriesWeekly = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getHistoryMessageBC = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReviewHistoriesWeeklyMessageId = getHistoryMessageBC.messageId;

    getHistoryMessageBC.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getHistoryMessageBC.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/${this.props.navigation.getParam(
        "testId"
      )}/review_histories`
    );

    getHistoryMessageBC.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getHistoryMessageBC.id, getHistoryMessageBC);
  };

  handleSuccessFetchResponse = (response: WeeklyTest) => {
    const breadcrumb = this.breadcrumb;
    breadcrumb[3].label = response.test_subject;
    breadcrumb[4].label = response.test_name;

    const propPassingRTWMC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingRTWMC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingRTWMC);

    this.setState({
      loading: false,
      examName: response.test_name,
    });
  };
  // Customizable Area End
}
