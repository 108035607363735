import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { WithStyles } from "@material-ui/core/styles";
import { webStyleRecordedClassVideo } from "./RecordedClassVideo.web";
import { TableUpcomingPrevious } from "../../CfZoomIntegration16/src/LiveClassController.web";
export interface Swayam {
  id: string;
  type: string;
  attributes: Attributes2;
}

export interface Attributes2 {
  id: number;
  subject_id: number;
  chapter_id: number;
  subject_name: string;
  chapter_name: string;
  class_name: string;
  video_name: string;
  part: number;
  url: string;
  date_telecast: string;
  video_by: string;
  video_name_origin: string;
  created_by: string;
  remarks: string;
  status: string;
  image_url: any;
  viewed: boolean;
  created_at: string;
  updated_at: string;
  youtube_video_id: string;
}

export interface RecordedClass {
  id: string;
  type: string;
  attributes: {
    id: number;
    category_name: string;
    subject_name: string;
    chapter_name: string;
    video_name: string;
    recording_url: string;
    logo: string;
    class_status: string;
    class_date: string;
    conducted_by: string;
    academic_year: string;
    view_recorded_classes_count: number;
    average_rating: number;
    paused_at: string;
    created_at: string;
    updated_at: string;
  };
}

type CurrentVideoType = Swayam & RecordedClass & TableUpcomingPrevious;
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleRecordedClassVideo> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentVideo: CurrentVideoType | null;
  videoType: string;
  videoId: string;
  isModalOpenRate: boolean;
  isModalOpenReport: boolean;
  isModalOpenReportSuccess: boolean;
  userInput: {
    report: string;
    rate: string;
  };
  isModalOpenRateMessage: boolean;
  isModalOpenRateMessageSuccess: boolean;
  starsIndex: number;
  isModalRecordingExist: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RecordedClassVideoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  startTime: number | null = null;
  totalTimeWatched: number = 0;
  addTotalTimeSpentWatchingMessageId = "";
  getRecordedClassVideoMessageId = "";
  getSwayamVideoMessageId = "";
  postReportMessageId = "";
  postRateMessageId = "";
  getPreviousMessageId = "";
  searchResolve = this.props.navigation.history.location.search;
  breadcrumb = [
    {
      label: "Recorded Classes",
      link: "/dashboard/classes/recorded-class",
    },
    {
      label: "Watch",
      link:
        this.props.navigation.history.location.pathname + this.searchResolve,
    },
    {
      label: "Video",
      link:
        this.props.navigation.history.location.pathname + this.searchResolve,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.startTime = null;
    this.totalTimeWatched = 0;

    this.state = {
      // Customizable Area Start
      currentVideo: null,
      videoType: "",
      videoId: "",
      isModalOpenReport: false,
      isModalOpenRate: false,
      userInput: {
        report: "",
        rate: "",
      },
      isModalOpenReportSuccess: false,
      isModalOpenRateMessage: false,
      isModalOpenRateMessageSuccess: false,
      isModalRecordingExist: false,
      starsIndex: -1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getRecordedClassVideoMessageId) {
      if (response.data) {
        this.handleSuccessfulFetchVideo(response.data);
      }
    }

    if (apiRequestCallId === this.getSwayamVideoMessageId) {
      if (response.data) {
        this.handleSuccessfulFetchVideoSwayam(response.data);
      }
    }

    if (apiRequestCallId === this.getPreviousMessageId && response) {
      if (response.data) {
        this.handleSuccessLive(response.data);
      }
    }

    if (apiRequestCallId === this.postReportMessageId) {
      this.handleModalReport();
      this.handleModalReportSuccess();
    }

    if (apiRequestCallId === this.postRateMessageId) {
      this.handleModalRateMessage();
      this.handleModalRateMessageSuccess();
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingRC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingRC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingRC);
    const urlParams = this.props.navigation.history.location.search;
    const { id, type } = this.extractParams(urlParams);

    if (id && type) {
      this.handleTypeId(id, type);
    } else {
      this.failExtract();
    }
  };

  failExtract = () => {
    this.setState({
      isModalRecordingExist: true,
    });
  };

  extractParams(url: string): { id: string | null; type: string | null } {
    const params: { id: string | null; type: string | null } = {
      id: null,
      type: null,
    };

    const match = url.match(/\?id=(\d+)&type=(\w+)/);
    if (match) {
      params.id = match[1];
      params.type = match[2];
    }

    return params;
  }

  handleModalReport = () => {
    this.setState({
      isModalOpenReport: !this.state.isModalOpenReport,
    });
  };

  handleModalRate = () => {
    this.setState({
      isModalOpenRate: !this.state.isModalOpenRate,
    });
  };

  handleModalReportSuccess = () => {
    this.setState({
      isModalOpenReportSuccess: !this.state.isModalOpenReportSuccess,
    });
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  handleModalRateMessage = () => {
    this.setState({
      isModalOpenRateMessage: !this.state.isModalOpenRateMessage,
    });
  };

  handleModalRateMessageSuccess = () => {
    this.setState({
      isModalOpenRateMessageSuccess: !this.state.isModalOpenRateMessageSuccess,
    });
  };

  handleInputChange = (report: string) => {
    this.setState({
      userInput: {
        ...this.state.userInput,
        report: report,
      },
    });
  };

  handleTypeId = (videoId: string, videoType: string) => {
    this.setState(
      {
        videoId,
        videoType,
      },
      () => {
        if (videoType === "swayam") {
          this.getSwayamVideo();
          const breadcrumb = this.breadcrumb;
          breadcrumb[0] = {
            label: "Swayam Prabha",
            link: "/dashboard/swayam-prabha",
          };

          const propPassingRC = new Message(
            getName(MessageEnum.NavigationBreadcrumbMessage)
          );
          propPassingRC.addData(
            getName(MessageEnum.BreadcrumbDataMessage),
            breadcrumb
          );
          this.send(propPassingRC);
        }
        if (videoType === "recorded") {
          this.getRecordedClassVideo();
        }
        if (videoType === "live") {
          this.getLiveClassVideo();
        }
      }
    );
  };

  getLiveClassVideo = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    const getPreviousMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPreviousMessageId = getPreviousMessage.messageId;

    getPreviousMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getPreviousMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/live_classes/previous_classes`
    );

    getPreviousMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getPreviousMessage.id, getPreviousMessage);
  };

  handleSuccessLive = (responseData: TableUpcomingPrevious[]) => {
    for (const video of responseData) {
      if (video.id === this.state.videoId) {
        if (!video.attributes.recording_url) {
          this.setState({
            isModalRecordingExist: true,
          });
        } else {
          const breadcrumb = this.breadcrumb;
          breadcrumb[2].label = video.attributes.chapter_name;
          const propPassingRC = new Message(
            getName(MessageEnum.NavigationBreadcrumbMessage)
          );
          propPassingRC.addData(
            getName(MessageEnum.BreadcrumbDataMessage),
            breadcrumb
          );
          this.send(propPassingRC);

          this.setState({
            currentVideo: video as CurrentVideoType,
          });
          break;
        }
      }
    }
  };

  handleRatedStars = (index: number) => {
    this.setState((prev) => ({
      ...prev,
      starsIndex: index,
    }));
  };

  handleSuccessfulFetchVideo = (video: RecordedClass) => {
    type AllType = RecordedClass & Swayam & TableUpcomingPrevious;

    if (video.id === this.state.videoId) {
      if (!video.attributes.recording_url) {
        this.setState({
          isModalRecordingExist: true,
        });
      } else {
        const breadcrumb = this.breadcrumb;
        breadcrumb[2].label = (video as AllType).attributes.video_name;

        const propPassingRC = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingRC.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingRC);

        this.setState({
          currentVideo: video as AllType,
        });
      }
    }
  };

  tick = () => {
    if (!this.startTime) {
      this.startTime = Date.now();
    }
  };

  handlePauseOrEnd = () => {
    if (this.startTime) {
      const timePlayed = Date.now() - this.startTime;
      this.totalTimeWatched += timePlayed;
      this.startTime = null;
      this.addTotalTimeSpentWatching(this.formatTime(this.totalTimeWatched));
    }
  };

  handleEnded = () => {
    this.handlePauseOrEnd();
  };

  formatTime = (milliseconds: number): string => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return [
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
      seconds.toString().padStart(2, "0"),
    ].join(":");
  };

  addTotalTimeSpentWatching = async (timeSpent: string) => {
    const addTotalTimeSpentWatchingMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.addTotalTimeSpentWatchingMessageId =
      addTotalTimeSpentWatchingMessage.messageId;

    addTotalTimeSpentWatchingMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    addTotalTimeSpentWatchingMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_scheduling/recorded_classes/${this.state.videoId}/time_spent?time_spent=${timeSpent}`
    );

    addTotalTimeSpentWatchingMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      addTotalTimeSpentWatchingMessage.id,
      addTotalTimeSpentWatchingMessage
    );
  };

  handleSuccessfulFetchVideoSwayam = (response: Swayam[]) => {
    type AllType = RecordedClass & Swayam & TableUpcomingPrevious;

    for (const video of response) {
      if (video.id === this.state.videoId) {
        if (!video.attributes.url) {
          this.setState({
            isModalRecordingExist: true,
          });
        } else {
          const breadcrumb = this.breadcrumb;
          breadcrumb[2].label = (video as AllType).attributes.video_name;

          const propPassingRCSwayam = new Message(
            getName(MessageEnum.NavigationBreadcrumbMessage)
          );
          propPassingRCSwayam.addData(
            getName(MessageEnum.BreadcrumbDataMessage),
            breadcrumb
          );
          this.send(propPassingRCSwayam);

          this.setState({
            currentVideo: video as AllType,
          });
          break;
        }
      }
    }
  };

  handleInputChangeRate = (rate: string) => {
    this.setState({
      userInput: {
        ...this.state.userInput,
        rate: rate,
      },
    });
  };

  // Customizable Area Start
  getRecordedClassVideo = async () => {
    const getRecordedClassVideoMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getRecordedClassVideoMessageId =
      getRecordedClassVideoMessage.messageId;

    getRecordedClassVideoMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getRecordedClassVideoMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/recorded_classes/${this.state.videoId}`
    );

    getRecordedClassVideoMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getRecordedClassVideoMessage.id,
      getRecordedClassVideoMessage
    );
  };

  handleRecordingModal = () => {
    this.setState({
      isModalRecordingExist: !this.state.isModalRecordingExist,
    });
  };

  getSwayamVideo = async () => {
    const getSwayamVideoMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getSwayamVideoMessageId = getSwayamVideoMessage.messageId;

    getSwayamVideoMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getSwayamVideoMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_videos8/swayamprabhas`
    );

    getSwayamVideoMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getSwayamVideoMessage.id, getSwayamVideoMessage);
  };

  sendReport = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const postReportMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const body = {
      report: {
        contant: this.state.userInput.report,
      },
    };

    this.postReportMessageId = postReportMessage.messageId;

    postReportMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    postReportMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/recorded_classes/${this.state.videoId}/report`
    );

    postReportMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    postReportMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(postReportMessage.id, postReportMessage);

    this.setState({
      userInput: {
        ...this.state.userInput,
        report: "",
      },
    });
  };

  sendRate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const postRateMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const body = {
      recorded_class_review: {
        recorded_class_id: this.state.videoId,
        rating: this.state.starsIndex + 1,
        comment: this.state.userInput.rate,
      },
    };

    this.postRateMessageId = postRateMessage.messageId;

    postRateMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    postRateMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/recorded_class_reviews`
    );

    postRateMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    postRateMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(postRateMessage.id, postRateMessage);

    this.setState({
      starsIndex: -1,
      userInput: {
        ...this.state.userInput,
        report: "",
      },
    });
  };
  // Customizable Area End
}
