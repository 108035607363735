import React from "react";

import {
  Box as MUIBox,
  // Customizable Area Start
  Button,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import QuestionBankSavedNotesAddEditController, {
  Props,
} from "./QuestionBankSavedNotesAddEditController.web";

export default class QuestionBankSavedNotesAddEdit extends QuestionBankSavedNotesAddEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const subjectId = this.props.navigation.getParam("subjectId");
    const chapterId = this.props.navigation.getParam("chapterId");
    return (
      // Customizable Area Start
      <MUIBox>
        <Loader loading={this.state.loading} />
        <MUIBox style={webStyle.centerHorizontally}>
          <Modal
            open={this.state.isNoteCreateSavedSuccessfully}
            BackdropProps={{ onClick: undefined }}
            onClose={this.handleNoteCreateModalClose}
          >
            <MUIBox style={webStyle.modalStyle}>
              <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
              <h3>
                {this.state.noteType === "Edit"
                  ? "Changes Updated Successfully!"
                  : "Note Saved Successfully"}
              </h3>
              <MUIBox>
                <Link
                  to={`/dashboard/question-bank/subject/${subjectId}/chapter/${chapterId}/saved-notes`}
                >
                  <Button
                    onClick={this.handleNoteCreateModalClose}
                    style={webStyle.buttonStyleYes}
                    data-test-id="go-saved-success"
                  >
                    Go to saved notes
                  </Button>
                </Link>
              </MUIBox>
            </MUIBox>
          </Modal>
          <Modal
            onClose={this.handleNoteCancelModalClose}
            BackdropProps={{ onClick: undefined }}
            open={this.state.isNoteCancellationModalOpen}
          >
            <MUIBox style={webStyle.modalStyle}>
              <h3>Are you sure you want to cancel note without saving?</h3>
              <MUIBox>
                <Button
                  style={webStyle.buttonStyleNo}
                  data-test-id="go-saved-no"
                  onClick={this.handleNoteCancelModalClose}
                >
                  No
                </Button>
                <Button
                  style={webStyle.buttonStyleYes}
                  data-test-id="go-saved-yes"
                  onClick={this.confirmCancel}
                >
                  Yes
                </Button>
              </MUIBox>
            </MUIBox>
          </Modal>
          <Modal
            open={this.state.cancelModalConfirm}
            onClose={this.handleNoteCancelConfirmModalClose}
            BackdropProps={{ onClick: undefined }}
          >
            <MUIBox style={webStyle.modalStyle}>
              <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
              <h3>Cancelled Note Successfully!</h3>
              <MUIBox>
                <Link
                  to={`/dashboard/question-bank/subject/${subjectId}/chapter/${chapterId}/saved-notes`}
                >
                  <Button
                    onClick={this.handleNoteCancelConfirmModalClose}
                    data-test-id="go-back"
                    style={webStyle.buttonStyleYes}
                  >
                    Go Back
                  </Button>
                </Link>
              </MUIBox>
            </MUIBox>
          </Modal>
          <MUIBox style={webStyle.bannerStyle}>
            <h2>{this.state.noteType} Note</h2>
          </MUIBox>
          <MUIBox style={webStyle.inputStyle}>
            <MUIBox style={webStyle.inputBannerStyle}>
              <p>Exam : {this.state.chapterName}</p>
              <p>Question No - {this.state.questionNumber}</p>
            </MUIBox>
            <textarea
              data-test-id="text-area"
              name="textarea"
              value={this.state.content}
              onChange={(event) => this.handleInputChange(event.target.value)}
              style={webStyle.textInputStyle}
              placeholder="Write your note here"
            />
          </MUIBox>
          <MUIBox>
            <Button
              data-test-id="cancel-btn"
              style={webStyle.buttonStyleNo}
              onClick={this.handleNoteCancelModalOpen}
            >
              Cancel
            </Button>
            <Button
              data-test-id="save-btn"
              style={webStyle.buttonStyleYes}
              onClick={this.NoteApi}
            >
              {this.state.noteType === "New" ? "Save" : "Update"}
            </Button>
          </MUIBox>
        </MUIBox>
      </MUIBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  bannerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    background: "rgba(247, 214, 255, 0.19)",
    height: "71px",
    color: "rgb(82, 0, 130)",
  },
  buttonStyleYes: {
    color: "white",
    height: "50px",
    width: "200px",
    textTransform: "none" as const,
    marginLeft: "1em",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
  },
  buttonStyleNo: {
    height: "50px",
    width: "200px",
    background: "white",
    color: "#4F1f6d",
    borderRadius: "0.6em",
    textTransform: "none" as const,
    border: "1px solid #ffe9ed",
  },
  inputBannerStyle: {
    justifyContent: "space-between",
    display: "flex",
    borderBottom: "1px solid gray",
    alignItems: "center",
    width: "100%",
    height: "70px",
    fontSize: "24px",
    padding: "0em 2em",
  },
  inputStyle: {
    height: "max-content",
    border: "1px solid rgb(255,167,167)",
    width: "90%",
  },
  centerHorizontally: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "1em",
  },
  textInputStyle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    border: "none",
    width: "100%",
    minHeight: "402px",
    fontSize: "20px",
    padding: "1.2em 1.2em",
    outline: "none",
    ":focus": {
      outline: "none",
    },
    resize: "none" as const,
  },
  modalStyle: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    minHeight: "max-content",
    backgroundColor: "white",
    border: "2px solid #FFD0D8",
    borderRadius: "1.5em",
    padding: "2em",
    gap: "1.5em",
  },
};
// Customizable Area End
