import React from "react";

import {
  Box,
  // Customizable Area Start
  createStyles,
  withStyles,
  Typography,
  Switch,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

import MyPackagesController, { Props } from "./MyPackagesController.web";
import Loader from "../../../components/src/Loader.web";

class MyPackages extends MyPackagesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const classes = this.props.classes;
    return (
      // Customizable Area Start
      <Box className={classes.pageLayoutStyle}>
        <Typography variant="h5" style={{ fontWeight: 500 }}>
          My Package
        </Typography>
        <Loader loading={this.state.loading} />
        {this.state.packageData.map((packageCurr, index) => {
          return (
            <Box
              key={`${index}-${
                packageCurr.attributes.account_data.student_name
              }`}
              className="package-container"
            >
              <Box style={{ margin: "3em 0 1em 0" }}>
                <Typography style={{ fontSize: "1.5em" }}>
                  Package {index + 1}
                </Typography>
                {this.state.packageData.length > 1 && (
                  <Box
                    style={{ position: "absolute", right: "0", top: "38px" }}
                  >
                    <Switch
                      color="primary"
                      checked={packageCurr.attributes.active}
                      onChange={() =>
                        this.handleToggleActive(
                          packageCurr.id,
                          packageCurr.attributes.active
                        )
                      }
                      inputProps={{ "aria-label": "controlled" }}
                      data-test-id="set-active-btn"
                    />
                  </Box>
                )}
              </Box>
              <Box className={classes.packageStyle}>
                <Box className="package-title">
                  <ul>
                    <li>Package level</li>
                    <li>Ask Your Query</li>
                    <li>Live Classes</li>
                    <li>Expiry date</li>
                    <li>Amount Paid</li>
                  </ul>
                </Box>
                <Box className="package-description">
                  <ul>
                    <li>{packageCurr.attributes.package_level}</li>
                    <li>{packageCurr.attributes.ayq_level}</li>
                    <li>{packageCurr.attributes.live_class_level}</li>
                    <li>{packageCurr.attributes.expiry_date}</li>
                    <li style={{ fontWeight: 700 }}>
                      RS {packageCurr.attributes.amount}
                    </li>
                  </ul>
                </Box>
              </Box>
              <Typography style={{ margin: "3em 0 1em 0", fontSize: "1.5em" }}>
                Registration Details
              </Typography>
              <Box className={classes.registerStyle}>
                <Box className="register-title">
                  <ul>
                    <li>Register ID</li>
                    <li>My Rank ID</li>
                    <li>Student name</li>
                    <li>Mobile number</li>
                    <li>Expiry date</li>
                  </ul>
                </Box>
                <Box className="register-description">
                  <ul>
                    <li>{packageCurr.attributes.account_data.register_id}</li>
                    <li>{packageCurr.attributes.account_data.myrank_id}</li>
                    <li>{packageCurr.attributes.account_data.student_name}</li>
                    <li>{packageCurr.attributes.account_data.mobile_number}</li>
                    <li>{packageCurr.attributes.account_data.expiry_date}</li>
                  </ul>
                </Box>
              </Box>
            </Box>
          );
        })}
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          gridGap={"2em"}
          marginTop={"3em"}
        >
          <Typography variant="h6">Contact us to add more Package</Typography>
          <Button
            data-test-id="go-to-contact"
            className={classes.contactUsStyle}
            onClick={this.handleContactUsNavigate}
          >
            Contact US
          </Button>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleMyPackages = () =>
  createStyles({
    pageLayoutStyle: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "2em",
      "& .package-container": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        position: "relative",
        "& ul": {
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          gap: "2em",
          "& li": {
            width: "200px",
            margin: "0 auto",
            textAlign: "left",
            fontSize: "1.5em",
            whiteSpace: "nowrap",
          },
        },
      },
    },
    packageStyle: {
      display: "flex",
      width: "100%",
      height: "400px",
      background: "rgb(242,240,243)",
      "& .package-title": {
        width: "50%",
        borderRight: "0.3px solid #ccc",
      },
      "& .package-description": {
        width: "50%",
        borderRight: "0.3px solid #ccc",
      },
    },
    registerStyle: {
      display: "flex",
      width: "100%",
      height: "400px",
      background: "rgb(242,240,243)",
      "& .register-title": {
        width: "50%",
        borderRight: "0.3px solid #ccc",
        "& li": {
          fontWeight: 500,
        },
      },
      "& .register-description": {
        width: "50%",
        borderRight: "0.3px solid #ccc",
      },
    },
    contactUsStyle: {
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      width: "300px",
      height: "50px",
      borderRadius: "0.8em",
      color: "white",
    },
  });

export default withStyles(webStyleMyPackages)(MyPackages);
export { MyPackages };
// Customizable Area End
