import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
export interface GrandTestPackage {
  id: number;
  package_name: string;
  created_at: string;
  updated_at: string;
  package_description: string;
  status: string;
  subject_id: string;
  class_zone: string;
  practice_test: string;
  subjective_test: string;
  gt_code: string;
  question_bank: string;
  created_by: string;
  image_url: string;
}

export interface QuestionBankInterface {
  id: string;
  type: string;
  attributes: AttributesQB;
}

export interface AttributesQB {
  id: number;
  name: string;
  image: string;
  created_by: string;
  class_zone: string;
  practice_test: string;
  question_bank: string;
  subjective_test: string;
  st_code: string;
  remarks: string;
  icon_code: string;
  total_topics: number;
  saved_notes: number;
}

export interface QbChapter {
  id: string;
  type: string;
  attributes: QbAttributes;
}

export interface QbAttributes {
  id: number;
  name: string;
  image: string;
  practice_test: string;
  avail_pt: string;
  date_pt: any;
  class_zone: string;
  avail_cz: string;
  date_cz: any;
  question_bank: string;
  avail_qb: string;
  date_qb: any;
  created_by: string;
  remarks: string;
  chapter_read: boolean;
  viewed: boolean;
  continue_reading_topic_id: any;
  test_attempted: boolean;
  subject: {
    id: number;
  };
}

export type SubjectPracticeTestType = {
  id: string;
  attributes: { name: string };
};

type PackagePracticeTestType = {
  id: string;
  attributes: { package_name: string };
};

type RootLogin = {
  id: string;
  type: string;
  attributes: {
    name: string;
    first_name: string;
    image: string;
    uuid: string | number | null;
    course_notification_enable: boolean;
    exam_notification_enable: boolean;
    email: string;
    last_name: string;
    phone_number: number;
    myrank_id: string;
    city: string;
    status: string;
    user_type: string;
    package: Array<{
      id: number;
      ayq_level: number;
      lc_level: number;
      account_id: number;
      pack_final_id: number;
      pack_pricing_id: number;
      packfinal_feature_id: string | number | null;
      bcat_apply: number;
      actual_price: number;
      amount: number;
      validity: string | number | null;
      expiry_date: string;
      wb_level: number;
      bms_level: number;
      tution_level: number;
      acat_apply: number;
      created_by: string;
      created_at: string;
      updated_at: string;
      sms_level: string | number | null;
      active: boolean;
      sales_manager_name: string | number | null;
      agent1_name: string | number | null;
      agent2_name: string | number | null;
      transaction_type: string;
      status: string;
    }>;
  };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showSidebar: boolean;
  practiceTestPackagesName: PackagePracticeTestType[];
  practiceTestSubMenuName: SubjectPracticeTestType[][];
  isModalOpenLogout: boolean;
  showSubmenu: boolean;
  submenuOpenStatus: boolean[];
  grandTestPackagesName: GrandTestPackage[];
  showSubmenuGrandTest: boolean;
  questionBankSubjectsName: QuestionBankInterface[];
  showSubmenuQb: boolean;
  submenuOpenStatusQb: boolean[];
  qbSubMenuName: QbChapter[][];
  showSubmenuClasses: boolean;
  userData: RootLogin | null;
  profilePopup: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  token = "";
  getPackagesMessageId = "";
  getSubjectPracticeTestMessageId: { messageId: string; indexId: number }[] =
    [];
  getSubjectsQuestionBankMessageId: string = "";
  getChaptersQbMessageId: string[] = [];
  getGrandTestPackagesMessageId = "";
  getQbSubMenuMessageId: string[] = [];
  packagesPracticeTestMessageArrayWithId: {
    data: PackagePracticeTestType;
    indexId: number;
  }[] = [];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showSidebar: true,
      practiceTestPackagesName: [],
      practiceTestSubMenuName: [],
      isModalOpenLogout: false,
      showSubmenu: false,
      showSubmenuGrandTest: false,
      submenuOpenStatus: new Array<boolean>(0),
      submenuOpenStatusQb: new Array<boolean>(0),
      grandTestPackagesName: [],
      questionBankSubjectsName: [],
      qbSubMenuName: [],
      showSubmenuQb: false,
      showSubmenuClasses: false,
      userData: null,
      profilePopup: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getPackagesMessageId) {
      if (response?.data) {
        this.handleSuccessfulPackageFetch(response.data);
      }
    }

    if (apiRequestCallId === this.getSubjectsQuestionBankMessageId) {
      if (response?.data) {
        this.handleSuccessfulQuestionBankSubjectFetch(response.data);
      }
    }

    for (let index in this.getSubjectPracticeTestMessageId) {
      const messageId = this.getSubjectPracticeTestMessageId[index].messageId;
      const indexId = this.getSubjectPracticeTestMessageId[index].indexId;

      if (apiRequestCallId === messageId) {
        if (response?.data) {
          this.handleSuccessfulSubjectFetch(response.data, indexId);
        }
      }
    }

    if (apiRequestCallId === this.getGrandTestPackagesMessageId) {
      if (response?.data) {
        this.handleSuccessfulGTFetch(response.data);
      }
    }

    for (let index in this.getQbSubMenuMessageId) {
      if (apiRequestCallId === this.getQbSubMenuMessageId[index]) {
        this.getQbSubMenuMessageId = this.getQbSubMenuMessageId.filter(
          (messageId) => messageId !== apiRequestCallId
        );
        if (response.data) {
          this.handleSuccessfulSubmenuFetch(response.data);
        }
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const authTokenForDashboard = await storage.get("authToken");
    if (authTokenForDashboard) {
      this.token = authTokenForDashboard;
    }
    this.fetchUserData();
  };

  handleNavigateToCalculate = () => {
    this.props.navigation.history.push("/dashboard/calculate");
  };

  fetchUserData = async () => {
    const userData = JSON.parse(await storage.get("userData")) as RootLogin;

    if (userData.attributes.user_type === "ayq") {
      return this.props.navigation.history.push(
        "/dashboard-ask-your-query/home"
      );
    }

    this.setState(
      {
        userData,
      },
      () => {
        this.getPackages();
        this.getGrandTestPackages();
        this.getSubjectsQuestionBank();
      }
    );
  };

  handleSuccessfulQuestionBankSubjectFetch = (
    response: QuestionBankInterface[]
  ) => {
    for (const data of response) {
      this.getQbSubmenu(data.id);
    }
    this.setState({
      questionBankSubjectsName: response,
    });
  };

  toggleSubmenuGrandTest = () => {
    this.setState({
      showSubmenuGrandTest: !this.state.showSubmenuGrandTest,
    });
  };

  handleSuccessfulGTFetch = (response: GrandTestPackage[]) => {
    const seenNames = new Set();
    const grandTestPackagesName = response.filter((data) => {
      if (!seenNames.has(data.package_name)) {
        seenNames.add(data.package_name);
        return true;
      }
      return false;
    });

    this.setState({
      grandTestPackagesName,
    });
  };

  logout = async () => {
    await storage.clearStorage();
    this.handleOpenModal();
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpenLogout: false,
    });
  };

  handleOpenModal = () => {
    this.setState({
      isModalOpenLogout: true,
    });
  };

  navigateToLandingPage = () => {
    this.handleCloseModal();
    this.props.navigation.history.push("/");
  };

  setShowSidebar = () => {
    this.setState({
      showSidebar: !this.state.showSidebar,
    });
  };

  handleSuccessfulPackageFetch = (response: PackagePracticeTestType[]) => {
    const seenNames = new Set();
    const practiceTestPackagesName = response.filter((data) => {
      if (!seenNames.has(data.attributes.package_name)) {
        seenNames.add(data.attributes.package_name);
        return true;
      }
      return false;
    });

    for (const [indexId, data] of practiceTestPackagesName.entries()) {
      // Create a Practice Test Packages with indexId references for submenu to use
      this.packagesPracticeTestMessageArrayWithId.push({ data, indexId });

      // Fetch Subjects for Practice Test Subjects and pass index for referencing
      this.getSubjectsForPracticeTestPackages(data.id, indexId);
    }

    this.setState({
      practiceTestPackagesName,
    });
  };

  handleSuccessfulSubjectFetch = (
    response: SubjectPracticeTestType[],
    indexId: number
  ) => {
    const practiceTestPackagesArray =
      this.packagesPracticeTestMessageArrayWithId;
    const practiceTestSubMenuName: SubjectPracticeTestType[][] = [
      ...this.state.practiceTestSubMenuName,
    ];

    // Add Response on index of Package that match its id equivalent to subjects
    for (const data of practiceTestPackagesArray) {
      if (indexId === data.indexId) {
        practiceTestSubMenuName[indexId] = response;
        break;
      }
    }

    this.setState({
      practiceTestSubMenuName,
    });
  };

  handleSuccessfulSubmenuFetch = (response: QbChapter[]) => {
    const qbSubMenuName = this.state.qbSubMenuName;
    if (response.length > 0) {
      for (
        let indexI = 0;
        indexI < this.state.questionBankSubjectsName.length;
        indexI++
      ) {
        if (
          this.state.questionBankSubjectsName[indexI].id ===
          String(response[0].attributes.subject.id)
        ) {
          qbSubMenuName[indexI] = response;
          this.setState({
            qbSubMenuName,
          });
          break;
        }
      }
    }
  };

  navigateTo = (linkTo: string) => {
    this.props.navigation.history.push(linkTo);
  };

  determineIfActiveLink = (linkInfo: string): string => {
    const path = this.props.navigation.history.location.pathname;
    const searchParams = this.props.navigation.history.location.search;
    const totalPath = path + searchParams;

    if (totalPath.includes(linkInfo)) {
      return "active-link";
    }

    return "";
  };

  toggleSubmenu = () => {
    this.setState((prevState) => ({
      showSubmenu: !prevState.showSubmenu,
    }));
  };

  toggleSubmenuOpenQB = (index: number) => {
    const submenuOpenStatusQB = [...this.state.submenuOpenStatusQb];
    submenuOpenStatusQB[index] = !submenuOpenStatusQB[index];
    this.setState({ submenuOpenStatusQb: submenuOpenStatusQB });
  };

  navigateToSubjectQb = (navigation: string) => {
    this.props.navigation.history.push(navigation);
  };

  toggleAYQ = () => {
    this.props.navigation.history.push("/dashboard-ask-your-query/home");
  };

  toggleProfilePopUp = () => {
    this.setState((prevState) => ({
      profilePopup: !prevState.profilePopup,
    }));
  };

  navigateToPackages = () => {
    this.props.navigation.history.push("/dashboard/my-package");
  };

  toggleSubmenuQb = () => {
    this.setState((prevState) => ({
      showSubmenuQb: !prevState.showSubmenuQb,
    }));
  };

  toggleSubmenuClasses = () => {
    this.setState((prevState) => ({
      showSubmenuClasses: !prevState.showSubmenuClasses,
    }));
  };

  toggleSubmenuOpen = (index: number) => {
    const submenuOpenStatus = [...this.state.submenuOpenStatus];
    submenuOpenStatus[index] = !submenuOpenStatus[index];
    this.setState({ submenuOpenStatus });
  };

  // Customizable Area Start
  getPackages = () => {
    const headers = {
      token: this.token,
    };

    const getPackagesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPackagesMessageId = getPackagesMessage.messageId;

    getPackagesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/get_packages`
    );

    getPackagesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getPackagesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getPackagesMessage.id, getPackagesMessage);
  };

  getSubjectsForPracticeTestPackages = (subjectId: string, indexId: number) => {
    const headers = {
      token: this.token,
    };

    const getSubjectsMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubjectPracticeTestMessageId.push({
      messageId: getSubjectsMessage.messageId,
      indexId,
    });

    getSubjectsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${subjectId}/get_subjects`
    );

    getSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSubjectsMessage.id, getSubjectsMessage);
  };

  getGrandTestPackages = () => {
    const headers = {
      token: this.token,
    };

    const getGrandTestSubjectsMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGrandTestPackagesMessageId = getGrandTestSubjectsMessage.messageId;

    getGrandTestSubjectsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/get_packages`
    );

    getGrandTestSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getGrandTestSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getGrandTestSubjectsMessage.id,
      getGrandTestSubjectsMessage
    );
  };

  getSubjectsQuestionBank = async () => {
    const getSubjectsQuestionBankMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getSubjectsQuestionBankMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/question_banks/qb_subjects`
    );
    this.getSubjectsQuestionBankMessageId =
      getSubjectsQuestionBankMessage.messageId;
    getSubjectsQuestionBankMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSubjectsQuestionBankMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getSubjectsQuestionBankMessage.id,
      getSubjectsQuestionBankMessage
    );
  };

  getQbSubmenu = async (subjectId: string) => {
    const getQbSubMenuMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getQbSubMenuMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/${subjectId}/qb_chapters`
    );
    this.getQbSubMenuMessageId.push(getQbSubMenuMessage.messageId);
    getQbSubMenuMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getQbSubMenuMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getQbSubMenuMessage.id, getQbSubMenuMessage);
  };

  bookmySlotNavigate = async () => {
    const userType = JSON.parse(await storage.get("userData")) as RootLogin;

    switch (userType.attributes.user_type) {
      case "cm":
        this.props.navigation.history.push("/dashboard/book-my-slot");
        break;
      case "student":
        this.props.navigation.history.push("/dashboard/book-my-slot-student");
        break;
      case "faculty":
        this.props.navigation.history.push("/dashboard/book-my-slot-faculty");
        break;
      default:
        break;
    }
  };
  // Customizable Area End
}
