import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { webStyleSynopsisBookmarks } from "./SynopsisBookmarks.web";
import storage from "../../../framework/src/StorageProvider.web";
import { SubjectData } from "./SynopsisSubjectsController.web";
import { ChapterMeta } from "./SynopsisTopicsController.web";
export type Root = {
  id: string;
  type: string;
  attributes: {
    id: number;
    account_id: number;
    bookmarkable_id: number;
    bookmarkable_type: string;
    question_id: string | null;
    highlighted_text: string;
    question_no: string | null;
    question: string;
    created_at: string;
    updated_at: string;
    bookmarkable: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
      avail_synopsis: string;
      remarks: string;
      remark_2: string;
      created_by: string;
      date_synopsis: string | null;
      question_status: string;
      question_db: string;
      pt_db: string;
      synopsis_db: string;
      scorept_db: string;
    };
  };
};
// Customizable Area End

export interface Props extends WithStyles<typeof webStyleSynopsisBookmarks> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bookmarks: Root[];
  subject: SubjectData | null;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SynopsisBookmarksController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBookmarksMessageId = "";
  subjectIdParam = this.props.navigation.getParam("subjectId");
  breadcrumb = [
    {
      label: "Synopsis",
      link: `/dashboard/synopsis`,
    },
    {
      label: "Chapters",
      link: `/dashboard/synopsis/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}`,
    },
    {
      link: `/dashboard/synopsis/chapters/${
        this.subjectIdParam
      }/topics/${this.props.navigation.getParam("chapterId")}`,
      label: "Topics",
    },
    {
      link: `/dashboard/synopsis/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/topics/${this.props.navigation.getParam(
        "chapterId"
      )}/topic-read/${this.props.navigation.getParam("topicId")}/off`,
      label: "Topic Read",
    },
    {
      label: "Bookmarks",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  getSubjectsForBookmarksMessageId = "";
  getTopicsMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      bookmarks: [],
      subject: null,
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getBookmarksMessageId) {
      if (response.data) {
        this.handleSuccess(response.data);
      }
    }

    if (apiRequestCallId === this.getSubjectsForBookmarksMessageId) {
      if (response.data) {
        this.handleSubjects(response.data);
      }
    }

    if (apiRequestCallId === this.getTopicsMessageId) {
      if (response.data) {
        this.handleTopicOrSubjectResponse(response);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingSBC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSBC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingSBC);

    this.getBookmarks();
    this.getSubjectsForBookmarks();
    this.getTopics();
  };

  handleTopicOrSubjectResponse(responseData: {
    data: { id: string; attributes: { name: string } }[];
    meta: { chapter: { name: string } };
  }) {
    if (responseData?.data) {
      const id = this.props.navigation.getParam("topicId");
      const item = responseData.data.find(
        (data: { id: string; attributes: { name: string } }) => data.id === id
      );
      const name = item?.attributes.name;

      const breadcrumb = this.breadcrumb;
      breadcrumb[3].label = (name as string) + " Read";
      const propPassingSSNAEC = new Message(
        getName(MessageEnum.NavigationBreadcrumbMessage)
      );
      propPassingSSNAEC.addData(
        getName(MessageEnum.BreadcrumbDataMessage),
        breadcrumb
      );
      this.send(propPassingSSNAEC);
      this.handleMeta(responseData.meta);
    }
  }

  handleMeta = (meta: ChapterMeta) => {
    const breadcrumb = this.breadcrumb;
    breadcrumb[2].label = meta.chapter.name;

    const propPassingSCC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSCC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingSCC);
    this.setState({
      loading: false,
    });
  };

  handleSuccess = (response: Root[]) => {
    this.setState({
      bookmarks: response,
    });
  };

  navigateToNewBookmark = () => {
    this.props.navigation.history.push(
      `/dashboard/synopsis/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/topics/${this.props.navigation.getParam(
        "chapterId"
      )}/topic-read/${this.props.navigation.getParam("topicId")}/off`
    );
  };

  navigateToTopic = () => {
    this.props.navigation.history.push(
      `/dashboard/synopsis/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/topics/${this.props.navigation.getParam("chapterId")}`
    );
  };

  navigateToTopicRead = () => {
    const subjectId = this.props.navigation.getParam("subjectId");
    const chapterId = this.props.navigation.getParam("chapterId");
    const topicId = this.props.navigation.getParam("topicId");

    this.props.navigation.navigate("SynopsisTopicRead", {
      subjectId,
      chapterId,
      topicId,
      highlighted: "on",
    });
  };

  handleSubjects = (response: SubjectData[]) => {
    let subjectData: SubjectData | null = null;
    let subjectId = this.props.navigation.getParam("subjectId");
    for (let index = 0; index <= response.length - 1; index++) {
      if (response[index].id === subjectId) {
        subjectData = response[index];
        break;
      }
    }

    const breadcrumb = this.breadcrumb;
    breadcrumb[1].label = subjectData?.attributes.name as string;
    const propPassingSBC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSBC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingSBC);
    this.setState({
      subject: subjectData,
    });
  };
  // Customizable Area Start

  getBookmarks = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    const topicId = this.props.navigation.getParam("topicId");

    const getBookmarksMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBookmarksMessageId = getBookmarksMessage.messageId;

    getBookmarksMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/bookmarks?topic_id=${topicId}`
    );

    getBookmarksMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getBookmarksMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getBookmarksMessage.id, getBookmarksMessage);
  };

  getSubjectsForBookmarks = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    const getSubjectsForBookmarksMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubjectsForBookmarksMessageId =
      getSubjectsForBookmarksMessage.messageId;

    getSubjectsForBookmarksMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `all_subjects`
    );

    getSubjectsForBookmarksMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectsForBookmarksMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getSubjectsForBookmarksMessage.id,
      getSubjectsForBookmarksMessage
    );
  };

  getTopics = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getTopicsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTopicsMessageId = getTopicsMessage.messageId;

    getTopicsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `chapter_topics/${this.props.navigation.getParam("chapterId")}`
    );

    getTopicsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getTopicsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getTopicsMessage.id, getTopicsMessage);
  };
  // Customizable Area End
}
