Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.showNotes = "Show Notes"
exports.getquestionTagEndPoint  = "/bx_block_questionbank/{id}/question_types"
exports.answer ="Answer :"
exports.putmethod = "PUT"
exports.editnoteEndPoint = "/bx_block_assessmenttest/notes/"
exports.notelable = 'Write your note here'
exports.savenote = "Save Note"
exports.Chapter = "Chapter"
exports.UpdateNote = "Update Note"
exports.showNotesEndPoint = "/bx_block_assessmenttest/notes/"
exports.backlabel = 'Go Back'
exports.alertModallabel = "Bookmarked Question no {questionNO} Succesfully"
exports.createBookmark = "Create New Bookmark"
exports.gotoSaveNotes = "Go To Saved Notes"
exports.createNotes = "Create New Notes"
exports.bookmarksModallabel = "Choose What You Need"
exports.bookmarkBTNlable = "Go to Saved Bookmarks"
exports.TotalNotes = "Total Notes";
exports.notoes = "Notes";
exports.noData  = 'No Data Found !';
exports.createBookmarkEndPoint = '/bx_block_content_management/bookmarks';
exports.bookmarkdeleteEndPoint = "/bx_block_content_management/bookmarks/";
exports.qblistendpoint = '/bx_block_questionbank/{id}/qb_lists?question_type={qb_type}'
exports.reportEndPoint ='/bx_block_questionbank/qb_reports'
exports.send = 'Send'
exports.modalText= "What do you want to report ?"
exports.AditionalEx = 'Additional Exercise'
exports.Example = 'Example'
exports.Excercise = 'Excercise';
exports.test = 'Test';
exports.praticesTest = 'Question Bank';
exports.delete = "DELETE";
exports.view= "View";
exports.subject= 'Subject';
exports.Topic= "Chapter";
exports.topic = "Select Chapter";
exports.registrstionText = "To get access for all subjects and more \n content you need to register";
exports.cancel = 'Cancel';
exports.placeHolder = "Enter your message";
exports.Note = "Note";
exports.newNotes = "New Notes"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "QuestionBank";
exports.labelBodyText = "QuestionBank Body";
exports.SaveNotes = "Saved Notes";
exports.btnExampleTitle = "CLICK ME";
exports.continueReading = 'Continue Reading';
exports.savedNotes = 'Saved notes';
exports.Bookmark = 'Bookmarks';
exports.learnNow = 'Learn Now';
exports.topicUnitMeasure = 'Topic Units and Measurments';
exports.selectTopic = 'Select Topics';
exports.additional_excercise = 'additional_excercise';
exports.example = 'example';
exports.excercise = 'excercise';
exports.qblistEndPoint = '/bx_block_questionbank/';
exports.Exam = "Topic";
exports.QuestionNo = "Question No :"
exports.SaveNotesEndPoint = '/bx_block_questionbank/{id}/qb_notes';
exports.createNotesEndPoint = '/bx_block_assessmenttest/notes'
exports.SubjectListEndPoint = '/bx_block_questionbank/question_banks/qb_subjects?pack_final_id={packageID}';
exports.BookmarkEndPoint = "/bx_block_questionbank/";
exports.deleteSaveNotes = "/bx_block_assessmenttest/notes/"
exports.saveNotes  = 'Save Note';
exports.endPointSubjectlist = "/bx_block_questionbank/";
exports.deleteBookmark = ''
exports.Token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzIwLCJleHAiOjE2OTEwNTQ0NzMsInRva2VuX3R5cGUiOiJsb2dpbiJ9.S0KVM-0FqxuqbA2TXQvpJyMfiPlnM9peFsc7TDrKLzfyH1oWIcLLDM1YcMb0SvO3tsUxj3srBY8ie5fSTyQHMw";
exports.TokenSample = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzczLCJleHAiOjE3MTQ1MTE4NjksInRva2VuX3R5cGUiOiJsb2dpbiJ9.ErzSzGukGJF9cwNjJwSy0TVyLvYqlrPZGJnHSFDDREQFxrqfaC-q1pc8q84qTIJd5WggJisRRpslp7PahEYfjw"
exports.guestSbject="bx_block_questionbank/question_banks/qb_subjects"

exports.customeWapper = {
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    backgroundColor: '#f0f0f0',
  },
  th: {
    backgroundColor: '#e6e6e6',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    padding: 8,
  },
  td: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    padding: 8,
  }
};
// Customizable Area End