import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import PracticeTestReviewController from "./PracticeTestReviewController.web";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Button from "../../../components/src/Button.web";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import TimerIcon from "@material-ui/icons/Timer";
import { Link } from "react-router-dom";
import { styles } from "./styles";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

class PracticeTestReview extends PracticeTestReviewController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box className={this.props.classes.practiceTestReview}>
          <p className={this.props.classes.practiceTestTimer}>
            <span>
              <TimerIcon />
            </span>{" "}
            {this.state.timeTaken}
          </p>
          <Box sx={webStyle.scoreContainer}>
            <Box sx={webStyle.progressStyles}>
              <Box sx={{ position: "absolute", top: 78, width: "140px" }}>
                <CircularProgressbar
                  value={this.state.correctPercentage as number}
                  strokeWidth={14}
                  styles={buildStyles({
                    pathColor: `#9BBB56`,
                  })}
                />
              </Box>
              <Box sx={{ position: "absolute", top: 40, width: "220px" }}>
                <CircularProgressbar
                  value={this.state.incorrectPercentage as number}
                  strokeWidth={9}
                  styles={buildStyles({
                    pathColor: `#D84553`,
                  })}
                />
              </Box>
              <Box sx={{ position: "absolute", top: 0 }}>
                <CircularProgressbar
                  value={this.state.unattendedPercentage as number}
                  strokeWidth={7}
                  styles={buildStyles({
                    pathColor: `#FF9D54`,
                  })}
                />
              </Box>
            </Box>
            <Box sx={webStyle.legendStyles}>
              <p style={webStyle.centerStyle}>
                <span>
                  <FiberManualRecordIcon style={{ color: "#9BBB56" }} />
                </span>
                Correct ({this.state.correctPercentage}%)
              </p>
              <p style={webStyle.centerStyle}>
                <span>
                  <FiberManualRecordIcon style={{ color: "#D84553" }} />
                </span>{" "}
                Incorrect ({this.state.incorrectPercentage}%)
              </p>
              <p style={webStyle.centerStyle}>
                <span>
                  <FiberManualRecordIcon style={{ color: "#FF9D54" }} />
                </span>{" "}
                Unattempted ({this.state.unattendedPercentage}%)
              </p>
            </Box>
          </Box>
          <Link
            to={`/dashboard/practice-test/chapters/${this.props.navigation.getParam(
              "subjectId"
            )}/review-test/${this.props.navigation.getParam(
              "chapterId"
            )}/review/${this.state.reviewId}${this.searchResolve}`}
          >
            <Box sx={{ marginTop: "3em" }}>
              <Button
                title="Review Test"
                width={"156"}
                height={"60"}
                color={"white"}
              />
            </Box>
          </Link>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  progressStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "400px",
    position: "relative",
  },
  scoreContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "space-around",
    width: "580px",
    height: "550px",
    background: "#FAF8FD",
    padding: "0em 2em",
  },
  legendStyles: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    gap: "3em",
  },
  centerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default withStyles(styles)(PracticeTestReview);
export { PracticeTestReview };
// Customizable Area End
