import React from "react";

import {
  Box,
  // Customizable Area Start
  createStyles,
  withStyles,
  Typography,
  Theme,
  Modal,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ReactPlayer from "react-player";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { SubmitIcon, warningIcon, emptyStar, starIcon } from "./assets";
// Customizable Area End

import RecordedClassVideoController, {
  Props,
} from "./RecordedClassVideoController.web";

class RecordedClassVideo extends RecordedClassVideoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const classes = this.props.classes;
    const videoAttributes = this.state.currentVideo?.attributes;

    let videoUrl = "";

    if (videoAttributes) {
      if (
        this.state.videoType === "recorded" ||
        this.state.videoType === "live"
      ) {
        videoUrl = videoAttributes.recording_url;
      }

      if (this.state.videoType === "swayam") {
        videoUrl = videoAttributes.url;
      }
    }

    return (
      // Customizable Area Start
      <Box>
        <Modal
          open={this.state.isModalRecordingExist}
          onClose={this.handleRecordingModal}
          BackdropProps={{ onClick: undefined }}
        >
          <Box className={this.props.classes.modalStyle}>
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant={"h6"} style={{ fontWeight: 700 }}>
                No Recordings found
              </Typography>
              <Box
                marginTop={"2em"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gridGap={"1em"}
              >
                <Button
                  type="submit"
                  data-test-id="send-init"
                  className={this.props.classes.buttonStyle}
                  onClick={this.goBack}
                >
                  Go Back
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpenReport}
          onClose={this.handleModalReport}
        >
          <Box className={this.props.classes.modalStyle}>
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant={"h6"} style={{ fontWeight: 700 }}>
                What do you want to report
              </Typography>
              <form
                onSubmit={(event) => this.sendReport(event)}
                data-test-id="form-submit"
              >
                <textarea
                  data-test-id="report-input"
                  value={this.state.userInput.report}
                  onChange={(event) =>
                    this.handleInputChange(event.target.value)
                  }
                  placeholder="Your message"
                  className={this.props.classes.messageBoxStyle}
                />
                <Box
                  marginTop={"2em"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gridGap={"1em"}
                >
                  <Button
                    data-test-id="close-report"
                    onClick={this.handleModalReport}
                    className={this.props.classes.buttonStyle}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    data-test-id="send-init"
                    className={this.props.classes.buttonStyle}
                  >
                    Send
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpenReportSuccess}
          onClose={this.handleModalReportSuccess}
        >
          <Box className={this.props.classes.modalStyle}>
            <img src={SubmitIcon} alt="success" />
            <Typography variant={"h6"} style={{ fontWeight: 700 }}>
              Report Successfully Submitted
            </Typography>
            <Button
              onClick={this.handleModalReportSuccess}
              className={this.props.classes.buttonStyle}
            >
              Go Back
            </Button>
          </Box>
        </Modal>
        <Modal open={this.state.isModalOpenRate} onClose={this.handleModalRate}>
          <Box className={this.props.classes.modalStyle}>
            <Typography
              variant="h6"
              style={{ padding: "0 10%", textAlign: "center" }}
            >
              How likely would you be to recommend myrank to a friend or
              colleague?
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gridGap={"1em"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                gridGap={"1em"}
                alignItems={"center"}
              >
                {Array.from(Array(10).keys()).map((_, index) => {
                  return (
                    <>
                      {this.state.starsIndex >= index ? (
                        <img
                          width={"40px"}
                          src={starIcon}
                          alt="filled"
                          onClick={() => this.handleRatedStars(index)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <img
                          width={"40px"}
                          src={emptyStar}
                          alt="empty"
                          onClick={() => this.handleRatedStars(index)}
                          style={{ cursor: "pointer" }}
                          data-test-id="rate-btn-star"
                        />
                      )}
                    </>
                  );
                })}
              </Box>
            </Box>
            <Button
              onClick={() => {
                this.handleModalRateMessage();
                this.handleModalRate();
              }}
              className={this.props.classes.buttonStyle}
              data-test-id="send-message-btn"
            >
              Send Message
            </Button>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpenRateMessage}
          onClose={this.handleModalRateMessage}
        >
          <Box className={this.props.classes.modalStyle}>
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant={"h6"} style={{ fontWeight: 700 }}>
                How can we do better
              </Typography>
              <form
                onSubmit={(event) => this.sendRate(event)}
                data-test-id="form-submit"
              >
                <textarea
                  data-test-id="input-rate"
                  value={this.state.userInput.rate}
                  onChange={(event) =>
                    this.handleInputChangeRate(event.target.value)
                  }
                  placeholder="Your message"
                  className={this.props.classes.messageBoxStyle}
                />
                <Box
                  marginTop={"2em"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gridGap={"1em"}
                >
                  <Button
                    data-test-id="close-report"
                    onClick={this.handleModalRateMessage}
                    className={this.props.classes.buttonStyle}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    data-test-id="send-init"
                    className={this.props.classes.buttonStyle}
                  >
                    Send
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpenRateMessageSuccess}
          onClose={this.handleModalRateMessageSuccess}
        >
          <Box className={this.props.classes.modalStyle}>
            <img src={SubmitIcon} alt="success" />
            <Typography variant={"h6"} style={{ fontWeight: 700 }}>
              Feedback Submitted Succesfully!
            </Typography>
            <Button
              onClick={() => {
                this.handleModalRateMessageSuccess();
              }}
              className={this.props.classes.buttonStyle}
            >
              Go Back
            </Button>
          </Box>
        </Modal>
        <Typography
          variant="h5"
          style={{ fontWeight: 700, textAlign: "center", marginTop: "2em" }}
        >
          {this.state.videoType === "swayam"
            ? "Swayam Prabha"
            : "Recorded Classes"}
        </Typography>
        <Box className={classes.bannerStyle}>
          <h2>
            {this.state.currentVideo?.attributes.subject_name ||
              "Unknown Subject"}
          </h2>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
        >
          <Box marginBottom={"2em"}>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              Chapter - {this.state.currentVideo?.attributes.chapter_name}
            </Typography>
          </Box>
          <ReactPlayer
            url={videoUrl}
            onPlay={this.tick}
            onPause={this.handlePauseOrEnd}
            onEnded={this.handleEnded}
            controls
          />
          <Box
            marginBottom={"2em"}
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"650px"}
            marginTop={"1.5em"}
          >
            <Typography variant="h5" style={{ textAlign: "center" }}>
              Video -{" "}
              {this.state.currentVideo?.attributes.video_name ||
                "Recorded Video"}
            </Typography>
            <Box display={"flex"} gridGap={"1em"}>
              <button
                data-test-id="report-btn"
                onClick={this.handleModalReport}
                className={this.props.classes.buttonReport}
              >
                <img src={warningIcon} alt="report" />
              </button>
              <button
                data-test-id="rate-btn"
                className={this.props.classes.buttonRate}
                onClick={this.handleModalRate}
              >
                <StarBorderIcon htmlColor={"#d77780"} />
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleRecordedClassVideo = (theme: Theme) =>
  createStyles({
    modalStyle: {
      background: "white",
      display: "flex",
      flexDirection: "column",
      transform: "translate(-50%, -50%)",
      width: "35%",
      minWidth: "max-content",
      minHeight: "max-content",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute" as "absolute",
      left: "50%",
      bgcolor: "background.paper",
      border: "2px solid #FFD0D8",
      borderRadius: "1.5em",
      padding: "2em",
      top: "50%",
      gap: "1.5em",
    },
    messageBoxStyle: {
      border: "1px solid #FD6455",
      resize: "none",
      borderRadius: "0.7em",
      width: "600px",
      height: "200px",
      padding: "1em",
    },
    inputStyle: {
      border: "1px solid #e9c5cb",
      borderRadius: "0.5em",
      height: "270px",
      width: "85%",
    },
    bannerStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "71px",
      background: "rgba(247, 214, 255, 0.19)",
      color: "rgb(82, 0, 130)",
      margin: "1em 0",
    },
    buttonReport: {
      border: "none",
      borderRadius: "50%",
      background: "rgb(254,228,227)",
      padding: "0.6em",
      cursor: "pointer",
    },
    buttonRate: {
      border: "none",
      borderRadius: "50%",
      background: "rgb(254,228,227)",
      padding: "0.6em",
      color: "#d777780",
      cursor: "pointer",
    },
    buttonStyle: {
      border: "1px solid #BE888E",
      borderRadius: "0.6em",
      textTransform: "none" as const,
      "&:hover": {
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        color: "white",
      },
      minWidth: "130px",
      width: "max-content",
      height: "50px",
      color: "#4F1f6d",
    },
    messageStyle: {
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      borderRadius: "0.7em",
      width: "200px",
      height: "50px",
      color: "white",
      marginTop: "2em",
    },
  });

export default withStyles(webStyleRecordedClassVideo)(RecordedClassVideo);
export { RecordedClassVideo };
// Customizable Area End
