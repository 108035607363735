import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { webStyleSavedNotesAddEdit } from "./SynopsisSavedNotesAddEdit.web";
import storage from "../../../framework/src/StorageProvider.web";
import { Root } from "./SynopsisSavedNotesController.web";
import { ChapterMeta } from "./SynopsisTopicsController.web";
// Customizable Area End

export interface Props extends WithStyles<typeof webStyleSavedNotesAddEdit> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  content: string;
  noteId: string;
  isNoteCreateSavedSuccessfully: boolean;
  cancelModalConfirm: boolean;
  isNoteCancellationModalOpen: boolean;
  noteType: string;
  note: Root | null;
  topicName: string;
  subjectName: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SynopsisSavedNotesAddEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  token = "";
  editSavedNotesMessageId = "";
  getSavedNotesMessageId = "";
  createSavedNotesMessageId = "";
  getSubjectMessageId = "";
  getTopicMessageId = "";
  subjectIdParam = this.props.navigation.getParam("subjectId");
  chapterIdParam = this.props.navigation.getParam("chapterId");
  breadcrumb = [
    {
      link: `/dashboard/synopsis`,
      label: "Synopsis",
    },
    {
      label: "Chapters",
      link: `/dashboard/synopsis/chapters/${this.subjectIdParam}`,
    },
    {
      label: "Topics",
      link: `/dashboard/synopsis/chapters/${this.subjectIdParam}/topics/${this.chapterIdParam}`,
    },
    {
      link: `/dashboard/synopsis/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/topics/${this.props.navigation.getParam(
        "chapterId"
      )}/topic-read/${this.props.navigation.getParam("topicId")}/off`,
      label: "Topic Read",
    },
    {
      link: `/dashboard/synopsis/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/topics/${this.props.navigation.getParam(
        "chapterId"
      )}/saved-notes/${this.props.navigation.getParam("topicId")}`,
      label: "Saved Notes",
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Add/Edit Notes",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      content: "",
      noteId: "",
      isNoteCreateSavedSuccessfully: false,
      cancelModalConfirm: false,
      isNoteCancellationModalOpen: false,
      noteType: "",
      note: null,
      topicName: "",
      subjectName: "",
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseData = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.getSavedNotesMessageId:
        this.handleSavedNotesResponse(responseData);
        break;
      case this.createSavedNotesMessageId:
      case this.editSavedNotesMessageId:
        this.handleNoteCreationResponse(responseData);
        break;
      case this.getTopicMessageId:
      case this.getSubjectMessageId:
        this.handleTopicOrSubjectResponse(apiRequestCallId, responseData);
        break;
      default:
        // Do nothing for other cases
        break;
    }
    // Customizable Area End
  }

  // web events
  handleSavedNotesResponse(responseData: Root[]) {
    if (responseData?.length > 0) {
      const data: Root[] = responseData.filter(
        (dataResponse: Root) => String(dataResponse.id) === this.state.noteId
      );
      if (data.length > 0) {
        this.setState({
          note: data[0],
          content: data[0].notes_content,
        });
      }
    }
  }

  handleNoteCreationResponse(responseData: { id: string }) {
    if (responseData.id) {
      this.setState({
        isNoteCreateSavedSuccessfully: true,
      });
    }
  }

  handleTopicOrSubjectResponse(
    apiRequestCallId: string,
    responseData: {
      data: { id: string; attributes: { name: string } }[];
      meta: { chapter: { name: string } };
    }
  ) {
    const idParam =
      apiRequestCallId === this.getTopicMessageId ? "topicId" : "subjectId";
    if (responseData?.data) {
      const id = this.props.navigation.getParam(idParam);
      const item = responseData.data.find(
        (data: { id: string; attributes: { name: string } }) => data.id === id
      );
      const name = item?.attributes.name;
      if (apiRequestCallId === this.getTopicMessageId) {
        const breadcrumb = this.breadcrumb;
        breadcrumb[3].label = (name as string) + " Read";
        breadcrumb[4].label = (name as string) + " Saved Notes";
        const propPassingSSNAEC = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingSSNAEC.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingSSNAEC);
        this.setState({ topicName: name as string, loading: false });
      } else {
        const breadcrumb = this.breadcrumb;
        breadcrumb[1].label = name as string;
        const propPassingSSNAEC = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingSSNAEC.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingSSNAEC);
        this.setState({ subjectName: name as string, loading: false });
      }
    }

    if (responseData?.meta) {
      const breadcrumb = this.breadcrumb;
      breadcrumb[2].label = responseData.meta.chapter.name;

      const propPassingSCC = new Message(
        getName(MessageEnum.NavigationBreadcrumbMessage)
      );
      propPassingSCC.addData(
        getName(MessageEnum.BreadcrumbDataMessage),
        breadcrumb
      );
      this.send(propPassingSCC);
      this.setState({
        loading: false,
      });
    }
  }

  componentDidMount = async () => {
    const propPassingSSNAEC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSSNAEC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingSSNAEC);

    const noteType = this.props.navigation.getParam("noteType");
    const noteId = this.props.navigation.getParam("noteId");
    this.setInitialState(noteType, noteId);
    this.getTopic();
    this.getSubject();
  };

  setInitialState = (noteType: string, noteId: string) => {
    this.setState(
      {
        noteId,
        noteType,
      },
      () => this.getSavedNotes()
    );
  };

  handleNoteCreateModalClose = () => {
    this.setState({
      isNoteCreateSavedSuccessfully: false,
    });
  };
  handleNoteCancelModalClose = () => {
    this.setState({
      isNoteCancellationModalOpen: false,
    });
  };
  handleNoteCancelConfirmModalOpen = () => {
    this.setState({
      cancelModalConfirm: true,
    });
  };
  handleNoteCancelConfirmModalClose = () => {
    this.setState({
      cancelModalConfirm: false,
    });

    this.props.navigation.history.push(
      `/dashboard/synopsis/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/topics/${this.props.navigation.getParam(
        "chapterId"
      )}/saved-notes/${this.props.navigation.getParam("topicId")}`
    );
  };
  handleInputChange = (value: string) => {
    this.setState({
      content: value,
    });
  };
  confirmCancel = () => {
    this.handleNoteCancelConfirmModalOpen();
    this.handleNoteCancelModalClose();
  };
  handleNoteCancelModalOpen = () => {
    this.setState({
      isNoteCancellationModalOpen: true,
    });
  };

  // Customizable Area Start
  getSavedNotes = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/notes?topic_id=${this.props.navigation.getParam(
        "topicId"
      )}`
    );

    this.getSavedNotesMessageId = getSavedNotesMessage.messageId;

    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getSavedNotesMessage.id, getSavedNotesMessage);
  };

  NoteApi = async () => {
    if (this.state.noteType === "Edit") {
      const headers = {
        token: await storage.get("authToken"),
      };

      const editSavedNotesMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      editSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_assessmenttest/notes/${this.state.noteId}?content=${this.state.content}`
      );
      editSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PATCH"
      );
      editSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      this.editSavedNotesMessageId = editSavedNotesMessage.messageId;

      runEngine.sendMessage(editSavedNotesMessage.id, editSavedNotesMessage);
    } else {
      const headers = {
        "Content-Type": "application/json",
        token: await storage.get("authToken"),
      };

      const body = {
        content: this.state.content,
        noteable_type: "Topic",
        noteable_id: this.props.navigation.getParam("topicId"),
      };

      const createSavedNotesMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createSavedNotesMessageId = createSavedNotesMessage.messageId;

      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_assessmenttest/notes`
      );
      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      runEngine.sendMessage(
        createSavedNotesMessage.id,
        createSavedNotesMessage
      );
    }
  };

  getSubject = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    const getSubjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getSubjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `all_subjects`
    );

    this.getSubjectMessageId = getSubjectMessage.messageId;

    getSubjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    getSubjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getSubjectMessage.id, getSubjectMessage);
  };

  getTopic = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getTopicMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getTopicMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `chapter_topics/${this.props.navigation.getParam("chapterId")}`
    );

    this.getTopicMessageId = getTopicMessage.messageId;

    getTopicMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    getTopicMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getTopicMessage.id, getTopicMessage);
  };
  // Customizable Area End
}
