import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const tokens = await storage.get("authToken");
    if (tokens) {
      this.handleToken(tokens);
    }
  };
  // Customizable Area Start
  navigateToAYQ = () => {
    this.props.navigation.history.push("/dashboard-ask-your-query/home");
  };

  handleToken = (token: string) => {
    this.setState({
      token,
    });
  };

  handleNotificationClick = async () => {
    interface IUserData {
      attributes: {
        user_type: string;
      };
    }

    const userType = JSON.parse(await storage.get("userData")) as IUserData;
    const token = await storage.get("authToken");
    const guesttoken = await storage.get("guestToken");

    if (token) {
      if (userType.attributes.user_type === "cm") {
        return this.props.navigation.history.push("/dashboard-cm");
      }

      return this.props.navigation.history.push("/dashboard");
    }

    if (guesttoken) {
      return this.props.navigation.history.push("/guest/notifications");
    }
  };
  // Customizable Area End
}
