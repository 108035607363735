import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { webStyleSynopsisSavedNotes } from "./SynopsisSavedNotes.web";
import storage from "../../../framework/src/StorageProvider.web";
import { ChapterMeta, TopicData } from "./SynopsisTopicsController.web";
export type Root = {
  id: number;
  notes_content: string;
  noteable_id: number;
  noteable_type: string;
  question_id: string | null;
  question_no: string | null | number;
  created_at: string;
  updated_at: string;
  serial_number: number;
};
import { format } from "date-fns";
import { SubjectData } from "./SynopsisSubjectsController.web";
// Customizable Area End

export interface Props extends WithStyles<typeof webStyleSynopsisSavedNotes> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  savedNotes: Root[];
  modalDelete: boolean;
  modalDeleteConfirm: boolean;
  saveNoteId: string;
  topic: TopicData | null;
  subject: SubjectData | null;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SynopsisSavedNotesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSavedNotesMessageId = "";
  breadcrumb = [
    {
      label: "Synopsis",
      link: `/dashboard/synopsis`,
    },
    {
      link: `/dashboard/synopsis/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}`,
      label: "Chapters",
    },
    {
      label: "Topics",
      link: `/dashboard/synopsis/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/topics/${this.props.navigation.getParam("chapterId")}`,
    },
    {
      link: `/dashboard/synopsis/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/topics/${this.props.navigation.getParam(
        "chapterId"
      )}/topic-read/${this.props.navigation.getParam("topicId")}/off`,
      label: "Topic Read",
    },
    {
      label: "Saved Notes",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  deleteSavedNotesMessageId = "";
  getTopicsSavedNotesMessageId = "";
  getSubjectSavedNotesMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      savedNotes: [],
      modalDelete: false,
      modalDeleteConfirm: false,
      saveNoteId: "",
      topic: null,
      subject: null,
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.getSavedNotesMessageId:
        this.handleGetSavedNotesResponse(response);
        break;
      case this.deleteSavedNotesMessageId:
        this.handleDeleteSavedNotesResponse(response);
        break;
      case this.getSubjectSavedNotesMessageId:
        this.handleGetSubjectSavedNotesResponse(response);
        break;
      case this.getTopicsSavedNotesMessageId:
        this.handleGetTopicsSavedNotesResponse(response);
        this.handleMeta(response.meta);
        break;
      default:
        // Do nothing for other cases
        break;
    }
    // Customizable Area End
  }

  // web events
  handleGetSavedNotesResponse(response: Root[]) {
    if (response?.length > 0) {
      this.handleSavedNotes(response);
    }
  }

  handleMeta = (meta: ChapterMeta) => {
    const breadcrumb = this.breadcrumb;
    breadcrumb[2].label = meta.chapter.name;

    const propPassingSCC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSCC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingSCC);
    this.setState({
      loading: false,
    });
  };

  handleDeleteSavedNotesResponse(response: { message: string }) {
    if (response.message) {
      if (this.state.savedNotes.length === 1) {
        this.setState({
          savedNotes: [],
        });
      } else {
        this.getSavedNotes();
      }
      this.handleDeleteBookmark();
    }
  }

  handleGetSubjectSavedNotesResponse(response: { data: SubjectData[] }) {
    if (response.data) {
      const data: SubjectData[] = response.data;
      const subjectId = this.props.navigation.getParam("subjectId");
      const subject = data.find((subjectData) => subjectData.id === subjectId);

      const breadcrumb = this.breadcrumb;
      breadcrumb[1].label = subject?.attributes.name as string;
      const propPassingSSNC = new Message(
        getName(MessageEnum.NavigationBreadcrumbMessage)
      );
      propPassingSSNC.addData(
        getName(MessageEnum.BreadcrumbDataMessage),
        breadcrumb
      );
      this.send(propPassingSSNC);

      if (subject) {
        this.setState({ subject, loading: false });
      }
    }
  }

  handleGetTopicsSavedNotesResponse(response: { data: TopicData[] }) {
    if (response.data) {
      const data: TopicData[] = response.data;
      const topicId = this.props.navigation.getParam("topicId");
      const topic = data.find((topicData) => topicData.id === topicId);

      const breadcrumb = this.breadcrumb;
      breadcrumb[3].label = (topic?.attributes.name as string) + " Read";
      breadcrumb[4].label = (topic?.attributes.name as string) + " Saved Notes";
      const propPassingSSNC = new Message(
        getName(MessageEnum.NavigationBreadcrumbMessage)
      );
      propPassingSSNC.addData(
        getName(MessageEnum.BreadcrumbDataMessage),
        breadcrumb
      );
      this.send(propPassingSSNC);

      if (topic) {
        this.setState({ topic, loading: false });
      }
    }
  }

  componentDidMount = async () => {
    const propPassingSSNC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSSNC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingSSNC);

    this.getSavedNotes();
    this.getSubjectSavedNotes();
    this.getTopicsSavedNotes();
  };

  getTimestamp = (time: string) => {
    const parsedDate = new Date(time);

    const formattedDate = format(parsedDate, "dd/MM/yy");
    const formattedTime = format(parsedDate, "h:mm a");
    return `${formattedDate} Time : ${formattedTime}`;
  };

  handleDeleteBookmark = () => {
    this.setState({
      modalDelete: !this.state.modalDelete,
      saveNoteId: "",
    });
  };

  handleBookmarkModal = (saveNoteId?: string) => {
    this.setState({
      modalDeleteConfirm: !this.state.modalDeleteConfirm,
      saveNoteId: saveNoteId !== undefined ? saveNoteId : this.state.saveNoteId,
    });
  };

  navigateToTopics = () => {
    this.props.navigation.history.push(
      `/dashboard/synopsis/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/topics/${this.props.navigation.getParam("chapterId")}`
    );
  };

  navigateToNewNote = () => {
    this.props.navigation.navigate("SynopsisAddEditNotes", {
      subjectId: this.props.navigation.getParam("subjectId"),
      topicId: this.props.navigation.getParam("topicId"),
      chapterId: this.props.navigation.getParam("chapterId"),
      noteType: "New",
      noteId: "none",
    });
  };

  // Customizable Area Start
  getSavedNotes = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSavedNotesMessageId = getSavedNotesMessage.messageId;

    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/notes?topic_id=${this.props.navigation.getParam(
        "topicId"
      )}`
    );
    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSavedNotesMessage.id, getSavedNotesMessage);
  };

  navigateToAddEdit = (noteId: string) => {
    const subjectId = this.props.navigation.getParam("subjectId");
    const chapterId = this.props.navigation.getParam("chapterId");
    const topicId = this.props.navigation.getParam("topicId");
    this.props.navigation.navigate("SynopsisAddEditNotes", {
      subjectId,
      chapterId,
      topicId,
      noteId,
      noteType: "Edit",
    });
  };

  deleteSavedNotes = async () => {
    this.setState({
      modalDeleteConfirm: false,
    });
    const headers = {
      token: await storage.get("authToken"),
    };

    const deleteSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSavedNotesMessageId = deleteSavedNotesMessage.messageId;

    deleteSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/notes/${this.state.saveNoteId}`
    );

    deleteSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deleteSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deleteSavedNotesMessage.id, deleteSavedNotesMessage);
  };

  getTopicsSavedNotes = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getTopicsSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTopicsSavedNotesMessageId = getTopicsSavedNotesMessage.messageId;

    getTopicsSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `chapter_topics/${this.props.navigation.getParam("chapterId")}`
    );

    getTopicsSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getTopicsSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getTopicsSavedNotesMessage.id,
      getTopicsSavedNotesMessage
    );
  };

  getSubjectSavedNotes = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getSubjectSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubjectSavedNotesMessageId = getSubjectSavedNotesMessage.messageId;

    getSubjectSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `all_subjects`
    );

    getSubjectSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getSubjectSavedNotesMessage.id,
      getSubjectSavedNotesMessage
    );
  };

  handleSavedNotes = (response: Root[]) => {
    this.setState({
      savedNotes: response,
      loading: false,
    });
  };
  // Customizable Area End
}
