import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userInput: {
    userIdInput: string;
    passwordInput: string;
  };
  showPassword: boolean;
  userNotFound: boolean;
  passwordIncorrect: boolean;
  rememberMe: boolean;
  isModalOpenWrongCredential: boolean;
  isModalOpenWrongPassword: boolean;
  isModalOpen: boolean;
  userInputLoaded: boolean;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AYQLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postUserIdLoginDocumentId = "";
  loginSchema = Yup.object({
    userIdInput: Yup.string().email().required("Please input your MyRank Id"),
    passwordInput: Yup.string().required("Please input your MyRank Password"),
  });
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userInput: {
        passwordInput: "",
        userIdInput: "",
      },
      userInputLoaded: false,
      loading: false,
      showPassword: false,
      isModalOpen: false,
      rememberMe: false,
      passwordIncorrect: false,
      userNotFound: false,
      isModalOpenWrongPassword: false,
      isModalOpenWrongCredential: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.postUserIdLoginDocumentId && responseJson) {
      this.handleUserIdLoginResponse(responseJson);
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const cookieExisting = this.getCookie("userCookieAYQ");
    if (cookieExisting) {
      this.rememberMeStateChange(
        JSON.parse(cookieExisting) as {
          userIdInput: string;
          passwordInput: string;
        }
      );
    } else {
      this.handleUserInputLoaded();
    }
  };

  // Customizable Area Start
  handleUserInputLoaded = () => {
    this.setState({
      userInputLoaded: true,
    });
  };

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  rememberMeStateChange = (cookieData: {
    userIdInput: string;
    passwordInput: string;
  }) => {
    this.setState(
      {
        userInput: {
          passwordInput: cookieData.passwordInput,
          userIdInput: cookieData.userIdInput,
        },
        rememberMe: true,
      },
      () => {
        this.handleUserInputLoaded();
      }
    );
  };

  getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let decodedCookieSplit = decodedCookie.split(";");

    for (let cookie of decodedCookieSplit) {
      let trimmedCookie = cookie.trim();
      if (trimmedCookie.indexOf(name) === 0) {
        return trimmedCookie.substring(name.length);
      }
    }

    return "";
  }

  handleRememberMe = (
    event: React.ChangeEvent<HTMLInputElement>,
    userInput: {
      userIdInput: string;
      passwordInput: string;
    }
  ) => {
    const { checked } = event.target;

    if (!checked) {
      document.cookie =
        "userCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    } else {
      document.cookie = `userCookieAYQ=${encodeURIComponent(
        JSON.stringify(userInput)
      )}; path=/;`;
    }

    this.setState({
      rememberMe: checked,
    });
  };

  handleToForgotPassword = () => {
    this.props.navigation.history.push("/forgot-password");
  };

  handleGoToSignUp = () => {
    this.props.navigation.history.push("/ask-your-query/register");
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleCloseModalWrongPassword = () => {
    this.setState({
      isModalOpenWrongPassword: false,
    });
  };

  handleGoToDashboard = () => {
    this.props.navigation.history.push("/dashboard-ask-your-query/home");
  };

  handleUserIdLoginSubmit = (values: {
    userIdInput: string;
    passwordInput: string;
  }) => {
    const requestBody = {
      data: {
        type: "ayq_account",
        attributes: {
          email: values.userIdInput,
          password: values.passwordInput,
        },
      },
    };

    const header = {
      "Content-Type": "application/json",
    };

    const loginMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postUserIdLoginDocumentId = loginMessage.messageId;

    loginMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/logins"
    );

    loginMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    loginMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    loginMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(loginMessage.id, loginMessage);
  };

  handleUserIdLoginResponse(responseJson: {
    data: {
      attributes: {
        name: string;
        myrank_id: string;
        email: string;
        package: { actual_price: number }[];
      };
      id: string;
    };
    errors: { failed_login: string }[];
    meta: { token: string };
  }) {
    if (responseJson.errors) {
      const { failed_login } = responseJson.errors[0];
      this.loginError(failed_login);
    }

    if (responseJson.data) {
      storage.set("authToken", responseJson.meta.token);
      storage.set("userId", responseJson.data.id);
      storage.set("userData", JSON.stringify(responseJson.data));
      this.setState({
        isModalOpen: true,
      });
    }
  }

  loginError = (error: string) => {
    if (error === "Account not found, or not activated") {
      this.setState({
        userNotFound: true,
      });

      this.handleCloseModalWrongCredential();
    } else {
      this.setState({
        passwordIncorrect: true,
        isModalOpenWrongPassword: true,
        userNotFound: false,
      });
    }
  };

  handleCloseModalWrongCredential = () => {
    this.setState({
      isModalOpenWrongCredential: !this.state.isModalOpenWrongCredential,
    });
  };

  handleGoToCredentials = () => {
    this.props.navigation.history.push("/get-credentials");
  };
  // Customizable Area End
}
