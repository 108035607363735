import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
interface Root {
  id: string;
  attributes: Attributes;
  type: string;
}

interface Attributes {
  question_id: number;
  highlighted_text: any;
  id: number;
  question_no: number;
  bookmarkable_id: number;
  account_id: number;
  updated_at: string;
  bookmarkable: Bookmarkable;
  bookmarkable_type: string;
  created_at: string;
  question: any;
}

interface Bookmarkable {
  time_taken: string;
  id: number;
  unattended: number;
  incorrect_answer: number;
  question_ids: string[];
  chapter_id: number;
  account_id: number;
  correct_answer: number;
  score_id: number;
  created_at: string;
  updated_at: string;
  marks: number;
  total_questions: number;
  score_db: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bookmarks: Root[];
  submitModalOpen2: boolean;
  submitModalOpen: boolean;
  loading: boolean;
  breadcrumb: Array<{ label: string; link: string }>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GrandTestBookmark extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getBookmarksNotesMessageId = "";
  indexToDelete = Infinity;
  bookmarkIdToDelete = "";
  token = "";
  breadcrumb = [
    {
      label: "Grand Test",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}`,
    },
    {
      label: "Chapters",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}`,
    },
    {
      label: "Reviews",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}/review-test/${this.props.navigation.getParam("subjectId")}`,
    },
    {
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}/review-test/${this.props.navigation.getParam("subjectId")}`,
      label: "Reviews",
    },
    {
      label: "Review Test",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}/review-test/${this.props.navigation.getParam(
        "subjectId"
      )}/review/${this.props.navigation.getParam("testId")}`,
    },
    {
      label: "Bookmarks",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  reviewId = "";
  getPackageReviewTestMessageId = "";
  getTestNameMessageBookmarkId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      bookmarks: [],
      submitModalOpen2: false,
      submitModalOpen: false,
      breadcrumb: this.breadcrumb,
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getBookmarksNotesMessageId) {
      if (response.data) {
        this.successfulFetchBookmark(response.data);
      }
    }

    if (apiRequestCallId === this.getTestNameMessageBookmarkId) {
      if (response.data) {
        this.handleSuccessfulFetchTest(response.data);
      }
    }

    if (apiRequestCallId === this.getPackageReviewTestMessageId) {
      if (response.data) {
        this.handleSuccessfulPackage(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  handleSubmitModalClose = () => {
    this.setState({
      submitModalOpen: false,
    });
  };

  successfulFetchBookmark = (response: Root[]) => {
    this.setState({
      bookmarks: response,
    });
  };
  componentDidMount = async () => {
    const token = await storage.get("authToken");
    if (token) {
      this.token = token;
    }
    const reviewId = this.props.navigation.getParam("testId");
    if (reviewId) {
      this.reviewId = reviewId;
      this.getBookmarksNotes(reviewId);
    }

    const propPassingGTBC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingGTBC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingGTBC);

    this.getPackageReviewTestMessage();
  };

  initializeBookmarkDeletion = (bookmarkId: string, index: number) => {
    this.indexToDelete = index;
    this.bookmarkIdToDelete = bookmarkId;
    this.setState({
      submitModalOpen: true,
    });
  };
  handleSubmitModalClose2 = () => {
    this.setState({
      submitModalOpen2: false,
    });
  };

  // Customizable Area Start
  getBookmarksNotes = (reviewId: string) => {
    const headers = {
      token: this.token,
    };

    const getBookmarksNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBookmarksNotesMessageId = getBookmarksNotesMessage.messageId;

    getBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/${reviewId}/gt_bookmarks`
    );

    getBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getBookmarksNotesMessage.id,
      getBookmarksNotesMessage
    );
  };

  deleteBookmarkNotes = (bookmarkId: string, index: number) => {
    const headers = {
      token: this.token,
    };

    let bookmarks = this.state.bookmarks;
    bookmarks = bookmarks.filter((_, indexFilter) => indexFilter !== index);

    this.setState({
      submitModalOpen: false,
      bookmarks,
      submitModalOpen2: true,
    });

    const deleteBookmarksNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    deleteBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/bookmarks/${bookmarkId}`
    );
    deleteBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deleteBookmarksNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(
      deleteBookmarksNotesMessage.id,
      deleteBookmarksNotesMessage
    );
  };

  handleSuccessfulFetchTest = (
    response: { id: string; attributes: { test_name: string } }[]
  ) => {
    const packageId = this.props.navigation.getParam("testId");

    response.forEach((data) => {
      if (data.id === packageId) {
        const breadcrumb = this.state.breadcrumb;
        breadcrumb[3] = { ...breadcrumb[3], label: data.attributes.test_name };

        const propPassingBookmark = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingBookmark.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingBookmark);

        this.setState({
          breadcrumb,
          loading: false,
        });
      }
    });
  };

  handleSuccessfulPackage = (
    response: { gt_code: number; package_name: string }[]
  ) => {
    const packageId = this.props.navigation.getParam("packageId");
    response.forEach((data) => {
      if (data.gt_code === packageId) {
        const breadcrumb = this.state.breadcrumb;
        breadcrumb[1] = { ...breadcrumb[1], label: data.package_name };

        const propPassingBookmark = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingBookmark.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingBookmark);

        this.setState(
          {
            breadcrumb,
            loading: false,
          },
          () => {
            this.getTestNameMessageBookmark();
          }
        );
      }
    });
  };

  getPackageReviewTestMessage = async () => {
    const getPackageMessageBookmark = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getPackageReviewTestMessageId =
      getPackageMessageBookmark.messageId;

    getPackageMessageBookmark.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getPackageMessageBookmark.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/get_packages`
    );

    getPackageMessageBookmark.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getPackageMessageBookmark.id,
      getPackageMessageBookmark
    );
  };

  getTestNameMessageBookmark = async () => {
    const getTestNameMessageBookmark = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getTestNameMessageBookmarkId =
      getTestNameMessageBookmark.messageId;

    getTestNameMessageBookmark.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getTestNameMessageBookmark.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/${this.props.navigation.getParam(
        "subjectId"
      )}/review_histories`
    );

    getTestNameMessageBookmark.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getTestNameMessageBookmark.id,
      getTestNameMessageBookmark
    );
  };
  // Customizable Area End
}
