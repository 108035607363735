import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { styles } from "./styles";
import { WithStyles } from "@material-ui/core";
import { WeeklyTest } from "./ReviewTestWeeklyMainController.web";
import storage from "../../../framework/src/StorageProvider.web";

export interface Root {
  data: Daum[];
  meta: Meta;
}

export interface Daum {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  question_no: number;
  question_1: string;
  question_2: string;
  passage: string;
  question_type: string;
  correct_answer: string;
  explanation: string;
  p_mark: string;
  n_mark: string;
  option_count: number;
  options: Option[];
  is_correct: boolean;
  is_unattended: any;
  user_answer: null | string;
  percentage_people_correct: number;
}

export interface Option {
  id: string;
  option: string;
}

export interface Meta {
  correct_answer: number;
  incorrect_answer: number;
  unattended: number;
  marks: number;
  time_taken: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof styles> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  reviewDataWeekly: Daum[];
  metaDataWeekly: Meta | null;
  currentReviewDataWeekly: number;
  openSaveNotesModalWeekly: boolean;
  openBookmarkModalWeekly: boolean;
  openBookmarkSuccessModalWeekly: boolean;
  loading: boolean;
  unattendedPercentage: number | null;
  correctPercentage: number | null;
  incorrectPercentage: number | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReviewTestWeeklyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  bookmarkHistoryMessageId = "";
  getReviewHistoriesWeeklyMessageId = "";
  reviewId = "";
  breadcrumb = [
    {
      label: "Weekly Test",
      link: "/dashboard/weekly-test/instructions",
    },
    {
      link: "/dashboard/weekly-test/instructions",
      label: "Instructions",
    },
    {
      label: "Tests",
      link: "/dashboard/weekly-test/tests",
    },
    {
      label: "Subject",
      link: "/dashboard/weekly-test/tests",
    },
    {
      link: `/dashboard/weekly-test/tests/review-test/${this.props.navigation.getParam(
        "testId"
      )}`,
      label: "Reviews",
    },
    {
      link: `/dashboard/weekly-test/tests/review-test/${this.props.navigation.getParam(
        "testId"
      )}`,
      label: "Reviews",
    },
    {
      label: "Review Test",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  getReviewHistoryMessageId = "";
  reviewChapter = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      reviewDataWeekly: [],
      metaDataWeekly: null,
      currentReviewDataWeekly: 0,
      openSaveNotesModalWeekly: false,
      openBookmarkModalWeekly: false,
      openBookmarkSuccessModalWeekly: false,
      loading: false,
      unattendedPercentage: null,
      correctPercentage: null,
      incorrectPercentage: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.bookmarkHistoryMessageId) {
      if (response.data) {
        this.handleSuccessfulBookmark();
      }
    }
    if (apiRequestCallId === this.getReviewHistoryMessageId) {
      if (response.data) {
        this.handleSuccessfulReviewFetch(response);
      }
    }
    if (apiRequestCallId === this.getReviewHistoriesWeeklyMessageId) {
      if (response?.weekly_test) {
        this.handleSuccessFetchResponse(response.weekly_test);
      }
    }
    // Customizable Area End
  }

  // web events
  handleSuccessFetchResponse = (response: WeeklyTest) => {
    const breadcrumb = this.breadcrumb;
    breadcrumb[3].label = response.test_subject;
    breadcrumb[4].label = response.test_name;

    const propPassingRTWMC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingRTWMC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingRTWMC);

    this.setState({
      loading: false,
    });
  };

  handleSuccessfulReviewFetch = (response: Root) => {
    const total =
      response.meta.incorrect_answer +
      response.meta.correct_answer +
      response.meta.unattended;
    this.setState({
      reviewDataWeekly: response.data,
      metaDataWeekly: response.meta,
      loading: false,
      correctPercentage: Number(
        ((response.meta.correct_answer / total) * 100).toFixed(2)
      ),
      unattendedPercentage: Number(
        ((response.meta.unattended / total) * 100).toFixed(2)
      ),
      incorrectPercentage: Number(
        ((response.meta.incorrect_answer / total) * 100).toFixed(2)
      ),
    });
  };

  handleNotesModalClose = () => {
    this.setState({
      openSaveNotesModalWeekly: false,
    });
  };

  componentDidMount = async () => {
    const propPassingRTWC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingRTWC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingRTWC);

    this.reviewId = this.props.navigation.getParam("reviewId");
    this.getReviewHistoryWeekly(this.reviewId);
    this.getReviewHistoriesWeekly();
  };

  handleBookmarkModalClose = () => {
    this.setState({
      openBookmarkModalWeekly: false,
    });
  };

  handleBookmarkModalOpen = () => {
    this.setState({
      openBookmarkModalWeekly: true,
    });
  };

  handleNotesModalOpen = () => {
    this.setState({
      openSaveNotesModalWeekly: true,
    });
  };

  handleBookmarkSuccessModalClose = () => {
    this.setState({
      openBookmarkSuccessModalWeekly: false,
    });
  };

  handleBookmarkSuccessModalOpen = () => {
    this.setState({
      openBookmarkSuccessModalWeekly: true,
    });
  };

  getBtnClassName = (index: number) => {
    const current = this.state.reviewDataWeekly[index].attributes;

    if (current.is_correct) {
      return "btn-answered";
    }

    if (!current.is_correct && !current.is_unattended) {
      return "btn-unanswered-marked";
    }

    if (current.is_unattended) {
      return "btn-unanswered";
    }
  };

  handleNextQuestion = () => {
    if (
      this.state.currentReviewDataWeekly !==
      this.state.reviewDataWeekly.length - 1
    ) {
      this.setState({
        currentReviewDataWeekly: this.state.currentReviewDataWeekly + 1,
      });
    }
  };

  handleSpecificQuestion = (index: number) => {
    this.setState({
      currentReviewDataWeekly: index,
    });
  };

  handlePreviousQuestion = () => {
    if (this.state.currentReviewDataWeekly !== 0) {
      this.setState({
        currentReviewDataWeekly: this.state.currentReviewDataWeekly - 1,
      });
    }
  };

  handleSuccessfulBookmark = () => {
    this.handleBookmarkModalClose();
    this.handleBookmarkSuccessModalOpen();
  };

  // Customizable Area Start
  getReviewHistoryWeekly = async (reviewId: string) => {
    const getReviewHistoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });
    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    this.getReviewHistoryMessageId = getReviewHistoryMessage.messageId;
    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/${reviewId}/review_history`
    );
    runEngine.sendMessage(getReviewHistoryMessage.id, getReviewHistoryMessage);
  };

  bookmarkSave = async () => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const bookmarkHistoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const body = {
      bookmarkable_id: this.reviewId,
      bookmarkable_type: "WeeklyTestReviewHistory",
      question_id:
        this.state.reviewDataWeekly[this.state.currentReviewDataWeekly]
          .attributes.id,
      question_no:
        this.state.reviewDataWeekly[this.state.currentReviewDataWeekly]
          .attributes.question_no,
    };

    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/bookmarks`
    );
    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    this.bookmarkHistoryMessageId = bookmarkHistoryMessage.messageId;

    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(bookmarkHistoryMessage.id, bookmarkHistoryMessage);
  };

  getReviewHistoriesWeekly = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getReviewHistoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReviewHistoriesWeeklyMessageId = getReviewHistoryMessage.messageId;

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/${this.props.navigation.getParam(
        "testId"
      )}/review_histories`
    );

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getReviewHistoryMessage.id, getReviewHistoryMessage);
  };
  // Customizable Area End
}
