import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
interface SubjectType {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    image: string;
    created_by: string;
    class_zone: string;
    practice_test: string;
    question_bank: string;
    subjective_test: string;
    st_code: string;
    remarks: string;
    icon_code: string;
    search_key: string | null;
    total_topics: number;
    saved_notes: number;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  subjects: SubjectType[];
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GuestPracticeTestSubjectsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSubjectsMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      subjects: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSubjectsMessageId && response) {
      if (response?.data) {
        this.handleSubjects(response.data);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getSubjects();
  };

  // Customizable Area Start
  getSubjects = async () => {
    const packageId = await storage.get("guestPackageId");
    const getSubjectsMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      uuid: await storage.get("guestToken"),
    };

    this.setState({
      loading: true,
    });

    this.getSubjectsMessageId = getSubjectsMessage.messageId;

    getSubjectsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.guestFlowSubjectEndPoint + `?pack_final_id=${packageId}`
    );

    getSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSubjectsMessage.id, getSubjectsMessage);
  };

  handleSubjects = (response: SubjectType[]) => {
    this.setState({
      subjects: response,
    });
  };

  navigateTo = (navigateTo: string) => {
    this.props.navigation.history.push(navigateTo);
  };
  // Customizable Area End
}
