import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { webStyleSwayamPrabha } from "./SwayamPrabha.web";
import storage from "../../../framework/src/StorageProvider.web";

import { ValueType } from "react-select";
export interface Swayam {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  subject_id: number;
  chapter_id: number;
  subject_name: string;
  chapter_name: string;
  class_name: string;
  video_name: string;
  part: number;
  url: string;
  date_telecast: string;
  video_by: string;
  video_name_origin: string;
  created_by: string;
  remarks: string;
  status: string;
  image_url: any;
  viewed: boolean;
  created_at: string;
  updated_at: string;
  youtube_video_id: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleSwayamPrabha> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  about: string;
  swayam: Swayam[];
  search: string;
  swayamButtons: string[];
  activeIndex: number;
  isModalOpenFilter: boolean;
  isModalOpenSort: boolean;
  currentSwayam: Swayam[];
  userInput: {
    subject: string;
    chapter: string;
    chapterSort: string;
  };
  subjects: { value: string; label: string }[];
  chapters: { value: string; label: string }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SwayamPrabhaController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAboutMessageId = "";
  getSearchMessageId = "";
  getSwayamPrabhasMessageId = "";
  getSortMessageId = "";
  getFilterMessageId = "";
  private searchTimeout: NodeJS.Timeout | null = null;
  breadcrumb = [
    {
      label: "Swayam Prabha",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      about: "",
      swayam: [],
      search: "",
      swayamButtons: [],
      activeIndex: 0,
      isModalOpenFilter: false,
      isModalOpenSort: false,
      currentSwayam: [],
      userInput: {
        subject: "",
        chapter: "",
        chapterSort: "",
      },
      subjects: [],
      chapters: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSwayamPrabhasMessageId) {
      if (response?.data) {
        this.handleSuccessSwayamFetch(response.data);
      }
    }

    if (apiRequestCallId === this.getAboutMessageId) {
      if (response?.length > 0) {
        this.handleSuccessAboutFetch(response[0].content);
      }
    }

    if (apiRequestCallId === this.getSearchMessageId) {
      if (response.data) {
        this.handleSuccessSwayamSearchFetch(response.data);
      }
    }

    if (apiRequestCallId === this.getFilterMessageId) {
      if (response.data) {
        this.handleSuccessSwayamSearchFetch(response.data);
      }
    }

    if (apiRequestCallId === this.getSortMessageId) {
      if (response.data) {
        this.handleSuccessSwayamSearchFetch(response.data, true);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getSwayamPrabhas();

    this.getAbout();
    const propPassing = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassing.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassing);
  };

  handleUserInputChange = (
    event: ValueType<
      {
        value: string;
        label: string;
      },
      false
    >,
    type: "chapter" | "subject" | "chapterSort"
  ) => {
    const label = event?.label;
    const value = event?.value;

    if (label && value) {
      this.setState({
        userInput: {
          ...this.state.userInput,
          [type]: value,
        },
      });
    }
  };

  componentDidUpdate = (_: Props, prevState: S) => {
    if (prevState.userInput.subject !== this.state.userInput.subject) {
      this.changeCurrentChapters();
    }
  };

  handleSuccessSwayamFetch = (swayam: Swayam[]) => {
    const swayamMap: { [swayam: string]: number } = {};
    swayam.forEach((swayamData) => {
      swayamMap[swayamData.attributes.subject_name] = 1;
    });

    const subjects = swayam.map((data) => ({
      value: data.attributes.subject_name,
      label: data.attributes.subject_name,
    }));

    const chapters = swayam.map((data) => ({
      value: data.attributes.chapter_name,
      label: data.attributes.chapter_name,
    }));

    const filteredSubjects = subjects.filter(
      (subject, index) =>
        subjects.findIndex((item) => item.label === subject.label) === index
    );

    const filteredChapters = chapters.filter(
      (chapter, index) =>
        chapters.findIndex((item) => item.label === chapter.label) === index
    );

    this.setState(
      {
        subjects: filteredSubjects,
        chapters: filteredChapters,
        swayam,
        swayamButtons: Object.keys(swayamMap),
      },
      () => {
        return this.handleActiveSubject(0);
      }
    );
  };

  changeCurrentChapters = () => {
    const { swayam } = this.state;

    const chapters = swayam
      .filter(
        (data) => data.attributes.subject_name === this.state.userInput.subject
      )
      .map((data) => ({
        value: data.attributes.chapter_name,
        label: data.attributes.chapter_name,
      }));

    const filteredChapters = chapters.filter(
      (chapter, index) =>
        chapters.findIndex((item) => item.label === chapter.label) === index
    );

    this.setState({
      chapters: filteredChapters,
    });
  };

  handleSuccessAboutFetch = (about: string) => {
    this.setState({ about });
  };

  handleSuccessSwayamSearchFetch = (swayam: Swayam[], isSort?: boolean) => {
    const currentSwayam = swayam;
    if (isSort) {
      if (this.state.userInput.chapterSort === "subject_name") {
        currentSwayam.sort((firstData, secondData) => {
          const subjectA = firstData.attributes.subject_name.toUpperCase();
          const subjectB = secondData.attributes.subject_name.toUpperCase();

          if (subjectA < subjectB) {
            return -1;
          }
          if (subjectA > subjectB) {
            return 1;
          }
          return 0;
        });
      } else {
        currentSwayam.sort((firstData, secondData) => {
          const subjectA = firstData.attributes.chapter_name.toUpperCase();
          const subjectB = secondData.attributes.chapter_name.toUpperCase();

          if (subjectA < subjectB) {
            return -1;
          }
          if (subjectA > subjectB) {
            return 1;
          }
          return 0;
        });
      }
    }

    this.setState({
      currentSwayam,
      isModalOpenFilter: false,
      isModalOpenSort: false,
    });
  };

  onKeyEnterPressSearch = (eventKey: string) => {
    if (eventKey === "Enter") {
      this.performSearch(this.state.search);
    }
  };

  handleActiveSubject = (index: number) => {
    const currentSwayam = this.state.swayam.filter((swayams) => {
      if (swayams.attributes.subject_name === this.state.swayamButtons[index]) {
        return swayams;
      }
    });

    this.setState({
      activeIndex: index,
      currentSwayam,
    });
  };

  handleCloseModalFilter = () => {
    this.setState({
      isModalOpenFilter: false,
    });
  };

  handleOpenModalFilter = () => {
    this.setState({
      isModalOpenFilter: true,
    });
  };

  handleCloseModalSort = () => {
    this.setState({
      isModalOpenSort: false,
    });
  };

  handleOpenModalSort = () => {
    this.setState({
      isModalOpenSort: true,
    });
  };

  handleSearch = (search: string) => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.setState(
      {
        search,
      },
      () => {
        this.searchTimeout = setTimeout(() => {
          this.performSearch(search);
        }, 600);
      }
    );
  };

  performSearch = async (search: string) => {
    const getSearchMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getSearchMessageId = getSearchMessage.messageId;

    getSearchMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getSearchMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_videos8/swayamprabhas?search=${search}`
    );

    getSearchMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getSearchMessage.id, getSearchMessage);
  };

  performSort = async () => {
    const getSortMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.handleActiveSubject(-1);

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getSortMessageId = getSortMessage.messageId;

    getSortMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getSortMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_videos8/swayamprabhas?sort_by=${this.state.userInput.chapterSort}`
    );

    getSortMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getSortMessage.id, getSortMessage);
  };

  performFilter = async (subjectName: string, chapterName: string) => {
    const getFilterMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let activeIndex: number | undefined = undefined;

    this.state.swayamButtons.forEach((data, index) => {
      if (data === subjectName) {
        activeIndex = index;
      }
    });

    if (activeIndex !== undefined) {
      this.handleActiveSubject(activeIndex);
    } else {
      this.handleActiveSubject(-1);
    }

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getFilterMessageId = getFilterMessage.messageId;

    getFilterMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getFilterMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_videos8/swayamprabhas?subject_name=${subjectName}&chapter_name=${chapterName}`
    );

    getFilterMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getFilterMessage.id, getFilterMessage);
  };

  doneFilter = () => {
    this.performFilter(
      this.state.userInput.subject,
      this.state.userInput.chapter
    );
    this.setState({
      userInput: {
        ...this.state.userInput,
        subject: "",
        chapter: "",
      },
    });
  };

  doneSort = () => {
    this.performSort();
  };

  // Customizable Area Start
  getSwayamPrabhas = async () => {
    const getSwayamPrabhasMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getSwayamPrabhasMessageId = getSwayamPrabhasMessage.messageId;

    getSwayamPrabhasMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getSwayamPrabhasMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_videos8/swayamprabhas`
    );

    getSwayamPrabhasMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getSwayamPrabhasMessage.id, getSwayamPrabhasMessage);
  };

  getAbout = async () => {
    const getAboutMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getAboutMessageId = getAboutMessage.messageId;

    getAboutMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getAboutMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_videos8/swayamprabhas/about`
    );

    getAboutMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getAboutMessage.id, getAboutMessage);
  };
  // Customizable Area End
}
