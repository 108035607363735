import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  Theme,
  createStyles,
  Typography,
  Button,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import { filterBtn, sortBtn } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import Select from "react-select";
import { colourStyles } from "../../../framework/src/UtilsWeb";
// Customizable Area End

import SwayamPrabhaController, { Props } from "./SwayamPrabhaController.web";

class SwayamPrabha extends SwayamPrabhaController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { subjects, chapters } = this.state;
    return (
      // Customizable Area Start
      <Box>
        <Modal
          open={this.state.isModalOpenFilter}
          onClose={this.handleCloseModalFilter}
        >
          <Box className={this.props.classes.modalStyle}>
            <Typography variant={"h6"} style={{ fontWeight: 700 }}>
              Filter By
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              gridGap={"1.5em"}
            >
              <Select
                isSearchable={true}
                isClearable={true}
                name="subject"
                options={subjects}
                placeholder="Subject"
                data-test-id="filter-subject"
                onChange={(event) =>
                  this.handleUserInputChange(event, "subject")
                }
                styles={colourStyles}
              />
              <Select
                isSearchable={true}
                isClearable={true}
                name="chapter"
                options={chapters}
                placeholder="Chapter"
                data-test-id="filter-chapter"
                onChange={(event) =>
                  this.handleUserInputChange(event, "chapter")
                }
                styles={colourStyles}
              />
            </Box>
            <Box alignSelf={"center"}>
              <Button
                data-test-id="close-filter"
                onClick={this.handleCloseModalFilter}
              >
                Cancel
              </Button>
              <Button data-test-id="filter-init" onClick={this.doneFilter}>
                Done
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpenSort}
          onClose={this.handleCloseModalSort}
        >
          <Box className={this.props.classes.modalStyle}>
            <Typography variant={"h6"} style={{ fontWeight: 700 }}>
              Sort By
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              gridGap={"1.5em"}
            >
              <Select
                isSearchable={true}
                isClearable={true}
                name="chapterSort"
                options={[
                  {
                    label: "Subject Name",
                    value: "subject_name",
                  },
                  {
                    label: "Chapter Name",
                    value: "chapter_name",
                  },
                ]}
                placeholder="Chapter Name / Subject Name"
                data-test-id="sort-chapter"
                onChange={(event) =>
                  this.handleUserInputChange(event, "chapterSort")
                }
                styles={colourStyles}
              />
            </Box>
            <Box alignSelf={"center"}>
              <Button
                data-test-id="close-sort"
                onClick={this.handleCloseModalSort}
              >
                Cancel
              </Button>
              <Button data-test-id="sort-init" onClick={this.doneSort}>
                Done
              </Button>
            </Box>
          </Box>
        </Modal>
        <Box className={this.props.classes.bannerStyle}>
          <Typography variant="h5">Swayam Prabha</Typography>
        </Box>
        <Box className={this.props.classes.swayamContainer}>
          <Box className={this.props.classes.aboutStyle}>
            <Typography variant="h6">About</Typography>
            <Box
              component={"div"}
              dangerouslySetInnerHTML={{ __html: this.state.about }}
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginTop={"3em"}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              gridGap={"1em"}
              margin={"0 auto"}
            >
              {this.state.swayamButtons.map((subject, index) => {
                return (
                  <Button
                    key={`${subject}-${index}`}
                    className={this.props.classes.buttonStyle}
                    style={{
                      background:
                        this.state.activeIndex === index
                          ? "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)"
                          : "white",
                      color:
                        this.state.activeIndex === index ? "white" : "black",
                    }}
                    onClick={() => this.handleActiveSubject(index)}
                  >
                    {subject}
                  </Button>
                );
              })}
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gridGap={"1em"}
            >
              <Box position={"relative"}>
                <SearchIcon
                  style={{
                    position: "absolute",
                    left: "4px",
                    top: "5px",
                    color: "#510081",
                  }}
                />
                <input
                  className={this.props.classes.inputSearchStyle}
                  data-test-id="search"
                  placeholder="Search"
                  value={this.state.search}
                  onChange={(event) => this.handleSearch(event.target.value)}
                  onKeyDown={(event) => this.onKeyEnterPressSearch(event.key)}
                />
              </Box>
              <img
                src={filterBtn}
                style={{ width: "20px", cursor: "pointer" }}
                data-test-id="filter"
                onClick={this.handleOpenModalFilter}
              />
              <img
                src={sortBtn}
                style={{ width: "20px", cursor: "pointer" }}
                data-test-id="sort"
                onClick={this.handleOpenModalSort}
              />
            </Box>
          </Box>
          <Box>
            <table
              className={this.props.classes.tableStyle}
              cellPadding="0"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>Subject</th>
                  <th>Chapter</th>
                  <th>Class Name</th>
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                {this.state.currentSwayam.map((swayams, index) => {
                  return (
                    <tr key={`${index}-tr-swayam`}>
                      <td>{swayams.attributes.subject_name}</td>
                      <td>{swayams.attributes.chapter_name}</td>
                      <td>{swayams.attributes.class_name}</td>
                      <td>
                        <NavLink
                          to={`/dashboard/swayam-prabha/watch?id=${swayams.id}&type=swayam`}
                          className={this.props.classes.buttonStyleView}
                        >
                          View
                        </NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleSwayamPrabha = (theme: Theme) =>
  createStyles({
    bannerStyle: {
      display: "flex",
      height: "71px",
      background: "rgba(247, 214, 255, 0.19)",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "rgb(82, 0, 130)",
      "& h5": {
        fontWeight: 700,
      },
    },
    tableStyle: {
      margin: "4em 0",
      width: "100%",
      textAlign: "left",
      tableLayout: "fixed",
      "& thead": {
        border: "1px solid #E8E8E8",
        background: "#E8E8E8",
        "& tr th:nth-child(1)": {
          borderTopLeftRadius: "0.7em",
          paddingLeft: "1.2em",
        },
        "& tr th:last-child": {
          borderTopRightRadius: "0.7em",
        },
        "& tr th": {
          padding: "1.2em 0",
        },
      },
      "& tbody": {
        "& tr td": {
          padding: "1.2em 0",
          wordWrap: "break-word",
          overflowWrap: "break-word",
        },
        "& tr:last-child td:nth-child(1)": {
          borderBottomLeftRadius: "0.7em",
        },
        "& tr:last-child td:last-child": {
          borderBottomRightRadius: "0.7em",
        },
        "& tr td:nth-child(1)": {
          paddingLeft: "1.2em",
        },
        "& tr:nth-child(even)": {
          background: "#E8E8E8",
        },
      },
    },
    inputSearchStyle: {
      border: "1px solid #510081",
      padding: "0.5em 0.5em 0.5em 2em",
      borderRadius: "1.2em",
      color: "#510081",
      outline: "none",
      "&::placeholder": {
        color: "#510081",
      },
    },
    aboutStyle: {
      width: "100%",
      marginTop: "3em",
      padding: "1.5em",
      border: "1px solid #E0E8F1",
      borderRadius: "1em",
      background: "#F5FAFE",
      "& h6": {
        marginBottom: "1em",
      },
    },
    swayamContainer: {
      padding: "0 2em",
    },
    modalStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      left: "50%",
      width: 500,
      height: "300px",
      border: "2px solid #832890",
      backgroundColor: "white",
      borderRadius: "0.5em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "2em",
      gap: "1em",
      outline: "none",
      textAlign: "left",
      "& input": {
        width: "100%",
        border: "1px solid #FF6B55",
        borderRadius: "0.5em",
        padding: "1em",
        outline: "none" as const,
        color: "#510081",
        "&::placeholder": {
          color: "#510081",
        },
      },
      "& button": {
        border: "1px solid #BE888E",
        borderRadius: "0.6em",
        textTransform: "none",
        "&:hover": {
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          color: "white",
        },
        minWidth: "130px",
        width: "max-content",
        height: "45px",
        color: "#4F1f6d",
        fontWeight: 700,
        margin: "0 1em",
      },
    },
    buttonStyle: {
      border: "1px solid #BE888E",
      borderRadius: "0.6em",
      textTransform: "none" as const,
      "&:hover": {
        background: "#FF5D5B",
        color: "white",
      },
      padding: "0 2em",
      width: "max-content",
      height: "50px",
      color: "#4F1f6d",
    },
    buttonStyleView: {
      border: "none",
      borderRadius: "0.6em",
      textTransform: "none" as const,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "120px",
      height: "45px",
      color: "white",
      fontSize: "0.9em",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    },
  });

export default withStyles(webStyleSwayamPrabha)(SwayamPrabha);
export { SwayamPrabha };
// Customizable Area End
