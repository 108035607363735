import React from "react";

import {
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Header from "../../../components/src/Header.web";
import { myranklogo } from "../../../components/src/assets";
// Customizable Area End

import MegamenuCommonController, {
  Props,
} from "./MegamenuCommonController.web";

export default class MegamenuCommon extends MegamenuCommonController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Header image={myranklogo} navigation={this.props.navigation} />
        <Box padding={"4em"}>
          <Box
            dangerouslySetInnerHTML={{
              __html: this.state.content,
            }}
          />
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
