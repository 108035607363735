import React from "react";

import {
  // Customizable Area Start
  Box as MUIDiv,
  withStyles,
  createStyles,
  Typography,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MathJaxBox from "../../../components/src/MathJaxBox.web";
// Customizable Area End

import AYQSimilarQuestionController, {
  Props,
} from "./AYQSimilarQuestionController.web";

class AYQSimilarQuestion extends AYQSimilarQuestionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MUIDiv>
        <MUIDiv className={this.props.classes.bannerStyle}>
          <Typography variant="h5">Ask Your Query</Typography>
        </MUIDiv>
        <MUIDiv className={this.props.classes.questionsAskedStyle}>
          <MUIDiv
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom={"2em"}
          >
            <Typography
              style={{
                border: "1px solid #bbb",
                textAlign: "center",
                width: "max-content",
                padding: "0.6em",
              }}
            >
              This question already has answers here
            </Typography>
          </MUIDiv>
          {this.state.questionsAsked.length > 0 ? (
            this.state.questionsAsked.map((question, index) => {
              return (
                <MUIDiv
                  className={this.props.classes.questionBoxStyle}
                  position={"relative"}
                  key={`${question.attributes.account_id}-${index}`}
                >
                  <MUIDiv
                    position="absolute"
                    left="20px"
                    top="20px"
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ArrowDropUpIcon />
                    <Typography variant="body1">
                      {question.attributes.current_user_liked}
                    </Typography>
                    <ArrowDropDownIcon />
                  </MUIDiv>
                  <MathJaxBox content={question.attributes.question} />
                  <MUIDiv className="main-wrapper">
                    <MUIDiv component={"ul"}>
                      {question.attributes.tag_list.map((tagList) => {
                        return (
                          <MUIDiv component={"li"} key={tagList}>
                            {tagList
                              .slice(0, 1)
                              .toUpperCase()
                              .concat(tagList.slice(1))}
                          </MUIDiv>
                        );
                      })}
                    </MUIDiv>
                    <MUIDiv
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      border={"1px solid rgb(254,110,82)"}
                      padding="0.5em"
                      width={"max-content"}
                      marginTop="0.6em"
                      borderRadius={"0.2em"}
                      style={{ cursor: "pointer" }}
                      onClick={() => this.viewAyqQuestion(question.id)}
                      data-test-id="question-view-btn"
                    >
                      <CheckCircleIcon className="check-circle" />
                      <span>{question.attributes.answer_count} Answers</span>
                    </MUIDiv>
                    <MUIDiv
                      position={"absolute"}
                      right="25px"
                      bottom="20px"
                      display="flex"
                      justifyContent={"center"}
                      alignItems={"center"}
                      style={{ cursor: "pointer" }}
                      data-test-id="profile-btn"
                      onClick={() =>
                        this.goToProfile(
                          question.attributes.user_profile?.data.id
                        )
                      }
                    >
                      <img
                        src={
                          question.attributes.user_profile?.data.attributes
                            .image
                        }
                        alt="picture"
                        width={"25px"}
                        height={"25px"}
                      />
                      <span className="profile-span">
                        {question.attributes.user_profile?.data.attributes.name}
                        <span style={{ marginLeft: "0.4em" }}>
                          {this.getTimeDifference2(
                            question.attributes.created_at
                          )}
                        </span>
                      </span>
                    </MUIDiv>
                  </MUIDiv>
                </MUIDiv>
              );
            })
          ) : (
            <MUIDiv>
              <Typography>There are no question found.</Typography>
            </MUIDiv>
          )}
        </MUIDiv>
        <MUIDiv
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography style={{ fontWeight: 600 }}>
            Not the answer you are looking for?
          </Typography>
          <Button
            className={this.props.classes.buttonStyleYes}
            data-test-id="navigateToQuestion"
            onClick={this.navigateToAskQuestion}
          >
            Ask Your own Question
          </Button>
        </MUIDiv>
      </MUIDiv>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleAYQSimilarQuestion = () =>
  createStyles({
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      color: "#510081",
      display: "flex",
      width: "100%",
      background: "rgb(247,242,249)",
      height: "71px",
      "& h5": {
        fontWeight: 700,
      },
    },
    questionBoxStyle: {
      display: "flex",
      padding: "1em 0.3em 3em 4em",
      background: "rgb(240,240,240)",
      margin: "1em",
      flexDirection: "column",
      height: "max-content",
      border: "none",
      minHeight: "200px",
      "& .main-wrapper": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        "& .profile-span": {
          "& span": {
            color: "gray",
            fontSize: "0.8em",
          },
          fontWeight: "500",
          fontSize: "1.2em",
        },
        "& p": {
          fontSize: "1.35em",
        },
        "& .check-circle": {
          color: "rgb(4,147,69)",
          marginRight: "0.6em",
          fontSize: "1.2em",
        },
        "& img": {
          borderRadius: "50%",
          marginRight: "0.2em",
        },
        "& ul": {
          "& li": {
            border: "none",
            fontWeight: "500",
            borderRadius: "0.5em",
            padding: "0.3em 1em",
            background: "rgb(233,225,240)",
          },
          flexWrap: "wrap",
          gap: "1em",
          margin: "1em 0",
          display: "flex",
        },
      },
    },
    questionsAskedStyle: {
      padding: "2em",
    },
    buttonStyleYes: {
      color: "white",
      marginTop: "1em",
      marginBottom: "3em",
      borderRadius: "1em",
      width: "400px",
      textTransform: "none" as const,
      height: "50px",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    },
  });
export default withStyles(webStyleAYQSimilarQuestion)(AYQSimilarQuestion);
export { AYQSimilarQuestion };
// Customizable Area End
