export const loginIcon = require("../assets/loginImg.png");
export const mainLoginImage = require("../assets/main-login-asset.png");
export const myrankLogo = require("../assets/myrank_svg-logo.png");
export const myrankLogo2 = require("../assets/myrank_svg-logo.png");
export const google = require("../assets/google.png");
export const imgPasswordInVisible = require("../assets/google.png");
export const imgPasswordVisible = require("../assets/google.png");
export const party = require("../assets/party.png");
export const bgLogin = require("../assets/stacked-waves-haikei.png")
export const bgLoginMain = require("../assets/login.png")
export const eyeIcon = require('../assets/eye.png')
export const IneyeIcon = require('../assets/Invisible.png')
export const button_filled = require('../assets/button_filled.png')
export const backGround = require('../assets/Erank_background.jpg')
export const Verified = require('../assets/verified.png')
export const closeIcon = require('../assets/close.png')
export const warning = require('../assets/warning.png')
export const ElearnImage = require('../assets/bro_copy.png')
export const GuidenceImage = require('../assets/cuate_copy.png')
export const GuidenceName = require('../assets/GuidenceName.png')
export const ElearnName =  require('../assets/ElearnName.png')
export const amico = require('../assets/amico.png')
