import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { Daum, Daum2, Daum3 } from "./AYQQuestionController.web";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentIndex: number;
  currentQuestionId: string;
  currentQuestion: Daum | null;
  answer: string;
  comments: string[];
  isModalOpen: boolean;
  search: string;
  modalCurrentFilter: boolean;
  filter: string;
  loading: boolean;
  token: string;
  isNotLoggedIn: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AYQQuestionAnswerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAyqQuestionsMessageId = "";
  commentAnswerMessageId = "";
  answerQuestionMessageId = "";
  searchTimeout: NodeJS.Timeout | null = null;
  answerId: number = Infinity;
  likeDislikeMessageId = "";
  likeDislikeQuestionMessageId = "";
  indexOfLikeDislike: { index: number; status: number } = {
    index: Infinity,
    status: Infinity,
  };
  likeDislikeQuestionStatus: number = Infinity;
  breadcrumb = [
    {
      label: "Questions",
      link: "/dashboard/ask-your-query/questions",
    },
    {
      label: "Question Answer",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentIndex: 1,
      search: "",
      currentQuestionId: "",
      currentQuestion: null,
      answer: "",
      comments: [],
      isModalOpen: false,
      filter: "",
      modalCurrentFilter: false,
      loading: false,
      token: "",
      isNotLoggedIn: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getAyqQuestionsMessageId) {
      if (response.data) {
        this.handleFetchSuccess(response.data);
      }
    }

    if (apiRequestCallId === this.answerQuestionMessageId) {
      if (response.data) {
        this.handleAnswerSuccess(response.data);
      }
    }

    if (apiRequestCallId === this.commentAnswerMessageId) {
      if (response.data) {
        this.handleCommentSuccess(response.data);
      }
    }

    if (apiRequestCallId === this.likeDislikeMessageId && !response.errors) {
      if (response.message) {
        this.handleLikeDislike();
      }
    }

    if (
      apiRequestCallId === this.likeDislikeQuestionMessageId &&
      !response.errors
    ) {
      if (response.message) {
        this.handleLikeDislikeQuestions();
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const questionIdParams = this.props.navigation.getParam("questionId");
    const propPassingAQAC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    this.getAyqQuestionAndAnswer(questionIdParams);
    propPassingAQAC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingAQAC);
    this.handleToken();
  };

  handleToken = async () => {
    const token = await storage.get("authToken");
    this.setState({
      token,
    });
  };
  navigateToLogin = () => {
    this.props.navigation.history.push("/ask-your-query/login");
  };

  handleAyqLoginModal = () => {
    this.setState({
      isNotLoggedIn: !this.state.isNotLoggedIn,
    });
  };

  handleLikeDislikeQuestions = () => {
    const question = this.state.currentQuestion;

    if (question) {
      if (this.likeDislikeQuestionStatus === 1) {
        question.attributes.like_count +=
          question.attributes.current_user_liked === 1 ? -1 : 1;
        question.attributes.current_user_liked =
          question.attributes.current_user_liked === 1 ? 0 : 1;
        question.attributes.dislike_count +=
          question.attributes.current_user_liked === -1 ? -1 : 0;
      } else if (this.likeDislikeQuestionStatus === -1) {
        question.attributes.like_count +=
          question.attributes.current_user_liked === 1 ? -1 : 0;
        question.attributes.dislike_count +=
          question.attributes.current_user_liked === -1 ? -1 : 1;
        question.attributes.current_user_liked =
          question.attributes.current_user_liked === -1 ? 0 : -1;
      }
    }

    this.setState({
      currentQuestion: question,
    });
  };

  handleCommentSuccess = (data: Daum3) => {
    const question = this.state.currentQuestion;
    question?.attributes.ayq_answers.data[
      this.answerId
    ].attributes.comments.data.push(data);

    this.setState({
      currentQuestion: question,
    });
  };

  navigateToAskQuestion = () => {
    this.props.navigation.navigate("AskYourQueryQuestionToAsk");
  };

  handleAnswerSuccess = (data: Daum2) => {
    const answerAdded = this.state.currentQuestion;
    answerAdded?.attributes.ayq_answers.data.push(data);
    this.handleModalOpen();
    this.setState({
      answer: "",
      currentQuestion: answerAdded,
    });
  };

  handleFetchSuccess = (data: Daum[]) => {
    const indexOfQuestion = data.findIndex((curr) => {
      return curr.id === this.state.currentQuestionId;
    });

    this.setState({
      currentQuestion: data[indexOfQuestion],
    });
  };

  handleChange = (value: string) => {
    this.setState({ answer: value });
  };

  getTimeDifference = (timestampStr: string): string => {
    const timestamp = new Date(timestampStr);
    const relativeTime = formatDistanceToNow(timestamp, { addSuffix: true });
    return relativeTime;
  };
  handleModalOpen = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  handleCommentChange = (index: number, value: string) => {
    const comments = this.state.comments;
    comments[index] = value;

    this.setState({
      comments,
    });
  };

  handleKeyDown = (
    keyDown: string,
    index: number,
    answerId: string,
    value: string
  ) => {
    if (keyDown === "Enter") {
      this.submitComment(answerId, value, index);
    }
  };

  // Customizable Area Start
  goToProfile = (dataId: string) => {
    this.props.navigation.navigate("AskYourQueryProfilePeek", {
      profileId: dataId,
    });
  };
  clickToNavigate = (index: number) => {
    this.props.navigation.navigate("AskYourQueryQuestion", {
      navigationCurr: index,
    });
  };

  handleSearch = (search: string) => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.setState(
      {
        search,
      },
      () => {
        this.searchTimeout = setTimeout(() => {
          this.navigateTo(search);
        }, 600);
      }
    );
  };

  navigateTo = (search: string) => {
    this.props.navigation.history.push(
      `/dashboard/ask-your-query/questions?query=${search}`
    );
  };

  handleFilter = (filter: string) => {
    this.setState({
      filter,
    });
  };

  filterQuestionNavigate = () => {
    this.props.navigation.history.push(
      `/dashboard/ask-your-query/questions?filter=${this.state.filter}`
    );
  };

  handleModalFilterCurrent = () => {
    this.setState({
      modalCurrentFilter: !this.state.modalCurrentFilter,
    });
  };

  navigateToRegister = () => {
    this.props.navigation.history.push("/ask-your-query/register");
  };

  handleLikeDislike = () => {
    const questions = this.state.currentQuestion;
    const answers =
      questions?.attributes.ayq_answers.data[this.indexOfLikeDislike.index];

    if (answers) {
      if (this.indexOfLikeDislike.status === 1) {
        answers.attributes.like_count +=
          answers.attributes.current_user_liked === 1 ? -1 : 1;
        answers.attributes.dislike_count +=
          answers.attributes.current_user_liked === -1 ? -1 : 0;
        answers.attributes.current_user_liked =
          answers.attributes.current_user_liked === 1 ? 0 : 1;
      } else if (this.indexOfLikeDislike.status === -1) {
        answers.attributes.dislike_count +=
          answers.attributes.current_user_liked === -1 ? -1 : 1;
        answers.attributes.like_count +=
          answers.attributes.current_user_liked === 1 ? -1 : 0;
        answers.attributes.current_user_liked =
          answers.attributes.current_user_liked === -1 ? 0 : -1;
      }
    }

    this.setState({
      currentQuestion: questions,
    });
  };

  answerQuestion = async () => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    if (!this.state.token) {
      return this.handleAyqLoginModal();
    }

    this.setState({
      loading: true,
    });

    const body = {
      data: {
        ayq_question_id: this.state.currentQuestionId,
        answer: this.state.answer,
      },
    };

    const answerQuestionsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.answerQuestionMessageId = answerQuestionsMessage.messageId;

    answerQuestionsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    answerQuestionsMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    answerQuestionsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    answerQuestionsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_answers`
    );

    runEngine.sendMessage(answerQuestionsMessage.id, answerQuestionsMessage);
  };

  getAyqQuestionAndAnswer = (questionId: string) => {
    this.setState(
      {
        currentQuestionId: questionId,
        loading: true,
      },
      async () => {
        const token = await storage.get("authToken");

        const headers = {
          token: token,
        };

        const getAyqQuestionsMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAyqQuestionsMessageId = getAyqQuestionsMessage.messageId;

        if (token) {
          getAyqQuestionsMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
        }

        getAyqQuestionsMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_communityforum/ayq_questions`
        );
        getAyqQuestionsMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );

        runEngine.sendMessage(
          getAyqQuestionsMessage.id,
          getAyqQuestionsMessage
        );
      }
    );
  };

  likeDislikeQuestion = async (status: number) => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    if (!this.state.token) {
      return this.handleAyqLoginModal();
    }

    this.setState({
      loading: true,
    });

    this.likeDislikeQuestionStatus = status;

    const likeDislikeQuestionMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeDislikeQuestionMessageId = likeDislikeQuestionMessage.messageId;

    likeDislikeQuestionMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    likeDislikeQuestionMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/create_ayq_like?ayq_question_id=${this.state.currentQuestion?.id}&status=${status}`
    );

    likeDislikeQuestionMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(
      likeDislikeQuestionMessage.id,
      likeDislikeQuestionMessage
    );
  };

  submitComment = async (answerId: string, comment: string, index: number) => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    if (!this.state.token) {
      return this.handleAyqLoginModal();
    }

    const comments = this.state.comments;
    comments[index] = "";

    this.setState({
      loading: true,
      comments,
    });

    this.answerId = index;

    const commentAnswerMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.commentAnswerMessageId = commentAnswerMessage.messageId;

    commentAnswerMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    commentAnswerMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    commentAnswerMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_answers/${answerId}/comment?comment=${comment}`
    );

    runEngine.sendMessage(commentAnswerMessage.id, commentAnswerMessage);
  };

  likeDislike = async (status: number, index: number) => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    if (!this.state.token) {
      return this.handleAyqLoginModal();
    }

    this.setState({
      loading: true,
    });

    this.indexOfLikeDislike = { index, status };

    const likeDislikeMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeDislikeMessageId = likeDislikeMessage.messageId;

    likeDislikeMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    likeDislikeMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    likeDislikeMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/create_ayq_like?ayq_answer_id=${this.state.currentQuestion?.attributes.ayq_answers.data[index].id}&status=${status}`
    );

    runEngine.sendMessage(likeDislikeMessage.id, likeDislikeMessage);
  };
  // Customizable Area End
}
