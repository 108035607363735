import React from "react";

import {
  Box as BoxFromMUI,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  Grid as MUIGrid,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import { Physics, emptyStar, starIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { webStyle } from "./BookMySlotAddEditSlot.web";
// Customizable Area End

import BookMySlotFacultyFeedbackController, {
  Props,
  configJSON,
} from "./BookMySlotFacultyFeedbackController.web";
import { boxStyles } from "./BookMySlot.web";

class BookMySlotFacultyFeedback extends BookMySlotFacultyFeedbackController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderAppointmentBox = () => {
    return (
      <MUIGrid key={`bookSlot-${this.state.slotDetails?.id}`} sm={12} item>
        <BoxFromMUI className={this.props.classes.appointmentBox}>
          <BoxFromMUI className="details-appointment" position={"relative"}>
            <img src={Physics} alt={"physics-image"} />
            <BoxFromMUI
              display="flex"
              flexDirection={"column"}
              gridGap={"1em"}
              marginLeft={"3em"}
            >
              <Typography>
                {configJSON.subjectTitle} -{" "}
                {this.state.slotDetails?.attributes.bms_slot.slot_subject}
              </Typography>
              <Typography>
                Chapter -{" "}
                {
                  this.state.slotDetails?.attributes.chapter.data.attributes
                    .name
                }
              </Typography>
              <Typography>
                Topic -{" "}
                {this.state.slotDetails?.attributes.topic.data.attributes.name}
              </Typography>
              <Typography>
                Time - {this.state.slotDetails?.attributes.bms_slot.slot_start}{" "}
                - {this.state.slotDetails?.attributes.bms_slot.slot_end}
              </Typography>
            </BoxFromMUI>
          </BoxFromMUI>
          <BoxFromMUI className="buttons-appointment">
            <Button className="status">
              Status: {this.state.slotDetails?.attributes.bms_slot.slot_status}
            </Button>
            <Typography>
              Student -{" "}
              {this.state.slotDetails?.attributes.account.data.attributes.name}
            </Typography>
          </BoxFromMUI>
        </BoxFromMUI>
      </MUIGrid>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <BoxFromMUI>
        <Modal
          open={this.state.isSlotUpdatedModal}
          onClose={this.handleIsSlotUpdatedModal}
          data-test-id="modal-slot-booked"
        >
          <BoxFromMUI sx={webStyle.modalStyle}>
            <Typography>Feedback Submitted Successfully</Typography>
            <Button
              style={webStyle.buttonStyle}
              onClick={this.navigateToBookingsFaculty}
              data-test-id="homebtn"
            >
              Go to Home
            </Button>
          </BoxFromMUI>
        </Modal>
        <Modal
          open={this.state.isErrorModal}
          onClose={this.showErrorModal}
          data-test-id="modal-slot-booked"
        >
          <BoxFromMUI sx={webStyle.modalStyle}>
            <Typography>Feedback was already given</Typography>
            <Button
              style={webStyle.buttonStyle}
              onClick={this.showErrorModal}
              data-test-id="homebtn"
            >
              Go Back
            </Button>
          </BoxFromMUI>
        </Modal>
        <BannerWeb title="Feedback" />
        <Loader loading={this.state.loading} />
        <BoxFromMUI
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <BoxFromMUI
            display="flex"
            flexDirection={"column"}
            width={"70%"}
            marginTop={"5em"}
          >
            <BoxFromMUI
              alignSelf={"center"}
              width={"100%"}
              marginBottom={"3em"}
            >
              {this.state.slotDetails && this.RenderAppointmentBox()}
            </BoxFromMUI>
            <BoxFromMUI>
              <BoxFromMUI
                display="flex"
                flexDirection={"column"}
                justifyContent="center"
                alignItems={"center"}
                width={"100%"}
                marginBottom={"3em"}
              >
                <Typography className={this.props.classes.typographyStyle2}>
                  Please Rate and Review your doubt session with{" "}
                  {
                    this.state.slotDetails?.attributes.account.data.attributes
                      .name
                  }
                </Typography>
                <BoxFromMUI>
                  {Array.from(Array(5).keys()).map((_, index) => {
                    return (
                      <>
                        {this.state.starsIndex >= index ? (
                          <img
                            width={"30px"}
                            src={starIcon}
                            onClick={() => this.handleRatedStars(index)}
                            alt="filled"
                            data-test-id="rate-btn-star"
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <img
                            src={emptyStar}
                            width={"30px"}
                            alt="empty"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleRatedStars(index)}
                            data-test-id="rate-btn-star"
                          />
                        )}
                      </>
                    );
                  })}
                </BoxFromMUI>
              </BoxFromMUI>
              <BoxFromMUI>
                <Typography className={this.props.classes.typographyStyle}>
                  Write your review
                </Typography>
                <textarea
                  className={this.props.classes.textAreaStyle}
                  onChange={(event) => this.setComments(event.target.value)}
                  placeholder="How's the experience?"
                  value={this.state.comments}
                  data-test-id="textarea"
                />
              </BoxFromMUI>
              <BoxFromMUI
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Button
                  className={this.props.classes.submitButtonStyle}
                  onClick={this.postUpdate}
                  data-test-id="submit"
                >
                  Submit
                </Button>
              </BoxFromMUI>
            </BoxFromMUI>
          </BoxFromMUI>
        </BoxFromMUI>
      </BoxFromMUI>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleBookMySlotFacultyFeedback = () =>
  createStyles({
    appointmentBox: {
      ...boxStyles,
      "& .details-appointment img": {
        height: "180px!important",
        borderRadius: "0.7em!important",
        width: "300px!important",
      },
      "& .details-appointment p": {
        fontWeight: 500,
        fontSize: "1.4em",
      },
      "& .buttons-appointment": {
        display: "flex",
        alignItems: "center",
        marginTop: "1.3em",
        "& .status": {
          background: "rgba(173, 135, 197, 0.25)",
          borderRadius: "4px",
          color: "rgb(82, 0, 130)",
          minWidth: "226px",
          width: "max-content",
          height: "48px",
          fontWeight: 500,
        },
        "& p": {
          fontWeight: 500,
          marginLeft: "1em",
        },
      },
      padding: "2em!important",
      border: "1px solid #808080!important",
    },
    typographyStyle: {
      fontSize: "1.3em",
      fontWeight: 500,
    },
    textAreaStyle: {
      width: "100%",
      height: "200px",
      padding: "1.5em",
      fontSize: "1.2em",
      outline: 0,
      border: "1px solid #FF4D4F",
      borderRadius: "0.7em",
      resize: "none",
      marginTop: "1.3em",
    },
    submitButtonStyle: {
      color: "white",
      borderRadius: "0.5em",
      width: "400px",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      height: "60px",
      alignSelf: "center",
      margin: "2em 0 3em 0",
    },
    typographyStyle2: {
      fontWeight: 500,
      fontSize: "1.4em",
      width: "30%",
      textAlign: "center",
    },
  });
export default withStyles(webStyleBookMySlotFacultyFeedback)(
  BookMySlotFacultyFeedback
);
export { BookMySlotFacultyFeedback };
// Customizable Area End
