import React from "react";

import {
  Box,
  // Customizable Area Start
  Stepper,
  Step,
  StepLabel,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Check } from "@material-ui/icons";
import Footer from "./Footer.web";
import SignupScreen from "./SignupScreen.web";
import PackageSelection from "./PackageSelection.web";
import PackagePricingSelection from "./PackagePricingSelection.web";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import "./styles/page.scss";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const CheckIcon = () => {
      return (
        <Box className="checkIcon">
          <Check />
        </Box>
      );
    };

    const FailIcon = () => {
      return (
        <Box className="checkIcon">
          <ClearIcon />
        </Box>
      );
    };

    const CircleEmptyIcon = () => {
      return <Box className="circleIcon" />;
    };

    const DisplayedStep = (step: number) => {
      switch (step) {
        case 0:
          return (
            <PackageSelection
              id="packageselection"
              navigation={this.props.navigation}
              allPackages={this.state.allPackagesData}
              getPackageDetails={this.getPackageDetails}
            />
          );

        case 1:
          return (
            <PackagePricingSelection
              id="packagepricingselection"
              navigation={this.props.navigation}
              package={
                this.state.currentPackageChosen
                  ? this.state.currentPackageChosen
                  : null
              }
              setPackageChosen={this.setPackageChosen}
              chosenPackageId={this.state.currentChosenPackageId}
            />
          );

        case 2:
          return (
            <SignupScreen
              id="signupform"
              navigation={this.props.navigation}
              chosenPackageId={this.state.currentChosenPackagePricingId}
              chosenPackageIndex={this.state.currentPricingIndex}
              package={
                this.state.currentPackageChosen
                  ? this.state.currentPackageChosen
                  : null
              }
              chosenPackageToken={this.state.chosenPackageToken}
            />
          );

        case 3:
          return (
            <Box className="paymentprocessing">
              <CircularProgress size={"6em"} style={{color:"#FF4552"}}/>
              <p>Payment Processing...</p>
            </Box>
          );

        case 4:
          return (
            <Box>
              {this.state.isPaymentSuccessful && (
                <Box className="paymentprocessed">
                  <CheckCircleIcon className="icon2" />
                  <Box marginBottom={"3em"} textAlign={"center"}>
                    <p>Payment Successful</p>
                    <p>My Rank ID - {this.state.myrankIdGenerated}</p>
                  </Box>
                  <Button onClick={this.handleGoToLogin}>LOGIN</Button>
                </Box>
              )}
              {this.state.isPaymentFailure && (
                <Box className="paymentprocessed">
                  <CancelIcon className="icon" />
                  <Box marginBottom={"3em"} textAlign={"center"}>
                    <p>Payment UnSuccessful</p>
                  </Box>
                  {this.state.paymentLink && (
                    <>
                      <a href={this.state.paymentLink}>
                        <Button>Retry</Button>
                      </a>
                      <Button onClick={this.handlePaymentNo}>
                        Cancel Registration
                      </Button>
                    </>
                  )}
                </Box>
              )}
            </Box>
          );

        default:
          return null;
      }
    };

    return (
      // Customizable Area Start
      <Box className="email-account-container">
        <Box
          data-test-id="gobackbtn"
          className="gobackstep"
          onClick={this.handlePrevStep}
        >{`<`}</Box>
        <h2
          style={{ textAlign: "center", marginBottom: "2em", color: "white" }}
        >
          {this.steps[this.state.activeStep]}
        </h2>
        <Stepper
          alternativeLabel
          activeStep={this.state.activeStep}
          className="custom-stepper"
        >
          {this.steps.map((label, index) => {
            let stepIconComponent: () => JSX.Element;
            if (this.isStepFinished(index)) {
              if (
                index === this.steps.length - 1 &&
                this.state.isPaymentFailure
              ) {
                stepIconComponent = FailIcon;
              } else {
                stepIconComponent = CheckIcon;
              }
            } else {
              stepIconComponent = CircleEmptyIcon;
            }

            return (
              <Step
                key={index}
                completed={this.isStepFinished(index)}
                className={`${index === 0 ? "firstActive" : ""}`}
              >
                <StepLabel StepIconComponent={stepIconComponent}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box style={webStyle.boxSyle}>
          {DisplayedStep(this.state.activeStep)}
        </Box>
        <Footer id="footer-register" navigation={this.props.navigation} />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  boxSyle: {
    minHeight: "90vh",
    width: "100%",
  },
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "2px solid #832890",
    boxShadow: 24,
    backgroundColor: "white",
    p: 4,
    borderRadius: "0.5em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
    maxHeight: "80%",
  },
};
// Customizable Area End
