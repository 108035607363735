import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button as MUIButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Button from "../../../components/src/Button.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import GuestPracticeTestChaptersController, {
  Props,
} from "./GuestPracticeTestChaptersController.web";

class GuestPracticeTestChapters extends GuestPracticeTestChaptersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className={this.props.classes.chapterContainerStyle}>
        <Loader loading={this.state.loading} />
        {this.state.chapters.map((attempted) => {
          const subjectId = this.props.navigation.getParam("subjectId");
          return (
            <Box className={this.props.classes.cardStyle}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  src={attempted.attributes.image}
                  alt={`chapter-${attempted.id}`}
                  className={this.props.classes.imageStyle}
                />
              </Box>
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {attempted.attributes.name}
              </Typography>
              <Box className={this.props.classes.buttonStyles}>
                <Button
                  color="white"
                  height="40px"
                  width="121px"
                  title="Take Test"
                  onClick={() =>
                    this.navigateTo(
                      `/guest/practice-test/${subjectId}/chapters/${attempted.id}/test`
                    )
                  }
                />
                <MUIButton className={this.props.classes.buttonStyle}>
                  Review Test
                  <Box
                    className={this.props.classes.goBackStep}
                    data-test-id="gobackbtn"
                  >{`>`}</Box>
                </MUIButton>
              </Box>
            </Box>
          );
        })}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleGuestPracticeTestChapters = () =>
  createStyles({
    chapterContainerStyle: {
      display: "flex",
      gap: "1em",
      width: "100%",
      flexWrap: "wrap" as const,
      justifyContent: "center",
      alignItems: "center",
      marginTop: "6em",
    },
    imageStyle: {
      width: "90px",
      borderRadius: "50%",
      height: "90px",
    },
    cardStyle: {
      width: "350px",
      minHeight: "130px",
      maxWidth: "100%",
      flexWrap: "wrap" as const,
      flexDirection: "column" as const,
      height: "max-content",
      display: "flex",
      justifyContent: "center",
      border: "1px solid rgba(255, 63, 89, 1)",
      borderRadius: "0.5em",
      padding: "2em 0.7em",
      gap: "1em",
      marginTop: "2em",
    },
    buttonStyle: {
      color: "rgb(82, 0, 130)",
      width: "125px",
      border: "1px solid #FF9486",
      height: "40px",
      display: "flex",
      position: "relative" as const,
      alignItems: "center",
      borderRadius: "10px",
      textTransform: "none" as const,
      justifyContent: "flex-start",
    },
    buttonStyles: {
      display: "flex",
      gap: "2em",
      justifyContent: "center",
      alignItems: "center",
    },
    goBackStep: {
      display: "flex",
      height: "20px",
      borderRadius: "50%",
      padding: "0.3em",
      justifyContent: "center",
      alignItems: "center",
      width: "20px",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      color: "white",
      cursor: "pointer",
      position: "absolute",
      right: "5px",
    },
  });
export default withStyles(webStyleGuestPracticeTestChapters)(
  GuestPracticeTestChapters
);
export { GuestPracticeTestChapters };
// Customizable Area End
