import React from "react";
import {
  // Customizable Area Start
  Box,
  Modal,
  Button,
  TextField,
  Checkbox,
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { bgAYQ } from "./assets";
import { Formik } from "formik";
import { CheckCircle } from "@material-ui/icons";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import AYQRegisterController, {
  Props,
  configJSON,
} from "./AYQRegisterController";

export class AYQRegister extends AYQRegisterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const termsAndCondition = this.state.terms.replace(
      /\{&#39;\\n&#39;\}/g,
      "<br />"
    );

    const isOtpEmailError = (email: string) =>
      this.state.isOtpEmailError && (
        <Box className={this.props.classes.resendOTPContainer}>
          <p className={this.props.classes.resendOTPText}>Invalid OTP</p>
          <Button
            data-testid="resend-email-otp-button"
            onClick={() => this.sendEmailOTP(email)}
            className={this.props.classes.resendOTPButton}
          >
            Resend OTP
          </Button>
        </Box>
      );

    const isOtpMobileError = (mobileNumber: string) =>
      this.state.isOtpMobileError && (
        <Box className={this.props.classes.resendOTPContainer}>
          <p className={this.props.classes.resendOTPText}>Invalid OTP</p>
          <Button
            data-testid="resend-mobile-otp-button"
            onClick={() => this.sendMobileOTP(mobileNumber)}
            className={this.props.classes.resendOTPButton}
          >
            Resend OTP
          </Button>
        </Box>
      );

    return (
      // Customizable Area Start
      <Box className={this.props.classes.registrationContainer}>
        <Loader loading={this.state.loading} />
        <Box
          className={this.props.classes.goBackBtn}
          onClick={this.handleGoBack}
          data-testid="go-back"
        >
          <div className="back-icon">{"<"}</div>
        </Box>
        <Box className="signup-form-register">
          <Modal
            open={this.state.modalTermsOpen}
            onClose={this.handleModalTerms}
          >
            <Box sx={webStyle.modalStyle}>
              <div
                id="terms-and-condition"
                style={{ overflowY: "scroll" }}
                dangerouslySetInnerHTML={{ __html: termsAndCondition }}
              />
            </Box>
          </Modal>
          <Modal
            open={this.state.modalEmailExistOpen}
            onClose={this.handleErrorEmailExistModal}
          >
            <Box sx={webStyle.modalStyle}>
              <h5>
                Email Account Already Activated. Please use a different one.
              </h5>
              <Button
                data-testid="closemodal-emailexist"
                onClick={this.handleErrorEmailExistModal}
              >
                Close
              </Button>
            </Box>
          </Modal>
          <Modal
            open={this.state.modalMobileExistOpen}
            onClose={this.handleErrorMobileExistModal}
          >
            <Box sx={webStyle.modalStyle}>
              <h5>Mobile Already Activated. Please use a different one.</h5>
              <Button
                data-testid="closemodal-emailexist"
                onClick={this.handleErrorMobileExistModal}
              >
                Close
              </Button>
            </Box>
          </Modal>
          <Modal
            open={this.state.isRegisterSuccess}
            onClose={this.handleRegisterSuccessModal}
            BackdropProps={{ onClick: undefined }}
          >
            <Box className={this.props.classes.modalStyleSuccess}>
              <CheckCircle />
              <h5>Registered Successfully</h5>
              <Button data-testid="to-login" onClick={this.navigateToLogin}>
                Login
              </Button>
            </Box>
          </Modal>
          <Modal
            open={this.state.completeOTPModal}
            onClose={this.openSubmitOtpModal}
          >
            <Box sx={webStyle.modalStyle}>
              <h5>Please verify both mobile number and email</h5>
            </Box>
          </Modal>
          <Typography className={this.props.classes.heading}>
            {configJSON.ayqRegisterHeading}
          </Typography>
          <Formik
            initialValues={{
              studentName: "",
              email: "",
              mobileNumber: "",
              initialPassword: "",
              finalPassword: "",
            }}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
            validationSchema={this.registerSchema}
            data-testid="formik"
          >
            {(props) => (
              <form
                data-testid="submitSignup"
                onSubmit={props.handleSubmit}
                className={this.props.classes.form}
              >
                <Box className={this.props.classes.formBox}>
                  <p className={this.props.classes.formBoxParagraph}>
                    Student Profile
                  </p>
                  <Box className={this.props.classes.getOTPContainer}>
                    <TextField
                      inputProps={{ "data-testid": "name-input" }}
                      name="studentName"
                      label="Student's Name"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.studentName}
                      type="text"
                      className={this.props.classes.inputField}
                    />
                    {props.errors.studentName && props.touched.studentName && (
                      <Box id="feedback">{props.errors.studentName}</Box>
                    )}
                  </Box>
                  <Box className={this.props.classes.getOTPContainer}>
                    <Box>
                      <TextField
                        inputProps={{ "data-testid": "email-input" }}
                        name="email"
                        label="Email"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.email}
                        type="email"
                        className={this.props.classes.inputField}
                      />
                      {this.state.isOtpEmailVerified && (
                        <Button className={this.props.classes.verifiedStyles} data-testid="verified-tag">
                          <CheckCircle /> Verified
                        </Button>
                      )}
                      {!this.state.emailOTPToken && (
                        <Button
                          data-testid="get-email-otp-button"
                          onClick={() => this.sendEmailOTP(props.values.email)}
                          disabled={
                            props.errors.email ||
                            props.values.email.length === 0
                              ? true
                              : false
                          }
                          className={this.props.classes.getOTPButton}
                        >
                          GET OTP
                        </Button>
                      )}
                      {props.errors.email && props.touched.email && (
                        <Box id="feedback">{props.errors.email}</Box>
                      )}
                    </Box>
                    {this.state.isReadyForEmailVerification && (
                      <Box className={this.props.classes.verifyContainer}>
                        <input
                          data-testid="email-otp-input"
                          name="emailOtp"
                          value={this.state.emailOTPInput}
                          onChange={(event) =>
                            this.handleEmailChangeInput(event.target.value)
                          }
                          placeholder="Enter OTP"
                          required
                          className={this.props.classes.verifyInput}
                        />
                        <Button
                          data-testid="verify-email-otp-button"
                          onClick={() =>
                            this.verifyEmailOTP(this.state.emailOTPInput)
                          }
                          className={this.props.classes.verifyButton}
                        >
                          Verify
                        </Button>
                        {isOtpEmailError(props.values.email)}
                      </Box>
                    )}
                  </Box>
                  <Box className={this.props.classes.getOTPContainer}>
                    <Box>
                      <TextField
                        inputProps={{ "data-testid": "mobile-input" }}
                        name="mobileNumber"
                        label="Mobile Number"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.mobileNumber}
                        type="text"
                        className={this.props.classes.inputField}
                      />
                      {this.state.isOtpMobileVerified && (
                        <Button className={this.props.classes.verifiedStyles} data-testid="verified-tag-mobile">
                          <CheckCircle /> Verified
                        </Button>
                      )}
                      {!this.state.mobileOTPToken && (
                        <Button
                          data-testid="get-mobile-otp-button"
                          onClick={() =>
                            this.sendMobileOTP(props.values.mobileNumber)
                          }
                          className={this.props.classes.getOTPButton}
                          disabled={
                            props.errors.mobileNumber ||
                            props.values.mobileNumber.length === 0
                              ? true
                              : false
                          }
                        >
                          GET OTP
                        </Button>
                      )}
                      {props.errors.mobileNumber &&
                        props.touched.mobileNumber && (
                          <Box id="feedback">{props.errors.mobileNumber}</Box>
                        )}
                    </Box>
                    {this.state.isReadyForMobileVerification && (
                      <Box className={this.props.classes.verifyContainer}>
                        <input
                          data-testid="mobile-otp-input"
                          name="mobileOtp"
                          value={this.state.mobileOTPInput}
                          onChange={(event) =>
                            this.handleMobileChangeInput(event.target.value)
                          }
                          placeholder="Enter OTP"
                          className={this.props.classes.verifyInput}
                          required
                        />
                        <Button
                          data-testid="verify-mobile-otp-button"
                          onClick={() =>
                            this.verifyMobileOTP(this.state.mobileOTPInput)
                          }
                          className={this.props.classes.verifyButton}
                        >
                          Verify
                        </Button>
                        {isOtpMobileError(props.values.mobileNumber)}
                      </Box>
                    )}
                  </Box>
                  <Box className={this.props.classes.passwordContainer}>
                    <TextField
                      inputProps={{ "data-testid": "initialPassword-input" }}
                      name="initialPassword"
                      label="Password"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.initialPassword}
                      type={this.state.showPassword ? "text" : "password"}
                      className={this.props.classes.inputField}
                    />
                    <Box
                      data-testid="peekbtn"
                      className={this.props.classes.peekBtn}
                      onClick={this.peekInitial}
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </Box>
                    {props.errors.initialPassword &&
                      props.touched.initialPassword && (
                        <Box id="feedback">{props.errors.initialPassword}</Box>
                      )}
                  </Box>
                  <Box className={this.props.classes.passwordContainer}>
                    <TextField
                      inputProps={{ "data-testid": "confirm-password-input" }}
                      name="finalPassword"
                      label="Confirm Password"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.finalPassword}
                      type={
                        this.state.showConfirmPassword ? "text" : "password"
                      }
                      className={this.props.classes.inputField}
                    />
                    <Box
                      data-testid="peekbtn-confirm"
                      className={this.props.classes.peekBtn}
                      onClick={this.peekConfirm}
                    >
                      {this.state.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </Box>
                    {props.errors.finalPassword &&
                      props.touched.finalPassword && (
                        <Box id="feedback">{props.errors.finalPassword}</Box>
                      )}
                  </Box>
                </Box>
                <Box
                  className={this.props.classes.packageSelectedTermsContainer}
                >
                  <Box>
                    <Checkbox
                      data-testid="checkboxTerms"
                      inputProps={{ "aria-label": "controlled" }}
                      required
                    />{" "}
                    I agree to{" "}
                    <span
                      data-testid="terms-btn"
                      style={{ cursor: "pointer" }}
                      onClick={this.handleModalTerms}
                      className={this.props.classes.span}
                    >
                      Terms and Conditions
                    </span>
                  </Box>
                </Box>
                <Button
                  data-testid="register-button"
                  type="submit"
                  className={this.props.classes.registerBtn}
                >
                  Register Now
                </Button>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "2px solid #832890",
    boxShadow: 24,
    backgroundColor: "white",
    p: 4,
    borderRadius: "0.5em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
    maxHeight: "80%",
  },
};

export const ayqRegisterStyle = () =>
  createStyles({
    modalStyleSuccess: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      minWidth: 560,
      height: "max-content",
      padding: "4em 0",
      border: "2px solid #FF5C5C",
      backgroundColor: "white",
      outline: "none",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "1.5em",
      gap: "0.5em",
      "& button": {
        width: "200px",
        height: "60px",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        color: "white",
        marginTop: "2em",
        borderRadius: "0.6em",
      },
      "& h5": {
        fontSize: "1.7em",
        fontWeight: 500,
      },
      "& svg": {
        color: "#fe5e56",
        fontSize: "6rem",
      },
    },
    registrationContainer: {
      background: `url(${bgAYQ})`,
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      width: "100%",
      height: "max-content",
      "& .signup-form-register": {
        minHeight: "100vh",
      },
    },
    heading: { fontSize: "2em", color: "#fff", margin: "5rem 0 3.5rem 0" },
    goBackBtn: {
      position: "absolute",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
      cursor: "pointer",
      top: "20px",
      left: "20px",
      "& .back-icon": {
        borderRadius: "50%",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        marginRight: "0.3em",
        width: "24px",
        height: "24px",
        textAlign: "center",
      },
    },
    form: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "2em",
      width: "80%",
      margin: "3em 0",
      position: "relative",
      "& #feedback": {
        color: "red",
        fontWeight: 500,
        fontSize: "0.7rem",
        transition: "0.7s all ease",
      },
    },
    verifiedStyles: {
      position: "absolute",
      right: 0,
      top: 20,
      color: "#df5469",
    },
    passwordContainer: {
      width: "100%",
      position: "relative",
    },
    peekBtn: {
      position: "absolute",
      right: 0,
      top: 10,
      color: "rgba(67, 28, 137, 1)",
      cursor: "pointer",
    },
    passwordInfo: {
      fontSize: "10px",
      color: "#df5469",
    },
    btnSubmit: {
      width: "250px",
      height: "50px",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      color: "white",
      alignSelf: "flex-end",
    },
    formBox: {
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      borderRadius: "0.5em",
      padding: "4em",
      gap: "1.5em",
      width: "100%",
      transition: "0.7s all ease",
    },
    formBoxParagraph: {
      fontWeight: "bold",
      alignSelf: "flex-start",
    },
    inputField: {
      width: "100%",
      "& input": {
        width: "100%",
        height: "30px",
      },
    },
    formControl: {
      width: "100%",
    },
    formButton: {
      marginTop: "10px",
    },
    getOTPContainer: {
      width: "100%",
      position: "relative",
    },
    getOTPButton: {
      position: "absolute",
      right: 0,
      top: 20,
      color: "#df5469",
    },
    verifyContainer: {
      width: "100%",
      marginTop: "1em",
      position: "relative",
    },
    verifyInput: {
      height: "60px",
      width: "100%",
      border: "none",
      backgroundColor: "#fff2ea",
      zIndex: 0,
      paddingLeft: "1em",
    },
    verifyButton: {
      position: "absolute",
      width: "100px",
      height: "60px",
      top: 0,
      right: 0,
      borderRadius: 0,
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      color: "white",
    },
    resendOTPContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    resendOTPText: {
      color: "rgba(254, 57, 85, 1)",
    },
    resendOTPButton: {
      color: "rgba(254, 57, 85, 1)",
      position: "relative",
      width: "105px",
      textAlign: "right",
      height: "20px",
      borderRadius: 0,
      background: "none",
    },
    packageSelectedTermsContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      color: "white",
    },
    span: {
      fontWeight: "bold",
    },
    registerBtn: {
      width: "250px",
      height: "50px",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      color: "white",
    },
  });

export default withStyles(ayqRegisterStyle)(AYQRegister);
// Customizable Area End
