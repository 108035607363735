import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
type LeaderboardType = {
  id: string;
  type: string;
  attributes: {
    id: number;
    badge_type: {
      id: number;
      name: string;
      created_at: string;
      updated_at: string;
    };
    name: string;
    description: string;
    image: string;
    created_at: string;
    updated_at: string;
  };
};

import { Bronze, Diamond, gold, Platinum, silver, Titanium } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  leaderboard: LeaderboardType[];
  loading: boolean;
  badgesData: {
    type: string;
    bgcolor: string;
    image: string;
  }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AYQLeaderboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLeaderboardsMessageId = "";
  breadcrumb = [
    {
      label: "Profile",
      link: "/dashboard/ask-your-query/profile",
    },
    {
      label: "Badges",
      link: "/dashboard/ask-your-query/badges",
    },
    {
      label: "Leaderboards",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      leaderboard: [],
      loading: false,
      badgesData: [
        {
          image: Bronze,
          type: "Bronze",
          bgcolor: "#D38844",
        },
        {
          image: silver,
          type: "Silver",
          bgcolor: "#c0c0c0",
        },
        {
          image: gold,
          type: "Gold",
          bgcolor: "#E6B451",
        },
        {
          image: Platinum,
          type: "Platinum",
          bgcolor: "#007E78",
        },
        {
          image: Diamond,
          type: "Diamond",
          bgcolor: "#D17DE3",
        },
        {
          image: Titanium,
          type: "Titanium",
          bgcolor: "#A3C5E3",
        },
      ],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getLeaderboardsMessageId) {
      if (response.data) {
        this.setState({
          leaderboard: response.data,
        });
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getLeaderboards();
    const propPassingAALC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingAALC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingAALC);
  };

  determineColor = (color: string) => {
    return { color };
  };

  // Customizable Area Start
  getLeaderboards = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    const getLeaderboardsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    this.getLeaderboardsMessageId = getLeaderboardsMessage.messageId;

    getLeaderboardsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getLeaderboardsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getLeaderboardsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/profile_settings/leaderboard`
    );

    runEngine.sendMessage(getLeaderboardsMessage.id, getLeaderboardsMessage);
  };
  // Customizable Area End
}
