import React from "react";

import {
  // Customizable Area Start
  Box,
  createStyles,
  withStyles,
  Typography,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Formik } from "formik";
import { calculateBg } from "./assets";
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area End

import CalculateController, {
  Props,
  configJSON,
} from "./CalculateController.web";

export class Calculate extends CalculateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box position={"relative"}>
        <Box height={250}>
          <img
            src={calculateBg}
            alt="bg-calculator"
            className={this.props.classes.calculateBg}
          />
          <Typography
            variant="h4"
            className={this.props.classes.calculateHeader}
          >
            {configJSON.marksTypo}
          </Typography>
        </Box>
        <Box className={this.props.classes.formikBox}>
          <Formik
            initialValues={{ eamcetInput: undefined, ipeInput: undefined }}
            onSubmit={(values) => {
              this.getCalculationSubmit(values);
            }}
            validationSchema={this.calculatorSchema}
            data-test-id="formik"
          >
            {(props) => (
              <form
                onSubmit={props.handleSubmit}
                data-testid="submit-form-action"
                className={this.props.classes.formBlock}
              >
                <Box className={this.props.classes.inputStyles}>
                  <Typography>EAMCET Exam Marks (out of 160)</Typography>
                  <Box position={"relative"}>
                    <input
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.eamcetInput}
                      data-testid="eamcetInput"
                      name="eamcetInput"
                    />
                    {props.errors.eamcetInput && props.touched.eamcetInput && (
                      <Box id="feedback">{props.errors.eamcetInput}</Box>
                    )}
                  </Box>
                </Box>
                <Box className={this.props.classes.inputStyles}>
                  <Typography>IPE M+P+C/B+Z+P+C Marks (out of 600)</Typography>
                  <Box position={"relative"}>
                    <input
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.ipeInput}
                      data-testid="ipeInput"
                      name="ipeInput"
                    />
                    {props.errors.ipeInput && props.touched.ipeInput && (
                      <Box id="feedback">{props.errors.ipeInput}</Box>
                    )}
                  </Box>
                </Box>
                <Box className={this.props.classes.inputStyles}>
                  <Typography>Weighted Marks (out of 160)</Typography>
                  <Box className="total-mark">
                    {this.state.loading ? (
                      <CircularProgress
                        color="inherit"
                        className={this.props.classes.progress}
                      />
                    ) : (
                      this.state.totalMark
                    )}
                  </Box>
                </Box>
                <Button data-testid="submit-btn" type="submit">
                  Calculate
                </Button>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyleCalculate = () =>
  createStyles({
    calculateBg: {
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      height: "250px",
      objectFit: "cover",
      filter: "grayscale(1) brightness(0.3)",
      zIndex: -1,
    },
    calculateHeader: {
      color: "white",
      width: "100%",
      height: "250px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 500,
      fontSize: "2.3rem",
    },
    formikBox: {
      height: "500px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    inputStyles: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "1000px",
      "& p": {
        fontWeight: 500,
        fontSize: "1.7em",
      },
      "& input": {
        width: "230px",
        height: "40px",
        borderRadius: "0.2em",
        border: "1px solid black",
        paddingLeft: "1em",
      },
      "& #feedback": {
        fontSize: "0.8em",
        color: "red",
        position: "absolute",
        bottom: "-20px",
      },
    },
    formBlock: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "3em",
      "& button": {
        background:
          "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
        width: "300px",
        border: "none",
        borderRadius: "0.6em",
        padding: "0.6em 2em",
        color: "white",
        fontWeight: 500,
        fontSize: "1.5em",
      },
      "& .total-mark": {
        width: "230px",
        height: "40px",
        borderRadius: "0.2em",
        border: "none",
        paddingLeft: "1em",
        background: "#aaa",
        display: "flex",
        alignItems: "center",
        color: "white",
        fontWeight: 500,
      },
    },
    progress: {
      width: "30px!important",
    },
  });
export default withStyles(webStyleCalculate)(Calculate);
// Customizable Area End
