import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Table,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Banner from "../../../components/src/Banner.web";
import {
  courseFinderGlobalStyle,
  searchInputStyle,
} from "./CourseFinderExamination.web";
import { searchImage, arrowCircle } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import CourseFinderCounsellingController, {
  Props,
  configJSON,
} from "./CourseFinderCounsellingController.web";

class CourseFinderCounselling extends CourseFinderCounsellingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { loading, currentPage, councellingData } = this.state;
    const startIndex = currentPage * this.dataPerPage;
    const endIndex = Math.min(
      startIndex + this.dataPerPage,
      councellingData.length
    );

    const currentPageData = councellingData.slice(startIndex, endIndex);

    const pagesArray = Array.from(
      { length: this.state.numberOfPages },
      (_, index) => index + 1
    );

    if (loading) {
      return <Loader loading={loading} />;
    }

    return (
      // Customizable Area Start
      <Box>
        <Banner title="Counselling Details" />
        <Box>
          <Box className={classes.searchContainer}>
            <Box position={"relative"}>
              <img
                className={classes.searchImageStyle}
                alt="search-image"
                src={searchImage}
              />
              <input className={classes.searchStyle} />
            </Box>
          </Box>
          <Box>
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{configJSON.counsellingN}</TableCell>
                    <TableCell>Notification</TableCell>
                    <TableCell>Colleges Linked</TableCell>
                    <TableCell>Website</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageData.map((councelling) => {
                    return (
                      <TableRow>
                        <TableCell data-test-id="counselling-name">
                          {councelling.attributes.couns_name}
                        </TableCell>
                        <TableCell>https://notification-sample.com</TableCell>
                        <TableCell>Colleges Linked</TableCell>
                        <TableCell>
                          <a
                            href={councelling.attributes.website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {councelling.attributes.website}
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.paginationContainerStyle}>
              <Box className={classes.buttonPaginationStyle}>
                {pagesArray.map((pageNumber) => (
                  <button
                    onClick={() => this.changePage(pageNumber - 1)}
                    className={
                      this.state.currentPage === pageNumber - 1
                        ? "active-page"
                        : ""
                    }
                    key={pageNumber}
                  >
                    {pageNumber}
                  </button>
                ))}
                <img
                  src={arrowCircle}
                  data-test-id="change-page-btn"
                  alt="arrow-key"
                  className={classes.arrowCircle}
                  onClick={() => this.changePage(currentPage + 1)}
                />
                <Typography className={classes.paginationLabel}>
                  1-{this.state.numberOfPages}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleCourseFinderCounselling = () =>
  createStyles({
    ...searchInputStyle,
    ...courseFinderGlobalStyle,
  });
export default withStyles(webStyleCourseFinderCounselling)(
  CourseFinderCounselling
);
export { CourseFinderCounselling };
// Customizable Area End
