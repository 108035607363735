import React from "react";

import {
  Box,
  // Customizable Area Start
  createStyles,
  withStyles,
  Typography,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import * as FooterComponent from "../../../components/src/Footer.web";
type Blog = {
  date: string;
  title: string;
  description: string;
  subjects: string[];
  image: string;
};

import Header from "../../../components/src/Header.web";
import {
  myranklogo,
  path,
  pcIcon,
  practical,
  academic,
  pcimage,
  taskImage,
  bitcoinTrophy,
  profile,
  classroom,
} from "./assets";

import LandingPageController, { Props } from "./LandingPageController.web";
import CarouselAdvertisement from "../../CustomAdvertisements/src/CarouselAdvertisement.web";
// Customizable Area End

export class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  TalentCategory = Array.from(Array(3).keys()).map((talent) => {
    return (
      <Box key={`talent-${talent}`} className="talent-category">
        <div>
          <img src={pcIcon} alt="pc-icon" />
          <p>Post a Job</p>
        </div>
        <p>
          It's free and easy to post a job. Simply fill in a title, description
          and budget and cocmpetitive bids come within minutes.
        </p>
        <Button>Learn More {">"}</Button>
      </Box>
    );
  });

  GuidanceCards = Array.from(Array(4).keys()).map(() => {
    return (
      <Box className="guidance-cards">
        <img src={practical} alt="practical" />
        <Typography variant="h5">NEET UG</Typography>
        <p>Learn More</p>
        <p>
          Keep track of all MPC Counsellings like JOSAA, State Level, VIT,
          BITSAT
        </p>
      </Box>
    );
  });

  ExamNotifications = Array.from(Array(4).keys()).map((examCard) => {
    return (
      <Box key={`${examCard}-examcard`} className="examNotification-card">
        <img src={taskImage} alt="taskimage" />
        <Box>
          <p>Exam Notifications</p>
          <Button>Learn more {`>`}</Button>
        </Box>
        <p>
          Be updated on latest information in corresponding examinations. Get
          each and every Updated information about the Examinations you want.
        </p>
      </Box>
    );
  });

  assetArray: string[] = [
    bitcoinTrophy,
    bitcoinTrophy,
    bitcoinTrophy,
    bitcoinTrophy,
  ];

  blog: Blog[] = [
    {
      date: "November 16,2022",
      title: "Blog 1",
      description:
        "Be updated on latest information in corresponding examinations. Get each and every Updated",
      subjects: ["Physics", "Units"],
      image: classroom,
    },
    {
      date: "September 24,2022",
      title: "Blog 2",
      description:
        "Be updated on latest information in corresponding examinations. Get each and every Updated information about the.",
      subjects: ["Physics", "UI Units"],
      image: classroom,
    },
    {
      date: "March 12,2022",
      title: "Blog 3",
      description:
        "Be updated on latest information in corresponding examinations. Get each and every Updated information about the Examinations you want.",
      subjects: ["Programming", "Research", "Developments"],
      image: classroom,
    },
  ];

  DataCard = this.assetArray.map((examCard) => {
    return (
      <Box className="datacard">
        <Box>
          <img src={examCard} alt={examCard} />
          <p>456</p>
        </Box>
        <p>Students Enrolled</p>
      </Box>
    );
  });
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box
        data-test-id="landingpage"
        className={this.props.classes.mainWrapper}
      >
        <Header image={myranklogo} navigation={this.props.navigation} />
        {/* HERO SECTION */}
        <CarouselAdvertisement
          id="carousel"
          navigation={this.props.navigation}
        />
        {/* TALENT CATEGORY */}
        <Box
          data-test-id="talentcategory"
          className={this.props.classes.browsetalentLanding}
        >
          <p>Browse Talent By Category</p>
          <Box className="talent-categories">{this.TalentCategory}</Box>
        </Box>
        {/* GET STARTED */}
        <Box
          data-test-id="getstarted"
          className={this.props.classes.getstartedLanding}
        >
          <h3>Get started with 3 simple steps</h3>
          <Box className="getstarted-first">
            <Box>
              <p>01</p>
              <p>Login/Register</p>
            </Box>
            <Box>
              <p>02</p>
              <p>Video Classes</p>
            </Box>
            <Box>
              <p>03</p>
              <p>Ask Your Query</p>
            </Box>
          </Box>
          <Box className="academic">
            <p>Academic Understanding</p>
            <Box className="academic-box">
              <img src={academic} alt="academic" />
              <Box>
                <h3>
                  A Brief Summary that gives student an idea of what a
                  composition is about.
                </h3>
                <p>
                  It provides an overview of the Chapter or Main Points and
                  other Important Topics of the Chapter. It is a Quick recap of
                  the topic which gives you an Edge while Revision. It is a "2
                  minutes maggi noodles" before the exam.
                </p>
                <Button>Know More</Button>
              </Box>
            </Box>
          </Box>
          <Box className="practical">
            <p>Practical Understanding</p>
            <Box className="getstarted-second">
              <Box>
                <p>01</p>
                <p>Grand Test</p>
              </Box>
              <Box>
                <p>02</p>
                <p>Practice Questions</p>
              </Box>
              <Box>
                <p>03</p>
                <p>Analysis</p>
              </Box>
            </Box>
            <Box className="practical-box">
              <img src={practical} alt="practical" />
              <Box>
                <h3>
                  A Brief Summary that gives student an idea of what a
                  composition is about.
                </h3>
                <p>
                  It provides an overview of the Chapter or Main Points and
                  other Important Topics of the Chapter. It is a Quick recap of
                  the topic which gives you an Edge while Revision. It is a "2
                  minutes maggi noodles" before the exam.
                </p>
                <Button>Know More</Button>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* ADMISSION GUIDANCE */}
        <Box
          data-test-id="admission"
          className={this.props.classes.admissionguidanceLanding}
        >
          <h3>Admission Guidance</h3>
          <Box>
            <p>
              On MyRank, we have top instructors available and you can contact
              us for more details.
            </p>
            <p>We offer the knowledge and abilities.</p>
          </Box>
          <Box className="guidance-container">{this.GuidanceCards}</Box>
        </Box>
        {/* GUIDANCE FEATURES */}
        <Box
          data-test-id="guidance"
          className={this.props.classes.guidancefeaturesLanding}
        >
          <Box className="head-guidance">
            <h3>Guidance Features</h3>
            <p>(Before Exam)</p>
          </Box>
          <Box>
            <img
              className={"image-main-guidance"}
              src={pcimage}
              alt="pc-image"
            />
          </Box>
          <Box className="exam-wrapper">{this.ExamNotifications}</Box>
        </Box>
        {/* Web Data */}
        <Box
          data-test-id="webdata"
          className={this.props.classes.webdataLanding}
        >
          <Typography variant="h4">The numbers speak for themselves</Typography>
          <Box>
            <Typography variant="body1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              scelerisque convallis mi. Nam consectetur euismod tellus, id
              bibendum est commodo ut.
            </Typography>
            <Typography variant="body1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
          </Box>
          <Box className="dataCardContainer">{this.DataCard}</Box>
        </Box>
        {/* TESTIMONIALS */}
        <Box
          data-test-id="testimonials"
          className={this.props.classes.testimonialsLanding}
        >
          <h2>What Our Customers Say...</h2>
          <Box className="customer-saying">
            <h3>
              It is a very useful program for the IIT aiming students, I
              seriously feel once the test papers given
            </h3>
            <Box className="customer-detail">
              <img src={profile} alt={profile} />
              <p>Lalitha Ahlada</p>
            </Box>
          </Box>
        </Box>
        {/* RECENT BLOGS */}
        <Box
          data-test-id="recentblogs"
          className={this.props.classes.recentblogsLanding}
        >
          <Box className="blog-header">
            <Typography variant={"h5"}>Our recent blogs</Typography>
            <Button>View all {">"}</Button>
          </Box>
          <Box className="blogs-container">
            <Box className="blog-1-2">
              <Box>
                <img src={this.blog[0].image} alt="image-blog" />
                <Box sx={webStyleBlogs.blog12Style}>
                  <p style={webStyleBlogs.dateStyle}>{this.blog[0].date}</p>
                  <Typography variant={"h6"}>{this.blog[0].title}</Typography>
                  <p>{this.blog[0].description}</p>
                  <Box sx={webStyleBlogs.subjectsStyle}>
                    {this.blog[0].subjects.map((subject) => {
                      return (
                        <p style={webStyleBlogs.subjectStyleFunc(subject)}>
                          {subject}
                        </p>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
              <Box>
                <img src={this.blog[0].image} alt="image-blog" />
                <Box sx={webStyleBlogs.blog12Style}>
                  <p style={webStyleBlogs.dateStyle}>{this.blog[1].date}</p>
                  <Typography variant={"h6"}>{this.blog[1].title}</Typography>
                  <p>{this.blog[1].description}</p>
                  <Box sx={webStyleBlogs.subjectsStyle}>
                    {this.blog[1].subjects.map((subject) => {
                      return (
                        <p style={webStyleBlogs.subjectStyleFunc(subject)}>
                          {subject}
                        </p>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="blog-3">
              <img src={this.blog[0].image} alt="image-blog" />
              <Box>
                <p style={webStyleBlogs.dateStyle}>{this.blog[2].date}</p>
                <Typography variant={"h5"}>{this.blog[2].title}</Typography>
                <p>{this.blog[2].description}</p>
                <Box sx={webStyleBlogs.subjectsStyle}>
                  {this.blog[2].subjects.map((subject) => {
                    return (
                      <p style={webStyleBlogs.subjectStyleFunc(subject)}>
                        {subject}
                      </p>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* FOOTER */}
        <Box>
          <Box style={webStyleFooter.pathStyle} />
          <Box
            data-test-id="footer"
            className={this.props.classes.footerLanding}
          >
            <Box className="footer-section">
              <div>
                <img src={myranklogo} alt="logomainfooter" />
                <p>
                  Top learning experiences that create more talent in the world.
                </p>
              </div>
              <div>
                <h4>Product</h4>
                <ul>
                  <li onClick={this.navigateToAYQ} data-test-id="to-ayq">
                    AYQ
                  </li>
                  <li>FAQ</li>
                  <li>Pricing</li>
                  <li>Articles</li>
                </ul>
              </div>
              <div>
                <h4>Company</h4>
                <ul>
                  <li>About Us</li>
                  <li
                    data-test-id="notifications-btn"
                    onClick={() => this.handleNotificationClick()}
                  >
                    Notifications
                  </li>
                  <li>Login</li>
                  <li>Blog</li>
                </ul>
              </div>
              <div>
                <h4>Social</h4>
                <ul>
                  <li>Twitter</li>
                  <li>LinkedIn</li>
                  <li>Instagram</li>
                  <li>Facebook</li>
                </ul>
              </div>
              <div>
                <h4>Legal</h4>
                <ul>
                  <li>Terms</li>
                  <li>Privacy</li>
                  <li>Cookies</li>
                  <li>Contact</li>
                </ul>
              </div>
            </Box>
            <FooterComponent.default />
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyleBlogs = {
  containerStyle: {
    height: "100%",
  },
  dateStyle: {
    color: "#9C91B3",
    fontWeight: 500,
  },
  subjectsStyle: {
    display: "flex",
    gap: "1em",
  },
  subjectStyleFunc: (subject: string) => {
    const objectBackground: { background: string; color: string } = {
      background: "",
      color: "",
    };

    if (subject === "Units") {
      objectBackground.background = "#EBFCF2";
      objectBackground.color = "#9CC0B2";
    }

    if (subject === "UI Units") {
      objectBackground.background = "#EDFBFF";
      objectBackground.color = "#A2C3CD";
    }

    if (subject === "Programming") {
      objectBackground.background = "#F7F1FC";
      objectBackground.color = "#A99AC1";
    }

    if (subject === "Research") {
      objectBackground.background = "#F0F3FB";
      objectBackground.color = "#9EA1CC";
    }

    if (subject === "Developments" || subject === "Physics") {
      objectBackground.background = "#FDEFFA";
      objectBackground.color = "#BF96B0";
    }

    return {
      ...objectBackground,
      border: "none",
      borderRadius: "3em",
      padding: "0.4em",
      fontSize: "13px",
      fontWeight: 700,
    };
  },
  blog12Style: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
};

const webStyleFooter = {
  pathStyle: {
    background: `url(${path})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100px",
  },
};

const webStyle = () =>
  createStyles({
    mainWrapper: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
      width: "100%",
    },
    pathStyle: {
      background: `url(${path})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100px",
      transform: "rotate(180deg)",
      backgroundColor: "#E5F4FF",
    },
    browsetalentLanding: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      backgroundColor: "#e5f4ff",
      height: "max-content",
      padding: "3em 1em 8em 1em",

      "& > p": {
        color: "#520082",
        fontWeight: 600,
        fontSize: "1.5em",
        alignSelf: "center",
      },

      "& .talent-categories": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        height: "max-content",
        gap: "1em",
        marginTop: "3em",
      },

      "& .talent-category": {
        color: "rgba(66, 11, 126, 1)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexWrap: "wrap",
        width: "300px",
        height: "300px",
        borderRadius: "1em",
        border: "1px solid rgba(66, 11, 126, 1)",
        padding: "2em",
        transition: "0.3s",

        "& > div": {
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: "1.3em",
          gap: "1em",

          "& img": {
            width: "50px",
            border: "none",
            borderRadius: "0.3em",
            padding: "0.5em",
            backgroundColor: "#c3b7df",
          },
        },

        "& button": {
          color: "rgba(66, 11, 126, 1)",
        },

        "&:hover": {
          color: "white",
          background:
            "linear-gradient(0deg, rgba(66, 11, 126, 1) 0%, rgba(129, 39, 143, 1) 100%)",
          border: "none",

          "& button": {
            color: "white",
          },

          "& img": {
            width: "50px",
            border: "none",
            borderRadius: "0.3em",
            padding: "0.5em",
            backgroundColor: "white",
          },
        },
      },
    },
    getstartedLanding: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "2em",
      width: "100%",
      height: "100%",
      backgroundColor: "#eae0ff",

      "& > h3": {
        alignSelf: "flex-start",
        fontSize: "2em",
        fontWeight: 400,
        marginBottom: "1em",
      },

      "& .academic, .practical": {
        margin: "3em 0",

        "& > p": {
          fontSize: "1.3em",
          color: "#6941c6",
        },
      },

      "& .getstarted-first, .getstarted-second": {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
        userSelect: "none",

        "& div": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          transition: "0.3s",

          "& > p": {
            fontSize: "3em",
          },

          "& > p + p": {
            fontSize: "1.5em",
            lineHeight: "0.7em",
          },

          "&:hover": {
            color: "#7f56d9",
          },
        },

        "& div::before": {
          content: '""',
          display: "block",
          position: "absolute",
          bottom: "-20px",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "110px",
          height: "5px",
          backgroundColor: "#7f56d9",
          transition: "0.3s",
          opacity: 0,
        },

        "& div:hover::before": {
          opacity: 1,
        },
      },

      "& .academic-box, .practical-box": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: "3em",

        "& img": {
          width: "370px",
          height: "363px",
        },

        "& div": {
          margin: "2em 3em",
          width: "50%",

          "& h3": {
            fontSize: "2em",
            fontWeight: 400,
          },

          "& p": {
            margin: "2em 0",
            fontSize: "1.2em",
          },

          "& button": {
            border: "none",
            borderRadius: "0.7em",
            backgroundColor: "#fe6854",
            color: "white",
            padding: "1em 2em",
          },
        },
      },
    },
    admissionguidanceLanding: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      width: "100%",
      height: "100%",
      backgroundColor: "#ffdacf",
      padding: "3em 0",

      "& h3": {
        color: "#520082",
        fontSize: "2em",
        fontWeight: 400,
      },

      "& > div": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "#667085",
        padding: "2em 0",
      },

      "& .guidance-container": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "2em",
        width: "100%",
        height: "100%",

        "& .guidance-cards": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          border: "none",
          borderRadius: "0.4em",
          padding: "1em 2.5em",
          backgroundColor: "white",
          height: "293px",
          width: "276px",
          transition: "0.3s",

          "& img": {
            width: "70px",
            borderRadius: "50%",
          },

          "& p": {
            textAlign: "center",
          },

          "& > h5": {
            color: "rgb(19, 19, 19)",
          },

          "& > p ~ p": {
            color: "rgb(49, 48, 48)",
          },

          "&:hover": {
            background:
              "linear-gradient(90deg, rgba(255, 57, 86, 1) 0%, rgba(255, 108, 83, 1) 100%)",

            "& > p": {
              color: "rgb(19, 19, 19)",
            },

            "& > p ~ p": {
              color: "white",
            },
          },
        },
      },
    },
    guidancefeaturesLanding: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      padding: "3em 0",

      "& .exam-wrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "2em",
        width: "80%",
        marginTop: "3em",
      },

      "& .head-guidance": {
        textAlign: "center",
        color: "#6941c6",
        fontSize: "1.5em",
      },

      "& .image-main-guidance": {
        width: "100%",
      },

      "& .examNotification-card": {
        display: "flex",
        flexDirection: "column",
        width: "489px",
        height: "242px",
        justifyContent: "space-around",
        alignContent: "flex-start",
        border: "none",
        backgroundColor: "#f7f3ff",
        borderRadius: "1em",
        padding: "2em",
        transition: "all 0.3s ease",

        "& img": {
          width: "58px",
        },

        "& > div": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "#7f56d9",
          fontWeight: "bold",
          fontSize: "1.3em",

          "& button": {
            textTransform: "none",
            color: "#7f56d9",
            fontWeight: "bold",
            fontSize: "0.65em",
          },
        },

        "&:hover": {
          border: "2px solid gray",
          backgroundColor: "#e6dbff",
        },
      },
    },
    webdataLanding: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      backgroundColor: "#61599e",
      height: "max-content",
      padding: "5em 1em",

      "& h4": {
        color: "white",
      },

      "& > div": {
        color: "rgb(185, 184, 184)",
        textAlign: "center",
        padding: "1em 5em",
      },

      "& .dataCardContainer": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        gap: "2em",

        "& .datacard": {
          width: "254px",
          height: "150px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          transition: "all 0.3s ease",

          "&:hover": {
            backgroundColor: "#ff9c27",
          },

          "& img": {
            width: "100px",
            height: "100px",
            objectFit: "cover",
          },

          "& div": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            "& p": {
              color: "white",
              fontWeight: "bold",
              fontSize: "1.3em",
            },
          },

          "& p": {
            color: "white",
          },
        },
      },
    },
    testimonialsLanding: {
      backgroundColor: "#d1e6ff",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      padding: "5em",

      "& h2": {
        fontSize: "2.5em",
        color: "rgb(72, 131, 255)",
        fontWeight: 500,
      },

      "& h3": {
        fontSize: "2em",
        fontWeight: 400,
        width: "70%",
        textAlign: "center",
        alignSelf: "center",
      },

      "& img": {
        width: "70px",
        height: "70px",
      },

      "& p": {
        fontSize: "1.2em",
        color: "black",
      },

      "& .customer-saying": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "3em",
      },

      "& .customer-detail": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "1.5em",
      },
    },
    recentblogsLanding: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "2em 10em",

      "& .blog-1-2": {
        display: "flex",
        flexDirection: "column",
        gap: "2em",
        width: "47%",

        "& > div": {
          display: "flex",
          height: "200px",
          gap: "1.5em",
        },

        "& img": {
          width: "320px",
          height: "200px",
        },
      },

      "& .blog-3": {
        width: "47%",
        height: "430px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",

        "& > div": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          height: "100%",
          paddingTop: "1em",
        },

        "& img": {
          width: "100%",
          height: "241px",
          objectFit: "cover",
          objectPosition: "center",
        },
      },

      ".blog-header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "& button": {
          color: "#ff4452",
        },
      },

      "& .blogs-container": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "2em",
        padding: "3em 0",
      },
    },
    footerLanding: {
      backgroundColor: "#520082",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      color: "white",
      height: "100%",

      "& .footer-section": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        alignContent: "center",
        height: "90%",

        "& ul": {
          height: "150px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: "1em",
        },

        "& img": {
          width: "200px",
        },

        "& li": {
          listStyleType: "none",
          cursor: "pointer",
        },

        "& h4": {
          color: "#98a2b3",
        },
      },

      "& .footer-bottom": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 3em",
        height: "10%",

        "& div": {
          display: "flex",
          gap: "1.5em",
        },
      },
    },
  });

export default withStyles(webStyle)(LandingPage);
// Customizable Area End
