import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeIndex: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UsageStatsMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPracticeTestStatsPackageMessageId = "";
  getPracticeTestStatsSubjectMessageId = "";
  getPracticeTestStatsChapterMessageId = "";
  getPracticeTestPackagesMessageId = "";
  getPracticeTestChaptersMessageId = "";
  getPracticeTestSubjectsMessageId = "";
  buttons = [
    { link: "synopsis", name: "Synopsis" },
    { link: "weekly-test", name: "Weekly Test" },
    { link: "grand-test", name: "Grand Test" },
    { link: "practice-test", name: "Practice Test" },
    { link: "live-class", name: "Live Classes" },
    { link: "recorded-class", name: "Recorded Classes" },
    { link: "question-bank", name: "Question Bank" },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeIndex: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // web events
  // Customizable Area Start
  navigateTo = (activeIndex: number) => {
    this.setState(
      {
        activeIndex,
      },
      () => {
        if (activeIndex === 0) {
          this.props.navigation.history.push(
            `/dashboard-guidance/usage-stats/synopsis`
          );
        }

        if (activeIndex === 1) {
          this.props.navigation.history.push(
            `/dashboard-guidance/usage-stats/weekly-test`
          );
        }

        if (activeIndex === 2) {
          this.props.navigation.history.push(
            `/dashboard-guidance/usage-stats/grand-test`
          );
        }

        if (activeIndex === 3) {
          this.props.navigation.history.push(
            `/dashboard-guidance/usage-stats/practice-test`
          );
        }

        if (activeIndex === 4) {
          this.props.navigation.history.push(
            `/dashboard-guidance/usage-stats/live-class`
          );
        }

        if (activeIndex === 5) {
          this.props.navigation.history.push(
            `/dashboard-guidance/usage-stats/recorded-class`
          );
        }

        if (activeIndex === 6) {
          this.props.navigation.history.push(
            `/dashboard-guidance/usage-stats/question-bank`
          );
        }
      }
    );
  };

  determineIfActiveLink = (linkInfo: string): string => {
    const pathLink = this.props.navigation.history.location.pathname;
    const searchParams = this.props.navigation.history.location.search;
    const totalPath = pathLink + searchParams;

    if (totalPath.includes(linkInfo)) {
      return this.props.classes.active;
    }

    return "";
  };
  // Customizable Area End
}
