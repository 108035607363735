Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.postAnswersucessMsg = "Your answer posted succesfully"
exports.Done = "Done"
exports.Cancel = "Cancel"
exports.FilterTitle = "Filter By"
exports.userAyqQuestionEndPoint = "/bx_block_communityforum/ayq_questions/user_ayq_question"
exports.postAnswer = "Post Your Answer"
exports.yourAnswer = "Your Answer"
exports.ayqGetAnswerbyID = "/bx_block_communityforum/ayq_answers?ayq_question_id={ayqQueID}"
exports.all = "All";
exports.que = "Que";
exports.ans = "Ans";
exports.userProfileDetailsEndPoint = "/bx_block_communityforum/ayq_questions/visit_user_profile?account_id={accountID}"
exports.userProfile = "User Profile"
exports.modalMsg = "Your Query added Successfully"
exports.goback = "Go Back"
exports.btnLabel = "Ask your own Question"
exports.nottheAnswerlabel  = "Not the answer you are looking for ?"
exports.alreadyanswerLabel = "This Question already has answers here"
exports.ayqReviewEndPoint = "/bx_block_communityforum/ayq_questions/review_question?question={question}&tags={tag}"
exports.createayqQuestionEndPoint = "/bx_block_communityforum/ayq_questions"
exports.submitLabel= "Submit"
exports.tagLabel = "Tags";
exports.tagbelowLabel = "Add up to 3 tags to describe what your question is about. Start typing to see suggestions.";
exports.tagPlaceholder = "Enter Tags";
exports.subjectLabel = "Subject Name";
exports.subjectplaceholderLabel = "Enter Subject Name";
exports.TopicLabel = "Topic Name";
exports.topicPlaceHolderName = "Enter Topic Name";
exports.describeQuerylabel = "Describe Your Query";
exports.QueryPlaceholderLabel = "Enter Your Query";
exports.reviewQuestionlabel = "Review Question";
exports.reviewQuestionbelowlabel = "If there's any previously answers available in Myrank it will be shown here";
exports.questionlabel = "Is there any previous answer available to your Question ?";
exports.newQueryLabel = "New Query"
exports.askYourQuerylabel = "Ask Your Query"
exports.Ttop= "top";
exports.Tweek = "week";
exports.Tmonth = "month"
exports.addyourAnswerEndpoint = "/bx_block_communityforum/ayq_answers"
exports.postCommentBtn = "Post your answer"
exports.enterYourQuery = "Enter your Query"
exports.yourAnswerLabel = "Your Answer"
exports.ayqAnswerLikeEndPoint ="/bx_block_communityforum/ayq_questions/create_ayq_like?ayq_answer_id={ayqAnswerID}&status={status}"
exports.answerCommentEndPoint = "/bx_block_communityforum/ayq_answers/{id}/comment?comment={comment}"
exports.placeHolderComment = "   Comment here"
exports.answeredBy = "Answered By"
exports.ansNotFound= "Answer not Found"
exports.getAllQuestionEndPoint = "bx_block_communityforum/ayq_answers?ayq_question_id={questionID}"
exports.questionTagsEndPoint = "/bx_block_communityforum/ayq_questions/search_by_tag?tag={tags}"
exports.ayqQuestionLikeEndPoint = "/bx_block_communityforum/ayq_questions/create_ayq_like?ayq_question_id={ayqQuesID}&status={status}"
exports.tagsLabel = "Questions Tagged (Measure)"
exports.questionTags = "Tag Questions"
exports.noDatafound = "No Data Found"
exports.searchLabel = "Search"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CommunityForum";
exports.labelBodyText = "CommunityForum Body";
exports.homelabel = "Home";
exports.headerAskQuestion = "Ask Question"
exports.btnExampleTitle = "CLICK ME";
exports.Top = "Top";
exports.Week = "Week";
exports.Month = "Month";
exports.ayqQuestionEndPoint = "/bx_block_communityforum/ayq_questions?data_by={Type}&subject_name={subjectName}"
exports.Token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NzU0LCJleHAiOjE3MDAyMjA0ODQsInRva2VuX3R5cGUiOiJsb2dpbiJ9.2mUwSId0-6w9nv6BlDj-_FS6iIKglYbgfKjsvWV1y4Tg9uJXm6UYqXDYztkusjdnckPbfXB4hR8N9XHW7KQC5w"
exports.uuid = "7e1d59ff-99b1-488d-a6bd-426484d90eb3";
exports.Answer = "Answer"
// Customizable Area End