import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { styles } from "./styles";
import storage from "../../../framework/src/StorageProvider.web";

type ApiData = {
  data: TopicQuestion[];
  meta: Test;
};

interface Option {
  id: string;
  option: string;
}

export interface TestSubject {
  id: number;
  gt_code: string;
  subject_name: string;
  subject_questions: number;
  order_preference: number;
  created_by: string;
  remarks: string;
  status: string;
  grand_test_id: number;
  created_at: string;
  updated_at: string;
}

interface GrandTestMeta {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  practice_test: string;
  avail_pt: string;
  date_pt: string;
  class_zone: string;
  avail_cz: string;
  date_cz: string;
  question_bank: string;
  avail_qb: string;
  date_qb: string;
  created_by: string;
  remarks: string;
  topic_db: string;
  qb_id: null | string;
  qb_db: string;
  id_pt: string;
  id_cz: string;
}

interface Test {
  chapter: GrandTestMeta;
  test_time: number;
  question_ids: number[];
}

export interface TopicQuestion {
  id: string;
  type: string;
  attributes: {
    id: number;
    grand_test_id: number;
    sub_name: string;
    question_no: number;
    question: string;
    options: Option[] | [];
    p_mark: string;
    n_mark: string;
    question_db: string;
    question_type: string;
    heading: string;
    is_marked: boolean;
    percentage_people_correct: number;
  };
}

type AnswersT = {
  question_id: number;
  option: string[][];
  legend?: string;
  is_marked?: boolean;
  question_db: string;
};
// Customizable Area End

export interface Props extends WithStyles<typeof styles> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  endTimeGrandTest: number;
  timeRemaining: number;
  testData: TopicQuestion[];
  currentTestData: number;
  answerGrandTest: AnswersT[][];
  currentAnswers: AnswersT[];
  meta: Test | null;
  submitModalOpen: boolean;
  reviewModalOpen: boolean;
  errorModalOpen: boolean;
  testSubjects: TestSubject[];
  currentSubjectTestData: TopicQuestion[];
  currentSubjectTestDataIndex: number;
  reviewId: string;
  loading: boolean;
  isBlocking: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GrandTestTakingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTestMessageId = "";
  intervalId: NodeJS.Timeout = setTimeout(() => {}, 0);
  submitTestMessageId = "";
  currentTestMeta = "";
  subjectId = "";
  getTestSubjectMessageId = "";
  breadcrumb = [
    {
      label: "Grand Test",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}`,
    },
    {
      label: "Chapters",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}`,
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Take Test",
    },
  ];
  getPackageReviewTestMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.tick = this.tick.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      endTimeGrandTest: 0,
      loading: false,
      timeRemaining: 0,
      testData: [],
      currentTestData: 0,
      answerGrandTest: localStorage.getItem("answerGrandTest")
        ? JSON.parse(localStorage.getItem("answerGrandTest") as string)
        : [[]],
      meta: null,
      submitModalOpen: false,
      isBlocking: true,
      reviewModalOpen: false,
      testSubjects: [],
      currentSubjectTestData: [],
      currentSubjectTestDataIndex: 0,
      errorModalOpen: false,
      reviewId: "",
      currentAnswers: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getTestMessageId && response) {
      this.handleTestDataResponse(response);
    }

    if (apiRequestCallId === this.submitTestMessageId && response) {
      this.handleTestSubmitResponse(response);
    }

    if (apiRequestCallId === this.getTestSubjectMessageId && response) {
      this.handleTestSubjectResponse(response);
    }

    if (apiRequestCallId === this.getPackageReviewTestMessageId) {
      if (response.data) {
        this.handleSuccessfulPackage(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getPackageReviewTestMessage();
    const subjectId = this.props.navigation.getParam("subjectId");
    this.subjectId = subjectId;
    const currentTestData = localStorage.getItem("currentGrandTest");
    const currentTestMeta = localStorage.getItem("currentGrandTestMeta");
    const currentTestSubjectData = localStorage.getItem("testSubjectsGT");
    this.currentTestMeta = currentTestMeta as string;
    const propPassingGTTC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingGTTC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingGTTC);

    if (
      subjectId === String(JSON.parse(currentTestMeta as string)?.grand_test.id)
    ) {
      if (currentTestData && currentTestMeta && currentTestSubjectData) {
        this.handleCurrentTest(
          JSON.parse(currentTestData),
          JSON.parse(currentTestMeta),
          JSON.parse(currentTestSubjectData)
        );
        const savedEndTime = localStorage.getItem("endTimeGrandTest");
        const totalTestTimeGrandTest = localStorage.getItem(
          "totalTestTimeGrandTest"
        );
        let endTimeGrandTest: number;

        if (
          savedEndTime &&
          parseInt(savedEndTime, 10) !== 0 &&
          totalTestTimeGrandTest
        ) {
          endTimeGrandTest = parseInt(savedEndTime, 10);
        } else {
          endTimeGrandTest = Date.now() + Number(totalTestTimeGrandTest) * 1000;
          localStorage.setItem("endTimeGrandTest", endTimeGrandTest.toString());
        }

        const timeRemaining = Math.max(endTimeGrandTest - Date.now(), 0);

        this.handleTimerStart(endTimeGrandTest, timeRemaining);
      }
    } else {
      localStorage.removeItem("totalTestTimeGrandTest");
      localStorage.removeItem("endTimeGrandTest");
      localStorage.removeItem("currentGrandTest");
      localStorage.removeItem("currentGrandTestMeta");
      localStorage.removeItem("answerGrandTest");
      localStorage.removeItem("testSubjectsGT");
      this.getTest(subjectId);
    }
  };

  componentWillUnmount = async () => {
    clearInterval(this.intervalId);
  };

  handleTestDataResponse(response: ApiData) {
    if (response.meta) {
      this.handleTestData(response.data, response.meta);
      const endTimeGrandTest = this.calculateEndTime(response.meta.test_time);
      const timeRemaining = Math.max(endTimeGrandTest - Date.now(), 0);
      this.handleTimerStart(endTimeGrandTest, timeRemaining);
    } else {
      this.setState({ errorModalOpen: true, loading: false });
    }
  }

  handleTestSubmitResponse(response: {
    analysis: string;
    meta: { gt_review_id: string };
  }) {
    if (response.analysis) {
      this.clearLocalStorage();
      this.setState(
        { reviewId: response.meta.gt_review_id, isBlocking: false },
        () => {
          this.handleSubmitModalClose();
          this.handleReviewModalOpen();
        }
      );
    }
  }

  handleTestSubjectResponse(response: { data: TestSubject[] }) {
    if (response.data) {
      this.handleTestSubjects(response.data);
    }
  }

  calculateEndTime(testTime: number) {
    const savedEndTime = localStorage.getItem("endTimeGrandTest");
    let endTimeGrandTest;
    if (savedEndTime && parseInt(savedEndTime, 10) !== 0) {
      endTimeGrandTest = parseInt(savedEndTime, 10);
    } else {
      endTimeGrandTest = Date.now() + testTime * 1000;
      localStorage.setItem("endTimeGrandTest", endTimeGrandTest.toString());
    }
    return endTimeGrandTest;
  }

  clearLocalStorage() {
    localStorage.removeItem("endTimeGrandTest");
    localStorage.removeItem("totalTestTimeGrandTest");
    localStorage.removeItem("currentGrandTest");
    localStorage.removeItem("currentGrandTestMeta");
    localStorage.removeItem("answerGrandTest");
  }

  handleTestSubjects = (response: TestSubject[]) => {
    const currentTestSubjectData = this.state.testData.filter(
      (testSubject) =>
        testSubject.attributes.sub_name === response[0].subject_name
    );
    localStorage.setItem("testSubjectsGT", JSON.stringify(response));
    this.setState({
      testSubjects: response,
      currentSubjectTestData: currentTestSubjectData,
      answerGrandTest: Array.from({ length: response.length }, () => []),
      loading: false,
    });
  };

  handleSubmitModalClose = () => {
    this.setState({
      submitModalOpen: false,
    });
  };

  handleSubmitModalOpen = () => {
    this.setState({
      submitModalOpen: true,
    });
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  handleReviewModalClose = () => {
    this.setState({
      reviewModalOpen: false,
    });
  };

  handleReviewModalOpen = () => {
    this.setState({
      reviewModalOpen: true,
    });
  };

  handleCurrentTest = async (
    testData: TopicQuestion[],
    meta: Test,
    testSubjects: TestSubject[]
  ) => {
    const currentTestSubjectData = testData.filter(
      (testSubject) =>
        testSubject.attributes.sub_name === testSubjects[0].subject_name
    );
    const answerGrandTest = JSON.parse(await storage.get("answerGrandTest"));
    const currentAnswers = answerGrandTest[0];

    this.setState({
      testData,
      meta,
      testSubjects,
      currentSubjectTestData: currentTestSubjectData,
      answerGrandTest,
      currentAnswers,
    });
  };

  tick = () => {
    const newTimeRemaining = Math.max(
      this.state.endTimeGrandTest - Date.now(),
      0
    );
    this.setState({ timeRemaining: newTimeRemaining });

    if (newTimeRemaining <= 0) {
      clearInterval(this.intervalId);
      this.timeReachedZero();
    }
  };

  timeReachedZero = () => {
    localStorage.removeItem("endTimeGrandTest");
    localStorage.removeItem("totalTestTimeGrandTest");
    localStorage.removeItem("currentGrandTest");
    localStorage.removeItem("currentGrandTestMeta");
    localStorage.removeItem("answerGrandTest");
    this.submitTest();
  };

  handleTimerStart = (endTimeGrandTest: number, timeRemaining: number) => {
    this.setState(
      {
        endTimeGrandTest,
        timeRemaining,
      },
      () => {
        this.intervalId = setInterval(this.tick, 1000);
      }
    );
  };

  handleTestData = (response: TopicQuestion[], meta: Test) => {
    if (response.length > 0) {
      localStorage.setItem("totalTestTimeGrandTest", String(meta.test_time));
      localStorage.setItem("currentGrandTest", JSON.stringify(response));
      localStorage.setItem("currentGrandTestMeta", JSON.stringify(meta));
      localStorage.setItem(
        "answerGrandTest",
        JSON.stringify([
          [
            {
              is_marked: false,
              question_id: response[0].attributes.id,
              option: [[]],
              question_db: response[0].attributes.question_db,
            },
          ],
        ])
      );
      this.currentTestMeta = JSON.stringify(meta);
      this.setState(
        {
          testData: response,
          currentTestData: 0,
          meta,
          answerGrandTest: [
            [
              {
                is_marked: false,
                question_id: response[0].attributes.id,
                option: [[]],
                question_db: response[0].attributes.question_db,
              },
            ],
          ],
          currentAnswers: [],
        },
        () => {
          this.getTestSubject(this.props.navigation.getParam("subjectId"));
        }
      );
    } else {
      this.setState({
        errorModalOpen: true,
      });
    }
  };

  handleNextQuestion = () => {
    if (
      this.state.currentTestData !==
      this.state.currentSubjectTestData.length - 1
    ) {
      this.setState({
        currentTestData: this.state.currentTestData + 1,
      });
    }
  };
  handleSpecificQuestion = (index: number) => {
    this.setState({
      currentTestData: index,
    });
  };

  setAnswer = (index: number, option: string[][]) => {
    const currentanswers = [...this.state.currentAnswers];

    currentanswers[index] = {
      ...this.state.currentAnswers[index],
      question_id: this.state.testData[index].attributes.id,
      option,
    };

    this.setState({ currentAnswers: currentanswers }, () => {
      this.submitAnswerOverview(
        this.props.navigation.getParam("subjectId"),
        "answered",
        this.state.currentSubjectTestData[index].id,
        this.state.currentSubjectTestData[index].attributes.question_db
      );
    });
  };
  handlePreviousQuestion = () => {
    if (this.state.currentTestData !== 0) {
      this.setState({
        currentTestData: this.state.currentTestData - 1,
      });
    }
  };
  handleSubjectChange = (subject: string, index: number) => {
    const currentTestDataB = this.state.testData.filter(
      (subjectData) => subjectData.attributes.sub_name === subject
    );
    const currentAnswers = [...this.state.currentAnswers];
    const answersGrandTest = [...this.state.answerGrandTest];

    answersGrandTest[this.state.currentSubjectTestDataIndex] = currentAnswers;

    if (currentTestDataB.length !== 0) {
      this.setState({
        currentSubjectTestDataIndex: index,
        currentSubjectTestData: currentTestDataB,
        answerGrandTest: answersGrandTest,
        currentTestData: 0,
        currentAnswers: answersGrandTest[index],
      });
    }
  };

  componentDidUpdate(_: Props, prevState: S) {
    if (
      prevState.currentTestData !== this.state.currentTestData &&
      prevState.currentSubjectTestDataIndex ===
        this.state.currentSubjectTestDataIndex
    ) {
      this.submitOverviewPerQuestion(prevState.currentTestData);
    }
    if (
      JSON.stringify(prevState.currentAnswers) !==
        JSON.stringify(this.state.currentAnswers) ||
      (JSON.stringify(prevState.currentTestData) !==
        JSON.stringify(this.state.currentTestData) &&
        prevState.currentSubjectTestDataIndex ===
          this.state.currentSubjectTestDataIndex)
    ) {
      this.handleAnswersChanged(prevState);
    }
  }

  handleAnswersChanged = (prevState: S) => {
    let currentAnswersOnSubject = this.state.currentAnswers[this.state.currentTestData];

    const isAnsweredB = currentAnswersOnSubject?.option?.[0]?.[0]?.length > 0;
    const isMarked = currentAnswersOnSubject?.is_marked;

    if (isMarked) {
      currentAnswersOnSubject = { ...currentAnswersOnSubject, is_marked: true };
    } else {
      currentAnswersOnSubject = { ...currentAnswersOnSubject, is_marked: false };
    }

    if (isAnsweredB) {
      currentAnswersOnSubject = { ...currentAnswersOnSubject, legend: "answered" };
    }

    if (isAnsweredB && isMarked) {
      currentAnswersOnSubject = {
        ...currentAnswersOnSubject,
        legend: "answered_and_marked_for_review",
      };
    }

    if (!isAnsweredB && isMarked) {
      currentAnswersOnSubject = {
        ...currentAnswersOnSubject,
        legend: "not_answered_and_marked_for_review",
      };
    }

    if (
      prevState.currentAnswers[this.state.currentTestData]?.option?.[0]?.[0]
        ?.length > 0 &&
      !currentAnswersOnSubject?.option?.[0]?.[0]?.length &&
      prevState.currentSubjectTestDataIndex ===
        this.state.currentSubjectTestDataIndex
    ) {
      currentAnswersOnSubject = {
        ...currentAnswersOnSubject,
        legend: "answered_and_cleared_the_answer",
      };
    }

    if (
      prevState.currentAnswers[this.state.currentTestData]?.is_marked &&
      !currentAnswersOnSubject?.is_marked &&
      prevState.currentSubjectTestDataIndex ===
        this.state.currentSubjectTestDataIndex
    ) {
      currentAnswersOnSubject = {
        ...currentAnswersOnSubject,
        legend: "marked_and_un_marked_for_review",
      };
    }

    const answers = this.state.currentAnswers;
    answers[this.state.currentTestData] = currentAnswersOnSubject;

    const answerGrandTest = this.state.answerGrandTest;
    answerGrandTest[this.state.currentSubjectTestDataIndex] = answers;

    storage.set("answerGrandTest", JSON.stringify(answerGrandTest));

    this.setState({
      currentAnswers: answers,
    });
  };

  markForReview = () => {
    const currentAnswers = [...this.state.currentAnswers];

    currentAnswers[this.state.currentTestData] = {
      ...currentAnswers[this.state.currentTestData],
      is_marked: !currentAnswers[this.state.currentTestData].is_marked,
    };

    this.setState(
      {
        currentAnswers: currentAnswers,
      },
      () => {
        if (currentAnswers[this.state.currentTestData].is_marked === false) {
          this.submitAnswerOverview(
            this.props.navigation.getParam("subjectId"),
            "unmark_review",
            this.state.currentSubjectTestData[this.state.currentTestData].id,
            this.state.currentSubjectTestData[this.state.currentTestData]
              .attributes.question_db
          );
        } else {
          this.submitAnswerOverview(
            this.props.navigation.getParam("subjectId"),
            "mark_review",
            this.state.currentSubjectTestData[this.state.currentTestData].id,
            this.state.currentSubjectTestData[this.state.currentTestData]
              .attributes.question_db
          );
        }
      }
    );
  };

  clearResponse = () => {
    const currentAnswers = [...this.state.currentAnswers];

    currentAnswers[this.state.currentTestData] = {
      ...currentAnswers[this.state.currentTestData],
      option: [[]],
    };

    this.setState({
      currentAnswers,
    });
  };

  getBtnClassName = (index: number) => {
    const currentAnswer = this.state.currentAnswers[index];

    if (currentAnswer?.legend === "answered") {
      return "btn-answered";
    }

    if (currentAnswer?.legend === "un_answered") {
      return "btn-unanswered";
    }

    if (currentAnswer?.legend === "answered_and_marked_for_review") {
      return "btn-answered-marked";
    }

    if (currentAnswer?.legend === "not_answered_and_marked_for_review") {
      return "btn-unanswered-marked";
    }

    if (currentAnswer?.legend === "marked_and_un_marked_for_review") {
      return "btn-marked-unmarked";
    }

    if (currentAnswer?.legend === "answered_and_cleared_the_answer") {
      return "btn-answered-cleared";
    }

    return "";
  };

  // Customizable Area Start
  getTest = async (gtId: string) => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getGrandTestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTestMessageId = getGrandTestMessage.messageId;

    getGrandTestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/${gtId}/take_test`
    );

    getGrandTestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getGrandTestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getGrandTestMessage.id, getGrandTestMessage);
  };

  secondsToHHMMSS = (totalSeconds: number): string => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const hh = hours.toString().padStart(2, "0");
    const mm = minutes.toString().padStart(2, "0");
    const ss = seconds.toString().padStart(2, "0");

    return `${hh}:${mm}:${ss}`;
  };

  submitTest = async () => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const submitTestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitTestMessageId = submitTestMessage.messageId;

    const testTime = Number(localStorage.getItem("totalTestTimeGrandTest"));
    const timeTaken = this.secondsToHHMMSS(
      testTime - Math.floor(this.state.timeRemaining / 1000)
    );

    submitTestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/${this.props.navigation.getParam(
        "subjectId"
      )}/submit_test`
    );

    submitTestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    submitTestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        taken_time: timeTaken,
      })
    );

    submitTestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(submitTestMessage.id, submitTestMessage);
  };

  submitAnswerOverview = async (
    grandTestId: string,
    answerType: string,
    questionId: string,
    questionDb: string
  ) => {
    const answerOption =
      this.state.currentAnswers[this.state.currentTestData]?.option[0];

    let body;

    if (answerType === "unmark_review" || answerType === "mark_review") {
      body = {
        answered: {
          question_id: questionId,
          question_db: questionDb,
        },
      };
    } else if (answerType === "answered") {
      body = {
        answered: {
          question_id: questionId,
          option: answerOption,
          question_db: questionDb,
        },
      };
    }

    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const submitOverviewMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    submitOverviewMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/${grandTestId}/${answerType}`
    );

    submitOverviewMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    submitOverviewMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    submitOverviewMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(submitOverviewMessage.id, submitOverviewMessage);
  };

  submitOverviewPerQuestion = (prevIndex: number) => {
    let currentAnswers = this.state.currentAnswers[prevIndex];
    const isAnswered = currentAnswers?.option?.[0]?.[0]?.length > 0;
    const legend = currentAnswers?.legend;

    if (!isAnswered && legend === undefined) {
      currentAnswers = { ...currentAnswers, legend: "un_answered" };
    }

    const answers = this.state.currentAnswers;
    answers[prevIndex] = currentAnswers;

    const answerGrandTest = this.state.answerGrandTest;
    answerGrandTest[this.state.currentSubjectTestDataIndex] = answers;

    storage.set("answerGrandTest", JSON.stringify(answerGrandTest));

    this.setState({
      currentAnswers: answers,
    });
  };

  getTestSubject = async (gtId: string) => {
    const headers = {
      token: await storage.get("authToken"),
    };

    const getTestSubjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTestSubjectMessageId = getTestSubjectMessage.messageId;

    getTestSubjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/${gtId}/gt_subjects`
    );

    getTestSubjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getTestSubjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getTestSubjectMessage.id, getTestSubjectMessage);
  };

  handleSuccessfulPackage = (
    response: { gt_code: number; package_name: string }[]
  ) => {
    const packageId = this.props.navigation.getParam("packageId");
    response.forEach((data) => {
      if (data.gt_code === packageId) {
        const breadcrumb = this.breadcrumb;
        breadcrumb[1] = { ...breadcrumb[1], label: data.package_name };

        const propPassingTestTaking = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingTestTaking.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingTestTaking);

        this.setState({
          loading: false,
        });
      }
    });
  };

  getPackageReviewTestMessage = async () => {
    const getPackageMessageTestTaking = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getPackageReviewTestMessageId = getPackageMessageTestTaking.messageId;

    getPackageMessageTestTaking.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getPackageMessageTestTaking.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/get_packages`
    );

    getPackageMessageTestTaking.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getPackageMessageTestTaking.id,
      getPackageMessageTestTaking
    );
  };
  // Customizable Area End
}
