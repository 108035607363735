import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Modal,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import { bookmarkImg, saveImg } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import SynopsisTopicsController, {
  Props,
} from "./SynopsisTopicsController.web";

class SynopsisTopics extends SynopsisTopicsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const param = this.props.navigation.getParam;

    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box className={this.props.classes.bannerStyle}>
          <Typography variant="h5">
            {this.state.meta?.chapter.name} Topics
          </Typography>
        </Box>
        <Box padding={"2em"}>
          {this.state.topicRead ? (
            <Box>
              <Typography
                variant="h3"
                style={{
                  fontSize: "1.5em",
                  fontWeight: 600,
                  marginBottom: "1.3em",
                }}
              >
                Topics Covered
              </Typography>
              <Box className={this.props.classes.chapterContainerStyle}>
                {this.state.topicRead.map((topic) => {
                  return (
                    <Box className={this.props.classes.cardStyle2}>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        width={"100%"}
                        height={"100%"}
                        paddingTop={"1em"}
                        alignItems={"center"}
                        data-test-id="navigate-to-reading"
                        onClick={() => this.navigateToReading(topic.id)}
                      >
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          width={"100%"}
                          height={"100%"}
                        >
                          <Typography
                            variant="h3"
                            style={{
                              fontSize: "1.5em",
                              fontWeight: 600,
                              marginBottom: "1.3em",
                              textAlign: "center",
                            }}
                          >
                            Topic - {topic.attributes.name}
                          </Typography>
                          <Box
                            display="flex"
                            justifyContent={"center"}
                            alignItems={"center"}
                            gridGap={"1.5em"}
                          >
                            <Box
                              data-test-id="note-modal-btn"
                              onClick={(event) => {
                                event.stopPropagation();
                                this.handleNotesModal(topic.id);
                              }}
                            >
                              <img
                                src={saveImg}
                                alt="save-notes-img"
                                width={"30px"}
                                style={{ marginLeft: "2em" }}
                              />
                              <p>Saved notes</p>
                            </Box>
                            <NavLink
                              to={`/dashboard/synopsis/chapters/${param(
                                "subjectId"
                              )}/topics/${[param("chapterId")]}/bookmarks/${
                                topic.id
                              }`}
                              data-test-id="navigate-btn-topic-read"
                              onClick={(event) => event.stopPropagation()}
                            >
                              <img
                                src={bookmarkImg}
                                alt="bookmark-img"
                                width={"30px"}
                                style={{ marginLeft: "1.2em" }}
                              />
                              <p>Bookmark</p>
                            </NavLink>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ) : null}
          {this.state.topicNotRead ? (
            <Box marginTop={"2em"}>
              <Typography
                variant="h3"
                style={{
                  fontSize: "1.5em",
                  fontWeight: 600,
                  marginBottom: "1.3em",
                }}
              >
                Topics Not Covered
              </Typography>
              <Box className={this.props.classes.chapterContainerStyle}>
                {this.state.topicNotRead.map((topic) => {
                  return (
                    <Box
                      className={this.props.classes.cardStyle}
                      data-test-id="navigate-to-topic"
                      onClick={() => this.navigateToReading(topic.id)}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Box display={"flex"} marginLeft={"1em"}>
                          <h3>{topic.attributes.name}</h3>
                        </Box>
                        <NavLink
                          to={`/dashboard/synopsis/chapters/${param(
                            "subjectId"
                          )}/topics/${param("chapterId")}/topic-read/${
                            topic.id
                          }`}
                          style={{
                            position: "absolute",
                            bottom: "25px",
                            right: "20px",
                          }}
                        >
                          <Box>
                            Learn Now{" "}
                            <Box
                              className={this.props.classes.circleIcon}
                            ></Box>
                          </Box>
                        </NavLink>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ) : null}
        </Box>
        <Modal open={this.state.notesModal} onClose={this.handleNotesModal}>
          <Box className={this.props.classes.modalStyle}>
            <h2>Choose What You Need</h2>
            <Box display={"flex"} flexDirection={"column"} gridGap={"1em"}>
              <NavLink
                to={`/dashboard/synopsis/chapters/${param(
                  "subjectId"
                )}/topics/${[param("chapterId")]}/saved-notes/${
                  this.state.topicId
                }`}
              >
                <Button className={this.props.classes.buttonStyleNo}>
                  Go to saved notes
                </Button>
              </NavLink>
              <Button
                className={this.props.classes.buttonStyleNo}
                onClick={this.navigateToNewNote}
              >
                Create New Note
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleSynopsisTopics = () =>
  createStyles({
    chapterContainerStyle: {
      display: "flex",
      width: "100%",
      gap: "1em",
      flexWrap: "wrap" as const,
    },
    imageStyle: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
    },
    cardStyle: {
      width: "400px",
      height: "130px",
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "center",
      border: "1px solid rgba(255, 63, 89, 1)",
      borderRadius: "0.5em",
      gap: "1em",
      position: "relative",
      cursor: "pointer",
    },
    cardStyle2: {
      width: "400px",
      minHeight: "130px",
      height: "max-content",
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "center",
      border: "1px solid #879685",
      background: "#F7FFF2",
      borderRadius: "0.5em",
      gap: "1em",
      position: "relative",
      cursor: "pointer",
      "& a": {
        textDecoration: "none",
        color: "black",
      },
    },
    modalStyle: {
      background: "white",
      display: "flex",
      flexDirection: "column",
      transform: "translate(-50%, -50%)",
      width: "35%",
      minHeight: "max-content",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute" as "absolute",
      border: "2px solid #FFD0D8",
      borderRadius: "1.5em",
      padding: "2em",
      left: "50%",
      bgcolor: "background.paper",
      top: "50%",
      gap: "1.5em",
      outline: "none",
    },
    buttonStyleNo: {
      minWidth: "230px",
      width: "max-content",
      background: "white",
      border: "1px solid #FF5B5B",
      color: "#222",
      textTransform: "none" as const,
      transition: "all 0.3s ease",
      height: "55px",
      borderRadius: "0.6em",
      fontSize: "1.35em",
      "&:hover": {
        color: "white",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      },
    },
    circleIcon: {
      position: "absolute",
      top: "3px",
      left: "-30px",
      borderRadius: "50%",
      textAlign: "center",
      display: "inline-block",
      color: "white",
      marginLeft: "0.5em",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      marginRight: "0.3em",
      width: "15px",
      height: "15px",
    },
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "#222",
      display: "flex",
      background: "rgb(247,242,249)",
      height: "71px",
      "& h5": {
        fontWeight: 600,
      },
    },
  });

export default withStyles(webStyleSynopsisTopics)(SynopsisTopics);
export { SynopsisTopics };
// Customizable Area End
