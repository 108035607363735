import React from "react";

import {
  Box,
  // Customizable Area Start
  createStyles,
  withStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import Button from "../../../components/src/Button.web";
import { clock } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import SynopsisChaptersController, {
  Props,
} from "./SynopsisChaptersController.web";

class SynopsisChapters extends SynopsisChaptersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box className={this.props.classes.bannerStyle}>
          <Typography variant="h5">
            {this.state.subjectName?.attributes.name} Chapters
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          padding={"2em"}
        >
          {this.state.chaptersCovered.length === 0 &&
            this.state.chaptersNotCovered.length === 0 && (
              <Box>There is no chapter data here.</Box>
            )}
          {this.state.chaptersCovered.length > 0 ? (
            <Box>
              <Typography
                variant="h3"
                style={{
                  fontSize: "1.5em",
                  fontWeight: 600,
                  marginBottom: "1.3em",
                }}
              >
                Chapters Covered
              </Typography>
              <Box className={this.props.classes.chapterContainerStyle}>
                {this.state.chaptersCovered.map((chapter) => {
                  return (
                    <Box
                      key={`chapter-${chapter.id}`}
                      className={this.props.classes.cardStyle}
                      style={{ background: "#e7ffe7" }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          <img
                            src={chapter.attributes.image}
                            alt={`chapter-${chapter.id}`}
                            className={this.props.classes.imageStyle}
                          />
                          <p
                            style={{
                              fontSize: "18px",
                            }}
                          >
                            {chapter.attributes.name}
                          </p>
                        </Box>
                        {this.state.chapterId === chapter.id && (
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            position={"absolute"}
                            width={"150px"}
                            right={"5px"}
                            bottom={"10px"}
                          >
                            <button
                              onClick={this.navigateToRead}
                              data-test-id="continue-btn"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src={clock}
                                alt="clock"
                                width={"25px"}
                                style={{ display: "block" }}
                              />
                              <span style={{ display: "block" }}>
                                Continue reading
                              </span>
                            </button>
                          </Box>
                        )}
                      </Box>
                      <NavLink
                        to={`/dashboard/synopsis/chapters/${this.props.navigation.getParam(
                          "subjectId"
                        )}/topics/${chapter.id}`}
                        className={this.props.classes.viewBtnStyle}
                      >
                        <Button
                          color="white"
                          height="35px"
                          width="121px"
                          title="View"
                        />
                      </NavLink>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ) : null}
          {this.state.chaptersNotCovered.length > 0 ? (
            <Box marginTop={"2em"}>
              <Typography
                variant="h3"
                style={{
                  fontSize: "1.5em",
                  fontWeight: 600,
                  marginBottom: "1.3em",
                }}
              >
                Chapters Not Covered
              </Typography>
              <Box className={this.props.classes.chapterContainerStyle}>
                {this.state.chaptersNotCovered.map((chapter) => {
                  return (
                    <Box className={this.props.classes.cardStyle}>
                      <Box
                        justifyContent={"space-between"}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Box
                          justifyContent={"flex-start"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          <img
                            src={chapter.attributes.image}
                            className={this.props.classes.imageStyle}
                            alt={`chapter-${chapter.id}`}
                          />
                          <p
                            style={{
                              fontSize: "18px",
                            }}
                          >
                            {chapter.attributes.name}
                          </p>
                        </Box>
                      </Box>
                      <NavLink
                        to={`/dashboard/synopsis/chapters/${this.props.navigation.getParam(
                          "subjectId"
                        )}/topics/${chapter.id}`}
                        className={this.props.classes.viewBtnStyle}
                      >
                        <Button
                          height="35px"
                          width="121px"
                          title="View"
                          color="white"
                        />
                      </NavLink>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleSynopsisChapters = () =>
  createStyles({
    cardStyle: {
      width: "400px",
      minHeight: "130px",
      height: "max-content",
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "center",
      border: "1px solid rgba(255, 63, 89, 1)",
      borderRadius: "0.5em",
      padding: "0.7em",
      gap: "1em",
      position: "relative",
    },
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "#222",
      display: "flex",
      background: "rgb(247,242,249)",
      height: "71px",
      "& h5": {
        fontWeight: 600,
      },
    },
    chapterContainerStyle: {
      display: "flex",
      width: "100%",
      gap: "1em",
      flexWrap: "wrap" as const,
    },
    viewBtnStyle: {
      marginLeft: "4.5em",
      width: "max-content",
    },
    imageStyle: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      marginRight: "1em",
    },
  });

export default withStyles(webStyleSynopsisChapters)(SynopsisChapters);
export { SynopsisChapters };
// Customizable Area End
