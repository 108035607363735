import React from "react";
import { Box } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";

const webStyle = {
  explanationStyle: {
    background: "#FEFDFB",
    minHeight: "100px",
    height: "max-content",
    width: "100%",
    maxWidth: "100%",
    borderRadius: "5px",
    border: "1px solid #F8F2F2",
    padding: "1em",
    color: "#520876",
    fontWeight: "bold",
  },
  checkbox: {
    appearance: "none" as const,
    width: "36px",
    height: "36px",
    border: "2px solid #969696",
    borderRadius: "50%",
    marginRight: "10px",
    position: "relative" as const,
    transition: "0.3s all ease",
    "&:checked": {
      backgroundColor: "white",
      "&:after": {
        content: '""',
        display: "block",
        width: "15px",
        height: "9px",
        border: "3px solid rgba(255, 54, 86, 1)",
        borderTop: "none",
        borderRight: "none",
        position: "absolute" as const,
        top: "45%",
        left: "50%",
        transform: "translate(-50%, -50%) rotate(-47deg)",
      },
    },
  },
};

const useStyles = makeStyles({
  reviewStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "250px",
    height: "max-content",
    width: "100%",
    borderRadius: "5px",
    padding: "1em",
    fontWeight: "bold",
    gap: "1.2em",
    "& .did-not-attend": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "none",
      padding: "1em 2em",
      background: "#EFBECA",
      borderRadius: "0.7em",
    },
    "& .correct-answer": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  reviewComponent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "max-content",
    color: "#272727",
    animation: "animate-in 0.3s forwards",
  },
  h3: {
    fontSize: "18px",
    marginBottom: "0.7em",
    maxWidth: "815.17px",
    minWidth: "300px",
    display: "flex",
    width: "100%",
    height: "max-content",
    fontWeight: 500,
    gap: "0.3em",
    "& div": {
      wordWrap: "break-word",
    },
  },
  p: {
    fontSize: "16px",
    margin: 0,
    maxWidth: "815.17px",
    minWidth: "300px",
    "& div": {
      wordWrap: "break-word",
    },
  },
  "@keyframes animate-in": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  textarea: {
    resize: "none",
    width: "100%",
    minWidth: "300px",
    maxHeight: "450px",
    height: "max-content",
    padding: "1em",
    border: "1px solid #ffa297",
    borderRadius: "5px",
    fontSize: "20px",
    fontWeight: 500,
    transition: "border 0.3s ease",
    marginBottom: "2em",
    backgroundColor: "rgb(254, 249, 255)",
    "&:focus": {
      border: "2px solid #ffa297",
      outline: "none",
    },
  },
  testComponentChoice: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    minWidth: "300px",
    height: "max-content",
    flexWrap: "wrap",
    minHeight: "70px",
    padding: "10px",
    margin: "10px 0",
    borderRadius: "10px",
    border: "1px solid #969696",
    color: "#5d5d5d",
    backgroundColor: "white",
    transition: "0.3s all ease",
    "& input": {
      width: "36px",
      height: "36px",
    },
    "& > div": {
      width: "90%",
      wordWrap: "break-word",
    },
    "& img": {
      maxWidth: "500px!important",
      height: "auto!important",
      objectFit: "cover",
    },
  },
  testComponentUnanswered: {
    background: "white",
    border: "2px solid #9CBA58",
    '& input[type="checkbox"]': {
      border: "2px solid #9CBA58",
    },
  },
  testComponentCorrect: {
    background: "#9CBA58",
    color: "white",
    border: "none",
    '& input[type="checkbox"]': {
      border: "none",
    },
  },
  testComponentIncorrect: {
    border: "2px solid #DD1000",
  },
  checkbox: {
    ...webStyle.checkbox,
  },
  checkboxCorrect: {
    ...webStyle.checkbox,
    "&:checked": {
      backgroundColor: "white",
      "&:after": {
        border: "3px solid #9CBA58",
        content: '""',
        display: "block",
        width: "15px",
        height: "9px",
        borderTop: "none",
        borderRight: "none",
        position: "absolute" as const,
        top: "45%",
        left: "50%",
        transform: "translate(-50%, -50%) rotate(-47deg)",
      },
    },
  },
  checkboxIncorrect: {
    ...webStyle.checkbox,
    border: "2px solid #DD1000",
    "&:checked": {
      backgroundColor: "white",
      "&::before, &::after": {
        content: '""',
        display: "block",
        width: "15px",
        height: "3px",
        backgroundColor: "#DD1000",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        boxSizing: "border-box",
      },
      "&::before": {
        transform: "translate(-50%, -50%) rotate(45deg)",
      },
      "&::after": {
        transform: "translate(-50%, -50%) rotate(-45deg)",
      },
    },
  },
  checkboxUnanswered: {
    ...webStyle.checkbox,
    "&:checked": {
      backgroundColor: "white",
      "&:after": {
        border: "3px solid #9CBA58",
        content: '""',
        display: "block",
        width: "15px",
        height: "9px",
        borderTop: "none",
        borderRight: "none",
        position: "absolute" as const,
        top: "45%",
        left: "50%",
        transform: "translate(-50%, -50%) rotate(-47deg)",
      },
    },
  },
});

interface Daum {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Attributes {
  id?: number;
  question?: string;
  question_no: number;
  question_1?: string;
  question_2?: string;
  correct_answer: string;
  explanation: string;
  options: Array<{ option: string; id: string }>;
  user_answer?: null | string;
  is_correct: boolean;
  is_unattended?: boolean;
  is_unattempt?: boolean;
  percentage_people_correct: number | null;
  attempted_data?: null | string;
  question_type: string;
}

const ReviewTestComponent = ({
  question,
  index,
}: {
  question: Daum;
  index: number;
}) => {
  const classes = useStyles();
  const selectedOption = (
    question.attributes.user_answer || question.attributes.attempted_data
  )?.toLowerCase();
  const isNoAnswer =
    question.attributes.is_unattempt || question.attributes.is_unattended;
  const correctAnswer = question.attributes.correct_answer.toLowerCase();

  return (
    <Box className={classes.reviewComponent} key={`question-${index}`}>
      <div className={classes.h3}>
        <div>{question.attributes.question_no}.</div>
        <div
          style={{ maxWidth: "100%" }}
          dangerouslySetInnerHTML={{
            __html: (question.attributes.question_1 ||
              question.attributes.question) as string,
          }}
        />
      </div>
      <div className={classes.h3}>
        <div
          style={{ maxWidth: "100%", marginTop: "1em", marginLeft: "1em" }}
          dangerouslySetInnerHTML={{
            __html: question.attributes.question_2 as string,
          }}
        />
      </div>
      <div style={{ marginBottom: "1.5em" }}>
        {question.attributes.options.map((option, optionIndex) => {
          option.id = option.id.toLowerCase();
          const userAnswer = selectedOption
            ? selectedOption.includes(option.id)
            : false;
          const realAnswer = correctAnswer.includes(option.id);
          const isUserCorrect = userAnswer && realAnswer;

          const checkBoxClass = isUserCorrect
            ? classes.checkboxCorrect
            : userAnswer
            ? classes.checkboxIncorrect
            : classes.checkbox;

          const labelClass = isUserCorrect
            ? classes.testComponentCorrect
            : userAnswer
            ? classes.testComponentIncorrect
            : "";

          const labelCorrect = realAnswer
            ? isNoAnswer
              ? classes.testComponentUnanswered
              : classes.testComponentCorrect
            : "";
          const checkBoxClassCorrect = realAnswer
            ? isNoAnswer
              ? classes.checkboxUnanswered
              : classes.checkboxCorrect
            : "";

          return (
            <label
              key={option.id}
              className={`${classes.testComponentChoice} ${labelClass} ${labelCorrect}`}
            >
              <input
                type="checkbox"
                name={`single-choice-${optionIndex}`}
                value={option.id}
                checked={userAnswer || realAnswer}
                className={`${checkBoxClass} ${checkBoxClassCorrect}`}
                readOnly
              />
              <span>
                {String.fromCharCode(optionIndex + 65)}
                {`)`}&nbsp;
              </span>
              <div
                dangerouslySetInnerHTML={{
                  __html: option.option,
                }}
              />
            </label>
          );
        })}
      </div>
      {question.attributes.question_type === "numerical" &&
        !question.attributes.is_unattended &&
        !question.attributes.is_unattempt && (
          <Box>
            <textarea
              value={
                question.attributes.user_answer ||
                (question.attributes.attempted_data as string)
              }
              className={classes.textarea}
              readOnly
            />
          </Box>
        )}
      <Box
        className={classes.reviewStyle}
        style={{
          background: `${
            question.attributes.is_correct ? "#D1FFBD" : "#F2EAF7"
          }`,
        }}
      >
        {question.attributes.is_correct &&
        (question.attributes.user_answer ||
          question.attributes.attempted_data) ? (
          <p className="correct-answer">
            <span>
              <CheckCircleIcon style={{ color: "#9BBB4C", fontSize: "2em" }} />
            </span>{" "}
            You answered this correctly. The answer is{" "}
            {question.attributes.correct_answer}
          </p>
        ) : (
          !question.attributes.is_unattempt &&
          !question.attributes.is_unattended && (
            <p className="correct-answer">
              <span>
                <CancelIcon style={{ color: "#DD1000", fontSize: "2em" }} />
              </span>{" "}
              Your answer{" "}
              {question.attributes.user_answer ||
                question.attributes.attempted_data}{" "}
              is an incorrect answer
            </p>
          )
        )}
        {(!question.attributes.is_unattended ||
          !question.attributes.is_unattempt) &&
          !question.attributes.is_correct && (
            <p className="correct-answer">
              <span>
                <CheckCircleIcon
                  style={{ color: "#9BBB4C", fontSize: "2em" }}
                />
              </span>{" "}
              Correct Answer is {question.attributes.correct_answer}
            </p>
          )}
        {(question.attributes.is_unattended ||
          question.attributes.is_unattempt) && (
          <p className="did-not-attend" style={{ color: "#DE697C" }}>
            <span>
              <InfoIcon style={{ color: "#DD4256", fontSize: "2em" }} />
            </span>{" "}
            You didn't attend this question
          </p>
        )}
        <p>
          {question.attributes.percentage_people_correct?.toFixed(2) || 0}% of
          people got it correct
        </p>
      </Box>
      <Box width="100%" marginTop={"2em"} marginBottom={"1em"}>
        <h2>Explanation</h2>
        <Box sx={webStyle.explanationStyle}>
          <div style={{ fontSize: "1em" }}>
            <div
              style={{ maxWidth: "100%", wordWrap: "break-word" }}
              dangerouslySetInnerHTML={{
                __html: question.attributes.explanation as string,
              }}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewTestComponent;
