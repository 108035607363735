import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { styles } from "./styles";
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

export interface Root {
  data: Daum[];
  meta: Meta;
}

export interface Daum {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  question_id: number;
  grand_test_id: number;
  gt_review_id: number;
  sub_name: string;
  question_no: number;
  question: string;
  options: Option[];
  correct_answer: string;
  explanation: string;
  heading: string;
  p_mark: string;
  n_mark: string;
  question_type: string;
  is_correct: boolean;
  is_wrong: boolean;
  is_unattempt: boolean;
  attempted_data: string | null;
  percentage_people_correct: number;
}

export interface Option {
  id: string;
  option: string;
}

export interface Meta {
  grand_test_id: number;
  taken_time: string;
  total_questions: number;
  attempted_questions: number;
  un_attempted_questions: number;
  correct_answer: number;
  incorrect_answer: number;
  max_marks: number;
  marks: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof styles> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  reviewDataGrand: Daum[];
  metaDataGrand: Meta | null;
  currentSubjectTestData: Daum[];
  openSaveNotesModalGrand: boolean;
  openBookmarkModalGrand: boolean;
  openBookmarkSuccessModalGrand: boolean;
  loading: boolean;
  correctPercentage: number | null;
  incorrectPercentage: number | null;
  unattendedPercentage: number | null;
  reviewData: Array<{
    subject_name: string;
    correct: number;
    incorrect: number;
    unattempted: number;
    totalMarks: number;
  }>;
  currentSubjectTestDataIndex: number;
  currentDataIndex: number;
  isTabular: boolean;
  breadcrumb: Array<{ link: string; label: string }>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GrandTestReviewTestMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  bookmarkHistoryMessageId = "";
  subjectId = "";
  breadcrumb = [
    {
      label: "Grand Test",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}`,
    },
    {
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}`,
      label: "Chapters",
    },
    {
      label: "Reviews",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}/review-test/${this.props.navigation.getParam("subjectId")}`,
    },
    {
      label: "Reviews",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}/review-test/${this.props.navigation.getParam("subjectId")}`,
    },
    {
      label: "Review Test",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  getReviewHistoryMessageId = "";
  getPackageReviewTestMessageId = "";
  getTestNameMessageRTMId = "";
  testId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      reviewDataGrand: [],
      openSaveNotesModalGrand: false,
      openBookmarkModalGrand: false,
      currentSubjectTestData: [],
      metaDataGrand: null,
      openBookmarkSuccessModalGrand: false,
      loading: false,
      correctPercentage: null,
      incorrectPercentage: null,
      unattendedPercentage: null,
      reviewData: [],
      isTabular: false,
      currentSubjectTestDataIndex: 0,
      currentDataIndex: 0,
      breadcrumb: this.breadcrumb,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseData = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getReviewHistoryMessageId) {
      if (responseData.data) {
        this.handleSuccessfulReviewFetch(responseData);
      }
    }
    if (apiRequestCallId === this.getPackageReviewTestMessageId) {
      if (responseData.data) {
        this.handleSuccessfulPackage(responseData.data);
      }
    }
    if (apiRequestCallId === this.bookmarkHistoryMessageId) {
      if (responseData.data) {
        this.handleSuccessfulBookmark();
      }
    }
    if (apiRequestCallId === this.getTestNameMessageRTMId) {
      if (responseData.data) {
        this.handleSuccessfulFetchTest(responseData.data);
      }
    }
    // Customizable Area End
  }

  // web events
  handleSuccessfulFetchTest = (
    response: { id: string; attributes: { test_name: string } }[]
  ) => {
    const packageId = this.props.navigation.getParam("testId");

    response.forEach((data) => {
      if (data.id === packageId) {
        const breadcrumb = this.state.breadcrumb;
        breadcrumb[2] = { ...breadcrumb[2], label: data.attributes.test_name };

        const propPassingRTM = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingRTM.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingRTM);

        this.setState(
          {
            breadcrumb,
            loading: false,
          },
          () => {}
        );
      }
    });
  };

  handleSuccessfulPackage = (
    response: { gt_code: number; package_name: string }[]
  ) => {
    const packageId = this.props.navigation.getParam("packageId");
    response.forEach((data) => {
      if (data.gt_code === packageId) {
        const breadcrumb = this.state.breadcrumb;
        breadcrumb[1] = { ...breadcrumb[1], label: data.package_name };

        const propPassingRTM = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingRTM.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingRTM);

        this.setState(
          {
            breadcrumb,
            loading: false,
          },
          () => {
            this.getTestNameMessageRTM();
          }
        );
      }
    });
  };

  handleSuccessfulReviewFetch = (response: Root) => {
    const total =
      response.meta.correct_answer +
      response.meta.incorrect_answer +
      response.meta.un_attempted_questions;

    this.handleReviewData(response);

    this.setState(
      {
        reviewDataGrand: response.data,
        metaDataGrand: response.meta,
        loading: false,
        correctPercentage: Number(
          ((response.meta.correct_answer / total) * 100).toFixed(2)
        ),
        incorrectPercentage: Number(
          ((response.meta.incorrect_answer / total) * 100).toFixed(2)
        ),
        unattendedPercentage: Number(
          ((response.meta.un_attempted_questions / total) * 100).toFixed(2)
        ),
      },
      () => {
        this.handleTestSubjects(response.data);
        this.getPackageReviewTestMessage();
      }
    );
  };

  handleTestSubjects = (response: Daum[]) => {
    const currentTestSubjectData = this.state.reviewDataGrand.filter(
      (testSubject) =>
        testSubject.attributes.sub_name === response[0].attributes.sub_name
    );

    this.setState({
      currentSubjectTestData: currentTestSubjectData,
    });
  };

  handleNotesModalClose = () => {
    this.setState({
      openSaveNotesModalGrand: false,
    });
  };

  componentDidMount = async () => {
    this.subjectId = this.props.navigation.getParam("subjectId");
    this.testId = this.props.navigation.getParam("testId");
    const propPassingGTRTMC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingGTRTMC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingGTRTMC);
    this.getReviewHistoryGrandTest(this.testId, this.subjectId);
  };

  handleSubjectChange = (subject: string, index: number) => {
    const currentTestData = this.state.reviewDataGrand.filter(
      (subjectData) => subjectData.attributes.sub_name === subject
    );

    if (currentTestData.length !== 0) {
      this.setState({
        currentSubjectTestData: currentTestData,
        currentSubjectTestDataIndex: index,
        currentDataIndex: 0,
      });
    }
  };

  handleBookmarkModalOpen = () => {
    this.setState({
      openBookmarkModalGrand: true,
    });
  };

  handleBookmarkModalClose = () => {
    this.setState({
      openBookmarkModalGrand: false,
    });
  };

  handleBookmarkSuccessModalClose = () => {
    this.setState({
      openBookmarkSuccessModalGrand: false,
    });
  };
  handleNotesModalOpen = () => {
    this.setState({
      openSaveNotesModalGrand: true,
    });
  };

  getBtnClassName = (index: number) => {
    const current = this.state.currentSubjectTestData[index].attributes;

    if (current.is_correct) {
      return "btn-answered";
    }

    if (current.is_unattempt) {
      return "btn-unanswered";
    }

    if (!current.is_correct && !current.is_unattempt) {
      return "btn-unanswered-marked";
    }
  };

  handleBookmarkSuccessModalOpen = () => {
    this.setState({
      openBookmarkSuccessModalGrand: true,
    });
  };

  handleSpecificQuestion = (index: number) => {
    this.setState({
      currentDataIndex: index,
    });
  };

  handleNextQuestion = () => {
    if (
      this.state.currentDataIndex !==
      this.state.currentSubjectTestData.length - 1
    ) {
      this.setState({
        currentDataIndex: this.state.currentDataIndex + 1,
      });
    }
  };

  handleSuccessfulBookmark = () => {
    this.handleBookmarkModalClose();
    this.handleBookmarkSuccessModalOpen();
  };

  handleReviewData = (response: { data: Daum[] }) => {
    const responseData: Daum[] = response.data;
    const reviewDataMap: {
      [subjectName: string]: {
        correct: number;
        subject_name: string;
        unattempted: number;
        incorrect: number;
        totalMarks: number;
      };
    } = {};

    responseData.forEach((subject: Daum) => {
      const subjectName = subject.attributes.sub_name;
      const existingData = reviewDataMap[subjectName] || {
        unattempted: 0,
        subject_name: subjectName,
        incorrect: 0,
        correct: 0,
        totalMarks: 0,
      };

      existingData.incorrect += subject.attributes.is_wrong ? 1 : 0;
      existingData.unattempted += subject.attributes.is_unattempt ? 1 : 0;
      existingData.correct += subject.attributes.is_correct ? 1 : 0;
      existingData.totalMarks += subject.attributes.is_correct
        ? Number(subject.attributes.p_mark)
        : Number(subject.attributes.n_mark);

      reviewDataMap[subjectName] = existingData;
    });

    const reviewData = Object.values(reviewDataMap);

    this.setState({
      reviewData,
    });
  };

  getReviewHistoryGrandTest = async (testId: string, subjectId: string) => {
    const getReviewHistoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    this.setState({
      loading: true,
    });

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    this.getReviewHistoryMessageId = getReviewHistoryMessage.messageId;
    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getReviewHistoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/${subjectId}/review_test/${testId}`
    );
    runEngine.sendMessage(getReviewHistoryMessage.id, getReviewHistoryMessage);
  };

  handlePreviousQuestion = () => {
    if (this.state.currentDataIndex !== 0) {
      this.setState({
        currentDataIndex: this.state.currentDataIndex - 1,
      });
    }
  };

  setTabular = () => {
    this.setState({
      isTabular: true,
    });
  };

  setGraphical = () => {
    this.setState({
      isTabular: false,
    });
  };

  // Customizable Area Start

  bookmarkSave = async () => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const bookmarkHistoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const body = {
      bookmarkable_id: this.testId,
      bookmarkable_type: "grand_test_review",
      question_id:
        this.state.currentSubjectTestData[
          this.state.currentSubjectTestDataIndex
        ].attributes.question_id,
      question_no:
        this.state.currentSubjectTestData[
          this.state.currentSubjectTestDataIndex
        ].attributes.question_no,
    };

    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/bookmarks`
    );
    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    this.bookmarkHistoryMessageId = bookmarkHistoryMessage.messageId;

    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    bookmarkHistoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(bookmarkHistoryMessage.id, bookmarkHistoryMessage);
  };

  getPackageReviewTestMessage = async () => {
    const getPackageMessageMain = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getPackageReviewTestMessageId = getPackageMessageMain.messageId;

    getPackageMessageMain.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getPackageMessageMain.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/get_packages`
    );

    getPackageMessageMain.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getPackageMessageMain.id, getPackageMessageMain);
  };

  getTestNameMessageRTM = async () => {
    const getTestNameMessageRTM = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getTestNameMessageRTMId = getTestNameMessageRTM.messageId;

    getTestNameMessageRTM.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getTestNameMessageRTM.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/${this.props.navigation.getParam(
        "subjectId"
      )}/review_histories`
    );

    getTestNameMessageRTM.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getTestNameMessageRTM.id, getTestNameMessageRTM);
  };
  // Customizable Area End
}
