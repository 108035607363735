import React from "react";

import {
  Box,
  // Customizable Area Start
  Button,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./styles/forgotpassword.scss";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CancelIcon from "@material-ui/icons/Cancel";
// Customizable Area End

import GetCredentialsController, {
  Props,
  configJSON,
} from "./GetCredentialsController.web";

export default class GetCredentials extends GetCredentialsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className="forgot-password-wrapper">
        <Box
          className="forgot-password-back"
          onClick={this.handleGoBack2}
          data-test-id="go-back"
        >
          <div className="forgot-password-back-icon">{"<"}</div>
          <p>{configJSON.goback}</p>
        </Box>
        <Box className="forgot-password-box">
          <h3>{configJSON.enterEmailCredential}</h3>
          <Box className="forgot-password-credential">
            <p>Enter email-id to send credentials</p>
            <form
              data-test-id="submit-form"
              onSubmit={(event) => this.sendCredentials(event)}
            >
              <input
                value={this.state.userInput.emailAddress}
                name="emailAddress"
                onChange={(event) => this.handleInputChange2(event)}
                placeholder="Enter mail id"
                data-test-id="email-input"
              />
              <Button data-test-id="submit-btn" type="submit">
                {configJSON.continue}
              </Button>
            </form>
          </Box>
        </Box>
        <Modal
          open={this.state.isModalOpen}
          onClose={this.handleCloseModal2}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box sx={webStyle.modalStyle}>
            <HighlightOffIcon
              data-test-id="close-modal"
              onClick={this.handleCloseModal2}
              style={webStyle.highlightOffIconStyle}
            />
            <CheckCircleIcon style={webStyle.checkCircle} />
            <p>{configJSON.sentToEmail}</p>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpen2}
          onClose={this.handleCloseModal3}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box sx={webStyle.modalStyle}>
            <HighlightOffIcon
              data-test-id="close-modal"
              onClick={this.handleCloseModal3}
              style={webStyle.highlightOffIconStyle}
            />
            <CancelIcon style={webStyle.cancelIcon} />
            <p>{configJSON.accountNotFound}</p>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  modalStyle: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
    position: "absolute" as "absolute",
    height: "210px",
    border: "none",
    backgroundColor: "white",
    borderRadius: "0.5em",
    display: "flex",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "389px",
  },
  highlightOffIconStyle: {
    position: "absolute" as const,
    top: "10px",
    right: "10px",
    fontSize: "2rem",
    cursor: "pointer",
    color: "gray",
  },
  cancelIcon: { color: "#FE5B56", fontSize: "4rem" },
  checkCircle: { color: "#FF6A54", fontSize: "4rem" },
};
// Customizable Area End
