import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isNoteCreateSavedSuccessfully: boolean;
  content: string;
  cancelModalConfirm: boolean;
  isNoteCancellationModalOpen: boolean;
  questionId: string;
  noteId: string;
  questionNumber: string;
  noteType: string;
  reviewId: string;
  chapterName: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuestionBankSavedNotesAddEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createSavedNotesMessageId = "";
  editSavedNotesMessageId = "";
  getSavedNotesMessageId = "";
  searchResolve = this.props.navigation.history.location.search;
  breadcrumb = [
    {
      label: "Question Bank",
      link: "/dashboard/question-bank",
    },
    {
      label: "Subject",
      link: `/dashboard/question-bank/subject/${this.props.navigation.getParam(
        "subjectId"
      )}`,
    },
    {
      label: "Chapter",
      link: `/dashboard/question-bank/subject/${this.props.navigation.getParam(
        "subjectId"
      )}/chapter/${this.props.navigation.getParam("chapterId")}`,
    },
    {
      label: "Saved Notes",
      link: `/dashboard/question-bank/subject/${this.props.navigation.getParam(
        "subjectId"
      )}/chapter/${this.props.navigation.getParam("chapterId")}/saved-notes`,
    },
    {
      label: "Add/Edit Notes",
      link:
        this.props.navigation.history.location.pathname + this.searchResolve,
    },
  ];
  token = "";
  getSubjectDetailsSNAECMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      content: "",
      isNoteCreateSavedSuccessfully: false,
      noteId: "",
      noteType: "",
      cancelModalConfirm: false,
      questionNumber: "",
      reviewId: "",
      isNoteCancellationModalOpen: false,
      questionId: "",
      chapterName: "",
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSavedNotesMessageId) {
      if (response.id) {
        this.setState({
          content: response.content,
        });
      }
    }
    if (apiRequestCallId === this.editSavedNotesMessageId) {
      if (response.id) {
        this.setState({
          isNoteCreateSavedSuccessfully: true,
        });
      }
    }
    if (apiRequestCallId === this.createSavedNotesMessageId) {
      if (response.id) {
        this.setState({
          isNoteCreateSavedSuccessfully: true,
        });
      }
    }
    if (apiRequestCallId === this.getSubjectDetailsSNAECMessageId && response) {
      if (response.data) {
        this.handleSubjectDetailSuccess(response.meta.subject_name);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingQBSNAEC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingQBSNAEC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingQBSNAEC);
    const token = localStorage.getItem("authToken");
    if (token) {
      this.token = token;
    }
    const windowUrl = window.location.href;
    const urlParams = new URLSearchParams(windowUrl.split("?")[1]);
    const noteId = urlParams.get("noteId");
    const noteType = urlParams.get("noteType");
    const questionNumber = urlParams.get("qno");
    const questionId = urlParams.get("qid");
    const reviewId = urlParams.get("reviewId");
    const chapterName = urlParams.get("chapterName") as string;

    this.setState({
      chapterName: chapterName,
    });

    if (noteId) {
      this.setState(
        {
          noteId,
        },
        () => {
          this.GetNote(noteId);
        }
      );
    }
    if (noteType && reviewId) {
      this.setState({
        noteType,
        questionNumber: questionNumber as string,
        reviewId,
        questionId: questionId as string,
      });
    }
    const subjectId = this.props.navigation.getParam("subjectId");
    this.getSubjectDetailsSNAEC(subjectId);
  };

  componentDidUpdate = (prevProps: Props, prevState: S) => {
    const windowUrl = window.location.href;
    const urlParams = new URLSearchParams(windowUrl.split("?")[1]);
    const reviewId = urlParams.get("reviewId");

    if (reviewId !== prevState.reviewId) {
      this.handleReviewIdChange(reviewId as string);
    }
  };

  handleNoteCancelModalClose = () => {
    this.setState({
      isNoteCancellationModalOpen: false,
    });
  };
  handleReviewIdChange = (reviewId: string) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      this.token = token;
    }

    this.setState({
      reviewId,
    });
  };
  handleNoteCancelModalOpen = () => {
    this.setState({
      isNoteCancellationModalOpen: true,
    });
  };
  handleInputChange = (value: string) => {
    this.setState({
      content: value,
    });
  };
  handleNoteCancelConfirmModalOpen = () => {
    this.setState({
      cancelModalConfirm: true,
    });
  };
  handleNoteCancelConfirmModalClose = () => {
    this.setState({
      cancelModalConfirm: false,
    });
  };
  confirmCancel = () => {
    this.handleNoteCancelModalClose();
    this.handleNoteCancelConfirmModalOpen();
  };
  handleNoteCreateModalClose = () => {
    this.setState({
      isNoteCreateSavedSuccessfully: false,
    });
  };

  // Customizable Area Start
  NoteApi = () => {
    const headers = {
      token: this.token,
      "Content-Type": "application/json",
    };

    const createSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let body;

    if (this.state.noteType === "Edit") {
      this.editSavedNotesMessageId = createSavedNotesMessage.messageId;
      body = {
        content: this.state.content,
      };
      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_assessmenttest/notes/${this.state.noteId}`
      );
    } else {
      body = {
        noteable_id: this.state.questionId,
        noteable_type: "qb_ipe",
        content: this.state.content,
        question_no: this.state.questionNumber,
        question_id: this.state.questionId,
      };
      this.createSavedNotesMessageId = createSavedNotesMessage.messageId;
      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      createSavedNotesMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_assessmenttest/notes`
      );
    }

    createSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    createSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(createSavedNotesMessage.id, createSavedNotesMessage);
  };

  GetNote = (noteId: string) => {
    this.setState({
      loading: true,
    });
    const headers = {
      token: this.token,
    };

    const getSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSavedNotesMessageId = getSavedNotesMessage.messageId;

    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/notes/${noteId}`
    );

    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getSavedNotesMessage.id, getSavedNotesMessage);
  };

  getSubjectDetailsSNAEC = (subjectId: string) => {
    this.setState({
      loading: true,
    });
    const getSubjectDetailsSNAECMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: localStorage.getItem("authToken"),
    };
    getSubjectDetailsSNAECMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_questionbank/${subjectId}/qb_chapters`
    );
    this.getSubjectDetailsSNAECMessageId =
      getSubjectDetailsSNAECMessage.messageId;
    getSubjectDetailsSNAECMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSubjectDetailsSNAECMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getSubjectDetailsSNAECMessage.id,
      getSubjectDetailsSNAECMessage
    );
  };

  handleSubjectDetailSuccess = (subjectName: string) => {
    const breadcrumb = this.breadcrumb;
    breadcrumb[1].label = subjectName;

    const propPassingQBMC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingQBMC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingQBMC);
  };
  // Customizable Area End
}
