export const myrankicon = require("../assets/myranklogo.png");
export const office = require("../assets/office.jpg");
export const rectangle = require("../assets/rectangle.png");
export const backImg = require("../assets/back.png");
export const linearButton = require('../assets/button_filled.png')
export const Location = require('../assets/icons8-location-48.png')
export const mail = require('../assets/icons8-mail-48.png')
export const phoneNumber = require('../assets/icons8-phone-50.png')
export const timing = require('../assets/icons8-time-24.png')
export const refreshCaptcha = require('../assets/rotateRight.png');
export const listenCaptcha = require('../assets/volumeHigh.png');
export const Submit = require('../assets/submitIcon.png');
export const SuccessFull = require ('../assets/verification.png');