import { v4 as uuidv4 } from "uuid";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export default class BreadcrumbNavigationAdapter {
  send: (message: Message) => void;

  constructor() {
    const blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.NavigationBreadcrumbMessage),
    ]);
  }

  convert = (from: Message): Message => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BreadcrumbNavigation"
    );

    // remove the lines below if you don't want to pass params
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      from.getData(getName(MessageEnum.BreadcrumbDataMessage))
    );

    to.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    // remove the lines above if you don't want to pass params
    return to;
  };

  receive(from: string, message: Message): void {
    this.send(this.convert(message));
  }
}
