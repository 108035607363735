import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { myranklogo } from "./assets";
// Customizable Area End

import CarouselAdvertisementController, {
  Props,
  configJSON,
} from "./CarouselAdvertisementController.web";

class CarouselAdvertisement extends CarouselAdvertisementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (_: number, next: number) => {
        this.handleCurrentIndex(next);
      },
    };
    return (
      // Customizable Area Start
      <Box>
        <Box
          data-test-id="herosection"
          className={this.props.classes.herosectionLanding}
        >
          <Box className={this.props.classes.containerStyle}>
            <Box className={this.props.classes.headerStyle}>
              <Typography variant={"h5"}>
                {this.state.carouselData.length > 0
                  ? this.state.carouselData[this.state.currentIndex].attributes
                      .exam_name
                  : configJSON.testname}
              </Typography>
              <img
                className={this.props.classes.imgLogoStyle}
                style={{ position: "absolute" }}
                src={myranklogo}
                alt="main-logo"
              />
            </Box>
            <Box className="hero-section-slider">
              <Slider {...settings}>
                {this.state.carouselData.map((data) => {
                  return (
                    <Box>
                      <Box className={this.props.classes.carouselStyle}>
                        <img
                          key={`graduate-${data.attributes.id}`}
                          className={this.props.classes.imageStyle}
                          src={data.attributes.image}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Slider>
            </Box>
          </Box>
        </Box>
        <Box className={this.props.classes.pathStyle} />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleCarouselAdvertisement = () =>
  createStyles({
    herosectionLanding: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#520082",
      paddingTop: "2em",
      paddingBottom: "3em",

      "& .hero-section-slider": {
        height: "800px",
      },

      "& .slick-prev:before, & .slick-next:before": {
        background:
          "linear-gradient(90deg, rgba(255, 57, 86, 1) 0%, rgba(255, 108, 83, 1) 100%)",
        borderRadius: "50%",
        fontSize: "28px",
        opacity: 1,
        backgroundClip: "text",
        WebkitTextFillColor: "transparent",
      },

      "& .slick-next": {
        right: "13px",
      },

      "& .slick-prev": {
        left: "5px",
        zIndex: 100,
      },

      "& .slick-dots": {
        bottom: "20px",
        display: "flex !important",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        gap: "1em",

        "& li button:before": {
          content: "none",
        },

        "& li button": {
          width: "15px",
          height: "15px",
          background:
            "linear-gradient(0deg, rgba(199, 198, 199, 1) 0%, rgba(154, 154, 154, 1) 100%)",
          borderRadius: "50%",
          transition: "all 0.3s ease",
        },

        "& .slick-active button": {
          background:
            "linear-gradient(0deg, rgba(66, 11, 126, 1) 0%, rgba(129, 39, 143, 1) 100%)",
          width: "50px",
          position: "relative",
          borderRadius: "10px",
          left: "-17px",
        },
      },
    },
    containerStyle: {
      backgroundColor: "#FFEAEA",
      borderRadius: "1em",
      width: "92%",
      height: "max-content",
    },
    headerStyle: {
      color: "#FFFFFF",
      fontSize: "1.2em",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#452280",
      borderRadius: "0.8em",
      width: "100%",
      height: "10vh",
      position: "relative" as const,
    },
    imgLogoStyle: {
      right: "10px",
      width: "149.51px",
      height: "45.62px",
    },
    carouselSampleStyle: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      gap: "2em",
    },
    imageStyle: {
      maxWidth: "80%",
    },
    carouselStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "3em",
      flexWrap: "wrap",
      height: "100%",
      padding: "1em 5em",
    },
  });
export default withStyles(webStyleCarouselAdvertisement)(CarouselAdvertisement);
export { CarouselAdvertisement };
// Customizable Area End
