import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

interface AppointmentAttributes {
  id: number;
  student_name: string;
  parent_name: string;
  mobile_number: string;
  email: string;
  slot_date: string;
  time_slot: string;
  cm_id: string;
  cm_officer_id: string;
  created_by: string;
  group: string;
  status: string;
  slot_type: string;
  zoom_url: string;
  comment: string;
  image_url: string;
  remarks: string;
  rating: string;
  package: { pack_final_id: number }[];
}

interface AppointmentData {
  id: string;
  type: string;
  attributes: AppointmentAttributes;
}

type AppointmentDataArray = AppointmentData[];

interface AppointmentAPI {
  data: AppointmentDataArray;
}

enum EAppointment {
  registration_done = 0,
  rejected = 1,
  follow_up = 2,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tableDataAppointments: AppointmentDataArray;
  cacheData: AppointmentDataArray;
  numberOfLoadingFromAPI: number;
  activeIndex: number;
  currentPage: number;
  itemsPerPage: number;
  input: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotAppointmentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentsMessageId = "";
  breadcrumb = [
    {
      label: "Book my Slot",
      link: `/dashboard/book-my-slot`,
    },
    {
      label: "Slots",
      link: `/dashboard/book-my-slot`,
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Appointments",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      numberOfLoadingFromAPI: 0,
      tableDataAppointments: [],
      activeIndex: 0,
      currentPage: 1,
      itemsPerPage: 10,
      input: "",
      cacheData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getAppointmentsMessageId && response) {
      if (response?.data) {
        this.appointmentDataSuccess(response);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getAppointments("registration_done");

    const propPassingAppointment = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingAppointment.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingAppointment);
  };

  componentDidUpdate = (_prevProps: Props, _prevState: S) => {
    const { input } = this.state;

    if (input.length > 0 && input !== _prevState.input) {
      this.handleSearchOnAllAppointments();
    }

    if (input.length === 0 && _prevState.input.length > 0) {
      this.handleUseCacheData();
    }
  };

  // Customizable Area Start
  appointmentDataSuccess = (response: AppointmentAPI) => {
    this.setState({
      tableDataAppointments: [...response.data],
      cacheData: [...response.data],
      numberOfLoadingFromAPI: this.state.numberOfLoadingFromAPI - 1,
    });
  };

  handleSearchOnAllAppointments = () => {
    const { cacheData } = this.state;
    const searchTerm = this.state.input;

    const filterAppointments = (appointments: AppointmentData[]) => {
      return appointments.filter((appointment) => {
        const { student_name, parent_name, group, email, mobile_number } =
          appointment.attributes;
        return (
          student_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          parent_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          group.toLowerCase().includes(searchTerm.toLowerCase()) ||
          mobile_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
          email.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    };

    const filteredData = filterAppointments(cacheData);

    this.setState({
      tableDataAppointments: filteredData,
    });
  };

  handleUseCacheData = () => {
    this.setState({
      tableDataAppointments: this.state.cacheData,
    });
  };

  navigateToAddNewSlot = () => {
    storage.remove("dateTimeSlot");
    storage.remove("userInputSelect");
    storage.remove("userInputProps");
    this.props.navigation.navigate("BookMySlotAddEditSlot");
  };

  getAppointments = async (appointmentType?: keyof typeof EAppointment) => {
    let activeIndex = 0;
    let status = "";

    const getAppointmentsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getAppointmentsMessageId = getAppointmentsMessage.messageId;

    if (appointmentType === EAppointment[0]) {
      status = EAppointment[0];
      activeIndex = 0;
    }

    if (appointmentType === EAppointment[1]) {
      status = EAppointment[1];
      activeIndex = 2;
    }

    if (appointmentType === EAppointment[2]) {
      status = EAppointment[2];
      activeIndex = 1;
    }

    this.setState({
      numberOfLoadingFromAPI: this.state.numberOfLoadingFromAPI + 1,
      activeIndex,
    });

    getAppointmentsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/cms_bookeds?status=${status}`
    );

    getAppointmentsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAppointmentsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getAppointmentsMessage.id, getAppointmentsMessage);
  };

  getButtonStyle = (index: number) => {
    if (index === this.state.activeIndex) {
      return {
        color: "rgb(82, 0, 130)",
        borderBottom: "4px solid rgb(82, 0, 130)",
        borderBottomRightRadius: "0",
        borderBottomLeftRadius: "0",
      };
    }
  };

  handlePageChange = (value: number) => {
    this.setState({
      currentPage: value,
    });
  };

  handleInput = (input: string) => {
    this.setState({
      input,
    });
  };

  navigateToUpdateFeedback = (appointmentId: string) => {
    this.props.navigation.history.push(
      `/dashboard/book-my-slot/appointments/feedback?slotId=${appointmentId}`
    );
  };

  editAppointment = (appointmentId: string) => {
    this.props.navigation.history.push(
      `/dashboard/book-my-slot/appointments/feedback?slotId=${appointmentId}&editable=true`
    );
  };
  // Customizable Area End
}
