Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.ContactUS = "Contact US"
exports.InfoLabel = "Contact us to add more Package"
exports.active = "Active"
exports.putmethod = "PUT"
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.headerLabel = 'My Package'
exports.myPackageEndPoint = "/account_block/profile_settings/my_packages"
exports.activePageEndPoint = "/bx_block_plan/packages/{id}/active_package"
exports.deactivePageEndPoint = "/bx_block_plan/packages/{id}/deactive_package"
exports.titleLabel = "Package"
exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.packagesLabel = "Package Level"
exports.askYourQuerry = "Ask Your Querry"
exports.LiveClass = "Live Classes"
exports.ExpiryDate = "Expiry date"
exports.AmountPaid = "Amount Paid"
exports.RegisterID = "Register ID"
exports.rankID = "My Rank ID"
exports.studentName = "Student Name"
exports.mobileNumber= "Mobile Number"
exports.errorTitle = "Error"
exports.rDetailsLabel = "Registration Details"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.Token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6Mzc0LCJleHAiOjE2OTQ1ODM0MDksInRva2VuX3R5cGUiOiJsb2dpbiJ9.lXRqndp38qwSMxUVYfxIy_74e3GucylJXqpPt11LLGXrB7fumCFz8gMVeg4tgC0JTehLbGuRg4sj6dkYUPh-DQ"

// Customizable Area End