import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

export type ExamNotifType = {
  id: string;
  type: string;
  attributes: {
    id: number;
    exam_name: string;
    course: string;
    notice_date: null | string;
    last_date: null | string;
    exam_date: null | string;
    typeof_exam: string;
    description: string;
    seat_matrix: string;
    released: null | string;
    application_mode: string;
    application_cost: string;
    modeof_payment: string;
    cource_notification_id: number;
    created_at: string;
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  examNotifications: ExamNotifType[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LiveChat2Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  examNotificationsMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      examNotifications: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.examNotificationsMessageId && response) {
      if (response.data) {
        this.setState({
          examNotifications: response.data,
        });
      }
    }
    // Customizable Area End
  }

  // web events
  // Customizable Area Start
  componentDidMount = async () => {
    this.getExamNotifications();
  };

  getExamNotifications = async () => {
    const header = {
      token: await storage.get("authToken"),
    };

    const examNotificationsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.examNotificationsMessageId = examNotificationsMessage.messageId;
    examNotificationsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.examNotificationEndpoint
    );
    examNotificationsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    examNotificationsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(
      examNotificationsMessage.id,
      examNotificationsMessage
    );
  };
  // Customizable Area End
}
