Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.buttonLabel = "GET THIS PACK";
exports.videoLabel = "Sample leature"
exports.videoEndPoint = "/account_block/guest_users/live_class_sample_lectures"
exports.uuid = "c2dcc964-546c-4034-bff9-6b00f10a2638"
exports.liveClassEndPoint = "/account_block/guest_users/live_class_advantages"
exports.liveClassLabel = "Live Classes Advantages"
exports.labelText = "Live Classes"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Videos";
exports.labelBodyText = "Videos Body";
exports.exampleVideoURL = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
exports.btnExampleTitle = "CLICK ME";
exports.labels = "Live online INTERACTIVE classes \n Highly qualified online TEACHING EXPERTS \n Clarity your doubts THEN & THEIR \n Focus on HIGH WEIGHTAGE TOPICS (HWT) \n Chance for ONE ON ONE interaction.Classes are done by IITians, PhD & senior faculties \n DAILY & WEEKLY track of your performance \n Clarity UNLIMITED DOUBTS in the live sessions \n Practice Practice Practice 100% to get a top rank."
// Customizable Area End