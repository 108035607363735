import React from "react";

import {
  // Customizable Area Start
  Box as BoxMUI,
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MathJaxBox from "../../../components/src/MathJaxBox.web";
// Customizable Area End

import AYQQuestionsAskedController, {
  Props,
} from "./AYQQuestionsAskedController.web";

class AYQQuestionsAsked extends AYQQuestionsAskedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <BoxMUI>
        <BoxMUI className={this.props.classes.bannerStyle}>
          <Typography variant="h5">Questions asked</Typography>
        </BoxMUI>
        <BoxMUI className={this.props.classes.questionsAskedStyle}>
          {this.state.questionsAsked.length > 0 ? (
            this.state.questionsAsked.map((question, index) => {
              return (
                <BoxMUI
                  className={this.props.classes.questionBoxStyle}
                  position={"relative"}
                  key={`${question.attributes.account_id}-${index}`}
                >
                  <BoxMUI
                    position="absolute"
                    left="20px"
                    top="20px"
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ArrowDropUpIcon
                      data-test-id="like-btn-q"
                      style={{
                        color:
                          question.attributes.current_user_liked === 1
                            ? "green"
                            : "#1f1f1f",
                        cursor: "pointer",
                      }}
                      onClick={() => this.likeDislike(1, index)}
                    />
                    <Typography variant="body1">
                      {question.attributes.current_user_liked}
                    </Typography>
                    <ArrowDropDownIcon
                      data-test-id="dislike-btn-q"
                      style={{
                        color:
                          question.attributes.current_user_liked === -1
                            ? "red"
                            : "#1f1f1f",
                        cursor: "pointer",
                      }}
                      onClick={() => this.likeDislike(-1, index)}
                    />
                  </BoxMUI>
                  <MathJaxBox content={question.attributes.question} />
                  <BoxMUI className="main-wrapper">
                    <ul>
                      {question.attributes.tag_list.map((tagList) => {
                        return (
                          <li key={tagList}>
                            {tagList
                              .slice(0, 1)
                              .toUpperCase()
                              .concat(tagList.slice(1))}
                          </li>
                        );
                      })}
                    </ul>
                    <BoxMUI
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      border={"1px solid rgb(254,110,82)"}
                      padding="0.5em"
                      width={"max-content"}
                      marginTop="0.6em"
                      borderRadius={"0.2em"}
                      style={{ cursor: "pointer" }}
                      onClick={() => this.viewAyqQuestion(question.id)}
                      data-test-id="question-view-btn"
                    >
                      <CheckCircleIcon className="check-circle" />
                      <span>{question.attributes.answer_count} Answers</span>
                    </BoxMUI>
                    <BoxMUI
                      position={"absolute"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      right="25px"
                      bottom="20px"
                      display="flex"
                      data-test-id="profile-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.goToProfile(
                          question.attributes.user_profile?.data.id
                        )
                      }
                    >
                      <img
                        src={
                          question.attributes.user_profile?.data.attributes
                            .image
                        }
                        alt="picture"
                        width={"25px"}
                        height={"25px"}
                      />
                      <span className="profile-span">
                        {question.attributes.user_profile?.data.attributes.name}
                        <span style={{ marginLeft: "0.4em" }}>
                          {this.getTimeDifference(
                            question.attributes.created_at
                          )}
                        </span>
                      </span>
                    </BoxMUI>
                  </BoxMUI>
                </BoxMUI>
              );
            })
          ) : (
            <BoxMUI>
              <Typography>There are no question found.</Typography>
            </BoxMUI>
          )}
        </BoxMUI>
      </BoxMUI>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleAYQQuestionsAsked = () =>
  createStyles({
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "#510081",
      display: "flex",
      background: "rgb(247,242,249)",
      height: "71px",
      "& h5": {
        fontWeight: 700,
      },
    },
    questionBoxStyle: {
      display: "flex",
      flexDirection: "column",
      padding: "1em 0.3em 3em 4em",
      background: "rgb(240,240,240)",
      margin: "1em",
      height: "max-content",
      border: "none",
      minHeight: "200px",
      "& .main-wrapper": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        "& .profile-span": {
          "& span": {
            color: "gray",
            fontSize: "0.8em",
          },
          fontWeight: "500",
          fontSize: "1.2em",
        },
        "& img": {
          borderRadius: "50%",
          marginRight: "0.2em",
        },
        "& .check-circle": {
          color: "rgb(4,147,69)",
          marginRight: "0.6em",
          fontSize: "1.2em",
        },
        "& p": {
          fontSize: "1.35em",
        },
        "& ul": {
          flexWrap: "wrap",
          gap: "1em",
          margin: "1em 0",
          display: "flex",
          "& li": {
            border: "none",
            fontWeight: "500",
            borderRadius: "0.5em",
            padding: "0.3em 1em",
            background: "rgb(233,225,240)",
          },
        },
      },
    },
    questionsAskedStyle: {
      padding: "2em",
    },
  });
export default withStyles(webStyleAYQQuestionsAsked)(AYQQuestionsAsked);
export { AYQQuestionsAsked };
// Customizable Area End
