import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

type CouncellingDataType = {
  data: Array<CouncellingData>;
};

type CouncellingData = {
  id: string;
  type: string;
  attributes: {
    id: number;
    couns_name: string;
    short_name: string;
    status: string;
    website: string;
    link_myrank: string;
    remarks: string;
  };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  councellingData: CouncellingData[];
  loading: boolean;
  currentPage: number;
  numberOfPages: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseFinderCounsellingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCounsellingMessageId = "";
  dataPerPage = 10;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      councellingData: [],
      loading: false,
      currentPage: 0,
      numberOfPages: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (response && apiRequestCallId === this.getAllCounsellingMessageId) {
      if (response.data) {
        this.successfulFetchCounsellings(response as CouncellingDataType);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getAllCounselling();
  };

  changePage = (pageNumber: number) => {
    if (pageNumber < this.state.numberOfPages) {
      this.setState({
        currentPage: pageNumber,
      });
    }
  };

  successfulFetchCounsellings = (response: CouncellingDataType) => {
    this.setState(
      {
        councellingData: response.data,
        loading: false,
      },
      () => {
        const dataLength = this.state.councellingData.length;
        this.setState({
          numberOfPages: Math.ceil(dataLength / this.dataPerPage),
        });
      }
    );
  };
  // Customizable Area Start
  getAllCounselling = async () => {
    this.setState({
      loading: true,
    });

    const getAllCounsellingMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getAllCounsellingMessageId = getAllCounsellingMessage.messageId;

    getAllCounsellingMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getAllCounsellingMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allCounselings
    );

    getAllCounsellingMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getAllCounsellingMessage.id,
      getAllCounsellingMessage
    );
  };
  // Customizable Area End
}
