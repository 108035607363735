export const arrowCircle = require('../assets/arrowCircle.png')
export const BTNbackgrrud = require('../assets/buttonFilled.png')
export const calendar = require("../assets/calendar.png");
export const camera = require('../assets/Group.png')
export const Edit = require('../assets/edit.png');
export const Physics = require('../assets/PhysicsImg.png');
export const starIcon = require('../assets/starIcon.png');
export const emptyStar = require("../assets/emptyStar.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const takeTestImg = require("../assets/takeTestImg.png");
export const PrevImg = require("../assets/PrevImg.png");
export const timerImg = require("../assets/timerImg.png");
export const backImg = require("../assets/back.png");
export const info = require("../assets/info.png");
export const leftImg = require("../assets/left.png");
export const rightImg = require("../assets/right.png");
export const buttonFilled = require("../assets/buttonFilled.png");
export const submitIcon = require("../assets/submitIcon.png");
export const alarmWatch = require("../assets/Timer.png");
export const clean = require('../assets/noun-broom.png');
export const Correct = require("../assets/Timer.png");
export const subjectImg = require("../assets/subjectImage.png");
export const Incorrect = require("../assets/Shield.png");
export const gradiantButton = require("../assets/buttonFilled.png");
export const physics =  require("../assets/PhysicsImg.png");
export const button_filled = require('../assets/button_filled.png')
export const subjectImage = require('../assets/subjectImage.png');
export const rightBW = require("../assets/tickCircleBW.png");
export const rightColor =  require("../assets/tickCircleColor.png");
export const bookSlot = require("../assets/AddMeeting.png");
export const dropDown =  require("../assets/arrowCircleRight.png");
export const rightIcon = require("../assets/right.png")
export const Minutes = require("../assets/Correct.png");
export const bookmark = require("../assets/bookmark.png");
export const PhysicsImg = require("../assets/PhysicsImg.png");
export const clearResponse = require("../assets/clear.png");
export const checkmark = require("../assets/checkmark.png")
export const profileImg = require('../assets/profileImg.png');