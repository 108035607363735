import React from "react";

import {
  // Customizable Area Start
  Button as MUIButton,
  Box,
  withStyles,
  createStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
import Button from "../../../components/src/Button.web";
import Loader from "../../../components/src/Loader.web";
import { noreview, Bookmark, saveImg } from "./assets";
// Customizable Area End

import ReviewTestWeeklyMainController, {
  Props,
} from "./ReviewTestWeeklyMainController.web";

export class ReviewTestWeeklyMain extends ReviewTestWeeklyMainController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        <Loader loading={this.state.loading} />
        <Box className={this.props.classes.bannerStyle}>
          <h2>Review History</h2>
        </Box>
        {this.state.review_histories_weekly.length > 0 ? (
          <Box padding={"2em"}>
            <p style={{ fontSize: "19px" }}>
              No. of attempts : {this.state.review_histories_weekly.length}
            </p>
            <p style={{ fontSize: "20px" }}>
              Test Name : {this.state.chapterData?.test_name}
            </p>
            <div className={this.props.classes.setMiddleStyleColumn}>
              {this.state.review_histories_weekly
                .map((review, index) => {
                  return (
                    <Box>
                      <div className={this.props.classes.cardStyle}>
                        <h3>Test Attempt {index + 1}</h3>
                        <div className={this.props.classes.setMiddleStyle}>
                          <Link
                            to={`/dashboard/weekly-test/tests/review-test/${this.props.navigation.getParam(
                              "testId"
                            )}/review/${review.id}`}
                          >
                            <MUIButton
                              className={this.props.classes.buttonStyle}
                            >
                              Review Test
                              <Box
                                className={this.props.classes.goBackStep}
                                data-test-id="gobackbtn"
                              >{`>`}</Box>
                            </MUIButton>
                          </Link>
                          <p className={this.props.classes.textStyle}>
                            {this.convertToDate(review.created_at)}
                          </p>
                        </div>
                        <Box
                          position="absolute"
                          display="flex"
                          gridGap="1em"
                          right="0"
                          top="0"
                          className={this.props.classes.savedNotesBookmarkStyle}
                          padding={"0.5em 1em"}
                        >
                          <Box>
                            <img
                              src={saveImg}
                              alt="save-notes"
                              onClick={() =>
                                this.goToSavedNotes(String(review.id))
                              }
                              style={{ marginLeft: "4px" }}
                              data-test-id="saved-notes-btn"
                            />
                            <p style={{ fontSize: "10px", color: "#999" }}>
                              Notes
                            </p>
                          </Box>
                          <Box>
                            <img
                              alt="bookmark-notes"
                              src={Bookmark}
                              onClick={() =>
                                this.goToBookmarks(String(review.id))
                              }
                              style={{ marginLeft: "15px" }}
                              data-test-id="bookmark-btn"
                            />
                            <p style={{ fontSize: "10px", color: "#999" }}>
                              Bookmark
                            </p>
                          </Box>
                        </Box>
                      </div>
                    </Box>
                  );
                })
                .reverse()}
            </div>
          </Box>
        ) : (
          <div className={this.props.classes.setMiddleStyleColumn2}>
            <div className={this.props.classes.setMiddleStyleColumn2}>
              <img src={noreview} alt="noreview" width={"600px"} />
              <h2>You didn't take test to Review</h2>
              <p>Click on Take Test to get Review</p>
            </div>
            <Link
              to={`/dashboard/weekly-test/tests/take-test/${this.props.navigation.getParam(
                "testId"
              )}`}
            >
              <Button
                height="40px"
                color="white"
                width="500px"
                title="Take Test"
              />
            </Link>
          </div>
        )}
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = () =>
  createStyles({
    cardStyle: {
      height: "142px",
      border: "1px solid #FF9486",
      width: "366px",
      position: "relative",
      borderRadius: "10px",
      paddingLeft: "2em",
      paddingTop: "1em",
    },
    bannerStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "71px",
      background: "rgba(247, 214, 255, 0.19)",
      color: "#461479",
    },
    setMiddleStyle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginTop: "1em",
    },
    setMiddleStyleColumn2: {
      display: "flex",
      flexDirection: "column" as const,
      alignItems: "center",
      justifyContent: "center",
      gap: "2em",
    },
    textStyle: {
      fontSize: "12px",
      color: "rgb(72, 100, 132)",
      fontFamily: "Helvetica, system-ui, sans serif",
      fontWeight: "bold" as const,
      marginLeft: "0.5em",
    },
    setMiddleStyleColumn: {
      display: "flex",
      justifyContent: "flex-start",
      gap: "2em",
      alignItems: "flex-start",
      marginTop: "1.5em",
      flexWrap: "wrap",
      flexDirection: "row",
    },
    buttonStyle: {
      color: "rgb(82, 0, 130)",
      width: "125px",
      borderRadius: "10px",
      textTransform: "none" as const,
      border: "1px solid #FF9486",
      height: "40px",
      display: "flex",
      position: "relative",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    savedNotesBookmarkStyle: {
      border: "1px solid #FF9486",
      borderRadius: "0.5em",
      "& img": {
        width: "20px",
        height: "20px",
        cursor: "pointer",
      },
    },
    goBackStep: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      padding: "0.3em",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      color: "white",
      cursor: "pointer",
      position: "absolute",
      right: "5px",
    },
  });
export default withStyles(webStyle)(ReviewTestWeeklyMain);
// Customizable Area End
