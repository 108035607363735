import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

export type WeeklyTestStats = {
  exam_wise_overall_attempts: number;
  exam_wise_overall_correct_percentage: number;
  exam_wise_overall_wrong_percentage: number;
  exam_wise_overall_unattempted_percentage: number;
  subject_wise_overall_attempts: Array<{
    subject_name: string;
    subject_wise_attempt_count: number;
    subject_wise_overall_correct_percentage: number;
    subject_wise_overall_wrong_percentage: number;
    subject_wise_overall_unattempted_percentage: number;
  }>;
};

export type WeeklyTest = {
  id: string;
  type: string;
  attributes: {
    id: number;
    test_name: string;
    test_subject: string;
    test_category: string;
    no_of_questions: string | number | null;
    duration: number;
    start_time: string;
    end_time: string;
    status: string;
    created_at: string;
    test_submitted_already: boolean;
    image: string;
    search_key: string | number | null;
  };
};

export type WeeklyTestStatSubject = {
  subject_name: string;
  weeklytest_attempt_count: number;
  weeklytest_overall_correct_percentage: number;
  weeklytest_overall_wrong_percentage: number;
  weeklytest_overall_unattempted_percentage: number;
};

export type WeeklyTestStatSubjectAndTest = {
  subject_name: string;
  test_name: string;
  weeklytest_attempt_count: number;
  weeklytest_overall_correct_percentage: number;
  weeklytest_overall_wrong_percentage: number;
  weeklytest_overall_unattempted_percentage: number;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  testName: string;
  subjectName: string;
  testStats: WeeklyTestStats | null;
  testStatsSubject: WeeklyTestStatSubject | null;
  testStatsSubjectAndTest: WeeklyTestStatSubjectAndTest | null;
  tests: WeeklyTest[];
  testSubjects: WeeklyTest[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UsageStatsWeeklyTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUsageStatsWeeklyTestsMessageId = "";
  getUsageStatsWeeklyTestsBySubjectMessageId = "";
  getUsageStatsWeeklyTestsBySubjectAndTestMessageId = "";
  getWeeklyTestsMessageId = "";
  buttons = [
    "Synopsis",
    "Weekly Test",
    "Grand Test",
    "Practice Test",
    "Live Classes",
    "Recorded Classes",
    "Question Bank",
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      testName: "",
      testStats: null,
      testStatsSubject: null,
      testStatsSubjectAndTest: null,
      subjectName: "",
      tests: [],
      testSubjects: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      apiRequestCallId === this.getUsageStatsWeeklyTestsMessageId &&
      response
    ) {
      if (!response?.errors) {
        this.handleSuccessStatsFetch(response);
      }
    }

    if (
      apiRequestCallId === this.getUsageStatsWeeklyTestsBySubjectMessageId &&
      response
    ) {
      if (!response?.errors) {
        this.handleSuccessStatsSubjectFetch(response);
      }
    }

    if (
      apiRequestCallId ===
        this.getUsageStatsWeeklyTestsBySubjectAndTestMessageId &&
      response
    ) {
      if (!response?.errors) {
        this.handleSuccessStatsSubjectTestFetch(response);
      }
    }

    if (apiRequestCallId === this.getWeeklyTestsMessageId && response) {
      if (response?.data) {
        this.handleSuccessTestsFetch(response.data);
      }
    }

    // Customizable Area End
  }

  // web events
  async componentDidMount(): Promise<void> {
    this.getWeeklyTests();
    this.getUsageStatsWeeklyTests();
  }

  componentDidUpdate(_: Readonly<Props>, prevState: Readonly<S>): void {
    if (
      prevState.subjectName.length > 0 &&
      this.state.subjectName.length === 0
    ) {
      this.onChangeSelectTest("");
      this.handleSuccessStatsSubjectFetch(null);
      this.handleSuccessStatsSubjectTestFetch(null);
    }

    if (prevState.testName.length > 0 && this.state.testName.length === 0) {
      this.handleSuccessStatsSubjectTestFetch(null);
    }
  }

  handleSuccessStatsFetch = (response: WeeklyTestStats) => {
    this.setState({
      loading: false,
      testStats: response,
    });
  };

  handleSuccessStatsSubjectFetch = (response: WeeklyTestStatSubject | null) => {
    this.setState({
      loading: false,
      testStatsSubject: response,
    });
  };

  handleSuccessStatsSubjectTestFetch = (
    response: WeeklyTestStatSubjectAndTest | null
  ) => {
    this.setState({
      loading: false,
      testStatsSubjectAndTest: response,
    });
  };

  handleSuccessTestsFetch = (response: WeeklyTest[]) => {
    const uniqueSet = new Set<string>();
    const uniqueTestSubjects: WeeklyTest[] = [];

    response.forEach((test) => {
      if (!uniqueSet.has(test.attributes.test_subject)) {
        uniqueSet.add(test.attributes.test_subject);
        uniqueTestSubjects.push(test);
      }
    });

    this.setState({
      loading: false,
      tests: response,
      testSubjects: uniqueTestSubjects,
    });
  };

  onChangeSelectSubject = (selectedChoice: string) => {
    this.setState(
      {
        subjectName: selectedChoice,
      },
      () => {
        this.getUsageStatsWeeklyTestsBySubject();
      }
    );
  };

  onChangeSelectTest = (selectedChoice: string) => {
    this.setState(
      {
        testName: selectedChoice,
      },
      () => {
        this.getUsageStatsWeeklyTestsBySubjectAndTest();
      }
    );
  };

  // Customizable Area Start
  filteredTestsBySubject = (subjectName: string): WeeklyTest[] => {
    const uniqueSet = new Set<string>();
    const uniqueTestNames: WeeklyTest[] = [];

    this.state.tests.forEach((test) => {
      const trimmedTestName = test.attributes.test_name.trim();
      if (
        test.attributes.test_subject === subjectName &&
        !uniqueSet.has(trimmedTestName)
      ) {
        uniqueSet.add(trimmedTestName);
        uniqueTestNames.push(test);
      }
    });

    return uniqueTestNames;
  };

  getUsageStatsWeeklyTests = async () => {
    this.setState({
      loading: true,
    });

    const getUsageStatsWeeklyTestsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getUsageStatsWeeklyTestsMessageId =
      getUsageStatsWeeklyTestsMessage.messageId;

    getUsageStatsWeeklyTestsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getUsageStatsWeeklyTestsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/weekly_usage_stats`
    );

    getUsageStatsWeeklyTestsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getUsageStatsWeeklyTestsMessage.id,
      getUsageStatsWeeklyTestsMessage
    );
  };

  getUsageStatsWeeklyTestsBySubject = async () => {
    this.setState({
      loading: true,
    });

    const getUsageStatsWeeklyTestsBySubjectMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getUsageStatsWeeklyTestsBySubjectMessageId =
      getUsageStatsWeeklyTestsBySubjectMessage.messageId;

    getUsageStatsWeeklyTestsBySubjectMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getUsageStatsWeeklyTestsBySubjectMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/weekly_usage_stats?sub_name=${this.state.subjectName}`
    );

    getUsageStatsWeeklyTestsBySubjectMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getUsageStatsWeeklyTestsBySubjectMessage.id,
      getUsageStatsWeeklyTestsBySubjectMessage
    );
  };

  getUsageStatsWeeklyTestsBySubjectAndTest = async () => {
    this.setState({
      loading: true,
    });

    const getUsageStatsWeeklyTestsBySubjectAndTestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getUsageStatsWeeklyTestsBySubjectAndTestMessageId =
      getUsageStatsWeeklyTestsBySubjectAndTestMessage.messageId;

    getUsageStatsWeeklyTestsBySubjectAndTestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getUsageStatsWeeklyTestsBySubjectAndTestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/weekly_usage_stats?test_name=${this.state.testName}&sub_name=${this.state.subjectName}`
    );

    getUsageStatsWeeklyTestsBySubjectAndTestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getUsageStatsWeeklyTestsBySubjectAndTestMessage.id,
      getUsageStatsWeeklyTestsBySubjectAndTestMessage
    );
  };

  getWeeklyTests = async () => {
    this.setState({
      loading: true,
    });

    const getWeeklyTestsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getWeeklyTestsMessageId = getWeeklyTestsMessage.messageId;

    getWeeklyTestsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getWeeklyTestsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.weeklyTestEndpoint
    );

    getWeeklyTestsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getWeeklyTestsMessage.id, getWeeklyTestsMessage);
  };
  // Customizable Area End
}
