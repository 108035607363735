import React from "react";

import {
  Box as MuiBox,
  // Customizable Area Start
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  webStyleGuestPracticeTestSubjects,
  webStylesGuestPracticeTest,
} from "./GuestPracticeTestSubjects.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import GuestGrandTestPackagesController, {
  Props,
} from "./GuestGrandTestPackagesController.web";

class GuestGrandTestPackages extends GuestGrandTestPackagesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MuiBox>
        <Loader loading={this.state.loading} />
        <MuiBox className={this.props.classes.practiceTestGuestStyle}>
          <p style={webStylesGuestPracticeTest.selectSubject}>
            Select Package to Continue
          </p>
          {this.state.packages.length > 0 && (
            <MuiBox className="test-box-container">
              {this.state.packages.map((mainPackage, index) => {
                return (
                  <MuiBox
                    key={`${mainPackage.package_name}-${index}`}
                    className="test-box"
                    onClick={() =>
                      this.navigateTo(
                        `/guest/grand-test/${mainPackage.gt_code}/list-test`
                      )
                    }
                    data-test-id="mainPackage-box"
                  >
                    <MuiBox className="icon-container">
                      <img
                        src={mainPackage.image_url}
                        className="sample-image"
                        alt={mainPackage.package_name}
                      />
                    </MuiBox>
                    <p className="link-test">{mainPackage.package_name}</p>
                  </MuiBox>
                );
              })}
            </MuiBox>
          )}
        </MuiBox>
      </MuiBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(webStyleGuestPracticeTestSubjects)(
  GuestGrandTestPackages
);
export { GuestGrandTestPackages };
// Customizable Area End
