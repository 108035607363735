import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { WithStyles } from "@material-ui/core";
import { webStyleMyPackages } from "./MyPackages.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleMyPackages> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface PackageData {
  id: string;
  type: string;
  attributes: {
    id: number;
    package_level: string;
    ayq_level: string;
    live_class_level: string;
    amount: number;
    expiry_date: string;
    status: string;
    active: boolean;
    account_data: {
      register_id: number;
      myrank_id: string;
      student_name: string;
      mobile_number: number;
      expiry_date: string;
    };
  };
}

interface S {
  // Customizable Area Start
  packageData: PackageData[];
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyPackagesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getPackageMessageID: string = "";
  setActivePackagesMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      packageData: [],
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getPackageMessageID) {
      if (response.data) {
        this.handleSuccessFetch(response.data as PackageData[]);
      }
    }

    if (apiRequestCallId === this.setActivePackagesMessageId) {
      if (response.data) {
        this.handleSuccessActiveSet(response.data as PackageData);
      }
    }
    // Customizable Area End
  }

  componentDidMount = async () => {
    this.getUserPackage();
  };

  handleSuccessFetch = (data: PackageData[]) => {
    this.setState({
      packageData: data,
      loading: false,
    });
  };

  handleToggleActive = (activeId: string, active: boolean) => {
    this.setActivePackage(activeId, active);
  };

  handleSuccessActiveSet = (data: PackageData) => {
    const newSetPackage = this.state.packageData;
    const index = newSetPackage.findIndex((packageData) => {
      return packageData.id === data.id;
    });

    newSetPackage[index] = data;

    this.setState({
      packageData: newSetPackage,
    });
  };

  handleContactUsNavigate = () => {
    this.props.navigation.history.push("/contact-us");
  };

  getUserPackage = async () => {
    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    const userPackageMesaage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPackageMessageID = userPackageMesaage.messageId;

    userPackageMesaage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myPackageEndPoint
    );
    userPackageMesaage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    userPackageMesaage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(userPackageMesaage.id, userPackageMesaage);
  };

  setActivePackage = async (packageId: string, active: boolean) => {
    const headers = {
      token: await storage.get("authToken"),
    };

    const setActivePackageMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setActivePackagesMessageId = setActivePackageMessage.messageId;

    if (active) {
      setActivePackageMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deactivePageEndPoint.replace("{id}", packageId)}`
      );
    } else {
      setActivePackageMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.activePageEndPoint.replace("{id}", packageId)}`
      );
    }
    setActivePackageMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    setActivePackageMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putmethod
    );
    runEngine.sendMessage(setActivePackageMessage.id, setActivePackageMessage);
  };
}
