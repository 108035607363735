import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Button,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Chart } from "react-google-charts";
// Customizable Area End

import UsageStatsWeeklyTestController, {
  configJSON,
  Props,
} from "./UsageStatsWeeklyTestController.web";

class UsageStatsWeeklyTest extends UsageStatsWeeklyTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderOverallChart = () => {
    if (this.state.testStats) {
      const dataOverall = [
        ["Category", "Percentage"],
        ["Correct", this.state.testStats.exam_wise_overall_correct_percentage],
        ["Wrong", this.state.testStats.exam_wise_overall_wrong_percentage],
        [
          "Unattempted",
          this.state.testStats.exam_wise_overall_unattempted_percentage,
        ],
      ];

      const dataSubjectTestOverall = [
        ["", "Correct", "Wrong", "Unattempted"],
        ...this.state.testStats.subject_wise_overall_attempts.map((subject) => {
          return [
            subject.subject_name,
            subject.subject_wise_overall_correct_percentage,
            subject.subject_wise_overall_wrong_percentage,
            subject.subject_wise_overall_unattempted_percentage,
          ];
        }),
      ];

      return (
        <Box>
          <Chart
            chartType="PieChart"
            data={dataOverall}
            width={"100%"}
            height={"500px"}
            className={this.props.classes.chart}
          />
          <Chart
            chartType="Bar"
            data={dataSubjectTestOverall}
            width={"100%"}
            height={"400px"}
            className={this.props.classes.chart}
          />
        </Box>
      );
    }

    return <></>;
  };

  RenderSubjectOverAllChart = () => {
    const subjectStat = this.state.testStatsSubject;

    if (subjectStat) {
      const dataOverall = [
        ["Category", "Percentage"],
        ["Correct Answers", subjectStat.weeklytest_overall_correct_percentage],
        ["Wrong Answers", subjectStat.weeklytest_overall_wrong_percentage],
        [
          "Unattempted Questions",
          subjectStat.weeklytest_overall_unattempted_percentage,
        ],
      ];

      return (
        <Box>
          <Chart
            chartType="PieChart"
            data={dataOverall}
            width={"100%"}
            height={"700px"}
            className={this.props.classes.chart}
          />
        </Box>
      );
    }

    return <></>;
  };

  RenderSubjectTestOverAllChart = () => {
    const subjectAllTestStat = this.state.testStatsSubjectAndTest;

    if (subjectAllTestStat) {
      const dataOverall = [
        ["Category", "Percentage"],
        [
          "Correct Answers",
          subjectAllTestStat.weeklytest_overall_correct_percentage,
        ],
        [
          "Wrong Answers",
          subjectAllTestStat.weeklytest_overall_wrong_percentage,
        ],
        [
          "Unattempted Questions",
          subjectAllTestStat.weeklytest_overall_unattempted_percentage,
        ],
      ];
      return (
        <Box>
          <Chart
            chartType="PieChart"
            data={dataOverall}
            width={"100%"}
            height={"700px"}
            className={this.props.classes.chart}
          />
        </Box>
      );
    }

    return <></>;
  };

  RenderBasedOnUserInput = () => {
    const subjectInput = this.state.subjectName;
    const testInput = this.state.testName;

    if (!subjectInput && !testInput) {
      return this.RenderOverallChart();
    }

    if (subjectInput && !testInput) {
      return this.RenderSubjectOverAllChart();
    }

    if (subjectInput && testInput) {
      return this.RenderSubjectTestOverAllChart();
    }
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box marginTop="2em" padding="2em">
          <Box>
            <Box position={"relative"}>
              <select
                onChange={(event) =>
                  this.onChangeSelectSubject(event.target.value)
                }
                value={this.state.subjectName}
                data-test-id="select-btn"
                className={this.props.classes.selectStyle}
              >
                <option value="" disabled hidden>
                  {configJSON.subjectNotSelected}
                </option>
                <option value=""> </option>
                {this.state.testSubjects.map((test) => {
                  return (
                    <option
                      value={test.attributes.test_subject}
                      key={`subject-${test.attributes.test_subject}`}
                    >
                      {test.attributes.test_subject}
                    </option>
                  );
                })}
              </select>
              <ExpandMoreIcon className={this.props.classes.expandMoreIcon} />
            </Box>
            <Box position={"relative"}>
              <select
                onChange={(event) =>
                  this.onChangeSelectTest(event.target.value)
                }
                value={this.state.testName}
                data-test-id="select-btn"
                className={this.props.classes.selectStyle}
              >
                <option value="" disabled hidden>
                  TEST NAME : NOT SELECTED
                </option>
                <option value=""> </option>
                {this.filteredTestsBySubject(this.state.subjectName).map(
                  (test) => {
                    return (
                      <option
                        value={test.attributes.test_name}
                        key={`test-${test.attributes.test_name}`}
                      >
                        {test.attributes.test_name}
                      </option>
                    );
                  }
                )}
              </select>
              <ExpandMoreIcon className={this.props.classes.expandMoreIcon} />
            </Box>
          </Box>
          <Box margin="4em 0">
            <Box textAlign={"center"}>
              <Typography className={this.props.classes.typeOverall}>
                Exams overall in Weekly Test -{" "}
                {this.state.testStats?.exam_wise_overall_attempts}
              </Typography>
              {this.RenderBasedOnUserInput()}
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleUsageStatsWeeklyTest = () =>
  createStyles({
    selectStyle: {
      width: "100%",
      fontSize: "1.4em",
      outline: 0,
      fontWeight: 500,
      paddingLeft: "1.5em",
      WebkitAppearance: "none",
      background: "rgb(250,231,231)",
      height: "70px",
      color: "#5C0F89",
      marginTop: "1.3em",
      border: "none",
    },
    expandMoreIcon: {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      color: "white",
      borderRadius: "50%",
      top: "47px",
      position: "absolute",
      right: "30px",
      cursor: "pointer",
    },
    chart: {
      "& rect": {
        fill: "#FAFAFA!important",
      },
    },
    typeOverall: {
      fontWeight: 500,
      fontSize: "1.2rem",
    },
  });

export default withStyles(webStyleUsageStatsWeeklyTest)(UsageStatsWeeklyTest);
export { UsageStatsWeeklyTest };
// Customizable Area End
