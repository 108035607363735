import React from "react";

import {
  Box,
  // Customizable Area Start
  Button,
  Typography,
  Modal,
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Stepper from "../../../components/src/Stepper.web";
import TimerIcon from "@material-ui/icons/Timer";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ReviewTestComponent from "../../../components/src/ReviewTestComponent.web";
import { styles } from "./styles";
import { saveImg, Bookmark } from "./assets";
import Loader from "../../../components/src/Loader.web";
import ProgressContainer from "../../../components/src/ProgressContainer.web";
// Customizable Area End

import ReviewTestWeeklyController, {
  Props,
} from "./ReviewTestWeeklyController.web";

class ReviewTestWeekly extends ReviewTestWeeklyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    let totalTime: string = "00:00";
    if (this.state.metaDataWeekly) {
      totalTime = this.state.metaDataWeekly.time_taken;
    }
    const { correctPercentage, unattendedPercentage, incorrectPercentage } =
      this.state;

    return (
      // Customizable Area Start
      <div>
        <Loader loading={this.state.loading} />
        <div style={webStyle.bannerStyle}>
          <h2>Weekly Test - Review</h2>
        </div>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <ProgressContainer
            correctPercentage={correctPercentage as number}
            incorrectPercentage={incorrectPercentage as number}
            unattendedPercentage={unattendedPercentage as number}
          />
        </Box>
        <div style={{ display: "flex", gap: "1em", padding: "1em 2em 0 2em" }}>
          <Stepper
            steps={this.state.reviewDataWeekly.length}
            activeStep={this.state.currentReviewDataWeekly + 1}
          />
          <p>
            {this.state.currentReviewDataWeekly + 1}/
            {this.state.reviewDataWeekly.length}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            padding: "1em 2em",
            alignItems: "center",
          }}
        >
          <p className={this.props.classes.practiceTestTimer}>
            <span>
              <TimerIcon />
            </span>
            {totalTime}
          </p>
        </div>
        <div className={this.props.classes.questionOverview}>
          This question carries +
          {
            this.state.reviewDataWeekly[this.state.currentReviewDataWeekly]
              ?.attributes.p_mark
          }{" "}
          for Correct Answer and -
          {Number(
            this.state.reviewDataWeekly[this.state.currentReviewDataWeekly]
              ?.attributes.n_mark
          )}{" "}
          for wrong Answer
          <Box>
            <p className={this.props.classes.savedNotesIcon}>
              <img
                src={saveImg}
                width={"25px"}
                onClick={()=>this.handleNotesModalOpen()}
                data-test-id="notes-modal"
              />
            </p>
            <p className={this.props.classes.bookmarkIcon}>
              <img
                src={Bookmark}
                width={"25px"}
                onClick={this.handleBookmarkModalOpen}
                data-test-id="bookmark-modal"
              />
            </p>
          </Box>
        </div>
        <div className={this.props.classes.mainContainerPracticeTest}>
          {this.state.reviewDataWeekly.length > 0 && (
            <ReviewTestComponent
              index={this.state.currentReviewDataWeekly}
              question={
                this.state.reviewDataWeekly[this.state.currentReviewDataWeekly]
              }
            />
          )}
          <div className="overview-practice-test">
            <div className="overview-board">
              <h3>Overview</h3>
              <div className="overview-btns">
                {this.state.reviewDataWeekly.map((question, index) => {
                  const className = this.getBtnClassName(index);
                  return (
                    <button
                      data-test-id={`specific-btn-test-id`}
                      key={`button-${index}`}
                      className={className}
                      onClick={() => this.handleSpecificQuestion(index)}
                    >
                      {question.attributes.question_no}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={this.props.classes.btnsPracticeTest}>
          <div className="submit-mark-practice-btns">
            <Link
              to={`/dashboard/weekly-test/tests/take-test/${this.props.navigation.getParam(
                "testId"
              )}`}
            >
              <button data-test-id={`submitTest`} className="submit-test-btn">
                Retake Test
              </button>
            </Link>
          </div>
          <div className="navigation-practice-btns">
            <div>
              <button
                data-test-id={`prevbtn`}
                onClick={this.handlePreviousQuestion}
              >
                <ChevronLeftIcon />
              </button>
              <button data-test-id={`nxtbtn`} onClick={this.handleNextQuestion}>
                <ChevronRightIcon />
              </button>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.openSaveNotesModalWeekly}
          onClose={this.handleNotesModalClose}
        >
          <Box sx={webStyle.modalStyle}>
            <Typography variant="h6">Choose What You Need</Typography>
            <Box display={"flex"} flexDirection={"column"} gridGap={"1em"}>
              <Link
                to={`${this.props.navigation.history.location.pathname}/saved-notes`}
              >
                <Button className={this.props.classes.buttonStyleModals}>
                  Go to saved notes
                </Button>
              </Link>
              <Link
                to={`${
                  this.props.navigation.history.location.pathname
                }/saved-notes/notes?noteType=New&reviewId=${
                  this.reviewId
                }&qno=${
                  this.state.reviewDataWeekly[
                    this.state.currentReviewDataWeekly
                  ]?.attributes.question_no
                }&qid=${
                  this.state.reviewDataWeekly[
                    this.state.currentReviewDataWeekly
                  ]?.attributes.id
                }`}
              >
                <Button className={this.props.classes.buttonStyleModals}>
                  Create New Note
                </Button>
              </Link>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.openBookmarkSuccessModalWeekly}
          onClose={this.handleBookmarkSuccessModalClose}
        >
          <Box sx={webStyle.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>
              You have Bookmarked Question no -{" "}
              {
                this.state.reviewDataWeekly[this.state.currentReviewDataWeekly]
                  ?.attributes.question_no
              }{" "}
              successfully!
            </h3>
            <div>
              <Link
                to={`${this.props.navigation.history.location.pathname}/bookmarks?reviewId=${this.reviewId}`}
              >
                <Button className={this.props.classes.buttonStyleModals}>
                  Go to Saved bookmarks
                </Button>
              </Link>
            </div>
          </Box>
        </Modal>
        <Modal
          onClose={this.handleBookmarkModalClose}
          open={this.state.openBookmarkModalWeekly}
        >
          <Box sx={webStyle.modalStyle}>
            <Typography variant="h6">Choose What You Need</Typography>
            <Box display={"flex"} flexDirection={"column"} gridGap={"1em"}>
              <Link
                to={`${this.props.navigation.history.location.pathname}/bookmarks?reviewId=${this.reviewId}`}
              >
                <Button
                  className={this.props.classes.buttonStyleModals}
                  data-test-id="gotobookmarks-modal"
                >
                  Go to Saved Bookmark
                </Button>
              </Link>
              <Button
                className={this.props.classes.buttonStyleModals}
                data-test-id="save-bookmarks-modal"
                onClick={this.bookmarkSave}
              >
                Create New Bookmark
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "300px",
    width: "35%",
    bgcolor: "background.paper",
    border: "2px solid #FFD0D8",
    borderRadius: "1.5em",
    padding: "2em",
    gap: "1.5em",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  bannerStyle: {
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    color: "rgb(82, 0, 130)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "relative" as const,
  },
};

export default withStyles(styles)(ReviewTestWeekly);
export { ReviewTestWeekly };
// Customizable Area End
