import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface IMegaMenuContent {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    content: string;
    icon: string;
    created_at: string;
    updated_at: string;
  };
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  content: string;
  menuId: string;
  examId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MegamenuCommonController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMegaMenuContentMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      content: "",
      examId: "",
      menuId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getMegaMenuContentMessageId && response) {
      if (response?.data) {
        this.successfulMegaMenuContentFetch(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const search = this.props.navigation.history.location.search;
    const params = new URLSearchParams(search);
    const menuId = params.get("menuId");
    const examId = params.get("examId");

    if (!menuId || !examId) {
      return this.props.navigation.history.goBack();
    }

    this.getMegaMenuContent(menuId, examId);
  };

  componentDidUpdate = async (_props: Props, _state: S) => {
    const search = this.props.navigation.history.location.search;
    const params = new URLSearchParams(search);
    const menuIdParams = params.get("menuId");
    const examId = params.get("examId");

    if (!menuIdParams || !examId) {
      return this.props.navigation.history.goBack();
    }

    if (this.state.menuId !== menuIdParams || this.state.examId !== examId) {
      this.getMegaMenuContent(menuIdParams, examId);
    }
  };

  // Customizable Area Start
  getMegaMenuContent = async (menuId: string, examId: string) => {
    this.setState({
      examId,
      menuId,
    });

    const getMegaMenuContentMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMegaMenuContentMessageId = getMegaMenuContentMessage.messageId;

    getMegaMenuContentMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/megamenus/${menuId}/show_exam?exam_id=${examId}`
    );

    getMegaMenuContentMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      getMegaMenuContentMessage.id,
      getMegaMenuContentMessage
    );
  };
  successfulMegaMenuContentFetch = (data: IMegaMenuContent) => {
    this.setState({
      content: data.attributes.content,
    });
  };
  // Customizable Area End
}
