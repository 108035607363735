import React from "react";

import {
  Box as MUIBox,
  // Customizable Area Start
  Typography,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import BannerWeb from "../../../components/src/Banner.web";
import Loader from "../../../components/src/Loader.web";
import StarIcon from "@material-ui/icons/Star";
import Pagination from "@material-ui/lab/Pagination";
import PageDefaultExport from "../../../components/src/PageDefaultExport.web";
// Customizable Area End

import ApplicationSavedController, {
  ISavedLater,
  Props,
} from "./ApplicationSavedController.web";
import { bookmarkImg } from "./assets";

class ApplicationSaved extends ApplicationSavedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ApplicationRender = (savedApplication: ISavedLater) => {
    return (
      <MUIBox
        flexDirection="column"
        display="flex"
        width="45%"
        className={this.props.classes.applicationMUIBoxStyle}
        minHeight="230px"
        height="max-content"
        gridGap={"0.5em"}
        key={savedApplication.id}
        padding="1.5em"
      >
        <Typography variant="h5">
          {savedApplication.attributes.cdf_univ.univ_name}
        </Typography>
        <MUIBox alignItems={"center"} display={"flex"}>
          <LocationOnOutlinedIcon />
          <Typography variant="body1">
            {savedApplication.attributes.cdf_univ.address}
          </Typography>
        </MUIBox>
        <MUIBox alignItems={"center"} display={"flex"}>
          <StarIcon htmlColor={"#FAC530"} fontSize="small" />
          <Typography variant="body1">
            {savedApplication.attributes.cdf_univ.rating
              ? savedApplication.attributes.cdf_univ.rating
              : 0}{" "}
            / 5
          </Typography>
        </MUIBox>
        <Typography variant="body1">
          {savedApplication.attributes.cdf_univ.univ_type} Estd.{" "}
          {savedApplication.attributes.cdf_univ.established}
        </Typography>
        <Button className={this.props.classes.applyNowBtn}>Apply Now</Button>
        <MUIBox className={this.props.classes.savedIcon}>
          <img
            className={this.props.classes.savedImgIcon}
            src={bookmarkImg}
            alt="bkmrk-img"
          />
          <Typography>Saved</Typography>
        </MUIBox>
      </MUIBox>
    );
  };

  SavedApplicationPage = (application: ISavedLater[]) => {
    return (
      <MUIBox
        flexWrap={"wrap"}
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        marginBottom={"5em"}
        gridGap={"2.5em"}
      >
        {application.map((application) => {
          return this.ApplicationRender(application);
        })}
      </MUIBox>
    );
  };
  // Customizable Area End

  render() {
    const { savedApplications, currentPageApplication } = this.state;

    const itemsPerPage = 10;
    const indexOfLastItem = currentPageApplication * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = savedApplications.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    const totalPages = Math.ceil(savedApplications.length / itemsPerPage);

    return (
      // Customizable Area Start
      <MUIBox>
        <Loader loading={this.state.loading} />
        <BannerWeb title="Saved Applications" />
        <MUIBox padding={"2em"}>
          <Typography variant="h5" className={this.props.classes.savedHeader}>
            You have {this.state.savedApplications.length} Saved Applications
          </Typography>
          {this.SavedApplicationPage(currentItems)}
        </MUIBox>
        <MUIBox
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          marginBottom={"5em"}
          flexDirection={"row"}
        >
          <Pagination
            count={totalPages}
            data-test-id="pagination"
            page={this.state.currentPageApplication}
            className={this.props.classes.muiPaginationStyle}
            onChange={(_: unknown, value: number) =>
              this.handlePageChangeApplication(value)
            }
          />
        </MUIBox>
      </MUIBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  buttons: {
    border: "1px solid #FF5C5C",
    color: "#FF5C5C",
    padding: "0.7em 2em",
  },
  active: {
    color: "white",
    background: "#FF5C5C",
    "&:hover": {
      background: "#FF5C5C",
    },
  },
  applyNowBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0.5em",
    color: "white",
    background: "#2271B1",
    width: "120px",
    height: "50px",
    "&:hover": {
      background: "#2271B1",
    },
  },
  applicationMUIBoxStyle: {
    border: "1px solid gray",
    position: "relative",
  },
  savedIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "15px",
    right: "15px",
    cursor: "pointer",
    "& p": {
      fontWeight: 500,
      color: "#5793C3",
    },
  },
  muiPaginationStyle: {
    width: "max-content",
    borderRadius: "4px",
    position: "relative",
    border: "1px solid rgba(255, 58, 87, 0.7)",
    "& li": {
      width: "35px",
      color: "white",
      borderRadius: "2px",
      background: "white",
      height: "41px",
    },
    "& li div": {
      width: "100%",
      height: "100%",
      background: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    "& li:nth-last-child(1)": {
      background: "transparent",
      position: "absolute",
      right: -20,
      top: 10,
      zIndex: 999,
      "& button": {
        height: "max-content",
        width: "max-content",
      },
      "& button svg": {
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        borderRadius: "50%",
        "& path": {
          fill: "white",
        },
      },
      "& button.Mui-disabled": {
        display: "none",
      },
    },
    "& li button:hover": {
      background: "none",
    },
    "& li button": {
      height: "100%",
      margin: 0,
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      display: "flex",
      padding: 0,
      background: "none",
    },
    "& li:nth-child(1)": {
      zIndex: 999,
      left: -20,
      position: "absolute",
      background: "transparent",
      top: 10,
      "& button": {
        height: "max-content",
        width: "max-content",
      },
      "& button.Mui-disabled": {
        display: "none",
      },
      "& button svg": {
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        "& path": {
          fill: "white",
        },
        borderRadius: "50%",
      },
    },

    "& li button.Mui-selected": {
      color: "white",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      borderRadius: "2px",
      fontWeight: 500,
    },
  },
  savedImgIcon: {
    width: "24px",
    height: "24px",
  },
  savedHeader: {
    textAlign: "center",
    margin: "2em",
  },
};

export const ApplicationSavedPage = PageDefaultExport({
  pageStyles: webStyles,
  component: ApplicationSaved,
});

export default ApplicationSaved;
// Customizable Area End
