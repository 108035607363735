import React from "react";
import { Box, Typography } from "@material-ui/core";
import { myranklogo } from "./assets";
import Button from "./Button.web";
import PersonIcon from "@material-ui/icons/Person";

const GuestHeader = (navigation: {
  navigate?: (navigateTo: string) => void;
  history?: {
    push: (navigateTo: string) => void;
  };
  title?: string;
}) => {
  return (
    <Box
      className="dashboard-header"
      component={"header"}
      display="flex"
      justifyContent={"space-between"}
      alignItems={"center"}
      boxShadow={"1px 1px 0.5px gray"}
      padding="1em 3em"
      position={"relative"}
    >
      <Box className="header-nav">
        <img
          src={myranklogo}
          alt="logomyrank"
          height={"39px"}
          style={{ cursor: "pointer" }}
          onClick={() => navigation.history?.push("/")}
        />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gridGap="0.5em"
      >
        <PersonIcon style={{ color: "#520082", fontSize: "2.5em" }} />
        <Typography style={{ fontWeight: 500 }}>Guest</Typography>
      </Box>
      <Box>
        <Button
          color="white"
          title="Register"
          height="50px"
          width="130px"
          onClick={() => navigation.history?.push("/register")}
        />
      </Box>
      <Box
        position={"absolute"}
        style={{ transform: "translate(-50%,-50%)" }}
        left={"50%"}
        top={"50%"}
      >
        <Typography variant="h1" style={{ fontSize: "2em" }}>
          {navigation.title}
        </Typography>
      </Box>
    </Box>
  );
};

export default GuestHeader;
