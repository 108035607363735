import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { webStyle } from "./ReviewTestWeeklyMain.web";
import storage from "../../../framework/src/StorageProvider.web";
export interface Root {
  review_histories: ReviewHistory[];
  number_of_attempts: number;
  weekly_test: WeeklyTest;
}

interface Details {
  id: number;
  test_name: string;
  test_subject: string;
  test_category: string;
}

export interface ReviewHistory {
  id: number;
  account_id: number;
  weekly_test_id: number;
  time_taken: string;
  correct_answer: number;
  unattended: number;
  incorrect_answer: number;
  marks: number;
  total_questions: number;
  score_db: string;
  question_ids: string[];
  score_id: number;
  created_at: string;
  updated_at: string;
}

export interface WeeklyTest {
  id: number;
  test_name: string;
  test_subject: string;
  test_category: string;
  no_of_questions: number;
  duration: number;
  start_time: string;
  end_time: string;
  status: string;
  remarks: string;
  created_by: string;
  created_at: string;
  updated_at: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyle> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  review_histories_weekly: ReviewHistory[];
  chapterData: WeeklyTest | null;
  testId: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReviewTestMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getReviewHistoriesWeeklyMessageId = "";
  getTestDetailsMessageId = "";
  breadcrumb = [
    {
      link: "/dashboard/weekly-test/instructions",
      label: "Weekly Test",
    },
    {
      label: "Instructions",
      link: "/dashboard/weekly-test/instructions",
    },
    {
      link: "/dashboard/weekly-test/tests",
      label: "Tests",
    },
    {
      link: "/dashboard/weekly-test/tests",
      label: "Subject",
    },
    {
      label: "Reviews",
      link: this.props.navigation.history.location.pathname,
    },
    {
      label: "Reviews",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      review_histories_weekly: [],
      chapterData: null,
      testId: "",
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getReviewHistoriesWeeklyMessageId) {
      if (response?.review_histories && response?.weekly_test) {
        this.handleSuccessFetchHistories(response.review_histories);
        this.handleSuccessFetchResponse(response.weekly_test);
      }

      this.setState({
        loading: false,
      });
    }

    if (apiRequestCallId === this.getTestDetailsMessageId) {
      if (response?.data as Array<{ id: string; attributes: Details }>) {
        for (let data of response.data) {
          if (data.id === this.props.navigation.getParam("testId")) {
            this.handleTestDetails(data.attributes);
            break;
          }
        }
      }
    }
    // Customizable Area End
  }

  // web events
  handleSuccessFetchHistories = (review_histories_weekly: ReviewHistory[]) => {
    this.setState({
      review_histories_weekly,
    });
  };

  handleSuccessFetchResponse = (response: WeeklyTest) => {
    this.setState({
      chapterData: response,
    });
  };

  handleTestDetails = (response: Details) => {
    const breadcrumb = this.breadcrumb;
    breadcrumb[3].label = response.test_subject;
    breadcrumb[4].label = response.test_name;

    const propPassingRTWMC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingRTWMC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingRTWMC);

    this.setState({
      loading: false,
    });
  };

  componentDidMount = async () => {
    const propPassingRTWMC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingRTWMC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingRTWMC);

    const testId = this.props.navigation.getParam("testId");

    this.setState(
      {
        testId: testId as string,
      },
      () => {
        this.getReviewHistoriesWeekly(this.state.testId);
        this.getTestDetails();
      }
    );
  };

  getTestDetails = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getTestDetailsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTestDetailsMessageId = getTestDetailsMessage.messageId;

    getTestDetailsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getTestDetailsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/get_tests`
    );

    getTestDetailsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getTestDetailsMessage.id, getTestDetailsMessage);
  };

  goToSavedNotes = (reviewId: string) => {
    this.props.navigation.history.push(
      `${this.props.navigation.history.location.pathname}/review/${reviewId}/saved-notes`
    );
  };

  goToBookmarks = (reviewId: string) => {
    this.props.navigation.history.push(
      `${this.props.navigation.history.location.pathname}/review/${reviewId}/bookmarks`
    );
  };

  convertToDate = (timestamp: string) => {
    const date = new Date(timestamp);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  // Customizable Area Start
  getReviewHistoriesWeekly = async (testId: string) => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getHistoryMessageRTMC = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReviewHistoriesWeeklyMessageId = getHistoryMessageRTMC.messageId;

    getHistoryMessageRTMC.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getHistoryMessageRTMC.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/${testId}/review_histories`
    );

    getHistoryMessageRTMC.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getHistoryMessageRTMC.id, getHistoryMessageRTMC);
  };
  // Customizable Area End
}
