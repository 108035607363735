import React from "react";

import {
  Box,
  Button,
  // Customizable Area Start
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./styles/newpassword.scss";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// Customizable Area End

import NewPasswordController, {
  Props,
  configJSON,
} from "./NewPasswordController.web";

export default class NewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className="new-password-wrapper">
        <Modal
          open={this.state.isModalOpenWrongPassword}
          onClose={this.handleCloseModalPasswordWrong}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box sx={webStyle.modalStyle}>
            <HighlightOffIcon
              data-test-id="close-modal2"
              onClick={this.handleCloseModalPasswordWrong}
              style={webStyle.highlightIcon}
            />
            <CancelIcon style={webStyle.cancelIcon} />
            <p style={webStyle.widthStyle}>{configJSON.passwordShouldHave}</p>
          </Box>
        </Modal>
        <Modal
          open={this.state.isModalOpen}
          onClose={this.handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box sx={webStyle.modalStyle}>
            <CheckCircleIcon
              style={webStyle.checkCircleStyle}
              data-test-id="close-modal"
              onClick={this.handleCloseModal}
            />
            <Box sx={webStyle.centerElements} className="modal-gotologin">
              <p>{configJSON.passwordSuccess}</p>
              <Button
                style={webStyle.loginButtonStyle}
                onClick={this.handleGoToLogin}
                data-test-id="go-login"
              >
                {configJSON.gotoLogin}
              </Button>
            </Box>
          </Box>
        </Modal>
        <Box className="new-password-box">
          <Box className="new-password-heading">
            <h3>{configJSON.createPassword}</h3>
            <p>{configJSON.passwordDescription}</p>
          </Box>
          <Box>
            <form
              data-test-id="password-submit"
              onSubmit={(event) => this.submitConfirmationPassword(event)}
            >
              <fieldset style={webStyle.fieldsetStyle}>
                <label htmlFor="newPassword">{configJSON.newPassword}</label>
                <input
                  name="newPassword"
                  type={this.state.visibilityPassword1 ? "text" : "password"}
                  value={this.state.userInput.newPassword}
                  placeholder="Enter new password"
                  onChange={(event) => this.handleInputChange(event)}
                  data-test-id="new-password-input"
                />
                <div
                  data-test-id="show-password"
                  style={webStyle.visibilityStyle}
                  onClick={this.handlePasswordVisibility1}
                >
                  {this.state.visibilityPassword1 ? (
                    <VisibilityIcon style={webStyle.visibilityStyle2} />
                  ) : (
                    <VisibilityOffIcon style={webStyle.visibilityStyle2} />
                  )}
                </div>
              </fieldset>
              <fieldset style={webStyle.fieldsetStyle}>
                <label htmlFor="newPasswordConfirmation">
                  {configJSON.confirmPassword}
                </label>
                <input
                  value={this.state.userInput.newPasswordConfirmation}
                  type={this.state.visibilityPassword2 ? "text" : "password"}
                  name="newPasswordConfirmation"
                  placeholder="Enter password confirmation"
                  onChange={(event) => this.handleInputChange(event)}
                  data-test-id="new-password-input-confirmation"
                />
                <div
                  data-test-id="show-password"
                  style={webStyle.visibilityStyle}
                  onClick={this.handlePasswordVisibility2}
                >
                  {this.state.visibilityPassword2 ? (
                    <VisibilityIcon style={webStyle.visibilityStyle2} />
                  ) : (
                    <VisibilityOffIcon style={webStyle.visibilityStyle2} />
                  )}
                </div>
              </fieldset>
              <Button
                data-test-id="reset-button"
                type="submit"
                disabled={
                  this.state.userInput.newPassword !==
                    this.state.userInput.newPasswordConfirmation ||
                  this.state.userInput.newPassword.length === 0 ||
                  this.state.userInput.newPasswordConfirmation.length === 0
                }
              >
                {configJSON.resetPassword}
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  visibilityStyle2: { color: "#4D0082" },
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "484px",
    height: "338px",
    backgroundColor: "white",
    borderRadius: "0.5em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
    border: "none",
  },
  fieldsetStyle: {
    position: "relative" as const,
    height: "100%",
  },
  visibilityStyle: {
    position: "absolute" as const,
    right: "10px",
    top: "42px",
    cursor: "pointer",
  },
  centerElements: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loginButtonStyle: {
    width: "306px",
    height: "65px",
    fontWeight: "bold" as const,
    color: "white" as const,
    background: "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)" as const,
    borderRadius: "8px" as const,
  },
  highlightIcon: {
    position: "absolute" as const,
    top: "10px",
    right: "10px",
    fontSize: "2rem",
    cursor: "pointer",
    color: "gray",
  },
  cancelIcon: { color: "#FE6056", fontSize: "4rem" },
  checkCircleStyle: { color: "#029344", fontSize: "4rem" },
  widthStyle: { width: "200px" },
};
// Customizable Area End
