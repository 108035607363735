import React from "react";

import {
  Box as MUIBox,
  // Customizable Area Start
  withStyles,
  Button as MUIButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import GrandTestTakingReviewController from "./GrandTestTakingReviewController.web";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Button from "../../../components/src/Button.web";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import TimerIcon from "@material-ui/icons/Timer";
import { Link } from "react-router-dom";
import { styles } from "./styles";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

class GrandTestTakingReview extends GrandTestTakingReviewController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        <MUIBox style={webStyle.bannerStyle}>
          <h2>Grand Test ( Performance Analysis )</h2>
        </MUIBox>
        <MUIBox className={this.props.classes.practiceTestReview}>
          <p className={this.props.classes.practiceTestTimer}>
            <span>
              <TimerIcon />
            </span>{" "}
            {this.state.timeTaken}
          </p>
          <MUIBox style={webStyle.containerMainStyle}>
            <MUIButton
              data-test-id="graphical-btn"
              onClick={this.setGraphical}
              style={
                !this.state.isTabular
                  ? webStyle.buttonStyleYes
                  : webStyle.buttonStyleNo
              }
            >
              Graphical
            </MUIButton>
            <MUIButton
              data-test-id="tabular-btn"
              onClick={() => this.setTabular()}
              style={
                this.state.isTabular
                  ? webStyle.buttonStyleYes
                  : webStyle.buttonStyleNo
              }
            >
              Tabular
            </MUIButton>
          </MUIBox>
          {this.state.isTabular ? (
            <MUIBox style={webStyle.container1Style}>
              <table style={webStyle.table}>
                <thead>
                  <tr>
                    <th style={webStyle.tableHeaderTh}>Category</th>
                    <th style={webStyle.tableHeaderTh}>Correct</th>
                    <th style={webStyle.tableHeaderTh}>Wrong</th>
                    <th style={webStyle.tableHeaderTh}>Unattempted</th>
                    <th style={webStyle.tableHeaderTh}>Marks</th>
                  </tr>
                </thead>
                <tfoot style={webStyle.tableFooter}>
                  <tr>
                    <td style={webStyle.tableFooterTd}>
                      <p>Total</p>
                    </td>
                    <td style={webStyle.tableFooterTd} />
                    <td style={webStyle.tableFooterTd} />
                    <td style={webStyle.tableFooterTd} />
                    <td style={webStyle.tableFooterTd}>
                      <p>{this.state.totalMarks}</p>
                    </td>
                  </tr>
                </tfoot>
                <tbody>
                  {this.state.reviewData.map((reviewData, index) => (
                    <tr key={`${index}-reviewData`}>
                      <td style={webStyle.tableCell}>
                        {reviewData.subject_name}
                      </td>
                      <td style={webStyle.tableCell}>{reviewData.correct}</td>
                      <td style={webStyle.tableCell}>{reviewData.incorrect}</td>
                      <td style={webStyle.tableCell}>
                        {reviewData.unattempted}
                      </td>
                      <td style={webStyle.tableCell}>
                        {reviewData.totalMarks}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p style={webStyle.pStyle}>
                Click on review for detailed solutions and analysis of the test
                taken
              </p>
            </MUIBox>
          ) : (
            <MUIBox sx={webStyle.scoreContainer}>
              <MUIBox sx={webStyle.progressStyles}>
                <MUIBox sx={{ position: "absolute", top: 78, width: "140px" }}>
                  <CircularProgressbar
                    value={this.state.correctPercentage as number}
                    strokeWidth={14}
                    styles={buildStyles({
                      pathColor: `#9BBB56`,
                    })}
                  />
                </MUIBox>
                <MUIBox sx={{ position: "absolute", top: 40, width: "220px" }}>
                  <CircularProgressbar
                    value={this.state.incorrectPercentage as number}
                    strokeWidth={9}
                    styles={buildStyles({
                      pathColor: `#D84553`,
                    })}
                  />
                </MUIBox>
                <MUIBox sx={{ position: "absolute", top: 0 }}>
                  <CircularProgressbar
                    value={this.state.unattendedPercentage as number}
                    strokeWidth={7}
                    styles={buildStyles({
                      pathColor: `#FF9D54`,
                    })}
                  />
                </MUIBox>
              </MUIBox>
              <MUIBox sx={webStyle.legendStyles}>
                <p style={webStyle.centerStyle}>
                  <span>
                    <FiberManualRecordIcon style={{ color: "#9BBB56" }} />
                  </span>
                  Correct ({this.state.correctPercentage}%)
                </p>
                <p style={webStyle.centerStyle}>
                  <span>
                    <FiberManualRecordIcon style={{ color: "#D84553" }} />
                  </span>{" "}
                  Incorrect ({this.state.incorrectPercentage}%)
                </p>
                <p style={webStyle.centerStyle}>
                  <span>
                    <FiberManualRecordIcon style={{ color: "#FF9D54" }} />
                  </span>{" "}
                  Unattempted ({this.state.unattendedPercentage}%)
                </p>
              </MUIBox>
            </MUIBox>
          )}
          <Link
            to={`/dashboard/grand-test/subjects/${this.props.navigation.getParam(
              "packageId"
            )}/review-test/${this.state.testId}/review/${this.state.reviewId}`}
          >
            <MUIBox sx={{ marginTop: "3em" }}>
              <Button
                title="Review"
                width={"156"}
                height={"60"}
                color={"white"}
              />
            </MUIBox>
          </Link>
        </MUIBox>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  progressStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "400px",
    position: "relative",
  },
  scoreContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "space-around",
    width: "580px",
    height: "550px",
    background: "#FAF8FD",
    padding: "0em 2em",
  },
  bannerStyle: {
    display: "flex",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    color: "rgb(82, 0, 130)",
  },
  legendStyles: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    gap: "3em",
  },
  centerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    width: "900px",
    borderCollapse: "collapse" as const,
    border: "2px solid #510081",
    background: "#FAF8FB",
    height: "480px",
  },
  tableHeaderTh: {
    padding: "40px",
    border: "2px solid #510081",
    color: "#510081",
  },
  tableFooter: {
    backgroundColor: "#510081",
    color: "white",
    textAlign: "center" as const,
  },
  tableFooterTd: {
    padding: "30px",
  },
  tableFooterP: {
    padding: "10px",
  },
  tableCell: {
    padding: "10px",
    textAlign: "center" as const,
    border: "2px solid #510081",
  },
  buttonStyleYes: {
    width: "130px",
    height: "50px",
    color: "white",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    textTransform: "none" as const,
    cursor: "pointer",
  },
  buttonStyleNo: {
    width: "130px",
    height: "50px",
    color: "#FE6156",
    background: "white",
    border: "1px solid #FE6156",
    borderRadius: "0.6em",
    textTransform: "none" as const,
    cursor: "pointer",
  },
  pStyle: {
    fontWeight: 500,
    fontSize: "17px",
    marginTop: "2.5em",
  },
  container1Style: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
  },
  containerMainStyle: {
    display: "flex",
    gap: "1em",
    marginBottom: "2em",
  },
};

export default withStyles(styles)(GrandTestTakingReview);
export { GrandTestTakingReview };
// Customizable Area End
