import React from "react";

import {
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import GuestHeader from "../../../components/src/GuestHeader.web";
import Footer from "../../../components/src/Footer.web";
import WebRoutesGenerator from "../../../components/src/NativeWebRouteWrapper";
import { routeMapGuest } from "../../../web/src/App";
// Customizable Area End

import DashboardGuestController, {
  Props,
} from "./DashboardGuestController.web";

export default class DashboardGuest extends DashboardGuestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box data-test-id="home-screen">
        <GuestHeader history={this.props.navigation.history} />
        <Box minHeight={"90vh"}>
          {WebRoutesGenerator({ routeMap: routeMapGuest })}
        </Box>
        {!this.props.navigation.history.location.pathname.includes(
          "live-classes"
        ) && <Footer />}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
