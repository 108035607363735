import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Box,
  Modal,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { makeStyles } from "@material-ui/core/styles";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import EventNoteOutlinedIcon from "@material-ui/icons/EventNoteOutlined";
import { Link } from "react-router-dom";

export interface Root {
  id: string;
  type: string;
  attributes: Attributes;
  createBookmark?: (bookmarkId: string, questionNumber: string) => void;
  chapterName?: string;
  chapterId?: string;
  subjectId?: string;
  withNotesBookmark?: boolean;
  disableClick?: boolean;
}

export interface Attributes {
  id: number;
  subject_id?: number;
  chapter_id?: number;
  question_type?: string;
  question_num?: number;
  question: string;
  solution: string;
  db_type?: string;
  qb_type?: string;
}

const useStyles = makeStyles(() => ({
  accordionMain: {
    boxShadow: "none",
    border: "2px solid #9E85AA",
    borderRadius: "1em !important",
    width: "100%",
    maxWidth: "80vw",
    "& img": {
      maxWidth: "70vw",
    },
  },
  accordionSummary: {
    minHeight: "80px",
    height: "max-content",
    display: "flex",
    flexWrap: "wrap",
  },
  iconButtonUnexpanded: {
    position: "absolute",
    right: "20px",
    top: "20px",
  },
  iconButtonExpanded: {
    position: "absolute",
    right: "20px",
    bottom: "10px",
  },
  notesBookmarkContainer: {
    border: "1px solid #CC889F",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "2em",
    position: "absolute",
    right: "0%",
    top: "0%",
    width: "150px",
    height: "60px",
    borderRadius: "0.8em",
  },
  savedNotesIcon: {
    position: "relative",
    cursor: "pointer",
    fontSize: "11px",
    color: "black",
    top: "-10%",
    "&::before": {
      content: '"Notes"',
      position: "absolute",
      top: "100%",
      left: "-20%",
      width: "100px",
      height: "30px",
    },
  },
  bookmarkIcon: {
    position: "relative",
    cursor: "pointer",
    color: "black",
    fontSize: "11px",
    top: "-10%",
    "&::before": {
      content: '"Bookmark"',
      position: "absolute",
      top: "100%",
      left: "-60%",
      width: "100px",
      height: "30px",
    },
  },
  buttonStyleNo: {
    border: "1px solid #BE888E",
    borderRadius: "0.6em",
    textTransform: "none" as const,
    "&:hover": {
      background: "#FF5D5B",
      color: "white",
    },
    width: "200px",
    height: "50px",
    color: "#4F1f6d",
    background: "white",
  },
  expandMoreIcon: {
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    color: "white",
    borderRadius: "50%",
  },
  expandLessIcon: {
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    color: "white",
    borderRadius: "50%",
  },
}));

const AccordionComponent: React.FC<Root> = ({
  id,
  attributes,
  createBookmark,
  chapterName,
  chapterId,
  subjectId,
  withNotesBookmark,
  disableClick,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [openSaveNotesModal, setOpenSaveNotesModal] = useState(false);
  const [openBookmarkModal, setOpenBookmarkModal] = useState(false);

  const toggleAccordion = () => {
    if (disableClick) {
      return setExpanded(false);
    }
    setExpanded(!expanded);
  };

  const handleNotesModal = () => {
    setOpenSaveNotesModal(!openSaveNotesModal);
  };

  const handleBookmarkModal = () => {
    setOpenBookmarkModal(!openBookmarkModal);
  };

  const handleBookmarkCreate = () => {
    if (createBookmark) {
      createBookmark(id, String(attributes.question_num));
    }
  };

  return (
    <Box>
      <Modal open={openSaveNotesModal} onClose={handleNotesModal}>
        <Box sx={webStyle.modalStyle}>
          <h3>Choose What You Need</h3>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <Link
              to={`/dashboard/question-bank/subject/${subjectId}/chapter/${chapterId}/saved-notes?chapterName=${chapterName}`}
            >
              <Button className={classes.buttonStyleNo}>
                Go to saved notes
              </Button>
            </Link>
            <Link
              to={`/dashboard/question-bank/subject/${subjectId}/chapter/${chapterId}/saved-notes/notes?noteType=New&reviewId=${attributes.chapter_id}&qno=${attributes.question_num}&qid=${attributes.id}&chapterName=${chapterName}`}
            >
              <Button className={classes.buttonStyleNo}>Create New Note</Button>
            </Link>
          </div>
        </Box>
      </Modal>
      <Modal onClose={handleBookmarkModal} open={openBookmarkModal}>
        <Box sx={webStyle.modalStyle}>
          <h3>Choose What You Need</h3>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <Link
              to={`/dashboard/question-bank/subject/${subjectId}/chapter/${chapterId}/bookmark?chapterName=${chapterName}`}
            >
              <Button
                className={classes.buttonStyleNo}
                data-test-id="gotobookmarks-modal"
              >
                Go to Saved Bookmark
              </Button>
            </Link>
            <Button
              className={classes.buttonStyleNo}
              data-test-id="save-bookmarks-modal"
              onClick={handleBookmarkCreate}
            >
              Create New Bookmark
            </Button>
          </div>
        </Box>
      </Modal>
      <Accordion
        onClick={toggleAccordion}
        expanded={expanded}
        className={classes.accordionMain}
      >
        <AccordionSummary className={classes.accordionSummary}>
          <Box
            width={"90%"}
            style={{
              fontWeight: 500,
              fontSize: "1.3em",
            }}
          >
            <div
              style={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
                wordBreak: "break-all",
              }}
              dangerouslySetInnerHTML={{
                __html: attributes.question_num + ". " + attributes.question,
              }}
            />
          </Box>
          {!expanded && (
            <IconButton className={classes.iconButtonUnexpanded}>
              <ExpandMoreIcon className={classes.expandMoreIcon} />
            </IconButton>
          )}
          {expanded && (withNotesBookmark as boolean) && (
            <Box className={classes.notesBookmarkContainer}>
              <p className={classes.savedNotesIcon} onClick={handleNotesModal}>
                <EventNoteOutlinedIcon data-test-id="notes-modal" />
              </p>
              <p className={classes.bookmarkIcon} onClick={handleBookmarkModal}>
                <AssignmentTurnedInOutlinedIcon data-test-id="bookmark-modal" />
              </p>
            </Box>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Box width={"95%"}>
            <Typography
              variant={"subtitle1"}
              style={{ color: "#520082", fontWeight: 500 }}
            >
              Answer:
            </Typography>
            <div
              dangerouslySetInnerHTML={{
                __html: attributes.solution,
              }}
            />
          </Box>
          {expanded && (
            <IconButton
              onClick={toggleAccordion}
              className={classes.iconButtonExpanded}
            >
              <ExpandLessIcon className={classes.expandLessIcon} />
            </IconButton>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AccordionComponent;

const webStyle = {
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "max-content",
    width: "35%",
    bgcolor: "background.paper",
    border: "2px solid #FFD0D8",
    borderRadius: "1.5em",
    padding: "2em",
    gap: "1.5em",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};
