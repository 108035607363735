import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Button,
  Typography,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import { Formik } from "formik";
import Select from "react-select";
import { colourStyles2 } from "../../../framework/src/UtilsWeb";
import TodayIcon from "@material-ui/icons/Today";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import BookMySlotAddEditSlotController, {
  Props,
  configJSON,
} from "./BookMySlotAddEditSlotController.web";

class BookMySlotAddEditSlot extends BookMySlotAddEditSlotController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      title,
      counsellingManagers,
      counsellingOfficers,
      groups,
    } = this.state;

    const status = [
      { value: "first_presentation", label: "First Presentation" },
      { value: "follow_up", label: "Follow up" },
      { value: "rejected", label: "Rejected" },
      { value: "registration_done", label: "Registration Done" },
    ];

    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.isLoading} />
        <BannerWeb title={title} />
        <Modal
          open={this.state.isUpdatedModal}
          onClose={this.handleIsUpdatedModal}
          data-test-id="modal-slot-booked"
        >
          <Box sx={webStyle.modalStyle}>
            <Typography>
              {this.state.slotId
                ? "Slot has been updated successfulyy"
                : configJSON.successSlotBook}
            </Typography>
            <Button
              style={webStyle.buttonStyle}
              data-test-id="slot-booking-btn"
              onClick={this.navigateToSlotBookings}
            >
              Go to slot bookings
            </Button>
          </Box>
        </Modal>
        <Box padding="2em">
          <Formik
            initialValues={{
              studentName: this.state.studentName || "",
              parentName: this.state.parentName || "",
              mobileNumber: this.state.mobileNumber || "",
              emailId: this.state.emailId || "",
            }}
            enableReinitialize={true}
            onSubmit={(values, { resetForm }) => {
              this.handleRegisterSlot(values);
              resetForm();
            }}
            validationSchema={this.addSlotSchema}
            data-test-id="formik"
          >
            {(props) => (
              <>
                <form
                  data-test-id="formsubmit"
                  onSubmit={props.handleSubmit}
                  className={this.props.classes.formStyles}
                >
                  <Box className={this.props.classes.formInputBox}>
                    <label htmlFor="studentName">Student Name</label>
                    <input
                      id="studentName"
                      className={`input-form`}
                      name="studentName"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.studentName}
                      placeholder="Enter Student name"
                      data-test-id="form-student-name"
                      type="text"
                      spellCheck={false}
                    />
                    {props.errors.studentName && props.touched.studentName && (
                      <Box id="feedback">{props.errors.studentName}</Box>
                    )}
                  </Box>
                  <Box className={this.props.classes.formInputBox}>
                    <label htmlFor="parentName">Parent Name</label>
                    <input
                      id="parentName"
                      data-test-id="form-parent-name"
                      className={`input-form`}
                      name="parentName"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.parentName}
                      placeholder="Enter Parent name"
                      type="text"
                      spellCheck={false}
                    />
                    {props.errors.parentName && props.touched.parentName && (
                      <Box id="feedback">{props.errors.parentName}</Box>
                    )}
                  </Box>
                  <Box className={this.props.classes.formInputBox}>
                    <label htmlFor="mobileNumber">Mobile number</label>
                    <input
                      id="mobileNumber"
                      data-test-id="form-mobile-number"
                      className={`input-form`}
                      name="mobileNumber"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.mobileNumber}
                      placeholder="Enter Mobile number"
                      type="text"
                      spellCheck={false}
                    />
                    {props.errors.mobileNumber &&
                      props.touched.mobileNumber && (
                        <Box id="feedback">{props.errors.mobileNumber}</Box>
                      )}
                  </Box>
                  <Box className={this.props.classes.formInputBox}>
                    <label htmlFor="emailId">Email ID</label>
                    <input
                      id="emailId"
                      data-test-id="form-emailid"
                      className={`input-form`}
                      name="emailId"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.emailId}
                      placeholder="Enter Email ID"
                      type="text"
                      spellCheck={false}
                    />
                    {props.errors.emailId && props.touched.emailId && (
                      <Box id="feedback">{props.errors.emailId}</Box>
                    )}
                  </Box>
                  <Box className={this.props.classes.formInputBox}>
                    <label htmlFor="emailId">Date And Time</label>
                    <Box display={"flex"} gridGap={"2em"}>
                      <Box position={"relative"} width={"100%"}>
                        <input
                          id="dateAndTime"
                          data-test-id="form-dateAndTime"
                          className={`input-form`}
                          name="dateAndTime"
                          value={this.state.date}
                          placeholder="Select Slot Date"
                          type="text"
                          spellCheck={false}
                          disabled
                        />
                        <TodayIcon
                          htmlColor="#520082"
                          className={this.props.classes.calendarIcon}
                          data-test-id="calendar-choose"
                          onClick={() => this.navigateToCalendar(props.values)}
                        />
                      </Box>
                      <input
                        id="time"
                        data-test-id="form-time"
                        className={`input-form`}
                        name="time"
                        value={this.state.time}
                        placeholder="Time Slot"
                        type="text"
                        spellCheck={false}
                        disabled
                      />
                    </Box>
                  </Box>
                  <Box className={this.props.classes.formInputBox}>
                    <label htmlFor="counsellingManager">
                      Counselling Manager
                    </label>
                    <Select
                      id="counsellingManager"
                      isSearchable={true}
                      isClearable={true}
                      name="counsellingManager"
                      options={counsellingManagers}
                      placeholder="Select Counselling manager"
                      data-test-id="form-counselling-manager"
                      onChange={(event) =>
                        this.handleUserInputReactSelect(
                          event,
                          "counsellingManager"
                        )
                      }
                      value={this.state.userInput.counsellingManager}
                      styles={colourStyles2}
                    />
                  </Box>
                  <Box className={this.props.classes.formInputBox}>
                    <label htmlFor="counsellingOfficer">
                      Counselling Officer
                    </label>
                    <Select
                      id="counsellingOfficer"
                      isSearchable={true}
                      isClearable={true}
                      name="counsellingOfficer"
                      options={counsellingOfficers}
                      placeholder="Select Counselling officer"
                      data-test-id="form-counselling-officer"
                      onChange={(event) =>
                        this.handleUserInputReactSelect(
                          event,
                          "counsellingOfficer"
                        )
                      }
                      value={this.state.userInput.counsellingOfficer}
                      styles={colourStyles2}
                    />
                  </Box>
                  <Box className={this.props.classes.formInputBox}>
                    <label htmlFor="selectStudentGroup">Select Group</label>
                    <Select
                      id="selectStudentGroup"
                      isSearchable={true}
                      isClearable={true}
                      name="selectStudentGroup"
                      options={groups}
                      placeholder="Select Student Group"
                      data-test-id="form-student-group"
                      onChange={(event) =>
                        this.handleUserInputReactSelect(
                          event,
                          "selectStudentGroup"
                        )
                      }
                      value={this.state.userInput.selectStudentGroup}
                      styles={colourStyles2}
                      required
                    />
                  </Box>
                  <Box className={this.props.classes.formInputBox}>
                    <label htmlFor="selectStudentStatus">Select Status</label>
                    <Select
                      id="selectStudentStatus"
                      isSearchable={true}
                      isClearable={true}
                      name="selectStudentStatus"
                      options={status}
                      placeholder="Select Status"
                      data-test-id="form-student-status"
                      value={this.state.userInput.selectStudentStatus}
                      onChange={(event) =>
                        this.handleUserInputReactSelect(
                          event,
                          "selectStudentStatus"
                        )
                      }
                      styles={colourStyles2}
                      required
                    />
                  </Box>
                  <Button data-test-id="submit-slot" type="submit">
                    {this.state.slotId ? "Update Slot" : "Register Slot"}
                  </Button>
                </form>
              </>
            )}
          </Formik>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
  modalStyle: {
    bgcolor: "background.paper",
    border: "2px solid #FFD0D8",
    borderRadius: "1.5em",
    padding: "2em",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    minHeight: "200px",
    height: "max-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute" as "absolute",
    gap: "1.5em",
  },
  buttonStyle: {
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    width: "40%",
    height: "55px",
    color: "white",
    alignSelf: "center",
  },
};
export const webStyleBookMySlotAddEditSlot = () =>
  createStyles({
    formStyles: {
      display: "flex",
      flexDirection: "column",
      gap: "2em",
      "& .input-form": {
        border: "1px solid #FF6B55",
        height: "51px",
        padding: "0 1em",
        width: "100%",
        borderRadius: "0.5em",
        "&:hover": {
          outline: "none",
        },
      },
      "& button": {
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        width: "40%",
        height: "55px",
        color: "white",
        alignSelf: "center",
      },
    },
    formInputBox: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      gap: "1em",
      "& label": {
        fontWeight: 500,
        fontSize: "16px",
      },
      "& #feedback": {
        position: "absolute",
        fontSize: "11px",
        bottom: -20,
        color: "red",
        fontWeight: 500,
      },
    },
    calendarIcon: {
      position: "absolute",
      right: 10,
      top: 10,
      cursor: "pointer",
      fontSize: "30px",
    },
  });
export default withStyles(webStyleBookMySlotAddEditSlot)(BookMySlotAddEditSlot);
export { BookMySlotAddEditSlot };
// Customizable Area End
