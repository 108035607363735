export const myranklogo = require("../assets/myrank_svg-logo.png");
export const home = require("../assets/home.png");
export const book = require("../assets/book.png");
export const document = require("../assets/document.png");
export const message = require("../assets/message.png");
export const computer = require("../assets/pc.png");
export const calculator = require("../assets/calculator.png");
export const calendar = require("../assets/calendar.png");
export const sms = require("../assets/sms.png");
export const searchImg = require("../assets/searchImg.png");
export const searchImage = require("../assets/searchImage.png");
export const gradhat = require("../assets/gradhat.png");
export const notes = require("../assets/note-2 copy 3.png");
export const  linearButton = require("../assets/button_filled.png");
export const  remove = require("../assets/trash.png");
export const  edit = require("../assets/edit-2.png");
export const logout = require("../assets/logout.png");
export const clock = require("../assets/clock.png");
export const oval = require("../assets/oval.png");
export const logoMyRank = require('../assets/MyRanklogo.png');
export const profileIcon = require('../assets/profileicon.png');
export const notificationIcon =  require('../assets/notificationicon.png');
export const swiperImg = require('../assets/GroupIcon.png');
export const timerImage = require('../assets/timer-start.png');
export const bookmarks = require('../assets/saveImg.png');
export const savedNotes = require('../assets/bookrmarkImg.png');
export const noteIcon = require('../assets/note-icon.png')
export const documentIcon = require('../assets/document-icon.png')
export const bookIcon = require('../assets/book-icon.png')
export const pcIcon = require('../assets/pc-icon.png')


