import React, { useState } from "react";
import { Typography, IconButton, Box } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { makeStyles } from "@material-ui/core/styles";
import { IUserDocument } from "../../blocks/BulkUploading/src/DocumentsUploadController.web";

const useStyles = makeStyles(() => ({
  documentContainer: {
    boxShadow: "none",
    border: "1px solid #FF4D5A",
    borderRadius: "0.5em",
    width: "100%",
    position: "relative",
  },
  documentSummary: {
    minHeight: "80px",
    height: "max-content",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "1em 2em",
  },
  documentDetails: {
    minHeight: "250px",
    height: "max-content",
    display: "flex",
    border: "1px solid #FF4D5A",
    marginTop: "1.5em",
    width: "100%",
  },
  iconButtonUnexpanded: {
    position: "absolute",
    right: "20px",
    top: "20px",
  },
  expandMoreIcon: {
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    color: "white",
    borderRadius: "50%",
  },
  expandLessIcon: {
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    color: "white",
    borderRadius: "50%",
  },
  updatedContainer: {
    background: "#E9F0FF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "160px",
  },
  verifiedContainer: {
    background: "#FFF3F3",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "160px",
  },
}));

const UserDocumentWideCard: React.FC<IUserDocument> = ({ ...props }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <Box>
      <Box onClick={toggleAccordion} className={classes.documentContainer}>
        <Box className={classes.documentSummary}>
          <Box>
            <Typography variant="h6">
              {props.attributes.document.document_name}
            </Typography>
          </Box>
          {expanded ? (
            <IconButton className={classes.iconButtonUnexpanded}>
              <ExpandLessIcon className={classes.expandLessIcon} />
            </IconButton>
          ) : (
            <IconButton className={classes.iconButtonUnexpanded}>
              <ExpandMoreIcon className={classes.expandMoreIcon} />
            </IconButton>
          )}
        </Box>
      </Box>
      {expanded && (
        <Box className={classes.documentDetails}>
          <Box width={"50%"}>
            <img />
            <a href={props.attributes.file_link} download>
              Download
            </a>
          </Box>
          <Box width={"50%"} display={"flex"} gridGap={"2em"} padding={"2em"}>
            <Box className={classes.updatedContainer}>
              <Typography>Updated On: {props.attributes.updated_at}</Typography>
              <Typography>Updated By: {props.attributes.created_by}</Typography>
            </Box>
            <Box className={classes.verifiedContainer}>
              <Typography>
                Verified On: {props.attributes.verified_date}
              </Typography>
              <Typography>
                Verified By: {props.attributes.document.created_by}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserDocumentWideCard;
