import React from "react";

import {
  Box as MUIBox,
  // Customizable Area Start
  Modal,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import QuestionBankBookmarksController, {
  Props,
} from "./QuestionBankBookmarksController.web";

export default class QuestionBankBookmarks extends QuestionBankBookmarksController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MUIBox>
        <Loader loading={this.state.loading} />
        <Modal
          open={this.state.submitModalOpen}
          onClose={this.handleSubmitModalClose}
          BackdropProps={{ onClick: undefined }}
        >
          <MUIBox sx={webStyle.modalStyle} className="submit-test-question">
            <h3>Are you sure you want to delete this Bookmark?</h3>
            <MUIBox>
              <Button
                style={webStyle.buttonStyleNo}
                data-test-id="closeSubmit"
                onClick={this.handleSubmitModalClose}
              >
                Cancel
              </Button>
              <Button
                style={webStyle.buttonStyleYes}
                data-test-id="deleteModal"
                onClick={() =>
                  this.deleteBookmarkNotes(
                    this.bookmarkIdToDelete,
                    this.indexToDelete
                  )
                }
              >
                Delete
              </Button>
            </MUIBox>
          </MUIBox>
        </Modal>
        <Modal
          open={this.state.submitModalOpen2}
          onClose={this.handleSubmitModalClose2}
          BackdropProps={{ onClick: undefined }}
        >
          <MUIBox sx={webStyle.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>Deleted Bookmark Successfully</h3>
            <MUIBox>
              <Button
                style={webStyle.buttonStyleYes}
                data-test-id="submitBtnModal"
                onClick={this.handleSubmitModalClose2}
              >
                Go Back
              </Button>
            </MUIBox>
          </MUIBox>
        </Modal>
        <MUIBox sx={webStyle.bannerStyle}>
          <h2>Question Bank</h2>
        </MUIBox>
        <MUIBox sx={webStyle.centerHorizontally} style={{ marginTop: "2em" }}>
          <h1>Bookmarks</h1>
          <MUIBox sx={webStyle.centerVertically}>
            <p>Exam : {this.state.subjectName}</p>
            <p>Bookmarks - {this.state.bookmarks.length}</p>
          </MUIBox>
        </MUIBox>
        <MUIBox sx={webStyle.centerHorizontally}>
          {this.state.bookmarks.map((bookmark, index) => {
            return (
              <MUIBox
                key={`box-${index}`}
                sx={webStyle.cardBookmark}
                data-test-id="navigation-box"
                onClick={() =>
                  this.navigateToMain(
                    bookmark.attributes.bookmarkable.question_type
                  )
                }
              >
                <p style={webStyle.subtitle2Style}>
                  Question No : {bookmark.attributes.question_no}
                </p>
                <MUIBox>
                  <p style={webStyle.subtitleColorStyle}>
                    You have Bookmarked this Question
                  </p>
                  <Button
                    style={webStyle.buttonDeleteStyle}
                    data-test-id="delete-init"
                    onClick={(event) => {
                      event.stopPropagation();
                      return this.initializeBookmarkDeletion(
                        bookmark.id,
                        index
                      );
                    }}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </Button>
                </MUIBox>
              </MUIBox>
            );
          })}
        </MUIBox>
      </MUIBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  subtitleColorStyle: {
    color: "#744390",
  },
  bannerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    color: "rgb(82, 0, 130)",
  },
  subtitle2Style: {
    fontWeight: 500,
  },
  buttonStyleNo: {
    width: "130px",
    height: "50px",
    color: "#4F1f6d",
    background: "white",
    border: "1px solid #ffe9ed",
    borderRadius: "0.6em",
    textTransform: "none" as const,
  },
  buttonDeleteStyle: {
    position: "absolute" as const,
    top: "37px",
    right: "20px",
  },
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute" as "absolute",
    alignItems: "center",
    top: "50%",
    left: "50%",
    width: "35%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    minHeight: "max-content",
    border: "2px solid #FFD0D8",
    borderRadius: "1.5em",
    padding: "2em",
    gap: "1.5em",
  },
  buttonStyleYes: {
    width: "130px",
    height: "50px",
    color: "white",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    textTransform: "none" as const,
    marginLeft: "1em",
  },
  centerVertically: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "678.55px",
    marginBottom: "1em",
  },
  cardBookmark: {
    width: "678.55px",
    height: "116.8px",
    background: "rgba(209, 170, 255, 0.14)",
    border: "1px solid #FCC2C0",
    borderRadius: "10px",
    position: "relative",
    padding: "1em",
    cursor: "pointer",
  },
  centerHorizontally: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1em",
  },
};
// Customizable Area End
