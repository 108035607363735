import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { deleteImg, edit, nosavednotes } from "./assets";
import CButton from "../../../components/src/Button.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import SynopsisSavedNotesController, {
  Props,
} from "./SynopsisSavedNotesController.web";

class SynopsisSavedNotes extends SynopsisSavedNotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box className={this.props.classes.bannerStyle}>
          <Typography variant="h5">Saved Notes</Typography>
        </Box>
        <Box>
          {this.state.savedNotes.length > 0 && (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gridGap={"3em"}
              margin={"3em 0"}
              className={this.props.classes.heading}
            >
              <>
                <Typography variant="h5">
                  Subject : {this.state.subject?.attributes?.name}
                </Typography>
                <Typography variant="h5">
                  Total topics : {this.state.subject?.attributes?.total_topics}
                </Typography>
                <Typography variant="h5">
                  Topic Name : {this.state.topic?.attributes?.name}
                </Typography>
                <Typography variant="h5">
                  Saved Notes In Topic : {this.state.savedNotes?.length}
                </Typography>
              </>
            </Box>
          )}
          <Box
            display="flex"
            flexWrap={"wrap"}
            gridGap={"2em"}
            padding={"2em"}
            justifyContent={"center"}
          >
            {this.state.savedNotes?.map((savedNotes) => {
              return (
                <Box
                  position={"relative"}
                  key={savedNotes.id}
                  className={this.props.classes.cardStyle}
                >
                  <Box className={this.props.classes.editDelete}>
                    <Box>
                      <img
                        src={edit}
                        alt="edit"
                        data-test-id="to-add-edit"
                        onClick={() =>
                          this.navigateToAddEdit(String(savedNotes.id))
                        }
                      />
                    </Box>
                    <Box>
                      <img
                        src={deleteImg}
                        alt="delete"
                        data-test-id="bookmark-modal-btn"
                        onClick={() =>
                          this.handleBookmarkModal(String(savedNotes.id))
                        }
                      />
                    </Box>
                  </Box>
                  <Typography>{savedNotes.notes_content}</Typography>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="body1"
                      className={this.props.classes.noteTimeStyle}
                    >
                      {this.getTimestamp(savedNotes.updated_at)}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
            {this.state.savedNotes.length === 0 && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                gridGap={"1em"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography variant="h5">There is no Saved Notes</Typography>{" "}
                <img src={nosavednotes} alt="illustration-notes-empty" />
                <CButton
                  color="white"
                  height="50px"
                  width="220px"
                  title="Add New Note"
                  key={"button"}
                  onClick={() => this.navigateToNewNote()}
                  data-test-id="nav-savednotes"
                />
                <CButton
                  color="#FE495E"
                  height="50px"
                  width="220px"
                  title="Cancel"
                  background="white"
                  border="1px solid #FE495E"
                  key={"button-cancel"}
                  onClick={() => this.navigateToTopics()}
                  data-test-id="nav-cancel"
                />
              </Box>
            )}
          </Box>
        </Box>
        <Modal
          open={this.state.modalDeleteConfirm}
          BackdropProps={{ onClick: undefined }}
          onClose={() => this.handleBookmarkModal()}
        >
          <Box className={this.props.classes.modalStyle}>
            <h3>Are you sure you want to Delete Note?</h3>
            <Box>
              <Button
                onClick={() => this.handleBookmarkModal()}
                className={this.props.classes.buttonStyleNo}
                data-test-id="closeSubmit"
              >
                Cancel
              </Button>
              <Button
                className={this.props.classes.buttonStyleYes}
                data-test-id="deleteModal"
                onClick={this.deleteSavedNotes}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          onClose={this.handleDeleteBookmark}
          open={this.state.modalDelete}
          BackdropProps={{ onClick: undefined }}
        >
          <Box className={this.props.classes.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>Note Deleted</h3>
            <Box>
              <Button
                className={this.props.classes.buttonStyleYes}
                data-test-id="submitBtnModal"
                onClick={this.handleDeleteBookmark}
              >
                Go Back
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleSynopsisSavedNotes = () =>
  createStyles({
    heading: {
      "& h5": {
        fontWeight: 600,
      },
    },
    bannerStyle: {
      justifyContent: "center",
      width: "100%",
      alignItems: "center",
      display: "flex",
      background: "rgb(247,242,249)",
      color: "#222",
      "& h5": {
        fontWeight: 600,
      },
      height: "71px",
    },
    cardStyle: {
      width: "48%",
      minHeight: "200px",
      height: "max-content",
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "center",
      padding: "2em 1em",
      border: "1px solid rgba(255, 63, 89, 1)",
      borderRadius: "0.5em",
      gap: "1em",
      position: "relative",
      margin: "2em 0",
    },
    noteTimeStyle: {
      color: "#999",
      fontSize: "0.9em",
      position: "absolute",
      bottom: "10px",
      right: "20px",
    },
    modalStyle: {
      background: "white",
      display: "flex",
      flexDirection: "column",
      transform: "translate(-50%, -50%)",
      width: "35%",
      minHeight: "max-content",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute" as "absolute",
      left: "50%",
      bgcolor: "background.paper",
      border: "2px solid #FFD0D8",
      borderRadius: "1.5em",
      padding: "2em",
      top: "50%",
      gap: "1.5em",
    },
    buttonStyleNo: {
      width: "130px",
      border: "1px solid #ffe9ed",
      borderRadius: "0.6em",
      height: "50px",
      color: "#4F1f6d",
      background: "white",
      textTransform: "none" as const,
    },
    buttonStyleYes: {
      height: "50px",
      color: "white",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      textTransform: "none" as const,
      width: "130px",
      marginLeft: "1em",
    },
    editDelete: {
      position: "absolute",
      top: "-40px",
      right: "20px",
      display: "flex",
      gap: "1em",
      "& img": {
        width: "30px",
        height: "30px",
        cursor: "pointer",
      },
    },
  });
export default withStyles(webStyleSynopsisSavedNotes)(SynopsisSavedNotes);
export { SynopsisSavedNotes };
// Customizable Area End
