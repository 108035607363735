import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

type MetaType = {
  chapter: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    practice_test: string;
    avail_pt: string;
    date_pt: string | null;
    class_zone: string;
    avail_cz: string;
    date_cz: string | null;
    question_bank: string;
    avail_qb: string;
    date_qb: string | null;
    created_by: string;
    remarks: string;
    topic_db: string;
    qb_id: string | null;
    qb_db: string;
    id_pt: string;
    id_cz: string;
  };
  test_time: number;
  question_ids: Array<number>;
};

export interface TestType {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  topic_id: number;
  question_no: number;
  question_1: string;
  question_2: string;
  correct_answer: string;
  explanation: string;
  passage: string;
  question_type: string;
  p_mark: number;
  n_mark: string;
  remark: string;
  created_by: string;
  no_of_users_correct: number;
  no_of_users_attempted: number;
  options: Option[];
  percentage_people_correct: number;
  user_answer: string | null;
  opt_count: number | null;
  is_correct: boolean;
  is_unattended: any;
}

export interface Option {
  id: string;
  option: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  testData: TestType[];
  loading: boolean;
  meta: MetaType | null;
  testDataIndex: number;
  timeRemaining: number;
  showModalSubmit: boolean;
  endTime: number;
  answers: {
    question_id: number;
    option: string[][];
    legend?: string;
    is_marked?: boolean;
  }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GuestPracticeTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTestMessageId = "";
  intervalId: NodeJS.Timeout = setTimeout(() => {}, 0);
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.tick = this.tick.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      testData: [],
      loading: false,
      meta: null,
      testDataIndex: 0,
      timeRemaining: 0,
      showModalSubmit: false,
      endTime: 0,
      answers: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getTestMessageId && response) {
      if (response?.data) {
        const endTime = Date.now() + response.meta.test_time * 1000;
        const timeRemaining = Math.max(endTime - Date.now(), 0);
        this.handleTest(response.data);
        this.handleMeta(response.meta);
        this.handleTimerStart(endTime, timeRemaining);
      }
    }

    this.setState({
      loading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const chapterId = this.props.navigation.getParam("chapterId");
    this.getTest(chapterId);
  };

  componentDidUpdate(_: Props, prevState: S) {
    if (
      JSON.stringify(prevState.answers) !==
        JSON.stringify(this.state.answers) ||
      JSON.stringify(prevState.testDataIndex) !==
        JSON.stringify(this.state.testDataIndex)
    ) {
      this.handleAnswersChanged(prevState);
    }

    if (prevState.testDataIndex !== this.state.testDataIndex) {
      this.submitOverviewPerQuestion(prevState.testDataIndex);
    }
  }

  // Customizable Area Start
  submitOverviewPerQuestion = (prevIndex: number) => {
    let currentAnswers = this.state.answers[prevIndex];
    const isAnswered = currentAnswers?.option?.[0]?.[0]?.length > 0;
    const legend = currentAnswers?.legend;

    if (!isAnswered && legend === undefined) {
      currentAnswers = { ...currentAnswers, legend: "un_answered" };
    }

    const answers = this.state.answers;
    answers[prevIndex] = currentAnswers;

    this.setState({
      answers,
    });
  };

  handleNextQuestion = () => {
    if (this.state.testDataIndex !== this.state.testData.length - 1) {
      this.setState({
        testDataIndex: this.state.testDataIndex + 1,
      });
    }
  };

  tick = () => {
    const newTimeRemaining = Math.max(this.state.endTime - Date.now(), 0);
    this.setState({ timeRemaining: newTimeRemaining });

    if (newTimeRemaining <= 0) {
      clearInterval(this.intervalId);
      this.timeReachedZero();
    }
  };

  handlePreviousQuestion = () => {
    if (this.state.testDataIndex !== 0) {
      this.setState({
        testDataIndex: this.state.testDataIndex - 1,
      });
    }
  };

  handleSpecificQuestion = (index: number) => {
    this.setState({
      testDataIndex: index,
    });
  };

  handleTimerStart = (endTime: number, timeRemaining: number) => {
    this.setState(
      {
        endTime,
        timeRemaining,
      },
      () => {
        this.intervalId = setInterval(this.tick, 1000);
      }
    );
  };

  setAnswer = (index: number, option: string[][]) => {
    const answers = [...this.state.answers];

    answers[index] = {
      ...this.state.answers[index],
      question_id: this.state.testData[index].attributes.id,
      option,
    };

    this.setState({ answers });
  };

  getBtnClassName = (index: number) => {
    const current = this.state.answers[index];

    if (current?.legend === "answered") {
      return "btn-answered";
    }
    if (current?.legend === "un_answered") {
      return "btn-unanswered";
    }
    if (current?.legend === "answered_and_marked_for_review") {
      return "btn-answered-marked";
    }
    if (current?.legend === "not_answered_and_marked_for_review") {
      return "btn-unanswered-marked";
    }
    if (current?.legend === "marked_and_un_marked_for_review") {
      return "btn-marked-unmarked";
    }
    if (current?.legend === "answered_and_cleared_the_answer") {
      return "btn-answered-cleared";
    }

    return "";
  };

  timeReachedZero = () => {
    clearInterval(this.intervalId);
    this.showModalSubmit();
  };

  clearResponse = () => {
    const answers = [...this.state.answers];

    answers[this.state.testDataIndex] = {
      ...answers[this.state.testDataIndex],
      option: [[]],
    };

    this.setState({
      answers,
    });
  };

  showModalSubmit = () => {
    clearInterval(this.intervalId);
    this.setState({
      showModalSubmit: !this.state.showModalSubmit,
    });
  };

  markForReview = () => {
    const newAnswer = [...this.state.answers];

    newAnswer[this.state.testDataIndex] = {
      ...newAnswer[this.state.testDataIndex],
      is_marked: !newAnswer[this.state.testDataIndex].is_marked,
    };

    this.setState({
      answers: newAnswer,
    });
  };

  handleAnswersChanged = (prevState: S) => {
    let currentAnswers = this.state.answers[this.state.testDataIndex];

    const isAnswered = currentAnswers?.option?.[0]?.[0]?.length > 0;
    const isMarked = currentAnswers?.is_marked;

    if (isMarked) {
      currentAnswers = { ...currentAnswers, is_marked: true };
    } else {
      currentAnswers = { ...currentAnswers, is_marked: false };
    }

    if (isAnswered) {
      currentAnswers = { ...currentAnswers, legend: "answered" };
    }

    if (isAnswered && isMarked) {
      currentAnswers = {
        ...currentAnswers,
        legend: "answered_and_marked_for_review",
      };
    }

    if (!isAnswered && isMarked) {
      currentAnswers = {
        ...currentAnswers,
        legend: "not_answered_and_marked_for_review",
      };
    }

    if (
      prevState.answers[this.state.testDataIndex]?.option?.[0]?.[0]?.length >
        0 &&
      !currentAnswers?.option?.[0]?.[0]?.length
    ) {
      currentAnswers = {
        ...currentAnswers,
        legend: "answered_and_cleared_the_answer",
      };
    }

    if (
      prevState.answers[this.state.testDataIndex]?.is_marked &&
      !currentAnswers?.is_marked
    ) {
      currentAnswers = {
        ...currentAnswers,
        legend: "marked_and_un_marked_for_review",
      };
    }

    const answers = this.state.answers;
    answers[this.state.testDataIndex] = currentAnswers;

    this.setState({
      answers,
    });
  };

  navigateToRegister = () => {
    this.props.navigation.history.push("/register");
  };

  getTest = async (chapterId: string) => {
    const getTestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      uuid: await storage.get("guestToken"),
    };

    this.setState({
      loading: true,
    });

    this.getTestMessageId = getTestMessage.messageId;

    getTestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/guest_practice_tests/${chapterId}/guest_take_test`
    );

    getTestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getTestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getTestMessage.id, getTestMessage);
  };

  handleTest = (response: TestType[]) => {
    this.setState({
      testData: response,
    });
  };

  handleMeta = (response: MetaType) => {
    this.setState({
      meta: response,
    });
  };
  // Customizable Area End
}
