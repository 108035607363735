import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { styles } from "./styles";
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

export interface Root {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  question_id: number;
  grand_test_id: number;
  gt_review_id: number;
  sub_name: string;
  question_no: number;
  question: string;
  options: Option[];
  correct_answer: string;
  explanation: string;
  heading: string;
  question_type: string;
  is_correct: boolean;
  is_wrong: boolean;
  is_unattempt: boolean;
  p_mark: string;
  n_mark: string;
}

export interface Option {
  id: string;
  option: string;
}

// Customizable Area End

export interface Props extends WithStyles<typeof styles> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  timeTaken: string;
  total: number | null;
  correctPercentage: number | null;
  incorrectPercentage: number | null;
  unattendedPercentage: number | null;
  reviewId: string;
  isTabular: boolean;
  reviewData: {
    subject_name: string;
    correct: number;
    incorrect: number;
    unattempted: number;
    totalMarks: number;
  }[];
  totalMarks: string;
  testId: string;
  breadcrumb: Array<{ label: string; link: string }>;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GrandTestTakingReviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTestNameMessageReviewId = "";
  getPackageReviewTestMessageId = "";
  breadcrumb = [
    {
      label: "Grand Test",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}`,
    },
    {
      label: "Chapters",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}`,
    },
    {
      label: "Reviews",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}/review-test/${this.props.navigation.getParam("subjectId")}`,
    },
    {
      label: "Reviews",
      link: `/dashboard/grand-test/subjects/${this.props.navigation.getParam(
        "packageId"
      )}/review-test/${this.props.navigation.getParam("subjectId")}`,
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Result Overview",
    },
  ];
  token: string = "";
  getResultsMessageId: string = "";
  reviewId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      timeTaken: "",
      correctPercentage: null,
      total: null,
      unattendedPercentage: null,
      incorrectPercentage: null,
      reviewId: "",
      isTabular: false,
      reviewData: [],
      totalMarks: "",
      testId: "",
      breadcrumb: this.breadcrumb,
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getResultsMessageId) {
      if (response.meta) {
        this.handleReviewData(response);

        const total =
          response.meta.correct_answer +
          response.meta.incorrect_answer +
          response.meta.un_attempted_questions;

        this.setState({
          timeTaken: response.meta.taken_time,
          total,
          correctPercentage: Number(
            ((response.meta.correct_answer / total) * 100).toFixed(2)
          ),
          incorrectPercentage: Number(
            ((response.meta.incorrect_answer / total) * 100).toFixed(2)
          ),
          unattendedPercentage: Number(
            ((response.meta.un_attempted_questions / total) * 100).toFixed(2)
          ),
          totalMarks: response.meta.marks,
        });
      }
    }

    if (apiRequestCallId === this.getTestNameMessageReviewId) {
      if (response.data) {
        this.handleSuccessfulFetchTest(response.data);
      }
    }

    if (apiRequestCallId === this.getPackageReviewTestMessageId) {
      if (response.data) {
        this.handleSuccessfulPackage(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const testId = this.props.navigation.getParam("subjectId");
    this.getPackageReviewTestMessage();
    const token = await storage.get("authToken");
    if (token) {
      this.token = token;
    }
    const reviewId = this.props.navigation.getParam("testId");
    this.reviewId = reviewId;
    if (testId) {
      this.getReview(reviewId, testId);
    }

    const propPassingGTTRC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingGTTRC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingGTTRC);
  };

  handleReviewData = (response: { data: Root[] }) => {
    const responseData: Root[] = response.data;
    const reviewDataMap: {
      [subjectName: string]: {
        subject_name: string;
        correct: number;
        incorrect: number;
        unattempted: number;
        totalMarks: number;
      };
    } = {};

    responseData.forEach((subject: Root) => {
      const subjectName = subject.attributes.sub_name;
      const existingData = reviewDataMap[subjectName] || {
        subject_name: subjectName,
        correct: 0,
        incorrect: 0,
        unattempted: 0,
        totalMarks: 0,
      };

      existingData.correct += subject.attributes.is_correct ? 1 : 0;
      existingData.incorrect += subject.attributes.is_wrong ? 1 : 0;
      existingData.unattempted += subject.attributes.is_unattempt ? 1 : 0;
      existingData.totalMarks += subject.attributes.is_correct
        ? Number(subject.attributes.p_mark)
        : Number(subject.attributes.n_mark);

      reviewDataMap[subjectName] = existingData;
    });

    const reviewData = Object.values(reviewDataMap);

    this.setState({
      reviewData,
    });
  };

  setTabular = () => {
    this.setState({
      isTabular: true,
    });
  };

  setGraphical = () => {
    this.setState({
      isTabular: false,
    });
  };

  // Customizable Area Start
  getReview = (reviewId: string, testId: string) => {
    this.setState({
      reviewId: reviewId,
      testId: testId,
    });

    const headers = {
      token: this.token,
    };

    const getResultsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getResultsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/${testId}/review_test/${reviewId}`
    );

    this.getResultsMessageId = getResultsMessage.messageId;

    getResultsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getResultsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getResultsMessage.id, getResultsMessage);
  };

  handleSuccessfulFetchTest = (
    response: { id: string; attributes: { test_name: string } }[]
  ) => {
    const packageId = this.props.navigation.getParam("testId");

    response.forEach((data) => {
      if (data.id === packageId) {
        const breadcrumb = this.state.breadcrumb;
        breadcrumb[2] = { ...breadcrumb[2], label: data.attributes.test_name };

        const propPassingTakingReview = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingTakingReview.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingTakingReview);

        this.setState({
          breadcrumb,
          loading: false,
        });
      }
    });
  };

  handleSuccessfulPackage = (
    response: { gt_code: number; package_name: string }[]
  ) => {
    const packageId = this.props.navigation.getParam("packageId");
    response.forEach((data) => {
      if (data.gt_code === packageId) {
        const breadcrumb = this.state.breadcrumb;
        breadcrumb[1] = { ...breadcrumb[1], label: data.package_name };

        const propPassingTakingReview = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingTakingReview.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingTakingReview);

        this.setState(
          {
            breadcrumb,
            loading: false,
          },
          () => {
            this.getTestNameMessageReview();
          }
        );
      }
    });
  };

  getPackageReviewTestMessage = async () => {
    const getPackageMessageReview = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getPackageReviewTestMessageId =
      getPackageMessageReview.messageId;

    getPackageMessageReview.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getPackageMessageReview.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/get_packages`
    );

    getPackageMessageReview.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getPackageMessageReview.id,
      getPackageMessageReview
    );
  };

  getTestNameMessageReview = async () => {
    const getTestNameMessageReview = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getTestNameMessageReviewId =
      getTestNameMessageReview.messageId;

    getTestNameMessageReview.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getTestNameMessageReview.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/${this.props.navigation.getParam(
        "subjectId"
      )}/review_histories`
    );

    getTestNameMessageReview.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getTestNameMessageReview.id,
      getTestNameMessageReview
    );
  };
  // Customizable Area End
}
