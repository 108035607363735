import React from "react";

import {
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
export const styles = (theme: Theme) => {
  return createStyles({
    questionBankChapters: {
      width: "100%",
      padding: "0 2em",
    },
    chaptersContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "wrap",
      gap: "3em",
      "& .chapter-box": {
        display: "flex",
        border: "1px solid #C1A09D",
        borderRadius: "0.3em",
        minWidth: "500px",
        height: "140px",
        padding: "1em",
        marginTop: "1.5em",
        gap: "0.8em",
        position: "relative",
        "& .chapter-image": {
          background: "rgb(178, 176, 176)",
          border: "none",
          borderRadius: "50%",
          width: "55px",
          height: "55px",
        },
      },
    },
  });
};
import Button from "../../../components/src/Button.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import QuestionBankChaptersController, {
  Props,
  configJSON,
} from "./QuestionBankChaptersController.web";

class QuestionBankChapters extends QuestionBankChaptersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const classes = this.props.classes;
    const subjectId = this.props.navigation.getParam("subjectId");
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box sx={webStyles.bannerStyle}>
          <h2>{this.state.subjectName}</h2>
        </Box>
        <Box className={classes.questionBankChapters}>
          {this.state.chapters.length > 0 ? (
            <>
              <p style={webStyles.selectChapter}>{configJSON.selectChapter}</p>
              <Box className={classes.chaptersContainer}>
                {this.state.chapters.map((chapter, index) => {
                  return (
                    <Box
                      key={`${chapter.attributes.id}-${index}-question-bank-chapter`}
                      className="chapter-box"
                    >
                      <img
                        className="chapter-image"
                        src={chapter.attributes.image}
                        alt={String(chapter.attributes.id)}
                      />
                      <Box style={webStyles.nameBox}>
                        <p>{chapter.attributes.name}</p>
                        <Link
                          to={`/dashboard/question-bank/subject/${subjectId}/chapter/${chapter.id}`}
                        >
                          <Button
                            color="white"
                            height="45px"
                            title="View"
                            width="160px"
                          />
                        </Link>
                      </Box>
                      <p style={webStyles.learnNow}>
                        <span style={webStyles.learnNowCircle} />
                        Learn now
                      </p>
                    </Box>
                  );
                })}
              </Box>
            </>
          ) : (
            <p style={webStyles.selectChapter}>No Chapters found here.</p>
          )}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  bannerStyle: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    height: "71px",
    alignItems: "center",
    color: "rgb(82, 0, 130)",
    background: "rgba(247, 214, 255, 0.19)",
  },
  selectChapter: {
    fontSize: "1.5em",
    fontWeight: 500,
    marginTop: "3em",
  },
  nameBox: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-around",
  },
  learnNow: {
    position: "absolute" as const,
    right: "15px",
    bottom: "24px",
    fontSize: "0.8em",
  },
  learnNowCircle: {
    display: "inline-block",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "#FF5B5F" as const,
    marginRight: "0.6em",
  },
};

export default withStyles(styles)(QuestionBankChapters);
export { QuestionBankChapters };
// Customizable Area End
