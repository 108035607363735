import React from "react";
import { Box } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";

interface Props {
  backgroundColor?: string;
}

function Footer({ backgroundColor }: Props) {
  return (
    <Box
      width={"100%"}
      height={"50px"}
      display="flex"
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      bgcolor={`${backgroundColor ? backgroundColor : "510081"}`}
    >
      <Box
        width={"90%"}
        height={"50px"}
        display="flex"
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        component={"footer"}
        color={"white"}
      >
        <p>&copy; 2023 MyRank. All rights reserved.</p>
        <Box display={"flex"} gridGap={"1em"}>
          <TwitterIcon />
          <LinkedInIcon />
          <FacebookIcon />
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
