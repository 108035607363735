import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { styles } from "./styles";
import storage from "../../../framework/src/StorageProvider.web";
import { extractParams } from "../../../framework/src/UtilsWeb";
interface Option {
  id: string;
  option: string;
}

interface Chapter {
  id: number;
  name: string;
  updated_at: string;
  created_at: string;
  practice_test: string;
  avail_pt: string;
  date_pt: string;
  class_zone: string;
  avail_cz: string;
  date_cz: string;
  question_bank: string;
  avail_qb: string;
  date_qb: string;
  created_by: string;
  remarks: string;
  topic_db: string;
  qb_id: null | string;
  qb_db: string;
  id_pt: string;
  id_cz: string;
}

interface Test {
  chapter: Chapter;
  test_time: number;
  question_ids: number[];
}

export interface TopicQuestion {
  id: string;
  type: string;
  attributes: {
    id: number;
    topic_id: number;
    question_no: number;
    question_1: string;
    question_2: string;
    correct_answer: string;
    explanation: string;
    passage: string;
    question_type: string;
    p_mark: number;
    n_mark: string;
    remark: string;
    created_by: string;
    options: Option[] | [];
    opt_count: null | number;
    is_correct: boolean;
    is_unattended: null;
  };
}
// Customizable Area End

export interface Props extends WithStyles<typeof styles> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  endTime: number;
  timeRemaining: number;
  testData: TopicQuestion[];
  currentTestData: number;
  answers: {
    question_id: number;
    option: string[][];
    legend?: string;
    is_marked?: boolean;
  }[];
  meta: Test | null;
  submitModalOpen: boolean;
  reviewModalOpen: boolean;
  errorModalOpen: boolean;
  loading: boolean;
  isBlocking: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PracticeTestTakingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTestMessageId = "";
  searchResolve = this.props.navigation.history.location.search;
  intervalId: NodeJS.Timeout = setTimeout(() => {}, 0);
  submitTestMessageId = "";
  breadcrumb = [
    {
      label: "Practice Test",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}${this.searchResolve}`,
    },
    {
      label: "Chapters",
      link: `/dashboard/practice-test/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}${this.searchResolve}`,
    },
    {
      link:
        this.props.navigation.history.location.pathname + this.searchResolve,
      label: "Take Test",
    },
  ];
  submitOverviewMessageId = "";
  currentTestMeta = "";
  chapterId = "";
  getSubjectsMessageTakingId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.tick = this.tick.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      endTime: 0,
      loading: false,
      timeRemaining: 0,
      testData: [],
      currentTestData: 0,
      answers: localStorage.getItem("answers")
        ? JSON.parse(localStorage.getItem("answers") as string)
        : [],
      meta: null,
      submitModalOpen: false,
      errorModalOpen: false,
      isBlocking: true,
      reviewModalOpen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getTestMessageId && response) {
      this.handleTestResponse(response);
    }
    
    if (apiRequestCallId === this.getSubjectsMessageTakingId && response) {
      if (response.data) {
        this.handleSuccessSubject(response.data);
      }
    }

    if (apiRequestCallId === this.submitTestMessageId && response) {
      this.handleSubmitResponse(response);
    }

    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getSubjects();

    const propPassingTT = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingTT.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingTT);

    const chapterId = this.props.navigation.getParam("chapterId");
    this.chapterId = chapterId;
    const currentTestData = localStorage.getItem("currentPracticeTest");
    const currentTestMeta = localStorage.getItem("currentPracticeMeta");
    this.currentTestMeta = currentTestMeta as string;

    if (
      chapterId === String(JSON.parse(currentTestMeta as string)?.chapter.id)
    ) {
      if (currentTestData && currentTestMeta) {
        this.handleCurrentTest(
          JSON.parse(currentTestData),
          JSON.parse(currentTestMeta)
        );
        const savedEndTime = localStorage.getItem("endTime");
        const totalTestTime = localStorage.getItem("totalTestTime");
        let endTime: number;

        if (savedEndTime && parseInt(savedEndTime, 10) !== 0 && totalTestTime) {
          endTime = parseInt(savedEndTime, 10);
        } else {
          endTime = Date.now() + Number(totalTestTime) * 1000;
          localStorage.setItem("endTime", endTime.toString());
        }

        const timeRemaining = Math.max(endTime - Date.now(), 0);

        this.handleTimerStart(endTime, timeRemaining);
      }
    } else {
      localStorage.removeItem("totalTestTime");
      localStorage.removeItem("endTime");
      localStorage.removeItem("currentPracticeTest");
      localStorage.removeItem("currentPracticeMeta");
      localStorage.removeItem("answers");
      this.getTest(chapterId);
    }
  };

  handleSuccessSubject = (
    response: {
      id: string;
      attributes: { name: string };
    }[]
  ) => {
    const subjectId = this.props.navigation.getParam("subjectId");
    for (const data of response) {
      if (data.id === subjectId) {
        const breadcrumb = this.breadcrumb;
        breadcrumb[1].label = data.attributes.name;

        const propPassingReviewTT = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingReviewTT.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );
        this.send(propPassingReviewTT);

        this.setState({
          loading: false,
        });
      }
    }
  };

  componentWillUnmount = async () => {
    clearInterval(this.intervalId);
  };

  handleTestResponse(response: {
    data: TopicQuestion[];
    meta: Test;
    errors: string;
  }) {
    if (response.errors) {
      this.setState({ errorModalOpen: true });
    }
    if (response.data) {
      this.handleTestData(response.data, response.meta);
    }
    if (response.meta) {
      const endTime = this.calculateEndTime(response.meta.test_time, "endTime");
      const timeRemaining = Math.max(endTime - Date.now(), 0);

      const breadcrumb = this.breadcrumb;
      breadcrumb[2].label = response.meta.chapter.name + " Test";

      const propPassingTT = new Message(
        getName(MessageEnum.NavigationBreadcrumbMessage)
      );
      propPassingTT.addData(
        getName(MessageEnum.BreadcrumbDataMessage),
        breadcrumb
      );

      this.send(propPassingTT);
      this.handleTimerStart(endTime, timeRemaining);
    }
    this.setState({ loading: false });
  }

  handleSubmitResponse(response: { message: string }) {
    if (response.message) {
      this.clearLocalStorage("endTime");
      this.clearLocalStorage("totalTestTime");
      this.clearLocalStorage("currentPracticeTest");
      this.clearLocalStorage("currentPracticeMeta");
      this.clearLocalStorage("answers");
      this.handleSubmitModalClose();
      this.handleReviewModalOpen();
    }
    this.setState({ loading: false, isBlocking: false });
  }

  calculateEndTime(testTime: number, storageKey: string) {
    const savedEndTime = localStorage.getItem(storageKey);
    let endTime;
    if (savedEndTime && parseInt(savedEndTime, 10) !== 0) {
      endTime = parseInt(savedEndTime, 10);
    } else {
      endTime = Date.now() + testTime * 1000;
      localStorage.setItem(storageKey, endTime.toString());
    }
    return endTime;
  }

  clearLocalStorage(keyStorage: string) {
    localStorage.removeItem(keyStorage);
  }

  goBack = () => {
    this.props.navigation.goBack();
  };

  handleSubmitModalClose = () => {
    this.setState({
      submitModalOpen: false,
    });
  };

  handleSubmitModalOpen = () => {
    this.setState({
      submitModalOpen: true,
    });
  };

  handleReviewModalOpen = () => {
    this.setState({
      reviewModalOpen: true,
    });
  };

  handleCurrentTest = (testData: TopicQuestion[], meta: Test) => {
    this.setState(
      {
        testData,
        meta,
      },
      () => {
        const breadcrumb = this.breadcrumb;
        breadcrumb[2].label =
          (this.state.meta?.chapter.name as string) + " Test";

        const propPassingTT = new Message(
          getName(MessageEnum.NavigationBreadcrumbMessage)
        );
        propPassingTT.addData(
          getName(MessageEnum.BreadcrumbDataMessage),
          breadcrumb
        );

        this.send(propPassingTT);
      }
    );
  };

  tick = () => {
    const newTimeRemaining = Math.max(this.state.endTime - Date.now(), 0);
    this.setState({ timeRemaining: newTimeRemaining });

    if (newTimeRemaining <= 0) {
      clearInterval(this.intervalId);
      this.timeReachedZero();
    }
  };

  timeReachedZero = () => {
    localStorage.removeItem("endTime");
    localStorage.removeItem("totalTestTime");
    localStorage.removeItem("currentPracticeTest");
    localStorage.removeItem("currentPracticeMeta");
    localStorage.removeItem("answers");
    this.submitTest();
  };

  handleTimerStart = (endTime: number, timeRemaining: number) => {
    this.setState(
      {
        endTime,
        timeRemaining,
      },
      () => {
        this.intervalId = setInterval(this.tick, 1000);
      }
    );
  };

  handleTestData = (response: TopicQuestion[], meta: Test) => {
    localStorage.setItem("totalTestTime", String(meta.test_time));
    localStorage.setItem("currentPracticeTest", JSON.stringify(response));
    localStorage.setItem("currentPracticeMeta", JSON.stringify(meta));
    localStorage.setItem("answers", JSON.stringify([{ option: [[]] }]));
    this.currentTestMeta = JSON.stringify(meta);
    this.setState({
      testData: response,
      currentTestData: 0,
      meta,
      answers: [
        {
          is_marked: false,
          question_id: response[0].attributes.id,
          option: [[]],
        },
      ],
    });
  };

  handleSpecificQuestion = (index: number) => {
    this.setState({
      currentTestData: index,
    });
  };

  handleNextQuestion = () => {
    if (this.state.currentTestData !== this.state.testData.length - 1) {
      this.setState({
        currentTestData: this.state.currentTestData + 1,
      });
    }
  };

  handlePreviousQuestion = () => {
    if (this.state.currentTestData !== 0) {
      this.setState({
        currentTestData: this.state.currentTestData - 1,
      });
    }
  };

  setAnswer = (index: number, option: string[][]) => {
    const answers = [...this.state.answers];

    answers[index] = {
      ...this.state.answers[index],
      question_id: this.state.testData[index].attributes.id,
      option,
    };

    this.setState({ answers });
  };

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (
      JSON.stringify(prevState.answers) !==
        JSON.stringify(this.state.answers) ||
      JSON.stringify(prevState.currentTestData) !==
        JSON.stringify(this.state.currentTestData)
    ) {
      this.handleAnswersChanged(prevState);
    }

    if (prevState.currentTestData !== this.state.currentTestData) {
      this.submitOverviewPerQuestion(
        this.state.answers[prevState.currentTestData],
        prevState.currentTestData
      );
    }
  }

  handleAnswersChanged = (prevState: S) => {
    let currentAnswers = this.state.answers[this.state.currentTestData];

    const isAnswered = currentAnswers?.option?.[0]?.[0]?.length > 0;
    const isMarked = currentAnswers?.is_marked;

    if (isMarked) {
      currentAnswers = { ...currentAnswers, is_marked: true };
    } else {
      currentAnswers = { ...currentAnswers, is_marked: false };
    }

    if (isAnswered) {
      currentAnswers = { ...currentAnswers, legend: "answered" };
    }

    if (isAnswered && isMarked) {
      currentAnswers = {
        ...currentAnswers,
        legend: "answered_and_marked_for_review",
      };
    }

    if (!isAnswered && isMarked) {
      currentAnswers = {
        ...currentAnswers,
        legend: "not_answered_and_marked_for_review",
      };
    }

    if (
      prevState.answers[this.state.currentTestData]?.option?.[0]?.[0]?.length >
        0 &&
      !currentAnswers?.option?.[0]?.[0]?.length
    ) {
      currentAnswers = {
        ...currentAnswers,
        legend: "answered_and_cleared_the_answer",
      };
    }

    if (
      prevState.answers[this.state.currentTestData]?.is_marked &&
      !currentAnswers?.is_marked
    ) {
      currentAnswers = {
        ...currentAnswers,
        legend: "marked_and_un_marked_for_review",
      };
    }

    const answers = this.state.answers;
    answers[this.state.currentTestData] = currentAnswers;

    localStorage.setItem("answers", JSON.stringify(answers));

    this.setState({
      answers,
    });
  };

  markForReview = () => {
    const newAnswer = [...this.state.answers];

    newAnswer[this.state.currentTestData] = {
      ...newAnswer[this.state.currentTestData],
      is_marked: !newAnswer[this.state.currentTestData].is_marked,
    };

    this.setState({
      answers: newAnswer,
    });
  };

  clearResponse = () => {
    const answers = [...this.state.answers];

    answers[this.state.currentTestData] = {
      ...answers[this.state.currentTestData],
      option: [[]],
    };

    this.setState({
      answers,
    });
  };

  getBtnClassName = (index: number) => {
    const current = this.state.answers[index];

    if (current?.legend === "answered") {
      return "btn-answered";
    }

    if (current?.legend === "un_answered") {
      return "btn-unanswered";
    }

    if (current?.legend === "answered_and_marked_for_review") {
      return "btn-answered-marked";
    }

    if (current?.legend === "not_answered_and_marked_for_review") {
      return "btn-unanswered-marked";
    }

    if (current?.legend === "marked_and_un_marked_for_review") {
      return "btn-marked-unmarked";
    }

    if (current?.legend === "answered_and_cleared_the_answer") {
      return "btn-answered-cleared";
    }

    return "";
  };

  // Customizable Area Start
  getTest = (chapterId: string) => {
    const headers = {
      token: localStorage.getItem("authToken"),
    };

    const getTestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    this.getTestMessageId = getTestMessage.messageId;

    getTestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${chapterId}/take_test`
    );

    getTestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getTestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getTestMessage.id, getTestMessage);
  };

  submitTest = () => {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    };

    this.setState({
      loading: true,
    });

    const submitTestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitTestMessageId = submitTestMessage.messageId;

    const testTime = Number(localStorage.getItem("totalTestTime"));
    const totalSeconds = testTime - this.state.timeRemaining / 1000;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60);

    const questionIds = this.state.meta?.question_ids.join(",");
    const answers: { [questionId: string]: string[] } = {};

    if (this.state.meta) {
      for (
        let indexQuestion = 0;
        indexQuestion < this.state.meta?.question_ids.length;
        indexQuestion++
      ) {
        if (this.state.answers[indexQuestion] === undefined) {
          continue;
        } else {
          if (this.state.answers[indexQuestion].option?.flat().length > 0) {
            answers[this.state.meta?.question_ids[indexQuestion].toString()] =
              this.state.answers[indexQuestion].option.flat();
          }
        }
      }
    }

    submitTestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${
        this.chapterId
      }/submit_test?a_str=${JSON.stringify(answers)}&time_taken=${
        String(hours).length > 1 ? hours : "0" + hours
      }:${String(minutes).length > 1 ? minutes : "0" + minutes}:${
        String(seconds).length > 1 ? seconds : "0" + seconds
      }&question_ids=${questionIds}`
    );

    submitTestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    submitTestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(submitTestMessage.id, submitTestMessage);
  };

  submitOverviewPerQuestion = (
    prevState: {
      question_id: number;
      option: string[][];
      legend?: string;
      is_marked?: boolean;
    },
    prevIndex: number
  ) => {
    let currentAnswers = this.state.answers[prevIndex];
    const isAnswered = currentAnswers?.option?.[0]?.[0]?.length > 0;
    const legend = currentAnswers?.legend;

    if (!isAnswered && legend === undefined) {
      currentAnswers = { ...currentAnswers, legend: "un_answered" };
    }

    const answers = this.state.answers;
    answers[prevIndex] = currentAnswers;

    localStorage.setItem("answers", JSON.stringify(answers));

    this.setState({
      answers,
    });

    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": "application/json",
    };

    const submitOverviewMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitOverviewMessageId = submitOverviewMessage.messageId;

    submitOverviewMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_assessmenttest/practice_tests/${this.chapterId}/overview`
    );

    submitOverviewMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    submitOverviewMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        ...prevState,
        option: prevState.option?.[0].flat(),
      })
    );

    submitOverviewMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(submitOverviewMessage.id, submitOverviewMessage);
  };

  getSubjects = async () => {
    const urlSearch = this.props.navigation.history.location.search;
    const packageId = extractParams(urlSearch, "packageId");
    const getSubjectsMessageTaking: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    this.getSubjectsMessageTakingId = getSubjectsMessageTaking.messageId;

    getSubjectsMessageTaking.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/practice_tests/${packageId}/get_subjects`
    );

    getSubjectsMessageTaking.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectsMessageTaking.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSubjectsMessageTaking.id, getSubjectsMessageTaking);
  };
  // Customizable Area End
}
