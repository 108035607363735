import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { webStyleAYQReceivedAnswers } from "./AYQReceivedAnswers.web";
import storage from "../../../framework/src/StorageProvider.web";
import {
  Daum,
  Daum3,
} from "../../CommunityForum/src/AYQQuestionController.web";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof webStyleAYQReceivedAnswers> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentQuestion: Daum | null;
  comments: string[];
  currentQuestionId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AYQReceivedAnswersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuestionsMessageId = "";
  likeMessageId = "";
  addCommentAnswerMessageId = "";
  likeDislikeMessageId = "";
  answerId = Infinity;
  indexOfLikeDislike: { index: number; status: number } = {
    index: Infinity,
    status: Infinity,
  };
  likeDislikeQuestionStatus: number = Infinity;
  likeDislikeQuestionMessageId = "";
  breadcrumb = [
    {
      label: "Profile",
      link: "/dashboard/ask-your-query/profile",
    },
    {
      label: "Questions Asked",
      link: "/dashboard/ask-your-query/questions-asked",
    },

    {
      label: "Question Answers",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentQuestion: null,
      comments: [],
      currentQuestionId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getQuestionsMessageId) {
      this.successfulFetch(response);
    }

    if (
      apiRequestCallId === this.likeDislikeQuestionMessageId &&
      !response.errors
    ) {
      if (response.message) {
        this.handleLikeDislikeQuestions();
      }
    }

    if (apiRequestCallId === this.addCommentAnswerMessageId) {
      if (response.data) {
        this.handleCommentSuccess(response.data);
      }
    }

    if (apiRequestCallId === this.likeDislikeMessageId && !response.errors) {
      if (response.message) {
        this.handleLikeDislike();
      }
    }

    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const questionId = this.props.navigation.getParam("questionId");
    const propPassingARAC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingARAC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingARAC);
    if (questionId) {
      this.getQuestionAndAnswer(questionId);
    }
  };

  getTimeDifference = (timestampStr: string): string => {
    const timestamp = new Date(timestampStr);
    const time = formatDistanceToNow(timestamp, { addSuffix: true });
    return time;
  };
  successfulFetch = (response: { data: Daum[] }) => {
    if (response.data) {
      const indexQuestion = response.data.findIndex((curr) => {
        return curr.id === this.state.currentQuestionId;
      });

      this.setState({
        currentQuestion: response.data[indexQuestion],
      });
    }
  };

  handleCommentChange = (index: number, value: string) => {
    const comments = this.state.comments;
    comments[index] = value;

    this.setState({
      comments,
    });
  };

  handleKeyDown = (
    keyDown: string,
    index: number,
    value: string,
    answerId: string
  ) => {
    if (keyDown === "Enter") {
      this.submitComment(answerId, value, index);
    }
  };

  goToProfile = (dataId: string) => {
    this.props.navigation.navigate("AskYourQueryProfilePeek", {
      profileId: dataId,
    });
  };
  handleCommentSuccess = (data: Daum3) => {
    const question = this.state.currentQuestion;
    question?.attributes.ayq_answers.data[
      this.answerId
    ].attributes.comments.data.push(data);

    this.setState({
      currentQuestion: question,
    });
  };

  submitComment = async (answerId: string, comment: string, index: number) => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const comments = this.state.comments;
    comments[index] = "";

    this.setState({
      comments,
    });

    this.answerId = index;

    const addCommentAnswerMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCommentAnswerMessageId = addCommentAnswerMessage.messageId;

    addCommentAnswerMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    addCommentAnswerMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    addCommentAnswerMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_answers/${answerId}/comment?comment=${comment}`
    );

    runEngine.sendMessage(addCommentAnswerMessage.id, addCommentAnswerMessage);
  };
  handleLikeDislikeQuestions = () => {
    const question = this.state.currentQuestion;

    if (question) {
      if (this.likeDislikeQuestionStatus === 1) {
        question.attributes.like_count +=
          question.attributes.current_user_liked === 1 ? -1 : 1;
        question.attributes.dislike_count +=
          question.attributes.current_user_liked === -1 ? -1 : 0;
        question.attributes.current_user_liked =
          question.attributes.current_user_liked === 1 ? 0 : 1;
      } else if (this.likeDislikeQuestionStatus === -1) {
        question.attributes.dislike_count +=
          question.attributes.current_user_liked === -1 ? -1 : 1;
        question.attributes.like_count +=
          question.attributes.current_user_liked === 1 ? -1 : 0;
        question.attributes.current_user_liked =
          question.attributes.current_user_liked === -1 ? 0 : -1;
      }
    }

    this.setState({
      currentQuestion: question,
    });
  };

  handleLikeDislike = () => {
    const questions3 = this.state.currentQuestion;
    const answers3 =
      questions3?.attributes.ayq_answers.data[this.indexOfLikeDislike.index];

    if (answers3) {
      if (this.indexOfLikeDislike.status === 1) {
        answers3.attributes.like_count +=
          answers3.attributes.current_user_liked === 1 ? -1 : 1;
        answers3.attributes.dislike_count +=
          answers3.attributes.current_user_liked === -1 ? -1 : 0;
        answers3.attributes.current_user_liked =
          answers3.attributes.current_user_liked === 1 ? 0 : 1;
      } else if (this.indexOfLikeDislike.status === -1) {
        answers3.attributes.dislike_count +=
          answers3.attributes.current_user_liked === -1 ? -1 : 1;
        answers3.attributes.like_count +=
          answers3.attributes.current_user_liked === 1 ? -1 : 0;
        answers3.attributes.current_user_liked =
          answers3.attributes.current_user_liked === -1 ? 0 : -1;
      }
    }

    this.setState({
      currentQuestion: questions3,
    });
  };

  // Customizable Area Start

  likeDislikeQuestion = async (status: number) => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    this.likeDislikeQuestionStatus = status;

    const likeDislikeQuestionMessage3 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeDislikeQuestionMessageId = likeDislikeQuestionMessage3.messageId;

    likeDislikeQuestionMessage3.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    likeDislikeQuestionMessage3.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    likeDislikeQuestionMessage3.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/create_ayq_like?ayq_question_id=${this.state.currentQuestion?.id}&status=${status}`
    );

    runEngine.sendMessage(
      likeDislikeQuestionMessage3.id,
      likeDislikeQuestionMessage3
    );
  };

  likeDislike = async (status: number, index: number) => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    this.indexOfLikeDislike = { index, status };

    const likeDislikeMessage3 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeDislikeMessageId = likeDislikeMessage3.messageId;

    likeDislikeMessage3.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    likeDislikeMessage3.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    likeDislikeMessage3.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/ayq_questions/create_ayq_like?ayq_answer_id=${this.state.currentQuestion?.attributes.ayq_answers.data[index].id}&status=${status}`
    );

    runEngine.sendMessage(likeDislikeMessage3.id, likeDislikeMessage3);
  };
  getQuestionAndAnswer = (questionId: string) => {
    this.setState(
      {
        currentQuestionId: questionId,
      },
      async () => {
        const headers = {
          token: await storage.get("authToken"),
        };

        const getQuestionsMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getQuestionsMessageId = getQuestionsMessage.messageId;

        getQuestionsMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );

        getQuestionsMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );

        getQuestionsMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_communityforum/ayq_questions`
        );

        runEngine.sendMessage(getQuestionsMessage.id, getQuestionsMessage);
      }
    );
  };
  // Customizable Area End
}
