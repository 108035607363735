Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = 'Email'

exports.labelHeader = 'Value proposition: why users should sign up.'
exports.labelFirstName = 'First name'
exports.lastName = 'Last name'
exports.labelEmail = 'Email'
exports.labelPassword = 'Password'
exports.labelRePassword = 'Re-Type Password'
exports.errorCountryCodeNotSelected = 'Please select country code'
exports.errorBlankField = "can't be blank"

exports.labelLegalText =
  'Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.'

exports.labelLegalTermCondition = 'Terms and conditions'
exports.labelLegalPrivacyPolicy = 'Privacy policy'
exports.btnTextSignUp = 'Sign up'
exports.errorPasswordNotValid = 'Password not valid.'

exports.errorTitle = 'Error'

exports.errorBothPasswordsNotSame = 'Passwords should be same.'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'

exports.contentTypeApiAddDetail = 'application/json'

exports.accountsAPiEndPoint = 'account/accounts'

exports.apiMethodTypeAddDetail = 'POST'
exports.validationApiMethodType = 'GET'

exports.errorEmailNotValid = 'Email not valid.'
exports.urlGetValidations = 'profile/validations'
exports.validationApiContentType = 'application/json'
exports.signUpEndPoint = 'account_block/accounts'
exports.sendOTPForSignup ="/send_otp_for_signup"
exports.validRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/
exports.ayqRegisterHeading = "Ask Yor Query Registration";
exports.register = "Register";
exports.guest = "Guest";
exports.price = "₹ 5499 / Year";
exports.price = "₹ 5499 / Year";
exports.contentDemo = "It is a long established fact that a reader will be distracted by \n the readable content.";
exports.Verified = "Verified";
exports.passErrorText = "Please enter valid password";
exports.labelLegalText ='Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.'
exports.paymentGetWayEndpoint = "/bx_block_payments/ccavanues"
exports.getPackagePlanapiMethodType = "GET"
exports.getPackagePlanEndPoint = "/bx_block_plan/plans"
exports.verifyOTPForSignup = "verify_mobile_otp"
exports.sendEmailOTPForSignup = "send_email_otp"
// Customizable Area End
