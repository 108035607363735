export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const EditIcon = require('../assets/edit_423.png');
export const plusIcon = require('../assets/pngwing.com.png');
export const Delete = require('../assets/delete.png');
export const oval = require('../assets/oval.png');
export const image = require('../assets/image.png');
export const button_filled = require('../assets/button_filled.png')
export const searchIcon = require('../assets/searchImg.png')
export const arrow= require("../assets/arrow.png");
export const arrowCircle = require("../assets/arrowCircle.png");
export const rightarrow = require("../assets/rightarrow.png");
export const searchImage = require("../assets/searchImage.png");
