import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Table,
  TableBody,
  Typography,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Banner from "../../../components/src/Banner.web";
import {
  courseFinderGlobalStyle,
  searchInputStyle,
} from "./CourseFinderExamination.web";
import { searchImage, arrowCircle } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import CourseFinderCoursesController, {
  Props,
  configJSON,
} from "./CourseFinderCoursesController.web";

class CourseFinderCourses extends CourseFinderCoursesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { loading, currentPage, coursesData } = this.state;
    const startIndex = currentPage * this.dataPerPage;
    const endIndex = Math.min(
      startIndex + this.dataPerPage,
      coursesData.length
    );

    const currentPageData = coursesData.slice(startIndex, endIndex);
    const pagesArray = Array.from(
      { length: this.state.numberOfPages },
      (_, index) => index + 1
    );

    if (loading) {
      return <Loader loading={loading} />;
    }

    return (
      // Customizable Area Start
      <Box>
        <Banner title="Courses Details" />
        <Box>
          <Box className={classes.searchContainer}>
            <Box position={"relative"}>
              <img
                src={searchImage}
                alt="search-image"
                className={classes.searchImageStyle}
              />
              <input className={classes.searchStyle} />
            </Box>
          </Box>
          <Box>
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{configJSON.coursesN}</TableCell>
                    <TableCell>Degree</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Eligible Courses</TableCell>
                    <TableCell>Remarks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageData.map((courses) => {
                    return (
                      <TableRow>
                        <TableCell data-test-id="course-name">
                          {courses.attributes.course_name}
                        </TableCell>
                        <TableCell>{courses.attributes.degree}</TableCell>
                        <TableCell>{courses.attributes.duration}</TableCell>
                        <TableCell>None</TableCell>
                        <TableCell>{courses.attributes.remarks}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.paginationContainerStyle}>
              <Box className={classes.buttonPaginationStyle}>
                {pagesArray.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => {
                      this.changePage(pageNumber - 1);
                    }}
                    className={
                      this.state.currentPage === pageNumber - 1
                        ? "active-page"
                        : ""
                    }
                  >
                    {pageNumber}
                  </button>
                ))}
                <img
                  src={arrowCircle}
                  alt="arrow"
                  className={classes.arrowCircle}
                  data-test-id="change-page-btn"
                  onClick={() => this.changePage(currentPage + 1)}
                />
                <Typography className={classes.paginationLabel}>
                  1-{this.state.numberOfPages}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleCourseFinderCourses = () =>
  createStyles({
    ...searchInputStyle,
    ...courseFinderGlobalStyle,
  });
export default withStyles(webStyleCourseFinderCourses)(CourseFinderCourses);
export { CourseFinderCourses };
// Customizable Area End
