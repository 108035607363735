import React from "react";

import {
  Box as MUIBox,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  Grid as MUIGrid,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import { Physics, emptyStar, starIcon } from "./assets";
import { boxStyles } from "./BookMySlot.web";
import Loader from "../../../components/src/Loader.web";
import { ISlotDetails } from "./BookMySlotChangeSlotController.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { webStyle } from "./BookMySlotAddEditSlot.web";
// Customizable Area End

import BookMySlotFeedbacksController, {
  Props,
  configJSON,
} from "./BookMySlotFeedbacksController.web";

class BookMySlotFeedbacks extends BookMySlotFeedbacksController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderAppointmentBox = (data: ISlotDetails) => {
    return (
      <MUIGrid item key={`notifs-${data.id}`} sm={12}>
        <MUIBox className={this.props.classes.appointmentBox}>
          <MUIBox className="details-appointment">
            <img src={Physics} alt={"physics-image"} />
            <MUIBox>
              <MUIBox className="text-appointment-headers">
                <Typography>{configJSON.StudentName}</Typography>
                <Typography>Parent Name</Typography>
                <Typography>Group</Typography>
              </MUIBox>
              <MUIBox className="text-appointment-description-dots">
                <Typography>:</Typography>
                <Typography>:</Typography>
                <Typography>:</Typography>
              </MUIBox>
              <MUIBox className="text-appointment-description">
                <Typography>{data.attributes.student_name}</Typography>
                <Typography>{data.attributes.parent_name}</Typography>
                <Typography>{data.attributes.group}</Typography>
              </MUIBox>
            </MUIBox>
          </MUIBox>
          <MUIBox className="buttons-appointment">
            <Button className="status">
              Status:{" "}
              {
                this.status.filter(
                  (status) => status.value === data.attributes.status
                )[0].label
              }
            </Button>
          </MUIBox>
        </MUIBox>
      </MUIGrid>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MUIBox>
        <Modal
          open={this.state.isSlotUpdatedModal}
          onClose={this.handleIsSlotUpdatedModal}
          data-test-id="modal-slot-booked"
        >
          <MUIBox sx={webStyle.modalStyle}>
            <Typography>Feedback Submitted Successfully</Typography>
            <Button
              style={webStyle.buttonStyle}
              onClick={this.navigateToBookings}
              data-test-id="homebtn"
            >
              Go to Home
            </Button>
          </MUIBox>
        </Modal>
        <Modal
          open={this.state.isErrorModal}
          onClose={this.showErrorModal}
          data-test-id="modal-slot-booked"
        >
          <MUIBox sx={webStyle.modalStyle}>
            <Typography>Feedback was already given</Typography>
            <Button
              style={webStyle.buttonStyle}
              onClick={this.showErrorModal}
              data-test-id="homebtn"
            >
              Go Back
            </Button>
          </MUIBox>
        </Modal>
        <BannerWeb title="Feedback" />
        <Loader loading={this.state.loading} />
        <MUIBox
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <MUIBox
            display="flex"
            flexDirection={"column"}
            width={"70%"}
            marginTop={"5em"}
          >
            <MUIBox alignSelf={"center"} width={"70%"} marginBottom={"3em"}>
              {this.state.slotDetails &&
                this.RenderAppointmentBox(this.state.slotDetails)}
            </MUIBox>
            <MUIBox>
              <MUIBox
                display="flex"
                flexDirection={"column"}
                justifyContent="center"
                alignItems={"center"}
                width={"100%"}
                marginBottom={"3em"}
              >
                <Typography className={this.props.classes.typographyStyle2}>
                  Please Rate and Review your session
                </Typography>
                <MUIBox>
                  {Array.from(Array(5).keys()).map((_, index) => {
                    return (
                      <>
                        {this.state.starsIndex >= index ? (
                          <img
                            width={"40px"}
                            src={starIcon}
                            alt="filled"
                            onClick={() =>
                              this.state.isEditable
                                ? this.handleRatedStars(index)
                                : ""
                            }
                            style={{ cursor: "pointer" }}
                            data-test-id="rate-btn-star"
                          />
                        ) : (
                          <img
                            width={"40px"}
                            src={emptyStar}
                            alt="empty"
                            onClick={() =>
                              this.state.isEditable
                                ? this.handleRatedStars(index)
                                : ""
                            }
                            style={{ cursor: "pointer" }}
                            data-test-id="rate-btn-star"
                          />
                        )}
                      </>
                    );
                  })}
                </MUIBox>
              </MUIBox>
              <MUIBox marginBottom={"3em"}>
                <Typography className={this.props.classes.typographyStyle}>
                  Update Call Status
                </Typography>
                <MUIBox position={"relative"}>
                  <select
                    value={this.state.selectedChoice}
                    onChange={(event) =>
                      this.onChangeSelect(event.target.value)
                    }
                    data-test-id="select-btn"
                    disabled={!this.state.isEditable}
                    className={this.props.classes.selectStyle}
                  >
                    <option value="follow_up">Follow Up</option>
                    <option value="registered">Registration Done</option>
                    <option value="rejected">Rejected</option>
                  </select>
                  <ExpandMoreIcon
                    className={this.props.classes.expandMoreIcon}
                  />
                </MUIBox>
              </MUIBox>
              <MUIBox>
                <Typography className={this.props.classes.typographyStyle}>
                  Remarks
                </Typography>
                <textarea
                  className={this.props.classes.textAreaStyle}
                  onChange={(event) => this.setComments(event.target.value)}
                  placeholder="How's the experience?"
                  value={this.state.comments}
                  disabled={!this.state.isEditable}
                  data-test-id="textarea"
                />
              </MUIBox>
              <MUIBox
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Button
                  className={this.props.classes.submitButtonStyle}
                  onClick={this.postUpdate}
                  disabled={!this.state.isEditable}
                  data-test-id="submit"
                >
                  Update
                </Button>
              </MUIBox>
            </MUIBox>
          </MUIBox>
        </MUIBox>
      </MUIBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleBookMySlotFeedbacks = () =>
  createStyles({
    appointmentBox: { ...boxStyles },
    selectStyle: {
      width: "100%",
      fontSize: "1.4em",
      outline: 0,
      border: "1px solid #FF4D4F",
      borderRadius: "0.7em",
      height: "70px",
      color: "#5C0F89",
      fontWeight: 500,
      paddingLeft: "1.5em",
      WebkitAppearance: "none",
      marginTop: "1.3em",
    },
    expandMoreIcon: {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      color: "white",
      borderRadius: "50%",
      right: "30px",
      top: "47px",
      cursor: "pointer",
      position: "absolute",
    },
    typographyStyle: {
      fontSize: "1.3em",
      fontWeight: 500,
    },
    textAreaStyle: {
      width: "100%",
      height: "200px",
      border: "1px solid #FF4D4F",
      borderRadius: "0.7em",
      resize: "none",
      padding: "1.5em",
      fontSize: "1.2em",
      outline: 0,
      marginTop: "1.3em",
    },
    submitButtonStyle: {
      width: "400px",
      color: "white",
      borderRadius: "0.5em",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      height: "60px",
      margin: "2em 0 3em 0",
      alignSelf: "center",
    },
    typographyStyle2: {
      fontSize: "1.7em",
      fontWeight: 500,
    },
  });
export default withStyles(webStyleBookMySlotFeedbacks)(BookMySlotFeedbacks);
export { BookMySlotFeedbacks };
// Customizable Area End
