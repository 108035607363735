import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { AppointmentT } from "./BookMySlotStudentAppointmentsController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  slotId: string;
  slotDetails: AppointmentT | null;
  starsIndex: number;
  comments: string;
  isSlotUpdatedModal: boolean;
  isErrorModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotFacultyFeedbackController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSlotOfIdMessageId = "";
  postUpdateMessageId = "";
  breadcrumb = [
    {
      link: `/dashboard/book-my-slot-faculty`,
      label: "Book my Slot Faculty",
    },
    {
      link: `/dashboard/book-my-slot-faculty`,
      label: "Bookings",
    },
    {
      link:
        this.props.navigation.history.location.pathname +
        this.props.navigation.history.location.search,
      label: "Feedback",
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      slotId: "",
      slotDetails: null,
      starsIndex: -1,
      comments: "",
      isSlotUpdatedModal: false,
      isErrorModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSlotOfIdMessageId) {
      if (response?.data) {
        this.successFetch(response.data);
      }
    }

    if (apiRequestCallId === this.postUpdateMessageId) {
      if (response?.id) {
        this.successPostMessage();
      }

      if (response?.errors) {
        this.showErrorModal();
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingFacultyFeedback = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingFacultyFeedback.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingFacultyFeedback);

    const search = this.props.navigation.history.location.search;
    const params = new URLSearchParams(search);
    if (search) {
      const slotId = params.get("slotId") as string;

      return this.getSlotOfId(slotId);
    }

    this.props.navigation.history.goBack();
  };

  setComments = (comments: string) => {
    this.setState({
      comments,
    });
  };

  showErrorModal = () => {
    this.setState({
      isErrorModal: !this.state.isErrorModal,
      loading: false,
    });
  };

  handleRatedStars = (index: number) => {
    this.setState((prev) => ({
      ...prev,
      starsIndex: index,
    }));
  };

  successFetch = (slotDetails: AppointmentT[]) => {
    const slotDetail = slotDetails.filter(
      (slot) => slot.id === this.state.slotId
    )[0];
    this.setState({
      slotDetails: slotDetail,
      comments: slotDetail.attributes.fac_feedback as string,
      loading: false,
      starsIndex: (slotDetail.attributes.student_rating as number) - 1 || 0,
    });
  };

  // Customizable Area Start
  successPostMessage = () => {
    this.handleIsSlotUpdatedModal();
  };

  handleIsSlotUpdatedModal = () => {
    this.setState({
      isSlotUpdatedModal: !this.state.isSlotUpdatedModal,
      loading: false,
    });
  };

  getSlotOfId = async (slotId: string) => {
    const getSlotOfIdMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
      slotId,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getSlotOfIdMessageId = getSlotOfIdMessage.messageId;

    getSlotOfIdMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/faculty_bms/previous_appoints`
    );

    getSlotOfIdMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSlotOfIdMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSlotOfIdMessage.id, getSlotOfIdMessage);
  };

  postUpdate = async () => {
    const postUpdateMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const body = {
      data: {
        rating: this.state.starsIndex + 1,
        remarks: this.state.comments,
      },
    };

    this.postUpdateMessageId = postUpdateMessage.messageId;

    postUpdateMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/faculty_bms/${this.state.slotId}/feedback`
    );

    postUpdateMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    postUpdateMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    postUpdateMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(postUpdateMessage.id, postUpdateMessage);
  };

  navigateToBookingsFaculty = () => {
    this.props.navigation.history.push("/dashboard/book-my-slot-faculty");
  };
  // Customizable Area End
}
