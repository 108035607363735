import React from "react";

import {
  Box,
  // Customizable Area Start
  createStyles,
  withStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import Button from "../../../components/src/Button.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import SynopsisSubjectsController, {
  Props,
} from "./SynopsisSubjectsController.web";

class SynopsisSubjects extends SynopsisSubjectsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Loader loading={this.state.loading} />
        <Typography variant="h1" style={{ fontSize: "1.7em", fontWeight: 700 }}>
          Synopsis
        </Typography>
        <Box padding={"5em"}>
          <Typography
            variant="h3"
            style={{ fontSize: "1.2em", fontWeight: 600, marginBottom: "2em" }}
          >
            Select subject
          </Typography>
          <Box className={this.props.classes.subjectContainerStyle}>
            {this.state.subjects.map((subject) => {
              return (
                <Box key={subject.id} className={this.props.classes.cardStyle}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <img
                        src={subject.attributes.image}
                        alt={`subject-${subject.id}`}
                        className={this.props.classes.imageStyle}
                      />
                      <p
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        {subject.attributes.name}
                      </p>
                    </Box>
                    <p style={{ fontSize: "16px", color: "#999" }}>
                      Topics - {subject.attributes.total_topics}
                    </p>
                  </Box>
                  <NavLink
                    to={`/dashboard/synopsis/chapters/${subject.id}`}
                    className={this.props.classes.viewBtnStyle}
                  >
                    <Button
                      color="white"
                      height="35px"
                      width="121px"
                      title="View"
                    />
                  </NavLink>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleSynopsisSubject = () =>
  createStyles({
    cardStyle: {
      width: "400px",
      minHeight: "130px",
      height: "max-content",
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "center",
      border: "1px solid rgba(255, 63, 89, 1)",
      borderRadius: "0.5em",
      padding: "0.7em",
      gap: "1em",
      transition: "all 0.3s ease",
      "&:hover": {
        background: "#FDEFFF",
      },
    },
    imageStyle: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      marginRight: "1em",
    },
    subjectContainerStyle: {
      display: "flex",
      width: "100%",
      height: "100%",
      gap: "1em",
      flexWrap: "wrap" as const,
    },
    viewBtnStyle: {
      marginLeft: "4.5em",
      width: "max-content",
    },
  });

export default withStyles(webStyleSynopsisSubject)(SynopsisSubjects);
export { SynopsisSubjects };
// Customizable Area End
