import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { object as yupObject, string as yupString } from "yup";
import { ValueType } from "react-select";
import storage from "../../../framework/src/StorageProvider.web";
import { ISlotDetails } from "./BookMySlotChangeSlotController.web";

type UserInputT = {
  studentName: string;
  parentName: string;
  mobileNumber: string;
  emailId: string;
};

type CounsellingManagerT = {
  id: string;
  type: string;
  attributes: {
    name: string;
    first_name: string;
    last_name: string;
    phone_number: number;
    email: string;
    city: string;
    status: string;
    myrank_id: string;
    image: string;
    user_type: string;
    uuid: string;
    course_notification_enable: boolean;
    exam_notification_enable: boolean;
    subject_dealing: string;
    package: Array<unknown>;
  };
};

type CounsellingOfficerT = {
  id: number;
  name: string;
  status: string;
  created_at: string;
  updated_at: string;
};

type GroupsT = {
  id: string;
  type: string;
  attributes: {
    id: number;
    guidance_name: string;
    remarks: string;
    guidance_id: number;
    status: string;
    created_by: string;
    created_at: string;
  };
};

type HandleReactSelectT = <T>(
  event: ValueType<{ value: T; label: string }, false>,
  typeOf: TypeofInput
) => void;

interface IReactSelect<T> {
  value: T;
  label: string;
}

type TypeofInput =
  | "counsellingOfficer"
  | "selectStudentGroup"
  | "counsellingManager"
  | "selectStudentStatus";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  title: string;
  isInitialPageLoading: boolean;
  isLoading: boolean;
  counsellingOfficers: IReactSelect<CounsellingOfficerT>[];
  counsellingManagers: IReactSelect<CounsellingManagerT>[];
  groups: IReactSelect<GroupsT>[];
  userInput: {
    counsellingOfficer?: IReactSelect<CounsellingOfficerT>;
    counsellingManager?: IReactSelect<CounsellingManagerT>;
    selectStudentGroup?: IReactSelect<GroupsT>;
    selectStudentStatus?: IReactSelect<string>;
  };
  isUpdatedModal: boolean;
  date: string;
  time: string;
  studentName: string;
  parentName: string;
  mobileNumber: string;
  emailId: string;
  slotId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookMySlotAddEditSlotController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\(\d{2,3}\\)[ \\-]*)|(\d{2,4})[ \\-]*)*?\d{3,4}?[ \\-]*\d{3,4}?$/;
  addSlotSchema = yupObject({
    studentName: yupString()
      .min(5, "Name must be more than 5")
      .max(30, "Name must be less than 30")
      .required("Please input your student name"),
    parentName: yupString().required("Please input your parent name"),
    mobileNumber: yupString()
      .matches(this.phoneRegExp, "Input must be a valid number")
      .min(10, "Input must be a valid number")
      .max(15, "Input must be a valid number")
      .required("Please enter a mobile number in this field"),
    emailId: yupString()
      .email("Please enter a valid email address")
      .required("Please enter your email address"),
  });
  getCounsellingOfficersMessageId = "";
  getCounsellingManagersMessageId = "";
  getGroupsMessageId = "";
  postBookMessageId = "";
  updateMessageId = "";
  fetchSlotIdMessageId = "";
  breadcrumb = [
    {
      label: "Book my Slot",
      link: `/dashboard/book-my-slot`,
    },
    {
      label: "Slots",
      link: `/dashboard/book-my-slot`,
    },
    {
      link: `/dashboard/book-my-slot/appointments`,
      label: "Appointments",
    },
    {
      label: "Add/Edit Slot",
      link:
        this.props.navigation.history.location.pathname +
        this.props.navigation.history.location.search,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isInitialPageLoading: true,
      isLoading: false,
      title: "",
      counsellingOfficers: [],
      counsellingManagers: [],
      groups: [],
      userInput: {
        counsellingOfficer: undefined,
        counsellingManager: undefined,
        selectStudentGroup: undefined,
        selectStudentStatus: undefined,
      },
      isUpdatedModal: false,
      date: "",
      time: "",
      studentName: "",
      parentName: "",
      mobileNumber: "",
      emailId: "",
      slotId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getCounsellingManagersMessageId && response) {
      if (response?.data) {
        this.handleCounsellingManagers(response.data);
      }
    }

    if (apiRequestCallId === this.getCounsellingOfficersMessageId && response) {
      if (response?.length > 0) {
        this.handleCounsellingOfficers(response);
      }
    }

    if (apiRequestCallId === this.getGroupsMessageId && response) {
      if (response?.data) {
        this.handleGroups(response.data);
      }
    }

    if (
      apiRequestCallId === this.postBookMessageId ||
      (apiRequestCallId === this.updateMessageId && response)
    ) {
      if (response?.data) {
        storage.remove("userInputProps");
        storage.remove("userInputSelect");
        storage.remove("dateTimeSlot");
        this.handleIsUpdatedModal();
      }
    }

    if (apiRequestCallId === this.fetchSlotIdMessageId) {
      if (response?.data) {
        this.successFetchSlot(response.data);
      }
    }

    this.setState({
      isLoading: false,
    });
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const propPassingAddEdit = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingAddEdit.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingAddEdit);

    const editSlotSearchParams = this.props.navigation.history.location.search;
    const params = new URLSearchParams(editSlotSearchParams);
    const slotId = params.get("slotId");
    const isEditSlot = Boolean(params.get("edit"));

    const getDateTimeSlot = JSON.parse(await storage.get("dateTimeSlot"));

    if (getDateTimeSlot) {
      this.setDateTimeSlot(getDateTimeSlot);
    }

    await this.getCounsellingManagers();
    await this.getCounsellingOfficers();
    await this.getGroups();

    if (isEditSlot) {
      return this.handlePageToEditSlot(slotId as string);
    }

    const getInitialValues: null | {
      studentName: string;
      parentName: string;
      mobileNumber: string;
      emailId: string;
    } = JSON.parse(await storage.get("userInputProps"));

    const getUserSelects: null | {
      counsellingOfficer?: IReactSelect<CounsellingOfficerT>;
      counsellingManager?: IReactSelect<CounsellingManagerT>;
      selectStudentGroup?: IReactSelect<GroupsT>;
      selectStudentStatus?: IReactSelect<string>;
    } = JSON.parse(await storage.get("userInputSelect"));

    if (getInitialValues) {
      this.successValueFetch(getInitialValues);
    }

    if (getUserSelects) {
      this.successSelectsFetch(getUserSelects);
    }

    return this.handlePageToNewSlot();
  };

  successFetchSlot = async (response: ISlotDetails) => {
    let status = "";

    switch (response.attributes.status) {
      case "follow_up":
        status = "Follow up";
        break;
      case "registration_done":
        status = "Registration Done";
        break;
      case "first_presentation":
        status = "First Presentation";
        break;
      case "rejected":
        status = "Rejected";
        break;
      default:
        break;
    }

    const counsellingManager = this.state.counsellingManagers.filter(
      (data) => data.value.id === response.attributes.cm_id
    )[0];

    const counsellingOfficer = this.state.counsellingOfficers.filter(
      (data) => String(data.value.id) === response.attributes.cm_officer_id
    )[0];

    const selectStudentGroup = this.state.groups.filter(
      (data) =>
        data.value.attributes.guidance_name === response.attributes.group
    )[0];

    this.setState(
      {
        emailId: response.attributes.email,
        studentName: response.attributes.student_name,
        parentName: response.attributes.parent_name,
        mobileNumber: response.attributes.mobile_number,
        time: response.attributes.time_slot,
        date: response.attributes.slot_date,
        userInput: {
          counsellingManager,
          counsellingOfficer,
          selectStudentGroup,
          selectStudentStatus: {
            value: response.attributes.status,
            label: status,
          },
        },
      },
      async () => {
        const getDateTimeSlot = JSON.parse(await storage.get("dateTimeSlot"));

        if (getDateTimeSlot) {
          this.setDateTimeSlot(getDateTimeSlot);
        }
      }
    );
  };

  // Customizable Area Start
  successValueFetch = (props: {
    studentName: string;
    parentName: string;
    mobileNumber: string;
    emailId: string;
  }) => {
    this.setState({
      ...props,
    });
  };

  successSelectsFetch = (props: {
    counsellingOfficer?: IReactSelect<CounsellingOfficerT>;
    counsellingManager?: IReactSelect<CounsellingManagerT>;
    selectStudentGroup?: IReactSelect<GroupsT>;
    selectStudentStatus?: IReactSelect<string>;
  }) => {
    this.setState({
      userInput: { ...props },
    });
  };

  setDateTimeSlot = (dateTimeSlot: { date: string; time: string }) => {
    this.setState({
      ...dateTimeSlot,
    });
  };

  handlePageToEditSlot = (slotId: string) => {
    this.fetchSlotId(slotId);

    this.setState({
      title: "Edit Slot",
      isInitialPageLoading: false,
      slotId,
    });
  };

  handlePageToNewSlot = () => {
    this.setState({
      title: "New Slot",
      isInitialPageLoading: false,
    });
  };

  handleRegisterSlot = (userInput: UserInputT) => {
    const {
      counsellingManager,
      counsellingOfficer,
      selectStudentGroup,
      selectStudentStatus,
    } = this.state.userInput;

    if (
      !counsellingManager ||
      !counsellingOfficer ||
      !selectStudentGroup ||
      !selectStudentStatus
    ) {
      return;
    }

    let formdata = new FormData();
    formdata.append("data[student_name]", userInput.studentName);
    formdata.append("data[parent_name]", userInput.parentName);
    formdata.append("data[mobile_number]", userInput.mobileNumber);
    formdata.append("data[email]", userInput.emailId);
    formdata.append("data[slot_date]", this.state.date);
    formdata.append("data[time_slot]", this.state.time);
    formdata.append("data[status]", selectStudentStatus.value);
    formdata.append("data[cm_id]", counsellingManager.value.id);
    formdata.append("data[cm_officer_id]", String(counsellingOfficer.value.id));
    formdata.append("data[group]", selectStudentGroup.label);

    if (this.state.slotId) {
      return this.updateSlot(formdata);
    }
    this.postBookSlot(formdata);
  };

  navigateToCalendar = (props: {
    studentName: string;
    parentName: string;
    mobileNumber: string;
    emailId: string;
  }) => {
    storage.set("userInputProps", JSON.stringify(props));
    storage.set("userInputSelect", JSON.stringify(this.state.userInput));

    this.props.navigation.history.push(
      `/dashboard/book-my-slot/date-time-slot?slotId=${this.state.slotId}`
    );
  };

  handleIsUpdatedModal = () => {
    this.setState({
      isUpdatedModal: !this.state.isUpdatedModal,
    });
  };

  handleUserInputReactSelect: HandleReactSelectT = (event, typeOfSelect) => {
    this.setState({
      userInput: {
        ...this.state.userInput,
        [typeOfSelect]: event,
      },
    });
  };

  getCounsellingOfficers = async () => {
    this.setState({
      isLoading: true,
    });
    const getCounsellingOfficersMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getCounsellingOfficersMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/cms_bookeds/councelling_officers`
    );
    this.getCounsellingOfficersMessageId =
      getCounsellingOfficersMessage.messageId;
    getCounsellingOfficersMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getCounsellingOfficersMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(
      getCounsellingOfficersMessage.id,
      getCounsellingOfficersMessage
    );
  };

  getCounsellingManagers = async () => {
    this.setState({
      isLoading: true,
    });
    const getCounsellingManagersMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getCounsellingManagersMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/counselling_managers`
    );
    this.getCounsellingManagersMessageId =
      getCounsellingManagersMessage.messageId;
    getCounsellingManagersMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getCounsellingManagersMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(
      getCounsellingManagersMessage.id,
      getCounsellingManagersMessage
    );
  };

  getGroups = async () => {
    this.setState({
      isLoading: true,
    });
    const getGroupsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };
    getGroupsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tutorials2/groups`
    );
    this.getGroupsMessageId = getGroupsMessage.messageId;
    getGroupsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getGroupsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getGroupsMessage.id, getGroupsMessage);
  };

  postBookSlot = async (body: FormData) => {
    this.setState({
      isLoading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": undefined,
    };
    const postBookMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    postBookMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/cms_appoinments`
    );
    this.postBookMessageId = postBookMessage.messageId;
    postBookMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    postBookMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    postBookMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(postBookMessage.id, postBookMessage);
  };

  updateSlot = async (body: FormData) => {
    this.setState({
      isLoading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": undefined,
    };
    const updateMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    updateMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/cms_appoinments/${this.state.slotId}`
    );
    this.updateMessageId = updateMessage.messageId;
    updateMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    updateMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(updateMessage.id, updateMessage);
  };

  handleCounsellingManagers = (counsellingManagers: CounsellingManagerT[]) => {
    const reactSelectCompatibleTypeForCounsellingManagers = [];

    for (const manager of counsellingManagers) {
      const compatibleTypeOfOfficerToArray = {
        value: manager,
        label: manager.attributes.name,
      };

      reactSelectCompatibleTypeForCounsellingManagers.push(
        compatibleTypeOfOfficerToArray
      );
    }

    this.setState({
      counsellingManagers: reactSelectCompatibleTypeForCounsellingManagers,
    });
  };

  handleCounsellingOfficers = (counsellingOfficers: CounsellingOfficerT[]) => {
    const reactSelectCompatibleTypeForCounsellingOfficers = [];

    for (const officer of counsellingOfficers) {
      const compatibleTypeOfOfficerToArray = {
        value: officer,
        label: officer.name,
      };

      reactSelectCompatibleTypeForCounsellingOfficers.push(
        compatibleTypeOfOfficerToArray
      );
    }

    this.setState({
      counsellingOfficers: reactSelectCompatibleTypeForCounsellingOfficers,
    });
  };

  handleGroups = (groups: GroupsT[]) => {
    const reactSelectCompatibleTypeForGroups = [];

    for (const group of groups) {
      const compatibleTypeOfOfficerToArray = {
        value: group,
        label: group.attributes.guidance_name,
      };

      reactSelectCompatibleTypeForGroups.push(compatibleTypeOfOfficerToArray);
    }

    this.setState({
      groups: reactSelectCompatibleTypeForGroups,
    });
  };

  navigateToSlotBookings = () => {
    this.props.navigation.history.push("/dashboard/book-my-slot/slot-bookings");
  };

  fetchSlotId = async (slotId: string) => {
    const fetchSlotIdMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      isLoading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.fetchSlotIdMessageId = fetchSlotIdMessage.messageId;

    fetchSlotIdMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/cms_appoinments/${slotId}`
    );

    fetchSlotIdMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    fetchSlotIdMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(fetchSlotIdMessage.id, fetchSlotIdMessage);
  };
  // Customizable Area End
}
