import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Banner from "../../../components/src/Banner.web";
import {
  courseFinderGlobalStyle,
  searchInputStyle,
} from "./CourseFinderExamination.web";
import { searchImage, arrowCircle } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import CourseFinderCollegesController, {
  Props,
  configJSON,
} from "./CourseFinderCollegesController.web";

class CourseFinderColleges extends CourseFinderCollegesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { loading, currentPage, collegesData } = this.state;
    const startIndex = currentPage * this.dataPerPage;
    const endIndex = Math.min(
      startIndex + this.dataPerPage,
      collegesData.length
    );

    const currentPageData = collegesData.slice(startIndex, endIndex);

    const pagesArray = Array.from(
      { length: this.state.numberOfPages },
      (_, index) => index + 1
    );

    if (loading) {
      return <Loader loading={loading} />;
    }
    return (
      // Customizable Area Start
      <Box>
        <Banner title="Colleges Details" />
        <Box>
          <Box className={classes.searchContainer}>
            <Box position={"relative"}>
              <img
                src={searchImage}
                className={classes.searchImageStyle}
                alt="search-image"
              />
              <input className={classes.searchStyle} />
            </Box>
          </Box>
          <Box>
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{configJSON.collegeN}</TableCell>
                    <TableCell>State Name</TableCell>
                    <TableCell>Established</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Contacts</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Website</TableCell>
                    <TableCell>Courses available</TableCell>
                    <TableCell>Link</TableCell>
                    <TableCell>Region</TableCell>
                    <TableCell>College Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageData.map((collegeData) => {
                    return (
                      <TableRow>
                        <TableCell data-test-id="college-name">
                          {collegeData.attributes.college_name}
                        </TableCell>
                        <TableCell>
                          {collegeData.attributes.state_name}
                        </TableCell>
                        <TableCell>
                          {collegeData.attributes.established}
                        </TableCell>
                        <TableCell>{collegeData.attributes.address}</TableCell>
                        <TableCell>
                          {collegeData.attributes.contact1},
                          {collegeData.attributes.contact2}
                        </TableCell>
                        <TableCell>{collegeData.attributes.email}</TableCell>
                        <TableCell>
                          <a
                            href={collegeData.attributes.website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {collegeData.attributes.website}
                          </a>
                        </TableCell>
                        <TableCell>
                          {collegeData.attributes.courses.data.map(
                            (courses) => courses.attributes.course_name
                          )}
                        </TableCell>
                        <TableCell>
                          {collegeData.attributes.link_myrank}
                        </TableCell>
                        <TableCell>{collegeData.attributes.region}</TableCell>
                        <TableCell>
                          {collegeData.attributes.college_type},
                          {collegeData.attributes.college_type2}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.paginationContainerStyle}>
              <Box className={classes.buttonPaginationStyle}>
                {pagesArray.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => this.changePage(pageNumber - 1)}
                    className={
                      this.state.currentPage === pageNumber - 1
                        ? "active-page"
                        : ""
                    }
                  >
                    {pageNumber}
                  </button>
                ))}
                <img
                  src={arrowCircle}
                  data-test-id="change-page-btn"
                  onClick={() => this.changePage(currentPage + 1)}
                  alt="arrow"
                  className={classes.arrowCircle}
                />
                <Typography className={classes.paginationLabel}>
                  1-{this.state.numberOfPages}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleCourseFinderColleges = () =>
  createStyles({
    ...searchInputStyle,
    ...courseFinderGlobalStyle,
  });
export default withStyles(webStyleCourseFinderColleges)(CourseFinderColleges);
export { CourseFinderColleges };
// Customizable Area End
