import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { WeeklyTest } from "./ReviewTestWeeklyMainController.web";
interface Root {
  id: number;
  notes_content: string;
  noteable_id: number;
  noteable_type: string;
  question_id: number;
  question_no: number;
  created_at: string;
  updated_at: string;
  serial_number: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  notes: Root[];
  isDeletePromptOpen: boolean;
  isConfirmDeletePromptOpen: boolean;
  reviewId: string;
  loading: boolean;
  examName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WeeklyTestSavedNotesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSavedNotesMessageId = "";
  getReviewHistoriesWeeklyMessageId = "";
  breadcrumb = [
    {
      label: "Weekly Test",
      link: "/dashboard/weekly-test/instructions",
    },
    {
      link: "/dashboard/weekly-test/instructions",
      label: "Instructions",
    },
    {
      link: "/dashboard/weekly-test/tests",
      label: "Tests",
    },
    {
      link: "/dashboard/weekly-test/tests",
      label: "Subject",
    },
    {
      label: "Reviews",
      link: `/dashboard/weekly-test/tests/review-test/${this.props.navigation.getParam(
        "testId"
      )}`,
    },
    {
      link: `/dashboard/weekly-test/tests/review-test/${this.props.navigation.getParam(
        "testId"
      )}`,
      label: "Reviews",
    },
    {
      label: "Review Test",
      link: `/dashboard/weekly-test/tests/review-test/${this.props.navigation.getParam(
        "testId"
      )}/review/${this.props.navigation.getParam("reviewId")}`,
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Saved Notes",
    },
  ];
  token = "";
  indexToDelete = Infinity;
  noteIdToDelete = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isDeletePromptOpen: false,
      isConfirmDeletePromptOpen: false,
      notes: [],
      reviewId: "",
      loading: false,
      examName: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSavedNotesMessageId) {
      if (responseJson?.length > 0) {
        this.successfulFetchNotes(responseJson);
      }
    }

    if (apiRequestCallId === this.getReviewHistoriesWeeklyMessageId) {
      if (responseJson?.weekly_test) {
        this.handleSuccessFetchResponse(responseJson.weekly_test);
      }
    }
    // Customizable Area End
  }

  // web events
  handleDeletePromptClose = () => {
    this.setState({
      isDeletePromptOpen: false,
    });
  };

  componentDidMount = async () => {
    const propPassingWTSNC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingWTSNC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingWTSNC);
    const token = await storage.get("authToken");
    if (token) {
      this.token = token;
    }

    const reviewId = this.props.navigation.getParam("reviewId");
    this.getSavedNotes(reviewId);
    this.setState({
      reviewId,
    });

    this.getReviewHistoriesWeekly();
  };

  successfulFetchNotes = (response: Root[]) => {
    this.setState({
      notes: response,
    });
  };

  deletePromptOpen = (noteId: string, index: number) => {
    this.noteIdToDelete = noteId;
    this.indexToDelete = index;
    this.setState({
      isDeletePromptOpen: true,
    });
  };

  deleteSavedNotes = (noteId: string, index: number) => {
    let notes = this.state.notes;
    notes = notes.filter((_, noteIndex) => noteIndex !== index);
    this.deleteSavedNotesApi(noteId);
    this.setState({
      notes,
      isDeletePromptOpen: false,
      isConfirmDeletePromptOpen: true,
    });
  };
  handleDeletePromptClose2 = () => {
    this.setState({
      isConfirmDeletePromptOpen: false,
    });
  };

  // Customizable Area Start
  deleteSavedNotesApi = (noteId: string) => {
    const deleteSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: this.token,
    };

    deleteSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/notes/${noteId}`
    );
    deleteSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    deleteSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(deleteSavedNotesMessage.id, deleteSavedNotesMessage);
  };
  getSavedNotes = (reviewId: string) => {
    const getSavedNotesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: this.token,
    };
    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/notes?weekly_test_review_history_id=${reviewId}`
    );
    this.getSavedNotesMessageId = getSavedNotesMessage.messageId;
    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSavedNotesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getSavedNotesMessage.id, getSavedNotesMessage);
  };

  getReviewHistoriesWeekly = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getHistoryMessageSNC = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReviewHistoriesWeeklyMessageId = getHistoryMessageSNC.messageId;

    getHistoryMessageSNC.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getHistoryMessageSNC.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/weekly_tests/${this.props.navigation.getParam(
        "testId"
      )}/review_histories`
    );

    getHistoryMessageSNC.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getHistoryMessageSNC.id, getHistoryMessageSNC);
  };

  handleSuccessFetchResponse = (response: WeeklyTest) => {
    const breadcrumb = this.breadcrumb;
    breadcrumb[3].label = response.test_subject;
    breadcrumb[4].label = response.test_name;

    const propPassingRTWMC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingRTWMC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingRTWMC);

    this.setState({
      loading: false,
      examName: response.test_name,
    });
  };
  // Customizable Area End
}
