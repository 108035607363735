import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { webStyleSynopsisTopics } from "./SynopsisTopics.web";
import { WithStyles } from "@material-ui/core";
import { SubjectData } from "./SynopsisSubjectsController.web";
export interface TopicData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    remarks: string;
    remark_2: string;
    date_synopsis: string | null;
    question_status: ActivityStatus;
    synopsis: string | null;
    topic_read: boolean;
    saved_notes_count: number;
    is_bookmarked: boolean;
  };
}

export interface ChapterMeta {
  chapter: {
    name: string;
  };
}

type ActivityStatus = "inactive" | "active";
// Customizable Area End

export interface Props extends WithStyles<typeof webStyleSynopsisTopics> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  topicNotRead: TopicData[] | null;
  topicRead: TopicData[] | null;
  meta: ChapterMeta | null;
  notesModal: boolean;
  topicId: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SynopsisTopicsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTopicsMessageId = "";
  getChapterMessageId = "";
  breadcrumb = [
    {
      link: `/dashboard/synopsis`,
      label: "Synopsis",
    },
    {
      link: `/dashboard/synopsis/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}`,
      label: "Chapters",
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Topics",
    },
  ];
  getSubjectsForChaptersMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      topicRead: null,
      topicNotRead: null,
      meta: null,
      notesModal: false,
      topicId: "",
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getTopicsMessageId) {
      if (response.data) {
        this.handleTopicsData(response.data);
        this.handleMeta(response.meta);
      }
    }

    if (apiRequestCallId === this.getSubjectsForChaptersMessageId) {
      if (response.data) {
        this.handleSubjects(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getTopics();
    const propPassingSTC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSTC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingSTC);
    this.getSubjectsForChapters();
  };

  handleSubjects = (response: SubjectData[]) => {
    let subjectDataTopics: SubjectData | null = null;
    let subjectId = this.props.navigation.getParam("subjectId");
    for (let index = 0; index <= response.length - 1; index++) {
      if (response[index].id === subjectId) {
        subjectDataTopics = response[index];
        break;
      }
    }
    const breadcrumb = this.breadcrumb;
    breadcrumb[1].label = subjectDataTopics?.attributes.name as string;

    const propPassingSCC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSCC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingSCC);
    this.setState({
      loading: false,
    });
  };

  handleMeta = (meta: ChapterMeta) => {
    const breadcrumb = this.breadcrumb;
    breadcrumb[2].label = meta.chapter.name;

    const propPassingSCC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSCC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingSCC);
    this.setState({
      meta: meta,
    });
  };

  handleNotesModal = (topicId: string) => {
    this.setState({
      notesModal: !this.state.notesModal,
      topicId,
    });
  };

  navigateToNewNote = () => {
    this.props.navigation.navigate("SynopsisAddEditNotes", {
      subjectId: this.props.navigation.getParam("subjectId"),
      topicId: this.state.topicId,
      chapterId: this.props.navigation.getParam("chapterId"),
      noteType: "New",
      noteId: "none",
    });
  };
  // Customizable Area Start
  getTopics = async () => {
    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    const getTopicsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getTopicsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `chapter_topics/${this.props.navigation.getParam("chapterId")}`
    );

    this.getTopicsMessageId = getTopicsMessage.messageId;

    getTopicsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getTopicsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getTopicsMessage.id, getTopicsMessage);
  };

  navigateToReading = (topicId: string) => {
    this.props.navigation.navigate("SynopsisTopicRead", {
      subjectId: this.props.navigation.getParam("subjectId"),
      chapterId: this.props.navigation.getParam("chapterId"),
      topicId: topicId,
      highlighted: "off",
    });
  };

  handleTopicsData = (data: TopicData[]) => {
    let topicRead: TopicData[] | null = data.filter(
      (topic) => topic.attributes.topic_read
    );
    let topicNotRead: TopicData[] | null = data.filter(
      (topic) => !topic.attributes.topic_read
    );
    if (topicRead.length === 0) {
      topicRead = null;
    }
    if (topicNotRead.length === 0) {
      topicNotRead = null;
    }

    this.setState({
      topicRead: topicRead,
      topicNotRead: topicNotRead,
      loading: false,
    });
  };

  getSubjectsForChapters = async () => {
    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    const getSubjectsForChaptersMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getSubjectsForChaptersMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `all_subjects`
    );
    this.getSubjectsForChaptersMessageId =
      getSubjectsForChaptersMessage.messageId;

    getSubjectsForChaptersMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getSubjectsForChaptersMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(
      getSubjectsForChaptersMessage.id,
      getSubjectsForChaptersMessage
    );
  };
  // Customizable Area End
}
