import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";

type CollegesDataType = {
  data: Array<CollegesData>;
};

type CollegesData = {
  id: string;
  type: string;
  attributes: {
    id: number;
    college_name: string;
    state_name: string;
    councelling_id: any;
    contact1: string;
    district: string;
    hostel: string;
    established: number;
    website: string;
    email: string;
    address: string;
    remarks: string;
    status: string;
    link_myrank: string;
    wikipedia: string;
    contact2: string;
    region: string;
    college_type: string;
    college_type2: string;
    remarks_2: string;
    courses: {
      data: Array<CourseAllData>;
    };
    universities: {
      data: Array<UniversityType>;
    };
  };
};

type CourseAllData = {
  id: string;
  type: string;
  attributes: {
    id: number;
    course_name: string;
    duration: number;
    status: string;
    degree: string;
    jee_id: any;
    mpc: string;
    bipc: string;
    mbipc: string;
    english: string;
    mec: string;
    cec: string;
    hec: string;
    hslc: string;
    specialization: string;
    scope: string;
    short_code: string;
    remarks: string;
    remarks_deletion: string;
  };
};

type UniversityType = {
  id: string;
  type: string;
  attributes: {
    id: number;
    univ_name: string;
    established: number;
    state: string;
    address: string;
    univ_type: string;
    status: string;
    website: string;
    link_myrank: string;
    wikipedia: string;
    contact1: string;
    contact2: string;
    email: string;
    description: string;
    remarks: string;
    application_status: any;
  };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  collegesData: CollegesData[];
  loading: boolean;
  currentPage: number;
  numberOfPages: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseFinderCollegesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCollegesMessageId = "";
  dataPerPage = 10;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      collegesData: [],
      loading: false,
      currentPage: 0,
      numberOfPages: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (response && apiRequestCallId === this.getAllCollegesMessageId) {
      if (response.data) {
        this.successfulColleges(response as CollegesDataType);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getAllColleges();
  };

  successfulColleges = (response: CollegesDataType) => {
    this.setState(
      {
        collegesData: response.data,
        loading: false,
      },
      () => {
        const dataLength = this.state.collegesData.length;
        this.setState({
          numberOfPages: Math.ceil(dataLength / this.dataPerPage),
        });
      }
    );
  };

  // Customizable Area Start
  getAllColleges = async () => {
    this.setState({
      loading: true,
    });

    const getAllCollegesMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getAllCollegesMessageId = getAllCollegesMessage.messageId;

    getAllCollegesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getAllCollegesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allColleges
    );

    getAllCollegesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getAllCollegesMessage.id, getAllCollegesMessage);
  };

  changePage = (numberOfPage: number) => {
    if (numberOfPage < this.state.numberOfPages) {
      this.setState({
        currentPage: numberOfPage,
      });
    }
  };
  // Customizable Area End
}
