import React, { Component } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import "react-circular-progressbar/dist/styles.css";

interface Props {
  correctPercentage: number;
  incorrectPercentage: number;
  unattendedPercentage: number;
}

export default class ProgressContainer extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { correctPercentage, incorrectPercentage, unattendedPercentage } =
      this.props;
    return (
      <div style={webStyle.scoreContainer}>
        <div style={webStyle.progressStyles}>
          <div style={webStyle.circularProgress1}>
            <CircularProgressbar
              value={correctPercentage as number}
              strokeWidth={14}
              styles={buildStyles({
                pathColor: `#9BBB56`,
              })}
            />
          </div>
          <div style={webStyle.circularProgress2}>
            <CircularProgressbar
              value={incorrectPercentage as number}
              strokeWidth={9}
              styles={buildStyles({
                pathColor: `#D84553`,
              })}
            />
          </div>
          <div style={webStyle.circularProgress3}>
            <CircularProgressbar
              value={unattendedPercentage as number}
              strokeWidth={7}
              styles={buildStyles({
                pathColor: `#FF9D54`,
              })}
            />
          </div>
        </div>
        <div style={webStyle.legendStyles}>
          <p style={webStyle.centerStyle}>
            <span>
              <FiberManualRecordIcon style={webStyle.legendIcon1} />
            </span>
            Correct ({correctPercentage}%)
          </p>
          <p style={webStyle.centerStyle}>
            <span>
              <FiberManualRecordIcon style={webStyle.legendIcon2} />
            </span>{" "}
            Incorrect ({incorrectPercentage}%)
          </p>
          <p style={webStyle.centerStyle}>
            <span>
              <FiberManualRecordIcon style={webStyle.legendIcon3} />
            </span>{" "}
            Unattempted ({unattendedPercentage}%)
          </p>
        </div>
      </div>
    );
  }
}

const webStyle = {
  legendStyles: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    gap: "3em",
  },
  centerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  progressStyles: {
    display: "flex",
    width: "100%",
    height: "400px",
    position: "relative" as const,
    justifyContent: "center",
    alignItems: "center",
  },
  scoreContainer: {
    alignItems: "space-around",
    width: "580px",
    height: "550px",
    justifyContent: "center",
    background: "#FAF8FD",
    padding: "0em 2em",
    display: "flex",
    flexDirection: "column" as const,
  },
  circularProgress1: {
    position: "absolute" as const,
    top: 78,
    width: "140px",
  },
  circularProgress2: {
    position: "absolute" as const,
    top: 40,
    width: "220px",
  },
  circularProgress3: {
    position: "absolute" as const,
    top: 0,
  },
  legendIcon1: {
    color: "#9BBB56",
  },
  legendIcon2: {
    color: "#D84553",
  },
  legendIcon3: {
    color: "#FF9D54",
  },
};
