export const myranklogo = require("../assets/myranklogo.png");
export const phonecall = require("../assets/phonecall.png");
export const graduate = require("../assets/portrait.jpg");
export const pcIcon = require("../assets/pc-icon.png");
export const academic = require("../assets/academic.jpg");
export const practical = require("../assets/practical.jpg");
export const pcimage = require("../assets/pc-image.png");
export const taskImage = require("../assets/task.png");
export const bitcoin = require("../assets/bitcoin.png");
export const bitcoinTrophy = require("../assets/bitcointrophy.png");
export const bitcoinWallet = require("../assets/bitcoinwallet.png");
export const money = require("../assets/money.png");
export const profile = require("../assets/profile.png");
export const classroom = require("../assets/classroom.jpg");
export const path = require("../assets/path.png");
