import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import AYQBadgesController, { Props } from "./AYQBadgesController.web";

class AYQBadges extends AYQBadgesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  BadgeContainer = ({
    image,
    description,
    count,
    type,
    bgcolor,
  }: {
    image: string;
    description: string;
    count: number;
    type: string;
    bgcolor: string;
  }) => {
    return (
      <Grid key={type} item xs={6}>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Box
            className={this.props.classes.badgesBoxStyle}
            style={this.determineBgColor(bgcolor)}
          >
            <img src={image} alt={description} width={"40px"} />
            <Typography variant="h5">{count}</Typography>
            <Typography>{description}</Typography>
            {count === 0 && (
              <Button onClick={this.navigateToQuestions}>Ask / Answer</Button>
            )}
          </Box>
        </Box>
      </Grid>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box className={this.props.classes.bannerStyle}>
          <Typography variant="h5">Badges</Typography>
        </Box>
        <Grid
          container
          spacing={5}
          justifyContent="center"
          alignContent="center"
          className={this.props.classes.gridContainer}
        >
          {this.state.badgesData
            .map((badge) => this.BadgeContainer(badge))
            .reverse()}
        </Grid>
        <Box className={this.props.classes.badgesContainerStyle}>
          <Typography variant="h5">
            Check Leaderboard for more details about badge
          </Typography>
          <Button onClick={this.goToLeaderboard}>Go to Leaderboard</Button>
          <Button>Cancel</Button>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleAYQBadges = () =>
  createStyles({
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "#510081",
      display: "flex",
      background: "rgb(247,242,249)",
      height: "71px",
      "& h5": {
        fontWeight: 700,
      },
    },
    badgesContainerStyle: {
      padding: "2em",
      marginTop: "4em",
      marginBottom: "6em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& button": {
        borderRadius: "1em",
        padding: "0.7em 1.6em",
        color: "white",
        marginTop: "1em",
        width: "300px",
      },
      "& button:nth-child(even)": {
        background:
          "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      },
      "& button:nth-child(odd)": {
        background: "transparent",
        border: "1px solid #FF565B",
        color: "#FF565B",
      },
    },
    badgesBoxStyle: {
      color: "white",
      fontWeight: 500,
      width: "330px",
      minHeight: "200px",
      height: "max-content",
      borderRadius: "2em",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      textAlign: "center",
      padding: "4em 2em 2em 2em",
      marginTop: "3em",
      "& img": {
        position: "absolute",
        transform: "translate(-50%,-50%)",
        left: "50%",
        top: "-5px",
        width: "60px",
      },
      "& button": {
        borderRadius: "1em",
        padding: "0.7em 1.6em",
        color: "white",
        marginTop: "1em",
        background:
          "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      },
    },
    gridContainer: {
      marginTop: "6em",
    },
  });
export default withStyles(webStyleAYQBadges)(AYQBadges);
export { AYQBadges };
// Customizable Area End
