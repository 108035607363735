import React from "react";

import {
  Box as MUIBox,
  // Customizable Area Start
  Button as MUIButton,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { noreview } from "./assets";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { NavLink } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import QuestionBankSavedNotesController, {
  Props,
} from "./QuestionBankSavedNotesController.web";

export default class QuestionBankSavedNotes extends QuestionBankSavedNotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const subjectId = this.props.navigation.getParam("subjectId");
    const chapterId = this.props.navigation.getParam("chapterId");
    return (
      // Customizable Area Start
      <MUIBox>
        <Loader loading={this.state.loading} />
        <Modal
          onClose={this.handleDeletePromptClose2}
          open={this.state.isConfirmDeletePromptOpen}
        >
          <MUIBox style={webStyle.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>Deleted Note Successfully</h3>
            <MUIBox>
              <MUIButton
                style={webStyle.buttonStyleYes}
                data-test-id="goback-btn"
                onClick={this.handleDeletePromptClose2}
              >
                Go Back
              </MUIButton>
            </MUIBox>
          </MUIBox>
        </Modal>
        <Modal
          open={this.state.isDeletePromptOpen}
          onClose={this.handleDeletePromptClose}
        >
          <MUIBox style={webStyle.modalStyle} className="submit-test-question">
            <h3>Are you sure to delete this Note?</h3>
            <MUIBox>
              <MUIButton
                style={webStyle.buttonStyleNo}
                data-test-id="cancel-delete"
                onClick={this.handleDeletePromptClose}
              >
                Cancel
              </MUIButton>
              <MUIButton
                style={webStyle.buttonStyleYes}
                data-test-id="delete-confirm"
                onClick={() =>
                  this.deleteSavedNotes(this.noteIdToDelete, this.indexToDelete)
                }
              >
                Delete
              </MUIButton>
            </MUIBox>
          </MUIBox>
        </Modal>
        <MUIBox style={webStyle.bannerStyle}>
          <h2>Saved Notes</h2>
        </MUIBox>
        {this.state.notes.length === 0 ? (
          <MUIBox style={webStyle.setMiddleStyleColumn2}>
            <MUIBox style={webStyle.setMiddleStyleColumn2}>
              <img src={noreview} alt="noreview" width={"600px"} />
              <h2>No Saved Notes Added</h2>
            </MUIBox>
          </MUIBox>
        ) : (
          <MUIBox sx={webStyle.centerContainer}>
            <MUIBox style={webStyle.centerVertically}>
              <p>Exam : {this.state.exam}</p>
              <p>Total Notes - {this.state.notes.length}</p>
            </MUIBox>

            <MUIBox style={webStyle.centerHorizontally}>
              {this.state.notes.map((note, index) => {
                return (
                  <MUIBox style={webStyle.cardBookmark}>
                    <p>Note: {note.notes_content}</p>
                    <MUIBox style={webStyle.cardBookmark2}>
                      <p>Question No: {note.question_no}</p>
                      <MUIBox>
                        <MUIButton>
                          <NavLink
                            to={`/dashboard/question-bank/subject/${subjectId}/chapter/${chapterId}/saved-notes/notes?noteType=Edit&reviewId=${this.state.reviewId}&qno=${note.question_no}&qid=${note.question_id}&noteId=${note.id}&chapterName=${this.state.exam}`}
                          >
                            <EditOutlinedIcon />
                          </NavLink>
                        </MUIButton>
                        <MUIButton>
                          <DeleteOutlineOutlinedIcon
                            data-test-id="delete-btn"
                            onClick={() =>
                              this.deletePromptOpen(String(note.id), index)
                            }
                          />
                        </MUIButton>
                      </MUIBox>
                    </MUIBox>
                  </MUIBox>
                );
              })}
            </MUIBox>
          </MUIBox>
        )}
      </MUIBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  buttonStyleNo: {
    width: "130px",
    height: "50px",
    color: "#4F1f6d",
    background: "white",
    border: "1px solid #ffe9ed",
    borderRadius: "0.6em",
    textTransform: "none" as const,
    cursor: "pointer",
  },
  setMiddleStyleColumn2: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "2em",
    marginTop: "2em",
  },
  bannerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    color: "rgb(82, 0, 130)",
  },
  buttonStyleYes: {
    width: "130px",
    height: "50px",
    color: "white",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    textTransform: "none" as const,
    marginLeft: "1em",
    cursor: "pointer",
  },
  centerHorizontally: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    gap: "1em",
  },
  centerVertically: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "678.55px",
    marginBottom: "1em",
  },
  modalStyle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as const,
    position: "absolute" as "absolute",
    alignItems: "center",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "max-content",
    width: "35%",
    border: "2px solid #FFD0D8",
    backgroundColor: "white",
    padding: "2em",
    borderRadius: "1.5em",
    gap: "1.5em",
  },
  cardBookmark2: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  cardBookmark: {
    height: "116.8px",
    width: "678.55px",
    border: "1px solid #FCC2C0",
    background: "rgba(209, 170, 255, 0.14)",
    padding: "1em",
    borderRadius: "10px",
  },
  centerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2em",
  },
};
// Customizable Area End
