import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
export interface Root {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  test_no: number;
  test_name: string;
  gt_code: string;
  avail_test: boolean;
  limit_test: string;
  test_page: string;
  analysis_page: string;
  avail_analysis: string;
  calc_page: string;
  attempted: boolean;
  image_url: any;
  created_by: string;
  created_at: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  attempted: Root[];
  unattempted: Root[];
  chapterName: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GrandTestAttemptsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAttemptsMessageId: string = "";
  getPackageMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      attempted: [],
      unattempted: [],
      loading: false,
      chapterName: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getAttemptsMessageId) {
      if (response.attempted) {
        this.handleSuccessfulFetch(response);
      }
    }

    if (apiRequestCallId === this.getPackageMessageId && response) {
      if (response.data) {
        const packageId = this.props.navigation.getParam("packageId");

        response.data.forEach(
          (data: { gt_code: string; package_name: string }) => {
            if (data.gt_code === packageId) {
              const breadcrumb = [
                {
                  link: this.props.navigation.history.location.pathname,
                  label: "Grand Test",
                },
                {
                  link: this.props.navigation.history.location.pathname,
                  label: data.package_name,
                },
              ];
              const propPassing = new Message(
                getName(MessageEnum.NavigationBreadcrumbMessage)
              );
              propPassing.addData(
                getName(MessageEnum.BreadcrumbDataMessage),
                breadcrumb
              );
              this.send(propPassing);

              this.setState({
                chapterName: data.package_name,
                loading: false,
              });
            }
          }
        );
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    const packageId = this.props.navigation.getParam("packageId");
    this.getAttempts(packageId);
    this.getPackage();
  };

  componentDidUpdate = (prevProps: Props, _: S) => {
    const packageId = this.props.navigation.getParam("packageId");

    if (packageId !== prevProps.navigation.getParam("packageId")) {
      this.getAttempts(packageId);
      this.getPackage();
    }
  };

  handleSuccessfulFetch = (response: {
    attempted: { data: Root[] };
    unattempted: { data: Root[] };
  }) => {
    this.setState({
      attempted: response.attempted.data,
      unattempted: response.unattempted.data,
      loading: false,
    });
  };

  // Customizable Area Start
  getAttempts = async (packageId: string) => {
    const getAttemptsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getAttemptsMessageId = getAttemptsMessage.messageId;

    getAttemptsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getAttemptsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests?gt_code=${packageId}`
    );

    getAttemptsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getAttemptsMessage.id, getAttemptsMessage);
  };

  getPackage = async () => {
    const getPackageMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getPackageMessageId = getPackageMessage.messageId;

    getPackageMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getPackageMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/grand_tests/get_packages`
    );

    getPackageMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getPackageMessage.id, getPackageMessage);
  };
  // Customizable Area End
}
