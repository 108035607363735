import React from "react";

import {
  Box,
  // Customizable Area Start
  Button as MUIButton,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
import Button from "../../../components/src/Button.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import PracticeTestAttemptsController from "./PracticeTestAttemptsController.web";

export default class PracticeTestAttempts extends PracticeTestAttemptsController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box sx={webStyle.bannerStyle}>
          <h2>{this.state.subjectName}</h2>
        </Box>
        <Box sx={webStyle.testAttemptsStyles}>
          {this.state.chaptersAttempted.length > 0 && (
            <Box>
              <h2>Tests attempted</h2>
              <Box style={webStyle.chapterContainerStyle}>
                {this.state.chaptersAttempted.map((attempted) => {
                  const subjectId = this.props.navigation.getParam("subjectId");
                  return (
                    <Box
                      style={{
                        ...webStyle.cardStyle,
                        border: "1px solid #55624D",
                        background: "#F7FFF4",
                      }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <img
                          src={attempted.attributes.image}
                          alt={`chapter-${attempted.id}`}
                          style={webStyle.imageStyle}
                        />
                        <Typography
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            marginLeft: "1em",
                          }}
                        >
                          {attempted.attributes.name}
                        </Typography>
                      </Box>
                      <Box sx={webStyle.buttonStyles}>
                        <Link
                          to={`/dashboard/practice-test/chapters/${subjectId}/test/${attempted.id}${this.searchResolve}`}
                        >
                          <Button
                            color="white"
                            height="40px"
                            width="121px"
                            title="Take Test"
                          />
                        </Link>
                        <Link
                          to={`/dashboard/practice-test/chapters/${subjectId}/review-test/${attempted.id}${this.searchResolve}`}
                        >
                          <MUIButton style={webStyle.buttonStyle}>
                            Review Test
                            <Box
                              sx={webStyle.goBackStep}
                              data-test-id="gobackbtn"
                            >{`>`}</Box>
                          </MUIButton>
                        </Link>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
          {this.state.chaptersUnattempted.length > 0 && (
            <Box>
              <h2>Tests Unattempted</h2>
              <Box style={webStyle.chapterContainerStyle}>
                {this.state.chaptersUnattempted.map((unattempted) => {
                  const subjectId = this.props.navigation.getParam("subjectId");
                  return (
                    <Box style={webStyle.cardStyle}>
                      <Box
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <img
                          src={unattempted.attributes.image}
                          alt={`chapter-${unattempted.id}`}
                          style={webStyle.imageStyle}
                        />
                        <p
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            marginLeft: "1em",
                          }}
                        >
                          {unattempted.attributes.name}
                        </p>
                      </Box>
                      <Box sx={webStyle.buttonStyles}>
                        <Link
                          to={`/dashboard/practice-test/chapters/${subjectId}/test/${unattempted.id}${this.searchResolve}`}
                        >
                          <Button
                            color="white"
                            height="40px"
                            width="121px"
                            title="Take Test"
                          />
                        </Link>
                        <Link
                          to={`/dashboard/practice-test/chapters/${subjectId}/review-test/${unattempted.id}${this.searchResolve}`}
                        >
                          <MUIButton style={webStyle.buttonStyle}>
                            Review Test
                            <Box
                              sx={webStyle.goBackStep}
                              data-test-id="gobackbtn"
                            >{`>`}</Box>
                          </MUIButton>
                        </Link>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  chapterContainerStyle: {
    display: "flex",
    width: "100%",
    gap: "1em",
    flexWrap: "wrap" as const,
  },
  imageStyle: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
  bannerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "71px",
    background: "rgba(247, 214, 255, 0.19)",
    color: "rgb(82, 0, 130)",
    position: "relative",
  },
  cardStyle: {
    width: "500px",
    minHeight: "130px",
    height: "max-content",
    display: "flex",
    maxWidth: "100%",
    flexWrap: "wrap" as const,
    flexDirection: "column" as const,
    justifyContent: "center",
    border: "1px solid rgba(255, 63, 89, 1)",
    borderRadius: "0.5em",
    padding: "2em 0.7em",
    gap: "1em",
    marginTop: "2em",
  },
  buttonStyle: {
    color: "rgb(82, 0, 130)",
    width: "125px",
    borderRadius: "10px",
    textTransform: "none" as const,
    border: "1px solid #FF9486",
    height: "40px",
    display: "flex",
    position: "relative" as const,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  buttonStyles: {
    display: "flex",
    gap: "2em",
    marginLeft: "3.5em",
  },
  testAttemptsStyles: {
    padding: "2em 3em",
    display: "flex",
    flexDirection: "column",
    gap: "2em" as const,
  },
  goBackStep: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    padding: "0.3em",
    background:
      "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
    color: "white",
    cursor: "pointer",
    position: "absolute",
    right: "5px",
  },
};
// Customizable Area End
